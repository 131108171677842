import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import DatePicker from '../DatePicker';
import { InputLayoutVariant, SelectInputLayout } from '../InputLayout';
import { UiSelector } from 'features/UiPreferences';

const defaultSelectStyle = { minWidth: '120px', maxWidth: '300px' };

export const Filter = ({ kind, component, value, props, onChange }) => {
  switch (kind) {
    case 'date':
      return (
        <DatePicker
          collapsedIfBlank={props?.collapsedIfBlank}
          variant={InputLayoutVariant.flat.medium}
          disableTime
          mode="range"
          label="Range"
          value={value}
          onChange={onChange}
          fitCurrentOptionWidth
          {...(props || {})}
        />
      );
    case 'select':
      const Component = component ? component : SelectInputLayout;
      return (
        <Component
          variant={InputLayoutVariant.flat.medium}
          onChange={onChange}
          style={defaultSelectStyle}
          value={value ? value : props?.multi ? [] : null}
          fitCurrentOptionWidth
          inline
          optionEditable={false}
          inputCreatable={false}
          {...(props || {})}
        />
      );
    default:
      return (
        <SelectInputLayout
          variant={InputLayoutVariant.flat.medium}
          onChange={onChange}
          style={defaultSelectStyle}
          value={value ? value : props?.multi ? [] : null}
          fitCurrentOptionWidth
          inline
          {...(props || {})}
        />
      );
  }
};

const useFilters = propsFilters => {
  const [filters, setFilters] = useState(propsFilters);
  const uiKeys = useMemo(
    () =>
      filters
        .filter(f => f.key && f.key.startsWith('ui.'))
        .map(f => f.key.replace('ui.', '')),
    [filters]
  );

  // on cherche dans le global state ui si y'a la key
  const selectUi = useMemo(
    () => UiSelector.selectUiKeys(...uiKeys),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [uiKeys.join(',')]
  );

  const uiValues = useSelector(selectUi, isEqual);

  const memoFilters = useMemo(() => {
    const array = filters.map(filter => {
      const key = filter.key?.replace('ui.', '');
      if (key && uiKeys.includes(key)) {
        return {
          ...filter,
          value: uiValues[key]
        };
      } else {
        return filter;
      }
    });
    array.asQueryParams = function (...args) {
      return this.reduce((acc, filter) => {
        return {
          ...acc,
          ...filter.asQueryParam(filter.value, ...args)
        };
      }, {});
    };
    return array;
  }, [filters, uiValues, uiKeys]);

  return [memoFilters, setFilters];
};

export default useFilters;

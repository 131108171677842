import { mergeJsonApi } from 'common/reducers';

const initialState = {
  deliveryById: {},
  deliveriesIds: []
};

const deliveries = (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case 'LOGOUT':
    case 'SELECT_ACCOUNT':
      return initialState;
    default:
      return mergeJsonApi('delivery', state, action);
  }
};

export default deliveries;

import React, {
  useCallback,
  useRef,
  useMemo,
  StyleHTMLAttributes
} from 'react';
import styled, { CSSProperties } from 'styled-components';
import Scrollbars, {
  ScrollbarProps,
  positionValues
} from 'react-custom-scrollbars';
import omit from 'lodash/omit';

const TrackVertical = styled.div`
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  border-radius: 3px;
  z-index: 4;
  width: 6px;
  transition: 200ms ease all;
  opacity: 0.54;
  &:hover,
  :active {
    width: 10px;
    opacity: 1;
  }
`;

const renderVerticalTrack = (
  props: React.HTMLProps<HTMLDivElement>,
  additionalStyle?: React.CSSProperties
) => {
  const { style } = props;

  const trackStyle = {
    ...omit(style, 'width'),
    ...(additionalStyle || {})
  } as CSSProperties;

  return <TrackVertical style={trackStyle} />;
};

const ThumbVertical = styled.div`
  background-color: ${({ theme }) => theme.hint};
  border-radius: inherit;
  cursor: pointer;
  width: 100%;
`;

const renderThumbVertical = (props: React.HTMLProps<HTMLDivElement>) => {
  const { style } = props;
  return <ThumbVertical style={style} />;
};

const TrackHorizontal = styled.div`
  right: 0;
  bottom: 0;
  left: 0;
  height: 6px;
  border-radius: 3px;
  z-index: 4;
  transition: 200ms ease all;
  opacity: 0.54;
  &:hover,
  :active {
    height: 10px;
    opacity: 1;
  }
`;

const renderHorizontalTrack = (
  props: React.HTMLProps<HTMLDivElement>,
  additionalStyle?: StyleHTMLAttributes<HTMLDivElement>
) => {
  const { style } = props;
  const trackStyle = {
    ...omit(style, 'height'),
    ...(additionalStyle || {})
  } as CSSProperties;
  return <TrackHorizontal style={trackStyle} />;
};

const ThumbHorizontal = styled.div`
  background-color: ${({ theme }) => theme.hint};
  border-radius: inherit;
  cursor: pointer;
  height: 100%;
`;

const renderThumbHorizontal = (props: React.HTMLProps<HTMLDivElement>) => {
  const { style } = props;
  return <ThumbHorizontal style={style} />;
};

const View = styled.div`
  overflow: hidden;
`;

const viewRender = (props: React.HTMLProps<HTMLDivElement>, grow?: boolean) => {
  let { style } = props;
  if (grow) {
    style = {
      ...style,
      minHeight: 'calc(100% + 15px)'
      // display: 'flex',
      // flexGrow: 1,
      // flexDirection: 'column'
    };
  }
  return <View style={style} />;
};

export interface Props extends ScrollbarProps {
  onVerticalScroll?: (scrollTop: number, bottomReached: boolean) => void;
  onHorizontalScroll?: (scrollLeft: number, rightReached: boolean) => void;
  loadMoreItems?: () => void;
  hasMoreItems?: boolean;
  loadMoreThreshold?: number;
  verticalThumbStyle?: StyleHTMLAttributes<HTMLDivElement>;
  horizontalThumbStyle?: StyleHTMLAttributes<HTMLDivElement>;
  grow?: boolean; // if true set display: flex & flex-grow: 1
}

const Scroller = React.forwardRef<Scrollbars, Props>(
  (
    {
      onScroll,
      onVerticalScroll,
      onHorizontalScroll,
      style,
      verticalThumbStyle,
      horizontalThumbStyle,
      loadMoreItems,
      hasMoreItems,
      loadMoreThreshold = 500,
      children,
      grow,
      ...props
    },
    ref
  ) => {
    const verticalOffset = useRef<number>(0);
    const horizontalOffset = useRef<number>(0);

    const handleScroll = useCallback(
      ({
        scrollTop,
        scrollHeight,
        clientHeight,
        scrollLeft,
        scrollWidth,
        clientWidth
      }: positionValues) => {
        if (horizontalOffset.current !== scrollLeft) {
          horizontalOffset.current = scrollLeft;
          if (onHorizontalScroll) {
            onHorizontalScroll(
              scrollLeft,
              scrollLeft + clientWidth >= scrollWidth
            );
          }
        }
        if (verticalOffset.current !== scrollTop) {
          verticalOffset.current = scrollTop;
          if (onVerticalScroll) {
            onVerticalScroll(
              scrollTop,
              scrollTop + clientHeight >= scrollHeight
            );
          }
        }
        if (
          loadMoreItems &&
          hasMoreItems &&
          scrollTop + clientHeight + loadMoreThreshold > scrollHeight
        ) {
          loadMoreItems();
        }
      },
      [
        onHorizontalScroll,
        onVerticalScroll,
        loadMoreThreshold,
        hasMoreItems,
        loadMoreItems
      ]
    );

    const memoStyle = useMemo(() => {
      return {
        ...(style || {}),
        overflow: 'hidden',
        flexGrow: 1,
        ...(grow
          ? ({
              display: 'flex',
              flexGrow: 1,
              flexDirection: 'column'
            } as CSSProperties)
          : {})
      };
    }, [style, grow]);

    const renderTrackVertical = useCallback(
      props => renderVerticalTrack(props, verticalThumbStyle),
      [verticalThumbStyle]
    );

    const renderTrackHorizontal = useCallback(
      props => renderHorizontalTrack(props, horizontalThumbStyle),
      [horizontalThumbStyle]
    );

    const renderView = useCallback(props => viewRender(props, grow), [grow]);

    return (
      <Scrollbars
        ref={ref}
        style={memoStyle}
        onScroll={onScroll}
        onUpdate={handleScroll}
        renderView={renderView}
        renderTrackVertical={renderTrackVertical}
        renderTrackHorizontal={renderTrackHorizontal}
        renderThumbVertical={renderThumbVertical}
        renderThumbHorizontal={renderThumbHorizontal}
        // autoHide
        // autoHideTimeout={1000}
        // autoHideDuration={200}
        {...props}
      >
        {children}
      </Scrollbars>
    );
  }
);

Scroller.defaultProps = {
  loadMoreThreshold: 500
};

export default React.memo(Scroller);

import flatten from 'lodash/flatten';
import { createDeepEqualSelector, getProps } from 'common/selector';
import { getState, selectRelations } from 'common/selector';
import { INVOICE_KINDS } from '..';
import { AccountSelector } from 'features/Accounts';
import isSameIds from 'utils/idUtils';

export const getInvoices = state => state.invoices;
export const getServices = state => state.services;
export const getUnits = state => state.units;
export const getSubcontractings = state => state.subcontractings;

export const selectInvoices = createDeepEqualSelector(
  [getInvoices, getState],
  ({ invoicesIds, invoiceById }, state) =>
    invoicesIds
      .map(id => selectRelations('invoice', invoiceById[id], state))
      .filter(Boolean)
);

export const selectInvoice = createDeepEqualSelector(
  [getInvoices, getProps, getState],
  ({ invoiceById }, { invoiceId }, state) => {
    const invoice = selectRelations('invoice', invoiceById[invoiceId], state);
    if (invoice) {
      let template = invoice.documentTemplate?.data || invoice.documentTemplate;
      if (template) {
        template = {
          ...template,
          ...(template.attributes || {})
        };
      }
      let lines = invoice.invoiceLines?.data || invoice.invoiceLines || [];
      return {
        ...invoice,
        invoice:
          invoiceById[invoiceById[invoiceId].relationships?.invoice?.data?.id],
        invoiceLines: lines.map(({ attributes, ...line }) => ({
          ...line,
          ...(attributes || {})
        })),
        documentTemplate: template
      };
    } else {
      return null;
    }
  }
);

export const selectProjectInvoices = createDeepEqualSelector(
  [getInvoices, getProps, getState],
  ({ invoiceById }, { projectId }, state) => {
    if (!projectId) {
      return [];
    }
    const invoices = Object.values(invoiceById)
      .filter(
        invoice =>
          isSameIds(invoice?.projectId, projectId) ||
          isSameIds(invoice?.relationships?.project?.id, projectId)
      )
      .map(invoice => selectRelations('invoice', invoice, state))
      .filter(Boolean)
      .map(invoice => ({
        ...invoice,
        canDelete:
          !Boolean(invoice.number) ||
          (invoice.state?.value || invoice.state) === 'draft'
      }));

    const grouped = invoices.reduce(
      (grouped, item) => {
        const { kind } = item;
        if (!grouped[kind]) {
          grouped[kind] = [];
        }
        grouped[kind].push(item);
        return grouped;
      },
      INVOICE_KINDS.reduce((grouped, model) => {
        return {
          ...grouped,
          [model.value]: []
        };
      }, {})
    );
    return flatten(
      Object.keys(grouped)
        .filter(key => grouped[key] && grouped[key].length > 0)
        .map(key => [
          { label: null, id: key, tableKind: 'section', small: true },
          ...grouped[key]
        ])
    ).slice(1);
  }
);

export const selectServices = createDeepEqualSelector(
  [getServices, getState],
  ({ servicesIds, serviceById }, state) =>
    servicesIds
      .map(id => selectRelations('service', serviceById[id], state))
      .filter(Boolean)
);

export const selectSearchServices = createDeepEqualSelector(
  [getServices, getProps, getState],
  ({ serviceById }, { groupId, projectKindId }, state) =>
    Object.values(serviceById)
      .map(service => selectRelations('service', service, state))
      .filter(s => filterService(s, groupId, projectKindId))
      .slice(0, 50)
);

export const filterService = (service, groupId, projectKindId) => {
  if (!service) {
    return false;
  }
  const inGroup =
    !groupId ||
    !service.groupIds ||
    service.groupIds.length === 0 ||
    service.groupIds.find(id => isSameIds(groupId, id));

  const inKind =
    !projectKindId ||
    !service.projectKindId ||
    isSameIds(service.projectKindId, projectKindId);
  return inGroup && inKind;
};

export const selectService = createDeepEqualSelector(
  [getServices, getProps, getState],
  ({ serviceById }, { serviceId }, state) =>
    selectRelations('service', serviceById[serviceId], state)
);

export const selectUnits = createDeepEqualSelector(
  [getUnits, getState],
  ({ unitsIds, unitById }, state) =>
    unitsIds
      .map(id => selectRelations('unit', unitById[id], state))
      .filter(Boolean)
);

export const selectUnit = createDeepEqualSelector(
  [getUnits, getProps, getState],
  ({ unitById }, { unitId }, state) =>
    selectRelations('unit', unitById[unitId], state)
);

export const selectSubcontracting = createDeepEqualSelector(
  [getSubcontractings, getProps, getState],
  ({ subcontractingById }, { subcontractingId }, state) =>
    selectRelations(
      'subcontracting',
      subcontractingById[subcontractingId],
      state
    )
);

export const selectProjectSubcontractings = createDeepEqualSelector(
  [getSubcontractings, getProps, getState],
  ({ subcontractingById }, { projectId }, state) =>
    Object.values(subcontractingById)
      .filter(
        contract =>
          isSameIds(contract?.projectId, projectId) ||
          isSameIds(contract?.relationships?.project?.id, projectId)
      )
      .map(contract => selectRelations('subcontracting', contract, state))
      .filter(Boolean)
);

export const selectInvoiceStates = createDeepEqualSelector(
  [AccountSelector.selectCurrentAccount, getProps],
  acc => {
    if (!acc) {
      return [];
    }
    const states = acc.invoiceStates ? acc.invoiceStates : [];
    return states.filter(state => {
      if (state.value === 'draft') {
        return false;
      }
      return true;
    });
  }
);

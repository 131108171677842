import React, {
  useCallback,
  useMemo,
  Fragment,
  useState,
  useEffect
} from 'react';
import { SelectField } from 'components/v3/Form';
import { SelectInputLayout } from 'components/v3/InputLayout';
import { useRedux } from 'components/v3/utils';
import { InputMaterialModal } from './InputMaterialModal';
import { jsonApiToObject } from 'common/api';
import {
  MaterialSelector,
  MaterialAction,
  inputMaterialAsOption
} from '../data';
import { permissions, useAccountConfig } from 'config';

const formatCreateLabel = input => `Créer l'entrant: ${input}`;

export const InputMaterialSelect = ({ value, onChange, ...props }) => {
  const { accountId, hasPermission } = useAccountConfig();
  const [inputMaterialModal, setInputMaterialModal] = useState();
  const [inputMaterials, fetch] = useRedux(
    MaterialSelector.selectInputMaterials,
    MaterialAction.getInputMaterials,
    {
      fetchOnMount: false,
      useLocalState: true,
      initialParams: {
        page: 1,
        perPage: 500,
        useCache: true
      }
    }
  );

  const handleCreateOption = useCallback(name => {
    setInputMaterialModal({
      name
    });
  }, []);

  const handleEditOption = useCallback(value => {
    setInputMaterialModal(value);
  }, []);

  const options = useMemo(
    () => inputMaterialAsOption(inputMaterials),
    [inputMaterials]
  );

  const currentValue = useMemo(() => inputMaterialAsOption(value), [value]);

  useEffect(() => {
    fetch(params => params);
  }, [fetch, accountId]);

  return (
    <Fragment>
      <SelectInputLayout
        label="Entrant"
        value={currentValue}
        options={options}
        isSearchable
        onChange={onChange}
        inputCreatable={hasPermission([
          permissions.RESOURCES_CONFIG,
          'input_material.create'
        ])}
        createLabel="Créer un entrant"
        formatCreateLabel={formatCreateLabel}
        onCreateOption={handleCreateOption}
        optionEditable={hasPermission([
          permissions.RESOURCES_CONFIG,
          'input_material.update'
        ])}
        onEditOptionClick={handleEditOption}
        {...props}
      />
      {inputMaterialModal && (
        <InputMaterialModal
          inputMaterial={inputMaterialModal}
          onRequestClose={() => setInputMaterialModal(null)}
          onSubmitSuccess={response => {
            const inputMaterial = jsonApiToObject(response);
            if (props.multi) {
              onChange([...currentValue, inputMaterial]);
            } else {
              onChange(inputMaterial);
            }
          }}
        />
      )}
    </Fragment>
  );
};

export const InputMaterialField = props => {
  return <SelectField component={InputMaterialSelect} {...props} />;
};

import { createBrowserHistory } from 'history';
import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import createRootReducer from 'common/reducers';
import { loadState } from './api/localStorage';
import { routerMiddleware } from 'connected-react-router';
import { enableBatching } from 'redux-batched-actions';
export const history = createBrowserHistory();
const persistedState = loadState();

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

const store = createStore(
  enableBatching(createRootReducer(history)),
  persistedState,
  composeEnhancers(applyMiddleware(routerMiddleware(history), thunkMiddleware))
);

export default store;

import React, { useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { MdArrowDropDown } from 'react-icons/md';
import Tag from 'components/v3/Tag';
import {
  AccountingAction,
  AccountingSelector,
  invoiceStateAsOption
} from 'features/Accounting';
import {
  SelectInputLayout,
  InputLayoutVariant
} from 'components/v3/InputLayout';
import { useAction } from 'components/v3/utils';

const State = ({
  invoiceId,
  invoiceKind,
  value,
  onChange,
  onSuccess,
  size,
  asTag = true,
  readOnly,
  mapOptionValue,
  ...props
}) => {
  const action = useAction();
  const dispatch = useDispatch();

  const invoiceStates = useSelector(
    AccountingSelector.selectInvoiceStates,
    isEqual
  );

  const options = useMemo(
    () => invoiceStates.map(invoiceStateAsOption),
    [invoiceStates]
  );

  const currentValue = useMemo(
    () =>
      mapOptionValue
        ? value
        : options.find(o => o.value === value || o.value === value?.value) ||
          invoiceStateAsOption(value),
    [value, mapOptionValue, options]
  );

  const currentOptionRenderer = useCallback(
    ({ option }) => (
      <Tag
        displayName={option.label}
        color={option.color}
        size={size}
        trailingIcon={readOnly ? null : MdArrowDropDown}
        clickable={!readOnly}
        wide
      />
    ),
    [readOnly, size]
  );

  const handleChange = useCallback(
    state => {
      if (readOnly) {
        return;
      }
      if (onChange) {
        onChange(state);
      } else if (invoiceId) {
        action(() =>
          dispatch(
            AccountingAction.changeInvoiceState({
              id: invoiceId,
              payload: { state: state?.value }
            })
          )
        ).onSuccess(result => onSuccess && onSuccess(state?.value, result));
      }
    },
    [readOnly, action, dispatch, invoiceId, onChange, onSuccess]
  );

  return (
    <SelectInputLayout
      label={asTag ? null : 'État'}
      isSearchable={!asTag}
      variant={asTag ? InputLayoutVariant.flat.medium : null}
      isClearable={false}
      showIndicator={!asTag}
      sortOptions={false}
      options={options}
      value={currentValue}
      onChange={handleChange}
      currentOptionRenderer={asTag ? currentOptionRenderer : null}
      readOnly={readOnly}
      mapOptionValue={mapOptionValue}
      {...props}
    />
  );
};

export default State;

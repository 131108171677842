import uniq from 'lodash/uniq';
import omit from 'lodash/omit';
import { mergeJsonApi } from 'common/reducers';

const initialState = {
  contactById: {},
  contactsIds: [],
  searchIds: []
};

const contacts = (state = initialState, action) => {
  const { payload, type } = action;
  const pagination = payload?.meta?.pagination || {};
  const { page } = pagination;

  switch (type) {
    case 'NEW_CONTACT_SUCCESS':
      return {
        ...mergeJsonApi('contact', state, action),
        contactsIds: [payload.data.id, ...state.contactsIds]
      };
    case 'DELETE_CONTACT_SUCCESS':
      return {
        ...state,
        contactById: omit(state.contactById, payload),
        contactsIds: state.contactsIds.filter(id => parseInt(id) !== payload)
      };
    case 'SEARCH_CONTACTS_SUCCESS':
      let searchIds = state.searchIds || [];
      if (page > 1) {
        searchIds = uniq([
          ...searchIds,
          ...payload.data.map(contact => contact.id)
        ]);
      } else {
        searchIds = payload.data.map(contact => contact.id);
      }
      return {
        ...state,
        ...mergeJsonApi('contact', state, action),
        [type]: {
          fetchAt: new Date().getTime(),
          organizationId: payload.organizationId
        },
        searchIds
      };
    case 'LOGOUT':
    case 'SELECT_ACCOUNT':
      return initialState;
    default:
      return mergeJsonApi('contact', state, action);
  }
};

export default contacts;

import React, { useMemo, useCallback, useState, useEffect } from 'react';
import {
  useRouteMatch,
  Switch,
  Redirect,
  Route,
  useHistory
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';
import isEqual from 'lodash/isEqual';
import {
  MdCheckCircle,
  MdInfoOutline,
  MdInsertDriveFile,
  MdEventNote,
  MdMoreVert
} from 'react-icons/md';
import { FaFileInvoice } from 'react-icons/fa';
import * as Yup from 'yup';
import { EntityForm, ERRORS } from 'components/v3/Form';
import { Container, Title, Icon, ButtonIcon, Overline } from 'components/v3/ui';
import Scroller from 'components/v3/Scroller';
import SubHeader from 'components/v3/SubHeader';
import Dropdown from 'components/v3/Dropdown';
import { useDelay, usePrevious } from 'components/v3/utils';
import {
  ProjectAction,
  ProjectSelector,
  projectAsValues
} from 'features/Projects';
import ProjectState from 'features/Projects/components/v3/State';
import { GroupField } from 'features/Groups/components/v3/Select';
import Informations from './Informations';
import Documents from './Documents';
import Sidebar from './Sidebar';
import Accounting from './Accounting';
import Phases from './Phases';
import { MODES } from 'pages/Plannings';
import { permissions, useAccountConfig, Permission, features } from 'config';

const ContentContainer = styled(Container)`
  overflow: hidden;
`;

const HeaderActions = styled.div`
  display: flex;
  align-items: center;
  margin-right: -8px;
  padding-left: 8px;

  *:not(:nth-last-child(-n + 2)) {
    margin-right: 8px;
  }
`;

const SuccessIcon = styled(Icon)`
  color: ${({ theme }) => theme.success};
  transition: all 0.25s ease;
  transform: scale(0);
  width: 0px;
  height: 32px;

  ${({ visible }) =>
    visible &&
    css`
      width: 32px;
      transform: scale(1);
    `}
`;
const MENU = [
  {
    label: 'Informations Générales',
    Icon: MdInfoOutline,
    pathname: 'informations',
    Component: Informations,
    disabledIfNew: false
  },
  {
    label: 'Documents',
    Icon: MdInsertDriveFile,
    pathname: 'documents',
    Component: Documents,
    disabledIfNew: true,
    permission: [permissions.COMPANY_DATA, permissions.CHECK_DOCUMENTS]
  },
  {
    label: 'Comptabilité',
    Icon: FaFileInvoice,
    pathname: 'comptabilite',
    Component: Accounting,
    disabledIfNew: true,
    permission: [permissions.ACCOUNTING],
    feature: features.ACCOUNTING
  },
  {
    label: 'Interventions',
    Icon: MdEventNote,
    pathname: 'interventions',
    Component: Phases,
    disabledIfNew: true,
    permission: [permissions.COMPANY_DATA]
  }
];

const validationSchema = Yup.object().shape({
  projectKind: Yup.object().nullable().required(ERRORS.REQUIRED)
});

const ProjectDetail = () => {
  const history = useHistory();
  const { path, url, params } = useRouteMatch();
  const { projectId } = params;
  const dispatch = useDispatch();
  const delay = useDelay();
  const { hasPermission, hasFeature, accountId } = useAccountConfig();
  const prevAccountId = usePrevious(accountId);
  const [isSuccessIconVisible, setSuccessIconVisible] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [fetching, setFetching] = useState(false);
  const isNew = !Boolean(projectId);

  const selectProject = useMemo(
    () => state => ProjectSelector.selectProject(state, { projectId }),
    [projectId]
  );
  const project = useSelector(selectProject, isEqual);

  const createRequest = useCallback(
    values => dispatch(ProjectAction.createProject({ payload: values })),
    [dispatch]
  );

  const fetchRequest = useCallback(
    () => dispatch(ProjectAction.getProject({ id: projectId })),
    [projectId, dispatch]
  );

  const updateRequest = useCallback(
    (id, values) =>
      dispatch(ProjectAction.updateProject({ id, payload: values })),
    [dispatch]
  );

  const deleteRequest = useCallback(
    id => dispatch(ProjectAction.deleteProject({ id })),
    [dispatch]
  );

  const canEdit = hasPermission(isNew ? 'project.create' : 'project.update', {
    target: project
  });
  const canDelete = hasPermission('project.delete', { target: project });

  const menu = useMemo(
    () =>
      MENU.map(menu => {
        const { feature, permission } = menu;
        if (
          hasFeature(feature) &&
          (!permission || permission.find(p => hasPermission(p)))
        ) {
          return menu;
        } else {
          return null;
        }
      }).filter(Boolean),
    [hasFeature, hasPermission]
  );

  const routes = useMemo(
    () => menu.filter(route => route && route.pathname),
    [menu]
  );

  const handleMoreOptionSelected = useCallback(
    option => {
      if (option.value === 'delete') {
        setShowConfirmDelete(true);
      } else {
        history.push({
          pathname: option.pathname,
          search: `?projectId=${projectId}`
        });
      }
    },
    [projectId, history]
  );

  const goBack = useCallback(() => {
    history.replace('/projets');
  }, [history]);

  useEffect(() => {
    if (prevAccountId && accountId !== prevAccountId) {
      goBack();
    }
  }, [prevAccountId, accountId, goBack]);

  return (
    <EntityForm
      model="project"
      id={projectId}
      entity={project}
      entityAsValue={projectAsValues}
      validationSchema={validationSchema}
      fetchRequest={fetchRequest}
      createRequest={createRequest}
      updateRequest={updateRequest}
      deleteRequest={canDelete ? deleteRequest : undefined}
      showConfirmDelete={showConfirmDelete}
      onDeleteConfirmed={setShowConfirmDelete}
      onDeleteSuccess={goBack}
      disableSuccessToast={true}
      onFetching={setFetching}
      canTriggerNamingRules={Boolean(projectId) && !fetching}
      readOnly={!canEdit}
      onSubmitSuccess={(result, form) => {
        setSuccessIconVisible(true);
        delay(() => setSuccessIconVisible(false), 2000);
        if (isNew) {
          const id = result.data?.id;
          form.setFieldValue('id', id);
          history.replace(`/projets/${id}`);
        }
      }}
    >
      {form => {
        const { isSubmitting, values, setFieldValue } = form;
        return (
          <Container>
            <Sidebar {...form} project={project} isNew={isNew} menu={menu} />
            <ContentContainer vertical>
              <SubHeader isLoading={isSubmitting}>
                <div>
                  <Title>
                    {values?.displayName || (isNew && 'Nouveau projet')}
                  </Title>
                  <Overline color="caption" style={{ fontSize: '1.25rem' }}>
                    {values?.reference}
                  </Overline>
                </div>
                {(isNew || (project && !fetching)) && (
                  <HeaderActions>
                    <GroupField
                      name="group"
                      asTag
                      size="medium"
                      readOnly={!canEdit}
                    />
                    <ProjectState
                      size="medium"
                      readOnly={!canEdit || fetching}
                      value={values.state}
                      onChange={state => {
                        if (!fetching) {
                          setFieldValue('state', state);
                        }
                      }}
                    />
                    {projectId && canEdit && (
                      <Permission
                        feature={[
                          features.PLANNING_CALENDAR,
                          features.PLANNING_MACHINES,
                          features.PLANNING_MEMBERS,
                          features.PLANNING_TEAMS,
                          features.PLANNING_PROJECTS
                        ]}
                      >
                        {({ features }) => {
                          let options = features
                            .map(feature => {
                              const mode = MODES.find(mode =>
                                mode.features.includes(feature)
                              );
                              if (mode && mode.name !== 'timeline') {
                                return mode.option(feature);
                              } else {
                                return null;
                              }
                            })
                            .filter(Boolean);

                          options = [
                            ...options,
                            { label: 'Supprimer', value: 'delete' }
                          ];

                          return (
                            <Dropdown
                              showIndicator={false}
                              options={options}
                              maxWidth={400}
                              onChange={handleMoreOptionSelected}
                            >
                              <ButtonIcon>
                                <MdMoreVert />
                              </ButtonIcon>
                            </Dropdown>
                          );
                        }}
                      </Permission>
                    )}
                    <SuccessIcon size="large" visible={isSuccessIconVisible}>
                      <MdCheckCircle />
                    </SuccessIcon>
                  </HeaderActions>
                )}
              </SubHeader>
              <Scroller>
                <Switch>
                  {routes
                    .filter(({ disabledIfNew }) => !(disabledIfNew && isNew))
                    .map(({ pathname, Component }) => (
                      <Route key={pathname} path={`${path}/${pathname}`}>
                        <Component
                          {...form}
                          projectId={projectId}
                          fetching={fetching}
                          project={project}
                        />
                      </Route>
                    ))}
                  <Redirect to={`${url}/informations`} />
                </Switch>
              </Scroller>
            </ContentContainer>
          </Container>
        );
      }}
    </EntityForm>
  );
};

export default ProjectDetail;

import {
  createDeepEqualSelector,
  selectRelations,
  getState
} from 'common/selector';

export const getAttachments = state => state.attachments;

export const selectSigns = createDeepEqualSelector(
  [getAttachments, getState],
  ({ attachmentById }, state) =>
    Object.values(attachmentById)
      .map(attachment => selectRelations('attachment', attachment, state))
      .filter(attachment => {
        if (!attachment) {
          return false;
        }
        return attachment.kind === 'sign_road';
      })
);

import React from 'react';
import { Route, Link } from 'react-router-dom';
import styled from 'styled-components';
import { Title } from '../ui';
import { List, ListItem } from '../ui/list';

const Wrapper = styled(List)`
  display: inline-flex;
  width: auto;
  align-items: center;

  > li + li:before {
    content: '/';
    margin-left: 6px;
    margin-right: 6px;
  }
`;

const Item = styled(ListItem)`
  display: flex;
  align-items: center;
`;

const Breadcrumb = ({ links, ...props }) => {
  return (
    <Wrapper {...props}>
      {links.map(link => {
        return (
          <Route
            path={link.path}
            key={link.path}
            render={props => (
              <Item>
                <Title
                  as={
                    props.location.pathname !== props.match.url
                      ? Link
                      : undefined
                  }
                  to={props.match.url}
                >
                  {link.name}
                </Title>
              </Item>
            )}
          />
        );
      })}
    </Wrapper>
  );
};

export default Breadcrumb;

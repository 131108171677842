import { get, put } from 'common/api';

export const getGpsBoxes = params => get(`/v5/gps_boxes`, params);

export const getGpsBoxHistories = params =>
  get(`/v5/gps_box_histories`, params);

export const getGpsBox = (id, config) => get(`/v5/gps_boxes/${id}`, config);

export const putGpsBox = (id, gpsBox, config) =>
  put(`/v5/gps_boxes/${id}`, { gpsBox }, config);

import React, { useEffect, useMemo, Fragment, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { history } from './store';
import { MeAction, MeSelector } from 'features/Me';
import { AccountAction, AccountSelector } from 'features/Accounts';
import { AppVersionApi } from 'features/AppVersions';
import Navbar from 'components/Navbar';
import { ConfigProvider } from 'config';
import isEqual from 'lodash/isEqual';
import Deviations from 'pages/Public/Deviations';
import { useAction } from 'components/v3/utils';
import { toaster } from 'components/v3/Toast';
import { CardHeader, CardButtons } from 'components/v3/ui/card';
import { isSameDay } from 'date-fns';
import { AccountSwitcherRedirect } from 'pages/AccountSwitcherRedirect';

const CURRENT_VERSION = '202';
let currentDate = new Date();
const handleWindowFocus = () => {
  if (!isSameDay(currentDate, new Date())) {
    currentDate = new Date();
    window.location.reload();
  }
};

export const Content = styled.main`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 100vh;
  background-color: ${({ theme }) => theme.background};
`;

const isPublic = location =>
  location && location.pathname && location.pathname.startsWith('/public/');

let lastCurrentVersionCheckTime = 0;
const shouldCheckVersion = () =>
  lastCurrentVersionCheckTime + 5 * 60 * 1000 < new Date().getTime();

const App = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const action = useAction();

  const pathname = location && location.pathname;
  const isPublicPage = isPublic(location);

  const authState = useSelector(MeSelector.selectAuthState, isEqual);
  const { isAuthenticated, needsFetchCurrentAccount, currentAccountId } =
    authState;
  const currentAccountIdRef = useRef();
  if (currentAccountId) {
    currentAccountIdRef.current = currentAccountId;
  }

  useEffect(() => {
    if (isPublicPage || !shouldCheckVersion()) {
      return;
    }
    lastCurrentVersionCheckTime = new Date().getTime();
    action(() =>
      AppVersionApi.getAppVersions({ currentVersion: CURRENT_VERSION })
    )
      .onSuccess(newVersions => {
        if (newVersions?.data?.length > 0) {
          toaster(
            <Fragment>
              <CardHeader
                title="Nouvelle version !"
                subtitle="Une nouvelle version de Roadmapper est disponible. Rafraichissez votre onglet pour en bénéficier"
              />
              <CardButtons
                positiveText="Rafraichir"
                neutralText="Plus tard"
                onButtonClick={({ name }) => {
                  if (name === 'positive') {
                    window.location.reload();
                  } else {
                    toaster.dismiss();
                  }
                }}
              />
            </Fragment>,
            {
              autoClose: false,
              closeButton: false,
              closeOnClick: false,
              draggable: false,
              position: 'bottom-center'
            }
          );
        }
      })
      .onError(() => (lastCurrentVersionCheckTime = 0));
  }, [action, pathname, isPublicPage]);

  useEffect(() => {
    if (isAuthenticated) {
      const fetchInitialData = async () => {
        await Promise.all[
          (dispatch(AccountAction.getAccounts()), dispatch(MeAction.getMe()))
        ];
      };
      fetchInitialData();
    } else if (!isPublicPage) {
      let redirect = window.location.pathname;
      if (window.location.pathname?.includes('/accounts/')) {
        redirect = window.location.pathname;
      } else if (currentAccountIdRef.current) {
        redirect = `accounts/${currentAccountIdRef.current}${window.location.pathname}`;
      }

      history.replace({
        pathname: '/connexion',
        search: redirect
          ? `redirect=${redirect}${window.location.search || ''}`
          : ''
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isPublicPage]);

  useEffect(() => {
    if (needsFetchCurrentAccount) {
      dispatch(AccountAction.getAccount(currentAccountId));
      dispatch(MeAction.getMe());
    }
  }, [needsFetchCurrentAccount, currentAccountId, dispatch]);

  useEffect(() => {
    window.onfocus = handleWindowFocus;
    return () => {
      window.onfocus = undefined;
    };
  }, []);

  const memoPublic = useMemo(
    () => (
      <Route path="/public/:accountName/deviations">
        <Deviations />
      </Route>
    ),
    []
  );

  const { isMeLoading, isLoading } = useSelector(AccountSelector.selectLoading);
  if (!isPublicPage && (isMeLoading || isLoading)) return null;

  return (
    <ConfigProvider>
      <Content>
        <Switch>
          <Route path="/accounts/:accountId/:path*">
            <AccountSwitcherRedirect />
          </Route>
          <Navbar isPublicPage={isPublicPage}>{memoPublic}</Navbar>
        </Switch>
      </Content>
    </ConfigProvider>
  );
};

export default App;

import React, { useCallback, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import * as Yup from 'yup';
import { EntityModal } from 'components/v3/Modal';
import {
  TextInputField,
  ColorField,
  CheckboxField,
  ERRORS
} from 'components/v3/Form';
import { Grid, GridItem } from 'components/v3/ui';
import {
  ActivitySelector,
  ActivityAction,
  activityAsValues
} from 'features/ProjectKindsActivities';
import { stringToColor } from 'utils';
import { permissions, useAccountConfig } from 'config';

const validationSchema = Yup.object().shape({
  displayName: Yup.string().required(ERRORS.REQUIRED)
});

const valuesDependencies = {
  color: {
    modifier: (values, form) => {
      if (!form.status?.color) {
        return stringToColor(values.displayName);
      } else {
        return values.color;
      }
    },
    triggers: ['displayName']
  }
};

const ActivityModal = ({
  onRequestClose,
  onSubmitSuccess,
  activity: propsActivity,
  projectKind,
  ...props
}) => {
  const { hasPermission } = useAccountConfig();
  const dispatch = useDispatch();
  const { params } = useRouteMatch();
  let { activityId } = params;
  if (propsActivity) {
    activityId = propsActivity.id;
  }
  const isNew = !Boolean(activityId);

  const selectActivity = useMemo(
    () => state => ActivitySelector.selectActivity(state, { activityId }),
    [activityId]
  );

  let activity = useSelector(selectActivity, isEqual);
  if (!activity) {
    activity = propsActivity;
  }

  const createRequest = useCallback(
    payload => dispatch(ActivityAction.createActivity({ payload })),
    [dispatch]
  );

  const fetchRequest = useCallback(
    () => dispatch(ActivityAction.getActivity({ id: activityId })),
    [activityId, dispatch]
  );

  const updateRequest = useCallback(
    (id, payload) => dispatch(ActivityAction.updateActivity({ id, payload })),
    [dispatch]
  );

  const deleteRequest = useCallback(
    id => dispatch(ActivityAction.deleteActivity({ id })),
    [dispatch]
  );

  const canEdit = hasPermission([
    permissions.RESOURCES_CONFIG,
    isNew ? 'activity.create' : 'activity.update'
  ]);
  const canDelete = hasPermission([
    permissions.RESOURCES_CONFIG,
    'activity.delete'
  ]);

  return (
    <EntityModal
      isOpen
      size="small"
      onRequestClose={onRequestClose}
      title={isNew ? 'Nouvelle activité' : "Édition du l'activité"}
      subtitle={`Type de projet: ${projectKind?.displayName}`}
      id={activityId}
      model="activity"
      entity={
        activity || {
          projectKind
        }
      }
      initialStatus={{
        color: Boolean(activity?.id)
      }}
      validationSchema={validationSchema}
      entityAsValue={activityAsValues}
      fetchRequest={fetchRequest}
      createRequest={createRequest}
      updateRequest={updateRequest}
      deleteRequest={canDelete ? deleteRequest : undefined}
      onSubmitSuccess={onSubmitSuccess}
      autoSubmit={false}
      valuesDependencies={valuesDependencies}
      readOnly={!canEdit}
      {...props}
    >
      {({ setStatus, status }) => (
        <Grid spacing={16} padded={false}>
          <GridItem width="100%">
            <TextInputField
              name="displayName"
              label="Nom de l'activité"
              required
              readOnly={!canEdit}
              iconLeading={
                <ColorField
                  name="color"
                  onColorSelected={color => setStatus({ ...status, color })}
                />
              }
              leadingIconClickable
            />
          </GridItem>
          <GridItem width="100%">
            <CheckboxField
              name="administrative"
              readOnly={!canEdit}
              label="Activité à titre indicative, il n'y a pas de technicien / machine associé"
              hint="Activité à titre indicative, il n'y a pas de technicien / machine associé"
            />
          </GridItem>
        </Grid>
      )}
    </EntityModal>
  );
};

export default ActivityModal;

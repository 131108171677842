import { createDeepEqualSelector, getProps } from 'common/selector';
import { getState, selectRelations } from 'common/selector';

export const getInputMaterials = state => state.inputMaterials;

export const selectInputMaterials = createDeepEqualSelector(
  [getInputMaterials, getState],
  ({ inputMaterialsIds, inputMaterialById }, state) =>
    inputMaterialsIds
      ?.map(id =>
        selectRelations('inputMaterial', inputMaterialById[id], state)
      )
      .filter(Boolean) ?? []
);

export const selectInputMaterial = createDeepEqualSelector(
  [getInputMaterials, getProps, getState],
  ({ inputMaterialById }, { id }, state) =>
    selectRelations('inputMaterial', inputMaterialById[id], state)
);

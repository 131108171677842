import React, { useCallback, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import * as Yup from 'yup';
import { EntityModal } from 'components/v3/Modal';
import { TextInputField, ColorField, ERRORS } from 'components/v3/Form';
import { Grid, GridItem } from 'components/v3/ui';
import { GroupSelector, GroupAction, groupAsValues } from 'features/Groups';
import { stringToColor } from 'utils';
import { permissions, useAccountConfig } from 'config';
import VouchersBox from 'features/Vouchers/components/VouchersBox';
import { ExportGroupMaterialsMenu } from '../../../Materials/components/ExportGroupMaterialsMenu';

const validationSchema = Yup.object().shape({
  displayName: Yup.string().required(ERRORS.REQUIRED)
});

const valuesDependencies = {
  color: {
    modifier: (values, form) => {
      if (!form.status?.color) {
        return stringToColor(values.displayName);
      } else {
        return values.color;
      }
    },
    triggers: ['displayName']
  }
};

const GroupModal = ({
  onRequestClose,
  onSubmitSuccess,
  group: propsGroup,
  ...props
}) => {
  const { hasPermission } = useAccountConfig();
  const dispatch = useDispatch();
  const { params } = useRouteMatch();
  let { groupId } = params;
  if (propsGroup) {
    groupId = propsGroup.id;
  }
  const isNew = !Boolean(groupId);

  const selectGroup = useMemo(
    () => state => GroupSelector.selectGroup(state, { groupId }),
    [groupId]
  );

  let group = useSelector(selectGroup, isEqual);
  if (!group) {
    group = propsGroup;
  }

  const createRequest = useCallback(
    payload => dispatch(GroupAction.createGroup({ payload })),
    [dispatch]
  );

  const fetchRequest = useCallback(
    () => dispatch(GroupAction.getGroup({ id: groupId })),
    [groupId, dispatch]
  );

  const updateRequest = useCallback(
    (id, payload) => dispatch(GroupAction.updateGroup({ id, payload })),
    [dispatch]
  );

  const deleteRequest = useCallback(
    id => dispatch(GroupAction.deleteGroup({ id })),
    [dispatch]
  );

  const canEdit = hasPermission([
    permissions.RESOURCES_CONFIG,
    isNew ? 'group.create' : 'group.update'
  ]);

  const canDelete = hasPermission([
    permissions.RESOURCES_CONFIG,
    'group.delete'
  ]);

  return (
    <EntityModal
      isOpen
      size="normal"
      onRequestClose={onRequestClose}
      title={isNew ? 'Nouveau groupe' : 'Édition du groupe'}
      id={groupId}
      model="group"
      entity={group}
      initialStatus={{
        color: Boolean(group?.id)
      }}
      validationSchema={validationSchema}
      entityAsValue={groupAsValues}
      fetchRequest={fetchRequest}
      createRequest={createRequest}
      updateRequest={updateRequest}
      deleteRequest={canDelete ? deleteRequest : undefined}
      onSubmitSuccess={onSubmitSuccess}
      autoSubmit={false}
      readOnly={!canEdit}
      valuesDependencies={valuesDependencies}
      headerRightContent={
        <div style={{ paddingRight: 16 }}>
          <ExportGroupMaterialsMenu groupId={groupId} />
        </div>
      }
      {...props}
    >
      {({ setStatus, status }) => (
        <Grid spacing={16} padded={false}>
          <GridItem width="100%">
            <TextInputField
              name="displayName"
              label="Nom du groupe"
              required
              readOnly={!canEdit}
              iconLeading={
                <ColorField
                  name="color"
                  onColorSelected={color => setStatus({ ...status, color })}
                />
              }
              leadingIconClickable
            />
          </GridItem>
          {group?.id && (
            <GridItem width="100%">
              <VouchersBox
                vouchers={group.vouchers}
                creatable
                flat
                parentId={group.id}
                parentType="Group"
                minHeight={200}
                useWindow={false}
              />
            </GridItem>
          )}
        </Grid>
      )}
    </EntityModal>
  );
};

export default GroupModal;

import React, {
  useCallback,
  useRef,
  forwardRef,
  useMemo,
  useImperativeHandle,
  useEffect
} from 'react';
import styled from 'styled-components';
import { VariableSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import RawSpinner from 'react-md-spinner';
import Scroller from '../Scroller';
import { Text, Container as RawContainer } from '../ui';
import { List as RawList, ListItem } from '../ui/list';

const getId = item => (item.id == null ? item.value : item.id);

const SpinnerWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Spinner = styled.div`
  color: ${({ theme }) => theme.primaryLight};
`.withComponent(RawSpinner);

const CenterText = styled(Text)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-height: 100px;
  margin: 16px;
  z-index: 1;
`;

export const Container = styled(RawContainer).attrs(
  ({ minHeight, maxHeight }) => ({
    style: {
      minHeight,
      maxHeight
    }
  })
)`
  height: 100%;
  padding-right: 16px;
  width: calc(100% + 16px);
`;

const WindowItem = sortableElement(
  ({
    style,
    spacing,
    scrollOverlay,
    width,
    setSize,
    item,
    itemIndex,
    rowRenderer,
    isFirst,
    isLast
  }) => {
    const root = useRef();

    useEffect(() => {
      setSize(itemIndex, root.current.getBoundingClientRect().height);
    }, [width, setSize, itemIndex]);

    return (
      <div
        ref={root}
        style={{
          ...style,
          // width: undefined,
          // minWidth: '100%',
          width: scrollOverlay ? 'calc(100% - 12px)' : '100%',
          height: undefined,
          paddingTop: !isFirst && spacing ? spacing / 2 : 0,
          paddingBottom: spacing ? spacing / 2 : 0
        }}
      >
        {rowRenderer({ item, isFirst, isLast, index: itemIndex })}
      </div>
    );
  }
);

const Item = React.memo(
  sortableElement(({ item, itemIndex, rowRenderer, isFirst, isLast }) => {
    return (
      <ListItem>
        {rowRenderer({ item, index: itemIndex, isFirst, isLast })}
      </ListItem>
    );
  })
);

const SimpleList = React.memo(
  ({
    items,
    spacing,
    rowRenderer,
    isSortable,
    hasMoreItems,
    loadMoreItems,
    loadMoreThreshold,
    onVerticalScroll,
    onHorizontalScroll,
    maxHeight,
    minHeight,
    isLoading,
    centerMessage
  }) => {
    if (maxHeight) {
      return (
        <Scroller
          onVerticalScroll={onVerticalScroll}
          onHorizontalScroll={onHorizontalScroll}
          hasMoreItems={hasMoreItems}
          loadMoreItems={loadMoreItems}
          loadMoreThreshold={loadMoreThreshold}
          autoHeight
          autoHeightMax={maxHeight}
          autoHeightMin={minHeight}
        >
          <RawList spacing={spacing}>
            {isLoading && (
              <SpinnerWrapper>
                <Spinner singleColor="currentColor" />
              </SpinnerWrapper>
            )}
            {centerMessage && (
              <CenterText variant="caption">{centerMessage}</CenterText>
            )}
            {items.map((item, index) => (
              <Item
                spacing={spacing}
                key={getId(item)}
                item={item}
                index={index}
                itemIndex={index}
                disabled={!isSortable}
                isSortable={isSortable}
                rowRenderer={rowRenderer}
                isFirst={index === 0}
                isLast={items.length - 1 === index}
              />
            ))}
          </RawList>
        </Scroller>
      );
    } else {
      return (
        <RawList spacing={spacing} minHeight={minHeight}>
          {isLoading && (
            <SpinnerWrapper>
              <Spinner singleColor="currentColor" />
            </SpinnerWrapper>
          )}
          {centerMessage && (
            <CenterText variant="caption">{centerMessage}</CenterText>
          )}
          {items.map((item, index) => (
            <Item
              spacing={spacing}
              key={getId(item)}
              item={item}
              index={index}
              itemIndex={index}
              disabled={!isSortable}
              isSortable={isSortable}
              rowRenderer={rowRenderer}
              isFirst={index === 0}
              isLast={items.length - 1 === index}
            />
          ))}
        </RawList>
      );
    }
  }
);

const WindowRow = ({ index, style, data }) => {
  const {
    rowRenderer,
    spacing,
    scrollOverlay,
    isSortable,
    width,
    setSize,
    items
  } = data;
  const item = items[index];
  return (
    <WindowItem
      key={getId(item)}
      spacing={spacing}
      scrollOverlay={scrollOverlay}
      disabled={!isSortable}
      index={index}
      item={item}
      itemIndex={index}
      style={style}
      width={width}
      setSize={setSize}
      rowRenderer={rowRenderer}
      isSortable={isSortable}
      isFirst={index === 0}
      isLast={items.length - 1 === index}
    />
  );
};

const WindowedList = ({
  forwardedRef,
  items,
  spacing,
  scrollOverlay,
  rowRenderer,
  estimatedItemSize,
  isSortable,
  hasMoreItems,
  loadMoreItems,
  loadMoreThreshold,
  innerElementType,
  minRows,
  maxHeight,
  onHorizontalScroll,
  onVerticalScroll,
  isLoading,
  centerMessage
}) => {
  const listRef = useRef();
  const outerRef = useRef();
  const sizeMap = useRef({});

  useImperativeHandle(
    forwardedRef,
    () => ({
      scroller: () => outerRef.current
    }),
    []
  );

  const setSize = useCallback(
    (index, size) => {
      const item = items[index];
      if (!item) {
        return;
      }
      sizeMap.current = {
        ...sizeMap.current,
        [getId(item)]: size
      };
      if (listRef.current) {
        listRef.current.resetAfterIndex(index);
      }
    },
    [items]
  );

  const getItemSize = useCallback(
    index => {
      const item = items[index];
      if (!item) {
        return estimatedItemSize;
      }
      let size = sizeMap.current[getId(item)];
      if (size) {
        return size;
      }
      if (index === 0 && spacing) {
        return estimatedItemSize - spacing / 2;
      }
      if (item.tableKind === 'section') {
        return 30 + parseInt(spacing);
      } else {
        return estimatedItemSize;
      }
    },
    [estimatedItemSize, spacing, items]
  );

  const hasMoreItemsRef = useRef();
  hasMoreItemsRef.current = hasMoreItems;
  const loadMoreItemsRef = useRef();
  loadMoreItemsRef.current = loadMoreItems;
  const handleLoadMore = useCallback(() => {
    if (loadMoreItemsRef.current && hasMoreItemsRef.current) {
      loadMoreItemsRef.current();
    }
  }, []);

  const onHorizontalScrollRef = useRef();
  onHorizontalScrollRef.current = onHorizontalScroll;
  const handleOnHorizontalScroll = useCallback((...args) => {
    if (onHorizontalScrollRef.current) {
      onHorizontalScrollRef.current(...args);
    }
  }, []);

  const onVerticalScrollRef = useRef();
  onVerticalScrollRef.current = onVerticalScroll;
  const handleOnVerticalScroll = useCallback((...args) => {
    if (onVerticalScrollRef.current) {
      onVerticalScrollRef.current(...args);
    }
  }, []);

  const ScrollerVirtualList = useMemo(
    () =>
      forwardRef((props, forwardedRef) => (
        <Scroller
          {...props}
          ref={forwardedRef}
          onVerticalScroll={handleOnVerticalScroll}
          onHorizontalScroll={handleOnHorizontalScroll}
          hasMoreItems={true}
          loadMoreItems={handleLoadMore}
          loadMoreThreshold={loadMoreThreshold}
        />
      )),
    [
      handleOnHorizontalScroll,
      handleOnVerticalScroll,
      loadMoreThreshold,
      handleLoadMore
    ]
  );

  let minHeight = undefined;
  if (minRows) {
    minHeight = Array(minRows)
      .fill()
      .map((_, i) => getItemSize(i) || 0);
    minHeight = minHeight.reduce((a, b) => a + b, 0);
  }

  if (maxHeight) {
    if (!minRows || items.length > minRows) {
      minHeight = Array(Math.min(50, items.length))
        .fill()
        .map((_, i) => getItemSize(i) || 0);
      minHeight = Math.min(
        maxHeight,
        minHeight.reduce((a, b) => a + b, 2)
      );
    }
  }

  const itemData = useMemo(
    () => ({
      spacing,
      scrollOverlay,
      disabled: !isSortable,
      setSize,
      rowRenderer,
      isSortable,
      items
    }),
    [items, spacing, isSortable, scrollOverlay, rowRenderer, setSize]
  );

  return (
    <Container minHeight={minHeight} maxHeight={maxHeight}>
      {isLoading && !(items?.length > 0) && (
        <SpinnerWrapper>
          <Spinner singleColor="currentColor" />
        </SpinnerWrapper>
      )}
      {centerMessage && (
        <CenterText variant="caption">{centerMessage}</CenterText>
      )}
      <AutoSizer>
        {({ height, width }) => (
          <VariableSizeList
            ref={listRef}
            height={height}
            itemCount={(items || []).length}
            itemSize={getItemSize}
            width={width}
            estimatedItemSize={estimatedItemSize}
            outerElementType={ScrollerVirtualList}
            outerRef={outerRef}
            innerElementType={innerElementType}
            itemData={itemData}
          >
            {WindowRow}
          </VariableSizeList>
        )}
      </AutoSizer>
    </Container>
  );
};

const SortableWindowedList = sortableContainer(WindowedList);

const SortableList = sortableContainer(SimpleList);

const List = forwardRef(
  (
    {
      items,
      rowRenderer,
      useWindow = true,
      spacing = 0,
      scrollOverlay = false,
      estimatedItemSize = 54,
      hasMoreItems,
      loadMoreItems,
      loadMoreThreshold = 500,
      innerElementType,
      minRows = undefined,
      maxHeight = undefined,
      minHeight,
      onVerticalScroll,
      onHorizontalScroll,
      isSortable,
      useDragHandle,
      onSortChanged,
      isLoading,
      centerMessage
    },
    ref
  ) => {
    const itemsRef = useRef();
    itemsRef.current = items;
    const onSortChangedRef = useRef();
    onSortChangedRef.current = onSortChanged;

    const onSortEnd = useCallback(({ oldIndex, newIndex }) => {
      if (!onSortChangedRef.current || oldIndex === newIndex) {
        return;
      }
      const newItems = arrayMove(itemsRef.current, oldIndex, newIndex).map(
        (i, index) => ({
          ...i,
          position: index
        })
      );
      onSortChangedRef.current(newItems, newItems[newIndex]);
    }, []);

    const Component = useWindow ? SortableWindowedList : SortableList;

    return (
      <Component
        forwardedRef={ref}
        items={items}
        spacing={spacing}
        scrollOverlay={scrollOverlay}
        innerElementType={innerElementType}
        estimatedItemSize={estimatedItemSize}
        maxHeight={maxHeight}
        minHeight={minHeight}
        minRows={minRows}
        rowRenderer={rowRenderer}
        hasMoreItems={hasMoreItems}
        loadMoreItems={loadMoreItems}
        loadMoreThreshold={loadMoreThreshold}
        onHorizontalScroll={onHorizontalScroll}
        onVerticalScroll={onVerticalScroll}
        disabled={!isSortable}
        useDragHandle={useDragHandle && isSortable}
        helperClass="sortableHelper"
        lockAxis="y"
        isSortable={isSortable}
        onSortEnd={onSortEnd}
        isLoading={isLoading}
        centerMessage={centerMessage}
      />
    );
  }
);

export default React.memo(List);

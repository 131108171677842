import React, { useState } from 'react';
import Box, { BoxHeader, BoxFilter } from 'components/v3/Box';
import ProjectList from './List';
import GroupSelect from 'features/Groups/components/v3/Select';

const FILTERS = [
  {
    kind: 'date',
    key: 'date',
    value: null,
    asQueryParam: value => ({
      'between[start_at]': value?.startAt,
      'between[end_at]': value?.endAt
    }),
    props: {
      mode: 'range',
      collapsedIfBlank: true
    }
  },
  {
    kind: 'select',
    key: 'groups',
    component: GroupSelect,
    value: [],
    asQueryParam: value => ({
      groupIdIn: value ? value.map(v => v.id) : undefined
    }),
    props: {
      multi: true,
      inputCreatable: false,
      optionsCheckable: true,
      limitValues: 2,
      style: {
        minWidth: 120,
        maxWidth: 360
      }
    }
  }
];

const ProjectBox = ({
  fetchAction,
  selector,
  params,
  marged,
  padded,
  ...props
}) => {
  const [count, setCount] = useState(null);
  const [filters, setFilters] = useState(FILTERS);

  return (
    <Box marged={marged} padded={padded}>
      <BoxHeader
        bigPadded
        title={`Projets${count != null ? ` (${count})` : ''}`}
      >
        <BoxFilter
          searchQuery="byQuery"
          filters={filters}
          onChanges={setFilters}
        />
      </BoxHeader>
      <ProjectList
        rowColor="transparent"
        selector={selector}
        action={fetchAction}
        params={params}
        filters={filters}
        onCountChanged={setCount}
        headerVariant="transparent"
        minRows={5}
        {...props}
      />
    </Box>
  );
};

export default ProjectBox;

export {
  HolidayApi,
  HolidayAction,
  HolidayReducer,
  HolidaySelector
} from './data';

export const holidayAsValues = holiday => ({
  id: holiday?.id || null,
  kind: holiday?.kind,
  users: holiday?.users || [],
  startAt: holiday?.startAt || '',
  endAt: holiday?.endAt || '',
  description: holiday?.description || ''
});

export const holidayKindAsOption = kind => {
  if (Array.isArray(kind)) {
    return kind.map(holidayKindAsOption);
  } else if (kind) {
    return {
      value: kind.value,
      color: kind.color,
      label: kind.displayName || kind.label,
      original: kind
    };
  } else {
    return null;
  }
};

import { omit } from 'lodash';
import { mergeJsonApi } from 'common/reducers';
import isSameIds from 'utils/idUtils';

const initialState = {
  projectKindById: {},
  projectKindsIds: []
};

const projectKinds = (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case 'NEW_PROJECT_KIND_SUCCESS':
      return {
        ...mergeJsonApi('projectKind', state, action),
        projectKindsIds: [payload.data.id, ...state.projectKindsIds]
      };
    case 'DELETE_PROJECT_KIND_SUCCESS':
      return {
        ...state,
        projectKindById: omit(state.projectKindById, payload),
        projectKindsIds: state.projectKindsIds.filter(
          id => !isSameIds(id, payload)
        )
      };
    case 'LOGOUT':
    case 'SELECT_ACCOUNT':
      return initialState;
    default:
      return mergeJsonApi('projectKind', state, action);
  }
};

export default projectKinds;

import { createAction } from 'redux-actions';
import omit from 'lodash/omit';
import * as Api from './api';
import { prepareAttributes, shouldFetch } from 'common/actions';

const preparePayload = payload => prepareAttributes(payload, 'address');

const organizationsLoading = createAction('ORGANIZATIONS_LOADING');
const organizationsSuccess = createAction('ORGANIZATIONS_SUCCESS');
const searchOrganizationsSuccess = createAction('SEARCH_ORGANIZATIONS_SUCCESS');
const organizationsError = createAction('ORGANIZATIONS_ERROR');
export const getOrganizations = payload => async (dispatch, getState) => {
  const { useCache, isSearchRequest, ...params } = payload || {};
  const state = getState();
  const action = isSearchRequest
    ? 'SEARCH_ORGANIZATIONS_SUCCESS'
    : 'ORGANIZATIONS_SUCCESS';
  if (!useCache || shouldFetch('organization', params, state, action)) {
    return fetchOrganizations(params, isSearchRequest, dispatch);
  } else {
    return {
      fromCache: true,
      isLoading: getState().organizations.isLoading
    };
  }
};
const fetchOrganizations = async (payload, isSearchRequest, dispatch) => {
  if (!isSearchRequest) {
    dispatch(organizationsLoading());
  }
  try {
    const result = await Api.getOrganizations(payload);
    if (isSearchRequest) {
      dispatch(
        searchOrganizationsSuccess({
          ...result,
          requestParams: omit(payload, 'cancelToken')
        })
      );
    } else {
      dispatch(
        organizationsSuccess({
          ...result,
          requestParams: omit(payload, 'cancelToken')
        })
      );
    }
    return result;
  } catch (error) {
    dispatch(organizationsError(error));
    throw error;
  }
};

const receiveCreateOrganization = createAction('NEW_ORGANIZATION_SUCCESS');
export const createOrganization = ({
  payload,
  ...config
}) => async dispatch => {
  const result = await Api.createOrganization(preparePayload(payload), config);
  dispatch(receiveCreateOrganization(result));
  return result;
};

const receiveOrganization = createAction('ORGANIZATION_SUCCESS');
export const getOrganization = ({ id, ...payload }) => async dispatch => {
  const result = await Api.getOrganization(id, payload);
  dispatch(receiveOrganization(result));
  return result;
};

const receiveUpdateOrganization = createAction('UPDATE_ORGANIZATION_SUCCESS');
export const updateOrganization = ({
  id,
  payload: organization,
  ...config
}) => async dispatch => {
  const result = await Api.putOrganization(
    id,
    preparePayload(organization),
    config
  );
  dispatch(receiveUpdateOrganization(result));
  return result;
};

const receiveDeleteOrganization = createAction('DELETE_ORGANIZATION_SUCCESS');
export const deleteOrganization = ({ id, ...config }) => async dispatch => {
  const response = await Api.deleteOrganization(id, config);
  dispatch(receiveDeleteOrganization(id));
  return response;
};

const receiveOrganizationProjects = createAction(
  'ORGANIZATION_PROJECTS_SUCCESS'
);
export const getProjects = ({
  organizationId,
  ...payload
}) => async dispatch => {
  const result = await Api.getProjects({
    ...payload,
    organizationIdEq: organizationId
  });
  dispatch(
    receiveOrganizationProjects({
      ...result,
      data: result.data.map(project => ({
        ...project,
        relationships: {
          ...project.relationships,
          organization: {
            data: {
              id: `${organizationId}`,
              type: 'organization'
            }
          }
        }
      }))
    })
  );
  return result;
};
const receiveOrganizationContacts = createAction(
  'ORGANIZATION_CONTACTS_SUCCESS'
);
export const getContacts = ({
  organizationId,
  ...payload
}) => async dispatch => {
  const result = await Api.getContacts({
    ...payload,
    organizationIdEq: organizationId
  });
  dispatch(receiveOrganizationContacts(result));
  return result;
};

import { get, put, post, destroy } from 'common/api';

export const getPeriods = params => get('/v5/exceptional_periods', params);

export const createPeriod = (period, config) =>
  post('/v5/exceptional_periods', { period }, config);

export const getPeriod = (id, config) =>
  get(`/v5/exceptional_periods/${id}`, config);

export const putPeriod = (id, period, config) =>
  put(`/v5/exceptional_periods/${id}`, { period }, config);

export const deletePeriod = (id, config) =>
  destroy(`/v5/exceptional_periods/${id}`, config);

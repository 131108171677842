import React, { Fragment, useCallback } from 'react';
import { Text } from 'components/v3/ui';
import Table from 'components/v3/List/Table';
import AttachmentIcon from './Icon';
import { authenticateUrl } from 'utils';

const COLUMNS = [
  {
    Header: ' ',
    accessor: 'fileType',
    width: 56,
    minWidth: 56,
    textAlign: 'center',
    Cell: ({ item }) => <AttachmentIcon attachment={item} size={24} />
  },
  {
    Header: ' ',
    accessor: 'lastName',
    width: 'calc(100% - 56px)',
    Cell: ({ item }) => <Text variant="small">{item.name}</Text>
  }
];

const AttachmentList = ({ columns = COLUMNS, attachments, ...props }) => {
  const detailLinkFormatter = useCallback(
    item => ({
      external: true,
      url: item.isLocal ? item.preview : authenticateUrl(item.fileUrl)
    }),
    []
  );

  return (
    <Fragment>
      <Table
        columns={COLUMNS}
        items={attachments}
        linkFormatter={detailLinkFormatter}
        {...props}
      />
    </Fragment>
  );
};

export default AttachmentList;

import React, { Fragment, useEffect, useCallback } from 'react';
import {
  useRouteMatch,
  Route,
  useHistory,
  useLocation
} from 'react-router-dom';
import { Text } from 'components/v3/ui';
import Table from 'components/v3/List/Table';
import { useListFetcher } from 'components/v3/utils';
import ProjectKindModal from './Modal';

const columns = [
  {
    Header: 'Nom',
    accessor: 'displayName',
    width: '100%',
    Cell: ({ item }) => <Text variant="small">{item.displayName}</Text>
  }
];

const ProjectKindList = ({
  selector,
  action,
  options = {
    fetchOnMount: false,
    useLocalState: true
  },
  params = {
    sort: 'display_name'
  },
  perPage = 25,
  filters,
  onCountChanged,
  detailUrlPrefix = '/types-de-projet',
  ...props
}) => {
  const { path, url } = useRouteMatch();
  const history = useHistory();
  const location = useLocation();

  const [projectKinds, paginationHelper, state] = useListFetcher({
    selector,
    action,
    options,
    params,
    perPage,
    filters
  });

  const detailLinkFormatter = useCallback(
    item => `${path}${detailUrlPrefix}/${item.id}`,
    [path, detailUrlPrefix]
  );

  useEffect(() => {
    if (paginationHelper.total != null && onCountChanged) {
      onCountChanged(paginationHelper.total);
    }
  }, [paginationHelper.total, onCountChanged]);

  return (
    <Fragment>
      <Table
        columns={columns}
        items={projectKinds}
        isLoading={state.isLoading}
        linkFormatter={detailLinkFormatter}
        centerMessage={state.errorMessage}
        {...paginationHelper}
        {...props}
      />
      <Route
        path={[
          `${path}${detailUrlPrefix}/nouveau-type-de-projet`,
          `${path}${detailUrlPrefix}/:projectKindId`
        ]}
      >
        <ProjectKindModal
          onRequestClose={() => {
            if (location.key) {
              history.goBack();
            } else {
              history.replace(url);
            }
          }}
        />
      </Route>
    </Fragment>
  );
};

export default ProjectKindList;

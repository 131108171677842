import React, { useState } from 'react';
import SubHeader from 'components/v3/SubHeader';
import { FilterBar } from 'components/v3/Filters';
import { Container, Title } from 'components/v3/ui';
import { GpsBoxSelector, GpsBoxAction } from 'features/GpsBoxes';
import GpsBoxList from 'features/GpsBoxes/components/v3/List';

const defaultParams = { sort: 'serial' };

const GpsBoxes = () => {
  const [count, setCount] = useState();
  const [filters, setFilters] = useState();

  return (
    <Container vertical>
      <SubHeader>
        <Title>{`GPS${count != null ? ` (${count})` : ''}`}</Title>
      </SubHeader>
      <Container vertical huge padded>
        <FilterBar
          big
          searchQuery="serialOrMachineDisplayNameContAny"
          onChanges={setFilters}
        />
        <GpsBoxList
          spacing={8}
          headerVariant="transparent"
          selector={GpsBoxSelector.selectGpsBoxes}
          action={GpsBoxAction.getGpsBoxes}
          onCountChanged={setCount}
          params={defaultParams}
          filters={filters}
          perPage={50}
          detailUrlPrefix=""
        />
      </Container>
    </Container>
  );
};

export default GpsBoxes;

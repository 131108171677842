import React from 'react';
import { useRouteMatch, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { MdAddCircleOutline, MdRemoveCircleOutline } from 'react-icons/md';
import {
  Container as InputLayoutContainer,
  FieldGroup as InputLayoutBackground
} from 'components/v3/InputLayout/InputLayout';
import { CurrentOption } from 'components/v3/Select/Select';
import Scroller from 'components/v3/Scroller';
import Avatar, { AvatarWrapper } from 'components/v3/Avatar';
import { Container as RawContainer, Icon, Text } from 'components/v3/ui';
import { UserField } from 'features/Users/components/v3/UserSelect';
import Tooltip from 'components/v3/Tooltip';
import { DETAIL_SIDEBAR_WIDTH, fontSize, spacing, theme } from 'ui';
import { SidebarMenuItem, SidebarMenuLink, MainMenu } from 'ui/sidebar';
import { features, useAccountConfig } from 'config';

const Container = styled(RawContainer)`
  align-items: center;
`;

const UserFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 32px;
  padding-left: 8px;
  padding-right: 8px;
  margin-bottom: 24px;
  width: 100%;

  ${InputLayoutContainer} {
    margin-top: 8px;
  }

  ${InputLayoutBackground} {
    border-top-left-radius: ${({ theme }) => theme.borderInputRadius};
    border-top-right-radius: ${({ theme }) => theme.borderInputRadius};
    background: none;
    background-color: transparent;
  }
  &:hover,
  &:focus-within {
    ${InputLayoutBackground} {
      background: ${({ theme }) => theme.primary} !important;
    }
  }
  * > * {
    color: ${({ theme }) => theme.textLight};
  }
  * > ${CurrentOption} > * {
    font-size: ${fontSize(20)} !important;
    font-weight: 700 !important;
  }
  * > label {
    color: ${({ theme }) => theme.textLightSecondary};
  }

  ${AvatarWrapper} {
    box-shadow: ${({ theme }) => theme.boxShadow};
  }
`;

export const HorizontalLine = styled.div`
  margin: ${spacing(2)} 0px;
  width: 60%;
  height: 1px;
  background: ${({ theme }) => theme.textLightSecondary};
  overflow: hidden;
  opacity: 0.7;
  background: linear-gradient(
    0.25turn,
    rgba(0, 0, 0, 0),
    rgba(255, 255, 255, 0.84),
    rgba(0, 0, 0, 0)
  );
`;

const AmountWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${({ container }) =>
    container &&
    css`
      width: 100%;
      padding: 0px ${spacing(0.75)};
      justify-content: space-between;
    `}
  i {
    margin-right: 4px;
  }
`;

const AmountTexts = styled.div`
  display: flex;
  flex-direction: column;
`;

const Total = styled.div`
  color: ${({ theme, balance }) =>
    balance >= 0 ? theme.success : theme.error};
  text-align: center;
  font-size: ${fontSize(26)};
`.withComponent(Text);

const Wrapper = styled.aside`
  z-index: 2;
  box-shadow: ${({ theme }) => theme.shadow};
  background-color: ${({ theme }) => theme.primaryDark};
  width: ${DETAIL_SIDEBAR_WIDTH}px;
  min-width: ${DETAIL_SIDEBAR_WIDTH}px;
  * > ${MainMenu} {
    width: 100%;
  }
  @media screen and (max-width: 680px) {
    max-width: 60px;
    width: 60px;
    min-width: 60px;
    padding-top: 40px;

    ${MainMenu} {
      span {
        display: none;
      }
    }
    ${UserFieldWrapper}, ${HorizontalLine}, ${AmountWrapper}, ${Total} {
      display: none;
    }
  }
`;

const Sidebar = ({ values, project, isNew, menu }) => {
  const { pathname: currentPath } = useLocation();
  const { url } = useRouteMatch();
  const { hasFeature } = useAccountConfig();

  let balance =
    parseFloat(project?.estimatedRevenues) - parseFloat(project?.totalCost);
  if (!balance) {
    balance = 0;
  }
  return (
    <Wrapper>
      <Scroller>
        <Container vertical>
          <UserFieldWrapper>
            <Avatar size="giant" user={values.user} borderHidden />
            <UserField
              role={['conductor', 'admin']}
              label="Responsable"
              name="user"
              avatarHidden
              fitCurrentOptionWidth
              // variant={InputLayoutVariant.flat.normal}
            />
          </UserFieldWrapper>
          {hasFeature(features.ACCOUNTING) && (
            <>
              <Total currency balance={balance}>
                {balance}
              </Total>
              <HorizontalLine />
              <AmountWrapper container>
                <AmountWrapper>
                  <Icon size="medium">
                    <MdRemoveCircleOutline color={theme.warning} />
                  </Icon>
                  <AmountTexts>
                    <Text color="lightSecondary">Coût</Text>
                    <Text color="light" currency>
                      {parseFloat(project?.totalCost)}
                    </Text>
                  </AmountTexts>
                </AmountWrapper>
                <AmountWrapper>
                  <Icon size="medium">
                    <MdAddCircleOutline color={theme.success} />
                  </Icon>
                  <AmountTexts>
                    <Text color="lightSecondary">Revenus</Text>
                    <Text color="light" currency>
                      {parseFloat(project?.estimatedRevenues)}
                    </Text>
                  </AmountTexts>
                </AmountWrapper>
              </AmountWrapper>
            </>
          )}
          <HorizontalLine />
          <MainMenu>
            {menu.map(({ label, Icon, pathname, disabledIfNew }) => {
              const isMenuActive = currentPath.startsWith(`${url}/${pathname}`);
              const disabled = disabledIfNew && isNew;
              return (
                <Tooltip
                  key={label}
                  content={
                    disabled
                      ? 'Vous devez choisir un type de projet pour activer ce menu.'
                      : null
                  }
                >
                  <SidebarMenuItem hasAccentBorder="true">
                    <SidebarMenuLink
                      main="true"
                      isActive={() => isMenuActive}
                      to={`${url}/${pathname}`}
                      disabled={disabled}
                    >
                      {Icon && <Icon size={24} />}
                      <span>{label}</span>
                    </SidebarMenuLink>
                  </SidebarMenuItem>
                </Tooltip>
              );
            })}
          </MainMenu>
        </Container>
      </Scroller>
    </Wrapper>
  );
};

export default Sidebar;

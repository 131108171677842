import { createContext } from 'react';

const Context = createContext({
  accountId: 0,
  permissions: [],
  hasPermission: (permission, object) => false,
  features: [],
  hasFeature: () => false,
  translate: () => null,
  userRole: null
});

export const { Consumer, Provider } = Context;

export default Context;

import React, { useState } from 'react';
import { useRouteMatch, Route, Switch } from 'react-router-dom';
import SubHeader from 'components/v3/SubHeader';
import { Container } from 'components/v3/ui';
import Breadcrumb from 'components/v3/Breadcrumb';
import Deviations from './Deviations';
import DeviationDetail from './DeviationDetail';

const PublicDeviations = () => {
  const { path } = useRouteMatch();

  const [isLoading, setLoading] = useState();
  const [deviationsCount, setDeviationsCount] = useState(null);
  const [currentDeviation, setCurrentDeviation] = useState();

  return (
    <Container vertical>
      <SubHeader isLoading={isLoading}>
        <Breadcrumb
          links={[
            {
              name: `Deviations${
                deviationsCount != null ? ` (${deviationsCount})` : ''
              }`,
              path: '/public/:accountName/deviations'
            },
            {
              name: currentDeviation?.displayName || '',
              path: '/public/:accountName/deviations/:deviationId'
            }
          ]}
        />
      </SubHeader>
      <Switch>
        <Route exact path={path}>
          <Deviations
            onDeviationsCount={setDeviationsCount}
            onLoading={setLoading}
          />
        </Route>
        <Route exact path={`${path}/:deviationId`}>
          <DeviationDetail
            onDeviation={setCurrentDeviation}
            onLoading={setLoading}
          />
        </Route>
      </Switch>
    </Container>
  );
};

export default PublicDeviations;

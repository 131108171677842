import { get, put, post, destroy } from 'common/api';

export const getHolidays = params => get('/v5/holidays', params);

export const createHoliday = (holiday, config) =>
  post('/v5/holidays', { holiday }, config);

export const getHoliday = (id, config) => get(`/v5/holidays/${id}`, config);

export const putHoliday = (id, holiday, config) =>
  put(`/v5/holidays/${id}`, { holiday }, config);

export const deleteHoliday = (id, config) =>
  destroy(`/v5/holidays/${id}`, config);

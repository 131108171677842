import React from 'react';
import { TextInputLayout } from 'components/v3/InputLayout';
import { Grid, GridItem } from 'components/v3/ui';
import Card, { CardContent } from 'components/v3/ui/card';
import { parameterizedString } from 'utils';

const stringify = filter => {
  try {
    if (isValidJson(filter)) {
      return JSON.stringify(filter, null, 2);
    } else {
      return filter;
    }
  } catch {
    return filter;
  }
};

const parse = value => {
  try {
    return JSON.parse(value);
  } catch {
    return value;
  }
};

const isValidJson = filter => {
  if (!filter) {
    return false;
  }
  return typeof filter !== 'string';
};

const example = filter => {
  if (!isValidJson(filter)) {
    return 'Json invalide';
  }

  let value = '****';
  if (filter.kind === 'checkbox') {
    value = true;
  }
  const queryName = `${filter.table}_${filter.column}_${filter.queryName}`;
  return `${queryName}=${parameterizedString(filter.queryFormat, value)}`;
};

const AccountSettingsPlanningFilter = ({ filters, onChange }) => {
  return (
    <Grid>
      {filters.map((filter, index) => {
        return (
          <GridItem width="50%" key={index}>
            <Card>
              <CardContent>
                <TextInputLayout
                  textArea
                  label="Filter (name = clé unique du filtre)"
                  minRows={3}
                  onChange={v => {
                    onChange(
                      filters.map((f, i) => {
                        if (i === index) {
                          return parse(v);
                        } else {
                          return f;
                        }
                      })
                    );
                  }}
                  value={stringify(filter)}
                  error={isValidJson(filter) ? null : 'INVALID JSON'}
                  hint={example(filter)}
                />
              </CardContent>
            </Card>
          </GridItem>
        );
      })}
    </Grid>
  );
};

export default AccountSettingsPlanningFilter;

import { useEffect } from 'react';
import { AccountingAction } from 'features/Accounting';
import { useDispatch } from 'react-redux';

const useLinesSelect = ({ groupId, projectKindId }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      AccountingAction.getUnits({
        page: 1,
        per_page: 500
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (!projectKindId) return;
    dispatch(
      AccountingAction.getServices({
        page: 1,
        sort: 'display_name',
        without_or_within_groups: [groupId],
        project_kind_id_eq_or_project_kind_id_null: projectKindId
      })
    );
  }, [dispatch, groupId, projectKindId]);
};

export default useLinesSelect;

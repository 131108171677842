import React, { useCallback, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import * as Yup from 'yup';
import { EntityModal } from 'components/v3/Modal';
import { TextInputField, ERRORS } from 'components/v3/Form';
import { Grid, GridItem } from 'components/v3/ui';
import {
  AccountingAction,
  AccountingSelector,
  unitAsValues
} from 'features/Accounting';
import { permissions, useAccountConfig } from 'config';

const validationSchema = Yup.object().shape({
  displayName: Yup.string().required(ERRORS.REQUIRED)
});

const UnitModal = ({
  onRequestClose,
  onSubmitSuccess,
  unit: propsUnit,
  ...props
}) => {
  const { hasPermission } = useAccountConfig();
  const dispatch = useDispatch();
  const { params } = useRouteMatch();
  let { unitId } = params;
  if (propsUnit) {
    unitId = propsUnit.id;
  }
  const isNew = !Boolean(unitId);

  const selectUnit = useMemo(
    () => state => AccountingSelector.selectUnit(state, { unitId }),
    [unitId]
  );

  let unit = useSelector(selectUnit, isEqual);
  if (!unit) {
    unit = propsUnit;
  }

  const createRequest = useCallback(
    payload => dispatch(AccountingAction.createUnit({ payload })),
    [dispatch]
  );

  const fetchRequest = useCallback(
    () => dispatch(AccountingAction.getUnit({ id: unitId })),
    [unitId, dispatch]
  );

  const updateRequest = useCallback(
    (id, payload) => dispatch(AccountingAction.updateUnit({ id, payload })),
    [dispatch]
  );

  const deleteRequest = useCallback(
    id => dispatch(AccountingAction.deleteUnit({ id })),
    [dispatch]
  );

  const canEdit = hasPermission([
    permissions.ACCOUNTING,
    isNew ? 'unit.create' : 'unit.update'
  ]);

  const canDelete = hasPermission([permissions.ACCOUNTING, 'unit.delete']);

  return (
    <EntityModal
      isOpen
      size="small"
      onRequestClose={onRequestClose}
      title={isNew ? 'Nouvelle unité' : "Édition de l'unité"}
      id={unitId}
      model="unit"
      entity={unit}
      validationSchema={validationSchema}
      entityAsValue={unitAsValues}
      fetchRequest={fetchRequest}
      createRequest={createRequest}
      updateRequest={updateRequest}
      deleteRequest={canDelete ? deleteRequest : undefined}
      onSubmitSuccess={onSubmitSuccess}
      autoSubmit={false}
      readOnly={!canEdit}
      {...props}
    >
      {() => (
        <Grid spacing={16} padded={false}>
          <GridItem width="100%">
            <TextInputField
              name="displayName"
              label="Nom de l'unité"
              required
              readOnly={!canEdit}
            />
          </GridItem>
        </Grid>
      )}
    </EntityModal>
  );
};

export default UnitModal;

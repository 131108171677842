import React from 'react';
import { SelectField } from 'components/v3/Form';
import { SelectInputLayout } from 'components/v3/InputLayout';

const TAXES = [0.0, 0.021, 0.055, 0.1, 0.2]
  .sort((a, b) => a - b)
  .map(tax => ({
    value: tax,
    label: `${tax * 100}%`
  }));

const TaxSelect = ({ value, onChange, ...props }) => {
  const currentValue = value != null ? parseFloat(value) : null;
  return (
    <SelectInputLayout
      label="Taxe (TVA)"
      value={currentValue}
      options={TAXES}
      onChange={onChange}
      mapOptionValue
      sortOptions={false}
      {...props}
    />
  );
};

export const TaxField = props => {
  return <SelectField component={TaxSelect} {...props} />;
};

export default TaxSelect;

import { createAction } from 'redux-actions';
import omit from 'lodash/omit';
import * as Api from './input_material_api';
import { shouldFetch } from 'common/actions';

const prepareInputMaterialPayload = payload => payload;

const inputMaterialsLoading = createAction('INPUT_MATERIALS_LOADING');
const inputMaterialsSuccess = createAction('INPUT_MATERIALS_SUCCESS');
const inputMaterialsError = createAction('INPUT_MATERIALS_ERROR');
export const getInputMaterials = payload => async (dispatch, getState) => {
  const { useCache = true, ...params } = payload || {};
  const state = getState();
  if (!useCache || shouldFetch('inputMaterial', params, state)) {
    return fetchInputMaterials(params, dispatch);
  } else {
    return {
      fromCache: true,
      isLoading: getState().inputMaterials.isLoading
    };
  }
};
const fetchInputMaterials = async (payload, dispatch) => {
  dispatch(inputMaterialsLoading());
  try {
    const result = await Api.getInputMaterials(payload);
    dispatch(
      inputMaterialsSuccess({
        ...result,
        requestParams: omit(payload, 'cancelToken')
      })
    );
    return result;
  } catch (error) {
    dispatch(inputMaterialsError(error));
    throw error;
  }
};

const receiveCreateInputMaterial = createAction('NEW_INPUT_MATERIAL_SUCCESS');
export const createInputMaterial =
  ({ payload, ...config }) =>
  async dispatch => {
    const result = await Api.createInputMaterial(
      prepareInputMaterialPayload(payload),
      config
    );
    dispatch(receiveCreateInputMaterial(result));
    return result;
  };

const receiveUpdateInputMaterial = createAction(
  'UPDATE_INPUT_MATERIAL_SUCCESS'
);
export const updateInputMaterial =
  ({ id, payload: inputMaterial, ...config }) =>
  async dispatch => {
    const result = await Api.putInputMaterial(
      id,
      prepareInputMaterialPayload(inputMaterial),
      config
    );
    dispatch(receiveUpdateInputMaterial(result));
    return result;
  };

const receiveDeleteInputMaterial = createAction(
  'DELETE_INPUT_MATERIAL_SUCCESS'
);
export const deleteInputMaterial =
  ({ id, ...config }) =>
  async dispatch => {
    const response = await Api.deleteInputMaterial(id, config);
    dispatch(receiveDeleteInputMaterial(id));
    return response;
  };

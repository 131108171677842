const isSameIds = (
  id1: number | string | null | undefined,
  id2: number | string | null | undefined
) => {
  if (id1 === id2) {
    return true;
  }
  if (typeof id1 === 'number') {
    return Number(id2) === id1;
  } else if (typeof id2 === 'number') {
    return Number(id1) === id2;
  } else {
    return false;
  }
};

export default isSameIds;

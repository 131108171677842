import React, {
  useCallback,
  useMemo,
  Fragment,
  useState,
  useEffect
} from 'react';
import { SelectField } from 'components/v3/Form';
import { SelectInputLayout } from 'components/v3/InputLayout';
import { useRedux, useDelay } from 'components/v3/utils';
import ProjectKindModal from './Modal';
import { jsonApiToObject } from 'common/api';
import {
  ProjectKindSelector,
  ProjectKindAction,
  projectKindAsOption
} from 'features/ProjectKinds';
import { permissions, useAccountConfig } from 'config';

const formatCreateLabel = input => `Créer le type ${input}`;

const ProjectKindSelect = ({
  value,
  onChange,
  useDefault,
  readOnly,
  ...props
}) => {
  const { accountId, hasPermission } = useAccountConfig();
  const delay = useDelay();
  const [projectKindModal, setProjectKindModal] = useState();
  const [projectKinds, fetch] = useRedux(
    ProjectKindSelector.selectProjectKinds,
    ProjectKindAction.getProjectKinds,
    {
      fetchOnMount: false,
      useLocalState: true,
      initialParams: {
        page: 1,
        perPage: 500,
        useCache: true
      }
    }
  );

  const handleCreateOption = useCallback(input => {
    setProjectKindModal({
      displayName: input
    });
  }, []);

  const handleEditOption = useCallback(value => {
    setProjectKindModal(value);
  }, []);

  const options = useMemo(() => projectKindAsOption(projectKinds), [
    projectKinds
  ]);

  const currentValue = useMemo(() => projectKindAsOption(value), [value]);

  useEffect(() => {
    if (readOnly) {
      return;
    }
    if (useDefault && !value && onChange) {
      if (options.length === 0) {
        return;
      }
      const defaultProjectKind = options.find(
        option => option?.original?.default
      )?.original;
      if (defaultProjectKind) {
        delay(() => onChange(defaultProjectKind), 32);
      }
    }
  }, [readOnly, delay, useDefault, value, onChange, options]);

  useEffect(() => {
    fetch(params => params);
  }, [accountId, fetch]);

  return (
    <Fragment>
      <SelectInputLayout
        label="Type de projet"
        value={currentValue}
        options={options}
        isSearchable
        onChange={onChange}
        inputCreatable={hasPermission([
          permissions.RESOURCES_CONFIG,
          'project_kind.create'
        ])}
        createLabel="Créer un type de projet"
        formatCreateLabel={formatCreateLabel}
        onCreateOption={handleCreateOption}
        optionEditable={hasPermission([
          permissions.RESOURCES_CONFIG,
          'project_kind.create'
        ])}
        onEditOptionClick={handleEditOption}
        readOnly={readOnly}
        {...props}
      />
      {projectKindModal && (
        <ProjectKindModal
          projectKind={projectKindModal}
          onRequestClose={() => setProjectKindModal(null)}
          onSubmitSuccess={response => {
            const projectKind = jsonApiToObject(response);
            if (props.multi) {
              onChange([...currentValue, projectKind]);
            } else {
              onChange(projectKind);
            }
          }}
        />
      )}
    </Fragment>
  );
};

export const ProjectKindField = props => {
  return <SelectField component={ProjectKindSelect} {...props} />;
};

export default ProjectKindSelect;

import { createAction } from 'redux-actions';
import omit from 'lodash/omit';
import * as Api from './api';
import { prepareAttributes, shouldFetch } from 'common/actions';

const preparePurveyorPayload = (payload) => payload;

const purveyorsLoading = createAction('PURVEYORS_LOADING');
const purveyorsSuccess = createAction('PURVEYORS_SUCCESS');
const purveyorsError = createAction('PURVEYORS_ERROR');
export const getPurveyors = (payload) => async (dispatch, getState) => {
  const { useCache, ...params } = payload || {};
  const state = getState();
  if (!useCache || shouldFetch('purveyor', params, state)) {
    return fetchPurveyors(params, dispatch);
  } else {
    return {
      fromCache: true,
      isLoading: getState().purveyors.isLoading
    };
  }
};
const fetchPurveyors = async (payload, dispatch) => {
  dispatch(purveyorsLoading());
  try {
    const result = await Api.getPurveyors(payload);
    dispatch(
      purveyorsSuccess({
        ...result,
        requestParams: omit(payload, 'cancelToken')
      })
    );
    return result;
  } catch (error) {
    dispatch(purveyorsError(error));
    throw error;
  }
};

const receiveCreatePurveyor = createAction('NEW_PURVEYOR_SUCCESS');
export const createPurveyor =
  ({ payload, ...config }) =>
  async (dispatch) => {
    const result = await Api.createPurveyor(preparePurveyorPayload(payload), config);
    dispatch(receiveCreatePurveyor(result));
    return result;
  };

const receivePurveyor = createAction('PURVEYOR_SUCCESS');
export const getPurveyor =
  ({ id, ...payload }) =>
  async (dispatch) => {
    const result = await Api.getPurveyor(id, payload);
    dispatch(receivePurveyor(result));
    return result;
  };

const receiveUpdatePurveyor = createAction('UPDATE_PURVEYOR_SUCCESS');
export const updatePurveyor =
  ({ id, payload: purveyor, ...config }) =>
  async (dispatch) => {
    const result = await Api.putPurveyor(id, preparePurveyorPayload(purveyor), config);
    dispatch(receiveUpdatePurveyor(result));
    return result;
  };

const receiveDeletePurveyor = createAction('DELETE_PURVEYOR_SUCCESS');
export const deletePurveyor =
  ({ id, ...config }) =>
  async (dispatch) => {
    const response = await Api.deletePurveyor(id, config);
    dispatch(receiveDeletePurveyor(id));
    return response;
  };

const prepareProductPayload = (payload) =>
  prepareAttributes(
    {
      ...payload,
      productRawMaterials: payload.productRawMaterials?.map((prm) => ({
        ...omit(prm, 'rawMaterial'),
        rawMaterialId: prm.rawMaterial?.id ?? prm.rawMaterialId
      }))
    },
    'productRawMaterials'
  );

const productsLoading = createAction('PRODUCTS_LOADING');
const productsSuccess = createAction('PRODUCTS_SUCCESS');
const searchProductsSuccess = createAction('SEARCH_PRODUCTS_SUCCESS');
const productsError = createAction('PRODUCTS_ERROR');
export const getProducts = (payload) => async (dispatch, getState) => {
  const { useCache, isSearchRequest, ...params } = payload || {};
  const state = getState();
  const action = isSearchRequest ? 'SEARCH_PRODUCTS_SUCCESS' : 'PRODUCTS_SUCCESS';
  if (!useCache || shouldFetch('product', params, state, action)) {
    return fetchProducts(params, isSearchRequest, dispatch);
  } else {
    return {
      fromCache: true,
      isLoading: getState().products.isLoading
    };
  }
};
const fetchProducts = async (payload, isSearchRequest, dispatch) => {
  if (!isSearchRequest) {
    dispatch(productsLoading());
  }
  try {
    const result = await Api.getProducts(payload);
    if (isSearchRequest) {
      dispatch(
        searchProductsSuccess({
          ...result,
          requestParams: omit(payload, 'cancelToken')
        })
      );
    } else {
      dispatch(
        productsSuccess({
          ...result,
          requestParams: omit(payload, 'cancelToken')
        })
      );
    }
    return result;
  } catch (error) {
    dispatch(productsError(error));
    throw error;
  }
};
const receiveProduct = createAction('PRODUCT_SUCCESS');
export const getProduct =
  ({ id, ...payload }) =>
  async (dispatch) => {
    const result = await Api.getProduct(id, payload);
    dispatch(receiveProduct(result));
    return result;
  };
const receiveCreateProduct = createAction('NEW_PRODUCT_SUCCESS');
export const createProduct =
  ({ payload, ...config }) =>
  async (dispatch) => {
    const result = await Api.createProduct(prepareProductPayload(payload), config);
    dispatch(receiveCreateProduct(result));
    return result;
  };
const receiveUpdateProduct = createAction('UPDATE_PRODUCT_SUCCESS');
export const updateProduct =
  ({ id, payload: product, ...config }) =>
  async (dispatch) => {
    const result = await Api.putProduct(id, prepareProductPayload(product), config);
    dispatch(receiveUpdateProduct(result));
    return result;
  };
const receiveDeleteProduct = createAction('UPDATE_PRODUCT_SUCCESS');
export const deleteProduct =
  ({ id, ...config }) =>
  async (dispatch) => {
    const response = await Api.deleteProduct(id, config);
    dispatch(receiveDeleteProduct(id));
    return response;
  };

const prepareRawMaterialPayload = (payload) => prepareAttributes(payload);
const rawMaterialsLoading = createAction('RAW_MATERIALS_LOADING');
const rawMaterialsSuccess = createAction('RAW_MATERIALS_SUCCESS');
const searchRawMaterialsSuccess = createAction('SEARCH_RAW_MATERIALS_SUCCESS');
const rawMaterialsError = createAction('RAW_MATERIALS_ERROR');
export const getRawMaterials = (payload) => async (dispatch, getState) => {
  const { useCache, isSearchRequest, ...params } = payload || {};
  const state = getState();
  const action = isSearchRequest ? 'SEARCH_RAW_MATERIALS_SUCCESS' : 'RAW_MATERIALS_SUCCESS';
  if (!useCache || shouldFetch('rawMaterial', params, state, action)) {
    return fetchRawMaterials(params, isSearchRequest, dispatch);
  } else {
    return {
      fromCache: true,
      isLoading: getState().rawMaterials.isLoading
    };
  }
};
const fetchRawMaterials = async (payload, isSearchRequest, dispatch) => {
  if (!isSearchRequest) {
    dispatch(rawMaterialsLoading());
  }
  try {
    const result = await Api.getRawMaterials(payload);
    if (isSearchRequest) {
      dispatch(
        searchRawMaterialsSuccess({
          ...result,
          requestParams: omit(payload, 'cancelToken')
        })
      );
    } else {
      dispatch(
        rawMaterialsSuccess({
          ...result,
          requestParams: omit(payload, 'cancelToken')
        })
      );
    }
    return result;
  } catch (error) {
    dispatch(rawMaterialsError(error));
    throw error;
  }
};
const receiveCreateRawMaterial = createAction('NEW_RAW_MATERIAL_SUCCESS');
export const createRawMaterial =
  ({ payload, ...config }) =>
  async (dispatch) => {
    const result = await Api.createRawMaterial(prepareRawMaterialPayload(payload), config);
    dispatch(receiveCreateRawMaterial(result));
    return result;
  };
const receiveUpdateRawMaterial = createAction('UPDATE_RAW_MATERIAL_SUCCESS');
export const updateRawMaterial =
  ({ id, payload: rawMaterial, ...config }) =>
  async (dispatch) => {
    const result = await Api.putRawMaterial(id, prepareRawMaterialPayload(rawMaterial), config);
    dispatch(receiveUpdateRawMaterial(result));
    return result;
  };
const receiveUpdateRawMaterialsOrder = createAction('REORDER_RAW_MATERIALS_SUCCESS');
export const updateRawMaterialsOrder =
  ({ payload: rawMaterial, payloads: rawMaterials, ...config }) =>
  async (dispatch) => {
    dispatch(receiveUpdateRawMaterialsOrder(rawMaterials));
    const result = await Api.reorderRawMaterial(rawMaterial.id, rawMaterial, config);
    return result;
  };

const receiveDeleteRawMaterial = createAction('DELETE_RAW_MATERIAL_SUCCESS');
export const deleteRawMaterial =
  ({ id, ...config }) =>
  async (dispatch) => {
    const response = await Api.deleteRawMaterial(id, config);
    dispatch(receiveDeleteRawMaterial(id));
    return response;
  };

const prepareOrderPayload = (
  { project, phase, purveyor, product, ...payload } // pour alleger le json
) =>
  prepareAttributes(
    {
      ...payload,
      orderDays: Object.keys(payload.orderDays || {}).map((date) => {
        return {
          date,
          ...(payload.orderDays[date] || {})
        };
      })
    },
    'orderDays'
  );

const receiveOrders = createAction('ORDERS_SUCCESS');
export const getOrders = (payload) => async (dispatch) => {
  const result = await Api.getOrders(payload);
  dispatch(receiveOrders(result));
  return result;
};

export const receiveUpdateOrder = createAction('UPDATE_ORDER_SUCCESS');
export const updateOrder =
  ({ id, payload: order, ...config }) =>
  async (dispatch) => {
    const result = await Api.putOrder(id, prepareOrderPayload(order), config);
    dispatch(receiveUpdateOrder(result));
    return result;
  };

const receiveOrderToPdf = createAction('UPDATE_ORDER_SUCCESS');
export const supplyVoucherToPdf =
  ({ id, payload, ...config }) =>
  async (dispatch) => {
    const result = await Api.orderToPdf(id, payload, config);
    dispatch(receiveOrderToPdf(result));
    return result;
  };

const receiveWeekOrders = createAction('WEEK_ORDERS_SUCCESS');
export const getWeekOrders = (payload) => async (dispatch) => {
  const result = await Api.getOrders(payload);
  dispatch(receiveWeekOrders(result));
  return result;
};

const receiveMaterialOrders = createAction('MATERIAL_ORDERS_SUCCESS');
export const getMaterialOrders = (payload) => async (dispatch) => {
  const result = await Api.getMaterialOrders(payload);
  dispatch(receiveMaterialOrders(result));
  return result;
};

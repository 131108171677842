import React from 'react';
import styled, { css } from 'styled-components';
import SadEmptyState from 'common/images/sad-empty-state.png';
import SadEmptyStateOpened from 'common/images/sad-empty-state-opened.png';
import { Text } from './v3/ui';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;

  img {
    width: 350px;
    height: auto;
    ${props =>
      props.small &&
      css`
        width: 180px;
      `};
  }
`;

const EmptyState = ({
  message = '',
  button = undefined,
  opened = false,
  small = false,
  ...props
}) => {
  return (
    <Wrapper small={small}>
      <img src={opened ? SadEmptyStateOpened : SadEmptyState} alt="no-data" />
      {Boolean(message) && (
        <Text color="secondary" alignCenter>
          {message}
        </Text>
      )}
    </Wrapper>
  );
};

export default EmptyState;

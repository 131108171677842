import { SurfacingApi, SurfacingAction, SurfacingReducer, SurfacingSelector } from './data';
import { ProductSelect, PurveyorList, PurveyorModal, PurveyorSelect, ProductList, RawMaterialList } from './components';
import { stringToColor } from 'utils';
import { printDate } from 'utils/dates';

export { SurfacingApi, SurfacingAction, SurfacingReducer, SurfacingSelector };
export { ProductSelect, PurveyorList, PurveyorModal, PurveyorSelect, ProductList, RawMaterialList };

export const purveyorAsOption = (purveyor, colored) => {
  if (Array.isArray(purveyor)) {
    return purveyor.map((p) => purveyorAsOption(p, colored));
  } else if (purveyor) {
    return {
      value: purveyor.id,
      label: purveyor.displayName,
      color: colored ? stringToColor(purveyor.displayName) : null,
      original: purveyor
    };
  } else {
    return null;
  }
};

export const orderExportHistoryAsOption = (history) => {
  if (Array.isArray(history)) {
    return history.map(orderExportHistoryAsOption);
  } else if (history) {
    return {
      value: history.id,
      label: `Version ${history.version} du ${printDate(history.createdAt, "dd/MM/yyyy 'à' HH:mm")}`,
      subtitle: history.user?.displayName,
      original: history
    };
  } else {
    return null;
  }
};

export const purveyorAsValues = (purveyor) => {
  return {
    id: purveyor?.id || null,
    displayName: purveyor?.displayName || '',
    email: purveyor?.email || '',
    phoneNumber: purveyor?.phoneNumber || '',
    faxNumber: purveyor?.faxNumber || '',
    productIds: purveyor?.productIds || []
  };
};

export const rawMaterialAsOption = (rawMaterial) => {
  if (Array.isArray(rawMaterial)) {
    return rawMaterial.map(rawMaterialAsOption);
  } else if (rawMaterial) {
    return {
      value: rawMaterial.id,
      label: rawMaterial.name,
      original: rawMaterial
    };
  } else {
    return null;
  }
};
export const rawMaterialAsValues = (rawMaterial) => {
  return {
    id: rawMaterial?.id || null,
    name: rawMaterial?.displayName || ''
  };
};

export const productAsOption = (product) => {
  if (Array.isArray(product)) {
    return product.map(productAsOption);
  } else if (product) {
    return {
      value: product.id,
      label: product.displayName,
      original: product
    };
  } else {
    return null;
  }
};
export const productAsValues = (product) => {
  return {
    id: product?.id || null,
    markingFormulaCe: product?.markingFormulaCe,
    subformula: product?.subformula,
    aqpNumber: product?.aqpNumber,
    density: product?.density,
    productRawMaterials: product?.productRawMaterials
  };
};

export const isValidProduct = (product) =>
  product.markingFormulaCe && product.aqpNumber && product.subformula && product.density;

export const layerAsValue = (layer) => ({
  id: layer?.id || null,
  purveyor: layer?.purveyor || null,
  product: layer?.product || null,
  quantity: layer?.quantity || null
});

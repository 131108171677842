import React, { useCallback, useMemo, useRef } from 'react';
import { v4 } from 'uuid';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import isEqual from 'lodash/isEqual';
import { MdAdd } from 'react-icons/md';
import { TextInputField } from 'components/v3/Form';
import { TextInputLayout, InputLayoutVariant } from 'components/v3/InputLayout';
import Table from 'components/v3/List/Table';
import { EntityModal } from 'components/v3/Modal';
import { Grid, GridItem, Button, DashContainer, Text, Dashed } from 'components/v3/ui';
import { useDelay } from 'components/v3/utils';
import { SurfacingSelector, SurfacingAction, productAsValues } from 'features/Surfacing';
import { permissions, useAccountConfig } from 'config';
import RawMaterialSelect from './RawMaterialSelect';

const RAW_MATERIAL_COLUMNS = [
  {
    Header: 'Nom',
    accessor: 'rawMaterial',
    width: '60%',
    dashed: true,
    isEditable: true,
    Cell: ({ item, onChange }) => (
      <RawMaterialSelect
        variant={InputLayoutVariant.flat.medium}
        required
        value={item.rawMaterial}
        fillWidth
        fetchDisabled
        onChange={(rawMaterial) =>
          onChange({
            ...item,
            rawMaterial
          })
        }
      />
    )
  },
  {
    Header: 'Pourcentage',
    accessor: 'percentage',
    width: '40%',
    dashed: true,
    isEditable: true,
    Cell: ({ item, onChange }) => (
      <TextInputLayout
        variant={InputLayoutVariant.flat.medium}
        percentage
        max={100}
        min={0}
        value={(item.percentage || 0) * 100}
        fillWidth
        onChange={(percentage) => {
          const newValue = percentage / 100;
          onChange({
            ...item,
            percentage: newValue
          });
        }}
      />
    )
  }
];

const RawMaterialsLabel = styled(Text)`
  padding-left: 16px;
  padding-top: 6px;
  padding-right: 16px;
  padding-bottom: 6px;
`;

const RawMaterialsGridItem = styled(GridItem)`
  display: flex;
  flex-direction: column;

  &:focus,
  &:focus-within {
    ${RawMaterialsLabel} {
      color: ${({ theme }) => theme.primaryLight};
    }
  }
`;

const RawMaterialsContainer = styled(DashContainer)`
  border: ${({ theme }) => theme.borderInput};
  border-top-left-radius: ${({ theme }) => theme.borderInputRadius};
  border-top-right-radius: ${({ theme }) => theme.borderInputRadius};
  overflow: hidden;
  max-height: 500px;

  * > input,
  * > textarea {
    padding-left: 8px;
    padding-right: 8px;
    font-size: 1rem;
  }
  * > label {
    left: 3px;
    font-size: 0.875rem;
  }ont-size: 0.75rem;
  }
  * > ${Dashed} {
    margin-left: -6px;
    width: calc(100% + 6px);
  }
`;

const AddProductButton = styled.div`
  align-self: flex-end;
`.withComponent(Button);

const ProductModal = ({ onRequestClose, onSubmitSuccess, product: propsProduct, ...props }) => {
  const { hasPermission } = useAccountConfig();
  const dispatch = useDispatch();
  const delay = useDelay();
  const { params } = useRouteMatch();
  let { productId } = params;
  if (propsProduct) {
    productId = propsProduct.id;
  }
  const isNew = !Boolean(productId);
  const rawMaterialRef = useRef();

  const selectProduct = useMemo(() => (state) => SurfacingSelector.selectProduct(state, { productId }), [productId]);

  let product = useSelector(selectProduct, isEqual);
  if (!product) {
    product = propsProduct;
  }

  const createRequest = useCallback((payload) => dispatch(SurfacingAction.createProduct({ payload })), [dispatch]);

  const fetchRequest = useCallback(
    () => dispatch(SurfacingAction.getProduct({ id: productId })),
    [productId, dispatch]
  );

  const updateRequest = useCallback(
    (id, payload) => dispatch(SurfacingAction.updateProduct({ id, payload })),
    [dispatch]
  );

  const deleteRequest = useCallback((id) => dispatch(SurfacingAction.deleteProduct({ id })), [dispatch]);

  const canEdit = hasPermission([permissions.RESOURCES_CONFIG, isNew ? 'product.create' : 'product.update']);
  const canDelete = hasPermission([permissions.RESOURCES_CONFIG, 'product.delete']);

  return (
    <EntityModal
      isOpen
      onRequestClose={onRequestClose}
      title={isNew ? 'Nouveau produit' : 'Édition du produit'}
      id={productId}
      model="product"
      entity={product}
      entityAsValue={productAsValues}
      fetchRequest={fetchRequest}
      createRequest={createRequest}
      updateRequest={updateRequest}
      deleteRequest={canDelete ? deleteRequest : undefined}
      onSubmitSuccess={onSubmitSuccess}
      autoSubmit={false}
      readOnly={!canEdit}
      {...props}
    >
      {({ values, setFieldValue }) => (
        <Grid spacing={16} padded={false}>
          <GridItem width="50%" minWidth={240}>
            <TextInputField name="markingFormulaCe" label="Formule CE" required />
          </GridItem>
          <GridItem width="50%" minWidth={240}>
            <TextInputField name="aqpNumber" label="N° AQP" required />
          </GridItem>
          <GridItem width="50%" minWidth={240}>
            <TextInputField name="subformula" label="Sous-formule" required />
          </GridItem>
          <GridItem width="50%" minWidth={240}>
            <TextInputField name="density" label="Densité" number />
          </GridItem>
          <RawMaterialsGridItem width="100%">
            <RawMaterialsLabel color="secondary" weight="semiBold">
              Matières premières
            </RawMaterialsLabel>
            <RawMaterialsContainer readOnly={false} fillWidth>
              <Table
                listRef={rawMaterialRef}
                columns={RAW_MATERIAL_COLUMNS}
                items={values.productRawMaterials || []}
                readOnly={false}
                hasDeleteConfirmation={false}
                headerVariant="dark"
                useWindow={false}
                onChange={(productRawMaterials) => setFieldValue('productRawMaterials', productRawMaterials)}
                isDeletable
                maxHeight={400}
                minRows={1}
              />
            </RawMaterialsContainer>
            <AddProductButton
              variant="text"
              iconLeading={MdAdd}
              onClick={() => {
                setFieldValue('productRawMaterials', [
                  ...(values.productRawMaterials || []),
                  {
                    id: v4(),
                    isNew: true,
                    percentage: 0
                  }
                ]);
                delay(rawMaterialRef.current?.scroller().scrollToBottom, 50);
              }}
            >
              Ajouter une matière première
            </AddProductButton>
          </RawMaterialsGridItem>
        </Grid>
      )}
    </EntityModal>
  );
};

export default ProductModal;

import React, { useState, useCallback } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { MdAdd } from 'react-icons/md';
import SubHeader from 'components/v3/SubHeader';
import { FilterBar } from 'components/v3/Filters';
import { Container, Title, Button } from 'components/v3/ui';
import { PeriodSelector, PeriodAction } from 'features/Periods';
import PeriodList from 'features/Periods/components/v3/List';

const defaultParams = { sort: '-start_at' };

const Period = () => {
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  const [count, setCount] = useState();
  const [filters, setFilters] = useState();

  const handleDelete = useCallback(
    item => dispatch(PeriodAction.deletePeriod({ id: item.id })),
    [dispatch]
  );

  return (
    <Container vertical>
      <SubHeader>
        <Title>{`Periode exceptionnelles${
          count != null ? ` (${count})` : ''
        }`}</Title>
        <Button small to={`${path}/nouvelle-periode`} icon={MdAdd}>
          Nouvelle période
        </Button>
      </SubHeader>
      <Container vertical huge padded>
        <FilterBar
          big
          searchQuery="descriptionContAny"
          onChanges={setFilters}
        />
        <PeriodList
          spacing={8}
          headerVariant="transparent"
          selector={PeriodSelector.selectPeriods}
          action={PeriodAction.getPeriods}
          onCountChanged={setCount}
          params={defaultParams}
          filters={filters}
          perPage={50}
          detailUrlPrefix=""
          isDeletable
          deleteRequest={handleDelete}
        />
      </Container>
    </Container>
  );
};

export default Period;

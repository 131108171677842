import { createDeepEqualSelector, getState, selectRelations, getProps } from 'common/selector';
import { SCHEDULER_TYPE } from 'pages/Plannings/Timeline/View';
import { PhaseSelector } from 'features/Phases';
import { ProjectSelector } from 'features/Projects';
import { UiSelector } from 'features/UiPreferences';
import get from 'lodash/get';
import flatten from 'lodash/flatten';
import { UserSelector } from 'features/Users';
import { MachineSelector } from 'features/Machines';
import { TeamSelector } from 'features/Teams';
import { createSelector } from 'reselect';
import { HolidaySelector } from 'features/Holidays';
import { PeriodSelector } from 'features/Periods';
import moment from 'moment';
import { pick, sortBy, groupBy, first } from 'lodash';
import { phaseProjectAttributes, pickPhaseAttributes, eventUsersAttributes } from '..';
import isSameIds from 'utils/idUtils';
import { PHASE_LINE_ATTRIBUTES } from 'pages/Administration/AccountSettings/AccountSettingsPlanningContent';
import { parseDate } from 'utils/dates';
import { ActivitySelector } from 'features/ProjectKindsActivities';
import { MeSelector } from 'features/Me';
import { AccountSelector } from 'features/Accounts';

export const getPlannings = (state) => state.plannings;

const resourceAttribute = (schedulerType, phase) => {
  if (!schedulerType || !phase) {
    return {};
  }
  switch (schedulerType.name) {
    case SCHEDULER_TYPE.MACHINE.name:
      if (!phase.machines || phase.machines.length === 0) {
        return { resourceIds: ['none'] };
      }
      return {
        resourceIds: phase.machines ? phase.machines.map((m) => m.id) : []
      };
    case SCHEDULER_TYPE.USERS.name:
      if (!phase.users || phase.users.length === 0) {
        return { resourceIds: ['none'] };
      }
      return {
        resourceIds: phase.users ? phase.users.map((m) => m.id) : []
      };
    case SCHEDULER_TYPE.TEAMS.name:
      if (!phase.team) {
        return { resourceId: 'none' };
      }
      return {
        resourceId: phase.team ? phase.team.id : phase.teamId
      };
    case SCHEDULER_TYPE.PROJECTS.name:
    default:
      if (!phase.project && !phase.projectId) {
        return null;
      }
      return {
        resourceId: phase.project ? phase.project.id : phase.projectId
      };
  }
};

export const selectPlanningUpdating = createSelector(getPlannings, ({ isUpdating }) => isUpdating);
export const selectPlanningLoading = createSelector(getPlannings, ({ isLoading }) => isLoading);
export const selectOverlapsToUpdate = createSelector(getPlannings, ({ overlapsToUpdate }) => overlapsToUpdate);

export const selectEventOverlaps = createDeepEqualSelector(
  [getPlannings, getProps],
  ({ overlaps }, { eventId }) => overlaps[eventId]
);

const getDefaultLines = (schedulerType) => {
  switch (schedulerType?.kind) {
    case 'timeline':
      return [
        {
          position: 0,
          elements: [PHASE_LINE_ATTRIBUTES.PhaseUsers, PHASE_LINE_ATTRIBUTES.PhaseDisplayName]
        }
      ];
    default:
      return [
        {
          position: 0,
          elements: [PHASE_LINE_ATTRIBUTES.ProjectReference]
        },
        {
          position: 1,
          elements: [PHASE_LINE_ATTRIBUTES.ProjectDisplayName]
        },
        { position: 2, elements: [PHASE_LINE_ATTRIBUTES.PhaseUsers] }
      ];
  }
};

const selectEvents = (fullContent = false) =>
  createDeepEqualSelector(
    [
      getPlannings,
      PhaseSelector.getPhases,
      HolidaySelector.getHolidays,
      PeriodSelector.getPeriods,
      UiSelector.selectUi,
      MeSelector.selectMe,
      getProps,
      getState
    ],
    (
      { phasesIds, holidaysIds, exceptionalPeriodsIds },
      { phaseById },
      { holidayById },
      { exceptionalPeriodById },
      ui,
      currentUser,
      { schedulerType },
      state
    ) => {
      const phases = phasesIds
        .map((id) => pickPhaseAttributes(selectRelations('phase', phaseById[id], state)))
        .filter((phase) => {
          if (!phase) {
            return false;
          }
          if (currentUser?.topRole === 'client' || currentUser?.topRole === 'user') {
            return currentUser.groups?.find((g) => g.id === phase?.project?.group?.id);
          } else {
            return true;
          }
        })
        .map((phase) => {
          const lines = fullContent
            ? null
            : sortBy(
                (ui.planning && ui.planning[`${schedulerType?.name}EventLines`]) || getDefaultLines(schedulerType),
                'position'
              ).map(({ elements, ...line }) => {
                return {
                  ...line,
                  elements: elements.map((element) => ({
                    ...element,
                    data: get(phase, element.value)
                  }))
                };
              });
          return {
            ...resourceAttribute(schedulerType, phase),
            id: `phase#${phase.id}`,
            uid: phase.id,
            start: phase.startAt,
            end: phase.endAt,
            title: `${phase.displayName}`,
            night: phase.night,
            customData: phase.customData,
            checked: phase.checked,
            planningSource: phase.source,
            project: {
              user: {
                id: phase.project?.user?.id
              },
              group: phase.project?.group
            },
            lines,
            ...(fullContent ? phase : {}),
            source: 'phase',
            color: get(phase, ui.planning.phaseColor) || '#ccc'
          };
        })
        .sort((a, b) => a.activityId - b.activityId);

      const holidays = holidaysIds
        .map((id) => selectRelations('holiday', holidayById[id], state))
        .filter(Boolean)
        .map((holiday) => {
          const users = holiday.users?.map((u) => pick(u, eventUsersAttributes));
          return {
            // ...holiday,
            id: `holiday#${holiday.id}`,
            uid: holiday.id,
            resourceIds: schedulerType?.holidaysInResources ? users?.map((u) => u.id) : ['holiday'],
            source: 'holiday',
            resourceEditable: false,
            start: holiday.startAt,
            end: holiday.endAt,
            title: '',
            lines: fullContent
              ? null
              : [
                  {
                    position: 0,
                    elements: [
                      schedulerType?.name === 'users'
                        ? null
                        : {
                            ...PHASE_LINE_ATTRIBUTES.PhaseUsers,
                            data: users || []
                          },
                      {
                        ...PHASE_LINE_ATTRIBUTES.PhaseDisplayName,
                        data: holiday.kind?.displayName
                      }
                    ].filter(Boolean)
                  }
                ],
            ...(fullContent ? holiday : {}),

            // user,
            // users: user ? [user] : [],
            // displayName: holiday.kind?.displayName || user?.displayName || '',
            color: holiday.kind?.color || '#cccccc',
            classNames: ['holiday-event']
          };
        });

      const periods = exceptionalPeriodsIds
        .map((id) => selectRelations('exceptionalPeriod', exceptionalPeriodById[id], state))
        .filter(Boolean)
        .map((period) => {
          let end = period.endAt;
          if (period.startAt === end || parseDate(period.endAt).getHours() === 0) {
            end = moment(period.endAt).add(1, 'day').format();
          }

          return {
            // ...period,
            id: `period#${period.id}`,
            uid: period.id,
            source: 'period',
            start: period.startAt,
            endAt: end,
            end,
            display: 'background',
            editable: true,
            allDay: true,
            color: period?.color !== '#000' ? period.color : '#608a32',
            title: period.displayName || period.description
          };
        });

      return [...phases, ...holidays, ...periods];
    }
  );

export const selectPlanningEvents = selectEvents(false);

export const selectCalendarEvents = selectEvents(true);

export const selectPlanningTeams = createDeepEqualSelector(
  [getPlannings, TeamSelector.getTeams, getState],
  ({ teamsIds }, { teamById }, state) =>
    teamsIds.map((id) => selectRelations('team', teamById[id], state)).filter((team) => team && !team.archived)
);

export const selectPlanningUsers = createDeepEqualSelector(
  [getPlannings, UserSelector.getUsers, getState],
  ({ usersIds }, { userById }, state) =>
    usersIds
      .map((id) => {
        const user = selectRelations('user', userById[id], state);
        if (user) {
          return {
            ...user,
            color: user.settings?.color
          };
        } else {
          return user;
        }
      })
      .filter((user) => user && !user.settings?.archived && (user.production || user.topRole === 'technician'))
);

export const selectPlanningMachines = createDeepEqualSelector(
  [getPlannings, MachineSelector.getMachines, getState],
  ({ machinesIds }, { machineById }, state) =>
    machinesIds
      .map((id) => selectRelations('machine', machineById[id], state))
      .filter((machine) => machine && !machine.archived)
);

export const selectPlanningProjects = createDeepEqualSelector(
  [
    getPlannings,
    ProjectSelector.getProjects,
    PhaseSelector.getPhases,
    ActivitySelector.getActivities,
    MeSelector.selectMe,
    UiSelector.getUi,
    AccountSelector.selectCurrentAccount,
    getState
  ],
  (
    { phasesIds, projectsIds, emptyProjectIds, searchProjectIds, searchEmptyProjectIds },
    { projectById },
    { phaseById },
    { activityById },
    currentUser,
    { selectedProjectKind, selectedGroups },
    account,
    state
  ) => {
    let ids = [];
    if (searchProjectIds) {
      ids = searchProjectIds;
    } else {
      ids = projectsIds;
    }
    const projects = ids
      .map((id) => {
        const project = selectRelations('project', projectById[id], state);
        if (!project) {
          return null;
        }
        return pick(project, phaseProjectAttributes);
      })
      .filter((project) => {
        if (!project) {
          return false;
        }
        if (currentUser?.topRole === 'client' || currentUser?.topRole === 'user') {
          if (!Boolean(currentUser.groups?.find((g) => g.id === project?.group?.id))) {
            return false;
          }
        }
        const hasSelectedProjectKind =
          !selectedProjectKind || isSameIds(selectedProjectKind.id, project.projectKind?.id);

        const hasSelectedGroup =
          !selectedGroups ||
          selectedGroups.length === 0 ||
          (project.group && selectedGroups.find((id) => isSameIds(id, project.group.id)));

        return hasSelectedGroup && hasSelectedProjectKind;
      })
      .map(({ phases, ...project }) =>
        pick(
          {
            ...project,
            projectActivities: project.projectActivities?.map((a) =>
              pick(a, ['id', 'projectId', 'endAt', 'startAt', 'color', 'displayName'])
            )
          },
          [...phaseProjectAttributes, 'projectActivities']
        )
      );

    if (account?.features?.includes('sort_planning_projects_by_reference')) {
      return sortBy(
        [
          ...projects,
          ...(searchProjectIds ? searchEmptyProjectIds : emptyProjectIds).map((id) =>
            selectRelations('project', projectById[id], state)
          )
        ],
        'reference'
      ).filter(Boolean);
    }
    if (projects.find((p) => p.position != null)) {
      return sortBy(projects, 'position');
    }
    const phases = phasesIds
      .map((id) => {
        const phase = phaseById[id];
        if (!phase) {
          return null;
        }
        return {
          ...phase,
          teamId: phase.relationships?.team?.data?.id,
          activity: activityById[phase.relationships?.activity?.data?.id]
        };
      })
      .filter(Boolean);
    const phaseByProjectId = groupBy(phases, (phase) => phase.relationships?.project?.data?.id);

    let allocatedProjects = [];
    projects.forEach((project) => {
      const phases = phaseByProjectId[project.id]?.filter((phase) => !Boolean(phase.administrative));
      const executionPhase = first(sortBy(phases, 'activity.displayName'));
      if (executionPhase && executionPhase.teamId && executionPhase.startAt) {
        project.firstExecutionStartAt = executionPhase.startAt;
        project.firstExecutionTeamId = executionPhase.teamId;
      }
      allocatedProjects = allocatedProjects.concat(project);
    });
    return [
      ...flatten(
        Object.values(
          groupBy(sortBy(allocatedProjects, 'firstExecutionStartAt'), (project) => project.firstExecutionTeamId)
        )
      ),
      ...(searchProjectIds ? searchEmptyProjectIds : emptyProjectIds).map((id) =>
        selectRelations('project', projectById[id], state)
      )
    ]
      .filter(Boolean)
      .map(({ firstExecutionStartAt, firstExecutionTeamId, ...project }) => project);
  }
);

import React, {
  Fragment,
  useEffect,
  useMemo,
  useCallback,
  useRef
} from 'react';
import styled from 'styled-components';
import { Field, FastField } from 'formik';
import { SectionTitle, Subtitle, GridItem } from 'components/v3/ui';
import { useRedux } from 'components/v3/utils';
import {
  CustomFieldGroupAction,
  CustomFieldGroupSelector
} from 'features/CustomFieldGroups';
import { spacing } from 'ui';
import CustomFieldGroup from './CustomFieldGroup';
import { resourceableCallbackFilter } from './utils';

const GroupTitle = styled(SectionTitle)`
  margin-top: ${spacing()};
`;

const Fields = ({
  field,
  form: { setFieldValue, values },
  fieldableType = null,
  fieldableId,
  readOnly,
  // groupRenderer = undefined,
  // groups: propsGroups = undefined,
  ...props
}) => {
  const { name, value: customData } = field;
  const customDataRef = useRef();
  customDataRef.current = customData;

  const handleChange = useCallback(value => setFieldValue(name, value), [
    setFieldValue,
    name
  ]);

  const customFieldGroupSelector = useMemo(
    () => CustomFieldGroupSelector.selectCustomFieldGroups(fieldableType),
    [fieldableType]
  );

  const [customFieldGroups, fetchCustomFields] = useRedux(
    customFieldGroupSelector,
    CustomFieldGroupAction.getCustomFieldGroups,
    {
      fetchOnMount: false,
      useLocalState: true,
      initialParams: {
        page: 1,
        perPage: 500
      }
    }
  );

  // const groups = customFieldGroups || propsGroups;

  useEffect(() => {
    // if (!propsGroups) {
    fetchCustomFields(prevParams => ({
      ...prevParams,
      fieldableTypeEq: fieldableType,
      fieldableIdEq: fieldableId
    }));
    // }
  }, [fieldableId, fieldableType, fetchCustomFields]);

  const handleDataChange = useCallback(
    value =>
      handleChange([
        ...customDataRef.current.filter(data => data.name !== value?.name),
        value
      ]),
    [handleChange]
  );

  const groups = useMemo(
    () =>
      customFieldGroups
        .filter(f => f.fieldableType === fieldableType)
        .sort((a, b) => (a.sort > b.sort ? 1 : -1)),
    [customFieldGroups, fieldableType]
  );

  if (!customData || !groups || groups.length === 0 || !fieldableType) {
    return null;
  }

  return (
    <Fragment>
      {groups
        .filter(customFieldGroup =>
          (customFieldGroup.customFields || []).some(customField =>
            resourceableCallbackFilter(customField, values)
          )
        )
        .map(customFieldGroup => {
          return (
            <Fragment key={customFieldGroup.id}>
              {(customFieldGroup.title || customFieldGroup.subtitle) && (
                <GridItem width="100%">
                  {customFieldGroup.title && (
                    <GroupTitle>{customFieldGroup.title}</GroupTitle>
                  )}
                  {customFieldGroup.subtitle && (
                    <Subtitle>{customFieldGroup.subtitle}</Subtitle>
                  )}
                </GridItem>
              )}
              <CustomFieldGroup
                customFieldGroup={customFieldGroup}
                onChange={handleDataChange}
                data={customData}
                values={values}
                readOnly={readOnly}
              />
            </Fragment>
          );
        })}
    </Fragment>
  );
};

export const CustomFields = ({ fastField = false, ...props }) => {
  const Component = fastField ? FastField : Field;
  return <Component {...props} component={Fields} />;
};

export default CustomFields;

import React, { Fragment } from 'react';
import Planning from './';
import interactionPlugin from '@fullcalendar/interaction';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import listPlugin from '@fullcalendar/list';
import DragHeader from './Utils/DragHeader';

const plugins = [resourceTimelinePlugin, listPlugin, interactionPlugin];

const Timeline = React.forwardRef((props, ref) => {
  return (
    <Fragment>
      <DragHeader />
      <Planning
        ref={ref}
        plugins={plugins}
        schedulerLicenseKey={'GPL-My-Project-Is-Open-Source'}
        {...props}
      />
    </Fragment>
  );
});

export default Timeline;

import React, { useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { MdAdd } from 'react-icons/md';
import Box, { BoxHeader, BoxFilter } from 'components/v3/Box';
import { Button } from 'components/v3/ui';
import ContactList from './List';

const ContactBox = ({
  fetchAction,
  selector,
  params,
  marged,
  padded,
  creatable = true,
  readOnly,
  ...props
}) => {
  const { url } = useRouteMatch();
  const [filters, setFilters] = useState([]);
  const [count, setCount] = useState(null);

  return (
    <Box marged={marged} padded={padded}>
      <BoxHeader
        bigPadded
        title={`Contacts${count != null ? ` (${count})` : ''}`}
      >
        <BoxFilter
          searchQuery="first_name_or_last_name_or_email_cont_any"
          onChanges={setFilters}
        />
        {!readOnly && creatable && (
          <Button small to={`${url}/contacts/nouveau-contact`} icon={MdAdd}>
            Ajouter
          </Button>
        )}
      </BoxHeader>
      <ContactList
        rowColor="transparent"
        selector={selector}
        action={fetchAction}
        params={params}
        filters={filters}
        onCountChanged={setCount}
        headerVariant="transparent"
        minRows={5}
        readOnly={readOnly}
        {...props}
      />
    </Box>
  );
};

export default ContactBox;

import { createDeepEqualSelector, selectId, getProps } from 'common/selector';
import { AccountSelector } from 'features/Accounts';
import { getState, selectRelations } from 'common/selector';

export const getProjects = state => state.projects;

export const selectSearchProjects = createDeepEqualSelector(
  [getProjects, getState],
  ({ projectById }, state) =>
    Object.values(projectById)
      .map(project => selectRelations('project', project, state))
      .filter(Boolean)
      .slice(0, 50)
);

export const selectProjects = createDeepEqualSelector(
  [getProjects, getState],
  ({ projectsIds, projectById }, state) => {
    return projectsIds
      .map(id => {
        const project = projectById[id];
        if (!project) {
          return null;
        }

        return selectRelations('project', project, state);
      })
      .filter(Boolean);
  }
);

export const selectMapProjects = createDeepEqualSelector(
  [getProjects, getState],
  ({ mapProjectsIds, projectById }, state) => {
    return mapProjectsIds
      .map(id => {
        const project = projectById[id];
        if (!project) {
          return null;
        }

        return selectRelations('project', project, state);
      })
      .filter(Boolean);
  }
);

export const selectProject = createDeepEqualSelector(
  [getProjects, selectId('project'), getState],
  ({ projectById }, projectId, state) => {
    const project = projectById[projectId];
    if (!project) {
      return null;
    }
    return selectRelations('project', project, state);
  }
);

export const selectProjectV3 = createDeepEqualSelector(
  [getProjects, getProps, getState],
  ({ projectById }, { projectId }, state) =>
    selectRelations('project', projectById[projectId], state)
);

export const selectProjectDocuments = createDeepEqualSelector(
  [getProjects, getProps, getState],
  ({ projectById }, { projectId }, state) => {
    const project = selectRelations('project', projectById[projectId], state);
    if (!project) {
      return {
        attachments: [],
        vouchers: []
      };
    }
    return {
      attachments: [
        ...(project.documentTemplates || []).map(d => {
          return {
            ...d,
            fileUrl: d.fileUrl + `?resource_id=${projectId}&resource=Project`,
            kind: 'template',
            canDelete: false,
            type: 'application/pdf'
          };
        }),
        ...(project.attachments || [])
      ],
      vouchers: project.vouchers || []
    };
  }
);

export const selectProjectStates = createDeepEqualSelector(
  [AccountSelector.selectCurrentAccount],
  currentAccount => {
    if (!currentAccount) {
      return [];
    }
    return currentAccount.projectsStates ? currentAccount.projectsStates : [];
  }
);

import React from 'react';
import { Container, Text } from 'components/v3/ui';

const EmptyState = () => {
  return (
    <Container
      vertical
      style={{ justifyContent: 'center', alignItems: 'center' }}
    >
      <Text variant="caption" size="big">
        Vous n'avez pas de configuration de compte.
      </Text>
      <Text variant="caption">
        Envoyez un email à{' '}
        <a href="mailto:contact@roadmapper.fr">contact@roadmapper.fr</a>
      </Text>
    </Container>
  );
};

export default EmptyState;

import { api } from 'common/api';
import { camelizeKeys } from 'humps';

export const searchAddress = input => {
  if (input) {
    return api
      .get(`/v5/maps/geocode`, {
        params: {
          bounds: '-5.5,40,9,51.5', // la france en gros
          region: 'FR',
          language: 'fr',
          address: input
        }
      })
      .then(({ data }) => {
        if (data.status !== 'OK' && data.status !== 'ZERO_RESULTS') {
          throw new Error(data.error_message);
        } else {
          return camelizeKeys(data.results).map(transformResultAsAddress);
        }
      });
  } else {
    return new Promise(() => []);
  }
};

const transformResultAsAddress = result => {
  const { lat, lng } = result.geometry?.location || {};

  const zipCode = result.addressComponents.find(component =>
    component.types?.includes('postal_code')
  )?.longName;

  const country = result.addressComponents.find(component =>
    component.types?.includes('country')
  )?.longName;

  const city = result.addressComponents.find(component =>
    component.types?.includes('locality')
  )?.longName;

  return {
    key: result.placeId,
    label: result.formattedAddress?.replace(', ', ',\n'),
    zipCode: zipCode || '',
    city: city || '',
    country: country || '',
    lat,
    lng,
    content: result.formattedAddress?.replace(', ', ',\n')
  };
};

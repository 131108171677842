import React, { useState, useCallback } from 'react';
import { Route, useRouteMatch, useHistory, Switch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { MdAdd } from 'react-icons/md';
import SubHeader from 'components/v3/SubHeader';
import { FilterBar } from 'components/v3/Filters';
import { Container, Title, Button } from 'components/v3/ui';
import {
  OrganizationAction,
  OrganizationSelector
} from 'features/Organizations';
import OrganizationList from 'features/Organizations/components/v3/List';
import OrganizationModal from 'features/Organizations/components/v3/Modal';
import OrganizationDetail from './OrganizationDetail';

const defaultParams = { sort: 'display_name' };

const Organizations = () => {
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  const history = useHistory();
  const [count, setCount] = useState();
  const [filters, setFilters] = useState();

  const handleDelete = useCallback(
    item => dispatch(OrganizationAction.deleteOrganization({ id: item.id })),
    [dispatch]
  );

  return (
    <Container vertical>
      <Switch>
        <Route exact path={[`${path}/nouvelle-organisation`, `${path}`]}>
          <SubHeader>
            <Title>{`Organisations${
              count != null ? ` (${count})` : ''
            }`}</Title>
            <Button small to={`${path}/nouvelle-organisation`} icon={MdAdd}>
              Nouvelle organisation
            </Button>
          </SubHeader>
          <Container vertical huge padded>
            <FilterBar
              big
              searchQuery="displayNameOrReferenceContAny"
              onChanges={setFilters}
            />
            <OrganizationList
              spacing={8}
              headerVariant="transparent"
              selector={OrganizationSelector.selectOrganizations}
              action={OrganizationAction.getOrganizations}
              onCountChanged={setCount}
              params={defaultParams}
              filters={filters}
              perPage={50}
              isDeletable
              deleteRequest={handleDelete}
            />
          </Container>
          <Route path={`${path}/nouvelle-organisation`}>
            <OrganizationModal onRequestClose={() => history.replace(path)} />
          </Route>
        </Route>
        <Route path={[`${path}/:organizationId`]}>
          <OrganizationDetail
            organizationsCount={count}
            onRequestClose={() => history.replace(path)}
          />
        </Route>
      </Switch>
    </Container>
  );
};

export default Organizations;

import React, { Fragment, useEffect, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import * as Yup from 'yup';
import Uppy from '@uppy/core';
import { DragDrop } from '@uppy/react';
import '@uppy/core/dist/style.css';
import '@uppy/drag-drop/dist/style.css';
import { EntityForm, TextInputField, AddressField, ERRORS } from 'components/v3/Form';
import RawScroller from 'components/v3/Scroller';
import { Grid, GridItem, Title, Text } from 'components/v3/ui';
import Box from 'components/v3/Box';
import SubHeader from 'components/v3/SubHeader';
import { AccountSelector, AccountAction } from 'features/Accounts';
import { MeSelector } from 'features/Me';
import { GIT_COMMIT_SHORT_SHA } from '../../constants';

const validationSchema = Yup.object().shape({
  address: Yup.string().required(ERRORS.REQUIRED)
});

const accountAsValues = (account) => ({
  id: account?.id || null,
  subdomain: account?.subdomain || '',
  displayName: account?.displayName || '',
  companyNumber: account?.subdocompanyNumbermain || '',
  address: account?.address || null,
  logoUrl: account?.logoUrl || '',
  phone: account?.phone || '',
  email: account?.email || ''
});

const Scroller = styled(RawScroller)`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`;

const Section = styled(GridItem)`
  min-width: 280px;
  @media screen and (min-width: 800px) {
    min-width: 400px;
  }
`;

const AvatarWrapper = styled.div`
  position: relative;
  display: flex;
  max-width: 282px;
  min-width: 232px;
  height: 182px;

  > div {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  * > .uppy-DragDrop-container {
    background: transparent;
    border-radius: ${({ theme }) => theme.borderInputRadius};
    border-color: ${({ theme }) => theme.borderInputColor};

    > .uppy-DragDrop-inner {
      padding: 0px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: none;
      align-items: center;
      justify-content: center;
      svg {
        fill: ${({ theme }) => theme.primaryLight};
      }
      > .uppy-DragDrop-label {
        display: none;
      }
    }
  }

  * > .uppy-DragDrop--isDraggingOver {
    border-color: ${({ theme }) => theme.primaryLight};
    > .uppy-DragDrop-inner {
      display: flex;
    }
  }

  * > .uppy-DragDrop-container {
    background: transparent;
  }

  * > .uppy-DragDrop--isDraggingOver {
    > .uppy-DragDrop-inner {
      background-color: rgba(255, 255, 255, 0.56);
    }
  }
`;

const Logo = styled.img`
  height: 150px;
  width: 100%;
  padding: 8px;
  object-fit: contain;
  margin: auto;
  display: none;
  &[src] {
    display: block;
  }
`;

const EditableLogoWrapper = styled.div`
  max-width: 250px;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  > span {
    padding-top: 4px;
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const AvatarPicker = ({ url, onChange }) => {
  const uppyRef = useRef();
  const onChangeRef = useRef();
  onChangeRef.current = onChange;

  useEffect(() => {
    uppyRef.current = Uppy({
      debug: true,
      meta: { type: 'avatar' },
      allowMultipleUploads: true,
      restrictions: {
        allowedFileTypes: ['image/*']
      },
      replaceTargetContent: true,
      autoProceed: false
    })
      .on('file-added', (file) => {
        if (onChangeRef.current) {
          onChangeRef.current(file.data);
        }
      })
      .on('upload-error', (file, error) => {})
      .on('complete', (result) => {});

    return () => {
      uppyRef.current.close();
    };
  }, []);

  return (
    <EditableLogoWrapper>
      <AvatarWrapper>
        <Logo src={url} alt="" />
        {uppyRef.current && <DragDrop uppy={uppyRef.current} target="body" locale={null} />}
      </AvatarWrapper>
      <Text variant="hint" color="hint">
        Cliquez ou glissez une image pour modifier le logo du compte
      </Text>
    </EditableLogoWrapper>
  );
};

const Account = () => {
  const dispatch = useDispatch();

  const currentAccountId = useSelector(MeSelector.selectCurrentAccountId);

  const account = useSelector(AccountSelector.selectCurrentAccount);

  const fetchRequest = useCallback(
    () => dispatch(AccountAction.getAccount(currentAccountId)),
    [currentAccountId, dispatch]
  );

  const updateRequest = useCallback(
    (_, values) => dispatch(AccountAction.updateAccount({ payload: values })),
    [dispatch]
  );

  if (!account) {
    return null;
  }

  return (
    <EntityForm
      id={currentAccountId}
      entity={account}
      selector={AccountSelector.selectCurrentAccount}
      fetchRequest={fetchRequest}
      updateRequest={updateRequest}
      entityAsValue={accountAsValues}
      validationSchema={validationSchema}
    >
      {({ setFieldValue, values, isSubmitting }) => {
        return (
          <Fragment>
            <SubHeader isLoading={isSubmitting}>
              <Title>Informations générales du compte {values.displayName}</Title>
            </SubHeader>
            <Scroller>
              <Box marged>
                <Grid spacing={16}>
                  <Section width="50%">
                    <GridItem alignCenter>
                      <AvatarPicker
                        url={(values.logo && URL.createObjectURL(values.logo)) || values.logoUrl}
                        onChange={(file) => setFieldValue('logo', file)}
                      />
                    </GridItem>
                    <GridItem width="100%" minWidth={280}>
                      <Grid spacing={16} vertical>
                        <GridItem width="100%">
                          <TextInputField name="displayName" label="Nom du compte" />
                        </GridItem>
                        <GridItem width="100%">
                          <TextInputField name="email" label="Email" />
                        </GridItem>
                        <GridItem width="100%">
                          <TextInputField name="phone" label="Numéro de téléphone" />
                        </GridItem>
                      </Grid>
                    </GridItem>
                  </Section>
                  <Section width="50%">
                    <Grid spacing={16} vertical>
                      <GridItem width="100%">
                        <AddressField name="address" mapHeight={336} mapRatio={3} useAccountAddressIfEmpty={false} />
                      </GridItem>
                    </Grid>
                  </Section>
                </Grid>
                <Text alignEnd style={{ padding: 8, display: 'block' }} variant="caption">
                  Version {GIT_COMMIT_SHORT_SHA}
                </Text>
              </Box>
            </Scroller>
          </Fragment>
        );
      }}
    </EntityForm>
  );
};

export default Account;

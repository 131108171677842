import React, {
  useCallback,
  useMemo,
  Fragment,
  useState,
  useEffect
} from 'react';
import { SelectField } from 'components/v3/Form';
import { SelectInputLayout } from 'components/v3/InputLayout';
import { useRedux } from 'components/v3/utils';
import { jsonApiToObject } from 'common/api';
import UserModal from './Modal';
import { UserSelector, UserAction, userAsOption } from 'features/Users';
import { filterUser } from 'features/Users/data/selector';
import { permissions, useAccountConfig } from 'config';

const formatCreateLabel = input => `Inviter par email ${input}`;

const userOptionsFormatter = (response, role) => {
  return (jsonApiToObject(response) || [])
    .filter(user => filterUser(user, role))
    .map(user => userAsOption(user, false));
};

const UserSelect = ({
  value,
  onChange,
  role,
  avatarHidden = false,
  ...props
}) => {
  const { accountId, hasPermission } = useAccountConfig();
  const [userModal, setUserModal] = useState();
  const roleString = (Array.isArray(role) ? role : [role])
    .filter(Boolean)
    .join('_,_');

  const selectSearchUsers = useMemo(
    () => state =>
      UserSelector.selectSearchUsers(state, { role: roleString.split('_,_') }),
    [roleString]
  );

  const [users, fetch, state] = useRedux(
    selectSearchUsers,
    UserAction.getUsers,
    {
      fetchOnMount: false,
      useLocalState: true,
      initialParams: {
        page: 1,
        perPage: 50,
        sort: 'first_name,last_name',
        useCache: true,
        isSearchRequest: true
      }
    }
  );

  const handleCreateOption = useCallback(input => {
    setUserModal({
      firstName: input
    });
  }, []);

  const handleEditOption = useCallback(value => {
    setUserModal(value);
  }, []);

  const handleSearch = useCallback(
    ({ query }) =>
      new Promise((resolve, reject) => {
        fetch(params => ({
          ...params,
          byQuery: query,
          page: 1,
          useCache: false
        }))
          .onError(reject)
          .onSuccess(res =>
            resolve(userOptionsFormatter(res, roleString.split('_,_')))
          );
      }),
    [fetch, roleString]
  );

  const options = useMemo(() => userAsOption(users), [users]);

  const currentValue = useMemo(() => userAsOption(value, avatarHidden), [
    value,
    avatarHidden
  ]);

  useEffect(() => {
    fetch(params => ({
      ...params,
      byQuery: undefined,
      page: 1,
      useCache: true
    }));
  }, [fetch, accountId]);

  return (
    <Fragment>
      <SelectInputLayout
        label="Technicien"
        value={currentValue}
        options={options}
        isLoading={state.isLoading}
        isSearchable
        loadAsyncOptions={handleSearch}
        filterOptions={false}
        onChange={onChange}
        inputCreatable={hasPermission([permissions.MANAGE_USER, 'user.create'])}
        createLabel="Inviter par email"
        formatCreateLabel={formatCreateLabel}
        onCreateOption={handleCreateOption}
        optionEditable={hasPermission([permissions.MANAGE_USER, 'user.update'])}
        onEditOptionClick={handleEditOption}
        {...props}
      />
      {userModal && (
        <UserModal
          user={{
            ...userModal,
            role: roleString.split('_,_')[0]
          }}
          onRequestClose={() => setUserModal(null)}
          onSubmitSuccess={response => {
            const user = jsonApiToObject(response);
            if (props.multi) {
              onChange([...currentValue, user]);
            } else {
              onChange(user);
            }
          }}
        />
      )}
    </Fragment>
  );
};

export const UserField = props => {
  return <SelectField component={UserSelect} {...props} />;
};

export default UserSelect;

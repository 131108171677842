import React, {
  useCallback,
  useMemo,
  useState,
  useEffect,
  useRef
} from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { addDays, startOfDay } from 'date-fns';
import isEqual from 'lodash/isEqual';
import flatten from 'lodash/flatten';
import * as Yup from 'yup';
import AutoSizer from 'react-virtualized-auto-sizer';
import Spinner from 'react-md-spinner';
import { MdNavigateBefore, MdNavigateNext } from 'react-icons/md';
import { ERRORS } from 'components/v3/Form';
import { Grid, GridItem, Icon } from 'components/v3/ui';
import { EntityModal } from 'components/v3/Modal';
import ActionGroup, { Action } from 'components/v3/ActionGroup';
import DatePicker from 'components/v3/DatePicker';
import Map from 'components/v3/Map';
import { toaster } from 'components/v3/Toast';
import { useAction } from 'components/v3/utils';
import {
  GpsBoxSelector,
  GpsBoxAction,
  gpsBoxAsValues,
  gpsHistoriesAsRoute,
  GpsBoxApi
} from 'features/GpsBoxes';
import { MachineField } from 'features/Machines/components/v3/Select';
import { parseDate, formatISODate } from 'utils/dates';
import { jsonApiToObject } from 'common/api';

const MapContainer = styled(GridItem)`
  min-height: 360px;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const Container = styled(Grid)`
  flex-direction: column;
  flex-grow: 1;
  > :not(${MapContainer}) {
    flex: 0;
  }
`;

const DateNavigationSection = styled(GridItem)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const DateNavigation = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const HistoryLoader = styled.div`
  margin-left: 8px;
  transform: scale(0);
  will-change: transform;
  color: ${({ theme }) => theme.accent};
  ${({ isLoading }) =>
    isLoading &&
    css`
      transform: scale(1);
    `}
`;

const validationSchema = Yup.object().shape({
  machine: Yup.object()
    .nullable()
    .required(ERRORS.REQUIRED)
});

const dateAsString = date => formatISODate(startOfDay(date));

const ModalContent = form => {
  const { id } = form.values;
  const [date, setDate] = useState(new Date());
  const mapRef = useRef();
  const action = useAction();
  const [historyRoutes, setHistoryRoutes] = useState({});
  const [loading, setLoading] = useState({});

  const stringDate = useMemo(() => dateAsString(date), [date]);

  const handleDateChange = useCallback(date => {
    setDate(parseDate(date));
  }, []);

  useEffect(() => {
    const date = stringDate;
    setLoading(true);
    action(cancelToken =>
      GpsBoxApi.getGpsBoxHistories({
        gpsBoxIdEq: id,
        byDay: date,
        sort: 'timestamp,created_at',
        perPage: 500,
        page: 1,
        cancelToken
      })
    )
      .onSuccess(result => {
        setHistoryRoutes(history => ({
          ...history,
          [date]: [gpsHistoriesAsRoute(jsonApiToObject(result))]
        }));
        if (mapRef.current) {
          mapRef.current.setInteracted(false);
        }
      })
      .onError(toaster.error)
      .onEnd(() => setLoading(false));
  }, [action, id, stringDate]);

  const data = useMemo(() => {
    const routes = historyRoutes[stringDate];
    if (!routes) {
      return {};
    }
    return {
      routes,
      markers: flatten(routes.map(r => r.markers))
    };
  }, [historyRoutes, stringDate]);

  return (
    <Container spacing={16} padded={false}>
      <GridItem width="100%">
        <MachineField
          name="machine"
          label="Machine associée"
          required
          optionEditable={false}
          hint="Vous pouvez changer la machine associée au gps."
        />
      </GridItem>
      <DateNavigationSection width="100%">
        <DateNavigation>
          <div style={{ width: 30 }} />
          <ActionGroup>
            <Action tooltip="Voir le jour précédent">
              <Icon onClick={() => setDate(date => addDays(date, -1))}>
                <MdNavigateBefore />
              </Icon>
            </Action>
            <Action>
              <DatePicker
                style={{ minWidth: 240 }}
                variant="flat.medium"
                label="Date"
                collapsedIfBlank
                value={date}
                dateFormat="EEEE dd MMMM yyyy"
                onChange={handleDateChange}
              />
            </Action>
            <Action tooltip="Voir le jour suivant">
              <Icon onClick={() => setDate(date => addDays(date, 1))}>
                <MdNavigateNext />
              </Icon>
            </Action>
          </ActionGroup>
          <HistoryLoader isLoading={loading}>
            <Spinner size={20} singleColor="currentColor" />
          </HistoryLoader>
        </DateNavigation>
      </DateNavigationSection>
      <MapContainer width="100%">
        <AutoSizer>
          {({ width, height }) => (
            <Map
              ref={mapRef}
              width={width}
              height={height}
              zoom={10}
              {...data}
            />
          )}
        </AutoSizer>
      </MapContainer>
    </Container>
  );
};

const GpsBoxModal = ({
  onRequestClose,
  onSubmitSuccess,
  gpsBox: propsGpsBox,
  ...props
}) => {
  const dispatch = useDispatch();
  const { params } = useRouteMatch();
  let { gpsBoxId } = params;
  if (propsGpsBox) {
    gpsBoxId = propsGpsBox.id;
  }

  const selectGpsBox = useMemo(
    () => state => GpsBoxSelector.selectGpsBox(state, { gpsBoxId }),
    [gpsBoxId]
  );

  let gpsBox = useSelector(selectGpsBox, isEqual);
  if (!gpsBox) {
    gpsBox = propsGpsBox;
  }

  const fetchRequest = useCallback(
    () => dispatch(GpsBoxAction.getGpsBox({ id: gpsBoxId })),
    [gpsBoxId, dispatch]
  );

  const updateRequest = useCallback(
    (id, payload) => dispatch(GpsBoxAction.updateGpsBox({ id, payload })),
    [dispatch]
  );

  return (
    <EntityModal
      isOpen
      onRequestClose={onRequestClose}
      title={'Détail du GPS'}
      id={gpsBoxId}
      model="gpsBox"
      entity={gpsBox}
      validationSchema={validationSchema}
      entityAsValue={gpsBoxAsValues}
      fetchRequest={fetchRequest}
      updateRequest={updateRequest}
      onSubmitSuccess={onSubmitSuccess}
      autoSubmit={false}
      expandable
      grow
      {...props}
    >
      {form => <ModalContent {...form} />}
    </EntityModal>
  );
};

export default GpsBoxModal;

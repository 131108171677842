import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { spacing, fontSize } from 'ui';
import { getContrastedTextColor } from 'utils';
import { Text } from '../ui';

const TagWrapper = styled(Text).attrs(({ style, color }) => ({
  style: {
    color: getContrastedTextColor(color),
    backgroundColor: color,
    ...(style || {})
  }
}))`
  display: flex;
  align-items: center;
  color: #fff;
  text-transform: uppercase;
  font-size: ${fontSize(12)};
  border-radius: ${({ theme }) => theme.borderButtonRadius};
  background-color: ${({ color }) => color};
  font-weight: 600;
  padding: 2px ${spacing(0.5)};
  max-width: 150px;
  text-overflow: ellipsis;
  overflow: hidden;
  min-height: 22px;
  border: 1px solid transparent;

  svg {
    height: 1rem;
    width: 1rem;
    min-height: 1rem;
    min-width: 1rem;
  }

  ${({ wide }) =>
    wide === 'true' &&
    css`
      max-width: 220px;
    `}

  ${props =>
    props['trailing-icon'] === 'true' &&
    css`
      padding-right: 2px;
    `}

  ${({ wrap }) =>
    wrap !== 'true' &&
    css`
      white-space: nowrap;
    `}

  ${({ clickable, theme }) =>
    clickable === 'true' &&
    css`
      &:hover {
        opacity: 0.7;
        border-color: ${theme.hoverColorBlacker};
      }
      cursor: pointer;
    `}

  > i {
    width: 16px;
    height: 16px;
    > svg {
      width: 16px;
      height: 16px;
    }
  }

  ${({ size }) =>
    size === 'big' &&
    css`
      font-size: ${fontSize(16)};
      padding: ${spacing(0.5)} ${spacing(1)};

      ${props =>
        props['trailing-icon'] === 'true' &&
        css`
          padding-right: ${spacing(0.5)};
        `}

        min-height: calc(${spacing(0.5 * 2)} + 28px);

      > i {
        width: 28px;
        height: 28px;
        > svg {
          width: 28px;
          height: 28px;
        }
      }
    `};

  ${({ size }) =>
    size === 'medium' &&
    css`
      font-size: ${fontSize(14)};
      padding: ${spacing(0.25)} ${spacing(0.75)};

      ${props =>
        props['trailing-icon'] === 'true' &&
        css`
          padding-right: ${spacing(0.25)};
        `}

      min-height: calc(${spacing(0.25 * 2)} + 22px);

      > i {
        width: 22px;
        height: 22px;
        > svg {
          width: 22px;
          height: 22px;
        }
      }
    `};
`;

const TagContent = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  ${({ wrap }) =>
    wrap !== 'true' &&
    css`
      white-space: nowrap;
    `}
`;

export const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  align-items: center;
  margin-left: ${spacing(-0.5)};
  margin-top: ${spacing(-0.5)};

  ${({ alignEnd }) =>
    alignEnd &&
    css`
      justify-content: flex-end;
    `}

  > * {
    margin-left: ${spacing(0.5)};
    margin-top: ${spacing(0.5)};
  }
`;

const Tag = forwardRef(
  (
    {
      displayName,
      color,
      onClick,
      clickable,
      size,
      wide,
      wrap,
      trailingIcon: TrailingIcon,
      ...props
    },
    ref
  ) => {
    return (
      <TagWrapper
        {...props}
        ref={ref}
        clickable={Boolean(clickable || Boolean(onClick)).toString()}
        onClick={onClick}
        size={size}
        wide={Boolean(wide).toString()}
        wrap={Boolean(wrap).toString()}
        color={color}
        trailing-icon={Boolean(TrailingIcon).toString()}
      >
        <TagContent wrap={Boolean(wrap).toString()}>{displayName}</TagContent>
        {typeof TrailingIcon === 'function' ? <TrailingIcon /> : TrailingIcon}
      </TagWrapper>
    );
  }
);

export const Tags = ({ alignEnd, tags, ...props }) => {
  return (
    <TagContainer alignEnd={alignEnd} {...props}>
      {tags.filter(Boolean).map(tag => (
        <Tag key={tag.displayName} {...tag} />
      ))}
    </TagContainer>
  );
};

export default React.memo(Tag);

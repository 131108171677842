import React, { Fragment, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Redirect, useLocation, useHistory, Route } from 'react-router';
import loadable from '@loadable/component';
import styled from 'styled-components';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import flatten from 'lodash/flatten';
import { MdSettings, MdArrowDropDown } from 'react-icons/md';
import { GiCrane, GiRoad, GiMineTruck } from 'react-icons/gi';
import { FaUser, FaRegCalendarAlt, FaUsers } from 'react-icons/fa';
import { Permission, useAccountConfig, features, permissions } from 'config';
import whiteLogo from 'common/images/logo-white.svg';
import { IcTimeline } from 'common/images/icons';
import { Icon } from 'components/v3/ui';
import Menu, { MenuItem } from 'components/v3/Menu';
import AccountSwitcher from 'components/AccountSwitcher';
import * as Ui from './ui';
import { MeSelector } from 'features/Me';
import { UiSelector } from 'features/UiPreferences';
import RawAvatar from 'components/v3/Avatar';
import { fontSize } from 'ui';
import { AuthAction } from 'features/Auth';
import { Project, Machine, Team, Member, Calendar } from 'pages/Plannings';
import Settings from 'pages/Settings';
import Administration from 'pages/Administration';
import ProjectDetail from 'pages/Projects/v3/Detail';

const Avatar = styled(RawAvatar)`
  background-color: white;
  span {
    color: ${({ theme }) => theme.primary};
    font-weight: 600;
    font-size: ${fontSize(14)};
  }
`;

const APP_MENU = [
  {
    label: 'Plannings',
    kind: 'planning',
    children: [
      {
        position: 0,
        label: 'Projets',
        pathname: 'plannings/projets',
        features: [features.PLANNING_PROJECTS],
        Icon: IcTimeline,
        Component: Project
      },
      {
        position: 1,
        label: 'Machines',
        pathname: 'plannings/machines',
        features: [features.PLANNING_MACHINES],
        Icon: GiCrane,
        Component: Machine
      },
      {
        position: 2,
        label: 'Équipes',
        pathname: 'plannings/equipes',
        features: [features.PLANNING_TEAMS],
        Icon: FaUsers,
        Component: Team
      },
      {
        position: 3,
        label: 'Techniciens',
        pathname: 'plannings/techniciens',
        features: [features.PLANNING_MEMBERS],
        Icon: FaUser,
        Component: Member
      },
      {
        position: 4,
        label: 'Calendrier',
        pathname: 'plannings/calendrier',
        features: [features.PLANNING_CALENDAR],
        Icon: FaRegCalendarAlt,
        Component: Calendar
      }
    ]
  },
  {
    label: 'Commandes',
    kind: 'commandes',
    permissions: [permissions.ORDERS_VIEW],
    children: [
      {
        position: 0,
        label: 'Produits',
        pathname: 'commandes/produits',
        features: [features.ORDERS_COATING],
        Icon: GiRoad,
        Component: loadable(() => import('pages/Orders/SurfacingOrders'))
        // Component: SurfacingOrders
      },
      {
        position: 1,
        label: 'Matières premières',
        pathname: 'commandes/matieres-premieres',
        features: [features.ORDERS_COATING],
        Icon: GiRoad,
        Component: loadable(() => import('pages/Orders/RawMaterialOrders'))
        // Component: SurfacingOrders
      },
      {
        position: 2,
        label: 'Transports',
        pathname: 'commandes/transporteurs',
        features: [features.ORDERS_DELIVERIES],
        Icon: GiMineTruck,
        Component: loadable(() => import('pages/Orders/CarrierOrders'))
        // Component: CarrierOrders
      }
    ]
  },
  {
    label: 'Projets',
    pathname: 'projets',
    features: [features.PROJECT_LIST, features.PROJECT_TABLE],
    Component: loadable(() => import('pages/Projects'))
    // Component: Projects
  },
  {
    label: 'Signalements',
    pathname: 'signalements',
    features: [features.ISSUES],
    Component: loadable(() => import('pages/Issues/v3'))
    // Component: Issues
  },
  {
    label: 'Carte',
    pathname: 'carte',
    features: [features.GLOBAL_MAP],
    Component: loadable(() => import('pages/Maps/v3'))
    // Component: Maps
  },
  {
    label: 'Groupes',
    pathname: 'groupes',
    features: [features.VOUCHERS],
    Component: loadable(() => import('pages/Administration/Groups'))
    // Component: Projects
  }
];

const defaultMenuRoute = ({ pathname, children }) => {
  const redirect = children && children.find((c) => c.default);
  if (redirect) {
    return redirect.pathname;
  }
  return children ? children[0].pathname : pathname;
};

const Navbar = ({ isPublicPage, ...props }) => {
  const { hasFeature, hasPermission } = useAccountConfig();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const currentUser = useSelector(MeSelector.selectMe, isEqual);
  const selectUi = useMemo(() => UiSelector.selectUiKeys('defaultFeature'), []);
  const { defaultFeature } = useSelector(selectUi, isEqual);
  const isLoadingAccounts = useSelector((state) => state.accounts.isLoading);

  const menu = useMemo(() => {
    return APP_MENU.filter((item) => hasFeature(item.features) && hasPermission(item.permissions))
      .map((item) => ({
        ...item,
        children:
          item.children &&
          sortBy(
            item.children
              .filter((child) => hasFeature(child.features) && hasPermission(item.permissions))
              .map((child) => ({
                ...child,
                default: defaultFeature && child.features?.includes(defaultFeature)
              })),
            'position'
          )
      }))
      .filter(({ children }) => (children ? children.length > 0 : true));
  }, [hasFeature, hasPermission, defaultFeature]);

  const menuRoutes = useMemo(() => {
    const routes = [
      ...flatten(menu.map((menu) => [menu, ...(menu.children || [])])).filter((route) => route && route.pathname),
      {
        pathname: 'parametres',
        Component: Settings
      },
      {
        pathname: 'administration',
        permission: permissions.ADMINISTRATION_VIEW,
        Component: Administration
      }
    ];

    let defaultRoute = null;
    if (defaultFeature) {
      const routeFeature = routes.find((route) => route.features && route.features.includes(defaultFeature));
      if (routeFeature) {
        defaultRoute = {
          ...routeFeature,
          redirect: true
        };
      } else {
        defaultRoute = { ...menu[0], redirect: true };
      }
    }
    return [...routes, defaultRoute].filter(Boolean);
  }, [menu, defaultFeature]);

  const routes = useMemo(
    () =>
      menuRoutes.map(({ exact, pathname, Component, redirect }) => {
        if (redirect) {
          return <Redirect key="redirect" to={`/${pathname}`} />;
        }
        return (
          <Permission exact={exact} key={pathname} path={`/${pathname}`}>
            <Component />
          </Permission>
        );
      }),
    [menuRoutes]
  );

  if (menu.length === 0 && !isPublicPage) {
    return null;
  }

  return (
    <Fragment>
      <Ui.Wrapper>
        <Ui.Logo exact to={menu[0] ? `/${defaultMenuRoute(menu[0])}` : '/'}>
          <img src={whiteLogo} alt="Roadmapper" />
        </Ui.Logo>
        {!isPublicPage && (
          <Fragment>
            <Ui.Menu left>
              {menu.map(({ label, pathname, children }) => {
                const hasChildren = children && children.length > 0;
                return (
                  <Ui.MenuItem key={label}>
                    <Ui.MenuItemLink
                      variant="textLight"
                      main="true"
                      type="text"
                      has-submenu={hasChildren ? 'true' : null}
                      isActive={() =>
                        Boolean(
                          hasChildren
                            ? children.find((i) => location.pathname.startsWith(`/${i.pathname}`))
                            : location.pathname.startsWith(`/${pathname}`)
                        )
                      }
                      to={`/${defaultMenuRoute({ pathname, children })}`}
                    >
                      {label}
                      {children && (
                        <Icon size="small">
                          <MdArrowDropDown />
                        </Icon>
                      )}
                    </Ui.MenuItemLink>

                    {children && (
                      <Ui.SubMenu key={pathname}>
                        {children.map(({ label, pathname, Icon }) => (
                          <Ui.MenuItem key={pathname}>
                            <Ui.MenuItemLink
                              type="text"
                              variant="textLight"
                              isActive={() => location.pathname.startsWith(`/${pathname}`)}
                              submenu="true"
                              icon={Icon}
                              to={`/${pathname}`}
                            >
                              {label}
                            </Ui.MenuItemLink>
                          </Ui.MenuItem>
                        ))}
                      </Ui.SubMenu>
                    )}
                  </Ui.MenuItem>
                );
              })}
            </Ui.Menu>
            <Ui.Menu right>
              <Permission permission={permissions.ADMINISTRATION_VIEW}>
                <Ui.MenuItem padded>
                  <Ui.MenuItemLink
                    type="text"
                    variant="icon"
                    to="/administration"
                    iconSize="medium"
                    icon={MdSettings}
                    isActive={() => location.pathname.startsWith('/administration')}
                  />
                </Ui.MenuItem>
              </Permission>
              <Ui.MenuItem>
                <Menu>
                  <Ui.MenuItemLink variant="textLight">
                    <Avatar user={currentUser} size="small" />
                  </Ui.MenuItemLink>
                  <MenuItem to="/parametres/profil">Mon profil</MenuItem>
                  <MenuItem
                    onClick={() => {
                      dispatch(AuthAction.logout());
                      history.replace('/connexion');
                    }}
                  >
                    Déconnexion
                  </MenuItem>
                </Menu>
              </Ui.MenuItem>
              <Ui.MenuItem>
                <AccountSwitcher />
              </Ui.MenuItem>
            </Ui.Menu>
          </Fragment>
        )}
      </Ui.Wrapper>
      <Switch>
        {props.children}
        {!hasFeature([features.PROJECT_LIST, features.PROJECT_TABLE]) && (
          <Route path="/projets/:projectId">
            <ProjectDetail />
          </Route>
        )}
        {!isLoadingAccounts && routes}
      </Switch>
    </Fragment>
  );
};

export default React.memo(Navbar);

import { Button } from 'components/v3/ui';
import React from 'react';
import { MdAdd } from 'react-icons/md';
import styled from 'styled-components';

const AddLineButton = styled.div`
  align-self: flex-end;
`.withComponent(Button);

export const AddNewLine = ({ readOnly, onClick }) => {
  if (readOnly) return null;

  return (
    <AddLineButton variant="text" iconLeading={MdAdd} onClick={onClick}>
      Ajouter une ligne
    </AddLineButton>
  );
};

export const AddNewSeparator = ({ readOnly, onClick }) => {
  if (readOnly) return null;
  return (
    <AddLineButton variant="text" iconLeading={MdAdd} onClick={onClick}>
      Ajouter un séparateur
    </AddLineButton>
  );
};

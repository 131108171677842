import axios from 'axios';
import { camelizeKeys } from 'humps';
import { MAP_BOX_TOKEN } from '../constants';
import keyBy from 'lodash/keyBy';
var polyline = require('@mapbox/polyline');

const mapbox = axios.create({
  baseURL: 'https://api.mapbox.com',
  params: {
    access_token: MAP_BOX_TOKEN
  }
});

export const searchAddress = input => {
  if (input) {
    return mapbox
      .get(`/geocoding/v5/mapbox.places/${input}.json`, {
        params: {
          bbox: '-5.5,40,9,51.5', // la france en gros
          proximity: '3,50.6', // trié par proximité à lille
          access_token: MAP_BOX_TOKEN,
          place_type: 'address'
        }
      })
      .then(res =>
        camelizeKeys(res.data.features).map(transformMapboxResultAsAddress)
      );
  } else {
    return new Promise(() => []);
  }
};

export const getRouteFromWaypoints = waypoints => {
  return mapbox
    .get(
      `/directions/v5/mapbox/driving/${waypoints
        .map(point => point.reverse().join(','))
        .join(';')}`,
      {
        params: {
          access_token: MAP_BOX_TOKEN
        }
      }
    )
    .then(res => {
      let result = res.data;
      if (result.routes && result.routes.length > 0) {
        const route = result.routes[0];
        if (route.geometry) {
          return polyline.decode(route.geometry);
        } else {
          return waypoints;
        }
      } else {
        return waypoints;
      }
    })
    .then(points => {
      return points.filter(
        (point, index, array) =>
          array.findIndex(p => point[0] === p[0] && point[1] === p[1]) === index
      );
    });
};

const transformMapboxResultAsAddress = feature => {
  let { placeName, text, placeType, context, center } = feature;

  const attributes = keyBy(context, c => c.id.split('.')[0]);
  let zipCode = attributes['postcode'];
  if (zipCode && zipCode.text) {
    zipCode = zipCode.text;
  }
  let city = attributes['place'];
  if (city && city.text) {
    city = city.text;
  }
  let country = attributes['country'];
  if (country && country.text) {
    country = country.text;
  }
  if (placeType.includes('place')) {
    city = text;
  } else if (placeType.includes('postcode')) {
    zipCode = text;
  }

  let content = placeName;
  if (content) {
    content = content.replace(`, ${zipCode}`, `\n${zipCode}`);
    content = content.replace(`, ${country}`, `\n${country}`);
  }
  return {
    key: feature.id,
    label: placeName,
    zipCode: zipCode || '',
    city: city || '',
    country: country || '',
    lat: center[1],
    lng: center[0],
    content: content
  };
};

import React, { useCallback, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import * as Yup from 'yup';
import { TextInputField, ERRORS } from 'components/v3/Form';
import { EntityModal } from 'components/v3/Modal';
import { Grid, GridItem } from 'components/v3/ui';
import { SurfacingSelector, SurfacingAction, rawMaterialAsValues } from 'features/Surfacing';
import { permissions, useAccountConfig } from 'config';

const validationSchema = Yup.object().shape({
  name: Yup.string().required(ERRORS.REQUIRED)
});

const RawMaterialModal = ({ onRequestClose, onSubmitSuccess, rawMaterial: propsRawMaterial, ...props }) => {
  const { hasPermission } = useAccountConfig();
  const dispatch = useDispatch();
  const { params } = useRouteMatch();
  let { rawMaterialId } = params;
  if (propsRawMaterial) {
    rawMaterialId = propsRawMaterial.id;
  }
  const isNew = !Boolean(rawMaterialId);

  const selectRawMaterial = useMemo(
    () => (state) => SurfacingSelector.selectRawMaterial(state, { rawMaterialId }),
    [rawMaterialId]
  );

  let rawMaterial = useSelector(selectRawMaterial, isEqual);
  if (!rawMaterial) {
    rawMaterial = propsRawMaterial;
  }

  const createRequest = useCallback((payload) => dispatch(SurfacingAction.createRawMaterial({ payload })), [dispatch]);

  const updateRequest = useCallback(
    (id, payload) => dispatch(SurfacingAction.updateRawMaterial({ id, payload })),
    [dispatch]
  );

  const deleteRequest = useCallback((id) => dispatch(SurfacingAction.deleteRawMaterial({ id })), [dispatch]);

  const canEdit = hasPermission([permissions.RESOURCES_CONFIG, isNew ? 'rawMaterial.create' : 'rawMaterial.update']);
  const canDelete = hasPermission([permissions.RESOURCES_CONFIG, 'rawMaterial.delete']);

  return (
    <EntityModal
      isOpen
      onRequestClose={onRequestClose}
      title={isNew ? 'Nouvelle matière première' : 'Matière première'}
      id={rawMaterialId}
      size="small"
      model="rawMaterial"
      entity={rawMaterial}
      validationSchema={validationSchema}
      entityAsValue={rawMaterialAsValues}
      createRequest={createRequest}
      updateRequest={updateRequest}
      deleteRequest={canDelete ? deleteRequest : undefined}
      onSubmitSuccess={onSubmitSuccess}
      autoSubmit={false}
      readOnly={!canEdit}
      {...props}
    >
      {() => (
        <Grid spacing={16} padded={false}>
          <GridItem width="50%" minWidth={240}>
            <TextInputField name="name" label="Nom" required />
          </GridItem>
        </Grid>
      )}
    </EntityModal>
  );
};

export default RawMaterialModal;

import { useMemo } from 'react';
import { printDate, printDateRange } from 'utils/dates';
import {
  addDays,
  addWeeks,
  endOfWeek,
  getWeeksInMonth,
  isSameMonth,
  isSameWeek,
  startOfMonth,
  startOfWeek
} from 'date-fns';

export const useMonthDates = ({ date, data }) => {
  return useMemo(() => {
    const start = startOfMonth(date);
    const generateDays = date => {
      const days = [];
      let currDate = date;
      while (isSameWeek(currDate, date, { weekStartsOn: 1 })) {
        const dateKey = printDate(currDate, 'yyyy-MM-dd');
        if (data && data[dateKey] && isSameMonth(start, currDate)) {
          let dayFormat = 'EEEE d';
          days.push({
            date: currDate,
            dateKey: dateKey,
            label: printDate(currDate, dayFormat),
          });
        }
        currDate = addDays(currDate, 1);
      }
      return days;
    };
    return new Array(getWeeksInMonth(start, { weekStartsOn: 1 }))
      .fill({})
      .map((_, idx) => {
        const week = startOfWeek(addWeeks(start, idx), { weekStartsOn: 1 });
        return {
          week: {
            date: week,
            label: printDate(week, "'Semaine' I"),
            description: printDateRange([
              week,
              endOfWeek(week, { weekStartsOn: 1 })
            ])
          },
          days: generateDays(week)
        };
      }).filter(({ days }) => days.length > 0);
  }, [date, data]);
};

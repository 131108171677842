import { createAction } from 'redux-actions';
import omit from 'lodash/omit';
import * as Api from './api';
import { prepareAttributes, shouldFetch } from 'common/actions';

const preparePayload = payload =>
  prepareAttributes(
    {
      ...payload,
      activities: payload.activities?.filter(
        a => Boolean(a.displayName) || a._destroy
      )
    },
    'activities'
  );

const projectKindsLoading = createAction('PROJECT_KINDS_LOADING');
const projectKindsSuccess = createAction('PROJECT_KINDS_SUCCESS');
const projectKindsError = createAction('PROJECT_KINDS_ERROR');
export const getProjectKinds = payload => async (dispatch, getState) => {
  const { useCache, ...params } = payload || {};
  const state = getState();
  if (!useCache || shouldFetch('projectKind', params, state)) {
    return fetchProjectKinds(params, dispatch);
  } else {
    return {
      fromCache: true,
      isLoading: getState().projectKinds.isLoading
    };
  }
};
const fetchProjectKinds = async (payload, dispatch) => {
  dispatch(projectKindsLoading());
  try {
    const result = await Api.getProjectKinds(payload);
    dispatch(
      projectKindsSuccess({
        ...result,
        requestParams: omit(payload, 'cancelToken')
      })
    );
    return result;
  } catch (error) {
    dispatch(projectKindsError(error));
    throw error;
  }
};

const receiveCreateProjectKind = createAction('NEW_PROJECT_KIND_SUCCESS');
export const createProjectKind = ({ payload, ...config }) => async dispatch => {
  const result = await Api.createProjectKind(preparePayload(payload), config);
  dispatch(receiveCreateProjectKind(result));
  return result;
};

const receiveProjectKind = createAction('PROJECT_KIND_SUCCESS');
export const getProjectKind = ({ id, ...payload }) => async dispatch => {
  const result = await Api.getProjectKind(id, payload);
  dispatch(receiveProjectKind(result));
  return result;
};

const receiveUpdateProjectKind = createAction('UPDATE_PROJECT_KIND_SUCCESS');
export const updateProjectKind = ({
  id,
  payload: projectKind,
  ...config
}) => async dispatch => {
  const result = await Api.putProjectKind(
    id,
    preparePayload(projectKind),
    config
  );
  dispatch(receiveUpdateProjectKind(result));
  return result;
};

const receiveDeleteProjectKind = createAction('DELETE_PROJECT_KIND_SUCCESS');
export const deleteProjectKind = ({ id, ...config }) => async dispatch => {
  const response = await Api.deleteProjectKind(id, config);
  dispatch(receiveDeleteProjectKind(id));
  return response;
};

import React, { useCallback, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import * as Yup from 'yup';
import { TextInputField, ERRORS } from 'components/v3/Form';
import { Grid, GridItem } from 'components/v3/ui';
import { EntityModal } from 'components/v3/Modal';
import {
  CarrierSelector,
  CarrierAction,
  carriageTypeAsValues
} from 'features/Carriers';

const validationSchema = Yup.object().shape({
  displayName: Yup.string().required(ERRORS.REQUIRED)
});

const CarriageTypeModal = ({
  onRequestClose,
  onSubmitSuccess,
  carriageType: propsCarriageType,
  ...props
}) => {
  const dispatch = useDispatch();
  const { params } = useRouteMatch();
  let { carriageTypeId } = params;
  if (propsCarriageType) {
    carriageTypeId = propsCarriageType.id;
  }
  const isNew = !Boolean(carriageTypeId);

  const selectCarriageType = useMemo(
    () => state =>
      CarrierSelector.selectCarriageType(state, { carriageTypeId }),
    [carriageTypeId]
  );

  let carriageType = useSelector(selectCarriageType, isEqual);
  if (!carriageType) {
    carriageType = propsCarriageType;
  }

  const createRequest = useCallback(
    payload => dispatch(CarrierAction.createCarriageType({ payload })),
    [dispatch]
  );

  const fetchRequest = useCallback(
    () => dispatch(CarrierAction.getCarriageType({ id: carriageTypeId })),
    [carriageTypeId, dispatch]
  );

  const updateRequest = useCallback(
    (id, payload) =>
      dispatch(CarrierAction.updateCarriageType({ id, payload })),
    [dispatch]
  );

  const deleteRequest = useCallback(
    id => dispatch(CarrierAction.deleteCarriageType({ id })),
    [dispatch]
  );

  return (
    <EntityModal
      isOpen
      size="small"
      onRequestClose={onRequestClose}
      title={isNew ? 'Nouveau type de camion' : 'Édition du type de camion'}
      id={carriageTypeId}
      model="carriageType"
      entity={carriageType}
      validationSchema={validationSchema}
      entityAsValue={carriageTypeAsValues}
      fetchRequest={fetchRequest}
      createRequest={createRequest}
      updateRequest={updateRequest}
      deleteRequest={deleteRequest}
      onSubmitSuccess={onSubmitSuccess}
      autoSubmit={false}
      {...props}
    >
      {() => (
        <Grid spacing={16} padded={false}>
          <GridItem width="100%">
            <TextInputField name="displayName" label="Nom" required />
          </GridItem>
        </Grid>
      )}
    </EntityModal>
  );
};

export default CarriageTypeModal;

import React, { useMemo, useCallback } from 'react';
import PhasesBox from 'features/Phases/components/v3/Box';
import { PhaseAction, PhaseSelector } from 'features/Phases';

const defaultParams = { sort: '-start_at' };

const Phases = ({ projectId }) => {
  const phasesSelector = useMemo(
    () => state => PhaseSelector.selectProjectPhases(state, { projectId }),
    [projectId]
  );

  const fetchAction = useCallback(
    params => PhaseAction.getPhases({ ...params, projectIdEq: projectId }),
    [projectId]
  );

  return (
    <PhasesBox
      fetchAction={fetchAction}
      selector={phasesSelector}
      creatable
      marged
      params={defaultParams}
      projectId={projectId}
      maxHeight={500}
      minRows={5}
      detailUrlPrefix=""
    />
  );
};

export default Phases;

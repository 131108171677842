import { useRedux, useDeepEffect } from './';
import { Options } from './useRedux';
import { DefaultRootState } from 'react-redux';
import { useAccountConfig } from 'config';
import { isNil, omitBy } from 'lodash';

interface Filter {
  kind: string;
  value?: any;
  asQueryParam: (value?: object) => object;
}

interface Props {
  selector?: (state: DefaultRootState) => any;
  action?: (params?: any | null) => Promise<any>;
  options?: Options & { withoutPrevParams?: boolean };
  dependencies?: any;
  params?: any;
  filters?: Array<Filter>;
  perPage: number;
  withoutPrevParams?: boolean;
}

const useListAutoFetcher = ({
  selector,
  action,
  options = {
    fetchOnMount: false,
    useLocalState: true,
    withoutPrevParams: false
  },
  dependencies,
  params,
  filters,
  perPage = 25
}: Props) => {
  const { accountId }: any = useAccountConfig();
  const { withoutPrevParams } = options;

  const [items, fetch, state] = useRedux(selector, action, {
    ...options,
    initialParams: {
      ...(params || {}),
      perPage,
      page: 1
    }
  });

  useDeepEffect(() => {
    fetch((prevParams: any) => {
      return omitBy(
        {
          ...(withoutPrevParams ? {} : prevParams),
          ...params,
          page: 1,
          ...(filters || []).reduce((acc, filter) => {
            return {
              ...acc,
              ...filter.asQueryParam(filter.value)
            };
          }, {})
        },
        isNil
      );
    });
  }, [fetch, accountId, dependencies, params, filters, withoutPrevParams]);

  return [
    items,
    {
      total: state.pagination?.total,
      hasMoreItems: !state.isLoading && state.pagination?.hasMoreItems,
      loadMoreItems: state.pagination?.loadMoreItems
    },
    state,
    fetch
  ];
};

export default useListAutoFetcher;

import React, { useState, useCallback } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { MdAdd } from 'react-icons/md';
import SubHeader from 'components/v3/SubHeader';
import { FilterBar } from 'components/v3/Filters';
import { Container, Title, Button } from 'components/v3/ui';
import { CarrierSelector, CarrierAction } from 'features/Carriers';
import CarrierList from 'features/Carriers/components/v3/List';

const defaultParams = { sort: 'displayName' };

const Carrier = () => {
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  const [count, setCount] = useState();
  const [filters, setFilters] = useState();

  const handleDelete = useCallback(
    item => dispatch(CarrierAction.deleteCarrier({ id: item.id })),
    [dispatch]
  );

  return (
    <Container vertical>
      <SubHeader>
        <Title>{`Transporteurs${count != null ? ` (${count})` : ''}`}</Title>
        <Button small to={`${path}/nouveau-transporteur`} icon={MdAdd}>
          Nouveau transporteur
        </Button>
      </SubHeader>
      <Container vertical huge padded>
        <FilterBar
          big
          searchQuery="displayNameContAny"
          onChanges={setFilters}
        />
        <CarrierList
          spacing={8}
          headerVariant="transparent"
          selector={CarrierSelector.selectCarriers}
          action={CarrierAction.getCarriers}
          onCountChanged={setCount}
          params={defaultParams}
          filters={filters}
          perPage={50}
          detailUrlPrefix=""
          isDeletable
          deleteRequest={handleDelete}
        />
      </Container>
    </Container>
  );
};

export default Carrier;

import React, { useState, useCallback, useMemo } from 'react';
import { Marker as RawMarker } from 'react-map-gl';
import styled, { css } from 'styled-components';
import { MdDelete } from 'react-icons/md';
import { lightenDarkenRgb, authenticateUrl } from 'utils';
import { theme } from 'ui';
import { SIGN_SIZE } from 'features/Projects/components/v3/Deviation/utils';

let defaultPinStyle = {
  stroke: 'none',
  fill: '#3943ae'
};

const pinStyle = marker => {
  const { highlighted, color, kind, item } = marker;

  let style = {
    fill: color || defaultPinStyle.fill
  };
  switch (kind) {
    case 'issue':
    case 'project':
      style = {
        ...style,
        fill: item?.group?.color || defaultPinStyle.fill
      };
      break;
    case 'gpsBox':
    case 'gpsPoint':
      style = {
        ...style,
        cursor: 'pointer'
      };
      break;
    default:
    //nothing
  }
  if (highlighted) {
    return {
      ...defaultPinStyle,
      fill: defaultPinStyle.fill,
      stroke: theme.primary,
      strokeWidth: '1',
      height: '60'
    };
  }
  return { ...defaultPinStyle, ...style };
};

export const Pin = ({ onClick, ...marker }) => {
  return (
    <svg
      onClick={onClick}
      height={SIGN_SIZE}
      viewBox="0 0 24 24"
      style={pinStyle(marker)}
    >
      <path d="M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z" />
    </svg>
  );
};

const PointContainer = styled.div`
  display: flex;
  align-items: center;
  height: 12px;
  border-radius: 14px;

  &:hover {
    height: 28px;
    padding: 2px 8px;
    margin-left: -8px;
    margin-top: -8px;

    ${props =>
      props.height &&
      css`
        height: ${props.height}px;
        margin-left: -8px;
        margin-top: calc((-${props.height}px + 12px) / 2);
      `}

    background: rgba(0, 0, 0, 0.6);
    color: #fff;
  }
`;

const SuppressibleMarker = ({ onClick, height, onDeleteClick, ...props }) => {
  const [isHover, setHover] = useState(false);
  if (!props.isEditable) {
    return props.render ? props.render({ isHover: false }) : props.children;
  }
  return (
    <PointContainer
      height={height}
      style={{ cursor: 'move' }}
      captureClick={true}
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {props.render ? props.render({ isHover }) : props.children}
      {isHover && (
        <MdDelete
          id={'handled'}
          onClick={onDeleteClick}
          style={{ cursor: 'pointer', marginLeft: '8px' }}
          size={'24px'}
        />
      )}
    </PointContainer>
  );
};

const pinRenderer = (marker, onClick, onDeleteClick) => {
  const { kind, item } = marker;
  switch (kind) {
    case 'deviation_sign':
    case 'sign_road':
    case 'custom': {
      const { width, height, name } = item;
      return (
        <SuppressibleMarker
          isEditable={marker.draggable}
          height={height}
          captureClick={true}
          onDeleteClick={e => onDeleteClick && onDeleteClick(marker)}
        >
          <img
            height={height}
            width={width}
            onDragStart={e => e.preventDefault()}
            src={
              kind === 'deviation_sign'
                ? item.src
                : authenticateUrl(
                    (item.attachment && item.attachment.fileUrl) || item.fileUrl
                  )
            }
            alt={name}
          />
        </SuppressibleMarker>
      );
    }
    case 'point': {
      return (
        <SuppressibleMarker
          isEditable={marker.draggable}
          onDeleteClick={e => onDeleteClick && onDeleteClick(marker)}
          render={({ isHover }) => (
            <svg height="14" width="14">
              <circle
                cx={isHover ? 6.5 : 6}
                cy={isHover ? 6.5 : 6}
                r="6"
                strokeWidth={isHover ? 1 : 0}
                stroke="#fff"
                fill={lightenDarkenRgb(marker.color, -15)}
              />
              Sorry, your browser does not support inline SVG.
            </svg>
          )}
        />
      );
    }
    case 'gpsPoint': {
      return (
        <svg height="14" width="14" onClick={() => onClick && onClick(marker)}>
          <circle
            cx={6}
            cy={6}
            r="6"
            strokeWidth={0}
            stroke="#fff"
            fill="#3943ae"
          />
        </svg>
      );
    }
    case 'gpsBox': {
      return (
        <svg
          onClick={() => onClick && onClick(marker)}
          viewBox="0 0 1000 1000"
          style={pinStyle(marker)}
          height={50}
        >
          <path d="M311.52 190.74c-73.5 7.45-135.95 33.41-207.66 86.61-13.36 9.51-23.64 17.99-23.13 18.5 1.54 1.54 384.99-63.74 384.99-65.54 0-2.83-41.12-22.1-59.11-27.76-28.53-8.98-70.42-14.12-95.09-11.81zM328.22 269.38c-173.73 29.56-316.88 54.48-318.17 55.77-1.03 1.03 12.85 55 31.1 120.02l32.9 117.96 245.95.77 245.95.52.51-147.01.77-147.26 53.71-9c29.56-4.88 54.23-10.28 54.74-11.56.51-1.54-6.43-9.77-15.16-18.25l-16.19-15.68-316.11 53.72z" />
          <path d="M639.71 272.46c-17.22 4.63-32.38 15.93-41.63 31.1l-7.71 12.34-.77 138.53-.77 138.27H333.88c-196.35.26-255.97.77-259.06 3.34-7.71 5.91-10.02 14.39-10.02 37.52 0 21.08.51 23.64 6.68 30.84l6.68 7.97H143.7l-1.29 17.73c-4.11 57.83 38.29 110.25 95.6 118.22 70.68 10.02 131.07-46.26 126.96-118.22l-1.03-17.73H696.5l-1.29 15.42c-3.08 32.38 7.97 62.71 32.13 87.89 69.9 73.25 193.01 20.56 190.44-81.47l-.51-20.56 29.81-.77c29.56-.77 29.81-.77 36.24-8.48 5.91-6.94 6.68-10.02 6.68-28.27 0-13.11-1.29-23.13-3.86-27.76-4.11-7.97-15.16-13.11-28.53-13.11h-8.74v-60.4c0-65.79-.51-68.11-14.65-81.73-9.25-8.48-20.56-12.08-39.58-12.08H880l-2.83-16.19c-1.54-8.74-6.17-38.29-10.54-65.79-4.37-27.5-9.51-52.94-11.56-56.54-4.63-8.74-18.25-21.59-29.56-27.5-8.22-4.37-16.71-4.88-91.24-5.4-61.92-.51-85.31.26-94.56 2.83zm174.5 40.35c6.42 6.42 7.2 10.54 14.65 66.31 4.37 32.64 7.2 59.88 6.68 60.4-.51.51-45.75.26-100.49-.51l-99.72-1.29v-56.03c0-38.81 1.03-58.08 3.08-62.97 5.91-13.11 12.08-13.88 93.81-13.11l75.3.51 6.69 6.69zM275.79 635.1c49.6 16.96 59.62 87.38 16.71 116.16-6.68 4.63-17.22 9.77-22.87 11.31-29.04 7.71-63.74-9.25-76.59-37.52-5.4-11.57-6.43-37.78-2.06-49.35 12.6-33.92 50.63-52.17 84.81-40.6zm552.55 0c26.21 9 43.95 33.92 44.21 61.94.51 49.34-51.92 82.5-95.35 60.4-13.11-6.68-29.55-25.44-33.41-38.29-3.86-12.59-3.86-33.92-.26-43.43 12.6-33.94 50.63-52.19 84.81-40.62z" />
          <path d="M243.41 675.44c-19.02 10.28-18.76 35.47.51 44.46 16.45 7.97 34.18-3.09 34.18-21.33 0-11.31-3.6-17.47-12.85-22.36-8.73-4.62-14.64-4.88-21.84-.77zM794.93 675.96c-17.73 9.77-16.96 35.21 1.54 43.95 16.45 7.97 34.18-3.09 34.18-21.33 0-11.31-3.6-17.47-12.85-22.36-8.99-4.63-14.64-4.89-22.87-.26z" />
        </svg>
      );
    }
    default: {
      return <Pin {...marker} onClick={() => onClick && onClick(marker)} />;
    }
  }
};

const getOffset = marker => {
  switch (marker.kind) {
    case 'deviation_sign':
    case 'sign_road':
    case 'custom': {
      const { width, height } = marker.item;
      return {
        offsetLeft: -width / 2,
        offsetTop: -height * 0.15
      };
    }
    case 'point': {
      return { offsetLeft: -6, offsetTop: -6 };
    }
    case 'gpsPoint': {
      return { offsetLeft: -6, offsetTop: -6 };
    }
    default: {
      return { offsetLeft: -24, offsetTop: -48 };
    }
  }
};

// other props in : https://github.com/uber/react-map-gl/blob/9c302ae8401bafc6b6def70466a93a3b2a326edf/docs/components/marker.md
// example: draggable
//
// type = issue, project
const Marker = React.memo(({ onClick, onMove, onDelete, marker, ...props }) => {
  const { lat, lng } = marker;

  const onDragEnd = useCallback(
    ({ lngLat }) => {
      onMove({
        ...marker,
        lat: lngLat[1],
        lng: lngLat[0],
        point: lngLat
      });
    },
    [onMove, marker]
  );

  const handleClick = useCallback(
    marker => {
      if (onClick) onClick(marker);
    },
    [onClick]
  );

  const { offsetLeft, offsetTop } = useMemo(() => getOffset(marker), [marker]);

  return (
    <RawMarker
      latitude={parseFloat(lat)}
      longitude={parseFloat(lng)}
      offsetLeft={offsetLeft}
      offsetTop={offsetTop}
      onDragEnd={onDragEnd}
      captureClick={true}
      draggable={onMove && props.draggable}
      {...marker}
      {...props}
    >
      {pinRenderer(marker, handleClick, onDelete)}
    </RawMarker>
  );
});

export const Markers = React.memo(({ markers, onClick, onMove, onDelete }) => {
  return markers
    .filter(marker => !marker.hidden)
    .map(marker => (
      <Marker
        key={marker.id}
        marker={marker}
        onClick={() => onClick && onClick(marker)}
        onMove={onMove}
        onDelete={onDelete}
      />
    ));
});

export default Marker;

import React, { useCallback, useState, useMemo, Fragment } from 'react';
import {
  Route,
  useRouteMatch,
  useHistory,
  useLocation
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import isUndefined from 'lodash/isUndefined';
import omitBy from 'lodash/omitBy';
import flatten from 'lodash/flatten';
import { UiSelector } from 'features/UiPreferences';
import Scheduler, { SCHEDULER_TYPE } from './View';
import { PlanningSelector, PlanningAction } from 'features/Plannings';
import isEqual from 'lodash/isEqual';
import moment from 'moment';
import ProjectModal from 'features/Projects/components/v3/Modal';
import sortBy from 'lodash/sortBy';
import { useAccountConfig } from 'config';
import { useDeepEffect } from 'components/v3/utils';

const Project = () => {
  const { accountId } = useAccountConfig();
  const history = useHistory();
  const location = useLocation();
  const { url, path } = useRouteMatch();
  const dispatch = useDispatch();
  const [requestParams, setRequestParams] = useState({
    projectKindIdEq: undefined,
    groupIdIn: undefined
  });
  const [queryParam, setQueryParam] = useState();
  const [currentDates, setCurrentDates] = useState(null);

  const selectUi = useMemo(() => UiSelector.selectUiKeys('planning'), []);
  const { planning } = useSelector(selectUi, isEqual);
  const { showAllResources } = planning || {};

  const projects = useSelector(
    PlanningSelector.selectPlanningProjects,
    isEqual
  );
  const projectActivities = useMemo(
    () =>
      sortBy(
        flatten(projects.map(project => project.projectActivities || []))
          .filter(activity => activity && activity.startAt && activity.endAt)
          .map(activity => ({
            ...activity,
            uid: activity.id,
            id: `projectActivity#${activity.id}`,
            source: 'projectActivity',
            title: activity.displayName,
            resourceId: activity.projectId,
            start: activity.startAt,
            end: activity.endAt,
            display: 'background',
            classNames: ['fc-activities']
          })),
        'start'
      ),
    [projects]
  );

  // useEffect(
  //   () =>
  //     setRequestParams(params => ({
  //       ...params,
  //       projectKindIdEq: selectedProjectKind
  //         ? selectedProjectKind.id
  //         : undefined,
  //       groupIdIn:
  //         selectedGroups.length > 0 ? selectedGroups.map(g => g.id) : undefined
  //     })),
  //   [selectedProjectKind, selectedGroups]
  // );

  // on récupère tous les projets du compte car showAllResources
  // mais ca  ne marche pas si on est en mode recherche
  useDeepEffect(() => {
    if (!showAllResources || queryParam) {
      return;
    }
    const params = omitBy(requestParams, isUndefined);
    dispatch(
      PlanningAction.searchProjects({
        perPage: 500,
        page: 1,
        // deletedAtPresent: false,
        sort: 'position,created_at',
        ...params,
        stateNotEqOrNull: 'archived'
      })
    );
  }, [queryParam, requestParams, dispatch, showAllResources, accountId]);

  // on récupère les projets non archivés et qui sont dans la plage de date
  useDeepEffect(() => {
    if (showAllResources || queryParam) {
      return;
    }
    const { startAt, endAt } = currentDates || {};
    if (!startAt || !endAt) {
      return;
    }
    const params = omitBy(requestParams, isUndefined);
    if (Object.keys(params).length === 0) {
      dispatch(PlanningAction.clearSearch());
      return;
    }
    dispatch(
      PlanningAction.searchProjects({
        perPage: 500,
        page: 1,
        // deletedAtPresent: false,
        ...params,
        'between[start_at]': startAt ? moment(startAt).format() : null,
        'between[end_at]': endAt ? moment(endAt).format() : null
      })
    );
  }, [
    queryParam,
    requestParams,
    dispatch,
    showAllResources,
    currentDates,
    accountId
  ]);

  // on est en mode "recherche", donc on enleve les dates mais on garde les params (groupes ...)
  useDeepEffect(() => {
    if (!queryParam) {
      return;
    }
    const params = omitBy(requestParams, isUndefined);
    dispatch(
      PlanningAction.searchProjects({
        perPage: 500,
        page: 1,
        // deletedAtPresent: false,
        byQuery: queryParam,
        ...params,
        sort: showAllResources ? 'position' : undefined,
        stateNotEqOrNull: undefined,
        'between[start_at]': undefined,
        'between[end_at]': undefined
      })
    );
  }, [queryParam, requestParams, dispatch, accountId, showAllResources]);

  const handleSearch = useCallback(query => {
    let byQuery = query.trim();
    byQuery = byQuery.length < 3 ? undefined : byQuery;
    setQueryParam(byQuery);
  }, []);

  const handleResourceClick = useCallback(
    id => {
      history.push(`${path}/detail/${id}`);
    },
    [history, path]
  );

  const handleDateChange = useCallback(({ startAt, endAt }) => {
    setCurrentDates(dates => {
      if (
        !dates ||
        moment(startAt).valueOf() !== dates.startAt.valueOf() ||
        moment(endAt).valueOf() !== dates.endAt.valueOf()
      ) {
        return {
          startAt,
          endAt
        };
      } else {
        return dates;
      }
    });
  }, []);

  const useDates = !(queryParam || showAllResources);

  return (
    <Fragment>
      <Scheduler
        schedulerType={SCHEDULER_TYPE.PROJECTS}
        resources={projects}
        onResourceClick={handleResourceClick}
        additionalPhases={projectActivities}
        resourceLabelText={`Projets (${projects.length})`}
        onSearch={handleSearch}
        onDateRangeChanged={useDates ? handleDateChange : null}
        showArchived={Boolean(queryParam)}
        onFiltersChange={filters => {
          setRequestParams(params => ({
            ...params,
            ...filters.asQueryParams(SCHEDULER_TYPE.PROJECTS)
            // projectKindIdEq: selectedProjectKind
            //   ? selectedProjectKind.id
            //   : undefined,
            // groupIdIn:
            //   selectedGroups.length > 0 ? selectedGroups.map(g => g.id) : undefined
          }));
        }}
      />
      <Route
        exact
        path={[
          `${path}/new`,
          `${path}/detail/:projectId`,
          `${path}/detail/:projectId/:mode`
        ]}
      >
        <ProjectModal
          onRequestClose={() => {
            if (location.key) {
              history.goBack();
            } else {
              history.replace(url);
            }
          }}
        />
      </Route>
    </Fragment>
  );
};

export default Project;

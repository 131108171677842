import React, {
  useState,
  useRef,
  Fragment,
  useEffect,
  useCallback
} from 'react';
import styled from 'styled-components';
import { PopoverContainer } from '../ui';
import Portal from '../Portal';
import RawScroller from '../Scroller';
import { scrollIntoView } from './utils';

const Scroller = styled(RawScroller)`
  box-shadow: 0 6px 24px rgba(0, 0, 0, 0.1);
`;

const isChildDisabled = child => child && child.props.disabled;

const moveFocus = ({ direction, children, focusedIndex, setFocusedIndex }) => {
  if (!children.length === 0) return;

  let nextFocusIndex = 0;

  if (direction === 'up') {
    nextFocusIndex = focusedIndex > 0 ? focusedIndex - 1 : children.length - 1;
  } else if (direction === 'down') {
    nextFocusIndex = (focusedIndex + 1) % children.length;
  } else if (direction === 'last') {
    nextFocusIndex = children.length - 1;
  } else if (direction === 'first') {
    nextFocusIndex = 0;
  }
  const nextChild = children[nextFocusIndex];
  if (!isChildDisabled(nextChild)) {
    setFocusedIndex(nextFocusIndex, true);
  } else if (children.find(child => !isChildDisabled(child))) {
    moveFocus({
      direction,
      children,
      focusedIndex: nextFocusIndex,
      setFocusedIndex
    });
  }
};

const ScrollerStyle = { borderRadius: 12 };

const handleMouseDown = e => {
  const tagName = e.target?.tagName?.toUpperCase();
  if (tagName !== 'INPUT' && tagName !== 'TEXTAREA') {
    e.preventDefault();
  }
};

export default ({
  triggerRef: propsTriggerRef = undefined,
  isOpen = undefined,
  onRequestClose = undefined,
  closeOnSelect = true,

  readOnly = false,
  disabled = false,
  maxWidth = 'triggerWidth',
  minWidth = 200,
  autoHeightMax = 250,
  isEmpty,
  ...props
}) => {
  if (isOpen !== undefined && !onRequestClose) {
    throw new Error('isOpen must be used with onRequestClose');
  }
  let trigger = props.children[0];

  const children = React.Children.map(props.children, (child, i) => {
    if (i < 1) return;
    return child;
  });

  const selfTriggerRef = useRef();
  const triggerRef = propsTriggerRef || selfTriggerRef;
  const scrollRef = useRef();
  const listRef = useRef();

  const [state, setState] = useState({
    focusedIndex: -1,
    updateScroll: false
  });

  const { focusedIndex, updateScroll } = state;

  const isMenuOpen = isOpen === undefined ? state.isOpen : isOpen;
  const enabled = !disabled && !readOnly;

  const setFocusedIndex = useCallback((focusedIndex, updateScroll = false) => {
    setState(s => ({
      ...s,
      focusedIndex,
      updateScroll: updateScroll
    }));
  }, []);

  const setMenuOpen = useCallback(open => {
    setState(s => ({
      ...s,
      isOpen: open,
      focusedIndex: -1,
      updateScroll: false
    }));
  }, []);

  const handleClose = useCallback(
    (e, index) => {
      e.stopPropagation();
      if (onRequestClose) {
        if (isMenuOpen) {
          onRequestClose(e, index);
        }
      } else if (isMenuOpen) {
        setMenuOpen(false);
      }
    },
    [setMenuOpen, onRequestClose, isMenuOpen]
  );

  useEffect(() => {
    if (updateScroll) {
      if (scrollRef.current) {
        scrollIntoView(
          scrollRef.current.view,
          listRef.current.children[focusedIndex]
        );
      }
      setState(s => ({ ...s, updateScroll: false }));
    }
  }, [updateScroll, focusedIndex]);

  const focusIndex = useCallback(
    direction => {
      moveFocus({
        direction,
        children,
        focusedIndex,
        setFocusedIndex
      });
    },
    [focusedIndex, setFocusedIndex, children]
  );

  useEffect(() => {
    if (!enabled) {
      return;
    }
    if (isOpen) {
      setFocusedIndex(-1);
    }
  }, [isOpen, enabled, setFocusedIndex]);

  const handleOutClick = useCallback(
    e => {
      handleClose(e);
    },
    [handleClose]
  );

  const handleOnKeyDown = e => {
    if (!isMenuOpen || isEmpty) {
      return;
    }
    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        focusIndex('down');
        break;
      case 'ArrowUp':
        e.preventDefault();
        focusIndex('up');
        break;
      case 'Escape':
        e.preventDefault();
        e.stopPropagation();
        handleClose(e);
        break;
      case 'Tab':
        handleClose(e);
        break;
      case 'Enter':
        e.preventDefault();
        e.stopPropagation();
        if (
          focusedIndex !== -1 &&
          !isChildDisabled(children[focusedIndex]) &&
          children.length > 0
        ) {
          const child = children[focusedIndex].props;
          if (child.onClick) {
            child.onClick(e);
            handleClose(e, focusedIndex);
          }
        }
        break;
      default:
        break;
    }
  };

  trigger = React.cloneElement(trigger, {
    onKeyDown: handleOnKeyDown,
    tabIndex: isMenuOpen ? '1' : undefined,
    ref: triggerRef,
    onClick: enabled
      ? isOpen === undefined
        ? e => {
            e.preventDefault();
            e.stopPropagation();
            setMenuOpen(!isMenuOpen);
          }
        : trigger.props?.onClick
      : null
  });

  return (
    <Fragment>
      {trigger}
      {isMenuOpen && (
        <Portal
          elementRef={triggerRef}
          left={0}
          top={5}
          minWidth={minWidth}
          maxWidth={maxWidth}
          onOutClick={handleOutClick}
          {...props}
        >
          {children.length > 0 && (
            <Scroller
              ref={scrollRef}
              style={ScrollerStyle}
              autoHeight
              autoHeightMax={autoHeightMax}
            >
              <PopoverContainer
                ref={listRef}
                focusedIndex={focusedIndex}
                onMouseDown={handleMouseDown}
                onClick={closeOnSelect ? handleClose : undefined}
                {...props}
              >
                {children}
              </PopoverContainer>
            </Scroller>
          )}
        </Portal>
      )}
    </Fragment>
  );
};

import React, { useState } from 'react';
import ReactModal from 'react-modal';
import styled from 'styled-components';
import Card, {
  CardHeader,
  CardContent,
  CardFooter,
  CardButtons
} from '../ui/card';
import { Text } from '../ui';
import { theme } from 'ui';

const customStyles = {
  overlay: {
    zIndex: 999,
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    border: 'none'
  },
  content: {
    height: 'auto',
    right: 'auto',
    bottom: 'auto',
    left: '50%',
    top: '30%',
    maxHeight: 'calc(100vh - 80px)',
    backgroundColor: undefined,
    background: undefined,
    border: undefined,
    overflowY: 'auto',
    boxShadow: theme.boxShadow,
    transition: '500ms ease',
    transform: 'translateX(-50%) translateY(-50%)',
    padding: undefined
  }
};

const Content = styled(Card)`
  width: 450px;
  @media screen and (max-width: 482px) {
    width: 100%;
  }
`;

export default ({
  isOpen,
  onRequestClose,
  title,
  subtitle,
  content,
  positiveText,
  neutralText,
  negativeText,
  onButtonClick,
  positiveAction,
  negativeAction,
  neutralAction,
  children
}) => {
  const [buttonNameLoading, setButtonNameLoading] = useState();

  const handleButtonClick = async ({ name, index }) => {
    if (name === 'positive' && positiveAction) {
      setButtonNameLoading(name);
      await positiveAction();
    } else if (name === 'negative' && negativeAction) {
      setButtonNameLoading(name);
      await negativeAction();
    } else if (name === 'neutral' && neutralAction) {
      setButtonNameLoading(name);
      await neutralAction();
    }
    if (onButtonClick) {
      onButtonClick({ name, index });
    } else {
      onRequestClose();
    }
  };

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
    >
      <Content>
        <CardHeader title={title} subtitle={subtitle} />
        {(content || children) && (
          <CardContent>
            {content && <Text color="secondary">{content}</Text>}
            {children}
          </CardContent>
        )}
        <CardFooter>
          <CardButtons
            positiveText={positiveText}
            negativeText={negativeText}
            neutralText={neutralText}
            onButtonClick={handleButtonClick}
            buttonNameLoading={buttonNameLoading}
          />
        </CardFooter>
      </Content>
    </ReactModal>
  );
};

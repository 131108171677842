import { v4 } from 'uuid';
import { omit } from 'lodash';
import { PhaseApi, PhaseAction, PhaseReducer, PhaseSelector } from './data';
import { formatISODate, parseDate } from 'utils/dates';
import { isBefore, startOfDay, endOfDay } from 'date-fns';

export { PhaseApi, PhaseAction, PhaseReducer, PhaseSelector };

export const phaseStateAsOption = (state) =>
  !!state
    ? {
        value: state.value,
        color: state.color,
        label: state.displayName,
        original: state
      }
    : '';

export const phaseAsValues = (phase) => {
  const startAt = phase?.startAt || formatISODate(startOfDay(new Date()));
  const endAt = phase?.endAt || formatISODate(endOfDay(new Date()));
  // const start = parseDate(startAt);
  // const end = parseDate(endAt);
  return {
    id: phase?.id || null,
    customData: phase?.customData || [],
    project: phase?.project || null,
    displayName: phase?.displayName || '',
    state: phase?.state || null,
    activity: phase?.activity || '',
    team: phase?.team || '',
    users: phase?.users || [],
    machinePhases: phase?.machinePhases || [],
    description: phase?.description || '',
    startAt,
    endAt,
    expectedStartAt: phase?.expectedStartAt || startAt,
    expectedEndAt: phase?.expectedEndAt || endAt,
    night: phase?.night || false,
    attachments: phase?.attachments || [],
    address:
      phase?.address ||
      (Boolean(phase?.project?.address) && {
        ...phase.project.address,
        id: undefined
      }) ||
      null,
    orders: phase?.orders || [],
    extractionItems: phase?.extractionItems || [],
    inputItems: phase?.inputItems || [],
    carrierOrders: phase?.carrierOrders || [],
    projectKind: phase?.project?.projectKind,
    source: phase?.source
    // interval: isBefore(start, end) && differenceInMilliseconds(end, start)
  };
};

export const duplicate = (phase) => {
  return omit(
    {
      ...phase,
      address: phase.address
        ? {
            ...phase.address,
            id: undefined
          }
        : undefined,
      isNew: true,
      isDuplicate: true,
      machinePhases: phase?.machinePhases.map((mp) => ({
        ...mp,
        id: v4(),
        isNew: true
      })),
      orders: phase?.orders.map((order) => ({
        ...order,
        id: v4(),
        isNew: true,
        orderDays: order.orderDays?.map((od) => ({
          ...od,
          isNew: true,
          id: v4()
        }))
      }))
    },
    ['id', 'attachments', 'deliveries']
  );
};

export const phaseResources = ['users', 'machines', 'team'];

export const phaseValuesDependencies = {
  users: {
    modifier: (values) => {
      if (values.source === 'users') {
        return values.users;
      }
      if (values.team) {
        return values.team.users || [];
      } else {
        return values.users;
      }
    },
    triggers: ['team']
  },
  startAt: {
    modifier: (values) => {
      const start = values.startAt && parseDate(values.startAt);
      const end = values.endAt && parseDate(values.endAt);
      if (!start && end) {
        return startOfDay(end);
      } else if (end && isBefore(end, start)) {
        // if (values.interval) {
        // return addMilliseconds(end, -values.interval);
        // } else {
        return startOfDay(end);
        // }
      }
      return values.startAt;
    },
    triggers: ['endAt']
  },
  endAt: {
    modifier: (values) => {
      const start = values.startAt && parseDate(values.startAt);
      const end = values.endAt && parseDate(values.endAt);
      if (!end && start) {
        return endOfDay(start);
      } else if (end && isBefore(end, start)) {
        // if (values.interval) {
        //   return addMilliseconds(start, values.interval);
        // } else {
        return endOfDay(start);
        // }
      }
      return values.endAt;
    },
    triggers: ['startAt']
  }
  // interval: {
  //   modifier: values => {
  //     const start = values.startAt && parseDate(values.startAt);
  //     const end = values.endAt && parseDate(values.endAt);
  //     if (start && end && isBefore(start, end)) {
  //       return differenceInMilliseconds(end, start);
  //     }
  //     return values.interval;
  //   },
  //   triggers: ['startAt', 'endAt']
  // }
};

import styled, { css } from 'styled-components';
import { spacing, NavLink, HEADER_HEIGHT, fontSize } from 'ui';
import { List, ListItem } from 'components/v3/ui/list';
import { Button } from 'components/v3/ui';

export const Wrapper = styled.header`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 0 ${spacing(0.5)} 0 0;
  background-color: ${({ theme }) => theme.primary};
  position: sticky;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  height: ${HEADER_HEIGHT}px;
  align-items: center;
  box-shadow: ${({ theme }) => theme.boxShadow};

  ul li:hover > ul,
  ul li ul:hover {
    visibility: visible;
    opacity: 1;
    display: block;
  }
`;

export const Menu = styled(List)`
  height: 100%;
  display: flex;
  flex-direction: row;

  ${({ right }) =>
    right &&
    css`
      width: auto;
      justify-self: flex-end;
      margin: auto;
    `}
`;

export const MenuItem = styled(ListItem)`
  display: flex;
  align-items: center;
  height: 100%;
  text-decoration: none;
  transition-duration: 0.2s;
`;

export const MenuItemLink = styled(Button)`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  font-size: ${fontSize(14)};
  text-transform: initial;
  border-radius: 0;
  transition: all 0.2s ease;
  justify-content: left;
  opacity: 1;

  > i:only-child {
    margin: 0px 4px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.hoverColorBlack};
  }

  > i {
    color: ${({ theme }) => theme.backgroundLight};
    > svg {
      opacity: 1;
    }
  }

  ${props =>
    props['has-submenu'] &&
    css`
      padding: 0px 0px 0px 12px;
    `}

  ${({ submenu }) =>
    !submenu &&
    css`
      &.active {
        border-bottom: 4px solid ${({ theme }) => theme.accent};
      }
    `}

  ${({ submenu }) =>
    submenu &&
    css`
      padding: 10px 16px 10px 8px;
      border-left: 4px solid transparent;

      &:hover:not(.active) {
        border-left: 4px solid ${({ theme }) => theme.accent};
      }
    `}
`;
export const SubMenu = styled(List)`
  visibility: hidden;
  display: none;
  opacity: 0;
  background-color: ${({ theme }) => theme.primary};
  position: absolute;
  transition: all 0.2s ease;
  margin-top: 0;
  top: 100%;
  box-shadow: ${({ theme }) => theme.boxShadow};
  padding-left: 0;
  min-width: 160px;
  max-width: auto;
  width: auto;
  border-radius: 0 0
    ${({ theme }) => `${theme.borderButtonRadius} ${theme.borderButtonRadius}`};

  > ${ListItem} {
    a {
      &.active {
        border-left: 4px solid ${({ theme }) => theme.accent};
        background-color: ${({ theme }) => theme.primaryLight};
      }
    }
    :last-child,
    :only-child {
      a {
        border-radius: 0 0
          ${({ theme }) =>
            `${theme.borderButtonRadius} ${theme.borderButtonRadius}`};
      }
    }
  }
`;

export const Logo = styled(NavLink)`
  padding: 0 ${spacing()};
  display: flex;
  align-items: center;
  height: 100%;

  > img {
    display: block;
    height: 30px;
  }
`;

import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { SelectField } from 'components/v3/Form';
import { SelectInputLayout } from 'components/v3/InputLayout';
import { VoucherSelector, voucherKindAsOption } from '../data';

const VoucherKindSelect = ({
  voucherableType,
  value,
  availableVoucherKinds,
  onChange,
  ...props
}) => {
  const selectVoucherKinds = useMemo(
    () => state => {
      return VoucherSelector.selectVoucherKinds(state, { voucherableType });
    },
    [voucherableType]
  );
  const voucherKinds = useSelector(selectVoucherKinds);

  const options = useMemo(() => {
    const data = availableVoucherKinds ? availableVoucherKinds : voucherKinds;
    return voucherKindAsOption(data);
  }, [voucherKinds, availableVoucherKinds]);

  const currentValue = useMemo(() => voucherKindAsOption(value), [value]);

  return (
    <SelectInputLayout
      label="Type de document"
      value={currentValue}
      options={options}
      isSearchable
      onChange={onChange}
      {...props}
    />
  );
};

export const VoucherKindField = props => {
  return <SelectField component={VoucherKindSelect} {...props} />;
};

export default VoucherKindSelect;

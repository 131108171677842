import React, {
  useEffect,
  useRef,
  useCallback,
  useState,
  Fragment,
  StyleHTMLAttributes
} from 'react';
import styled, { css } from 'styled-components';
import { MdCheckCircle } from 'react-icons/md';
import { FormikValues, FormikHelpers, FormikProps } from 'formik';
import Form, { ConfirmDelete } from '../Form';
import { FormProps, FormikBag } from '../Form/Form';
import { toaster, ToastMessage } from '../Toast';
import useAction from '../utils/useAction';
import { usePrevious } from '../utils';

const ConfirmDeleteWrapper = styled.div`
  position: absolute;
  right: 32px;
  left: 32px;
  width: calc(100% - 64px);
  top: 30%;
  transform: translateY(-30%);
  display: flex;
  justify-content: center;
`;

const ContentWrapper = styled('div')<{ blur: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  height: 100%;

  ${({ blur }) =>
    blur &&
    css`
      filter: blur(6px);
      pointer-events: none;
      max-height: calc(100vh - 32px);
      min-height: 200px;
    `}
`;

export interface Entity {
  [field: string]: any;
}

interface StyleProps {
  className?: string;
  style?: StyleHTMLAttributes<HTMLDivElement>;
}

export interface FetchStatus {
  hasFetch: boolean;
  canFetch: boolean;
}

export interface Props<Entity> extends FormProps {
  id?: string;
  entity?: Entity;
  entityAsValue: (entity?: Entity | null) => FormikValues;
  fetchRequest?: (props?: any) => Promise<any>;
  createRequest?: (
    values: FormikValues,
    form: FormikHelpers<FormikValues>
  ) => Promise<any>;
  updateRequest?: (
    id: string,
    values: FormikValues,
    form: FormikHelpers<FormikValues>
  ) => Promise<any>;
  deleteRequest?: (id: string) => Promise<any>;
  onFetching?: (fetching: boolean) => void;
  onSubmitSuccess: (
    props: any | null | undefined,
    formHelpers: FormikHelpers<FormikValues>,
    form?: FormikProps<FormikValues>
  ) => void;
  showConfirmDelete?: boolean;
  onDeleteConfirmed?: (confirmed: boolean) => void;
  onDeleteSuccess?: () => void;
  disableSuccessToast?: boolean;

  children: (
    props: FormikProps<FormikValues>,
    fetching: FetchStatus
  ) => React.ReactNode;

  enableReinitialize: never;
  onSubmit: never;
}

const EntityForm: React.FunctionComponent<Props<Entity> & StyleProps> = ({
  id,
  entity,
  entityAsValue,
  fetchRequest,
  onFetching,
  createRequest,
  updateRequest,
  onSubmitSuccess,
  showConfirmDelete,
  onDeleteConfirmed,
  deleteRequest,
  onDeleteSuccess,
  style,
  className,
  disableSuccessToast,
  formikBagRef,
  ...props
}) => {
  const action = useAction();
  const formRef = useRef<FormikBag>();
  const entityRef = useRef<Entity>();
  entityRef.current = entity;
  const initialStatusRef = useRef<any>();
  initialStatusRef.current = props.initialStatus;
  const onSuccessRef = useRef(onSubmitSuccess);
  onSuccessRef.current = onSubmitSuccess;
  const onDeleteSuccessRef = useRef(onDeleteSuccess);
  onDeleteSuccessRef.current = onDeleteSuccess;
  const [isConfirmDelete, setConfirmDelete] = useState<boolean>(false);
  const prevId = usePrevious(id);
  const [isDeleting, setDeleting] = useState<boolean>(false);
  const [fetchStatus, setFetchStatus] = useState<FetchStatus>({
    hasFetch: false,
    canFetch: false
  });

  const { autoSubmit } = props;
  const { hasFetch, canFetch } = fetchStatus;
  const idChanged = prevId !== id;

  const isConfirmationDeleteVisible = showConfirmDelete || isConfirmDelete;
  const { isSubmitting } = formRef.current || {};

  const handleSubmit = useCallback(
    (values: FormikValues, form: FormikHelpers<FormikValues>) => {
      if ((id && !updateRequest) || (!id && !createRequest)) {
        form.setSubmitting(false);
        return;
      }
      return new Promise((resolve, reject) => {
        action(() =>
          id ? updateRequest!(id, values, form) : createRequest!(values, form)
        )
          .onSuccess(p => {
            // formRef.current?.setValues(entityAsValue(entityRef.current));
            onSuccessRef.current &&
              onSuccessRef.current(p, form, formRef.current);

            if (autoSubmit) {
              formRef.current?.resetForm({
                values: formRef.current.values
              });
            } else {
              formRef.current?.resetForm({
                values: entityAsValue(entityRef.current),
                status: initialStatusRef.current,
                touched: {},
                submitCount: 0
              });
            }
            // formRef.current?.setValues(entityAsValue(entityRef.current));
            if (!disableSuccessToast) {
              toaster.success(
                <ToastMessage
                  icon={<MdCheckCircle />}
                  text="Modifications enregistrées."
                />,
                { autoClose: 2000 }
              );
            }
            resolve(p);
          })
          .onError(e => {
            toaster.error(e, { autoClose: 2000 });
            reject(e);
          })
          .onEnd(() => {
            form.setSubmitting(false);
          });
      });
    },
    [
      id,
      action,
      createRequest,
      updateRequest,
      disableSuccessToast,
      autoSubmit,
      entityAsValue
    ]
  );

  const handleDeleteConfirmed = useCallback(
    confirmed => {
      if (onDeleteConfirmed) {
        onDeleteConfirmed(confirmed);
      }
      if (confirmed) {
        setDeleting(true);
      } else {
        setConfirmDelete(false);
      }
    },
    [onDeleteConfirmed]
  );

  useEffect(() => {
    if (!canFetch || hasFetch || !fetchRequest || !id) {
      return;
    }
    action(() => fetchRequest(id))
      .onBegin(() => {
        if (onFetching) {
          onFetching(true);
        }
      })
      .onEnd(() => {
        if (onFetching) {
          onFetching(false);
        }
        formRef.current?.resetForm({
          values: entityAsValue(entityRef.current),
          status: initialStatusRef.current,
          touched: {},
          submitCount: 0
        });
        setFetchStatus({
          hasFetch: true,
          canFetch: false
        });
      })
      .onError((error: any) =>
        toaster.error(error, { position: 'bottom-center' })
      );
  }, [id, action, fetchRequest, onFetching, entityAsValue, canFetch, hasFetch]);

  useEffect(() => {
    setFetchStatus(fetchStatus => {
      return {
        hasFetch: idChanged ? false : fetchStatus.hasFetch,
        canFetch: Boolean(!isSubmitting)
      };
    });
  }, [idChanged, isSubmitting]);

  useEffect(() => {
    if (!isDeleting || !deleteRequest || !id) {
      return;
    }
    action(() => deleteRequest(id))
      .onSuccess(() => {
        if (onDeleteSuccessRef.current) {
          onDeleteSuccessRef.current();
        }
      })
      .onError(e => toaster.error(e, { position: 'bottom-center' }))
      .onEnd(() => setDeleting(false));
  }, [id, action, deleteRequest, isDeleting]);

  useEffect(() => {
    if (!formRef.current?.dirty && !formRef.current?.isSubmitting) {
      // if not dirty reset form
      formRef.current?.resetForm({
        values: entityAsValue(entity),
        status: initialStatusRef.current,
        touched: {},
        submitCount: 0
      });
    }
  }, [entity, entityAsValue]);

  const formikBagSetter = useCallback(
    (ref: FormikBag) => {
      if (formikBagRef) {
        if (formikBagRef instanceof Function) {
          formikBagRef(ref as any);
        } else {
          (formikBagRef as React.MutableRefObject<FormikBag>).current = ref;
        }
      }
      formRef.current = ref;
    },
    [formikBagRef]
  );

  return (
    <Form
      {...props}
      id={id}
      formikBagRef={formikBagSetter}
      enableReinitialize={false}
      initialValues={entityAsValue(entity)}
      onSubmit={handleSubmit}
      resetDependencies={[id, ...(props.resetDependencies || [])]}
    >
      {form => (
        <Fragment>
          <ContentWrapper
            style={style}
            className={className}
            blur={isConfirmationDeleteVisible}
          >
            {props.children(form, fetchStatus)}
          </ContentWrapper>
          {isConfirmationDeleteVisible && deleteRequest && (
            <ConfirmDeleteWrapper>
              <ConfirmDelete
                onDeleteConfirmed={handleDeleteConfirmed}
                isDeleting={isDeleting}
              />
            </ConfirmDeleteWrapper>
          )}
        </Fragment>
      )}
    </Form>
  );
};

EntityForm.defaultProps = {
  autoSubmit: true,
  showConfirmDelete: false
};

export default EntityForm;

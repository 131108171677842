import React, { useState, useEffect, useMemo } from 'react';
import { Bar } from 'react-chartjs-2';
import sum from 'lodash/sum';
import flatten from 'lodash/flatten';
import SubHeader from 'components/v3/SubHeader';
import { FilterBar, useFilters } from 'components/v3/Filters';
import { Container, Title, Text } from 'components/v3/ui';
import { useAction } from 'components/v3/utils';
import { PurveyorSelect, SurfacingApi } from 'features/Surfacing';
import { useAccountConfig } from 'config';
import { toaster } from 'components/v3/Toast';
import ActionGroup, { Action } from 'components/v3/ActionGroup';
import { stringToColor } from 'utils';

const MODE = {
  real: {
    value: 'real',
    label: 'Réel',
    tooltip: 'Ce qui a été commandé dans les bons de commandes'
  },
  previsional: {
    value: 'previsional',
    label: 'Prévisionnel',
    tooltip: 'Ce qui est estimé sur le planning'
  }
};

const BAR_OPTIONS = {
  maintainAspectRatio: false,
  tooltips: {
    mode: 'index',
    intersect: false
  },
  legend: {
    position: 'bottom'
  },
  scales: {
    xAxes: [
      {
        stacked: true
      }
    ],
    yAxes: [
      {
        stacked: true,
        offset: false
      }
    ]
  }
};

const Services = () => {
  const { accountId } = useAccountConfig();
  const action = useAction();
  const [isLoading, setLoading] = useState(false);
  const [apiData, setApiData] = useState([]);
  const [mode, setMode] = useState(MODE.real);

  const FILTERS = useMemo(() => {
    const year = new Date().getFullYear();
    return [
      {
        kind: 'select',
        key: 'ui.selectedPurveyor',
        component: PurveyorSelect,
        value: null,
        asQueryParam: value => ({
          idEq: value?.id
        }),
        props: {
          colored: true,
          inputCreatable: false,
          style: {
            minWidth: 150,
            maxWidth: 250
          }
        }
      },
      {
        kind: 'select',
        key: 'year',
        value: year,
        asQueryParam: value => ({
          year: value
        }),
        props: {
          style: {
            minWidth: 100
          },
          isClearable: false,
          fillWidth: true,
          label: 'Année',
          fitCurrentOptionWidth: true,
          options: Array.from(new Array(20), (_, index) => year - index + 2)
            .filter(v => v > 2015)
            .map(value => ({
              value,
              label: value
            })),
          mapOptionValue: true
        }
      }
    ];
  }, []);

  const [filters, setFilters] = useFilters(FILTERS);

  useEffect(() => {
    action(() =>
      SurfacingApi.getCoatingStats({
        ...filters.asQueryParams(),
        mode: mode?.value
      })
    )
      .onBegin(() => setLoading(true))
      .onSuccess(data => setApiData(data))
      .onError(toaster.error)
      .onEnd(() => setLoading(false));
  }, [action, accountId, filters, mode]);

  const formattedData = useMemo(() => {
    if (!apiData || apiData.length === 0) {
      return {};
    }
    return {
      labels: apiData.labels,
      datasets: apiData.datasets.map(dataset => {
        return {
          ...dataset,
          backgroundColor: stringToColor(dataset.label)
        };
      })
    };
  }, [apiData]);

  const totalTonnage = useMemo(() => {
    return sum(
      flatten(apiData.datasets?.map(dataset => dataset.data)).map(i =>
        parseFloat(i.y)
      )
    );
  }, [apiData]);

  return (
    <Container vertical>
      <SubHeader isLoading={isLoading}>
        <Title style={{ minWidth: 150 }}>{`${totalTonnage || 0} tonnes`}</Title>
        <FilterBar
          variant={'flat.small'}
          disableSearch
          filters={filters}
          onChanges={setFilters}
        />
        <div>
          <ActionGroup>
            {Object.values(MODE).map(({ value, label, tooltip }) => (
              <Action
                key={value}
                onClick={() => setMode(MODE[value])}
                checked={mode?.value === value}
                tooltip={tooltip}
              >
                <Text variant="small" style={{ padding: '7px 12px' }}>
                  {label}
                </Text>
              </Action>
            ))}
          </ActionGroup>
        </div>
      </SubHeader>
      <Container padded style={{ overflow: 'hidden' }}>
        <Bar data={formattedData} options={BAR_OPTIONS} />
      </Container>
    </Container>
  );
};

export default Services;

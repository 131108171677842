import React from 'react';
import styled from 'styled-components';
import Card, { CardHeader, CardContent } from 'components/v3/ui/card';
import { Text, Overline } from 'components/v3/ui';
import { printDate } from 'utils/dates';
import BatteryTag from './BatteryTag';

const Header = styled(CardHeader)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
`;

const Content = styled(CardContent)``;

const TextContent = styled.div`
  flex: 0;
  flex-grow: 1;
  margin-right: 16px;
`;

const Serial = styled(Overline)`
  margin-top: 4px;
`;

const GpsBoxCard = ({ gpsBox, ...props }) => {
  return (
    <Card {...props}>
      <Header>
        <TextContent>
          <Text weight="semiBold">{gpsBox.machine?.displayName}</Text>
          <Serial color="theme">{gpsBox.serial}</Serial>
        </TextContent>
        <BatteryTag gpsBox={gpsBox} />
      </Header>
      <Content>
        <Text variant="caption" color="caption">
          {`Dernière mise à jour: ${printDate(
            gpsBox.timestamp,
            "EEE dd MMM yyyy 'à' HH:mm"
          )}`}
        </Text>
      </Content>
    </Card>
  );
};

export default GpsBoxCard;

import { omit } from 'lodash';
import { mergeJsonApi } from 'common/reducers';
import isSameIds from 'utils/idUtils';
import { merge } from 'utils';

const initialPurveyorsState = {
  purveyorById: {},
  purveyorsIds: []
};

const purveyors = (state = initialPurveyorsState, action) => {
  const { payload, type } = action;

  switch (type) {
    case 'NEW_PURVEYOR_SUCCESS':
      return {
        ...mergeJsonApi('purveyor', state, action),
        purveyorsIds: [payload.data.id, ...state.purveyorsIds]
      };
    case 'DELETE_PURVEYOR_SUCCESS':
      return {
        ...state,
        purveyorById: omit(state.purveyorById, payload),
        purveyorsIds: state.purveyorsIds.filter((id) => !isSameIds(id, payload))
      };
    case 'LOGOUT':
    case 'SELECT_ACCOUNT':
      return initialPurveyorsState;
    default:
      return mergeJsonApi('purveyor', state, action);
  }
};

const initialRawMaterialsState = {
  rawMaterialById: {},
  rawMaterialsIds: []
};

const rawMaterials = (state = initialRawMaterialsState, action) => {
  const { payload, type } = action;
  switch (type) {
    case 'NEW_RAW_MATERIAL_SUCCESS':
      return {
        ...mergeJsonApi('rawMaterial', state, action),
        rawMaterialsIds: [payload.data.id, ...state.rawMaterialsIds]
      };

    case 'REORDER_RAW_MATERIALS_SUCCESS':
      return {
        ...state,
        rawMaterialById: merge(
          state.rawMaterialById,
          payload.reduce((acc, rawMaterial) => {
            acc[rawMaterial.id] = rawMaterial;
            return acc;
          }, {})
        ),
        rawMaterialsIds: payload.map((m) => m.id)
      };
    case 'DELETE_RAW_MATERIAL_SUCCESS':
      return {
        ...state,
        rawMaterialById: omit(state.rawMaterialById, payload),
        rawMaterialsIds: state.rawMaterialsIds.filter((id) => !isSameIds(id, payload))
      };
    case 'LOGOUT':
    case 'SELECT_ACCOUNT':
      return initialRawMaterialsState;
    default:
      return mergeJsonApi('rawMaterial', state, action);
  }
};

const initialProductsState = {
  productById: {},
  productsIds: []
};

const products = (state = initialProductsState, action) => {
  const { payload, type } = action;
  switch (type) {
    case 'NEW_PRODUCT_SUCCESS':
      return {
        ...mergeJsonApi('product', state, action),
        productsIds: [payload.data.id, ...state.productsIds]
      };
    case 'DELETE_PRODUCT_SUCCESS':
      return {
        ...state,
        productById: omit(state.productById, payload),
        productsIds: state.productsIds.filter((id) => !isSameIds(id, payload))
      };
    case 'LOGOUT':
    case 'SELECT_ACCOUNT':
      return initialProductsState;
    default:
      return mergeJsonApi('product', state, action);
  }
};

const initialLayersState = {
  layerById: {},
  layersIds: []
};

const layers = (state = initialLayersState, action) => {
  const { type } = action;
  switch (type) {
    case 'LOGOUT':
    case 'SELECT_ACCOUNT':
      return initialLayersState;
    default:
      return mergeJsonApi('layer', state, action);
  }
};

const initialOrdersState = {
  ordersIds: [],
  orderById: {}
};

const orders = (state = initialOrdersState, action) => {
  const { type } = action;
  switch (type) {
    case 'LOGOUT':
    case 'SELECT_ACCOUNT':
      return initialOrdersState;
    default:
      return mergeJsonApi('order', state, action);
  }
};

const initialDaysState = {
  orderDaysIds: [],
  orderDayById: {}
};

const orderDays = (state = initialDaysState, action) => {
  const { type } = action;
  switch (type) {
    case 'LOGOUT':
    case 'SELECT_ACCOUNT':
      return initialDaysState;
    default:
      return mergeJsonApi('orderDay', state, action);
  }
};

const initialProductRawMaterialsState = {
  productRawMaterialById: {},
  productRawMaterialsIds: []
};

const productRawMaterials = (state = initialProductRawMaterialsState, action) => {
  const { type } = action;
  switch (type) {
    case 'LOGOUT':
    case 'SELECT_ACCOUNT':
      return initialProductRawMaterialsState;
    default:
      return mergeJsonApi('productRawMaterial', state, action);
  }
};

export { purveyors, rawMaterials, products, productRawMaterials, orders, orderDays, layers };

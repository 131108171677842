import { omit } from 'lodash';
import { mergeJsonApi } from 'common/reducers';
import { merge } from 'utils';
import isSameIds from 'utils/idUtils';

const initialState = {
  machineById: {},
  machinesIds: []
};

const machines = (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case 'NEW_MACHINE_SUCCESS':
      return {
        ...mergeJsonApi('machine', state, action),
        machinesIds: [payload.data.id, ...state.machinesIds]
      };
    case 'DELETE_MACHINE_SUCCESS':
      return {
        ...state,
        machineById: omit(state.machineById, payload),
        machinesIds: state.machinesIds.filter(id => !isSameIds(id, payload))
      };
    case 'REORDER_MACHINES_SUCCESS':
      return {
        ...state,
        machineById: merge(
          state.machineById,
          payload.reduce((acc, machine) => {
            acc[machine.id] = machine;
            return acc;
          }, {})
        ),
        machinesIds: payload.map(m => m.id)
      };
    case 'LOGOUT':
    case 'SELECT_ACCOUNT':
      return initialState;
    default:
      return mergeJsonApi('machine', state, action);
  }
};

export default machines;

import React from 'react';
import styled from 'styled-components';
import Tag, { TagContainer } from 'components/v3/Tag';
import Card, { CardHeader, CardContent } from 'components/v3/ui/card';
import { Text, Overline } from 'components/v3/ui';
import State from './State';
import { printDate } from 'utils/dates';
import Author from 'features/Users/components/v3/Author';

const Reference = styled(Overline)`
  margin-top: 4px;
`;
const Date = styled(Text)`
  margin-top: 4px;
`;
const Content = styled(CardContent)`
  margin-top: 8px;
  flex-direction: row;
  justify-content: space-between;
`;
const DateAndRevenueWrapper = styled.span`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 12px;
`;

const ProjectCard = ({ project, readOnly, ...props }) => {
  return (
    <Card {...props}>
      <CardHeader variant="tight">
        <TagContainer alignEnd>
          <span>
            <Tag
              size="small"
              displayName={project.group?.displayName}
              color={project.group?.color}
            />
          </span>
          <State
            projectId={project.id}
            value={project.state}
            asTag
            readOnly={readOnly}
          />
        </TagContainer>
        <Text weight="semiBold">{project.displayName}</Text>
        <Reference color="theme">{project.reference}</Reference>
      </CardHeader>
      <Content>
        <Author user={project.user || project.owner} />
        <DateAndRevenueWrapper>
          <Text currency>{project.estimatedRevenues}</Text>
          <Date alignEnd variant="caption" color="caption">
            {`Créé le ${printDate(project.createdAt)}`}
          </Date>
        </DateAndRevenueWrapper>
      </Content>
    </Card>
  );
};

export default ProjectCard;

import React, { useMemo } from 'react';
import styled from 'styled-components';
import isEqual from 'lodash/isEqual';
import { phaseResources, PhaseSelector } from 'features/Phases';
import PhaseCard from 'features/Phases/components/v3/Card';
import { Text, Overline } from 'components/v3/ui';
import { List, ListItem } from 'components/v3/ui/list';
import HolidayCard from 'features/Holidays/components/v3/Card';
import { useSelector } from 'react-redux';
import { HolidaySelector } from 'features/Holidays';
import { PlanningSelector, isEventOverlap } from 'features/Plannings';
import { ProjectKindSelector } from 'features/ProjectKinds';
import { PeriodSelector } from 'features/Periods';
import isSameIds from 'utils/idUtils';

const OverlapItem = styled(ListItem)`
  display: flex;
  flex-direction: column;
`;

const Overlaps = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 16px;
  position: relative;

  > *:first-child {
    margin-bottom: 6px;
  }

  :before {
    content: '';
    position: absolute;
    top: 8px;
    left: 0px;
    right: 0px;
    height: 1px;
    overflow: hidden;
    opacity: 0.4;
    background: linear-gradient(
      0.25turn,
      rgba(0, 0, 0, 0),
      ${({ theme }) => theme.error},
      rgba(0, 0, 0, 0)
    );
  }
`;

const eventSelector = (source, id) => state => {
  switch (source) {
    case 'projectActivity':
      return ProjectKindSelector.selectProjectActivity(state, {
        activityId: id
      });
    case 'phase':
      return PhaseSelector.selectPhase(state, { phaseId: id });
    case 'period':
      return PeriodSelector.selectPeriod(state, { periodId: id });
    case 'holiday':
      return HolidaySelector.selectHoliday(state, { holidayId: id });
    default:
      return null;
  }
};

const getOverlapReference = ({ event, resourceSource }) => {
  if (!event) {
    return null;
  }
  let ref = event[resourceSource];
  if (!ref) {
    return event.project?.displayName || event.displayName;
  }
  if (Array.isArray(ref)) {
    return ref.map(({ displayName }) => displayName).join(',');
  } else {
    return ref.displayName;
  }
};

const OverlapRow = ({ overlap, resourceSource, children }) => {
  const eventId = overlap.event?.id;

  const selector = useMemo(() => {
    const split = eventId?.split('#');
    return eventSelector(split[0], split[1]);
  }, [eventId]);

  const overlapEvent = useSelector(selector, isEqual);

  return (
    <OverlapItem>
      <Text variant="small">{children}</Text>
      <Text variant="caption" color="caption">
        {getOverlapReference({
          event: overlapEvent,
          resourceSource
        })}
      </Text>
    </OverlapItem>
  );
};

export const overlapInfo = ({
  event,
  overlaps: overlapData,
  resourceSource
}) => {
  return phaseResources
    .map(resource => {
      return {
        resource,
        overlaps: overlapData[resource]
      };
    })
    .filter(line => line.overlaps?.length > 0)
    .map(({ resource, overlaps }, i) => {
      return (
        <List key={resource}>
          {overlaps.map(overlap => {
            if (Array.isArray(event[resource])) {
              return overlap.ids.map((id, index) => {
                const displayName = event[resource]?.find(r =>
                  isSameIds(r.id, id)
                )?.displayName;
                return (
                  displayName && (
                    <OverlapRow
                      resourceSource={resourceSource}
                      overlap={overlap}
                      key={`${resource}-${id}-${i}-${index}`}
                    >
                      {displayName}
                    </OverlapRow>
                  )
                );
              });
            } else {
              const displayName = event[resource].displayName;
              const id = overlap.event?.id;
              return (
                <OverlapRow
                  key={`${resource}-${id}-${i}`}
                  overlap={overlap}
                  resourceSource={resourceSource}
                >
                  {displayName}
                </OverlapRow>
              );
            }
          })}
        </List>
      );
    });
};

const EventTooltip = ({ event: propsEvent, showOverlap, readOnly }) => {
  const { extendedProps, id: eventId } = propsEvent || {};
  const { source, uid } = extendedProps || {};

  const selector = useMemo(() => eventSelector(source, uid), [uid, source]);
  const data = useSelector(selector, isEqual);

  const overlapSelector = useMemo(
    () => state =>
      PlanningSelector.selectEventOverlaps(state, {
        eventId
      }),
    [eventId]
  );
  const overlaps = useSelector(overlapSelector, isEqual);

  const isOverlap = useMemo(() => showOverlap && isEventOverlap(overlaps), [
    showOverlap,
    overlaps
  ]);

  if (!data) {
    return null;
  }
  const resourceSource =
    propsEvent?._def?.resourceIds?.length > 0
      ? propsEvent.getResources()[0]?.extendedProps?.source
      : null;

  switch (source) {
    case 'projectActivity':
    case 'period':
      return <PhaseCard phase={data} hideState />;
    case 'holiday':
      return (
        <HolidayCard holiday={data} overlaps={overlaps}>
          {showOverlap && isOverlap && (
            <Overlaps>
              <Overline>Conflits</Overline>
              {overlapInfo({
                event: data,
                overlaps,
                resourceSource
              })}
            </Overlaps>
          )}
        </HolidayCard>
      );
    case 'phase':
      return (
        <PhaseCard
          showResources
          hiddenResource={resourceSource}
          phase={data}
          readOnly={readOnly}
        >
          {showOverlap && isOverlap && (
            <Overlaps>
              <Overline>Conflits</Overline>
              {overlapInfo({
                event: data,
                overlaps,
                resourceSource
              })}
            </Overlaps>
          )}
        </PhaseCard>
      );
    default:
      return null;
  }
};

export default EventTooltip;

import { getState, selectRelations } from 'common/selector';
import { createSelector } from 'reselect';

export const getCustomFieldGroups = state => state.customFieldGroups;

export const selectCustomFieldGroups = type =>
  createSelector(
    [getCustomFieldGroups, getState],
    ({ customFieldGroupsIds, customFieldGroupById }, state) =>
      customFieldGroupsIds
        .map(id =>
          selectRelations('customFieldGroup', customFieldGroupById[id], state)
        )
        .filter(Boolean)
        .filter(group => group && group.fieldableType === type)
  );

import React from 'react';
import styled, { css } from 'styled-components';
import { NavLink as RawNavLink } from 'react-router-dom';

import { spacing, media, fontSize, lineHeight } from './utils';
import { theme } from './theme';

export { theme, spacing, media, fontSize, lineHeight };

export const HEADER_HEIGHT = 48;
export const TIMELINE_SIDEBAR_WIDTH = 300;
export const MAIN_SIDEBAR_WIDTH = 240;
export const DETAIL_SIDEBAR_WIDTH = 280;
export const FILTER_HEADER_HEIGHT = 48;

export const NavLink = styled(RawNavLink)`
  text-decoration: none;
  font-family: Montserrat;
`;

export const ProgressBar = props => {
  return (
    <StyledProgressBar className="rm-progress" height="3px" {...props}>
      <div className="line" />
      <div className="subline inc" />
      <div className="subline dec" />
    </StyledProgressBar>
  );
};

export const StyledProgressBar = styled.div`
  position: relative;
  width: 100%;
  height: ${props => props.height};
  overflow-x: hidden;
  z-index: 99;

  ${props =>
    !props.noBackgroundLine &&
    css`
    .line {
      position: absolute;
      opacity: 0.4;
      background: ${({ theme }) =>
        props.accent ? theme.accent : theme.primary}
      width: 150%;
      height: ${props => props.height};
    }
  `}

  .subline {
    position: absolute;
    ${props =>
      css`
        background: ${({ theme }) =>
          props.accent ? theme.accent : theme.primary};
      `};
    height: ${props => props.height};
  }
  .inc {
    animation: increase 2s infinite;
  }
  .dec {
    animation: decrease 2s 0.5s infinite;
  }

  @keyframes increase {
    from {
      left: -5%;
      width: 5%;
    }
    to {
      left: 130%;
      width: 100%;
    }
  }
  @keyframes decrease {
    from {
      left: -80%;
      width: 80%;
    }
    to {
      left: 110%;
      width: 10%;
    }
  }
`;

import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Grid as RawGrid, GridItem } from 'components/v3/ui';
import StaticMap from 'components/v3/Map/StaticMap';
import { TextInputLayout, SelectInputLayout } from './InputLayout';
import { searchAddress } from 'api/google';
import { fullAddress } from 'features/Addresses';

const Grid = styled(RawGrid)`
  padding: 0px;

  ${GridItem} {
    :first-child {
      padding-top: 0px;
    }
    :last-child {
      padding-bottom: 0px;
    }
  }
`;

const addressOptionsFormatter = result => {
  return Object.values(result).map(a => ({
    value: a.id || a.key,
    original: a,
    label: fullAddress(a)
  }));
};

const addressAsValues = address => ({
  id: address?.id || null,
  content: address?.content || '',
  zipCode: address?.zipCode || '',
  city: address?.city || '',
  country: address?.country || '',
  lat: address?.lat || 0,
  lng: address?.lng || 0
});

const AddressPicker = ({
  useAccountAddressIfEmpty = true,
  mapHeight,
  mapRatio,
  value,
  project,
  onChange,
  onMapClick,
  readOnly
}) => {
  const handleChange = useCallback(
    address => {
      if (onChange) {
        onChange(addressAsValues(address));
      }
    },
    [onChange]
  );

  const handleContentChange = useCallback(
    content =>
      handleChange({
        ...(value || {}),
        content
      }),
    [value, handleChange]
  );

  const findAddress = useCallback(
    ({ query }) => searchAddress(query).then(addressOptionsFormatter),
    []
  );

  return (
    <Grid vertical>
      {!readOnly && (
        <GridItem width="100%">
          <SelectInputLayout
            label="Rechercher une adresse"
            loadAsyncOptions={findAddress}
            hint="Recherchez une adresse pour pouvoir récupérer les coordonnées GPS. Vous pourrez éditer les champs ci-dessous si l'adresse n'est pas complete."
            emptyMessage={false}
            filterOptions={false}
            onChange={handleChange}
          />
        </GridItem>
      )}
      <GridItem width="100%">
        <TextInputLayout
          label="Adresse"
          value={value?.content || ''}
          onChange={handleContentChange}
          // autoComplete="shipping street-address"
          textArea
          minRows={3}
          readOnly={readOnly}
        />
      </GridItem>
      {/* <GridItem width="33%" minWidth={150}>
        <TextInputField
          name="address.zipCode"
          label="Code postal"
          // autoComplete="shipping postal-code"
        />
      </GridItem>
      <GridItem width="34%" minWidth={200}>
        <TextInputField
          name="address.city"
          label="Ville"
          // autoComplete="shipping locality"
        />
      </GridItem>
      <GridItem width="33%" minWidth={150}>
        <TextInputField
          name="address.country"
          label="Pays"
          // autoComplete="shipping country"
        />
      </GridItem> */}
      <GridItem width="100%">
        <StaticMap
          useAccountAddressIfEmpty={useAccountAddressIfEmpty}
          address={value}
          height={mapHeight}
          imageRatio={mapRatio}
          onClick={onMapClick}
          project={project}
        />
      </GridItem>
    </Grid>
  );
};

export default AddressPicker;

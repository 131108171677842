import { authenticateUrl } from 'utils';
import {
  AttachmentApi,
  AttachmentReducer,
  AttachmentSelector,
  AttachmentAction
} from './data';

export {
  AttachmentApi,
  AttachmentReducer,
  AttachmentSelector,
  AttachmentAction
};

// size: thumb || small || medium || original : undefined
export const getAttachmentUrl = (attachment, size) => {
  if (attachment.isLocal) {
    return attachment.preview;
  }
  const url = authenticateUrl(attachment.fileUrl);
  if (url) {
    return `${url}${size ? `&size=${size}` : ''}`;
  } else {
    return null;
  }
};

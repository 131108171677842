import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { AuthAction } from 'features/Auth';
import Form, {
  ERRORS,
  TextInputField,
  InputLayoutVariant
} from 'components/v3/Form';
import { Grid, GridItem, Text } from 'components/v3/ui';
import { toaster } from 'components/v3/Toast';
import { useRedux } from 'components/v3/utils';
import { FormFooter, FormHeader } from './';

const Schema = Yup.object().shape({
  email: Yup.string()
    .required()
    .trim()
    .email(ERRORS.EMAIL)
});

const Content = ({ values: { email }, isSubmitting, isValid, emailSent }) => {
  const [secondaryActionVisible, setSecondaryActionVisible] = useState(false);

  useEffect(() => {
    let handler;
    if (isValid && email && emailSent) {
      handler = setTimeout(() => setSecondaryActionVisible(true), 10000);
    } else {
      setSecondaryActionVisible(false);
    }
    return () => {
      if (handler !== undefined) {
        clearTimeout(handler);
      }
    };
  }, [isValid, email, emailSent]);

  return (
    <Grid spacing={16}>
      <FormHeader
        title={emailSent ? 'Email envoyé' : 'Mot de passe oublié'}
        subtitle={
          emailSent
            ? null
            : 'Indiquez votre email. Nous vous enverrons un lien afin de réinitialiser votre mot de passe.'
        }
      />
      {!emailSent && (
        <GridItem width="100%">
          <TextInputField
            variant={InputLayoutVariant.fill}
            name="email"
            label="Adresse e-mail"
            type="email"
            autoComplete="email"
          />
        </GridItem>
      )}
      {emailSent && (
        <Fragment>
          <GridItem width="100%">
            <Text>
              Un email a été envoyé à l’adresse <strong>{email}</strong>.
            </Text>
          </GridItem>
          <GridItem width="100%">
            <Text>Merci de suivre les inscriptions qui y figurent.</Text>
          </GridItem>
        </Fragment>
      )}
      <FormFooter
        isSubmitting={isSubmitting}
        isValid={isValid}
        primaryAction={
          emailSent
            ? null
            : {
                text: 'Envoyer'
              }
        }
        secondaryAction={{
          text: secondaryActionVisible
            ? "Je n'ai rien reçu, renvoyer l'email."
            : null,
          type: secondaryActionVisible ? 'submit' : 'button'
        }}
      />
    </Grid>
  );
};

export const LostPasswordForm = () => {
  const history = useHistory();

  const [, sendForgottenPasswordEmail] = useRedux(
    null,
    AuthAction.sendForgottenPasswordEmail,
    { fetchOnMount: false }
  );

  const { state } = useLocation();
  let initialEmail = '';
  let emailSent = false;
  if (state) {
    initialEmail = state.email;
    emailSent = state.emailSent;
  }

  const handleSubmit = useCallback(
    (values, form) => {
      const { email } = values;
      sendForgottenPasswordEmail({ email })
        .onSuccess(() => {
          if (emailSent) {
            toaster.info('Demande bien envoyée.', {
              position: 'bottom-center'
            });
          } else {
            history.push({
              pathname: `/email-envoye`,
              state: { email, emailSent: true }
            });
          }
        })
        .onError(e => toaster.error(e, { position: 'bottom-center' }))
        .onEnd(() => form.setSubmitting(false));
    },
    [sendForgottenPasswordEmail, history, emailSent]
  );
  return (
    <Form
      onSubmit={handleSubmit}
      validateOnMount
      validationSchema={Schema}
      initialValues={{
        email: initialEmail || ''
      }}
    >
      {form => <Content {...form} emailSent={emailSent} />}
    </Form>
  );
};

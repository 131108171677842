import { omit } from 'lodash';
import { mergeJsonApi } from 'common/reducers';
import uniq from 'lodash/uniq';
import isSameIds from 'utils/idUtils';

const initialIssuesState = {
  issuesIds: [],
  issueById: {},
  mapIssuesIds: []
};

const issues = (state = initialIssuesState, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'NEW_ISSUE_SUCCESS':
      return {
        ...mergeJsonApi('issue', state, action),
        issuesIds: [payload.data.id, ...state.issuesIds]
      };
    case 'SET_SEARCH_MAP_QUERY':
    case 'SELECT_GROUPS':
    case 'SELECT_GROUP':
    case 'SELECT_ISSUE_KIND':
    case 'SELECT_ISSUE_STATE':
    case 'SET_MAP_DATES':
    case 'SELECT_USER':
      return {
        ...state,
        issuesIds: [],
        mapIssuesIds: []
      };
    case 'MAP_ISSUES_SUCCESS':
      return {
        ...state,
        ...mergeJsonApi('issue', state, action),
        mapIssuesIds:
          payload.meta?.pagination?.page === 1
            ? payload.data.map(d => d.id)
            : uniq([...state.mapIssuesIds, ...payload.data.map(d => d.id)])
      };
    case 'DELETE_ISSUE_SUCCESS':
      return {
        ...state,
        issueById: omit(state.issueById, payload),
        issuesIds: state.issuesIds.filter(id => !isSameIds(id, payload))
      };
    case 'LOGOUT':
    case 'SELECT_ACCOUNT':
      return initialIssuesState;
    default:
      return mergeJsonApi('issue', state, action);
  }
};

const issueEventsState = {
  issueEventById: {},
  issueEventsIds: []
};

const issueEvents = (state = issueEventsState, action) => {
  const { type } = action;

  switch (type) {
    case 'LOGOUT':
    case 'SELECT_ACCOUNT':
      return issueEventsState;
    default:
      return mergeJsonApi('issueEvent', state, action);
  }
};

export { issues, issueEvents };

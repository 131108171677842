import React from 'react';
import styled, { css } from 'styled-components';
import Avatar, { AvatarWrapper } from 'components/v3/Avatar';
import { List, ListItem as RawListItem } from 'components/v3/ui/list';
import isSameIds from 'utils/idUtils';

const AvatarsWrapper = styled(List)`
  display: flex;
  align-items: center;
  padding: 2px;
  flex: 0;

  * > * {
    font-weight: 700 !important;
  }

  li:not(:last-child) {
    min-width: 15px;
  }

  li:last-child {
    margin-right: auto;
  }

  ${AvatarWrapper} {
    background-color: white;
  }
  span {
    color: ${({ theme }) => theme.primary} !important;
  }

  ${({ wrap }) =>
    wrap &&
    css`
      width: auto;
      margin-right: 10px;
    `}
`;

const ListItem = styled(RawListItem)`
  ${({ overlap }) =>
    overlap &&
    css`
      border-radius: 50%;
      ${AvatarWrapper} {
        border: 2px solid red;
        box-shadow: 0px 0px 2px 3px #ffffff99;
      }
    `}
`;

const Avatars = ({ users, size, wrap, overlaps, maxCount = 3, tooltip }) => {
  // const uiSelector = useMemo(
  //   () => state =>
  //     UiSelector.selectUiKeys('planning.avatarSize')(state)?.planning || {},
  //   []
  // );
  // const { avatarSize } = useSelector(uiSelector, isEqual);
  const totalCount = users.length;
  if (!users || !Array.isArray(users) || users.length === 0) {
    return null;
  }

  return (
    <AvatarsWrapper wrap={wrap ? 'true' : undefined}>
      {users.slice(0, maxCount).map((u, index) => {
        let user = u;
        if (index > 1 && totalCount > maxCount) {
          user = {
            id: u.id,
            settings: {
              abbr: `+${totalCount - 2}`
            }
          };
        }
        return (
          <ListItem
            key={user.id}
            overlap={
              overlaps?.users?.find(({ ids }) =>
                ids.find(id => isSameIds(id, user.id))
              )
                ? 'true'
                : undefined
            }
          >
            <Avatar tooltip={tooltip} user={user} size={size || 'small'} />
          </ListItem>
        );
      })}
    </AvatarsWrapper>
  );
};

Avatars.displayName = 'Avatars';

export default React.memo(Avatars);

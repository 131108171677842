import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';
import isEqual from 'lodash/isEqual';
import { MdSearch } from 'react-icons/md';
import { Grid, GridItem, Container } from '../ui';
import { TextInputLayout, InputLayoutVariant } from '../InputLayout';
import { Wrapper as DateWrapper } from '../DatePicker/DatePicker';
import Filter from './Filter';
import { UiAction } from 'features/UiPreferences';

export const Wrapper = styled(Container)`
  flex-grow: unset;
  align-self: center;
  margin-bottom: 16px;
`;

const FilterItem = styled(GridItem)`
  position: relative;
  z-index: 1;
  height: 100%;
  display: flex;
  align-items: center;
  ${({ active }) =>
    active &&
    css`
      box-shadow: 0px 1px 0px 0px ${({ theme }) => theme.primaryLight};
    `};

  flex: 1 !important;

  > * {
    width: 100%;
  }

  :not(:last-child) {
    &:before {
      position: absolute;
      top: 4;
      bottom: 4;
      right: 0;
      content: '';
      display: block;
      border-right: 1px solid ${({ theme }) => theme.separator};
    }
  }
`;

const SearchItem = styled(FilterItem)`
  min-width: 160px;
  flex-grow: 1;
  flex: 100 !important;
`;

export const FilterWrapper = styled(Grid)`
  box-shadow: ${({ theme }) => theme.boxShadowSmall};
  border-radius: ${({ theme }) => theme.borderButtonRadius};
  z-index: 1;
  background: white;
  align-items: center;
  > * {
    border-radius: 0;
    border: none;
  }
  > *:first-child {
    border-radius: ${({ theme }) => theme.borderButtonRadius} 0 0
      ${({ theme }) => theme.borderButtonRadius};
  }
  > *:last-child {
    border-radius: 0 ${({ theme }) => theme.borderButtonRadius}
      ${({ theme }) => theme.borderButtonRadius} 0;
  }
  > div:not(:last-child):not(:only-child),
  > span:not(:last-child):not(:only-child) {
    border-right: 1px solid rgba(0, 0, 0, 0.08);
  }
  > *:only-child {
    flex: 1;
    border-radius: ${({ theme }) => theme.borderButtonRadius};
  }

  ${DateWrapper} {
    flex-grow: 1;
  }
`;

const FilterBar = ({
  searchQuery,
  searchLabel,
  filters,
  onChange,
  onChanges,
  variant = InputLayoutVariant.flat.medium,
  searchMaxWidth,
  searchMinWidth,
  disableSearch,
  ...props
}) => {
  const dispatch = useDispatch();
  const filterComponents = useMemo(
    () => (filters || []).filter(filter => filter.kind !== 'search'),
    [filters]
  );

  const searchFilter = (filters || []).find(filter => filter.kind === 'search');

  const handleChange = useCallback(
    filter => {
      if (onChange) {
        onChange(filter);
      }
      let included = false;
      let newFilters = (filters || []).map(f => {
        if (f.key === filter.key) {
          included = true;
          return filter;
        } else {
          return f;
        }
      });
      if (!included) {
        newFilters = [...newFilters, filter];
      }
      if (isEqual(newFilters, filters)) {
        return;
      }
      if (onChanges) {
        onChanges(newFilters, {
          ...(newFilters || []).reduce((acc, filter) => {
            return {
              ...acc,
              ...filter.asQueryParam(filter.value)
            };
          }, {})
        });
      }
      if (filter.key?.startsWith('ui.')) {
        dispatch(
          UiAction.setFilter({
            [filter.key.replace('ui.', '')]: filter.value
          })
        );
      }
    },
    [filters, onChange, onChanges, dispatch]
  );

  return (
    <Wrapper {...props} vertical>
      <FilterWrapper spacing={0}>
        {filterComponents.map(filter => {
          const { key, value } = filter;
          const active = Array.isArray(value)
            ? value.length > 0
            : Boolean(value);
          return (
            <FilterItem key={key} active={active ? 'true' : undefined}>
              <Filter
                {...filter}
                variant={variant}
                onChange={value =>
                  handleChange({
                    ...filter,
                    value
                  })
                }
              />
            </FilterItem>
          );
        })}
        {!disableSearch && (
          <SearchItem
            style={{ maxWidth: searchMaxWidth, minWidth: searchMinWidth }}
          >
            <TextInputLayout
              iconLeading={MdSearch}
              variant={variant}
              debounced
              fillWidth
              label={searchLabel}
              value={searchFilter?.value || ''}
              onChangeDebounce={value =>
                handleChange({
                  ...(searchFilter || {
                    kind: 'search',
                    key: 'search',
                    asQueryParam: v => ({ [searchQuery]: v })
                  }),
                  value
                })
              }
            />
          </SearchItem>
        )}
      </FilterWrapper>
    </Wrapper>
  );
};

export default FilterBar;

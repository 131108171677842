import { createAction } from 'redux-actions';

// Common
export const setFilter = createAction('SET_FILTER');
export const selectPlanningView = createAction('SELECT_PLANNING_VIEW');
export const selectMapMode = createAction('SELECT_MAP_MODE');
export const selectStatus = createAction('SELECT_STATUS');
export const selectGroup = createAction('SELECT_GROUP');
export const selectGroups = createAction('SELECT_GROUPS');
export const selectUser = createAction('SELECT_USER');
export const selectProjectKind = createAction('SELECT_PROJECT_KIND');
export const selectProject = createAction('SELECT_PROJECT');
export const setEmergency = createAction('SELECT_EMERGENCY');

export const selectYear = createAction('SELECT_YEAR');
export const selectPurveyor = createAction('SELECT_PURVEYOR');
export const selectCarrier = createAction('SELECT_CARRIER');
export const setSearchMapQuery = createAction('SET_SEARCH_MAP_QUERY');
export const setSearchProjectQuery = createAction('SET_SEARCH_PROJECT_QUERY');
export const setSearchIssueQuery = createAction('SET_SEARCH_ISSUE_QUERY');
export const setDates = createAction('SET_DATES');
export const selectProjectState = createAction('SELECT_PROJECT_STATE');
export const selectIssueState = createAction('SELECT_ISSUE_STATE');

export const selectOrganization = createAction('SELECT_ORGANIZATION');

// Service
export const setSearchServiceQuery = createAction('SET_SEARCH_SERVICE_QUERY');

// Invoice
export const selectInvoiceKind = createAction('SELECT_INVOICE_KIND');
export const selectInvoiceState = createAction('SELECT_INVOICE_STATE');
export const setSearchInvoiceQuery = createAction('SET_SEARCH_INVOICE_QUERY');

// User
export const selectRole = createAction('SELECT_ROLE');

// Holiday
export const setHolidayDates = createAction('SET_HOLIDAY_DATES');
export const selectHolidayKind = createAction('SELECT_HOLIDAY_KIND');

// Plannings
export const updateAvatarSize = createAction('UPDATE_AVATAR_SIZE');
export const updateCompact = createAction('UPDATE_COMPACT');
export const updateOverlap = createAction('UPDATE_OVERLAP');
export const selectOverableCustomData = createAction(
  'SELECT_PROJECT_OVERABLE_CUSTOM_DATA'
);
export const updatePhaseColor = createAction('UPDATE_PHASE_COLOR');
export const updateLineHeight = createAction('UPDATE_LINE_HEIGHT');
export const updateShowWeekend = createAction('UPDATE_SHOW_WEEK_END');

// Calendar
export const addCalendarUsers = createAction('ADD_CALENDAR_USERS');
export const updateCalendarUsersCheck = createAction(
  'UPDATE_CALENDAR_USERS_CHECK'
);
export const removeCalendarUser = createAction('REMOVE_CALENDAR_USER');

// Deviations
export const toggleUseWaypoints = createAction('TOGGLE_USE_WAY_POINTS');

export const selectOldProjectList = createAction('SELECT_OLD_PROJECT_LIST');

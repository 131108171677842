import { mergeJsonApi } from 'common/reducers';

const initialState = {
  gpsBoxById: {},
  gpsBoxesIds: []
};

const gpsBoxes = (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case 'LOGOUT':
    case 'SELECT_ACCOUNT':
      return initialState;
    default:
      return mergeJsonApi('gpsBox', state, action);
  }
};

export default gpsBoxes;

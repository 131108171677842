import React, { useCallback, useMemo, Fragment, useEffect } from 'react';
import { Route, useRouteMatch, useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { UiSelector } from 'features/UiPreferences';
import { isEqual } from 'lodash';
import Scheduler, { SCHEDULER_TYPE } from './View';
import { PlanningSelector, PlanningAction } from 'features/Plannings';
import MachineModal from 'features/Machines/components/v3/Modal';
import { useRedux } from 'components/v3/utils';
import { useAccountConfig } from 'config';
import ProjectModal from 'features/Projects/components/v3/Modal';

const Machine = () => {
  const history = useHistory();
  const location = useLocation();
  const { path, url } = useRouteMatch();
  const { accountId } = useAccountConfig();

  const selectUi = useMemo(() => UiSelector.selectUiKeys('selectedProjectKind'), []);
  const { selectedProjectKind } = useSelector(selectUi, isEqual);

  const [machines, fetch] = useRedux(PlanningSelector.selectPlanningMachines, PlanningAction.getMachines, {
    initialParams: {
      sort: 'position,display_name',
      perPage: 500,
      page: 1,
      // deletedAtPresent: false,
      projectKindIdEq: selectedProjectKind ? selectedProjectKind.id : undefined
    },
    fetchOnMount: false
  });

  const handleSearch = useCallback(
    (query) => {
      let byQuery = query.trim();
      byQuery = byQuery.length === 0 ? undefined : byQuery;
      fetch((params) => ({ ...params, displayNameContAny: byQuery }));
    },
    [fetch]
  );

  useEffect(() => {
    fetch();
  }, [fetch, accountId]);

  const handleResourceClick = useCallback((id) => history.push(`${path}/${id}`), [history, path]);

  const resources = useMemo(() => [...machines, { id: 'none', displayName: 'Sans machine' }], [machines]);
  return (
    <Fragment>
      <Scheduler
        schedulerType={SCHEDULER_TYPE.MACHINE}
        resources={resources}
        resourceLabelText={`Machines (${machines.length})`}
        onResourceClick={handleResourceClick}
        onSearch={handleSearch}
      />
      <Route exact path={[`${path}/new`, `${path}/:machineId`]}>
        <MachineModal
          onRequestClose={() => {
            if (location.key) {
              history.goBack();
            } else {
              history.replace(url);
            }
          }}
        />
      </Route>
      <Route exact path={[`${path}/projets/:projectId`]}>
        <ProjectModal
          onRequestClose={() => {
            if (location.key) {
              history.goBack();
            } else {
              history.replace(url);
            }
          }}
        />
      </Route>
    </Fragment>
  );
};

export default Machine;

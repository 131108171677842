import omit from 'lodash/omit';
import uniq from 'lodash/uniq';
import { mergeJsonApi } from 'common/reducers';

const initialInvoiceState = {
  invoiceById: {},
  invoicesIds: [],
  totalAmount: ''
};

const invoices = (state = initialInvoiceState, action) => {
  const { payload, type } = action;

  switch (type) {
    case 'INVOICES_AMOUNTS':
      return {
        ...state,
        totalAmount: payload
      };
    case 'NEW_INVOICE_SUCCESS':
      return {
        ...mergeJsonApi('invoice', state, action),
        invoicesIds: [payload.data.id, ...state.invoicesIds]
      };
    case 'DELETE_INVOICE_SUCCESS':
      return {
        ...state,
        invoiceById: omit(state.invoiceById, payload),
        invoicesIds: state.invoicesIds.filter(id => parseInt(id) !== payload)
      };
    case 'LOGOUT':
    case 'SELECT_ACCOUNT':
      return initialInvoiceState;
    default:
      return mergeJsonApi('invoice', state, action);
  }
};

const initialServiceState = {
  serviceById: {},
  servicesIds: [],
  searchIds: []
};

const services = (state = initialServiceState, action) => {
  const { payload, type } = action;
  const pagination = payload?.meta?.pagination || {};
  const { page } = pagination;
  switch (type) {
    case 'NEW_SERVICE_SUCCESS':
      return {
        ...mergeJsonApi('service', state, action),
        servicesIds: [payload.data.id, ...state.servicesIds]
      };
    case 'DELETE_SERVICE_SUCCESS':
      return {
        ...state,
        serviceById: omit(state.serviceById, payload),
        servicesIds: state.servicesIds.filter(id => parseInt(id) !== payload)
      };
    case 'SEARCH_SERVICES_SUCCESS':
      let searchIds = state.searchIds || [];
      if (page > 1) {
        searchIds = uniq([
          ...searchIds,
          ...payload.data.map(service => service.id)
        ]);
      } else {
        searchIds = payload.data.map(service => service.id);
      }
      return {
        ...state,
        ...mergeJsonApi('service', state, action),
        [type]: {
          fetchAt: new Date().getTime()
        },
        searchIds
      };
    case 'LOGOUT':
    case 'SELECT_ACCOUNT':
      return initialServiceState;
    default:
      return mergeJsonApi('service', state, action);
  }
};

const initialGroupServiceState = {
  groupServiceById: {},
  groupServicesIds: []
};

const groupServices = (state = initialGroupServiceState, action) => {
  const { type } = action;
  switch (type) {
    case 'LOGOUT':
    case 'SELECT_ACCOUNT':
      return initialGroupServiceState;
    default:
      return mergeJsonApi('groupService', state, action);
  }
};

const initialUnitState = {
  unitById: {},
  unitsIds: []
};

const units = (state = initialUnitState, action) => {
  const { type } = action;
  switch (type) {
    case 'LOGOUT':
    case 'SELECT_ACCOUNT':
      return initialUnitState;
    default:
      return mergeJsonApi('unit', state, action);
  }
};

const initialSubcontractingsState = {
  subcontractingById: {},
  subcontractingsIds: []
};

const subcontractings = (state = initialSubcontractingsState, action) => {
  const { payload, type } = action;

  switch (type) {
    case 'NEW_SUBCONTRACTING_SUCCESS':
      return {
        ...mergeJsonApi('subcontracting', state, action),
        subcontractingsIds: [payload.data.id, ...state.subcontractingsIds]
      };
    case 'DELETE_SUBCONTRACTING_SUCCESS':
      return {
        ...state,
        subcontractingById: omit(state.subcontractingById, payload),
        subcontractingsIds: state.subcontractingsIds.filter(
          id => parseInt(id) !== payload
        )
      };
    case 'LOGOUT':
    case 'SELECT_ACCOUNT':
      return initialSubcontractingsState;
    default:
      return mergeJsonApi('subcontracting', state, action);
  }
};

export { invoices, services, groupServices, units, subcontractings };

import { createAction } from 'redux-actions';
import * as Api from './api';
import { omit, keys } from 'lodash';
import { decamelize } from 'humps';
import isSameIds from 'utils/idUtils';

const selectAccountAction = createAction('SELECT_ACCOUNT');
export const selectAccount = accountId => async (dispatch, getState) => {
  if (isSameIds(getState().ui.currentAccountId, accountId)) return true;
  const response = await Api.getAccount(accountId);
  dispatch(
    selectAccountAction({
      response,
      id: accountId,
      userId: getState().ui.userId
    })
  );
};

const loadingAccounts = createAction('ACCOUNTS_LOADING');
const receiveAccounts = createAction('ACCOUNTS_SUCCESS');
export const getAccounts = () => async dispatch => {
  dispatch(loadingAccounts());
  const response = await Api.getAccounts();
  dispatch(receiveAccounts(response));
  return response;
};

const receiveAccount = createAction('ACCOUNT_SUCCESS');
export const getAccount = id => async dispatch => {
  const response = await Api.getAccount(id);
  dispatch(receiveAccount(response));
  return response;
};

export const updateSettingAccount =
  ({ id, payload: account, ...config }) =>
  async dispatch => {
    const result = await Api.putAccount(id, account, config);
    dispatch(receiveAccount(result));
    return result;
  };

export const updateAccount =
  ({ payload }) =>
  async dispatch => {
    const { logo, address } = payload;
    const formData = new FormData();
    if (logo) {
      formData.append('account[logo]', logo);
    }
    keys(omit(payload, ['logo', 'address'])).forEach(key => {
      return formData.append(`account[${decamelize(key)}]`, payload[key]);
    });
    if (address) {
      keys(address).forEach(key => {
        if (!(key === 'id' && !address[key])) {
          return formData.append(
            `account[address_attributes][${decamelize(key)}]`,
            address[key]
          );
        }
      });
    }
    const response = await Api.updateAccount(formData);
    dispatch(receiveAccount(response));
    return response;
  };

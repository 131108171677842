import { createDeepEqualSelector, getProps } from 'common/selector';
import { getState, selectRelations } from 'common/selector';

export const getTeams = state => state.teams;

export const selectTeams = createDeepEqualSelector(
  [getTeams, getState],
  ({ teamsIds, teamById }, state) =>
    teamsIds
      .map(id => selectRelations('team', teamById[id], state))
      .filter(Boolean)
);

export const selectTeam = createDeepEqualSelector(
  [getTeams, getProps, getState],
  ({ teamById }, { teamId }, state) =>
    selectRelations('team', teamById[teamId], state)
);

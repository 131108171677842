import styled, { css } from 'styled-components';

export type IconSize = 'giant' | 'large' | 'medium' | 'small' | 'tiny';

const SizeCss = {
  giant: css`
    width: 48px;
    height: 48px;
    > svg {
      width: 40px;
      height: 40px;
    }
  `,
  large: css`
    width: 40px;
    height: 40px;
    > svg {
      width: 32px;
      height: 32px;
    }
  `,
  medium: css`
    width: 32px;
    height: 32px;
    > svg {
      width: 24px;
      height: 24px;
    }
  `,
  small: css`
    width: 24px;
    height: 24px;
    > svg {
      width: 18px;
      height: 18px;
    }
  `,
  tiny: css`
    width: 18px;
    height: 18px;
    > svg {
      width: 14px;
      height: 14px;
    }
  `
};

const Icon = styled('i')<{ size?: IconSize }>`
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  ${({ size }) => (size && SizeCss[size]) || SizeCss.medium};
`;

export { Icon };

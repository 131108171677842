import * as VoucherApi from './api';
import * as VoucherAction from './action';
import * as VoucherReducer from './reducer';
import * as VoucherSelector from './selector';

export { VoucherApi, VoucherAction, VoucherReducer, VoucherSelector };

export const voucherKindAsOption = voucherKind => {
  if (Array.isArray(voucherKind)) {
    return voucherKind.map(voucherKindAsOption);
  } else if (voucherKind) {
    return {
      value: voucherKind.id,
      label: voucherKind.name,
      original: voucherKind
    };
  } else {
    return null;
  }
};

export const voucherVersionAsValues = voucherVersion => ({
  id: voucherVersion?.id || null,
  voucherKindId: null
});

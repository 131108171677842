import React, { useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import RawSpinner from 'react-md-spinner';
import Card, { CardHeader, CardContent } from 'components/v3/ui/card';
import { Container, Title, Text, Overline, NavLink } from 'components/v3/ui';
import { useRedux } from 'components/v3/utils';
import { DeviationAction, DeviationSelector } from 'features/Deviation';
import { StaticMap } from 'components/v3/Map';
import { printDateRange } from 'utils/dates';

const Spinner = styled.div`
  color: ${({ theme }) => theme.primaryLight};
`.withComponent(RawSpinner);

const GridWrapper = styled.div`
  display: grid;
  justify-items: center;
  grid-gap: 16px;
  margin: 16px;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
`;

const SpinnerWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const CenterText = styled(Text)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-height: 100px;
  margin: 16px;
`;

const Reference = styled(Overline)`
  margin-top: 4px;
`;

const Dates = styled(Text)`
  margin-top: 6px;
`;

const DeviationCardItem = ({ to, deviation }) => {
  const { displayName, reference, endAt, startAt } = deviation;

  return (
    <Card as={NavLink} to={to}>
      <CardHeader>
        <Title>{displayName}</Title>
        <Reference color="theme">{reference}</Reference>
      </CardHeader>
      <CardContent>
        <StaticMap disableLink project={deviation} height={250} />
        <Dates color="caption" weight="semiBold">
          {printDateRange(
            {
              startAt,
              endAt
            },
            'EEEE dd MMMM yyyy'
          )}
        </Dates>
      </CardContent>
    </Card>
  );
};

const PublicDeviations = ({ onDeviationsCount, onLoading }) => {
  const { url, params } = useRouteMatch();
  const { accountName } = params;
  const [deviations, fetchDeviations, state] = useRedux(
    DeviationSelector.selectDeviations,
    DeviationAction.getDeviations,
    { fetchOnMount: false, useLocalState: true }
  );

  const { isLoading, errorMessage } = state;

  useEffect(() => {
    fetchDeviations({ tenant: accountName, page: 1, perPage: 100 });
  }, [fetchDeviations, accountName]);

  const count = deviations.length;

  useEffect(() => {
    onDeviationsCount(count);
  }, [count, onDeviationsCount]);

  useEffect(() => {
    onLoading(isLoading);
  }, [onLoading, isLoading]);

  return (
    <Container vertical>
      {isLoading && deviations.length === 0 && (
        <SpinnerWrapper>
          <Spinner singleColor="currentColor" />
        </SpinnerWrapper>
      )}
      {!isLoading && errorMessage && deviations.length === 0 && (
        <CenterText variant="caption">{errorMessage}</CenterText>
      )}
      <GridWrapper>
        {deviations.map(deviation => (
          <DeviationCardItem
            key={deviation.id}
            deviation={deviation}
            to={`${url}/${deviation.id}`}
          />
        ))}
      </GridWrapper>
    </Container>
  );
};

export default PublicDeviations;

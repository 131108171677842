import React from 'react';
import Avatar from 'components/v3/Avatar';
import { UserApi, UserAction, UserReducer, UserSelector } from './data';

export { UserApi, UserAction, UserReducer, UserSelector };

export const userAsOption = (user, avatarHidden) => {
  if (Array.isArray(user)) {
    return user.map(user => userAsOption(user, avatarHidden));
  } else if (user) {
    return {
      value: user.id,
      label: user.displayName || `${user.firstName} ${user.lastName}`,
      icon: avatarHidden ? null : <Avatar size="small" user={user} />,
      subtitle: user.displayTopRole,
      original: user
    };
  } else {
    return null;
  }
};

export const userAsValues = user => {
  return {
    id: user?.id || null,
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    email: user?.email || '',
    role: (user && (user.role || user.topRole)) || '',
    groups: user?.groups || [],
    production: Boolean(user?.settings?.production),
    notifyByEmail: true,
    settings: {
      id: user?.settings?.id,
      abbr: user?.settings?.abbr || '',
      color: user?.settings?.color || '',
      dailyCost: user?.settings?.dailyCost || 0,
      production: Boolean(user?.settings?.production),
      description: user?.settings?.description,
      job: user?.settings?.job
    }
  };
};

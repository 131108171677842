import { post, put } from 'common/api';
import { CLIENT_ID } from '../../../constants';

export const login = payload =>
  post(`/oauth/token`, {
    ...payload,
    client_id: CLIENT_ID,
    grant_type: 'password',
    scope: 'web'
  });

export const refreshToken = token =>
  post(`/oauth/token`, {
    refresh_token: token,
    client_id: CLIENT_ID,
    grant_type: 'refresh_token'
  });

export const sendForgottenPasswordEmail = payload =>
  post(`/v5/users/password`, {
    user: { email: payload.email },
    client_id: CLIENT_ID
  });

export const resetPassword = ({ email, password, resetPasswordToken }) =>
  post(`/v5/users/reset`, {
    user: { email, password },
    resetPasswordToken,
    clientId: CLIENT_ID
  });

export const acceptInvite = ({
  email,
  password,
  invitationToken,
  firstName,
  lastName
}) =>
  post(`/v5/users/accept`, {
    user: { email, password, firstName, lastName },
    invitationToken,
    clientId: CLIENT_ID
  });

export const updatePassword = payload => put(`/v5/users/me/passwords`, payload);

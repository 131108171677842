import React, {
  useCallback,
  useMemo,
  Fragment,
  useState,
  useEffect
} from 'react';
import { SelectField } from 'components/v3/Form';
import { SelectInputLayout } from 'components/v3/InputLayout';
import { useRedux } from 'components/v3/utils';
import MachineModal from './Modal';
import { jsonApiToObject } from 'common/api';
import {
  MachineSelector,
  MachineAction,
  machineAsOption
} from 'features/Machines';
import { permissions, useAccountConfig } from 'config';

const formatCreateLabel = input => `Créer la machine ${input}`;

const MachineSelect = ({ value, onChange, ...props }) => {
  const { accountId, hasPermission } = useAccountConfig();
  const [machineModal, setMachineModal] = useState();
  const [machines, fetch] = useRedux(
    MachineSelector.selectMachines,
    MachineAction.getMachines,
    {
      fetchOnMount: false,
      useLocalState: true,
      initialParams: {
        page: 1,
        perPage: 500,
        useCache: true
      }
    }
  );

  const handleCreateOption = useCallback(input => {
    setMachineModal({
      displayName: input
    });
  }, []);

  const handleEditOption = useCallback(value => {
    setMachineModal(value);
  }, []);

  const options = useMemo(() => machineAsOption(machines), [machines]);

  const currentValue = useMemo(() => machineAsOption(value), [value]);

  useEffect(() => {
    fetch(params => params);
  }, [accountId, fetch]);

  return (
    <Fragment>
      <SelectInputLayout
        label="Machine"
        value={currentValue}
        options={options}
        isSearchable
        onChange={onChange}
        inputCreatable={hasPermission([
          permissions.RESOURCES_CONFIG,
          'machine.create'
        ])}
        createLabel="Créer une machine"
        formatCreateLabel={formatCreateLabel}
        onCreateOption={handleCreateOption}
        optionEditable={hasPermission([
          permissions.RESOURCES_CONFIG,
          'machine.create'
        ])}
        onEditOptionClick={handleEditOption}
        {...props}
      />
      {machineModal && (
        <MachineModal
          machine={machineModal}
          onRequestClose={() => setMachineModal(null)}
          onSubmitSuccess={response => {
            const machine = jsonApiToObject(response);
            if (props.multi) {
              onChange([...currentValue, machine]);
            } else {
              onChange(machine);
            }
          }}
        />
      )}
    </Fragment>
  );
};

export const MachineField = props => {
  return <SelectField component={MachineSelect} {...props} />;
};

export default MachineSelect;

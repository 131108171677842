import { get, put, post, destroy } from 'common/api';

export const getUsers = params =>
  get('/v5/users', {
    ...(params || {}),
    sort: (params || {}).sort ? params.sort : 'position,first_name,display_name'
  });

export const getUserPhases = params => get('/v5/user_phases', params);

export const createUser = (user, config) => post('/v5/users', { user }, config);

export const getUser = (id, config) => get(`/v5/users/${id}`, config);

export const putUser = (id, user, config) =>
  put(`/v5/users/${id}`, { user }, config);

export const deleteUser = (id, config) => destroy(`/v5/users/${id}`, config);

export const resendInvitation = (id, config) =>
  put(`/v5/users/${id}/resend_invitation`, {}, config);

export const getWorkRecords = ({ userId, ...rest }) => {
  return get(`/v5/users/${userId}/work_records`, rest);
};

export const updateWorkRecords = ({ userId, year, month, user, ...config }) => {
  return put(`/v5/users/${userId}/work_records`, { year, month, user }, config);
};

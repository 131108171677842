import React, { useMemo, useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { getYear, getISOWeek } from 'date-fns';
import groupBy from 'lodash/groupBy';
import isEqual from 'lodash/isEqual';
import RawSpinner from 'react-md-spinner';
import { Container, Button } from 'components/v3/ui';
import { useRedux, usePrevious } from 'components/v3/utils';
import { formatISODate } from 'utils/dates';
import { authenticateUrl } from 'utils';
import { API_BASE } from '../../../../constants';
import {
  CarrierAction,
  CarrierSelector,
  CarrierApi,
  carrierOrderExportHistoryAsOption
} from 'features/Carriers';
import EmptyState from 'components/EmptyState';
import CarrierOrderTable from './CarrierOrderTable';
import Scroller from 'components/v3/Scroller';
import { spacing } from 'ui';
import { useAction } from 'components/v3/utils';
import { jsonApiToObject } from 'common/api';
import { toaster } from 'components/v3/Toast';
import Dropdown from 'components/v3/Dropdown';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: ${spacing()};
  padding-left: ${spacing()};
  padding-right: ${spacing()};
`;

const HeaderActions = styled.div`
  display: inline-flex;
`;

const SpinnerWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Spinner = styled.div`
  color: ${({ theme }) => theme.primaryLight};
`.withComponent(RawSpinner);

const Tables = React.memo(({ carrierOrders, date }) => {
  const groupedByTeam = groupBy(
    carrierOrders.sort((a, b) => b.teamId - a.teamId),
    item => item.teamDisplayName
  );

  return Object.keys(groupedByTeam).map(team => {
    const teamCarrierOrders = groupedByTeam[team]?.sort((a, b) =>
      `${a.id}-${a.projectDisplayName}` < `${b.id}-${b.projectDisplayName}`
        ? -1
        : 1
    );

    return (
      <CarrierOrderTable
        key={team}
        title={team}
        carrierOrders={teamCarrierOrders || []}
        date={date}
      />
    );
  });
});

const CarriersOrders = ({ carrierId, date: propsDate }) => {
  const action = useAction();
  const [
    carrierOrdersExportHistories,
    setCarrierOrdersExportHistories
  ] = useState([]);
  const [fetchHistory, setFetchHistory] = useState(false);

  const date = useMemo(() => {
    return {
      isoValue: formatISODate(propsDate),
      year: getYear(propsDate),
      week: getISOWeek(propsDate)
    };
  }, [propsDate]);

  const prevDependencies = usePrevious({ carrierId, date });
  const dependenciesChanged = !isEqual(prevDependencies, { carrierId, date });

  const selectCarrierOrders = useMemo(
    () => state => {
      return CarrierSelector.selectCarrierOrdersByWeek(state, {
        carrierId,
        year: date.year,
        week: date.week
      });
    },
    [carrierId, date]
  );

  const [carrierOrders, fetchWeekCarrierOrders, state] = useRedux(
    selectCarrierOrders,
    CarrierAction.getWeekCarrierOrders,
    {
      fetchOnMount: false,
      useLocalState: true
    }
  );

  useEffect(() => {
    fetchWeekCarrierOrders({
      carrierIdEq: carrierId,
      byWeek: date.isoValue
    });
  }, [fetchWeekCarrierOrders, carrierId, date]);

  const fetchExportHistories = useCallback(() => {
    setCarrierOrdersExportHistories([]);
    action(
      cancelToken =>
        CarrierApi.getCarrierOrdersExportHistories({
          byWeek: date.isoValue,
          carrierId,
          sort: '-created_at',
          perPage: 500,
          page: 1,
          cancelToken
        }),
      'getCarrierOrdersExportHistories',
      true
    )
      .onSuccess(result => {
        setFetchHistory(false);
        const history = jsonApiToObject(result) || [];
        const length = history.length;
        setCarrierOrdersExportHistories(
          history
            .map((history, index) => ({
              ...history,
              version: length - index
            }))
            .map(carrierOrderExportHistoryAsOption)
        );
      })
      .onError(toaster.error);
  }, [action, carrierId, date]);

  useEffect(() => {
    setFetchHistory(false);
  }, [carrierId, date]);

  useEffect(() => {
    let cancel = false;
    if (!fetchHistory) {
      return;
    }
    setTimeout(() => {
      if (!cancel) {
        fetchExportHistories();
      }
    }, 5000);
    return () => {
      cancel = true;
    };
  }, [fetchHistory, fetchExportHistories]);

  useEffect(() => {
    fetchExportHistories();
  }, [fetchExportHistories]);

  if ((state.isLoading && carrierOrders?.length === 0) || dependenciesChanged) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }

  if (carrierOrders?.length > 0) {
    return (
      <Container vertical>
        <Header>
          <HeaderActions />
          <HeaderActions>
            {carrierOrdersExportHistories.length > 0 && (
              <Dropdown
                maxWidth="400px"
                options={carrierOrdersExportHistories}
                showIndicator={false}
                onChange={history => {
                  window.open(
                    authenticateUrl(
                      `${API_BASE}/v5/carrier_orders_export_histories/pdf?id=${
                        history.original.id
                      }`
                    ),
                    '_blank'
                  );
                }}
              >
                <Button variant="textCaption" underline>
                  Historique des exports
                </Button>
              </Dropdown>
            )}
            <Button
              href={authenticateUrl(
                `${API_BASE}/v5/carrier_orders/pdf?carrier_id_eq=${carrierId}&by_week=${
                  date.isoValue
                }`
              )}
              as="a"
              target="_blank"
              onClick={() => setFetchHistory(true)}
            >
              Exporter en PDF
            </Button>
          </HeaderActions>
        </Header>
        <Scroller grow>
          <Container vertical padded>
            <Tables carrierOrders={carrierOrders} date={date.isoValue} />
          </Container>
        </Scroller>
      </Container>
    );
  } else {
    return (
      <EmptyState
        opened
        message="Cette semaine ne contient aucun bon de transport"
      />
    );
  }
};

export default CarriersOrders;

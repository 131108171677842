import { useLocation } from 'react-router';
import queryString from 'query-string';
import { camelizeKeys as camelize } from 'humps';
import { useMemo } from 'react';

const useQuery = (camelizeKeys = true) => {
  const search = useLocation().search;
  const queries = useMemo(() => {
    const parsed = queryString.parse(search, {
      decode: false
    });
    return camelizeKeys ? camelize(parsed) : parsed;
  }, [search, camelizeKeys]);
  return queries;
};

export default useQuery;

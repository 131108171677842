import { createDeepEqualSelector, selectId } from 'common/selector';
import { getState, selectRelations } from 'common/selector';

export const getGroups = state => state.groups;

export const selectGroups = createDeepEqualSelector(
  [getGroups, getState],
  ({ groupsIds, groupById }, state) =>
    groupsIds
      .map(id => selectRelations('group', groupById[id], state))
      .filter(Boolean)
);

export const selectGroup = createDeepEqualSelector(
  [getGroups, selectId('group'), getState],
  ({ groupById }, groupId, state) => {
    const group = groupById[groupId];
    if (!group) {
      return null;
    }
    return selectRelations('group', group, state);
  }
);

import React, { useCallback, useState, useMemo, Fragment } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { formatDistanceToNow } from 'date-fns';
import fr from 'date-fns/locale/fr';
import * as Yup from 'yup';
import isEqual from 'lodash/isEqual';
import { MdSend } from 'react-icons/md';
import { EntityModal } from 'components/v3/Modal';
import { ERRORS, TextInputField, AddressField } from 'components/v3/Form';
import {
  Grid,
  GridItem as RawGridItem,
  SectionTitle,
  Button
} from 'components/v3/ui';
import {
  ProjectSelector,
  ProjectAction,
  projectAsValues
} from 'features/Projects';
import { Permission, features, useAccountConfig, permissions } from 'config';
import { UserField } from 'features/Users/components/v3/UserSelect';
import AttachmentBox from 'features/Attachments/components/v3/Box';
import State from './State';
import { ProjectKindField } from 'features/ProjectKinds/components/v3/Select';
import { GroupField } from 'features/Groups/components/v3/Select';
import { OrganizationField } from 'features/Organizations/components/v3/Select';
import { ContactField } from 'features/Contacts/components/v3/Select';
import AdministrativeActivitiesField from './Activities';
import InvitationModal from './InvitationModal';
import CustomFields from 'features/CustomFieldGroups/components/v3/CustomFields';
import { IcExternalLink } from 'common/images/icons';
import { useQuery } from 'components/v3/utils';
import { useHistory } from 'react-router';
import Deviation from './Deviation';
import { jsonApiToObject } from 'common/api';
import isSameIds from 'utils/idUtils';
import { parseDate, printDate } from 'utils/dates';

const GridItem = styled(RawGridItem)`
  min-width: 280px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;

  > *:not(:last-child) {
    margin-right: 8px;
  }
  > *:last-child {
    margin-right: 16px;
  }
`;

const valuesDependencies = {
  organization: {
    modifier: values => {
      if (
        !values.contact ||
        isSameIds(values.organization?.id, values.contact?.id)
      ) {
        return values.organization;
      } else {
        return values.contact?.organization;
      }
    },
    triggers: ['contact']
  },
  contact: {
    modifier: values => {
      if (
        isSameIds(values.contact?.organization?.id, values.organization?.id)
      ) {
        return values.contact;
      } else {
        return null;
      }
    },
    triggers: ['organization']
  }
};

const Header = ({ form, fetchStatus, readOnly, projectId }) => {
  if (!fetchStatus.hasFetch && projectId) {
    return null;
  }
  const { id, state } = form.values;
  return (
    <HeaderWrapper>
      <State
        size="medium"
        projectId={id}
        value={state}
        right={0}
        readOnly={readOnly}
        onChange={
          fetchStatus.hasFetch || !projectId
            ? state => form.setFieldValue('state', state)
            : undefined
        }
      />
      {id && (
        <Button icon={IcExternalLink} variant="text" to={`/projets/${id}`}>
          Voir le détail
        </Button>
      )}
    </HeaderWrapper>
  );
};

const validationSchema = Yup.object().shape({
  projectKind: Yup.object().nullable().required(ERRORS.REQUIRED)
});

const ProjectModal = ({
  onRequestClose,
  organization,
  project: propsProject,
  ...props
}) => {
  const { hasPermission, hasFeature, translate } = useAccountConfig();
  const dispatch = useDispatch();
  const { view } = useQuery();
  const history = useHistory();
  const { params, url } = useRouteMatch();
  let { projectId } = params;
  if (propsProject) {
    projectId = propsProject.id;
  }
  const [invitationModalOpen, setInvitationModalOpen] = useState(false);
  const isNew = !Boolean(projectId);
  const hasDeviationFeature = useMemo(
    () => hasFeature(features.DEVIATIONS),
    [hasFeature]
  );
  const isDeviationVisible = hasDeviationFeature && view === 'deviation';

  const selectProject = useMemo(
    () => state => ProjectSelector.selectProjectV3(state, { projectId }),
    [projectId]
  );

  let project = useSelector(selectProject, isEqual);
  if (!project) {
    project = propsProject;
  }
  const canEdit = hasPermission(isNew ? 'project.create' : 'project.update', {
    target: project
  });
  const canDelete = hasPermission('project.delete', { target: project });

  const createRequest = useCallback(
    values => dispatch(ProjectAction.createProject({ payload: values })),
    [dispatch]
  );

  const fetchRequest = useCallback(
    () => dispatch(ProjectAction.getProject({ id: projectId })),
    [projectId, dispatch]
  );

  const updateRequest = useCallback(
    (id, values) =>
      dispatch(ProjectAction.updateProject({ id, payload: values })),
    [dispatch]
  );

  const deleteRequest = useCallback(
    id => dispatch(ProjectAction.deleteProject({ id })),
    [dispatch]
  );

  const handleShowInvitationModal = useCallback(
    () => setInvitationModalOpen(true),
    []
  );

  const handleMapClick = useMemo(() => {
    if (hasDeviationFeature) {
      return () =>
        history.push({
          pathname: url,
          search: '?view=deviation'
        });
    } else {
      return null;
    }
  }, [hasDeviationFeature, history, url]);

  const HeaderContent = useMemo(
    () => (form, fetchStatus) =>
      (
        <Header
          form={form}
          fetchStatus={fetchStatus}
          readOnly={!canEdit}
          projectId={projectId}
        />
      ),
    [canEdit, projectId]
  );

  const _descriptionUpdatedAt = project?.descriptionUpdatedAt;
  const descriptionUpdatedAt = useMemo(
    () => parseDate(_descriptionUpdatedAt),
    [_descriptionUpdatedAt]
  );

  return (
    <Fragment>
      <EntityModal
        size="large"
        isOpen
        onRequestClose={onRequestClose}
        title={isNew ? 'Nouveau projet' : 'Édition de du projet'}
        subtitle={project?.displayName}
        id={projectId}
        model="project"
        entity={project}
        entityAsValue={projectAsValues}
        fetchRequest={fetchRequest}
        createRequest={createRequest}
        updateRequest={updateRequest}
        deleteRequest={canDelete ? deleteRequest : undefined}
        autoSubmit={false}
        headerRightContent={isDeviationVisible ? null : HeaderContent}
        valuesDependencies={valuesDependencies}
        negativeText={isDeviationVisible ? 'Retour' : 'Annuler'}
        readOnly={!canEdit}
        validationSchema={validationSchema}
        grow
        expanded={isDeviationVisible}
        {...props}
        onSubmitSuccess={(result, helpers, form) => {
          helpers.setValues(projectAsValues(jsonApiToObject(result)));
          if (props.onSubmitSuccess) {
            props.onSubmitSuccess(result, helpers, form);
          }
        }}
      >
        {({ values, setFieldValue }) =>
          isDeviationVisible ? (
            <Deviation
              project={values}
              readOnly={!canEdit}
              setFieldValue={setFieldValue}
            />
          ) : (
            <Grid spacing={0} padded={false}>
              <GridItem width="50%" minWidth={550}>
                <Grid spacing={16} padded={false}>
                  <GridItem width="100%">
                    <SectionTitle>Informations générales</SectionTitle>
                  </GridItem>
                  <GridItem width="50%">
                    <TextInputField
                      textArea
                      name="displayName"
                      label="Nom du projet"
                      readOnly={!canEdit}
                    />
                  </GridItem>
                  <GridItem width="50%">
                    <ProjectKindField
                      name="projectKind"
                      required
                      useDefault
                      isClearable={false}
                      readOnly={!canEdit}
                    />
                  </GridItem>
                  <GridItem width="50%">
                    <TextInputField
                      name="reference"
                      label="Référence"
                      readOnly={!canEdit}
                    />
                  </GridItem>
                  <GridItem width="50%">
                    <GroupField name="group" readOnly={!canEdit} />
                  </GridItem>
                  <Permission feature={features.CRM}>
                    <GridItem width="50%">
                      <OrganizationField
                        name="organization"
                        label="Organisation"
                        readOnly={!canEdit}
                      />
                    </GridItem>
                    <GridItem width="50%">
                      <ContactField
                        name="contact"
                        label="Contact"
                        readOnly={!canEdit}
                        organizationId={values.organization?.id}
                      />
                    </GridItem>
                  </Permission>
                  <Permission
                    permission={permissions.COMPANY_DATA}
                    feature={features.CLIENT_SHARING}
                  >
                    <GridItem minWidth="50%">
                      <UserField
                        name="clients"
                        label="Clients"
                        role={['user', 'client']}
                        multi
                        avatarHidden
                        readOnly={!canEdit}
                        hint={
                          canEdit
                            ? `En invitant des clients vous leur donnez la possibilité de voir ce projet. Vous pourrez également leur envoyer un email d'invitation${
                                Boolean(projectId)
                                  ? '.'
                                  : ' une fois le projet créé.'
                              }`
                            : null
                        }
                        trailingTooltip="Envoyer un email d'invitation au projet"
                        onTrailingIconClick={handleShowInvitationModal}
                        iconTrailing={
                          canEdit &&
                          Boolean(projectId) &&
                          values.clients?.length > 0
                            ? MdSend
                            : null
                        }
                      />
                    </GridItem>
                  </Permission>
                  <Permission permission={permissions.COMPANY_DATA}>
                    <GridItem width="100%">
                      <TextInputField
                        textArea
                        name="description"
                        label="Description / Informations supplémentaires"
                        minRows={4}
                        readOnly={!canEdit}
                        hint={
                          descriptionUpdatedAt
                            ? `dernière mise à jour: ${formatDistanceToNow(
                                descriptionUpdatedAt,
                                { addSuffix: true, locale: fr }
                              )} (${printDate(
                                descriptionUpdatedAt,
                                "dd/MM/yyyy 'à' HH:mm"
                              )})`
                            : ''
                        }
                      />
                    </GridItem>
                  </Permission>
                  <CustomFields
                    name="customData"
                    fieldableType="Project"
                    readOnly={!canEdit}
                  />
                  <AdministrativeActivitiesField
                    name="projectActivities"
                    projectKindId={values.projectKind?.id}
                    readOnly={!canEdit}
                  />
                </Grid>
              </GridItem>
              <GridItem width="50%" minWidth={550}>
                <Grid spacing={16} padded={false}>
                  <GridItem width="100%">
                    <SectionTitle>
                      {translate({
                        value: 'projects.address_title',
                        fallback: 'Lieu du projet'
                      })}
                    </SectionTitle>
                  </GridItem>
                  <GridItem width="100%">
                    <AddressField
                      name="address"
                      mapHeight={180}
                      onMapClick={handleMapClick}
                      project={project}
                      readOnly={!canEdit}
                    />
                  </GridItem>
                </Grid>
              </GridItem>
              {projectId && (
                <Permission feature={[features.ATTACHMENTS]}>
                  <GridItem width="100%">
                    <Grid spacing={16} padded={false}>
                      <GridItem width="100%">
                        <AttachmentBox
                          attachments={values.attachments}
                          flat
                          maxHeight={180}
                          minHeight={100}
                          parentId={projectId}
                          parentModel="Project"
                          readOnly={!canEdit}
                        />
                      </GridItem>
                    </Grid>
                  </GridItem>
                </Permission>
              )}
            </Grid>
          )
        }
      </EntityModal>
      {invitationModalOpen && (
        <InvitationModal
          projectId={projectId}
          onRequestClose={() => setInvitationModalOpen(false)}
        />
      )}
    </Fragment>
  );
};

export default ProjectModal;

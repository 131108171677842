import { get, put, post, destroy } from 'common/api';

export const getPhases = params => get('/v5/phases', params);

export const createPhase = (phase, config) =>
  post(`/v5/phases`, { phase }, config);

export const getPhase = (id, config) => get(`/v5/phases/${id}`, config);

export const putPhase = (id, phase, config) =>
  put(`/v5/phases/${id}`, { phase }, config);

export const deletePhase = (id, config) => destroy(`/v5/phases/${id}`, config);

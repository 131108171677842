import React, { useMemo, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { MdArrowDropDown } from 'react-icons/md';
import Tag from 'components/v3/Tag';
import { IssueSelector, issueStateAsOption } from 'features/Issues';
import { useAction, useDelay } from 'components/v3/utils';
import { IssueAction } from 'features/Issues';
import {
  SelectInputLayout,
  InputLayoutVariant
} from 'components/v3/InputLayout';

const State = ({
  issueId,
  value,
  onChange,
  size,
  asTag = true,
  readOnly,
  ...props
}) => {
  const delay = useDelay();
  const action = useAction();
  const dispatch = useDispatch();
  const issueStates = useSelector(IssueSelector.selectIssueStates, isEqual);

  const options = useMemo(() => issueStates.map(issueStateAsOption), [
    issueStates
  ]);

  const currentValue = useMemo(() => issueStateAsOption(value), [value]);

  const currentOptionRenderer = useCallback(
    ({ option }) => (
      <Tag
        displayName={option.label}
        color={option.color}
        size={size}
        wide
        trailingIcon={readOnly ? null : MdArrowDropDown}
        clickable={!readOnly}
      />
    ),
    [readOnly, size]
  );

  const handleChange = useCallback(
    state => {
      if (readOnly) {
        return;
      }
      if (onChange) {
        onChange(state);
      } else if (issueId) {
        action(() =>
          dispatch(
            IssueAction.updateIssueState({
              id: issueId,
              payload: { state: state?.value, omitAttachments: true }
            })
          )
        );
      }
    },
    [readOnly, action, dispatch, issueId, onChange]
  );

  useEffect(() => {
    if (readOnly) {
      return;
    }
    if (asTag && !value && onChange) {
      if (options.length === 0) {
        return;
      }
      const state = options.find(option => option?.original?.default)?.original;
      delay(() => onChange(state || options[0]?.original), 32);
    }
  }, [readOnly, delay, asTag, value, onChange, options]);

  return (
    <SelectInputLayout
      label={asTag ? null : 'État'}
      isSearchable={!asTag}
      variant={asTag ? InputLayoutVariant.flat.medium : null}
      isClearable={false}
      showIndicator={!asTag}
      sortOptions={false}
      options={options}
      value={currentValue}
      onChange={handleChange}
      currentOptionRenderer={asTag ? currentOptionRenderer : null}
      readOnly={readOnly}
      {...props}
    />
  );
};

export default State;

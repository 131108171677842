import { omit } from 'lodash';
import { mergeJsonApi } from 'common/reducers';

const initialState = {
  extractionDestinationById: {},
  extractionDestinationsIds: []
};

export const extractionDestinations = (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case 'EXTRACTION_DESTINATIONS_LOADING':
      return {
        ...state,
        isLoading: true
      };
    case 'EXTRACTION_DESTINATIONS_ERROR':
      return {
        ...state,
        isLoading: false
      };
    case 'NEW_EXTRACTION_DESTINATION_SUCCESS':
      return {
        ...mergeJsonApi('extractionDestination', state, action),
        extractionDestinationsIds: [
          ...state.extractionDestinationsIds,
          payload.data.id
        ]
      };
    case 'DELETE_EXTRACTION_DESTINATION_SUCCESS':
      return {
        ...state,
        extractionDestinationById: omit(
          state.extractionDestinationById,
          payload
        ),
        extractionDestinationsIds: state.extractionDestinationsIds.filter(
          id => parseInt(id) !== payload
        )
      };
    case 'LOGOUT':
    case 'SELECT_ACCOUNT':
      return initialState;
    default:
      return mergeJsonApi('extractionDestination', state, action);
  }
};

import React from 'react';
import styled, { css } from 'styled-components';
import { MdCheck, MdClose } from 'react-icons/md';
import { permissions, useAccountConfig } from 'config';
import { Icon, Text, Button } from 'components/v3/ui';
import Author from 'features/Users/components/v3/Author';
import { printDate } from 'utils/dates';
import { VoucherAction } from '../data';
import { useRedux } from 'components/v3/utils';
import NegativeCheckButton from './NegativeCheckButton';
import Tooltip from 'components/v3/Tooltip';

const PositiveButton = styled(Button)`
  background-color: ${({ theme }) => theme.success};
  color: ${({ theme }) => theme.textLight};
  border-color: ${({ theme }) => theme.backgroundLight};

  &:hover:not(:active):not(:disabled) {
    background-color: ${({ theme }) => theme.successLight};
  }
  &:active {
    background-color: ${({ theme }) => theme.successDark};
  }
`;
const ValidationButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  button {
    margin: 4px;
  }
`;
const CheckedWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  > * {
    margin: 4px;
  }
  ${({ withComment }) =>
    withComment &&
    css`
      cursor: help;
    `}
`;

const CheckIcon = styled(Icon)`
  border-width: 2px;
  border-radius: 50%;
  border: 1px solid;

  ${({ checkResult, theme }) =>
    checkResult === 'accepted' &&
    css`
      border-color: ${theme.success};
      svg {
        fill: ${theme.success};
      }
    `}
  ${({ checkResult, theme }) =>
    checkResult === 'refused' &&
    css`
      border-color: ${theme.error};
      svg {
        fill: ${theme.error};
      }
    `}
`;

const VoucherCheck = ({ voucherVersion, editable }) => {
  const { hasPermission } = useAccountConfig();
  const [, updateVoucherVersion, { isLoading }] = useRedux(
    null,
    VoucherAction.updateVoucherVersion,
    {
      fetchOnMount: false,
      useLocalState: true
    }
  );
  if (voucherVersion.checkResult !== 'pending') {
    return (
      <Tooltip content={voucherVersion.comment}>
        <CheckedWrapper withComment={Boolean(voucherVersion.comment)}>
          <CheckIcon size="small" checkResult={voucherVersion.checkResult}>
            {voucherVersion.checkResult === 'accepted' ? (
              <MdCheck />
            ) : (
              <MdClose />
            )}
          </CheckIcon>
          <Author
            user={voucherVersion?.checkedBy}
            size="small"
            avatarTooltipHidden
            subtitle={printDate(voucherVersion.checkedAt)}
            variant="small"
          />
        </CheckedWrapper>
      </Tooltip>
    );
  } else if (editable && hasPermission(permissions.CHECK_DOCUMENTS)) {
    return (
      <ValidationButtons>
        <PositiveButton
          variant="light"
          small
          isLoading={isLoading}
          disabled={isLoading}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            updateVoucherVersion(() => ({
              id: voucherVersion.id,
              payload: {
                checkResult: 'accepted'
              }
            }));
          }}
        >
          Valider
        </PositiveButton>
        <NegativeCheckButton
          isLoading={isLoading}
          voucherVersion={voucherVersion}
          updateVoucherVersion={updateVoucherVersion}
        />
      </ValidationButtons>
    );
  } else {
    return (
      <Text variant="small" style={{ textTransform: 'italic' }}>
        En attente
      </Text>
    );
  }
};

export default VoucherCheck;

const themePalette = {
  primary: '#3943ae',
  primaryDark: '#001c7e',
  primaryLight: '#326ffe',
  primaryDisabled: '#9097b1',

  accent: '#f25d68',
  accentDark: '#ba273e',
  accentLight: '#ff8f96',

  hint: '#8C91D0'
};

export const theme = {
  ...themePalette,

  sidebarSubmenuBackgroundColor: themePalette.primaryDark,
  sidebarMenuActiveLinkColor: '#fff',
  sidebarMenuActiveLinkBackgroundColor: themePalette.primary,
  sidebarMenuActiveLinkInnerBackgroundColor: 'transparent',
  sidebarMenuActiveLinkFontWeight: '200',

  // REMOVE textDark, textDarkSecondary, textDarkHint, textDarkDisabled
  textDark: 'rgba(0, 0, 0, 0.87)',
  textDarkSecondary: 'rgba(0, 0, 0, 0.52)',
  textDarkHint: 'rgba(0, 0, 0, 0.38)',
  textDarkDisabled: 'rgba(0, 0, 0, 0.38)',

  text: 'rgba(0, 0, 0, 0.87)',
  textSecondary: 'rgba(0, 0, 0, 0.52)',
  textHint: 'rgba(0, 0, 0, 0.38)',
  textDisabled: 'rgba(0, 0, 0, 0.38)',

  textLight: 'rgba(255, 255, 255, 1)',
  textLightSecondary: 'rgba(255, 255, 255, 0.70)',
  textLightHint: 'rgba(255, 255, 255, 0.50)',
  textLightDisabled: 'rgba(255, 255, 255, 0.70)',

  textTheme: themePalette.primary,
  textThemeLight: themePalette.primaryLight,
  textCaption: themePalette.hint,

  separator: 'rgba(0, 0, 0, 0.12)',
  separatorLight: 'rgba(0, 0, 0, 0.08)',
  separatorDark: 'rgba(0, 0, 0, 0.3)',

  background: '#EFF1F6',
  backgroundLight: '#fefeff',
  backgroundSection: '#f6f6f6',
  backgroundDisabled: 'rgba(0, 0, 0, 0.12)',

  successLight: '#6ae1b9',
  success: '#26bf8c',
  successDark: '#156a4e',
  warning: '#e66322',
  error: '#b00020',
  errorDark: '#891E00',

  iconLight: '#fff',

  borderButtonRadius: '12px',
  borderBoxRadius: '20px',
  borderInputRadius: '8px',
  borderRadius: '4px',
  boxShadowIdle: '0 1px 4px rgba(0, 0, 0, 0.15)',
  boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.1)',
  boxShadowSmall: '0 1px 10px rgba(0, 0, 0, 0.08)',
  boxShadowFocused: '0 10px 40px rgba(0, 0, 0, 0.2)',

  sidebarLinkColor: '#fff',
  shadow: '0 2px 7px 0 rgba(0,0,0,.12)',

  borderInput: '1px solid rgba(0, 0, 0, 0.12)',
  borderInputColor: 'rgba(0, 0, 0, 0.12)',

  disabled: '#ECECEC',
  hoverColor: '#F5F5F7',
  hoverColorDark: '#F0F0F2',
  hoverColorBlack: 'rgba(0, 0, 0, 0.12)',
  hoverColorBlacker: 'rgba(0, 0, 0, 0.22)'
};

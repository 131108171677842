import React from 'react';
import styled from 'styled-components';
import Tag, { TagContainer } from 'components/v3/Tag';
import Tooltip from 'components/v3/Tooltip';
import Card, { CardHeader, CardContent } from 'components/v3/ui/card';
import { Text, Icon } from 'components/v3/ui';
import { printDate } from 'utils/dates';
import Author from 'features/Users/components/v3/Author';
import Emergency from 'components/Icons/Emergency';
import State from './State';

const Description = styled(Text)`
  margin-bottom: 12px;
`;

const IssueCard = ({ issue, readOnly, ...props }) => {
  return (
    <Card {...props}>
      <CardHeader variant="tight">
        <TagContainer alignEnd>
          {issue.emergency && (
            <Tooltip content="Ce signalement est urgent">
              <Icon>
                <Emergency />
              </Icon>
            </Tooltip>
          )}
          <span>
            <Tag
              size="small"
              displayName={issue.group?.displayName}
              color={issue.group?.color}
            />
          </span>
          <State
            issueId={issue.id}
            value={issue.state}
            asTag
            readOnly={readOnly}
          />
        </TagContainer>
      </CardHeader>
      <CardContent>
        <Description maxLines={4}>{issue.description}</Description>
        <Author
          user={issue.user || issue.author}
          subtitle={`Créé le ${printDate(issue.createdAt)}`}
        />
      </CardContent>
    </Card>
  );
};

export default IssueCard;

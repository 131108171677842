import React, { useState, useCallback } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { MdAdd } from 'react-icons/md';
import SubHeader from 'components/v3/SubHeader';
import { FilterBar } from 'components/v3/Filters';
import { Container, Title, Button } from 'components/v3/ui';
import { SurfacingSelector, SurfacingAction, ProductList } from 'features/Surfacing';

const Products = () => {
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  const [count, setCount] = useState();
  const [filters, setFilters] = useState();

  const handleDelete = useCallback((item) => dispatch(SurfacingAction.deleteProduct({ id: item.id })), [dispatch]);

  return (
    <Container vertical>
      <SubHeader>
        <Title>{`Produits${count != null ? ` (${count})` : ''}`}</Title>
        <Button small to={`${path}/nouveau-produit`} icon={MdAdd}>
          Nouveau produit
        </Button>
      </SubHeader>
      <Container vertical huge padded>
        <FilterBar big searchQuery="displayNameContAny" onChanges={setFilters} />
        <ProductList
          spacing={8}
          headerVariant="transparent"
          selector={SurfacingSelector.selectProducts}
          action={SurfacingAction.getProducts}
          onCountChanged={setCount}
          filters={filters}
          perPage={50}
          detailUrlPrefix=""
          isDeletable
          deleteRequest={handleDelete}
        />
      </Container>
    </Container>
  );
};

export default Products;

import { createDeepEqualSelector, selectId, getProps } from 'common/selector';
import { getState, selectRelations } from 'common/selector';
import isSameIds from 'utils/idUtils';

export const getContacts = state => state.contacts;

export const selectContacts = createDeepEqualSelector(
  [getContacts, getState],
  ({ contactsIds, contactById }, state) =>
    contactsIds
      .map(id => selectRelations('contact', contactById[id], state))
      .filter(Boolean)
);

export const selectContact = createDeepEqualSelector(
  [getContacts, selectId('contact'), getState],
  ({ contactById }, contactId, state) =>
    selectRelations('contact', contactById[contactId], state)
);

export const selectSearchContacts = createDeepEqualSelector(
  [getContacts, getProps, getState],
  ({ contactById }, { organizationId }, state) =>
    Object.values(contactById)
      .filter(contact => {
        if (!contact) {
          return false;
        }
        if (!organizationId) {
          return true;
        }
        return isSameIds(
          organizationId,
          contact.relationships?.organization?.data?.id
        );
      })
      .map(contact => selectRelations('contact', contact, state))
      .filter(Boolean)
      .slice(0, 50)
);

import { createAction } from 'redux-actions';
import omit from 'lodash/omit';
import * as Api from './api';
import { prepareAttributes, shouldFetch } from 'common/actions';

const prepareInvoicePayload = payload => ({
  ...prepareAttributes(omit(payload, 'invoice'), 'invoiceLines'),
  discount: -parseFloat(payload.discount || 0),
  secondDiscount: -parseFloat(payload.secondDiscount || 0),
  invoiceId: payload.invoice?.id || payload.invoiceId
});

const receiveInvoices = createAction('INVOICES_SUCCESS');
const receiveInvoicesTotalAmount = createAction('INVOICES_AMOUNTS');
export const getInvoices = payload => async dispatch => {
  dispatch(receiveInvoicesTotalAmount(''));
  const result = await Api.getInvoices(payload);
  dispatch(receiveInvoices(result));
  dispatch(receiveInvoicesTotalAmount(result.meta?.amount));
  return result;
};

const receiveCreateInvoice = createAction('NEW_INVOICE_SUCCESS');
export const createInvoice =
  ({ payload, ...config }) =>
  async dispatch => {
    const result = await Api.createInvoice(
      prepareInvoicePayload(payload),
      config
    );
    dispatch(receiveCreateInvoice(result));
    return result;
  };

const receiveInvoice = createAction('INVOICE_SUCCESS');
export const getInvoice =
  ({ id, ...payload }) =>
  async dispatch => {
    const result = await Api.getInvoice(id, payload);
    dispatch(receiveInvoice(result));
    return result;
  };

const receiveUpdateInvoice = createAction('UPDATE_INVOICE_SUCCESS');
export const updateInvoice =
  ({ id, payload, ...config }) =>
  async dispatch => {
    const result = await Api.putInvoice(
      id,
      prepareInvoicePayload(payload),
      config
    );
    dispatch(receiveUpdateInvoice(result));
    return result;
  };

export const changeInvoiceState =
  ({ id, payload, ...config }) =>
  async dispatch => {
    const result = await Api.putInvoice(id, payload, config);
    dispatch(receiveUpdateInvoice(result));
    return result;
  };

const receiveDeleteInvoice = createAction('DELETE_INVOICE_SUCCESS');
export const deleteInvoice =
  ({ id, ...config }) =>
  async dispatch => {
    const response = await Api.deleteInvoice(id, config);
    dispatch(receiveDeleteInvoice(id));
    return response;
  };

export const sendInvoiceByEmail =
  ({ payload: { invoiceId, ...content }, ...config }) =>
  async () => {
    const result = await Api.sendInvoiceByEmail(invoiceId, content, config);
    return result;
  };

const prepareServicePayload = payload =>
  prepareAttributes(
    {
      ...payload,
      projectKindId: payload.projectKind?.id,
      unitId: payload.unit?.id,
      taxRate: payload.taxRate || 0,
      price: payload.price || 0,
      reference: payload.reference || null,
      groupServices: payload.groupServices
        ?.filter(a => Boolean(a.group) || a._destroy)
        .map(gs => ({
          ...gs,
          groupId: gs.group?.id
        }))
    },
    'groupServices'
  );

const servicesLoading = createAction('SERVICES_LOADING');
const servicesSuccess = createAction('SERVICES_SUCCESS');
const searchServicesSuccess = createAction('SEARCH_SERVICES_SUCCESS');
const servicesError = createAction('SERVICES_ERROR');
export const getServices = payload => async (dispatch, getState) => {
  const { useCache, isSearchRequest, ...params } = payload || {};
  const state = getState();
  const action = isSearchRequest
    ? 'SEARCH_SERVICES_SUCCESS'
    : 'SERVICES_SUCCESS';
  if (!useCache || shouldFetch('service', params, state, action)) {
    return fetchServices(params, isSearchRequest, dispatch);
  } else {
    return {
      fromCache: true,
      isLoading: getState().services.isLoading
    };
  }
};
const fetchServices = async (payload, isSearchRequest, dispatch) => {
  if (!isSearchRequest) {
    dispatch(servicesLoading());
  }
  try {
    const result = await Api.getServices(payload);
    if (isSearchRequest) {
      dispatch(
        searchServicesSuccess({
          ...result,
          requestParams: omit(payload, 'cancelToken')
        })
      );
    } else {
      dispatch(
        servicesSuccess({
          ...result,
          requestParams: omit(payload, 'cancelToken')
        })
      );
    }
    return result;
  } catch (error) {
    dispatch(servicesError(error));
    throw error;
  }
};

const receiveCreateService = createAction('NEW_SERVICE_SUCCESS');
export const createService =
  ({ payload, ...config }) =>
  async dispatch => {
    const result = await Api.createService(
      prepareServicePayload(payload),
      config
    );
    dispatch(receiveCreateService(result));
    return result;
  };

const receiveService = createAction('SERVICE_SUCCESS');
export const getService =
  ({ id, ...payload }) =>
  async dispatch => {
    const result = await Api.getService(id, payload);
    dispatch(receiveService(result));
    return result;
  };

const receiveUpdateService = createAction('UPDATE_SERVICE_SUCCESS');
export const updateService =
  ({ id, payload: service, ...config }) =>
  async dispatch => {
    const result = await Api.putService(
      id,
      prepareServicePayload(service),
      config
    );
    dispatch(receiveUpdateService(result));
    return result;
  };

const receiveDeleteService = createAction('DELETE_SERVICE_SUCCESS');
export const deleteService =
  ({ id, ...config }) =>
  async dispatch => {
    const response = await Api.deleteService(id, config);
    dispatch(receiveDeleteService(id));
    return response;
  };

const unitsLoading = createAction('UNITS_LOADING');
const unitsSuccess = createAction('UNITS_SUCCESS');
const unitsError = createAction('UNITS_ERROR');
export const getUnits = payload => async (dispatch, getState) => {
  const { useCache, ...params } = payload || {};
  const state = getState();
  if (!useCache || shouldFetch('unit', params, state)) {
    return fetchUnits(params, dispatch);
  } else {
    return {
      fromCache: true,
      isLoading: getState().units.isLoading
    };
  }
};
const fetchUnits = async (payload, dispatch) => {
  dispatch(unitsLoading());
  try {
    const result = await Api.getUnits(payload);
    dispatch(
      unitsSuccess({ ...result, requestParams: omit(payload, 'cancelToken') })
    );
    return result;
  } catch (error) {
    dispatch(unitsError(error));
    throw error;
  }
};

const receiveCreateUnit = createAction('NEW_UNIT_SUCCESS');
export const createUnit =
  ({ payload, ...config }) =>
  async dispatch => {
    const result = await Api.createUnit(payload, config);
    dispatch(receiveCreateUnit(result));
    return result;
  };

const receiveUnit = createAction('UNIT_SUCCESS');
export const getUnit =
  ({ id, ...payload }) =>
  async dispatch => {
    const result = await Api.getUnit(id, payload);
    dispatch(receiveUnit(result));
    return result;
  };

const receiveUpdateUnit = createAction('UPDATE_UNIT_SUCCESS');
export const updateUnit =
  ({ id, payload: unit, ...config }) =>
  async dispatch => {
    const result = await Api.putUnit(id, unit, config);
    dispatch(receiveUpdateUnit(result));
    return result;
  };

const receiveDeleteUnit = createAction('DELETE_UNIT_SUCCESS');
export const deleteUnit =
  ({ id, ...config }) =>
  async dispatch => {
    const response = await Api.deleteUnit(id, config);
    dispatch(receiveDeleteUnit(id));
    return response;
  };

const prepareSubcontractingPayload = payload => {
  return {
    ...payload,
    organizationId: payload?.organization?.id
  };
};

const receiveCreateSubcontracting = createAction('NEW_SUBCONTRACTING_SUCCESS');
export const createSubcontracting =
  ({ payload, ...config }) =>
  async dispatch => {
    const result = await Api.createSubcontracting(
      prepareSubcontractingPayload(payload),
      config
    );
    dispatch(receiveCreateSubcontracting(result));
    return result;
  };

const receiveSubcontracting = createAction('SUBCONTRACTING_SUCCESS');
export const getSubcontracting =
  ({ id, ...payload }) =>
  async dispatch => {
    const result = await Api.getSubcontracting(id, payload);
    dispatch(receiveSubcontracting(result));
    return result;
  };

const receiveUpdateSubcontracting = createAction(
  'UPDATE_SUBCONTRACTING_SUCCESS'
);
export const updateSubcontracting =
  ({ id, payload, ...config }) =>
  async dispatch => {
    const result = await Api.putSubcontracting(
      id,
      prepareSubcontractingPayload(payload),
      config
    );
    dispatch(receiveUpdateSubcontracting(result));
    return result;
  };

const receiveDeleteSubcontracting = createAction(
  'DELETE_SUBCONTRACTING_SUCCESS'
);
export const deleteSubcontracting =
  ({ id, ...config }) =>
  async dispatch => {
    const response = await Api.deleteSubcontracting(id, config);
    dispatch(receiveDeleteSubcontracting(id));
    return response;
  };

import { createAction } from 'redux-actions';
import omit from 'lodash/omit';
import * as Api from './extraction_destination_api';
import { shouldFetch } from 'common/actions';

const prepareExtractionDestinationPayload = payload => payload;

const extractionDestinationsLoading = createAction(
  'EXTRACTION_DESTINATIONS_LOADING'
);
const extractionDestinationsSuccess = createAction(
  'EXTRACTION_DESTINATIONS_SUCCESS'
);
const extractionDestinationsError = createAction(
  'EXTRACTION_DESTINATIONS_ERROR'
);
export const getExtractionDestinations =
  payload => async (dispatch, getState) => {
    const { useCache = true, ...params } = payload || {};
    const state = getState();
    if (!useCache || shouldFetch('extractionDestination', params, state)) {
      return fetchExtractionDestinations(params, dispatch);
    } else {
      return {
        fromCache: true,
        isLoading: getState().extractionDestinations.isLoading
      };
    }
  };
const fetchExtractionDestinations = async (payload, dispatch) => {
  dispatch(extractionDestinationsLoading());
  try {
    const result = await Api.getExtractionDestinations(payload);
    dispatch(
      extractionDestinationsSuccess({
        ...result,
        requestParams: omit(payload, 'cancelToken')
      })
    );
    return result;
  } catch (error) {
    dispatch(extractionDestinationsError(error));
    throw error;
  }
};

const receiveCreateExtractionDestination = createAction(
  'NEW_EXTRACTION_DESTINATION_SUCCESS'
);
export const createExtractionDestination =
  ({ payload, ...config }) =>
  async dispatch => {
    const result = await Api.createExtractionDestination(
      prepareExtractionDestinationPayload(payload),
      config
    );
    dispatch(receiveCreateExtractionDestination(result));
    return result;
  };

const receiveUpdateExtractionDestination = createAction(
  'UPDATE_EXTRACTION_DESTINATION_SUCCESS'
);
export const updateExtractionDestination =
  ({ id, payload: extractionDestination, ...config }) =>
  async dispatch => {
    const result = await Api.putExtractionDestination(
      id,
      prepareExtractionDestinationPayload(extractionDestination),
      config
    );
    dispatch(receiveUpdateExtractionDestination(result));
    return result;
  };

const receiveDeleteExtractionDestination = createAction(
  'DELETE_EXTRACTION_DESTINATION_SUCCESS'
);
export const deleteExtractionDestination =
  ({ id, ...config }) =>
  async dispatch => {
    const response = await Api.deleteExtractionDestination(id, config);
    dispatch(receiveDeleteExtractionDestination(id));
    return response;
  };

import React, { useMemo, useState, useRef, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { sortableHandle } from 'react-sortable-hoc';
import { NavLink as Link } from 'react-router-dom';
import get from 'lodash/get';
import { FaGripHorizontal } from 'react-icons/fa';
import { MdMoreVert, MdUnarchive } from 'react-icons/md';
import Spinner from 'react-md-spinner';
import { IcSortDesc, IcSort, IcSortAsc } from 'common/images/icons';
import Column from './Column';
import { Row } from './row';
import List from './List';
import Menu, { MenuItem } from '../Menu';
import Dialog from '../Dialog';
import { toaster } from '../Toast';
import { Button, ButtonIcon, Dot, Container as RawContainer } from '../ui';
import { spacing, fontSize, theme } from 'ui';
import { ProgressBar } from '../ui';
import Tooltip from '../Tooltip';
import { entries, groupBy, keys } from 'lodash';

const getId = item => item.id || item.value;

const Container = styled(RawContainer)`
  height: 100%;
  padding: 0px;
`;

export const CellsWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  min-height: 44px;
  width: 100%;

  ${({ wrap }) =>
    wrap === 'true' &&
    css`
      flex-wrap: wrap;
    `}
`;

export const HeaderSections = styled.div`
  display: inline-flex;
  align-items: center;
  width: 100%;
  // min-width: 100%;
`;

export const HeaderSection = styled.div`
  color: ${({ theme }) => theme.textHint};
  text-transform: uppercase;
  font-size: ${fontSize(11)};
  padding-top: ${spacing(0.5)} !important;
  padding-bottom: ${spacing(0.5)} !important;
  padding-left: ${({ tight }) => (tight ? '2px' : spacing(0.5))};
  padding-right: ${({ tight }) => (tight ? '2px' : spacing(0.5))};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 600;
  color: ${({ theme }) => theme.textHint};
  height: 100%;

  text-align: ${({ textAlign }) => textAlign || 'start'};

  ${({ variant, theme, noBorder }) =>
    variant === 'primary' &&
    !noBorder &&
    css`
      color: ${theme.textLight};
      &:not(:last-child) {
        border-right: 2px solid ${theme.backgroundLight};
      }
    `}

  ${({ sortable }) =>
    sortable &&
    css`
      display: flex;
      justify-content: ${({ textAlign }) => {
        switch (textAlign) {
          case 'end':
            return 'flex-end';
          default:
            return textAlign || 'start';
        }
      }};
      cursor: pointer;
      > svg {
        min-width: 14px;
        opacity: 0.5;
        margin-left: 6px;
      }
      &:hover {
        > svg {
          opacity: 1;
        }
      }
    `}
`;

const HeaderTooltipSection = styled.div`
  cursor: help;
`;

export const HeaderWrapper = styled.div`
  position: relative;
  display: flex;
  width: calc(100% - 2px);
  min-width: calc(100% - 2px);
  z-index: 1;
  white-space: nowrap;
  overflow: hidden;
  min-height: 32px;

  ${({ topReached }) =>
    !topReached &&
    css`
      box-shadow: rgba(0, 0, 0, 0.08) 0px 6px 5px -3px !important;
    `}

  ${({ spacing, variant }) =>
    !spacing &&
    variant !== 'primary' &&
    css`
      border-bottom: 1px solid ${({ theme }) => theme.separator};
    `}

  ${({ variant, theme }) =>
    variant === 'light' &&
    css`
      background-color: ${theme.backgroundLight};
    `}

  ${({ variant, theme }) =>
    variant === 'primary' &&
    css`
      background-color: ${theme.primaryLight};
    `}

  ${({ variant, theme }) =>
    variant === 'dark' &&
    css`
      background-color: ${theme.backgroundSection};
    `}

  ${({ variant, theme }) =>
    variant === 'background' &&
    css`
      background-color: ${theme.background};
    `}


  ${({ isArchivable, isDeletable }) =>
    css`
      // padding-right: ${(isDeletable || isArchivable ? 32 : 0) +
      (isDeletable || isArchivable ? 16 : 0)}px;
    `}

  ${({ isSortable }) =>
    css`
      // padding-left: ${isSortable ? 32 : 0}px;
    `}
`;

export const RowSection = styled.div`
  padding: ${({ tight }) => (tight ? '2px 2px' : `4px ${spacing(0.5)}`)};
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;

  ${({ textAlign }) => css`
    text-align: ${textAlign || 'start'};
    justify-content: ${textAlign || 'start'};
  `}
`;

export const HeaderProgressBar = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
`.withComponent(ProgressBar);

export const RowActionWrapper = styled.div`
  height: 32px;
  width: 32px;
  min-width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ more }) =>
    more &&
    css`
      width: 48px;
      min-width: 48px;
    `}
`;

export const Draggable = styled(RowActionWrapper)`
  padding: 8px;
  border: none;
  cursor: grab;
  display: flex;
  align-items: center;
  color: #cccccc;

  &:hover {
    color: #222222;
  }
`;

const UnarchiveButton = styled(Button)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
`;

const Handle = sortableHandle(({ tabIndex }) => (
  <Draggable tabIndex={tabIndex}>
    <FaGripHorizontal size={16} />
  </Draggable>
));

export const RowColumnRenderer = React.memo(
  ({ columns, item, onChange, readOnly, index }) =>
    columns.map(column => (
      <RowSection
        className={column.className}
        center={column.center}
        key={`item-${getId(item)}-${column.accessor}`}
        columnKind={column.kind}
        textAlign={column.textAlign}
        tight={column.tight}
        style={{
          // minWidth: column.minWidth || undefined,
          // width: column.width || column.minWidth,
          // padding: column.padding,

          minWidth: column.minWidth,
          width: column.grow ? undefined : column.width,
          maxWidth: column.grow ? undefined : column.maxWidth || column.width,
          display: column.grow ? 'flex' : undefined,
          flexGrow: column.grow ? '1' : undefined,
          padding: column.padding

          // minWidth: column.minWidth,
          // width: column.width || column.minWidth,
          // maxWidth: column.grow ? undefined : column.maxWidth || column.width,
          // display: column.grow ? 'flex' : undefined,
          // flexGrow: column.grow ? '1' : undefined,
          // padding: column.padding
        }}
      >
        <Column
          column={column}
          item={item}
          onChange={onChange}
          readOnly={readOnly}
          index={index}
        />
      </RowSection>
    ))
);

const getSortIcon = (currentSort, column) => {
  if (currentSort?.accessor === column.accessor) {
    if (currentSort.desc) {
      return <IcSortDesc size={14} />;
    } else {
      return <IcSortAsc size={14} />;
    }
  } else {
    return <IcSort size={14} />;
  }
};

export const Header = React.memo(
  React.forwardRef(
    (
      {
        columns,
        isArchivable,
        isDeletable,
        isSortable,
        variant,
        topReached,
        spacing,
        isLoading,
        initialColumnSort,
        onColumnSortChanged
      },
      ref
    ) => {
      const onColumnSortChangedRef = useRef();
      onColumnSortChangedRef.current = onColumnSortChanged;
      const [currentSort, setCurrentSort] = useState(initialColumnSort);

      const handleSortClick = useCallback(
        (column, desc) => {
          if (!onColumnSortChanged) {
            return;
          }
          const newSort = {
            accessor: column.accessor,
            desc
          };
          setCurrentSort(newSort);
          onColumnSortChanged(newSort);
        },
        [onColumnSortChanged]
      );

      const columnsByGroup = useMemo(() => {
        return groupBy(columns, c => c.group);
      }, [columns]);
      const withGroup = keys(columnsByGroup).length > 1;

      return (
        <HeaderWrapper
          ref={ref}
          topReached={topReached}
          spacing={spacing}
          isSortable={isSortable}
          isArchivable={isArchivable}
          isDeletable={isDeletable}
          variant={variant}
        >
          <HeaderSections>
            {isSortable && <RowActionWrapper />}
            {entries(columnsByGroup).map((entry, index) => {
              const [group, columns] = entry;
              const columnsContent = columns.map(column => {
                const headerContent =
                  typeof column.Header === 'function'
                    ? column.Header()
                    : column.Header;

                const isSorted = currentSort?.accessor === column.accessor;
                const isSortDesc = isSorted && currentSort?.desc;
                return (
                  <HeaderSection
                    className={column.className}
                    key={`header-${column.accessor}-${index}`}
                    variant={variant}
                    bold
                    sortable={column.sortable}
                    onClick={() => handleSortClick(column, !isSortDesc)}
                    tight={column.tight}
                    textAlign={column.textAlign}
                    columnKind={column.kind}
                    noBorder={column.noBorder}
                    style={{
                      // minWidth: column.minWidth || column.width,
                      // width: column.width,
                      // maxWidth: column.maxWidth || column.width,

                      minWidth: column.minWidth,
                      width: column.grow ? undefined : column.width,
                      maxWidth: column.grow
                        ? undefined
                        : column.maxWidth || column.width,
                      display: column.grow ? 'flex' : undefined,
                      flexGrow: column.grow ? '1' : undefined,
                      padding: column.padding
                    }}
                  >
                    {column.tooltip ? (
                      <Tooltip content={column.tooltip}>
                        <HeaderTooltipSection>
                          {headerContent}
                          {column.sortable && getSortIcon(currentSort, column)}
                        </HeaderTooltipSection>
                      </Tooltip>
                    ) : (
                      headerContent
                    )}
                    {column.sortable && getSortIcon(currentSort, column)}
                  </HeaderSection>
                );
              });
              if (withGroup) {
                return (
                  <div key={group}>
                    <HeaderSection textAlign="center">
                      {group || ''}
                    </HeaderSection>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'nowrap'
                      }}
                    >
                      {columnsContent}
                    </div>
                  </div>
                );
              } else {
                return columnsContent;
              }
            })}
            {(isArchivable || isDeletable) && <RowActionWrapper more />}
          </HeaderSections>
          {isLoading && <HeaderProgressBar />}
        </HeaderWrapper>
      );
    }
  )
);

const TableRow = ({
  columns,
  item,
  rowRenderer,
  onChange,
  linkFormatter,
  isSortable,
  readOnly,
  isItemLoading,
  isDeletable,
  isArchivable,
  isArchived,
  onClick,
  onDeleteClick,
  onArchiveClick,
  spacing,
  wrap,
  rowColor,
  isFirst,
  isLast,
  menuOptions,
  index
}) => {
  let archivable =
    typeof isArchivable === 'function' ? isArchivable(item) : isArchivable;
  const archived = isArchived ? isArchived(item) : item.archived;
  archivable = archivable && !archived;

  const canDeleteRow =
    (item.canDelete !== undefined ? item.canDelete : true) && !archived;
  const deletable =
    (typeof isDeletable === 'function' ? isDeletable(item) : isDeletable) &&
    canDeleteRow;
  const loading = isItemLoading && isItemLoading(item);

  const to = linkFormatter ? linkFormatter(item) : undefined;

  return (
    <Row
      as={to ? Link : undefined}
      to={
        to?.external
          ? {
              pathname: to.url
            }
          : to
      }
      // to={to?.external ? undefined : to}
      target={to?.external ? '_blank' : undefined}
      data-archived={archived}
      // data-deletable={isDeletable ? 'true' : undefined}
      // data-archivable={archivable ? 'true' : undefined}
      data-spacing={spacing}
      data-color={rowColor}
      data-last-item={isLast ? 'true' : undefined}
      onClick={
        onClick
          ? e => {
              e.preventDefault();
              e.stopPropagation();
              onClick(item);
            }
          : undefined
      }
    >
      {archived && (
        <UnarchiveButton
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            onArchiveClick(item);
          }}
          variant="light"
          icon={MdUnarchive}
        >
          Désarchiver
        </UnarchiveButton>
      )}
      {isSortable && <Handle />}
      {rowRenderer ? (
        rowRenderer({
          columns,
          item,
          onChange,
          readOnly: readOnly || archived
        })
      ) : (
        <CellsWrapper wrap={wrap ? 'true' : 'false'}>
          <RowColumnRenderer
            columns={columns}
            item={item}
            onChange={onChange}
            readOnly={readOnly || archived}
            index={index}
          />
        </CellsWrapper>
      )}
      {(isArchivable || isDeletable || menuOptions?.length) && (
        <RowActionWrapper more>
          {loading && <Spinner size={16} singleColor={theme.accent} />}
          {!loading && (
            <Menu maxWidth={300}>
              <ButtonIcon size="medium">
                <MdMoreVert />
              </ButtonIcon>
              {menuOptions?.length &&
                menuOptions.map(opt => (
                  <MenuItem
                    key={opt.label}
                    onClick={e => opt.onClick({ item, index })}
                  >
                    {opt.label}
                  </MenuItem>
                ))}
              {archivable && (
                <MenuItem onClick={e => onArchiveClick(item)}>
                  Archiver
                </MenuItem>
              )}
              {deletable && canDeleteRow && (
                <MenuItem onClick={e => onDeleteClick(item)}>
                  Supprimer
                </MenuItem>
              )}
            </Menu>
          )}
        </RowActionWrapper>
      )}
    </Row>
  );
};

const Table = ({
  listRef,
  className,
  columns,
  items,
  rowRenderer: customRowRenderer,
  lightContainer,
  isSortable,
  onChange,
  onClick,
  linkFormatter, // ({item}) => "/path/..." avec useCallback,
  minWidth,

  initialColumnSort,
  onColumnSortChanged,
  readOnly = false,

  isItemLoading,
  isDeletable = false,
  onDelete,
  deleteRequest,
  hasDeleteConfirmation = true,
  deleteConfirmationMessage,
  onDeleteSuccess, // on l'utilise si y'a la deleteRequest

  isArchived,
  isArchivable,
  onToggleArchive,

  headerHidden = false,
  headerVariant = 'light', // light (default), primary, dark, background, ou transparent
  rowColor = 'light', // light (default), dark ou transparent
  useWindow = true,
  rowWrap = true,
  spacing,
  estimatedItemSize = spacing ? 46 + spacing : 44,
  isLoading,
  menuOptions,

  ...props
}) => {
  const [confirmDelete, setConfirmDelete] = useState();
  const [topReached, setTopReached] = useState(true);
  const headerRef = useRef();
  const itemsRef = useRef();
  const onChangeRef = useRef();
  itemsRef.current = items;
  onChangeRef.current = onChange;

  const deletable = Boolean(isDeletable) && !readOnly;
  const archivable = Boolean(isArchivable) && !readOnly;
  const sortable = isSortable && !readOnly;

  const memoColumns = useMemo(() => {
    let formatted = columns.map(column => {
      if (column.kind === 'color' || column.accessor === 'color') {
        return {
          ...column,
          Header: ' ',
          noBorder: true,
          width: 24,
          minWidth: 24,
          padding: '2px 0px 2px 10px',
          textAlign: 'center',
          Cell: ({ item }) => (
            <Dot
              color={
                column.accessor === 'color'
                  ? get(item, 'color')
                  : get(item, column.accessor)
              }
              size={14}
            />
          )
        };
      } else {
        return column;
      }
    });
    return formatted;
  }, [columns]);

  const performDelete = useCallback(
    async item => {
      const items = itemsRef.current;
      if (deleteRequest) {
        try {
          const result = await deleteRequest(item);
          if (onDeleteSuccess) {
            onDeleteSuccess(item);
          }
          return result;
        } catch (e) {
          toaster.error(e);
        }
      } else if (onDelete) {
        onDelete(item);
      } else {
        if (item.isNew) {
          onChangeRef.current(items.filter(i => getId(i) !== getId(item)));
        } else {
          onChangeRef.current(
            items.map(i => {
              if (getId(i) !== getId(item)) {
                return i;
              } else {
                return {
                  ...item,
                  _destroy: true
                };
              }
            })
          );
        }
      }
    },
    [deleteRequest, onDelete, onDeleteSuccess]
  );

  const handleItemChange = useCallback(({ prevId, ...item }) => {
    onChangeRef.current(
      itemsRef.current.map(i => {
        if (getId(i) === (prevId || getId(item))) {
          return item;
        } else {
          return i;
        }
      }),
      item
    );
  }, []);

  const handleDelete = useCallback(
    item => {
      if (hasDeleteConfirmation) {
        setConfirmDelete(item);
      } else {
        return performDelete(item);
      }
    },
    [hasDeleteConfirmation, performDelete]
  );

  const handleToggleArchive = useCallback(
    item => {
      onToggleArchive({
        ...item,
        archived: !item.archived
      });
    },
    [onToggleArchive]
  );

  const handleVerticalScroll = useCallback(scrollTop => {
    setTopReached(scrollTop === 0);
  }, []);

  const handleHorizontalScroll = useCallback(scrollLeft => {
    if (headerRef.current) {
      headerRef.current.scrollLeft = scrollLeft;
    }
  }, []);

  const wrap = useMemo(
    () => rowWrap && !columns.find(c => Boolean(c.minWidth)),
    [columns, rowWrap]
  );

  const rowRenderer = useCallback(
    ({ item, isLast, isFirst, index }) => {
      if (item.id === 'empty') return <div style={{ height: 1, minWidth }} />;
      if (item.tableKind === 'section') {
        return <div style={{ height: 30 }} />;
      }
      return (
        <TableRow
          columns={memoColumns}
          item={item}
          rowRenderer={customRowRenderer}
          linkFormatter={linkFormatter}
          onClick={onClick}
          isSortable={sortable}
          readOnly={readOnly}
          onChange={handleItemChange}
          isItemLoading={isItemLoading}
          onDeleteClick={handleDelete}
          isDeletable={deletable ? isDeletable : false}
          isArchivable={archivable ? isArchivable : false}
          isArchived={isArchived}
          onArchiveClick={handleToggleArchive}
          spacing={spacing}
          wrap={wrap}
          rowColor={rowColor}
          isLast={isLast}
          isFirst={isFirst}
          index={index}
          menuOptions={menuOptions}
        />
      );
    },
    [
      memoColumns,
      customRowRenderer,
      linkFormatter,
      onClick,
      sortable,
      readOnly,
      handleItemChange,
      handleDelete,
      isItemLoading,
      deletable,
      archivable,
      isArchivable,
      isDeletable,
      isArchived,
      handleToggleArchive,
      spacing,
      wrap,
      rowColor,
      menuOptions,
      minWidth
    ]
  );

  const isContentLoading = isLoading && items.length === 0;
  const isHeaderLoading = isLoading && items.length > 0;

  const memoItems = useMemo(() => {
    const data = items.filter(i => !i._destroy);
    if (data.length || !minWidth) return data;
    return [{ id: 'empty' }];
  }, [items, minWidth]);

  const containerStyle = useMemo(
    () => ({ height: useWindow ? undefined : 'unset' }),
    [useWindow]
  );

  return (
    <Container className={className} vertical style={containerStyle}>
      {!headerHidden && (
        <Header
          ref={headerRef}
          columns={memoColumns}
          isArchivable={archivable}
          isDeletable={deletable}
          isSortable={sortable}
          initialColumnSort={initialColumnSort}
          onColumnSortChanged={onColumnSortChanged}
          variant={headerVariant}
          topReached={topReached}
          spacing={spacing}
          isLoading={isHeaderLoading}
        />
      )}
      <List
        {...props}
        ref={listRef}
        useWindow={useWindow}
        items={memoItems}
        spacing={spacing}
        isSortable={sortable}
        onHorizontalScroll={handleHorizontalScroll}
        onVerticalScroll={handleVerticalScroll}
        useDragHandle
        scrollPadding={false}
        rowRenderer={rowRenderer}
        isLoading={isContentLoading}
        estimatedItemSize={estimatedItemSize}
      />
      {confirmDelete && (
        <Dialog
          isOpen={true}
          onRequestClose={() => setConfirmDelete(null)}
          title="Confirmer la suppression"
          content={
            deleteConfirmationMessage
              ? deleteConfirmationMessage(confirmDelete)
              : 'Toute suppression est définitive et les données associées seront perdues.'
          }
          positiveText="Annuler"
          negativeText="Supprimer"
          negativeAction={() => performDelete(confirmDelete)}
        />
      )}
    </Container>
  );
};

export default React.memo(Table);

import React from 'react';

const IcSortAsc = props => (
  <svg
    width={`${props.size || 18}px`}
    height={`${props.size || 18}px`}
    viewBox="0 0 18 18"
    {...props}
  >
    <g
      id="ic_sort_asc"
      stroke="none"
      strokeWidth="1"
      fill="currentColor"
      fillRule="evenodd"
    >
      <g
        id="Group"
        transform="translate(4.000000, 1.000000)"
        fill="#000000"
        fillRule="nonzero"
      >
        <g
          id="iconmonstr-arrow-81"
          transform="translate(5.000000, 12.307692) rotate(180.000000) translate(-5.000000, -12.307692) translate(0.000000, 8.615385)"
          fillOpacity="0.37"
        >
          <polygon id="Path" points="5 0 10 7.38461538 0 7.38461538" />
        </g>
        <g id="iconmonstr-arrow-81" fillOpacity="0.87">
          <polygon id="Path" points="5 0 10 7.38461538 0 7.38461538" />
        </g>
      </g>
    </g>
  </svg>
);

export default IcSortAsc;

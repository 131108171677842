import React, { useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { MdArrowDropDown } from 'react-icons/md';
import Tag from 'components/v3/Tag';
import { useAction } from 'components/v3/utils';
import {
  SelectInputLayout,
  InputLayoutVariant
} from 'components/v3/InputLayout';
import {
  HolidaySelector,
  HolidayAction,
  holidayKindAsOption
} from 'features/Holidays';

const HolidayKindSelect = ({
  holidayId,
  value,
  onChange,
  size,
  asTag,
  readOnly,
  ...props
}) => {
  const action = useAction();
  const dispatch = useDispatch();
  const kinds = useSelector(HolidaySelector.selectHolidayKinds, isEqual);

  const options = useMemo(() => kinds.map(holidayKindAsOption), [kinds]);

  const currentValue = useMemo(() => holidayKindAsOption(value), [value]);

  const currentOptionRenderer = useCallback(
    ({ option }) => (
      <Tag
        displayName={option.label}
        color={option.color}
        size={size}
        wide
        trailingIcon={readOnly ? null : MdArrowDropDown}
        clickable={!readOnly}
      />
    ),
    [readOnly, size]
  );

  const handleChange = useCallback(
    kind => {
      if (readOnly) {
        return;
      }
      if (onChange) {
        onChange(kind);
      } else if (holidayId) {
        action(() =>
          dispatch(
            HolidayAction.updateHolidayKind({
              id: holidayId,
              payload: { kind: kind?.value }
            })
          )
        );
      }
    },
    [readOnly, action, dispatch, holidayId, onChange]
  );

  return (
    <SelectInputLayout
      label={asTag ? null : 'Type de congé'}
      isSearchable={!asTag}
      variant={asTag ? InputLayoutVariant.flat.medium : null}
      isClearable={false}
      showIndicator={!asTag}
      sortOptions={false}
      options={options}
      value={currentValue}
      onChange={handleChange}
      currentOptionRenderer={asTag ? currentOptionRenderer : null}
      readOnly={readOnly}
      {...props}
    />
  );
};

export default HolidayKindSelect;

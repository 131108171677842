import { get, put, post, destroy } from 'common/api';

export const getInvoices = params => get('/v5/invoices', params);

export const createInvoice = (invoice, config) =>
  post('/v5/invoices', { invoice }, config);

export const getInvoice = (id, config) => get(`/v5/invoices/${id}`, config);

export const putInvoice = (id, invoice, config) =>
  put(
    `/v5/invoices/${id}`,
    {
      invoice
    },
    config
  );

export const deleteInvoice = (id, config) =>
  destroy(`/v5/invoices/${id}`, config);

export const sendInvoiceByEmail = (id, content, config) =>
  post(`/v5/invoices/${id}/send_email`, { emailAttributes: content }, config);

export const getServices = params => get('/v5/services', params);

export const createService = (service, config) =>
  post('/v5/services', { service }, config);

export const getService = (id, config) => get(`/v5/services/${id}`, config);

export const putService = (id, service, config) =>
  put(
    `/v5/services/${id}`,
    {
      service
    },
    config
  );

export const deleteService = (id, config) =>
  destroy(`/v5/services/${id}`, config);

export const getUnits = params => get('/v5/units?sort=display_name', params);

export const createUnit = (unit, config) => post('/v5/units', { unit }, config);

export const getUnit = (id, config) => get(`/v5/units/${id}`, config);

export const putUnit = (id, unit, config) =>
  put(`/v5/units/${id}`, { unit }, config);

export const deleteUnit = (id, config) => destroy(`/v5/units/${id}`, config);

export const createSubcontracting = (subcontracting, config) =>
  post('/v5/subcontractings', { subcontracting }, config);

export const getSubcontracting = (id, config) =>
  get(`/v5/subcontractings/${id}`, config);

export const putSubcontracting = (id, subcontracting, config) =>
  put(`/v5/subcontractings/${id}`, { subcontracting }, config);

export const deleteSubcontracting = (id, config) =>
  destroy(`/v5/subcontractings/${id}`, config);

import { createDeepEqualSelector, getParams, getProps } from 'common/selector';
import { getState, selectRelations } from 'common/selector';
import sortBy from 'lodash/sortBy';
import isSameIds from 'utils/idUtils';

export const getActivities = state => state.activities;

export const selectActivities = createDeepEqualSelector(
  [getActivities, getState],
  ({ activitiesIds, activityById }, state) =>
    activitiesIds
      .map(id => selectRelations('activity', activityById[id], state))
      .filter(Boolean)
);

export const selectActivitiesByProjectKind = createDeepEqualSelector(
  [getActivities, getProps, getState],
  ({ activityById }, { projectKindId, administrative }, state) =>
    sortBy(
      Object.values(activityById)
        .map(activity => selectRelations('activity', activity, state))
        .filter(
          activity =>
            activity &&
            Boolean(activity.administrative) === administrative &&
            projectKindId &&
            (isSameIds(activity?.projectKind?.id, projectKindId) ||
              isSameIds(activity?.projectKindId, projectKindId))
        ),
      'id'
    )
);

export const selectActivity = createDeepEqualSelector(
  [getActivities, getParams, getState],
  ({ activityById }, { activityId }, state) =>
    selectRelations('activity', activityById[activityId], state)
);

import React, { useCallback } from 'react';
import styled from 'styled-components';
import { v4 } from 'uuid';
import { TextInputLayout, InputLayoutVariant } from 'components/v3/InputLayout';
import { FormTable, FormTableTitle } from 'components/v3/Form/TableField';
import {
  ExtractionDestinationSelect,
  ExtractionMaterialSelect
} from 'features/Materials';

const Wrapper = styled.div`
  width: 100%;
`;

const isRowValid = row =>
  Boolean(row.destinationMaterial) &&
  Boolean(row.extractionMaterial) &&
  row.quantity != null;

export const COLUMNS = [
  {
    Header: 'Tonnage',
    accessor: 'quantity',
    width: 100,
    maxWidth: 100,
    minWidth: 100,
    dashed: true,
    isEditable: true,
    Cell: ({ item, onChange }) => (
      <TextInputLayout
        variant={InputLayoutVariant.flat.medium}
        number
        value={item.quantity}
        fillWidth
        decimalScale={1}
        onChange={quantity =>
          onChange({
            ...item,
            quantity
          })
        }
      />
    )
  },
  {
    Header: 'Classe',
    accessor: 'extractionMaterial',
    width: '60%',
    dashed: true,
    minWidth: 200,
    isEditable: true,
    Cell: ({ item, onChange }) => (
      <ExtractionMaterialSelect
        variant={InputLayoutVariant.flat.medium}
        label="Classe"
        value={item?.extractionMaterial}
        fillWidth
        onChange={extractionMaterial => {
          onChange({
            ...item,
            extractionMaterial,
            extractionMaterialId: extractionMaterial?.id || null
          });
        }}
      />
    )
  },
  {
    Header: 'Destination',
    accessor: 'extractionDestination',
    width: 'calc(40% - 100px)',
    minWidth: 150,
    dashed: true,
    isEditable: true,
    Cell: ({ item, onChange }) => (
      <ExtractionDestinationSelect
        variant={InputLayoutVariant.flat.medium}
        value={item.extractionDestination}
        fillWidth
        label="Destination"
        onChange={extractionDestination => {
          onChange({
            ...item,
            extractionDestination,
            extractionDestinationId: extractionDestination?.id || null
          });
        }}
      />
    )
  }
];

export const ExtractionTable = ({
  phaseId,
  extractionItems,
  onChange,
  readOnly,
  ...props
}) => {
  const rowCreator = useCallback(() => {
    return {
      destinationMaterial: null,
      extractionMaterial: null,
      quantity: null,
      isNew: true,
      id: v4(),
      phaseId
    };
  }, [phaseId]);

  return (
    <Wrapper>
      <FormTableTitle
        label="Sortants"
        asSection
        items={extractionItems}
        readOnly={readOnly}
        onAddRow={() =>
          onChange([
            ...(extractionItems || []),
            {
              ...rowCreator()
            }
          ])
        }
      />
      <FormTable
        {...props}
        columns={COLUMNS}
        useWindow={false}
        rowWrap={false}
        value={extractionItems}
        onChange={onChange}
        minHeight={50}
        isRowValid={isRowValid}
        readOnly={readOnly}
      />
    </Wrapper>
  );
};

import React, { useMemo, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { MdArrowDropDown } from 'react-icons/md';
import Tag from 'components/v3/Tag';
import { ProjectSelector, projectStateAsOption } from 'features/Projects';
import { useAction, useDelay } from 'components/v3/utils';
import { ProjectAction } from 'features/Projects';
import {
  SelectInputLayout,
  InputLayoutVariant
} from 'components/v3/InputLayout';

const State = ({
  projectId,
  value,
  onChange,
  size,
  asTag = true,
  readOnly,
  mapOptionValue,
  ...props
}) => {
  const delay = useDelay();
  const action = useAction();
  const dispatch = useDispatch();
  const projectStates = useSelector(
    ProjectSelector.selectProjectStates,
    isEqual
  );

  const options = useMemo(
    () => projectStates.map(projectStateAsOption),
    [projectStates]
  );

  const currentValue = useMemo(
    () => (mapOptionValue ? value : projectStateAsOption(value)),
    [value, mapOptionValue]
  );

  const currentOptionRenderer = useCallback(
    ({ option }) => (
      <Tag
        displayName={option.label}
        color={option.color}
        size={size}
        wide
        trailingIcon={readOnly ? null : MdArrowDropDown}
        clickable={!readOnly}
      />
    ),
    [readOnly, size]
  );

  const handleChange = useCallback(
    state => {
      if (readOnly) {
        return;
      }
      if (onChange) {
        onChange(state);
      } else if (projectId) {
        action(() =>
          dispatch(
            ProjectAction.updateProjectState({
              id: projectId,
              payload: { state: state?.value }
            })
          )
        );
      }
    },
    [readOnly, action, dispatch, projectId, onChange]
  );

  useEffect(() => {
    if (readOnly) {
      return;
    }
    if (asTag && !value && onChange) {
      if (options.length === 0) {
        return;
      }
      const state = options.find(option => option?.original?.default)?.original;
      delay(() => onChange(state || options[0]?.original), 32);
    }
  }, [readOnly, delay, asTag, value, onChange, options]);

  if (!options || options.length === 0) {
    return null;
  }
  return (
    <SelectInputLayout
      label={asTag ? null : 'État'}
      isSearchable={!asTag}
      variant={asTag ? InputLayoutVariant.flat.medium : null}
      isClearable={false}
      showIndicator={!asTag}
      sortOptions={false}
      options={options}
      value={currentValue}
      onChange={handleChange}
      currentOptionRenderer={asTag ? currentOptionRenderer : null}
      readOnly={readOnly}
      mapOptionValue={mapOptionValue}
      {...props}
    />
  );
};

export default State;

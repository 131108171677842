import React from 'react';
import styled from 'styled-components';
import { SortableHandle } from 'react-sortable-hoc';
import { FaGripHorizontal } from 'react-icons/fa';

export const Draggable = styled.div`
  padding: 8px;
  border: none;
  color: #cccccc;
  height: 32px;
  width: 32px;
  min-width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: grab;
  align-self: center;
  &:hover {
    color: #222222;
  }
`;

const Handle = SortableHandle(({ tabIndex }) => (
  <Draggable tabIndex={tabIndex}>
    <FaGripHorizontal size={16} />
  </Draggable>
));

export default Handle;

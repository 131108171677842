import React, { useCallback, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import isEqual from 'lodash/isEqual';
import { EntityModal } from 'components/v3/Modal';
import { Grid as RawGrid } from 'components/v3/ui';
import { useQuery } from 'components/v3/utils';
import { ReportSelector, ReportAction, reportAsValues } from 'features/Reports';
import CustomFields from 'features/CustomFieldGroups/components/v3/CustomFields';

const Grid = styled(RawGrid)`
  min-height: 200px;
`;

const ReportModal = ({
  onRequestClose,
  onSubmitSuccess,
  report: propsReport,
  ...props
}) => {
  const dispatch = useDispatch();
  let { reportKindId } = useQuery();
  const { params } = useRouteMatch();
  let { reportId, projectId } = params;
  if (propsReport) {
    reportId = propsReport.id;
  }
  const isNew = !Boolean(reportId);

  const selectReport = useMemo(
    () => state =>
      ReportSelector.selectReport(state, {
        reportId
      }),
    [reportId]
  );

  let report = useSelector(selectReport, isEqual);
  if (!report) {
    report = propsReport;
  } else {
    reportKindId = report.reportKindId;
  }

  const createRequest = useCallback(
    payload => dispatch(ReportAction.createReport({ payload })),
    [dispatch]
  );

  const fetchRequest = useCallback(
    () => dispatch(ReportAction.getReport({ id: reportId })),
    [reportId, dispatch]
  );

  const updateRequest = useCallback(
    (id, payload) => dispatch(ReportAction.updateReport({ id, payload })),
    [dispatch]
  );

  const deleteRequest = useCallback(
    id => dispatch(ReportAction.deleteReport({ id })),
    [dispatch]
  );

  const entityAsValue = useCallback(
    entity => {
      const values = reportAsValues(entity);
      return {
        ...values,
        projectId
      };
    },
    [projectId]
  );

  // const validationSchema = useMemo(() => {
  //   const schema = {};
  //   flatten(
  //     customFieldGroups
  //       .filter(f => f.fieldableType === 'Report')
  //       .map(cfg => cfg.customFields)
  //   )
  //     .filter(cf => cf.required && resourceableCallbackFilter(cf, reportValues))
  //     .forEach(cf => {
  //       switch (cf.kind) {
  //         case 'text':
  //         case 'textarea':
  //           return (schema[
  //             `customDataValidation_${cf.name}`
  //           ] = Yup.string().required(ERRORS.REQUIRED));
  //         case 'number':
  //           return (schema[
  //             `customDataValidation_${cf.name}`
  //           ] = Yup.number().required(ERRORS.REQUIRED));
  //         default:
  //           return null;
  //       }
  //     });
  //   return Yup.object().shape(schema);
  // }, [customFieldGroups, reportValues]);

  return (
    <EntityModal
      isOpen
      onRequestClose={onRequestClose}
      title={isNew ? 'Nouveau compte-rendu' : 'Édition du compte-rendu'}
      id={reportId}
      model="report"
      entity={
        report || {
          projectId,
          reportKindId
        }
      }
      entityAsValue={entityAsValue}
      fetchRequest={fetchRequest}
      createRequest={createRequest}
      updateRequest={updateRequest}
      deleteRequest={deleteRequest}
      onSubmitSuccess={onSubmitSuccess}
      autoSubmit={false}
      {...props}
    >
      {() => (
        <Grid spacing={16} padded={false}>
          <CustomFields
            name="customData"
            fieldableType="ReportKind"
            fieldableId={reportKindId}
          />
        </Grid>
      )}
    </EntityModal>
  );
};

export default ReportModal;

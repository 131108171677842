import { mergeJsonApi } from 'common/reducers';

const initialState = {
  namingRuleById: {},
  namingRulesIds: []
};

const namingRules = (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case 'LOGOUT':
    case 'SELECT_ACCOUNT':
      return initialState;
    default:
      return mergeJsonApi('namingRule', state, action);
  }
};

export default namingRules;

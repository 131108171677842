import omit from 'lodash/omit';
import uniq from 'lodash/uniq';
import { mergeJsonApi } from 'common/reducers';
import isSameIds from 'utils/idUtils';

const initialState = {
  organizationById: {},
  organizationsIds: [],
  searchIds: [],
  projectIds: [],
  contactIds: []
};

const organizations = (state = initialState, action) => {
  const { payload, type } = action;
  const pagination = payload?.meta?.pagination || {};
  const { page } = pagination;
  switch (type) {
    case 'NEW_ORGANIZATION_SUCCESS':
      return {
        ...mergeJsonApi('organization', state, action),
        organizationsIds: [payload.data.id, ...state.organizationsIds]
      };
    case 'DELETE_ORGANIZATION_SUCCESS':
      return {
        ...state,
        organizationById: omit(state.organizationById, payload),
        organizationsIds: state.organizationsIds.filter(
          id => !isSameIds(id, payload)
        )
      };
    case 'NEW_CONTACT_SUCCESS':
      return {
        ...state,
        contactIds: [payload.data.id, ...(state.contactIds || [])]
      };
    case 'SEARCH_ORGANIZATIONS_SUCCESS':
      let searchIds = state.searchIds || [];
      if (page > 1) {
        searchIds = uniq([
          ...searchIds,
          ...payload.data.map(organization => organization.id)
        ]);
      } else {
        searchIds = payload.data.map(organization => organization.id);
      }
      return {
        ...state,
        ...mergeJsonApi('organization', state, action),
        [type]: {
          fetchAt: new Date().getTime()
        },
        searchIds
      };
    case 'ORGANIZATION_CONTACTS_SUCCESS':
      let contactIds = state.contactIds || [];
      if (page > 1) {
        contactIds = uniq([
          ...contactIds,
          ...payload.data.map(contact => contact.id)
        ]);
      } else {
        contactIds = payload.data.map(contact => contact.id);
      }
      return {
        ...state,
        contactIds
      };
    case 'ORGANIZATION_PROJECTS_SUCCESS':
      let projectIds = state.projectIds || [];
      if (page > 1) {
        projectIds = uniq([
          ...projectIds,
          ...payload.data.map(project => project.id)
        ]);
      } else {
        projectIds = payload.data.map(project => project.id);
      }
      return {
        ...state,
        projectIds
      };
    case 'LOGOUT':
    case 'SELECT_ACCOUNT':
      return initialState;
    default:
      return mergeJsonApi('organization', state, action);
  }
};

export default organizations;

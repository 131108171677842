import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { AuthAction } from 'features/Auth';
import Form, { PasswordField, InputLayoutVariant } from 'components/v3/Form';
import { toaster } from 'components/v3/Toast';
import PasswordStrength from 'components/v3/PasswordStrength';
import { Grid, GridItem } from 'components/v3/ui';
import { useQuery, useRedux } from 'components/v3/utils';
import { FormFooter, FormHeader } from './';

const Schema = Yup.object().shape({
  password: Yup.string()
    .trim()
    .required()
});

export const ResetPasswordForm = () => {
  const { email, resetToken } = useQuery();
  const history = useHistory();

  const [, resetPassword] = useRedux(null, AuthAction.resetPassword, {
    fetchOnMount: false
  });

  const handleSubmit = useCallback(
    (values, form) => {
      resetPassword(values)
        .onSuccess(res => {
          switch (res) {
            case 'logged':
              history.replace('/');
              break;
            default:
              toaster.info(
                'Vous pouvez maintenant vous connecter avec votre nouveau mot de passe.'
              );
              history.replace('/connexion');
              break;
          }
        })
        .onError(e => toaster.error(e, { position: 'bottom-center' }))
        .onEnd(() => form.setSubmitting(false));
    },
    [resetPassword, history]
  );

  return (
    <Form
      autofill={false}
      onSubmit={handleSubmit}
      validationSchema={Schema}
      initialValues={{
        password: '',
        email,
        resetPasswordToken: resetToken
      }}
    >
      {({ values, isSubmitting, isValid }) => (
        <Grid spacing={16}>
          <FormHeader
            title="Réinitalisation du mot de passe"
            subtitle="Choisissez votre nouveau mot de passe."
          />
          <GridItem width="100%">
            <input type="hidden" />
            <input name="email" type="hidden" />
            <PasswordField
              variant={InputLayoutVariant.fill}
              hint="Pour un maximum de sécurité, choisissez un mot de passe fort, en suivant notre indicateur."
              label="Votre mot de passe"
              name="password"
            />
            <PasswordStrength password={values.password} />
          </GridItem>
          <FormFooter
            isSubmitting={isSubmitting}
            isValid={isValid}
            primaryAction={{
              text: 'Valider'
            }}
          />
        </Grid>
      )}
    </Form>
  );
};

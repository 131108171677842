import React, { useMemo } from 'react';
import { MdMoreVert } from 'react-icons/md';
import isSameIds from 'utils/idUtils';
import Menu, { MenuItem } from 'components/v3/Menu';
import { createLine, createSeparator } from './utils';
import { ButtonIcon } from 'components/v3/ui';
import { sortBy } from 'lodash';

const LineOptions = ({ item, index, invoiceLines, onLinesChanged, group }) => {
  const options = useMemo(() => {
    const insert = (line, index) => {
      let lines = [...invoiceLines];

      lines.splice(index, 0, line);
      const newItems = sortBy(
        lines.map((i, index) => ({
          ...i,
          position: index
        })),
        'position'
      );
      onLinesChanged(newItems);
    };

    return [
      {
        label: 'Dupliquer',
        onClick: ({ index, item }) => {
          insert(createLine(index + 1, group, item), index + 1);
        }
      },
      {
        label: 'Insérer une ligne au dessus',
        onClick: ({ index }) => {
          insert(createLine(index, group), index);
        }
      },
      {
        label: 'Insérer une ligne en dessous',
        onClick: ({ index }) => {
          insert(createLine(index + 1, group), index + 1);
        }
      },
      {
        label: 'Insérer un séparateur au dessus',
        onClick: ({ index }) => {
          insert(createSeparator(index), index);
        }
      },
      {
        label: 'Insérer un séparateur en dessous',
        onClick: ({ index }) => {
          insert(createSeparator(index + 1), index + 1);
        }
      },
      {
        label: 'Supprimer',
        onClick: ({ item }) => {
          if (item.isNew) {
            onLinesChanged(invoiceLines.filter(i => !isSameIds(i.id, item.id)));
          } else {
            onLinesChanged(
              invoiceLines.map(i => {
                if (!isSameIds(i.id, item.id)) {
                  return i;
                } else {
                  return {
                    ...item,
                    _destroy: true
                  };
                }
              })
            );
          }
        }
      }
    ];
  }, [invoiceLines, onLinesChanged, group]);

  return (
    <Menu maxWidth={300}>
      <ButtonIcon size="medium">
        <MdMoreVert />
      </ButtonIcon>
      {options.map(opt => (
        <MenuItem
          key={opt.label}
          onClick={e => opt.onClick({ item, index: index - 1 })}
        >
          {opt.label}
        </MenuItem>
      ))}
    </Menu>
  );
};

export default LineOptions;

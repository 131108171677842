import { createAction } from 'redux-actions';
import omit from 'lodash/omit';
import * as Api from './api';
import { prepareAttributes, shouldFetch } from 'common/actions';

const preparePayload = payload =>
  prepareAttributes(
    {
      ...payload,
      groupIds: payload.groups?.map(group => group.id),
      production: payload.settings?.production || payload.role === 'technician'
    },
    'settings'
  );

const usersLoading = createAction('USERS_LOADING');
const usersSuccess = createAction('USERS_SUCCESS');
const searchUsersSuccess = createAction('SEARCH_USERS_SUCCESS');
const usersError = createAction('USERS_ERROR');
export const getUsers = payload => async (dispatch, getState) => {
  const { useCache, isSearchRequest, ...params } = payload || {};
  const state = getState();
  const action = isSearchRequest ? 'SEARCH_USERS_SUCCESS' : 'USERS_SUCCESS';
  if (!useCache || shouldFetch('user', params, state, action)) {
    return fetchUsers(params, isSearchRequest, dispatch);
  } else {
    return {
      fromCache: true,
      isLoading: getState().users.isLoading
    };
  }
};
const fetchUsers = async (payload, isSearchRequest, dispatch) => {
  if (!isSearchRequest) {
    dispatch(usersLoading());
  }
  try {
    const result = await Api.getUsers(payload);
    if (isSearchRequest) {
      dispatch(
        searchUsersSuccess({
          ...result,
          requestParams: omit(payload, 'cancelToken')
        })
      );
    } else {
      dispatch(
        usersSuccess({ ...result, requestParams: omit(payload, 'cancelToken') })
      );
    }
    return result;
  } catch (error) {
    dispatch(usersError(error));
    throw error;
  }
};

export const receiveFindUsers = createAction('SEARCH_USERS_SUCCESS');
export const findUsers = payload => async dispatch => {
  const result = await Api.getUsers(payload);
  dispatch(receiveFindUsers(result, payload));
  return result;
};

const receiveCreateUser = createAction('NEW_USER_SUCCESS');
export const createUser =
  ({ payload, ...config }) =>
  async dispatch => {
    const result = await Api.createUser(preparePayload(payload), config);
    dispatch(receiveCreateUser(result));
    return result;
  };

const receiveUser = createAction('USER_SUCCESS');
export const getUser =
  ({ id, ...payload }) =>
  async dispatch => {
    const result = await Api.getUser(id, payload);
    dispatch(receiveUser(result));
    return result;
  };

const receiveUpdateUser = createAction('UPDATE_USER_SUCCESS');
export const updateUser =
  ({ id, payload, ...config }) =>
  async dispatch => {
    const result = await Api.putUser(id, preparePayload(payload), config);
    dispatch(receiveUpdateUser(result));
    return result;
  };

const receiveDeleteUser = createAction('DELETE_USER_SUCCESS');
export const deleteUser =
  ({ id, ...config }) =>
  async dispatch => {
    const response = await Api.deleteUser(id, config);
    dispatch(receiveDeleteUser(id));
    return response;
  };

const receiveResendInvitation = createAction('RESEND_INVITATION_USER_SUCCESS');
export const resendInvitation =
  ({ id, ...config }) =>
  async dispatch => {
    const result = await Api.resendInvitation(id, config);
    dispatch(receiveResendInvitation(result));
    return result;
  };

export const fetchWorksRecords = params => async dispatch => {
  const response = await Api.getWorkRecords({
    skipCamelize: true,
    ...params
  });
  return response;
};

export const updateWorkRecords = params => async dispatch => {
  const response = await Api.updateWorkRecords({
    skipCamelize: true,
    ...params
  });
  return response;
};

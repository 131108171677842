import { omit } from 'lodash';
import { mergeJsonApi } from 'common/reducers';
import isSameIds from 'utils/idUtils';

const initialReportsState = {
  reportById: {},
  reportsIds: []
};

const reports = (state = initialReportsState, action) => {
  const { payload, type } = action;

  switch (type) {
    case 'NEW_REPORT_SUCCESS':
      return {
        ...mergeJsonApi('report', state, action),
        reportsIds: [payload.data.id, ...state.reportsIds]
      };
    case 'DELETE_REPORT_SUCCESS':
      return {
        ...state,
        reportById: omit(state.reportById, payload),
        reportsIds: state.reportsIds.filter(id => !isSameIds(id, payload))
      };
    case 'LOGOUT':
    case 'SELECT_ACCOUNT':
      return initialReportsState;
    default:
      return mergeJsonApi('report', state, action);
  }
};

const initialKindsState = {
  reportKindById: {},
  reportKindsIds: []
};

const reportKinds = (state = initialKindsState, action) => {
  const { type } = action;

  switch (type) {
    case 'LOGOUT':
    case 'SELECT_ACCOUNT':
      return initialKindsState;
    default:
      return mergeJsonApi('reportKind', state, action);
  }
};

export default reportKinds;

export { reports, reportKinds };

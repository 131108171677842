import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import Modal, { ModalHeader } from 'components/v3/Modal';
import { Grid, GridItem } from 'components/v3/ui';
import { VoucherSelector } from '../data';
import VoucherVersionsBox from './VoucherVersionsBox';

const VoucherModal = ({
  voucherableId,
  voucherableType,
  voucherId,
  onRequestClose,
  ...props
}) => {
  const selectVoucher = useMemo(
    () => state => VoucherSelector.selectVoucher(state, { id: voucherId }),
    [voucherId]
  );
  const voucher = useSelector(selectVoucher, isEqual);

  return (
    <Modal isOpen size="normal" onRequestClose={onRequestClose} {...props}>
      <ModalHeader title={`${voucher?.voucherKind?.name}\u00a0: versions`} />
      <Grid spacing={16} padded>
        <GridItem>
          <VoucherVersionsBox
            voucher={voucher}
            creatable
            flat
            withoutTitle
            voucherableId={voucherableId}
            voucherableType={voucherableType}
          />
        </GridItem>
      </Grid>
    </Modal>
  );
};

export default VoucherModal;

import {
  parseISO,
  format as formatter,
  formatRFC3339,
  addDays,
  getYear,
  isEqual,
  addSeconds,
  addMilliseconds,
  startOfDay,
  getMonth,
  getDayOfYear,
  parse
} from 'date-fns';
import fr from 'date-fns/locale/fr';

export const dateString = (
  dateString: string | undefined | null,
  format: string = 'dd/MM/yyyy'
) => {
  return printDate(dateString, format);
};

export const printDate = (
  d: Date | string | undefined | null,
  format: string = 'dd/MM/yyyy'
) => {
  try {
    const date = d instanceof Date ? d : parseDate(d);
    return date
      ? formatter(date, format, { locale: fr, weekStartsOn: 1 })
      : null;
  } catch (e) {
    console.warn(e, d);
    return '';
  }
};

export const printDateRange = (
  dateRange: any | Array<any> | undefined | null,
  format: string = 'dd/MM/yyyy',
  separator: string = ' au ',
  prefix: string = 'Du '
) => {
  if (!dateRange) {
    return null;
  }
  let start: string | Date | undefined | null;
  let end;
  if (Array.isArray(dateRange)) {
    start = dateRange[0];
    end = dateRange[1];
  } else {
    start = dateRange.startAt;
    end = dateRange.endAt;
  }
  if (!(start instanceof Date)) {
    start = parseDate(start);
  }
  if (!(end instanceof Date)) {
    end = parseDate(end);
  }
  if (!(start instanceof Date)) {
    return;
  }
  let showYear = getYear(start) !== getYear(new Date());

  let sameYear = end instanceof Date && getYear(start) === getYear(end);
  let startAtStartOfDay = startOfDay(start);
  let sameDay =
    !(end instanceof Date) ||
    (sameYear &&
      (getDayOfYear(start) === getDayOfYear(end) ||
        isEqual(startAtStartOfDay, end) ||
        isEqual(startAtStartOfDay, addDays(addSeconds(end, 1), -1)) ||
        isEqual(startAtStartOfDay, addDays(addMilliseconds(end, 1), -1)) ||
        isEqual(startAtStartOfDay, addDays(end, -1))));

  if (sameDay) {
    if (showYear) {
      return 'Le ' + printDate(start, format);
    } else {
      return (
        'Le ' +
        printDate(start, format.replace('/yyyy', '').replace('yyyy', ''))
      );
    }
  }
  let safeEnd = end as Date;
  if (isEqual(startOfDay(safeEnd), safeEnd)) {
    safeEnd = addMilliseconds(safeEnd, -1);
  }
  let startFormat = format;
  let endFormat = format;
  showYear = showYear || getYear(safeEnd) !== getYear(start);
  if (sameYear) {
    startFormat = startFormat.replace('/yyyy', '').replace(' yyyy', '');
    if (getMonth(start) === getMonth(safeEnd)) {
      startFormat = startFormat.replace('/MM', '').replace(' MMMM', '');
    }
  }
  if (!showYear) {
    endFormat = endFormat.replace('/yyyy', '').replace(' yyyy', '');
  }
  return `${prefix}${[
    printDate(start, startFormat),
    printDate(safeEnd, endFormat)
  ].join(separator)}`;
};

export const parseDate = (
  date: string | Date | undefined | null,
  format?: string
) => {
  try {
    if (date instanceof Date) {
      return date;
    }
    return date
      ? format
        ? parse(date, format, new Date())
        : parseISO(date)
      : null;
  } catch (e) {
    console.warn(e, dateString);
    return '';
  }
};

export const formatISODate = (date: Date | undefined | null) => {
  try {
    return date
      ? formatRFC3339(date, {
          fractionDigits: 3
        })
      : null;
  } catch (e) {
    console.warn(e, date);
    return '';
  }
};

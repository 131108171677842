import { createDeepEqualSelector, selectId, getProps } from 'common/selector';
import { ContactSelector } from 'features/Contacts';
import { ProjectSelector } from 'features/Projects';
import { getState, selectRelations } from 'common/selector';
import isSameIds from 'utils/idUtils';

export const getOrganizations = state => state.organizations;

export const selectOrganizations = createDeepEqualSelector(
  [getOrganizations, getState],
  ({ organizationsIds, organizationById }, state) =>
    organizationsIds
      .map(id => selectRelations('organization', organizationById[id], state))
      .filter(Boolean)
);

export const selectSearchOrganizations = createDeepEqualSelector(
  [getOrganizations, getState],
  ({ organizationById }, state) =>
    Object.values(organizationById)
      .map(organization => selectRelations('organization', organization, state))
      .filter(Boolean)
      .slice(0, 50)
);

export const selectOrganization = createDeepEqualSelector(
  [getOrganizations, selectId('organization'), getState],
  ({ organizationById }, organizationId, state) => {
    const organization = organizationById[organizationId];
    if (!organization) {
      return null;
    }
    return selectRelations('organization', organization, state);
  }
);

export const selectOrganizationProjects = createDeepEqualSelector(
  [getOrganizations, ProjectSelector.getProjects, getProps, getState],
  ({ projectIds }, { projectById }, { organizationId }, state) => {
    return projectIds
      .map(id => projectById[id])
      .filter(project =>
        isSameIds(
          project?.relationships?.organization?.data?.id,
          organizationId
        )
      )
      .map(project => selectRelations('project', project, state));
  }
);

export const selectOrganizationContacts = createDeepEqualSelector(
  [getOrganizations, ContactSelector.getContacts, getProps, getState],
  ({ contactIds }, { contactById }, { organizationId }, state) => {
    return contactIds
      .map(id => contactById[id])
      .filter(contact =>
        isSameIds(
          contact?.relationships?.organization?.data?.id,
          organizationId
        )
      )
      .map(contact => selectRelations('user', contact, state));
  }
);

import { createGlobalStyle } from 'styled-components';
import { spacing } from 'ui';

export const Styles = createGlobalStyle`
.flatpickr-month, 
.flatpickr-weekdays, 
.flatpickr-weekday, 
.flatpickr-monthDropdown-months, 
.flatpickr-day.selected {
  background: ${({ theme }) => theme.primaryLight} !important;
}
.flatpickr-prev-month, .flatpickr-next-month {
  user-select: none;
}

.flatpickr-calendar {
  margin-top: 6px !important;
  border-radius: ${spacing(0.75)} !important;
}

.flatpickr-month {
  border-radius: ${spacing(0.5)} ${spacing(0.5)} 0 0;
}

.flatpickr-innerContainer {
  border-radius:  0 0 ${spacing(0.5)} ${spacing(0.5)};
}

.flatpickr-calendar.arrowTop:after {
  border-bottom-color: ${({ theme }) => theme.primaryLight} !important; 
}

.flatpickr-weekday {
  color: ${({ theme }) => theme.textLight} !important;
}

.flatpickr-day {
  line-height: 36px !important;
}

`;

export default Styles;

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) {
      return undefined;
    }

    let localState = JSON.parse(localStorage.getItem('state'));
    if (localState && localState.currentUser) {
      // migration from old state
      localState = {
        ui: {
          ...(localState.ui || {}),
          isAuthenticated: localState.currentUser.isAuthenticated,
          userId: localState.currentUser.userId,
          currentAccountId: localState.currentUser.currentAccountId
        }
      };
    }
    return localState;
  } catch (err) {
    return undefined;
  }
};

export const saveState = state => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
  } catch (err) {
    return undefined;
  }
};

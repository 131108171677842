import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useVirtual } from 'react-virtual';
import { StickySectionList } from '../Virtualized';
import InvoiceLineV2 from './Line';
import { arrayMove, sortableContainer, sortableElement } from 'react-sortable-hoc';
import './index.css';
import Header from './Header';
import useLinesSelect from './useLinesSelect';
import LineOptions from './LineOptions';
import isSameIds from 'utils/idUtils';

export const spacing = () => ({ top: 0, bottom: 0 });
const style = { maxHeight: 500 };

const SortableRow = sortableElement(({ itemIndex, ...props }) => <InvoiceLineV2 {...props} index={itemIndex} />);
const SortableList = sortableContainer(StickySectionList);
const getContainer = () => document.querySelector('.rm-list')?.getElementsByTagName('ul')[0];
const estimateSize = (index) => (index ? 40 : 28);

const InvoicesList = ({ projectKindId, group, groupId, invoiceLines: invoiceLinesProps, readOnly, setFieldValue }) => {
  const listRef = useRef();
  useLinesSelect({ projectKindId, groupId });

  const invoiceLines = useMemo(() => invoiceLinesProps.filter((l) => !l._destroy), [invoiceLinesProps]);

  const destroyedLines = useMemo(() => invoiceLinesProps.filter((l) => l._destroy), [invoiceLinesProps]);

  const handleChange = useCallback(
    (lines) => {
      setFieldValue('invoiceLines', [...lines, ...destroyedLines]);
    },
    [destroyedLines, setFieldValue]
  );

  const lines = useMemo(() => {
    const lines = invoiceLines.map((line) => {
      if (line.kind !== 'divider') {
        return {
          ...line,
          total: parseFloat(line.unitPrice || 0) * parseFloat(line.quantity || 0)
        };
      } else {
        return line;
      }
    });
    let previousSection = -1;
    lines.forEach((line, index) => {
      const endReached = index === lines.length - 1;
      if (line.kind === 'divider' || endReached) {
        if (previousSection !== -1 && previousSection !== index - 1) {
          lines[previousSection] = {
            ...lines[previousSection],
            total: lines
              .slice(previousSection + 1, index + (endReached ? 1 : 0))
              .reduce((acc, { total }) => acc + total, 0)
          };
        }
        previousSection = index;
      }
    });
    return [{ id: 'section', name: 'header' }, ...lines];
  }, [invoiceLines]);

  const { virtualItems, totalSize, measure } = useVirtual({
    size: lines.length,
    parentRef: listRef,
    overscan: 10,
    paddingEnd: 56,
    estimateSize
  });

  const onSortEnd = useCallback(
    ({ oldIndex, newIndex }) => {
      if (oldIndex === newIndex) {
        return;
      }
      // -1 because of section header
      const newItems = arrayMove(invoiceLines, oldIndex - 1, newIndex - 1).map((i, index) => ({
        ...i,
        position: index
      }));
      handleChange(newItems);
    },
    [invoiceLines, handleChange]
  );

  const renderSection = useCallback(() => <Header />, []);

  const handleLineChanged = useCallback(
    (item) => {
      handleChange(
        invoiceLines.map((i) => {
          if (isSameIds(i.id, item.id)) {
            return {
              ...i,
              ...item
            };
          } else {
            return i;
          }
        })
      );
    },
    [handleChange, invoiceLines]
  );

  const optionMenu = useCallback(
    ({ item, index }) => {
      if (readOnly) {
        return null;
      }
      return (
        <LineOptions
          item={item}
          index={index}
          group={group}
          invoiceLines={invoiceLines}
          onLinesChanged={handleChange}
        />
      );
    },
    [handleChange, invoiceLines, group, readOnly]
  );

  const renderItem = useCallback(
    ({ item, index }) => {
      return (
        <SortableRow
          itemIndex={index}
          index={index}
          item={item}
          onChanged={(v) => handleLineChanged(v)}
          onChange={(v) => {}}
          readOnly={readOnly}
          groupId={groupId}
          optionMenu={optionMenu}
        />
      );
    },
    [handleLineChanged, readOnly, groupId, optionMenu]
  );

  useEffect(() => {
    measure();
  }, [measure]);

  return (
    <SortableList
      items={lines}
      style={style}
      virtualItems={virtualItems}
      totalSize={totalSize}
      listRef={listRef}
      renderSection={renderSection}
      renderItem={renderItem}
      itemSpacing={spacing}
      className="rm-list"
      helperClass="sort-invoice-line"
      useDragHandle
      lockAxis="y"
      getContainer={getContainer}
      onSortEnd={onSortEnd}
    />
  );
};

export default InvoicesList;

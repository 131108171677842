import React, { Fragment, useEffect, useState, useCallback, useRef, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useRouteMatch, useHistory } from 'react-router';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import queryString from 'query-string';
import PhaseModal from 'features/Phases/components/v3/Modal';
import RawTimeline from 'components/v3/Planning/Timeline';
import RawCalendar from 'components/v3/Planning/Calendar';
import { useAccountConfig, Permission, permissions } from 'config';

import { PlanningSelector, PlanningAction } from 'features/Plannings';

import EmptyState from './EmptyState';
import PhasePrompt from 'features/Phases/components/v3/Prompt';
import HolidayPrompt from 'features/Holidays/components/v3/Prompt';

import { useDeepEffect, useDelay } from 'components/v3/utils';
import EventTooltip from './EventTooltip';

const createPhase = (event) => {
  const { resource } = event;
  if (!resource || resource.id === 'none') {
    return event;
  }
  let { id: resourceId } = resource;

  switch (resource.source) {
    case 'machines':
      return {
        ...event,
        machinePhases: [
          {
            machine: pick(resource, ['displayName', 'id']),
            machineId: resourceId
          }
        ]
      };
    case 'users':
      return {
        ...event,
        users: [resource]
      };
    case 'teams':
      return {
        ...event,
        team: resource
      };
    case 'holiday':
      return event;
    case 'projects':
      return {
        ...event,
        projectId: resourceId,
        project: resource
      };
    default:
      return {};
  }
};

const eventResourceChanged = (info) => {
  let {
    newResource: {
      id: newId,
      extendedProps: { source }
    },
    oldResource: { id: oldId },
    event: { extendedProps: phase }
  } = info;

  switch (source) {
    case 'machines':
      if (newId === 'none') {
        return { machineIds: [] };
      }
      return {
        machineIds: [...(phase.machines || []).map((m) => m.id).filter((id) => oldId !== id), newId]
      };
    case 'users':
      if (newId === 'none') {
        return { userIds: null };
      }
      return {
        userIds: [...(phase.users || []).map((u) => u.id).filter((id) => oldId !== id), newId]
      };
    case 'teams':
      if (newId === 'none') {
        return { teamId: null };
      }
      return {
        teamId: newId
      };
    case 'projects':
      return {
        projectId: newId
      };
    default:
      return {};
  }
};

const Planning = ({
  currentView,
  kind = 'timeline',

  eventResourceEditable,
  showOverlap,

  onDateRangeChanged,

  phasePromptProps,
  phaseModalProps,

  ...props
}) => {
  const calendarRef = useRef();
  const calendar = calendarRef.current?.getApi();
  const dispatch = useDispatch();
  const delay = useDelay();

  const [dateRange, setDateRange] = useState();
  const [tempPhase, setTempPhase] = useState(null);
  const [tempHoliday, setTempHoliday] = useState(null);
  const { hasPermission } = useAccountConfig();

  const location = useLocation();
  const { url, path } = useRouteMatch();
  const history = useHistory();
  let projectId = undefined;
  if (location && location.search) {
    const queries = queryString.parse(location.search);
    projectId = queries.projectId;
  }

  const canEdit = hasPermission('phase.create');

  const overlapsToUpdate = useSelector(PlanningSelector.selectOverlapsToUpdate, isEqual);

  useDeepEffect(() => {
    if (!dateRange || !onDateRangeChanged) {
      return;
    }
    onDateRangeChanged(dateRange);
  }, [dateRange, onDateRangeChanged]);

  const handleEventSelect = useCallback(
    (newEvent) => {
      if (!canEdit) {
        return;
      }
      switch (newEvent.resource?.id) {
        case 'holiday':
          setTempHoliday(newEvent);
          break;
        default:
          setTempPhase({
            ...newEvent,
            ...createPhase(newEvent),
            title: '',
            administrative: false,
            projectId: projectId || undefined
          });
      }
    },
    [projectId, canEdit]
  );

  const handleEventClick = useCallback(
    ({ event, bounds }) => {
      if (!canEdit) {
        return;
      }
      switch (event.extendedProps.source) {
        case 'phase':
          history.push(`${path}/interventions/${event.extendedProps?.uid}`);
          break;
        case 'holiday':
          setTempHoliday({
            id: event.extendedProps.uid,
            ...event.extendedProps,
            bounds
          });
          break;
        default:
          break;
      }
    },
    [canEdit, history, path]
  );

  const handleEventUpdate = useCallback(
    (info, data) => {
      let payload = data;
      if (info.newResource) {
        if (!eventResourceEditable) {
          info.revert();
          return;
        }
        if (info.newResource.id === 'holiday') {
          info.revert();
          return;
        }
        payload = {
          ...payload,
          payload: {
            ...payload.payload,
            ...eventResourceChanged(info)
          }
        };
      }
      if (info.event.extendedProps.source === 'holiday') {
        dispatch(PlanningAction.updateHoliday(payload));
      } else {
        dispatch(PlanningAction.updatePlanningPhase(payload));
      }
    },
    [dispatch, eventResourceEditable]
  );

  useEffect(() => {
    delay.cancel();
    if (!showOverlap) {
      return;
    }
    delay(() => dispatch(PlanningAction.updateOverlaps(overlapsToUpdate)), 500, 'overlapsToUpdate');
  }, [overlapsToUpdate, showOverlap, dispatch, delay]);

  const tooltipContent = useMemo(
    () =>
      ({ event }) =>
        <EventTooltip event={event} showOverlap={showOverlap} readOnly={!canEdit} />,
    [showOverlap, canEdit]
  );

  const Component = useMemo(() => {
    switch (kind) {
      case 'calendar':
        return RawCalendar;
      default:
        return RawTimeline;
    }
  }, [kind]);

  const canUpdateEvent = useCallback(
    (_, e) => {
      if (e.extendedProps?.source === 'holiday') {
        return hasPermission(permissions.HOLIDAYS);
      }
      return hasPermission('phase.update', {
        target: e.extendedProps,
        attribute: 'dates'
      });
    },
    [hasPermission]
  );

  const canCreateEvent = useCallback(
    (e) => {
      if (e.resource?.extendedProps?.source === 'holiday') {
        return hasPermission(permissions.HOLIDAYS);
      }
      return hasPermission('phase.create', {
        target: e.resource?.extendedProps
      });
    },
    [hasPermission]
  );

  if (!currentView || !currentView.name) {
    return <EmptyState />;
  }

  return (
    <Fragment>
      <Component
        ref={calendarRef}
        currentView={currentView}
        onDateRangeChanged={setDateRange}
        tooltipContent={tooltipContent}
        onEventSelect={handleEventSelect}
        onEventClick={handleEventClick}
        onEventUpdate={handleEventUpdate}
        readOnly={!canEdit}
        eventAllow={canUpdateEvent}
        selectAllow={canCreateEvent}
        {...props}
      />
      {tempPhase && (
        <PhasePrompt
          elementRef={() => document.getElementsByClassName('fc-highlight')[0]}
          top={5}
          minWidth={360}
          projectId={projectId}
          phase={tempPhase}
          onRequestClose={() => {
            if (calendar) {
              calendar.unselect();
            }
            setTempPhase(null);
            history.replace({
              pathname: path,
              search: location.search
            });
          }}
          {...(phasePromptProps || {})}
        />
      )}
      <Permission
        permission={'phase.update'}
        path={[`${path}/interventions/nouvelle-intervention`, `${path}/interventions/:phaseId`]}
      >
        <PhaseModal
          parentSelector={document.fullscreenElement ? () => document.querySelector('.fc-media-screen') : undefined}
          onRequestClose={() => {
            if (location.key) {
              history.goBack();
            } else {
              history.replace(url);
            }
          }}
          {...(phasePromptProps || {})}
          {...(phaseModalProps || {})}
        />
      </Permission>
      {tempHoliday && (
        <HolidayPrompt
          holiday={tempHoliday}
          bounds={tempHoliday.bounds}
          elementRef={tempHoliday.bounds ? null : () => document.getElementsByClassName('fc-highlight')[0]}
          showUserSelect
          top={5}
          minWidth={360}
          onRequestClose={() => {
            if (calendar) {
              calendar.unselect();
            }
            setTempHoliday(null);
          }}
        />
      )}
    </Fragment>
  );
};

export default Planning;

import React, { useCallback, useRef, useMemo, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { v4 } from 'uuid';
import { MdAdd } from 'react-icons/md';
import Table from 'components/v3/List/Table';
import { InputLayoutVariant } from 'components/v3/InputLayout';
import {
  RowSection,
  CellsWrapper as RawCellsWrapper,
  RowActionWrapper,
  RowColumnRenderer
} from 'components/v3/List/Table';
import { Row } from 'components/v3/List/row';
import { CurrentOption } from 'components/v3/Select/Select';
import { DashContainer, Button, Dashed, DebouncedInput, InputArea } from 'components/v3/ui';
import { ServiceSelect, UnitSelect, TaxSelect } from 'features/Accounting';
import isSameIds from 'utils/idUtils';
import { useDispatch } from 'react-redux';
import { AccountingAction } from '../data';
import arrayMove from 'array-move';

const TaxWrapper = styled.div`
  * > ${CurrentOption} {
    > * {
      width: 100%;
    }
  }
`;

const CellsWrapper = styled(RawCellsWrapper)`
  align-items: start;
  * > * {
    font-weight: 600;
    font-size: 0.875rem !important;
  }
`;

const SimpleInput = styled.input`
  min-height: 30px;
`.withComponent(DebouncedInput);

const NoteArea = styled(Dashed)`
  margin: 4px;
  margin-top: ${({ divider }) => (divider ? '4px' : '-4px')};
  width: calc(100% - 4px) !important;

  ${({ divider }) =>
    divider &&
    css`
      textarea {
        font-weight: 600;
      }
    `}

  textarea {
    min-height: 30px;
    font-size: 0.875rem !important;
    padding: 6px 4px 6px 4px;
  }
`;

const RowWrapper = styled.div`
  width: 100%;

  * > ${Dashed} {
    width: 100%;
    min-height: 30px;
  }

  > input,
  > textarea,
  * > input,
  * > textarea {
    padding-left: 4px;
    padding-right: 4px;
    padding-top: 6px;
    padding-bottom 6px;
    font-size: 0.875rem !important;
  }

  * > label {
    left: 3px;
    font-size: 0.875rem !important;
  }
  * > ${RowSection} {
    padding: 4px 4px;
  }
  
  * > *:not(textarea) {
    min-height: unset !important;
  }

  * > textarea {
    min-height: 30px !important;
  }

  * > ${CurrentOption} {
    padding-left: 4px;
    padding-right: 4px;
    padding-top: 6px;
    padding-bottom 6px;
  }

  ${({ divider }) =>
    divider &&
    css`
      background-color: #f2f2f2;
    `}

  ${({ divider, hasDescription }) =>
    !divider &&
    !hasDescription &&
    css`
      // :not(:focus-within):not(:hover),
      // &[readOnly] {
      //   > ${NoteArea} {
      //     border: none !important;
      //     textarea {
      //       height: 0px !important;
      //       padding-top: 0px;
      //       padding-bottom: 0px;
      //       min-height: 0px !important;
      //     }
      //   }
      // }
    `}
`;

const InvoiceLinesContainer = styled(DashContainer)`
  border: ${({ theme }) => theme.borderInput};
  border-top-left-radius: ${({ theme }) => theme.borderInputRadius};
  border-top-right-radius: ${({ theme }) => theme.borderInputRadius};
  overflow: hidden;
  min-height: 100px;

  * > ${RowActionWrapper} {
    align-self: center;
  }

  * > ${Row} {
    align-items: start;
  }
`;

const AddLineButton = styled.div`
  align-self: flex-end;
`.withComponent(Button);

const CustomRowRenderer = ({ columns, item, onChange, readOnly, ...rest }) => {
  const isDivider = item.kind === 'divider' ? 'true' : undefined;
  const noteAreaRef = useRef();
  return (
    <RowWrapper divider={isDivider} hasDescription={Boolean(item.description)} readOnly={readOnly} {...rest}>
      {!isDivider && (
        <CellsWrapper>
          <RowColumnRenderer columns={columns} item={item} onChange={onChange} readOnly={readOnly} />
        </CellsWrapper>
      )}
      <NoteArea divider={isDivider}>
        <InputArea
          inputRef={noteAreaRef}
          readOnly={readOnly}
          placeholder={isDivider ? 'Nom de la séparation' : 'Description'}
          label={isDivider ? 'Nom de la séparation' : 'Description'}
          value={item.description || ''}
          onChange={(e) => {
            onChange({
              ...item,
              description: e.target.value
            });
          }}
        />
      </NoteArea>
    </RowWrapper>
  );
};

const createSeparator = (position) => ({
  id: v4(),
  isNew: true,
  kind: 'divider',
  description: '',
  position
});
const createLine = (position, group, initialData = {}) => ({
  serviceId: null,
  displayName: '',
  description: '',
  quantity: 1,
  unit: null,
  unitPrice: 0,
  total: 0,
  taxRate: group?.displayName === 'Bâtiment' ? 0.1 : 0.2,
  kind: 'financial',
  ...initialData,
  id: v4(),
  isNew: true,
  position
});

const Lines = ({ projectKindId, group, groupId, invoiceLines, readOnly, setFieldValue }) => {
  const listRef = useRef();
  const dispatch = useDispatch();
  console.log('Lines');

  const handleChange = useCallback(
    (values) => {
      setFieldValue('invoiceLines', values);
    },
    [setFieldValue]
  );

  useEffect(() => {
    dispatch(
      AccountingAction.getUnits({
        page: 1,
        per_page: 500
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (!projectKindId) return;
    dispatch(
      AccountingAction.getServices({
        page: 1,
        sort: 'display_name',
        without_or_within_groups: [groupId],
        project_kind_id_eq_or_project_kind_id_null: projectKindId
      })
    );
  }, [dispatch, groupId, projectKindId]);

  const handleAddEmptyLine = () => {
    setFieldValue('invoiceLines', [...invoiceLines, createLine(invoiceLines.length + 1, group)]);
  };

  const handleAddEmptyDivider = () => {
    setFieldValue('invoiceLines', [...invoiceLines, createSeparator(invoiceLines.length + 1)]);
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Service',
        accessor: 'group',
        width: '48%',
        dashed: true,
        isEditable: true,
        Cell: ({ item, onChange, readOnly }) => (
          <ServiceSelect
            value={{
              id: item.serviceId || item.service?.id,
              displayName: item.displayName,
              price: item.unitPrice || 0,
              taxRate: item.taxRate,
              description: item.description,
              unit: item.unit?.displayName || '',
              unitId: item.unit?.id || ''
            }}
            variant={InputLayoutVariant.flat.medium}
            required
            fillWidth
            freeSolo
            fetchOnMount={false}
            textArea
            groupId={groupId}
            projectKindId={projectKindId}
            readOnly={readOnly}
            isClearable={false}
            optionEditable={false}
            inputCreatable={false}
            onInputChange={(displayName) => {
              if (item.displayName !== displayName) {
                onChange({
                  ...item,
                  displayName
                });
              }
            }}
            onChange={(service) => {
              onChange({
                ...item,
                service: undefined,
                displayName: service?.reference
                  ? `${service.reference} - ${service.displayName}`
                  : service?.displayName,
                serviceId: service?.id,
                unitId: service?.unit?.id || '',
                unit: service?.unit?.displayName || '',
                unitPrice:
                  parseFloat(
                    service?.groupServices?.find((gs) => isSameIds(gs.groupId, groupId))?.price || service?.price
                  ) || 0,
                taxRate: parseFloat(service?.taxRate) || 0,
                description: service?.description,
                quantity: item.quantity != null ? item.quantity : 1
              });
            }}
          />
        )
      },
      {
        Header: 'Quantité',
        accessor: 'quantity',
        width: '8%',
        dashed: true,
        isEditable: true,
        textAlign: 'end',
        Cell: ({ item, onChange, readOnly }) => (
          <SimpleInput
            variant={InputLayoutVariant.flat.medium}
            number
            value={item.quantity != null ? item.quantity : 1}
            fillWidth
            readOnly={readOnly}
            label=""
            textAlign="end"
            onChange={(quantity) => {
              onChange({
                ...item,
                quantity
              });
            }}
          />
        )
      },
      {
        Header: 'Unité',
        accessor: 'unit',
        width: '12%',
        dashed: true,
        isEditable: true,
        Cell: ({ item, onChange, readOnly }) => (
          <UnitSelect
            variant={InputLayoutVariant.flat.medium}
            currency
            isClearable
            optionEditable={false}
            fetchOnMount={false}
            value={
              item.unit
                ? {
                    id: item.unitId,
                    displayName: item.unit
                  }
                : null
            }
            readOnly={readOnly}
            fillWidth
            label=""
            onChange={(unit) =>
              onChange({
                ...item,
                unit: unit?.displayName || '',
                unitId: unit?.id || null
              })
            }
          />
        )
      },
      {
        Header: 'P.U. HT',
        accessor: 'unitPrice',
        width: '12%',
        dashed: true,
        isEditable: true,
        textAlign: 'end',
        Cell: ({ item, onChange, readOnly }) => (
          <SimpleInput
            variant={InputLayoutVariant.flat.medium}
            currency
            value={item.unitPrice}
            fillWidth
            textAlign="end"
            readOnly={readOnly}
            label=""
            onChange={(unitPrice) => {
              onChange({
                ...item,
                unitPrice
              });
            }}
          />
        )
      },
      {
        Header: 'Total HT',
        accessor: 'total',
        width: '12%',
        textAlign: 'end',
        Cell: ({ item }) => (
          <SimpleInput
            variant={InputLayoutVariant.flat.medium}
            currency
            value={item.total}
            fillWidth
            readOnly
            disableEvents
            textAlign="end"
            label=""
          />
        )
      },
      {
        Header: 'TVA',
        accessor: 'taxRate',
        width: '8%',
        dashed: true,
        isEditable: true,
        textAlign: 'end',
        Cell: ({ item, onChange, readOnly }) => (
          <TaxWrapper>
            <TaxSelect
              variant={InputLayoutVariant.flat.medium}
              value={item.taxRate}
              fillWidth
              isClearable={false}
              optionEditable={false}
              label=""
              readOnly={readOnly}
              onChange={(taxRate) =>
                onChange({
                  ...item,
                  taxRate
                })
              }
            />
          </TaxWrapper>
        )
      }
    ],
    [groupId, projectKindId]
  );

  const menuOptions = useMemo(() => {
    const insert = (line, index) => {
      const lines = [...invoiceLines];

      lines.splice(index, 0, line);

      const newItems = arrayMove(lines, index, index).map((i, index) => ({
        ...i,
        position: index
      }));
      handleChange(newItems, newItems[index]);
    };

    return [
      {
        label: 'Dupliquer',
        onClick: ({ index, item, ...props }) => {
          insert(createLine(index + 1, group, item), index + 1);
        }
      },
      {
        label: 'Insérer une ligne au dessus',
        onClick: ({ index }) => {
          insert(createLine(index, group), index);
        }
      },
      {
        label: 'Insérer une ligne en dessous',
        onClick: ({ index }) => {
          insert(createLine(index + 1, group), index + 1);
        }
      },
      {
        label: 'Insérer un séparateur au dessus',
        onClick: ({ index }) => {
          insert(createSeparator(index, group), index);
        }
      },
      {
        label: 'Insérer un séparateur en dessous',
        onClick: ({ index }) => {
          insert(createSeparator(index + 1, group), index + 1);
        }
      }
    ];
  }, [invoiceLines, handleChange, group]);

  const lines = useMemo(() => {
    let previousSection = -1;
    const lines = invoiceLines.map((line) => {
      if (line.kind !== 'divider') {
        return {
          ...line,
          total: parseFloat(line.unitPrice || 0) * parseFloat(line.quantity || 0)
        };
      } else {
        return line;
      }
    });
    lines.forEach((line, index) => {
      if (line.kind === 'divider') {
        if (previousSection !== -1 && previousSection !== index - 1) {
          lines[previousSection] = {
            ...lines[previousSection],
            total: lines.slice(previousSection + 1, index - 1).reduce((acc, { total }) => acc + total, 0)
          };
        }
        previousSection = index;
      }
    });
    return lines;
  }, [invoiceLines]);

  return (
    <div>
      <InvoiceLinesContainer readOnly={readOnly} fillWidth>
        <Table
          listRef={listRef}
          columns={columns}
          items={lines}
          readOnly={readOnly}
          hasDeleteConfirmation={false}
          headerVariant="primary"
          onChange={handleChange}
          isDeletable
          rowRenderer={CustomRowRenderer}
          useWindow={false}
          isSortable
          onSortChanged={handleChange}
          menuOptions={menuOptions}
        />
      </InvoiceLinesContainer>
      {!readOnly && (
        <AddLineButton
          // disabled={
          //   invoiceLines?.filter(
          //     s => s.kind === 'financial' && !s._destroy && !s.displayName
          //   )?.length > 0
          // }
          variant="text"
          iconLeading={MdAdd}
          onClick={handleAddEmptyLine}
        >
          Ajouter une ligne
        </AddLineButton>
      )}
      {!readOnly && (
        <AddLineButton variant="text" iconLeading={MdAdd} onClick={handleAddEmptyDivider}>
          Ajouter un séparateur
        </AddLineButton>
      )}
    </div>
  );
};

export default Lines;

import styled, { css } from 'styled-components';

export const ListItem = styled.li`
  list-style-type: none;
`;

export const List = styled.ul.attrs(({ minHeight }) => ({
  style: {
    minHeight
  }
}))`
  list-style-type: none;
  margin: 0;
  padding-left: 0;
  width: 100%;

  ${({ spacing }) =>
    spacing &&
    css`
      > ${ListItem}:not(:first-child) {
        margin-top: ${spacing / 2}px;
        margin-bottom: ${spacing / 2}px;
      }
    `}
`;

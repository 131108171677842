import { get, put, post, destroy } from 'common/api';

export const getCarriers = config => get('/v5/carriers', config);

export const getCarrierOrdersExportHistories = params =>
  get('/v5/carrier_orders_export_histories', params);

export const createCarrier = (carrier, config) =>
  post('/v5/carriers', { carrier }, config);

export const getCarrier = (id, config) => get(`/v5/carriers/${id}`, config);

export const putCarrier = (id, carrier, config) =>
  put(`/v5/carriers/${id}`, { carrier }, config);

export const deleteCarrier = (id, config) =>
  destroy(`/v5/carriers/${id}`, config);

export const getCarrierOrders = params =>
  get('/v5/carrier_orders?per_page=500', params);

export const createCarrierOrder = (carrierOrder, config) =>
  post('/v5/carrier_orders', { carrierOrder }, config);

export const getCarrierOrder = (id, config) =>
  get(`/v5/carrier_orders/${id}`, config);

export const putCarrierOrder = (id, carrierOrder, config) =>
  put(`/v5/carrier_orders/${id}`, { carrierOrder }, config);

export const carrierOrderToPdf = (id, carrierOrder, config) =>
  post(`/v5/carrier_orders/${id}/to_pdf`, { carrierOrder }, config);

export const getProductStats = params =>
  get('/v5/carrier_orders/stats', params);

export const getCarriageTypes = config => get('/v5/carriage_types', config);

export const createCarriageType = (carriageType, config) =>
  post('/v5/carriage_types', { carriageType }, config);

export const getCarriageType = (id, config) =>
  get(`/v5/carriage_types/${id}`, config);

export const putCarriageType = (id, carriageType, config) =>
  put(`/v5/carriage_types/${id}`, { carriageType }, config);

export const deleteCarriageType = (id, config) =>
  destroy(`/v5/carriage_types/${id}`, config);

import React, { useMemo, useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import {
  isWithinInterval,
  startOfWeek,
  setDay,
  isSameWeek,
  isBefore,
  endOfWeek,
  isAfter,
  startOfDay,
  differenceInDays,
  addDays,
  isSameDay
} from 'date-fns';
import { v4 } from 'uuid';
import { TextInputLayout, InputLayoutVariant } from 'components/v3/InputLayout';
import { FormTable, FormTableTitle } from 'components/v3/Form/TableField';
import DateNavigation from 'components/v3/DateNavigation';
import { Text } from 'components/v3/ui';
import { parseDate, printDate, formatISODate } from 'utils/dates';
import CarrierSelect from 'features/Carriers/components/v3/Select';
import CarriageTypeSelect from 'features/Carriers/components/v3/CarriageTypeSelect';

const Wrapper = styled.div`
  width: 100%;
  .phased {
    border-bottom: 2px solid ${({ theme }) => theme.accent};
  }
`;

const MultilineRow = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  > *:not(only-child):not(:first-child) {
    margin-top: 4px;
  }
`;

export const orderRowCreator = () => ({
  carrier: null,
  isNew: true,
  id: v4()
});

const isRowValid = row => Boolean(row.carrier);

const getQuantityColumn = (header, dates, week, weekDay) => {
  const date = startOfDay(
    setDay(week, weekDay, {
      weekStartsOn: 1
    })
  );
  const accessor = formatISODate(date);
  let isWithinPhaseRange = false;
  if (dates) {
    const { startAt: start, endAt: end } = dates;
    isWithinPhaseRange = start && end;
    if (isWithinInterval) {
      try {
        isWithinPhaseRange = isWithinInterval(date, { start, end });
      } catch (e) {
        isWithinPhaseRange = false;
      }
    }
  }
  return {
    Header: `${header} ${printDate(date, 'dd')}`,
    accessor,
    className: isWithinPhaseRange ? 'phased' : null,
    width: `${50 / 7}%`,
    minWidth: dates ? undefined : 50,
    dashed: true,
    isEditable: true,
    tooltip: isWithinPhaseRange ? "L'intervention se déroule ce jour" : null,
    Cell: ({ item, onChange }) => {
      let day = (item?.carrierOrderDays || {})[accessor];
      let safeAccessor = accessor;
      if (!day) {
        const dayKey = Object.keys(item?.carrierOrderDays || {}).find(key =>
          isSameDay(parseDate(key), date)
        );
        if (dayKey) {
          day = item.carrierOrderDays[dayKey];
          safeAccessor = dayKey;
        }
      }
      if (!day) {
        day = {};
      }
      const currentValue =
        day.value == null || day.value === '' ? null : day.value;
      return (
        <TextInputLayout
          variant={InputLayoutVariant.flat.medium}
          number
          decimalScale={0}
          value={currentValue}
          fillWidth
          onChange={value =>
            onChange({
              ...item,
              carrierOrderDays: {
                ...item.carrierOrderDays,
                [safeAccessor]: {
                  ...day,
                  value: value == null || value === '' ? null : value
                }
              }
            })
          }
        />
      );
    }
  };
};

export const COLUMNS = (phaseDates, week) => [
  {
    Header: 'Type de camion',
    accessor: 'carriageType',
    width: '25%',
    dashed: true,
    isEditable: true,
    Cell: ({ item, onChange }) => (
      <CarriageTypeSelect
        variant={InputLayoutVariant.flat.medium}
        value={item.carriageType}
        fillWidth
        label="Type de camion"
        onChange={carriageType =>
          onChange({
            ...item,
            carriageType,
            carriageTypeId: carriageType?.id || null
          })
        }
      />
    )
  },
  {
    Header: 'Transporteur',
    accessor: 'carrier',
    width: '25%',
    dashed: true,
    isEditable: true,
    Cell: ({ item, onChange }) => (
      <CarrierSelect
        variant={InputLayoutVariant.flat.medium}
        value={item.carrier}
        fillWidth
        label="Transporteur"
        onChange={carrier =>
          onChange({
            ...item,
            carrier,
            carrierId: carrier?.id || null
          })
        }
      />
    )
  },
  getQuantityColumn('Lun', phaseDates, week, 1),
  getQuantityColumn('Mar', phaseDates, week, 2),
  getQuantityColumn('Mer', phaseDates, week, 3),
  getQuantityColumn('Jeu', phaseDates, week, 4),
  getQuantityColumn('Ven', phaseDates, week, 5),
  getQuantityColumn('Sam', phaseDates, week, 6),
  getQuantityColumn('Dim', phaseDates, week, 0)
];

const DeliveryTable = ({
  id,
  startAt,
  endAt,
  carrierOrders,
  onChange,
  globalView = false,
  readOnly,
  ...props
}) => {
  const [currentWeek, setCurrentWeek] = useState(new Date());

  const filteredCarrierOrders = useMemo(() => {
    return (carrierOrders || []).map(carrierOrder => {
      const { carrierOrderDays } = carrierOrder;
      if (Array.isArray(carrierOrderDays)) {
        return {
          ...carrierOrder,
          carrierOrderDays: carrierOrderDays.reduce((acc, { date, ...day }) => {
            acc[date] = day;
            return acc;
          }, {})
        };
      } else {
        return carrierOrder;
      }
    });
  }, [carrierOrders]);

  const dates = useMemo(
    () => ({
      startAt: startAt ? parseDate(startAt) : null,
      endAt: endAt ? parseDate(endAt) : null
    }),
    [startAt, endAt]
  );

  const columns = useMemo(
    () =>
      globalView
        ? [
            {
              Header: 'Chantier',
              accessor: 'project.displayName',
              width: '25%',
              minWidth: 100,
              Cell: ({ item }) => (
                <MultilineRow>
                  <Text variant="small">{item.project?.displayName}</Text>
                  <Text variant="caption" color="theme">
                    {item.phase?.displayName}
                  </Text>
                </MultilineRow>
              )
            },
            ...COLUMNS(dates, currentWeek)
          ]
        : COLUMNS(dates, currentWeek),
    [dates, currentWeek, globalView]
  );

  useEffect(() => {
    const { startAt, endAt } = dates;
    if (!startAt) {
      return;
    }
    const now = new Date();
    if (
      isBefore(now, startAt) ||
      isSameWeek(now, startAt, { weekStartsOn: 1 }) ||
      !endAt
    ) {
      setCurrentWeek(startOfWeek(dates.startAt, { weekStartsOn: 1 }));
    } else if (
      isBefore(dates.endAt, now) ||
      isSameWeek(now, endAt, { weekStartsOn: 1 })
    ) {
      setCurrentWeek(startOfWeek(dates.endAt, { weekStartsOn: 1 }));
    } else {
      setCurrentWeek(startOfWeek(now, { weekStartsOn: 1 }));
    }
  }, [dates]);

  const rowCreator = useCallback(() => {
    // on init les orderDays en attendant le fix du bug de l'issue 199
    let orderDays = [];
    try {
      let start = startOfDay(parseDate(startAt));
      let end = startOfDay(parseDate(endAt));
      orderDays = Array(1 + differenceInDays(end, start))
        .fill()
        .map((_, index) => {
          return {
            date: formatISODate(addDays(start, index)),
            value: null
          };
        });
    } catch (e) {
      console.warn(e);
    }
    return {
      ...orderRowCreator(),
      phaseId: id,
      orderDays
    };
  }, [id, startAt, endAt]);

  const hasDataBefore = useMemo(() => {
    return (
      !globalView &&
      filteredCarrierOrders.find(order => {
        return Boolean(
          Object.keys(order.orderDays || {}).find(day => {
            if (isBefore(parseDate(day), currentWeek)) {
              return parseFloat(order.orderDays[day]?.value) > 0;
            } else {
              return false;
            }
          })
        );
      })
    );
  }, [globalView, filteredCarrierOrders, currentWeek]);

  const hasDataAfter = useMemo(() => {
    const endWeek = endOfWeek(currentWeek);
    return (
      !globalView &&
      filteredCarrierOrders.find(order => {
        return Boolean(
          Object.keys(order.orderDays || {}).find(day => {
            if (isAfter(parseDate(day), endWeek)) {
              return parseFloat(order.orderDays[day]?.value) > 0;
            } else {
              return false;
            }
          })
        );
      })
    );
  }, [globalView, filteredCarrierOrders, currentWeek]);

  return (
    <Wrapper>
      {!globalView && (
        <FormTableTitle
          label="Commandes transporteurs"
          asSection
          items={carrierOrders}
          onAddRow={() =>
            onChange([
              ...(carrierOrders || []),
              {
                ...rowCreator()
              }
            ])
          }
          readOnly={readOnly}
        >
          <DateNavigation
            leftActionTooltip=""
            rightActionTooltip=""
            dateIncrement={{
              weeks: 1
            }}
            hintLeftVisible={hasDataBefore}
            hintLeftTooltip="Une commande est prévue"
            hintRightVisible={hasDataAfter}
            hintRightTooltip="Une commande est prévue"
            date={currentWeek}
            dateTodayHidden
            dateAlignment="week"
            onDateChanged={date => {
              setCurrentWeek(parseDate(date));
            }}
            datePickerProps={{
              value: formatISODate(currentWeek),
              asWeekSelect: true,
              fitContent: true,
              isClearable: false
            }}
          />
        </FormTableTitle>
      )}
      <FormTable
        {...props}
        columns={columns}
        useWindow={false}
        rowWrap={false}
        value={filteredCarrierOrders}
        onChange={onChange}
        minHeight={50}
        isRowValid={isRowValid}
        readOnly={readOnly}
      />
    </Wrapper>
  );
};

export default DeliveryTable;

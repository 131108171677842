import React, { useCallback, useMemo, Fragment } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import isEqual from 'lodash/isEqual';
import * as Yup from 'yup';
import { EntityModal } from 'components/v3/Modal';
import { TextInputField, ERRORS } from 'components/v3/Form';
import {
  TextInputLayout,
  InputLayoutVariant,
  SelectInputLayout
} from 'components/v3/InputLayout';
import Table from 'components/v3/List/Table';
import { StaticMap } from 'components/v3/Map';
import { Grid, GridItem, DashContainer, Text, Dashed } from 'components/v3/ui';
import {
  PmvSelector,
  PmvAction,
  pmvAsValues,
  PMV_LINE_STYLES
} from 'features/Pmvs';
import { sortBy } from 'lodash';

const LINES_COLUMNS = [
  {
    Header: ' ',
    accessor: 'number',
    width: 24,
    minWidth: 24,
    Cell: ({ item }) => <Text variant="small">{item.number}</Text>
  },
  {
    Header: 'Message',
    accessor: 'text',
    width: 'calc(70% - 56px)',
    dashed: true,
    isEditable: true,
    Cell: ({ item, onChange }) => (
      <TextInputLayout
        variant={InputLayoutVariant.flat.medium}
        label="Message"
        value={item.text}
        fillWidth
        onChange={text =>
          onChange({
            ...item,
            text
          })
        }
      />
    )
  },
  {
    Header: 'Style',
    accessor: 'style',
    width: '30%',
    dashed: true,
    isEditable: true,
    Cell: ({ item, onChange }) => (
      <SelectInputLayout
        label="Style"
        variant={InputLayoutVariant.flat.medium}
        options={PMV_LINE_STYLES}
        mapOptionValue
        isClearable={false}
        value={item.style}
        fillWidth
        onChange={style =>
          onChange({
            ...item,
            style
          })
        }
      />
    )
  }
];

const LinesLabel = styled(Text)`
  padding-left: 16px;
  padding-top: 6px;
  padding-right: 16px;
  padding-bottom: 6px;
`;

const LinesGridItem = styled(GridItem)`
  display: flex;
  flex-direction: column;

  &:focus,
  &:focus-within {
    ${LinesLabel} {
      color: ${({ theme }) => theme.primaryLight};
    }
  }
`;

const LinesContainer = styled(DashContainer)`
  border: ${({ theme }) => theme.borderInput};
  border-top-left-radius: ${({ theme }) => theme.borderInputRadius};
  border-top-right-radius: ${({ theme }) => theme.borderInputRadius};
  overflow: hidden;
  max-height: 500px;

  * > input,
  * > textarea {
    padding-left: 8px;
    padding-right: 8px;
    font-size: 1rem;
  }
  * > label {
    left: 3px;
    font-size: 0.875rem;
  }ont-size: 0.75rem;
  }
  * > ${Dashed} {
    margin-left: -6px;
    width: calc(100% + 6px);
  }
`;

const validationSchema = Yup.object().shape({
  address: Yup.string().required(ERRORS.REQUIRED),
  ip: Yup.string().required(ERRORS.REQUIRED)
});

const PmvModal = ({
  onRequestClose,
  onSubmitSuccess,
  pmv: propsPmv,
  ...props
}) => {
  const dispatch = useDispatch();
  const { params } = useRouteMatch();
  let { pmvId } = params;
  if (propsPmv) {
    pmvId = propsPmv.id;
  }
  const isNew = !Boolean(pmvId);

  const selectPmv = useMemo(
    () => state => PmvSelector.selectPmv(state, { pmvId }),
    [pmvId]
  );

  let pmv = useSelector(selectPmv, isEqual);
  if (!pmv) {
    pmv = propsPmv;
  }

  const createRequest = useCallback(
    payload => dispatch(PmvAction.createPmv({ payload })),
    [dispatch]
  );

  const fetchRequest = useCallback(
    () => dispatch(PmvAction.getPmv({ id: pmvId })),
    [pmvId, dispatch]
  );

  const updateRequest = useCallback(
    (id, payload) => dispatch(PmvAction.updatePmv({ id, payload })),
    [dispatch]
  );

  const deleteRequest = useCallback(
    id => dispatch(PmvAction.deletePmv({ id })),
    [dispatch]
  );

  return (
    <EntityModal
      isOpen
      onRequestClose={onRequestClose}
      title={isNew ? 'Nouveau panneau' : 'Édition du panneau'}
      id={pmvId}
      model="pmv"
      entity={pmv}
      validationSchema={validationSchema}
      entityAsValue={pmvAsValues}
      fetchRequest={fetchRequest}
      createRequest={createRequest}
      updateRequest={updateRequest}
      deleteRequest={deleteRequest}
      onSubmitSuccess={onSubmitSuccess}
      autoSubmit={false}
      {...props}
    >
      {({ values, setFieldValue }) => (
        <Grid spacing={16} padded={false}>
          <GridItem width="30%">
            <TextInputField name="address" label="Adresse" required />
          </GridItem>
          <GridItem width="70%">
            <TextInputField name="ip" label="Ip" required />
          </GridItem>
          {values.id && (
            <Fragment>
              <LinesGridItem width="100%">
                <LinesLabel color="secondary" weight="semiBold">
                  Lignes
                </LinesLabel>
                <LinesContainer readOnly={false} fillWidth>
                  <Table
                    columns={LINES_COLUMNS}
                    items={values.lines || []}
                    readOnly={false}
                    headerVariant="dark"
                    onChange={lines =>
                      setFieldValue('lines', sortBy(lines, 'number'))
                    }
                    useWindow={false}
                  />
                </LinesContainer>
              </LinesGridItem>
              <GridItem width="100%">
                <StaticMap
                  useAccountAddressIfEmpty={false}
                  address={pmv?.position}
                  height={200}
                />
              </GridItem>
            </Fragment>
          )}
        </Grid>
      )}
    </EntityModal>
  );
};

export default PmvModal;

import { get, put, post, destroy } from 'common/api';

export const getGroups = params => get('/v5/groups', params);

export const createGroup = (group, config) =>
  post('/v5/groups', { group }, config);

export const getGroup = (id, config) => get(`/v5/groups/${id}`, config);

export const putGroup = (id, group, config) =>
  put(`/v5/groups/${id}`, { group }, config);

export const deleteGroup = (id, config) => destroy(`/v5/groups/${id}`, config);

import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { GrDocumentCsv } from 'react-icons/gr';
import { Button } from 'components/v3/ui';
import { API_BASE } from '../../../constants';
import { authenticateUrl } from 'utils';
import Menu, { MenuItem } from 'components/v3/Menu';
import { useAccountConfig, features, permissions } from 'config';

export const ExportGroupMaterialsMenu = ({ groupId, ...rest }) => {
  const { hasFeature, hasPermission } = useAccountConfig();
  const years = useMemo(() => {
    const year = new Date().getFullYear();
    return Array.from(new Array(20), (_, index) => year - index).filter(
      v => v > 2020
    );
  }, []);

  if (
    !groupId ||
    !hasFeature(features.MATERIALS) ||
    !hasPermission(permissions.COMPANY_DATA)
  )
    return null;

  return (
    <Menu {...rest}>
      <Button small variant="outline" icon={GrDocumentCsv}>
        Exporter les entrants/sortants
      </Button>
      {years.map(year => (
        <MenuItem
          target="_blank"
          key={year}
          as={Link}
          to={{
            pathname: authenticateUrl(
              `${API_BASE}/v5/groups/${groupId}/materials_csv?year=${year}`
            )
          }}
        >
          {year}
        </MenuItem>
      ))}
    </Menu>
  );
};

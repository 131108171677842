import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { entries, sumBy } from 'lodash';
import { HolidaySelector } from 'features/Holidays';
import { asNumber } from 'utils';
import { parseDate } from 'utils/dates';

const Table = styled.table`
  margin-left: auto;
  table-layout: fixed;
  border-collapse: collapse;
  color: ${({ theme }) => theme.textDark};
  td {
    border: 1px solid ${({ theme }) => theme.separator};
    font-size: 0.875rem;
    text-align: center;
    padding: 4px 8px;
    min-width: 50px;
  }
  thead {
    font-weight: 600;
    color: ${({ theme }) => theme.textDarkSecondary};
  }
`;

export const WorkRecordRecap = ({ formik, year, month }) => {
  const holidayKinds = useSelector(HolidaySelector.selectHolidayKinds);
  const days = useMemo(() => {
    const yearMonth = `${year}-${String(month + 1).padStart(2, '0')}`;
    return entries(formik.values)
      .map(([day, values]) => {
        if (day.startsWith(yearMonth)) {
          return {
            day: parseDate(day, 'yyyy-MM-dd'),
            ...values
          };
        }
        return undefined;
      })
      .filter(Boolean);
  }, [formik.values, year, month]);

  return (
    <Table>
      <tbody>
        <tr>
          <td colSpan="4">Heures standard travaillées</td>
          <td colSpan="2">
            {sumBy(days, (dayValue) => {
              let count = 0;
              if (dayValue.morning?.kind === 'work') {
                count += asNumber(dayValue.morning.duration) || 0;
              }
              if (dayValue.afternoon?.kind === 'work') {
                count += asNumber(dayValue.afternoon.duration) || 0;
              }
              return count;
            })}
          </td>
        </tr>
        <tr>
          <td colSpan="4">Heures supplémentaires</td>
          <td colSpan="2">
            {sumBy(days, (dayValue) => {
              let count = 0;
              if (dayValue.overtime?.kind === 'work') {
                count += asNumber(dayValue.overtime.duration) || 0;
              }
              return count;
            })}
          </td>
        </tr>
        <tr>
          {holidayKinds.map(({ abbr }) => (
            <td colSpan="1" key={abbr}>
              {abbr}
            </td>
          ))}
        </tr>
        <tr>
          {holidayKinds.map(({ value }) => (
            <td key={value} colSpan="1">
              {sumBy(days, (dayValue) => {
                let count = 0;
                if (dayValue.morning?.kind === value) {
                  count += 0.5;
                }
                if (dayValue.afternoon?.kind === value) {
                  if (dayValue.day.getDay() === 4 && value === 'paid_leave') {
                    count += 1;
                  }
                  count += 0.5;
                }
                return count;
              })}
            </td>
          ))}
        </tr>
      </tbody>
    </Table>
  );
};

import React from 'react';
import { v4 } from 'uuid';
import { TextInputLayout, InputLayoutVariant } from 'components/v3/InputLayout';
import TableField from 'components/v3/Form/TableField';
import { stringToColor, getInitials } from 'utils';
import ColorPicker from 'components/v3/ColorPicker';

export const rowCreator = () => ({
  id: v4(),
  value: '',
  displayName: '',
  abbr: '',
  color: ''
});

const isRowValid = row =>
  Boolean(row.value?.trim().length) &&
  Boolean(row.displayName?.trim().length) &&
  Boolean(row.abbr?.trim().length);

export const COLUMNS = [
  {
    Header: ' ',
    accessor: 'colorPicker',
    width: 48,
    textAlign: 'center',
    Cell: ({ item, onChange }) => (
      <ColorPicker
        style={{ height: 32, width: 32 }}
        value={item.color}
        onChange={color =>
          onChange({
            ...item,
            color,
            colorInitialized: true
          })
        }
      />
    )
  },
  {
    Header: 'Value',
    accessor: 'value',
    width: '25%',
    dashed: true,
    isEditable: true,
    Cell: ({ item, onChange }) => (
      <TextInputLayout
        variant={InputLayoutVariant.flat.medium}
        label="Value"
        required
        value={item.value}
        fillWidth
        onChange={value =>
          onChange({
            ...item,
            value,
            color: item.colorInitialized ? item.color : stringToColor(value)
          })
        }
      />
    )
  },
  {
    Header: 'Nom',
    accessor: 'displayName',
    width: 'calc(50% - 48px)',
    dashed: true,
    isEditable: true,
    Cell: ({ item, onChange }) => (
      <TextInputLayout
        variant={InputLayoutVariant.flat.medium}
        label="Nom"
        required
        value={item.displayName}
        fillWidth
        onChange={displayName =>
          onChange({
            ...item,
            displayName,
            abbr: item.abbrInitialized ? item.abbr : getInitials(displayName)
          })
        }
      />
    )
  },
  {
    Header: 'Abréviation',
    accessor: 'abbr',
    width: '25%',
    dashed: true,
    isEditable: true,
    Cell: ({ item, onChange }) => (
      <TextInputLayout
        variant={InputLayoutVariant.flat.medium}
        label="Abréviation"
        fillWidth
        value={item.abbr}
        onChange={abbr =>
          onChange({
            ...item,
            abbr,
            abbrInitialized: true
          })
        }
      />
    )
  }
];

const HolidayKinds = props => {
  return (
    <TableField
      label="Types de congés"
      asSection
      columns={COLUMNS}
      rowCreator={rowCreator}
      useWindow={false}
      addRowLabel="Ajouter"
      isRowValid={isRowValid}
      minHeight={50}
      {...props}
    />
  );
};

export default HolidayKinds;

import React from 'react';
import styled from 'styled-components';
import {
  ToastContainer,
  ToastContainerProps,
  toast,
  ToastContent,
  ToastOptions
} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MdClose } from 'react-icons/md';
import { ButtonIcon, Icon } from '../ui';
import { IconSize } from '../ui/icon';
import { getErrorMessage } from 'common/api';

const StyledToastContainer = styled(ToastContainer)`
  .Toastify__toast {
    border-radius: ${({ theme }) => theme.borderInputRadius};
    border: 2px solid ${({ theme }) => theme.backgroundLight};
  }

  .Toastify__toast--default {
    border-color: ${({ theme }) => theme.primaryLight};
  }

  .Toastify__toast--info {
    background: ${({ theme }) => theme.primaryLight};
    * {
      color: ${({ theme }) => theme.textLight};
    }
  }
  .Toastify__toast--success {
    background: ${({ theme }) => theme.success};
    * {
      color: ${({ theme }) => theme.textLight};
    }
  }
  .Toastify__toast--warning {
    background: ${({ theme }) => theme.warning};
    * {
      color: ${({ theme }) => theme.textLight};
    }
  }
  .Toastify__toast--error {
    background: ${({ theme }) => theme.error};
    * {
      color: ${({ theme }) => theme.textLight};
    }
  }

  .Toastify__progress-bar {
    height: 3px;
    background: ${({ theme }) => theme.backgroundLight};
  }
  .Toastify__progress-bar--default {
    background: ${({ theme }) => theme.primaryLight};
  }
`;

const Container: React.FunctionComponent<ToastContainerProps> = props => {
  return (
    <StyledToastContainer
      position="bottom-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      closeButton={
        <ButtonIcon size="small">
          <MdClose />
        </ButtonIcon>
      }
      {...props}
    />
  );
};

const Message = ({
  icon,
  iconSize,
  text
}: {
  icon?: React.ReactNode;
  iconSize?: IconSize;
  text?: string;
}) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {icon && <Icon size={iconSize}>{icon}</Icon>}
      {text}
    </div>
  );
};

const _error = toast.error;
toast.error = (
  content: Error | ToastContent,
  options?: ToastOptions | undefined
) => {
  if (content instanceof Error) {
    return _error(getErrorMessage(content), options);
  } else {
    return _error(content, options);
  }
};

export { toast as toaster, Message, Container as ToastContainer };

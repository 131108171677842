import React from 'react';

// prettier-ignore
const path = "m392 512h-272c-66.168 0-120-53.832-120-120v-272c0-66.168 53.832-120 120-120h272c66.168 0 120 53.832 120 120v272c0 66.168-53.832 120-120 120zm-272-472c-44.112 0-80 35.888-80 80v272c0 44.112 35.888 80 80 80h272c44.112 0 80-35.888 80-80v-272c0-44.112-35.888-80-80-80zm304 216c0-11.046-8.954-20-20-20h-141c-11.046 0-20 8.954-20 20s8.954 20 20 20h141c11.046 0 20-8.954 20-20zm-70-77c0-11.046-8.954-20-20-20h-187c-11.046 0-20 8.954-20 20s8.954 20 20 20h187c11.046 0 20-8.954 20-20zm-143-78c0-11.046-8.954-20-20-20h-84c-11.046 0-20 8.954-20 20s8.954 20 20 20h84c11.046 0 20-8.954 20-20zm183 233c0-11.046-8.954-20-20-20h-167c-11.046 0-20 8.954-20 20s8.954 20 20 20h167c11.046 0 20-8.954 20-20zm27 78c0-11.046-8.954-20-20-20h-44c-11.046 0-20 8.954-20 20s8.954 20 20 20h44c11.046 0 20-8.954 20-20z"

const IcTimeline = props => (
  <svg
    viewBox="0 0 512 512"
    fill="currentColor"
    width={`${props.size || 18}px`}
    height={`${props.size || 18}px`}
  >
    <path d={path} />
  </svg>
);

export default IcTimeline;

import React from 'react';
import { MdMoreVert } from 'react-icons/md';
import { SelectInputLayout, TextInputLayout } from 'components/v3/InputLayout';
import Checkbox from 'components/v3/Checkbox';
import Menu, { MenuItem } from 'components/v3/Menu';
import Card, { CardHeader, CardContent } from 'components/v3/ui/card';
import { Grid, GridItem, ButtonIcon } from 'components/v3/ui';
import { VIEWS } from 'pages/Plannings';

const START_OPTIONS = [
  {
    value: 'days-0',
    label: 'Début de la journée',
    original: {
      days: 0
    }
  },
  {
    value: 'days-1',
    label: '1 jour avant',
    original: {
      days: 1
    }
  },
  {
    value: 'days-2',
    label: '2 jours avant',
    original: {
      days: 2
    }
  },
  {
    value: 'days-3',
    label: '3 jours avant',
    original: {
      days: 3
    }
  },
  {
    value: 'weeks-0',
    label: 'Début de la semaine',
    original: {
      weeks: 0
    }
  },
  {
    value: 'weeks-1',
    label: '1 semaine avant',
    original: {
      weeks: 1
    }
  },
  {
    value: 'weeks-2',
    label: '2 semaines avant',
    original: {
      weeks: 2
    }
  },
  {
    value: 'month-0',
    label: 'Début du mois',
    original: {
      months: 0
    }
  },
  {
    value: 'month-1',
    label: '1 mois avant',
    original: {
      months: 1
    }
  },
  {
    value: 'month-2',
    label: '2 mois avant',
    original: {
      months: 2
    }
  }
];

const DATE_INCREMENT_OPTIONS = [
  ...Array(2)
    .fill()
    .map((_, i) => ({
      value: `weeks-${i + 1}`,
      label: `${i + 1} semaine(s)`,
      original: {
        weeks: i + 1
      }
    })),
  ...Array(6)
    .fill()
    .map((_, i) => ({
      value: `months-${i + 1}`,
      label: `${i + 1} mois`,
      original: {
        months: i + 1
      }
    })),
  ...Array(1)
    .fill()
    .map((_, i) => ({
      value: `years-${i + 1}`,
      label: `${i + 1} an`,
      original: {
        years: i + 1
      }
    }))
];

const SLOT_DURATION_DEPENDENCIES = {
  '12:00:00': {
    slotLabelFormat: [
      {
        format: "MMMM '-' 'sem.' I",
        canShowDayHint: false
        // function: {
        //   arguments: 'info',
        //   body:
        //     'try {' +
        //     'const date = moment(info.date).toDate();' +
        //     "const options = { year: 'numeric', month: 'long' };" +
        //     // eslint-disable-next-line no-template-curly-in-string
        //     "return `${moment(date).startOf('isoWeek').format('[Sem.] WW')}`;" +
        //     '} catch (e) { ' +
        //     "return { week: 'short', month: 'long', year: 'numeric' };" +
        //     '}'
        // }
      },
      {
        // format: 'EEE dd',
        weekday: 'short',
        day: 'numeric',
        canShowDayHint: true
      },
      {
        function: {
          arguments: 'info',
          body: "return (info.date.hour === 0 ? 'AM' : 'PM');"
        },
        canShowDayHint: false
      }
    ]
  },
  '24:00:00': {
    slotLabelFormat: [
      {
        month: 'long',
        year: 'numeric',
        canShowDayHint: false
      },
      {
        format: "'sem.' I",
        canShowDayHint: false
      },
      {
        canShowDayHint: true,
        day: 'numeric'
      }
    ]
  },
  '168:00:00': {
    slotLabelFormat: [
      { year: 'numeric', canShowDayHint: false },
      { month: 'long', canShowDayHint: false },
      { week: 'numeric', canShowDayHint: false }
    ],
    dateAlignment: 'week'
  }
};

const PlanningCard = ({ item, onChange, onDelete }) => {
  return (
    <Card>
      <CardHeader style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Checkbox
          label="Mode par défaut"
          value={item.default === true ? true : false}
          onChange={checked => {
            onChange({
              ...item,
              default: checked
            });
          }}
        />
        <Menu>
          <ButtonIcon size="medium">
            <MdMoreVert />
          </ButtonIcon>
          <MenuItem onClick={() => onDelete(item)}>Supprimer</MenuItem>
        </Menu>
      </CardHeader>
      <CardContent>
        <Grid vertical spacing={8}>
          <GridItem width="100%">
            <SelectInputLayout
              label="Mode"
              mapOptionValue
              sortOptions={false}
              options={Object.values(VIEWS)}
              value={item?.name}
              fillWidth
              onChange={value => {
                const view = VIEWS[value];
                onChange({
                  ...view,
                  prevId: item.id,
                  id: view.name,
                  value,
                  ...SLOT_DURATION_DEPENDENCIES[view.slotLabelInterval]
                });
              }}
            />
          </GridItem>
          <GridItem width="100%">
            <SelectInputLayout
              options={START_OPTIONS}
              label="Décalage début"
              fillWidth
              sortOptions={false}
              value={START_OPTIONS.find(option => {
                const kind =
                  item.startOffset && Object.keys(item.startOffset)[0];
                return (
                  kind && option.value === `${kind}-${item.startOffset[kind]}`
                );
              })}
              onChange={startOffset =>
                onChange({
                  ...item,
                  startOffset
                })
              }
            />
          </GridItem>
          <GridItem width="100%">
            <SelectInputLayout
              label="Navigation gauche/droite"
              sortOptions={false}
              options={DATE_INCREMENT_OPTIONS}
              value={DATE_INCREMENT_OPTIONS.find(option => {
                const kind =
                  item.dateIncrement && Object.keys(item.dateIncrement)[0];
                return (
                  kind && option.value === `${kind}-${item.dateIncrement[kind]}`
                );
              })}
              fillWidth
              onChange={increment =>
                onChange({
                  ...item,
                  dateIncrement: increment,
                  dateDecrement: {
                    [Object.keys(increment)[0]]: -Object.values(increment)[0]
                  }
                })
              }
            />
          </GridItem>
          <GridItem>
            <SelectInputLayout
              label="Taille minimum de resize"
              options={[
                {
                  label: `Demie journée (journée de ${
                    parseInt(item.slotWidth || 0) * 2
                  }px)`,
                  value: '12:00:00'
                },
                {
                  label: 'Journée',
                  value: '24:00:00'
                },
                {
                  label: `Semaine (semaine de ${parseInt(
                    item.slotWidth || 0
                  )}px)`,
                  value: '168:00:00'
                }
              ]}
              mapOptionValue
              sortOptions={false}
              value={item.slotDuration}
              fillWidth
              onChange={slotDuration =>
                onChange({
                  ...item,
                  slotDuration
                })
              }
            />
          </GridItem>
          <GridItem width="100%">
            <TextInputLayout
              label="Taille du resize (px)"
              required
              number
              value={item.slotWidth}
              fillWidth
              onChange={slotWidth =>
                onChange({
                  ...item,
                  slotWidth
                })
              }
            />
          </GridItem>
          <GridItem width="100%">
            <SelectInputLayout
              label="Slot label interval"
              options={[
                {
                  label: 'Demie journée (on affiche mat. a-m)',
                  value: '12:00:00'
                },
                {
                  label: 'Journée',
                  value: '24:00:00'
                },
                {
                  label: 'Semaine (pas de jour sur le planning)',
                  value: '168:00:00'
                }
              ]}
              mapOptionValue
              sortOptions={false}
              value={item.slotLabelInterval}
              fillWidth
              hint="Périodicité du label le plus en bas du header"
              onChange={slotLabelInterval =>
                onChange({
                  ...item,
                  slotLabelInterval,
                  ...SLOT_DURATION_DEPENDENCIES[slotLabelInterval],
                  noWeekendBackground: slotLabelInterval === '168:00:00'
                })
              }
            />
          </GridItem>
          <GridItem>
            <SelectInputLayout
              label="Alignement du planning"
              options={[
                {
                  label: 'Jour (début du jour)',
                  value: 'day'
                },
                {
                  label: 'Semaine (début de semaine)',
                  value: 'week'
                },
                {
                  label: 'Mois (début du mois)',
                  value: 'month'
                }
              ]}
              error={
                item.slotLabelInterval === '168:00:00' &&
                item.dateAlignment !== 'week'
                  ? "Attention il est obligatoire de mettre l'alignement à la semaine si le Slot label interval est d'une semaine."
                  : null
              }
              mapOptionValue
              sortOptions={false}
              value={item.dateAlignment}
              fillWidth
              onChange={dateAlignment =>
                onChange({
                  ...item,
                  dateAlignment
                })
              }
            />
          </GridItem>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default PlanningCard;

import get from 'lodash/get';

export const sortString = key => (a, b) => {
  if (get(a, key) < get(b, key)) {
    return -1;
  }
  if (get(a, key) > get(b, key)) {
    return 1;
  }
  return 0;
};

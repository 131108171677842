import { useRef, useEffect, useMemo } from 'react';
import { useVirtual } from 'react-virtual';
import { omit } from 'lodash';

type MeasureCache = { [key: string]: (el: HTMLElement | null) => void };

function useVirtualList<T>(options: Parameters<typeof useVirtual>[0]) {
  const measureRefCacheRef = useRef<MeasureCache>({});
  const elCacheRef = useRef<{
    [key: string]: HTMLElement;
  }>({});

  const roRef = useRef(
    new ResizeObserver(entries => {
      entries.forEach(entry => {
        const el = entry.target;
        const index = el.getAttribute('data-index');
        if (index != null) {
          measureRefCacheRef.current[index](el as HTMLElement);
        }
      });
    })
  );

  useEffect(() => {
    const ro = roRef.current;
    return () => {
      ro.disconnect();
    };
  }, []);

  const rowVirtualizer = useVirtual<T>(options as any);

  const refs = useMemo(() => {
    const obj: MeasureCache = {};
    for (let i = 0; i < options.size; i++) {
      obj[i] = (el: HTMLElement | null) => {
        if (elCacheRef.current[i]) {
          roRef.current.unobserve(elCacheRef.current[i]);
        }

        if (el) {
          // sync
          measureRefCacheRef.current[i](el);
          el.setAttribute('data-index', String(i));
          roRef.current.observe(el);
          elCacheRef.current[i] = el;
        } else {
          elCacheRef.current = omit(elCacheRef.current, i);
        }
      };
    }
    return obj;
  }, [options.size]);

  for (let i = 0; i < rowVirtualizer.virtualItems.length; i++) {
    const item = rowVirtualizer.virtualItems[i];
    if (item.measureRef !== refs[item.index]) {
      measureRefCacheRef.current[item.index] = item.measureRef;
    }
    item.measureRef = refs[item.index];
  }

  // const measure = useCallback(
  //   index => {
  //     rowVirtualizer.virtualItems.find(item => item.index === index);
  //     if (virtualItems) return;
  //   },
  //   [rowVirtualizer.virtualItems]
  // );

  // const remeasure = useCallback(
  //   index => {
  //     rowVirtualizer.virtualItems.find(item => item.index === index);
  //     if (virtualItems) return;
  //   },
  //   [rowVirtualizer.virtualItems]
  // );
  return rowVirtualizer;
}

export default useVirtualList;

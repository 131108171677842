import { createDeepEqualSelector, getProps } from 'common/selector';
import { getState, selectRelations } from 'common/selector';

export const getExtractionDestinations = state => state.extractionDestinations;

export const selectExtractionDestinations = createDeepEqualSelector(
  [getExtractionDestinations, getState],
  ({ extractionDestinationsIds, extractionDestinationById }, state) =>
    extractionDestinationsIds
      ?.map(id =>
        selectRelations(
          'extractionDestination',
          extractionDestinationById[id],
          state
        )
      )
      .filter(Boolean) ?? []
);

export const selectExtractionDestination = createDeepEqualSelector(
  [getExtractionDestinations, getProps, getState],
  ({ extractionDestinationById }, { id }, state) =>
    selectRelations(
      'extractionDestination',
      extractionDestinationById[id],
      state
    )
);

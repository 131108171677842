import { createAction } from 'redux-actions';
import * as Api from './api';
import { getVoucherVersions } from './selector';

const prepareVoucherPayload = payload => payload;
const prepareVoucherVersionPayload = payload => payload;

const receiveCreateVoucherVersion = createAction('NEW_VOUCHER_VERSION_SUCCESS');
export const createVoucherVersion =
  ({ payload, ...config }) =>
  async dispatch => {
    const result = await Api.createVoucherVersion(
      prepareVoucherVersionPayload(payload),
      config
    );
    dispatch(receiveCreateVoucherVersion(result));
    return result;
  };

const receiveUpdateVoucher = createAction('UPDATE_VOUCHER_SUCCESS');
export const updateVoucher =
  ({ id, payload: voucher, ...config }) =>
  async dispatch => {
    const result = await Api.updateVoucher(
      id,
      prepareVoucherPayload(voucher),
      config
    );
    dispatch(receiveUpdateVoucher(result));
    return result;
  };
const receiveUpdateVoucherVersion = createAction(
  'UPDATE_VOUCHER_VERSION_SUCCESS'
);
export const updateVoucherVersion =
  ({ id, payload: voucher, ...config }) =>
  async dispatch => {
    const result = await Api.updateVoucherVersion(
      id,
      prepareVoucherVersionPayload(voucher),
      config
    );
    dispatch(receiveUpdateVoucherVersion(result));
    return result;
  };

const receiveDeleteVoucher = createAction('DELETE_VOUCHER_SUCCESS');
export const deleteVoucher =
  ({ id, ...config }) =>
  async dispatch => {
    const response = await Api.deleteVoucher(id, config);
    dispatch(receiveDeleteVoucher(id));
    return response;
  };

const receiveDeleteVoucherVersion = createAction(
  'DELETE_VOUCHER_VERSION_SUCCESS'
);
export const deleteVoucherVersion =
  ({ id, ...config }) =>
  async (dispatch, getState) => {
    const response = await Api.deleteVoucherVersion(id, config);
    const state = getState();
    const voucherId =
      getVoucherVersions(state).voucherVersionById[id]?.voucherId;
    dispatch(receiveDeleteVoucherVersion({ voucherVersionId: id, voucherId }));
    return response;
  };

import { createSelectorCreator, defaultMemoize } from 'reselect';
import isEqual from 'lodash/isEqual';
import queryString from 'query-string';
import { getStateName, getPlural } from 'common';

export const getState = state => state;

export const createDeepEqualSelector = createSelectorCreator(
  defaultMemoize,
  isEqual
);

// export const createDeepEqualSelector = createSelectorCreator(
//   defaultMemoize,
//   isEqual
// );

export const getQuery = (state, { location }) =>
  (location && location.search && queryString.parse(location.search)) || {};
export const getParams = (state, { match }) => Boolean(match) && match.params;
export const getProps = (state, props) => props;
export const getFunc = (state, props, func) => func && func();

export const selectId = model =>
  createDeepEqualSelector(
    [getParams, getQuery, getProps, getFunc],
    (params, query, props, funcId) =>
      params[`${model}Id`] ||
      query[`${model}Id`] ||
      props[`${model}Id`] ||
      (props[`${model}`] && props[`${model}`].id) ||
      funcId
  );

export const selectAll = (type, state) => {
  const plural = getPlural(type);
  const typeIds = `${plural}Ids`;
  const typeState = state[plural];
  return typeState[typeIds].map(id =>
    selectRelations(type, typeState[`${type}ById`][id], state)
  );
};

export const selectRelations = (rootType, data, state, types) => {
  if (!data) {
    return null;
  }
  if (types && types.includes(data.type)) {
    return null;
  }
  const { relationships, ...item } = data;

  let relationValue = null;
  let relationState = null;

  return Object.keys(relationships || {})
    .filter(i => Boolean(relationships[i].data))
    .reduce((acc, relation) => {
      if (relation === rootType) {
        return acc;
      }
      relationValue = relationships[relation];
      if (!relationValue) {
        return acc;
      }
      if (Array.isArray(relationValue.data)) {
        if (
          relationValue.data.length > 0 &&
          types &&
          types.includes(relationValue.data[0].type)
        ) {
          acc[`${relation}Ids`] = relationValue.data.map(({ id }) => id);
        } else {
          acc[relation] = relationValue.data
            .map(({ type, id }) => {
              relationState = state[getStateName(type)];
              if (relationState) {
                return selectRelations(
                  rootType,
                  relationState[`${type}ById`][id],
                  state,
                  [...(types || []), type]
                );
              } else {
                console.warn('there is no state for type', type);
                return null;
              }
            })
            .filter(Boolean);
        }
      } else {
        relationState = relationValue.data
          ? state[getStateName(relationValue.data.type)]
          : null;

        if (
          (types && types.includes(relationValue.data.type)) ||
          !relationState
        ) {
          acc[`${relation}Id`] = relationValue.data
            ? relationValue.data.id
            : null;
        } else {
          acc[relation] = selectRelations(
            rootType,
            relationState[`${relationValue.data.type}ById`][
              relationValue.data.id
            ],
            state,
            [...(types || []), relationValue.data.type]
          );
        }
      }
      return acc;
    }, item);
};

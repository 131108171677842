import React, { useMemo, useRef } from 'react';
import styled from 'styled-components';
import { Text } from 'components/v3/ui';
import { TextInputLayout, InputLayoutVariant } from 'components/v3/InputLayout';
import { spacing } from 'ui';
import { Permission, features, useAccountConfig } from 'config';

export const TotalValuesContainer = styled.div`
  display: table;
  margin-left: auto;
  border-spacing: 0px 2px;
  border-collapse: separate;
  margin-right: 8px;
`;

export const TotalLabel = styled.div`
  display: table-row;
  justify-content: space-between;
  border-bottom: 2px solid white;
  font-weight: 600;

  > * {
    padding: ${spacing(0.75)} ${spacing()};
    display: table-cell;
    vertical-align: middle;
    * {
      min-height: unset;
    }
    * > input {
      padding: 0;
      text-align: right;
      font-weight: 600;
      max-width: 5em;
    }
  }

  span:first-child {
    text-align: left;
    width: auto;
    background-color: ${({ theme }) => theme.primaryLight};
    border: 1px solid ${({ theme }) => theme.primaryLight};
    color: ${({ theme }) => theme.textLight};
  }

  > :last-child {
    text-align: right;
    width: auto;
    color: ${({ theme }) => theme.textDark};
    border: 1px solid ${({ theme }) => theme.separator};
    font-size: inherit;
    line-height: inherit;
    border-radius: 0;
    font-weight: 600;
  }
`;

const DiscountWrapper = styled.div`
  display: flex;
`;

const DiscountTotal = styled.div`
  flex: 1;
  color: ${({ theme }) => theme.textDarkSecondary} !important;
  padding-left: 8px;
  width: auto;
  align-self: center;
  :before {
    content: '(';
    margin-right: 1px;
  }
  :after {
    content: ')';
    margin-left: 1px;
  }
`.withComponent(Text);

const Amounts = React.forwardRef(({ values, setFieldValue, readOnly }, ref) => {
  const { hasFeature } = useAccountConfig();
  const discountRef = useRef();
  const secondDiscountRef = useRef();
  const depositRef = useRef();
  let { discount, secondDiscount } = values;
  discount = parseFloat(discount || 0);
  secondDiscount = parseFloat(secondDiscount || 0);
  const linesPricing = (values.invoiceLines || [])
    .filter(i => !i._destroy)
    .filter(item => Boolean(item.unitPrice))
    .map(item => {
      const total = parseFloat(item.unitPrice) * parseFloat(item.quantity || 0);
      return {
        ...item,
        total,
        tax: total * parseFloat(item.taxRate || 0)
      };
    });

  const { discountValue, secondDiscountValue, total, totalTax } =
    useMemo(() => {
      let discountValue = 0;
      let secondDiscountValue = 0;
      let total = 0;
      let totalTax = 0;
      linesPricing.forEach(line => {
        let totalLine = 0;
        if (line.total >= 0) {
          const fDiscount = line.total * discount;
          const sDiscount = (line.total + fDiscount) * secondDiscount;
          discountValue += fDiscount;
          secondDiscountValue += sDiscount;
          totalLine = line.total + fDiscount + sDiscount;
        } else {
          totalLine = line.total;
        }
        total += totalLine;
        totalTax += totalLine * Number(line.taxRate);
      });
      return { discountValue, secondDiscountValue, total, totalTax };
    }, [linesPricing, discount, secondDiscount]);

  const totalWithTax = total + totalTax;
  const hasSecondDiscount = hasFeature(features.SECOND_DISCOUNT);

  return (
    <TotalValuesContainer readOnly={false} ref={ref}>
      <TotalLabel>
        <Text variant="small">{`${discount > 0 ? 'MAJORATION' : 'REMISE'}${
          hasSecondDiscount ? ' N°1' : ''
        } (%)`}</Text>
        <DiscountWrapper onClick={() => discountRef.current?.focus()}>
          <TextInputLayout
            ref={discountRef}
            name="discount"
            variant={InputLayoutVariant.flat.normal}
            percentage
            max={100}
            min={0}
            isNumericString={false}
            allowNegative
            readOnly={readOnly}
            value={values.discount * 100}
            onChange={newDiscount => {
              const newValue = Math.min(1, newDiscount / 100);
              setFieldValue('discount', discount === 0 ? -newValue : newValue);
            }}
          />
          <DiscountTotal variant="small" currency isNumericString={false}>
            {discountValue}
          </DiscountTotal>
        </DiscountWrapper>
      </TotalLabel>
      <Permission feature={features.SECOND_DISCOUNT}>
        <TotalLabel>
          <Text variant="small">{`${
            secondDiscount > 0 ? 'MAJORATION' : 'REMISE'
          } N°2 (%)`}</Text>
          <DiscountWrapper onClick={() => secondDiscountRef.current?.focus()}>
            <TextInputLayout
              ref={secondDiscountRef}
              name="secondDiscount"
              variant={InputLayoutVariant.flat.normal}
              percentage
              max={100}
              min={0}
              isNumericString={false}
              allowNegative
              readOnly={readOnly}
              value={values.secondDiscount * 100}
              onChange={newSecondDiscount => {
                const newValue = Math.min(1, newSecondDiscount / 100);
                setFieldValue(
                  'secondDiscount',
                  secondDiscount === 0 ? -newValue : newValue
                );
              }}
            />
            <DiscountTotal variant="small" currency isNumericString={false}>
              {secondDiscountValue}
            </DiscountTotal>
          </DiscountWrapper>
        </TotalLabel>
      </Permission>
      <TotalLabel>
        <Text variant="small">TOTAL HT</Text>
        <Text variant="small" currency isNumericString={false}>
          {total}
        </Text>
      </TotalLabel>
      <TotalLabel>
        <Text variant="small">TOTAL TVA</Text>
        <Text variant="small" currency isNumericString={false}>
          {totalTax}
        </Text>
      </TotalLabel>
      <Permission feature={features.INVOICE_DEPOSIT}>
        <TotalLabel>
          <Text variant="small">ACOMPTE</Text>
          <DiscountWrapper onClick={() => depositRef.current?.focus()}>
            <TextInputLayout
              ref={depositRef}
              name="deposit"
              variant={InputLayoutVariant.flat.normal}
              isNumericString={false}
              currency
              readOnly={readOnly}
              value={values.deposit || 0}
              onChange={deposit => {
                setFieldValue('deposit', deposit);
              }}
            />
            <DiscountTotal variant="small" currency isNumericString={false}>
              {parseFloat(values.deposit || 0)}
            </DiscountTotal>
          </DiscountWrapper>
        </TotalLabel>
      </Permission>
      <TotalLabel>
        <Text variant="small">TOTAL TTC</Text>
        <Text variant="small" currency isNumericString={false}>
          {totalWithTax}
        </Text>
      </TotalLabel>
      <Permission feature={features.INVOICE_DEPOSIT}>
        <TotalLabel>
          <Text variant="small">À PAYER</Text>
          <Text variant="small" currency isNumericString={false}>
            {totalWithTax - parseFloat(values.deposit || 0)}
          </Text>
        </TotalLabel>
      </Permission>
    </TotalValuesContainer>
  );
});

export default Amounts;

import React from 'react';
import { useCallback, useEffect, useState, memo } from 'react';
import { LocationDescriptor } from 'history';
import styled from 'styled-components';
import Section from './Section';
import VirtualList, { VirtualListProps } from './VirtualList';
import { isEqual } from 'lodash';

const Container = styled.div`
  height: 100%;
  overflow: hidden;
  position: relative;
`;

export type SectionItem = {
  id: string;
  [key: string]: any;
};

export type Item<S extends SectionItem = SectionItem> = {
  id: string;
  section: S;
};

export type StickySectionListProps<
  T extends Item<S>,
  S extends SectionItem = SectionItem
> = {
  className?: string;
  style?: React.CSSProperties;
  isSection?: (props: { item: T | S; index: number }) => boolean;
  getSectionForItem?: (props: { item: T | S; index: number }) => S | undefined;
  renderSection: (props: { section: S }) => React.ReactNode;
  renderItem: (props: { item: T; index: number }) => React.ReactNode;
  getItemStyle?: (props: {
    item: T;
    index: number;
    isSection: boolean;
  }) => React.CSSProperties;
  to?: (props: { item: T; index: number }) => LocationDescriptor | null;
} & Omit<VirtualListProps<T | S>, 'renderItem' | 'getItemStyle' | 'to'>;

function defaultSection<T extends Item<S>, S extends SectionItem>({
  item
}: {
  item: T | S;
}) {
  return String(item.id).includes('section');
}

function getSectionFormItem<T extends Item<S>, S extends SectionItem>({
  item
}: {
  item: T | S;
}) {
  const id = String(item.id);
  if (id.includes('section')) {
    return item as S;
  } else if (id.includes('loadMore')) {
    return undefined;
  }
  return item.section;
}

function StickySectionList<
  T extends Item<S>,
  S extends SectionItem = SectionItem
>({
  className,
  style,
  isSection = defaultSection,
  renderSection,
  getSectionForItem = getSectionFormItem,
  renderItem,
  getItemStyle,
  to,
  itemSpacing,
  ...props
}: StickySectionListProps<T, S>) {
  const { listRef, items, virtualItems } = props;
  const [currentSection, setCurrentSection] = useState<S>();

  const renderItemImpl = useCallback(
    ({ item, index }) => {
      if (isSection({ item, index })) {
        return <Section render={renderSection} section={item} />;
      } else {
        return renderItem({ item: item as T, index });
      }
    },
    [isSection, renderItem, renderSection]
  );

  const getItemStyleImpl = useCallback(
    ({ item, index }): React.CSSProperties => {
      if (isSection({ item, index })) {
        if (index === 0) {
          return {
            zIndex: -1,
            ...(getItemStyle?.({ item, index, isSection: true }) || {})
          };
        } else if (currentSection !== item) {
          return {
            ...(getItemStyle?.({ item, index, isSection: true }) || {}),
            zIndex: 3
          };
        }
      }
      return {
        ...(getItemStyle?.({ item, index, isSection: false }) ?? {}),
        marginLeft: 15,
        maxWidth: 'calc(100% - 15px)'
      };
    },
    [getItemStyle, isSection, currentSection]
  );

  const toImpl = useCallback(
    ({ item, index }): LocationDescriptor | null => {
      if (!to || item.disabled || isSection({ item, index })) {
        return null;
      } else {
        return to({ item, index });
      }
    },
    [to, isSection]
  );

  const itemSpacingImpl = useCallback(
    ({ item, index }) => {
      const spacing =
        itemSpacing instanceof Function
          ? itemSpacing({ item, index })
          : itemSpacing;

      if (isSection({ item, index })) {
        return {
          ...(spacing ?? {}),
          right: 0
        };
      } else {
        return spacing;
      }
    },
    [itemSpacing, isSection]
  );

  const computeCurrentSection = useCallback(
    (scrollTop: number) => {
      const topRow = virtualItems.find(({ end }) => end >= scrollTop);
      if (!topRow) return;
      const index = topRow.index;
      const item = items[index];
      const section = getSectionForItem({ item, index });
      if (section) {
        setCurrentSection(s => {
          if (isEqual(s, section)) {
            return s;
          }
          return section;
        });
      }
    },
    [virtualItems, getSectionForItem, items]
  );

  const handleScroll = useCallback(
    e => computeCurrentSection(e.currentTarget.scrollTop),
    [computeCurrentSection]
  );

  useEffect(() => {
    computeCurrentSection(listRef.current?.scrollTop ?? 0);
  }, [listRef, computeCurrentSection]);

  return (
    <Container className={className} style={style}>
      <Section render={renderSection} section={currentSection} sticky />
      <VirtualList
        renderItem={renderItemImpl}
        getItemStyle={getItemStyleImpl}
        to={toImpl}
        itemSpacing={itemSpacingImpl}
        onScroll={handleScroll}
        {...props}
      />
    </Container>
  );
}

export default memo(StickySectionList) as <
  T extends Item<S>,
  S extends SectionItem = SectionItem
>(
  props: StickySectionListProps<T, S>
) => JSX.Element;

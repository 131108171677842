import { get } from 'lodash';
import isSameIds from 'utils/idUtils';

export const resourceableCallbackFilter = (customField, values) => {
  if (customField.resourceable.length === 0) {
    return true;
  }
  return !customField.resourceable.some(e => {
    if (e.name.includes('customData') || e.name.includes('custom_data')) {
      const split = e.name.split('.');
      const data = values.customData.find(data => data.name === split[1]);
      split.splice(0, 2);
      const name = split.join('.');
      return !isSameIds(get(data, name), e.value);
    } else {
      const value =
        get(values, e.name) || get(values, e.name?.replace('Id', '.id'));
      return !isSameIds(value, e.value);
    }
  });
};

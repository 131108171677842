import {
  createDeepEqualSelector,
  selectRelations,
  getState,
  getProps
} from 'common/selector';
import { ProjectSelector } from 'features/Projects';

export const getDeviations = state => state.deviations;

export const selectDeviations = createDeepEqualSelector(
  [getDeviations, ProjectSelector.getProjects, getState],
  ({ projectsIds }, { projectById }, state) => {
    return projectsIds
      .map(id => selectRelations('project', projectById[id], state))
      .filter(Boolean);
  }
);

export const selectDeviation = createDeepEqualSelector(
  [ProjectSelector.getProjects, getProps, getState],
  ({ projectById }, { deviationId }, state) =>
    selectRelations('project', projectById[deviationId], state)
);

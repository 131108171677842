import React, { useEffect, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { Route } from 'react-router-dom';
import styled from 'styled-components';
import backgroundBlur from 'common/images/background-blur.png';
import logo from 'common/images/logo-full.svg';
import { useQuery } from 'components/v3/utils';
import { Grid, Button, Container } from 'components/v3/ui';
import Box from 'components/v3/Box';
import {
  FormHeader,
  LoginForm,
  LostPasswordForm,
  ResetPasswordForm,
  AcceptInvitationForm
} from './Form';
import { spacing } from 'ui';
import { AuthAction } from 'features/Auth';

export const Wrapper = styled.div`
  min-height: 100vh;
  background-image: url(${backgroundBlur});
  background-size: cover;
  background-position: center center;
  background-position-y: bottom;
  padding-top: ${spacing()};
  padding-bottom: ${spacing()};
  display: flex;
  align-items: center;
`;

export const Logo = styled.div`
  text-align: center;
  margin-left: ${spacing(1)};
  margin-top: ${spacing(1)};
  margin-right ${spacing(1)};
  margin-bottom: ${spacing(1.5)};

  img {
    display: inline-block;
    max-width: 100%;
  }
`;

const Callback = () => {
  const dispatch = useDispatch();
  const { resetToken, invitationToken } = useQuery();

  useEffect(() => {
    dispatch(AuthAction.logout());
  }, [dispatch]);

  if (invitationToken) {
    return <AcceptInvitationForm />;
  } else if (resetToken) {
    return <ResetPasswordForm />;
  } else {
    return (
      <Fragment>
        <Grid>
          <FormHeader
            title="Lien invalide"
            subtitle="Il semblerait que le lien que vous venez de cliquer soit expiré ou invalide."
          />
        </Grid>
        <Button variant="textCaption" to="/connexion">
          Se connecter
        </Button>
      </Fragment>
    );
  }
};

const Auth = () => {
  return (
    <Wrapper>
      <Container tight padded>
        <Box padded>
          <Logo>
            <img src={logo} alt="roadmapper" />
          </Logo>
          <Route path="/connexion">
            <LoginForm />
          </Route>
          <Route path={['/mot-de-passe-oublie', '/email-envoye']}>
            <LostPasswordForm />
          </Route>
          <Route path="/callback/:type">
            <Callback />
          </Route>
          <Route path={['/mot-de-passe-oublie', '/email-envoye']}>
            <Button variant="textCaption" to="/connexion">
              Retour
            </Button>
          </Route>
        </Box>
      </Container>
    </Wrapper>
  );
};

export default Auth;

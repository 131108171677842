import React from 'react';
import styled from 'styled-components';
import { get } from 'lodash';
import { Grid, GridItem } from 'components/v3/ui';
import Popover from 'components/v3/Popover';
import RawCard from 'components/v3/ui/card';
import { TextInputLayout } from 'components/v3/InputLayout';
import HolidayKindSelect from 'features/Holidays/components/v3/HolidayKindSelect';
import ActionGroup, { Action } from 'components/v3/ActionGroup';

const LIMITED_MODE = true;

const DisplayValue = styled.td`
  * > * {
    min-height: unset;
  }
  input {
    padding: 0px;
    height: 100%;
    width: 100%;
    text-align: center;
  }
`;

const Card = styled(RawCard)`
  textarea {
    min-height: 140px;
  }
`;

const PopoverContent = ({
  formik,
  dateKey,
  splitKey,
  holidayKindByValue,
  withoutKindPicker
}) => {
  const values = get(formik.values, splitKey);
  const holidayKind = holidayKindByValue[values?.kind];
  return (
    <Card
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <Grid>
        {!withoutKindPicker && (
          <GridItem width="100%">
            <ActionGroup>
              <Action
                checked={values?.kind === 'work'}
                style={{ width: '33%', justifyContent: 'center' }}
                onClick={() => {
                  formik.setFieldValue(`${splitKey}.kind`, 'work');
                  formik.setFieldValue(`${dateKey}.updated`, true);
                }}
              >
                Travail
              </Action>
              <Action style={{ width: '67%' }}>
                <HolidayKindSelect
                  variant="flat.small"
                  isSearchable
                  label="Type de congé"
                  value={holidayKind}
                  onChange={kind => {
                    formik.setFieldValue(`${splitKey}.kind`, kind?.value);
                    formik.setFieldValue(`${dateKey}.updated`, true);
                  }}
                />
              </Action>
            </ActionGroup>
          </GridItem>
        )}
        {!Boolean(holidayKind) && (
          <GridItem>
            <TextInputLayout
              error={formik.errors.comment}
              label="Durée en heures"
              number
              value={values?.duration}
              fillWidth
              fixedDecimalScale={false}
              onChange={value => {
                formik.setFieldValue(`${splitKey}`, {
                  duration: value,
                  kind: 'work'
                });
                formik.setFieldValue(`${dateKey}.updated`, true);
              }}
            />
          </GridItem>
        )}
      </Grid>
    </Card>
  );
};

export const WorkRecordSplit = ({
  dateKey,
  split,
  formik,
  holidayKindByValue,
  withoutKindPicker
}) => {
  const splitKey = `${dateKey}.${split}`;
  const data = get(formik.values, splitKey);
  const holidayKind = holidayKindByValue[data?.kind];

  let dataValue = '';
  if (data?.kind === 'work' || !data) {
    dataValue = data?.duration;
  } else if (data?.kind === 'public_holiday') {
    dataValue = data?.name;
  } else {
    dataValue = holidayKind?.abbr;
  }
  dataValue = dataValue ?? '';
  if (LIMITED_MODE) {
    if (Boolean(holidayKind || data?.kind === 'public_holiday')) {
      return <DisplayValue>{dataValue}</DisplayValue>;
    } else {
      return (
        <DisplayValue>
          <TextInputLayout
            fixedDecimalScale={false}
            number
            variant="flat.small"
            value={dataValue ?? ''}
            onChange={value => {
              formik.setFieldValue(`${splitKey}`, {
                duration: value,
                kind: 'work'
              });
              formik.setFieldValue(`${dateKey}.updated`, true);
            }}
          />
        </DisplayValue>
      );
    }
  }
  return (
    <Popover
      closeOnSelect={false}
      autoHeightMax={400}
      minWidth={300}
      disabled={Boolean(holidayKind || data?.kind === 'public_holiday')}
    >
      <DisplayValue>{dataValue}</DisplayValue>
      <PopoverContent
        dateKey={dateKey}
        formik={formik}
        holidayKindByValue={holidayKindByValue}
        withoutKindPicker
      />
    </Popover>
  );
};

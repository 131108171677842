import React, { useMemo } from 'react';
import { v4 } from 'uuid';
import { TextInputLayout, InputLayoutVariant } from 'components/v3/InputLayout';
import TableField from 'components/v3/Form/TableField';
import { stringToColor, getInitials } from 'utils';
import ColorPicker from 'components/v3/ColorPicker';
import Checkbox from 'components/v3/Checkbox';

export const rowCreator = () => ({
  id: v4(),
  isNew: true,
  value: '',
  displayName: '',
  color: ''
});

const isRowValid = row =>
  Boolean(row.value?.trim().length) && Boolean(row.displayName?.trim().length);

export const COLUMNS = [
  {
    Header: ' ',
    accessor: 'colorPicker',
    width: 48,
    textAlign: 'center',
    Cell: ({ item, onChange }) => (
      <ColorPicker
        style={{ height: 32, width: 32 }}
        value={item.color}
        onChange={color =>
          onChange({
            ...item,
            color,
            colorInitialized: true
          })
        }
      />
    )
  },
  {
    Header: 'Value',
    accessor: 'value',
    width: '25%',
    dashed: true,
    isEditable: true,
    Cell: ({ item, onChange }) => (
      <TextInputLayout
        variant={InputLayoutVariant.flat.medium}
        label="Value"
        required
        value={item.value}
        fillWidth
        onChange={value =>
          onChange({
            ...item,
            value,
            color: item.colorInitialized ? item.color : stringToColor(value)
          })
        }
      />
    )
  },
  {
    Header: 'Nom',
    accessor: 'displayName',
    width: 'calc(75% - 48px)',
    dashed: true,
    isEditable: true,
    Cell: ({ item, onChange }) => (
      <TextInputLayout
        variant={InputLayoutVariant.flat.medium}
        label="Nom"
        required
        value={item.displayName}
        fillWidth
        onChange={displayName =>
          onChange({
            ...item,
            displayName,
            abbr: item.abbrInitialized ? item.abbr : getInitials(displayName)
          })
        }
      />
    )
  }
];

const States = ({ editableCheckbox, ...props }) => {
  const columns = useMemo(() => {
    if (editableCheckbox) {
      return [
        ...COLUMNS.map(c => {
          if (c.accessor === 'displayName') {
            return {
              ...c,
              width: 'calc(75% - 96px)'
            };
          } else {
            return c;
          }
        }),
        {
          Header: 'Editable',
          accessor: 'editable',
          width: '48px',
          dashed: true,
          isEditable: true,
          Cell: ({ item, onChange }) => (
            <Checkbox
              value={item.editable}
              onChange={editable =>
                onChange({
                  ...item,
                  editable
                })
              }
            />
          )
        }
      ];
    } else {
      return COLUMNS;
    }
  }, [editableCheckbox]);
  return (
    <TableField
      asSection
      columns={columns}
      rowCreator={rowCreator}
      useWindow={false}
      addRowLabel="Ajouter"
      isRowValid={isRowValid}
      minHeight={50}
      isSortable
      {...props}
    />
  );
};

export default States;

import React, { useEffect, useCallback, Fragment, useMemo } from 'react';
import { Route, useRouteMatch, useHistory, useLocation } from 'react-router-dom';
import Scheduler, { SCHEDULER_TYPE } from './View';
import { PlanningSelector, PlanningAction } from 'features/Plannings';
import TeamModal from 'features/Teams/components/v3/Modal';
import { useRedux } from 'components/v3/utils';
import { useAccountConfig } from 'config';
import ProjectModal from 'features/Projects/components/v3/Modal';

const Team = ({ ...props }) => {
  const history = useHistory();
  const location = useLocation();
  const { path, url } = useRouteMatch();
  const { accountId } = useAccountConfig();

  const [teams, fetch] = useRedux(PlanningSelector.selectPlanningTeams, PlanningAction.getTeams, {
    fetchOnMount: false,
    initialParams: {
      sort: 'position,display_name',
      perPage: 500,
      page: 1
      // deletedAtPresent: false
    }
  });

  useEffect(() => {
    fetch();
  }, [fetch, accountId]);

  const handleSearch = useCallback(
    (query) => {
      fetch((prevParams) => ({
        ...prevParams,
        displayNameContAny: query
      }));
    },
    [fetch]
  );

  const handleResourceClick = useCallback((id) => history.push(`${path}/${id}`), [history, path]);

  const resources = useMemo(() => [...teams, { id: 'none', displayName: 'Sans équipe' }], [teams]);

  return (
    <Fragment>
      <Scheduler
        schedulerType={SCHEDULER_TYPE.TEAMS}
        resources={resources}
        onResourceClick={handleResourceClick}
        resourceLabelText={`Équipes (${teams.length})`}
        onSearch={handleSearch}
      />
      <Route exact path={[`${path}/new`, `${path}/:teamId`]}>
        <TeamModal
          onRequestClose={() => {
            if (location.key) {
              history.goBack();
            } else {
              history.replace(url);
            }
          }}
        />
      </Route>
      <Route exact path={[`${path}/projets/:projectId`]}>
        <ProjectModal
          onRequestClose={() => {
            if (location.key) {
              history.goBack();
            } else {
              history.replace(url);
            }
          }}
        />
      </Route>
    </Fragment>
  );
};

export default Team;

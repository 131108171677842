import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { connect, useSelector } from 'react-redux';
import {
  Route,
  useRouteMatch,
  useLocation,
  useHistory
} from 'react-router-dom';
import styled from 'styled-components';
import isEqual from 'lodash/isEqual';
import { spacing } from 'ui';
import { Content, UsersContainer } from './ui';
import Users from './Users';
import { UserAction, UserSelector } from 'features/Users';
import { UiAction, UiSelector } from 'features/UiPreferences';
import { MeSelector } from 'features/Me';
import useFetch from 'utils/hooks/useFetch';
import { PhaseAction } from 'features/Phases';
import { CalendarSelector } from 'features/Calendar';
import Planning from 'features/Plannings/components';
// import DayHeaderView from 'components/v3/Planning/DayHeaderView';

import UserModal from 'features/Users/components/v3/Modal';

import { PlanningAction, PlanningSelector } from 'features/Plannings';
import { useAccountConfig } from 'config';
import { Title, ProgressBar, Text } from 'components/v3/ui';
import DayHeaderView from './DayHeaderView';
import isSameIds from 'utils/idUtils';

const AgendaTitle = styled(Title)`
  display: flex;
  align-items: center;
  min-height: 48px;
  padding: ${spacing(0.5)} ${spacing(1)};
`;

const dateIncrement = {
  weeks: 1
};

const dayHeaderFormat = {
  weekday: 'short',
  day: 'numeric'
};

const CURRENT_VIEW = {
  name: 'timeGridWeek',
  dateIncrement: dateIncrement,
  dayHeaderFormat
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  height: 100%;
`;

const EventWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: 100%;
  position: sticky;
  top: 0px;
  left: 0px;
  padding: 0px 4px;
  > span {
    margin-top: 4px;
  }
`;

const EventTitle = styled.div`
  display: flex;
  flex-direction: column;
  > span:not(:first-child) {
    margin-top: 4px;
  }
`;

const EventMeta = styled.div`
  display: flex;
  justify-content: space-between;
  .revenue {
    display: flex;
    width: unset;
    align-self: normal;
    padding-left: 12px;
  }
  > span:not(.revenue) {
    word-break: break-word;
  }
`;

const eventRenderer = ({ event }) => {
  const { extendedProps } = event;
  if (extendedProps.source === 'holiday') {
    return (
      <EventWrapper>
        <Text variant="small" weight="semiBold" color="light">
          {extendedProps.kind?.displayName}
        </Text>
        {extendedProps.description && (
          <Text variant="small" weight="semiBold" color="light">
            {extendedProps.description}
          </Text>
        )}
      </EventWrapper>
    );
  }
  const { displayName, project, description } = extendedProps || {};
  return (
    <EventWrapper>
      <EventMeta>
        <EventTitle>
          {Boolean(displayName?.trim()) && (
            <Text variant="small" weight="semiBold" color="light">
              {displayName?.trim()}
            </Text>
          )}
          {Boolean(project?.displayName?.trim()) && (
            <Text variant="small" weight="semiBold" color="light">
              {project?.displayName?.trim()}
            </Text>
          )}
        </EventTitle>
        {project?.estimatedRevenues != null && (
          <Text
            className="revenue"
            variant="small"
            currency
            color="light"
            ellipsis={false}
          >
            {project.estimatedRevenues}
          </Text>
        )}
      </EventMeta>
      {Boolean(project?.description?.length) && (
        <Text variant="small" color="light">
          {project.description}
        </Text>
      )}
      {Boolean(description?.length) && (
        <Text variant="small" color="light">
          {description}
        </Text>
      )}
    </EventWrapper>
  );
};

const Calendar = ({
  calendarUserIds,
  calendarCheckedIds,
  currentAccountId,
  match,
  phases,
  ...props
}) => {
  const { url, path } = useRouteMatch();
  const history = useHistory();
  const location = useLocation();
  const { search } = location;
  const [dateRange, setDateRange] = useState();
  const { hasPermission } = useAccountConfig();
  const canEdit = hasPermission('phase.create');
  const [allSelected, setAllSelected] = useState(false);

  const uiSelector = useMemo(
    () => state =>
      UiSelector.selectUiKeys('planning.showWeekend')(state)?.planning || {},
    []
  );

  const { showWeekend } = useSelector(uiSelector, isEqual);
  // const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });

  const isUpdating = useSelector(PlanningSelector.selectPlanningUpdating);

  const {
    isLoading: isUsersLoading,
    updateParams: updateUsersParams
  } = useFetch(params => props.getUsers({ ...params, perPage: 500, page: 1 }), [
    currentAccountId
  ]);

  const {
    isLoading: isPhasesLoading,
    updateParams: updatePhasesParams
  } = useFetch(props.getPhases, [currentAccountId], true);

  const usersSelector = useMemo(
    () => state => {
      if (allSelected) {
        return UserSelector.selectUsers(state);
      } else {
        return UiSelector.selectCalendarUsers(state);
      }
    },
    [allSelected]
  );

  const users = useSelector(usersSelector, isEqual);

  const userIdInString = JSON.stringify(calendarCheckedIds);

  useEffect(() => {
    if (!dateRange) {
      return;
    }
    updatePhasesParams({
      from: dateRange.startAt,
      'between[start_at]': dateRange.startAt,
      to: dateRange.endAt,
      'between[end_at]': dateRange.endAt,
      usersIdIn: calendarCheckedIds,
      include_invoices: true
    });
  }, [
    currentAccountId,
    updatePhasesParams,
    dateRange,
    userIdInString,
    calendarCheckedIds
  ]);

  // const selectUsers = useDeepMemo(
  //   () => state => selectUsersById(state, { userIds: calendarCheckedIds }),
  //   [calendarCheckedIds]
  // );

  const handleSetCalendarUser = (type, id) => {
    switch (type) {
      case 'add':
        return props.addCalendarUsers(id);
      case 'update':
        return props.updateCalendarUsersCheck(id);
      case 'remove':
        return props.removeCalendarUser(id);
      default:
        return null;
    }
  };

  const isLoading = isUsersLoading || isPhasesLoading;

  const containerStyle = useMemo(
    () => ({
      pointerEvents: isUpdating ? 'none' : undefined
    }),
    [isUpdating]
  );

  const dayHeaderContent = useCallback(
    info => (
      <DayHeaderView {...info} userIds={calendarCheckedIds} phases={phases} />
    ),
    [phases, calendarCheckedIds]
  );

  return (
    <Container style={containerStyle}>
      {isLoading && <ProgressBar style={{ position: 'absolute' }} />}
      <Content left>
        <AgendaTitle>Mes Agendas</AgendaTitle>
        <UsersContainer>
          <Users
            checkedIds={calendarCheckedIds}
            subscribedIds={calendarUserIds}
            users={users}
            allSelected={allSelected}
            onAllSelected={setAllSelected}
            onAdd={payload => handleSetCalendarUser('add', payload)}
            onChange={payload => handleSetCalendarUser('update', payload)}
            onRemove={payload => handleSetCalendarUser('remove', payload)}
            onEditUser={userId =>
              history.push({
                pathname: `${path}/utilisateurs/${userId}`,
                search: search
              })
            }
            onSearch={v =>
              updateUsersParams(p => ({
                ...p,
                byQuery: v
              }))
            }
          />
        </UsersContainer>
      </Content>
      <Planning
        // ref={calendarRef}
        kind="calendar"
        currentView={CURRENT_VIEW}
        firstDay={1}
        nowIndicator
        eventDurationEditable
        eventResizableFromStart
        slotMinTime="6:00"
        slotMaxTime="20:00"
        // dayHeaderContent={DayHeaderView}
        selectable={canEdit}
        allDaySlot={false}
        headerToolbar={false}
        unselectAuto={false}
        dayHeaderContent={dayHeaderContent}
        onDateRangeChanged={setDateRange}
        events={phases}
        // onEventSelect={handleSelect}
        // onEventClick={handleItemClick}
        // onEventUpdate={handleItemChange}
        eventContent={eventRenderer}
        // tooltipContent={({ event }) => (
        //   <EventTooltip
        //     event={event}
        //     schedulerType={SCHEDULER_TYPE.USERS}
        //     showOverlap
        //   />
        // )}
        expandRows
        disableSearch
        readOnly={!canEdit}
        phasePromptProps={{
          requireMembers: true,
          requireProject: false,
          users: users.filter(u =>
            calendarCheckedIds.find(id => isSameIds(id, u.id))
          ),
          onSubmitSuccess: response => {
            if (Array.isArray(response?.data?.relationships?.users?.data)) {
              handleSetCalendarUser(
                'add',
                response.data.relationships.users.data.map(d => d.id)
              );
            }
          }
        }}
        phaseModalProps={{ showSelectProject: true }}
        weekends={showWeekend}
      />
      <Route exact path={`${path}/utilisateurs/:userId`}>
        <UserModal
          onRequestClose={() => {
            if (location.key) {
              history.goBack();
            } else {
              history.replace(url);
            }
          }}
        />
      </Route>
    </Container>
  );
};

const mapStateToProps = (state, props) => ({
  calendarUserIds: UiSelector.selectCalendarUserIds(state),
  calendarCheckedIds: UiSelector.selectCalendarCheckedIds(state),
  currentAccountId: MeSelector.selectCurrentAccountId(state),
  phases: CalendarSelector.selectPhases(state, props)
});

const mapDispatchToProps = dispatch => ({
  addCalendarUsers: (...args) => dispatch(UiAction.addCalendarUsers(...args)),
  updateCalendarUsersCheck: (...args) =>
    dispatch(UiAction.updateCalendarUsersCheck(...args)),
  removeCalendarUser: (...args) =>
    dispatch(UiAction.removeCalendarUser(...args)),
  getUsers: (...args) => dispatch(UserAction.getUsers(...args)),
  getPhases: (...args) => dispatch(PlanningAction.getPlanningData(...args)),
  getUser: (...args) => dispatch(UserAction.getUser(...args)),
  preUpdatePhase: (...args) => dispatch(PhaseAction.preUpdatePhase(...args)),
  updatePhase: (...args) =>
    dispatch(PlanningAction.updatePlanningPhase(...args)),
  createPhase: payload => dispatch(PhaseAction.createPhase(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(Calendar);

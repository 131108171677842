import { get, post, put, destroy } from 'common/api';

export const getIssues = params => get(`/v5/issues`, params);

export const getIssueCsvUrl = params => get(`/v5/issues/csv_url`, params);

export const createIssue = (issue, config) =>
  post(`/v5/issues`, { issue }, config);

export const getIssue = (id, config) => get(`/v5/issues/${id}`, config);

export const putIssue = (issueId, issue) =>
  put(`/v5/issues/${issueId}`, { issue });

export const deleteIssue = (id, config) => destroy(`/v5/issues/${id}`, config);

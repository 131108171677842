import Form from './Form';
import EntityForm from './EntityForm';
import ConfirmDelete from './ConfirmDelete';
import {
  TextInputField,
  PasswordField,
  SelectField,
  CheckboxField,
  DatePickerField,
  AddressField,
  ColorField
} from './fields';
import TableField from './TableField';
import { InputLayoutVariant, InputLayoutTheme } from '../InputLayout';

export { InputLayoutTheme, InputLayoutVariant };
export {
  TextInputField,
  PasswordField,
  SelectField,
  CheckboxField,
  DatePickerField,
  AddressField,
  ColorField,
  TableField
};
export { EntityForm };
export { ConfirmDelete };

export const ERRORS = {
  REQUIRED: 'Ce champ est requis',
  TAKEN: 'Cette valeur est déjà prise',
  INVALID: 'Cette valeur n’est pas valide',
  EMAIL: 'Cette adresse mail n’est pas valide',
  EMAIL_TAKEN: 'Cette adresse mail est déjà utilisée',
  PHONE: 'Ce numéro de téléphone n’est pas valide'
};

export default Form;

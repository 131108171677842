import React, { useEffect, useCallback } from 'react';
import { Text } from 'components/v3/ui';
import Table from 'components/v3/List/Table';
import { useListFetcher } from 'components/v3/utils';

const columns = [
  {
    Header: 'Nom',
    accessor: 'displayName',
    width: '40%',
    Cell: ({ item }) => <Text variant="small">{item.displayName}</Text>
  },
  {
    Header: 'Référence',
    accessor: 'reference',
    width: '30%',
    Cell: ({ item }) => (
      <Text variant="caption" color="theme">
        {item.reference}
      </Text>
    )
  },
  {
    Header: 'Tél',
    accessor: 'phone',
    width: '30%',
    Cell: ({ item }) => <Text variant="small">{item.phone}</Text>
  }
];

const OrganizationList = ({
  selector,
  action,
  options = {
    fetchOnMount: false,
    useLocalState: true
  },
  params = {
    sort: 'display_name'
  },
  perPage = 25,
  filters,
  onCountChanged,
  detailUrlPrefix = 'organisations',
  ...props
}) => {
  const [organizations, paginationHelper, state] = useListFetcher({
    selector,
    action,
    options,
    params,
    perPage,
    filters
  });

  const detailLinkFormatter = useCallback(
    item => `${detailUrlPrefix}/${item.id}`,
    [detailUrlPrefix]
  );

  useEffect(() => {
    if (paginationHelper.total != null && onCountChanged) {
      onCountChanged(paginationHelper.total);
    }
  }, [paginationHelper.total, onCountChanged]);

  return (
    <Table
      columns={columns}
      items={organizations}
      isLoading={state.isLoading}
      linkFormatter={detailLinkFormatter}
      centerMessage={state.errorMessage}
      {...paginationHelper}
      {...props}
    />
  );
};

export default OrganizationList;

import { PlanningAction, PlanningApi, PlanningReducer, PlanningSelector } from './data';
import pick from 'lodash/pick';
import { phaseResources } from 'features/Phases';

export { PlanningAction, PlanningApi, PlanningReducer, PlanningSelector };

export const phaseProjectAttributes = [
  'id',
  'reference',
  'displayName',
  'checked',
  'estimatedRevenues',
  'group.color',
  'group.id',
  'group.displayName',
  'state',
  'projectActivities',
  'sort',
  'user.id'
];

export const phaseActivityAttributes = ['id', 'displayName', 'color'];

export const phaseTeamAttributes = ['id', 'displayName', 'color'];

export const eventUsersAttributes = ['id', 'displayName', 'avatarUrl', 'settings.color', 'settings.abbr'];

export const phaseOrdersAttributes = ['id', 'product.aqpNumber', 'purveyor.displayName', 'estimatedTotal'];

export const phaseMachinesAttributes = ['id', 'displayName'];

export const pickPhaseAttributes = (phase) =>
  phase
    ? pick(
        {
          ...phase,
          users: phase.users?.map((u) => pick(u, eventUsersAttributes)),
          machines: phase.machines?.map((u) => pick(u, phaseMachinesAttributes)),
          orders: phase.orders?.map((u) => pick(u, phaseOrdersAttributes)),
          project: phase.project
            ? {
                ...pick(phase.project, phaseProjectAttributes),
                invoices: phase.project?.invoices?.map((i) => pick(i, 'id', 'number', 'kind'))
              }
            : phase.project
        },
        [
          'id',
          'displayName',
          'users',
          'machines',
          'project',
          'source',
          ...phaseActivityAttributes.map((i) => `activity.${i}`),
          ...phaseTeamAttributes.map((i) => `team.${i}`),
          'state',
          'startAt',
          'endAt',
          'checked',
          'night',
          'orders',
          'description',
          'customData'
        ]
      )
    : phase;

export const isEventOverlap = (event) => {
  return event && phaseResources.findIndex((resource) => event[resource] && event[resource].length > 0) >= 0;
};

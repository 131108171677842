import React, { useEffect, useCallback, Fragment } from 'react';
import {
  useRouteMatch,
  Route,
  useHistory,
  useLocation
} from 'react-router-dom';
import { MdFileDownload } from 'react-icons/md';
import Avatar from 'components/v3/Avatar';
import Table from 'components/v3/List/Table';
import { Text, ButtonIcon } from 'components/v3/ui';
import { useListFetcher } from 'components/v3/utils';
import ReportModal from './ReportModal';
import AttachmentIcon from 'features/Attachments/components/v3/Icon';
import { authenticateUrl } from 'utils';

const columns = [
  {
    Header: () => <MdFileDownload size={18} />,
    accessor: 'fileType',
    width: 56,
    minWidth: 56,
    textAlign: 'center',
    Cell: ({ item }) => (
      <ButtonIcon
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
          window.open(
            authenticateUrl(
              `${
                item.reportKind?.documentTemplate?.fileUrl
              }?resource=Report&resource_id=${item.id}`
            ),
            '_blank'
          );
        }}
      >
        <AttachmentIcon
          attachment={{
            sourceContentType: 'application/pdf'
          }}
          size={24}
        />
      </ButtonIcon>
    )
  },
  {
    Header: ' ',
    accessor: 'user',
    width: 56,
    minWidth: 56,
    textAlign: 'center',
    Cell: ({ item }) => <Avatar user={item.user} />
  },
  {
    Header: 'Type de compte-rendu',
    accessor: 'reportKind.documentTemplate.displayName',
    width: 'calc(100% - 56px - 56px)',
    Cell: ({ item }) => (
      <Text variant="caption" color="theme">
        {item.reportKind?.documentTemplate?.displayName}
      </Text>
    )
  }
];

const ReportList = ({
  projectId,
  selector,
  action,
  page = 'default',
  options = {
    fetchOnMount: false,
    useLocalState: true
  },
  params = {
    sort: '-created_at'
  },
  perPage = 25,
  filters,
  onCountChanged,
  detailUrlPrefix = '/compte-rendus',
  onSubmitSuccess,
  ...props
}) => {
  const { path, url } = useRouteMatch();
  const history = useHistory();
  const location = useLocation();
  const [reports, paginationHelper, state] = useListFetcher({
    selector,
    action,
    options,
    params,
    perPage,
    filters
  });

  const detailLinkFormatter = useCallback(
    item => `${url}${detailUrlPrefix}/${item.id}`,
    [url, detailUrlPrefix]
  );

  useEffect(() => {
    if (paginationHelper.total != null && onCountChanged) {
      onCountChanged(paginationHelper.total);
    }
  }, [paginationHelper.total, onCountChanged]);

  return (
    <Fragment>
      <Table
        columns={columns}
        items={reports}
        isLoading={state.isLoading}
        linkFormatter={detailLinkFormatter}
        centerMessage={state.errorMessage}
        estimatedItemSize={48 + (props.spacing || 0) / 2}
        {...paginationHelper}
        {...props}
      />
      <Route
        path={[
          `${path}${detailUrlPrefix}/nouveau`,
          `${path}${detailUrlPrefix}/:reportId`
        ]}
      >
        <ReportModal
          onRequestClose={() => {
            if (location.key) {
              history.goBack();
            } else {
              history.replace(url);
            }
          }}
          onSubmitSuccess={onSubmitSuccess}
        />
      </Route>
    </Fragment>
  );
};

export default ReportList;

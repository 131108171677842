import React, { useCallback, useMemo, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import * as Yup from 'yup';
import { MdCheckCircle, MdEmail } from 'react-icons/md';
import { EntityModal } from 'components/v3/Modal';
import {
  TextInputField,
  SelectField,
  ColorField,
  CheckboxField,
  ERRORS
} from 'components/v3/Form';
import { Button, Grid, GridItem } from 'components/v3/ui';
import {
  UserAction,
  UserSelector,
  UserApi,
  userAsValues
} from 'features/Users';
import { JobField } from './JobSelect';
import { GroupField } from 'features/Groups/components/v3/Select';
import { useDelay } from 'components/v3/utils';
import { stringToColor, getInitials } from 'utils';
import { getErrorMessage } from 'common/api';
import { ROLES, useAccountConfig } from 'config';
import { differenceInMinutes } from 'date-fns';
import { parseDate, printDate } from 'utils/dates';
import { toaster } from 'components/v3/Toast';

const DynamicGridItem = styled(GridItem)`
  display: flex;

  ${GridItem}:not(:first-child):not(:only-child) {
    margin-left: 16px;
  }
`;
const GridItemTransition = styled(GridItem)`
  transition: width 0.3s ease;
`;

const validationSchema = Yup.object().shape({
  lastName: Yup.string().required(ERRORS.REQUIRED),
  role: Yup.string().required(ERRORS.REQUIRED)
});

const isEmailRequired = role => role !== 'technician';

const valuesDependencies = {
  'settings.abbr': {
    modifier: (values, form) => {
      if (!form.status?.abbr && (!form.touched.abbr || !values.abbr)) {
        form.touched.abbr = false;
        return getInitials(`${values.firstName} ${values.lastName}`);
      } else {
        return values.abbr;
      }
    },
    triggers: ['firstName', 'lastName']
  },
  'settings.color': {
    modifier: (values, form) => {
      if (form.status?.color) {
        return values.color;
      } else {
        return stringToColor(values.email);
      }
    },
    triggers: ['email']
  }
};

const isClientRole = role => {
  return role === 'user' || role === 'client';
};

const UserModal = ({ onRequestClose, user: propsUser, ...props }) => {
  const { hasPermission } = useAccountConfig();
  const dispatch = useDispatch();
  const delay = useDelay();
  const { params } = useRouteMatch();
  let { userId } = params;
  if (propsUser) {
    userId = propsUser.id;
  }
  const isNew = !Boolean(userId);
  const [trailingIcon, setTrailingIcon] = useState(null);
  const [resendButtonVisible, setResendVisible] = useState(null);
  const [resendLoading, setResendLoading] = useState(false);
  const selectUser = useMemo(
    () => state => UserSelector.selectUserV3(state, { userId }),
    [userId]
  );

  let user = useSelector(selectUser, isEqual);
  if (!user) {
    user = propsUser;
  }
  const canEdit = hasPermission('user.update');

  const createRequest = useCallback(
    payload => dispatch(UserAction.createUser({ payload })),
    [dispatch]
  );

  const fetchRequest = useCallback(
    () => dispatch(UserAction.getUser({ id: userId })),
    [userId, dispatch]
  );

  const updateRequest = useCallback(
    (id, payload) => dispatch(UserAction.updateUser({ id, payload })),
    [dispatch]
  );

  const deleteRequest = useCallback(
    id => dispatch(UserAction.deleteUser({ id })),
    [dispatch]
  );

  const initialEmail = user?.email;

  const validateEmail = useCallback(
    (email, role) => {
      if (!isEmailRequired(role) || (initialEmail === email && email)) {
        // if (initialEmail === email) {
        setTrailingIcon(null);
        return '';
      }
      if (!isEmailRequired(role) && (!email || email.trim().length === 0)) {
        return '';
      }
      if (!Yup.string().required().email(ERRORS.EMAIL).isValidSync(email)) {
        setTrailingIcon(null);
        if (email.trim().length === '') {
          return ERRORS.REQUIRED;
        } else {
          return ERRORS.EMAIL;
        }
      }
      return delay(
        () =>
          UserApi.getUsers({ emailEq: email })
            .then(response => {
              if (response.data.length > 0) {
                return ERRORS.EMAIL_TAKEN;
              } else {
                setTrailingIcon(<MdCheckCircle color="green" />);
                return '';
              }
            })
            .catch(e => getErrorMessage(e)),
        500,
        'emailValidation',
        true
      );
    },
    [initialEmail, delay]
  );

  const invitationAccepted = user?.invitationAccepted;
  const invitationSentAt = useMemo(() => {
    if (!user?.invitationSentAt) return null;
    return parseDate(user.invitationSentAt);
  }, [user]);

  useEffect(() => {
    if (!invitationSentAt) return;
    if (invitationAccepted) {
      setResendVisible(false);
      return;
    }
    setResendVisible(differenceInMinutes(new Date(), invitationSentAt) > 0);
    const interval = window.setInterval(() => {
      setResendVisible(differenceInMinutes(new Date(), invitationSentAt) > 0);
    }, 5000);
    return () => {
      window.clearInterval(interval);
    };
  }, [invitationSentAt, invitationAccepted]);

  return (
    <EntityModal
      isOpen
      onRequestClose={onRequestClose}
      title={isNew ? 'Nouveau membre' : 'Édition du membre'}
      id={userId}
      model="user"
      entity={user}
      initialStatus={{
        color: Boolean(user?.settings?.id),
        abbr: Boolean(user?.settings?.abbr)
      }}
      validationSchema={validationSchema}
      entityAsValue={userAsValues}
      fetchRequest={fetchRequest}
      createRequest={createRequest}
      updateRequest={updateRequest}
      deleteRequest={deleteRequest}
      valuesDependencies={valuesDependencies}
      autoSubmit={false}
      readOnly={!canEdit}
      {...props}
    >
      {({ values, status, setStatus }) => (
        <Grid spacing={16} padded={false}>
          <GridItem width="100%">
            <TextInputField
              name="firstName"
              label="Prénom"
              readOnly={!canEdit}
            />
          </GridItem>
          <GridItem width="100%">
            <TextInputField
              name="lastName"
              label="Nom"
              required
              readOnly={!canEdit}
            />
          </GridItem>
          <DynamicGridItem>
            <GridItemTransition width={resendButtonVisible ? '65%' : '100%'}>
              <TextInputField
                name="email"
                label="Email"
                validate={email => validateEmail(email, values.role)}
                readOnly={!canEdit}
                leadingIconClickable
                iconLeading={
                  <ColorField
                    name="settings.color"
                    onColorSelected={color => setStatus({ ...status, color })}
                  />
                }
                iconTrailing={trailingIcon}
                hint={
                  Boolean(values.id)
                    ? invitationSentAt &&  !invitationAccepted ? `Invitation envoyée le ${printDate(invitationSentAt, "dd/MM/yyyy 'à' HH:mm" )}` : null
                    : "L'email n'est pas obligatoire pour un technicien."
                }
              />
            </GridItemTransition>
            {values.id && resendButtonVisible && (
              <GridItem width="35%" alignCenter justifyCenter minWidth={280}>
                <Button
                  icon={MdEmail}
                  color="textCaption"
                  variant="text"
                  underline
                  isLoading={resendLoading}
                  style={{ fontSize: '12px' }}
                  onClick={() => {
                    setResendLoading(true);
                    dispatch(UserAction.resendInvitation({ id: values.id }))
                      .then(() => {
                        setResendLoading(false);
                        toaster.success("L'email d'invitation a été renvoyé.");
                      })
                      .catch(err => {
                        setResendLoading(false);
                        toaster.error(err);
                      });
                  }}
                >
                  <u>Renvoyer l'email d'invitation</u>
                </Button>
              </GridItem>
            )}
          </DynamicGridItem>

          <GridItem width="100%">
            <TextInputField
              name="settings.abbr"
              label="Initiales"
              readOnly={!canEdit}
              hint="Les initiales sont affichées si l'utilisateur n'a pas d'avatar."
            />
          </GridItem>
          <DynamicGridItem width="100%">
            <GridItemTransition
              minWidth={
                Boolean(values.role) && !isClientRole(values.role)
                  ? values.role === 'technician'
                    ? '33%'
                    : '50%'
                  : '100%'
              }
              width="50%"
            >
              <SelectField
                label="Role"
                name="role"
                options={ROLES}
                readOnly={!canEdit}
                mapOptionValue
                isClearable={false}
              />
            </GridItemTransition>
            {values.role === 'technician' && (
              <JobField
                name="settings.job"
                asTag={false}
                readOnly={!canEdit}
                container={child => (
                  <GridItem minWidth="33%" width="50%">
                    {child}
                  </GridItem>
                )}
              />
            )}
            {values.role === 'technician' && (
              <GridItem width="50%">
                <TextInputField
                  name="settings.dailyCost"
                  number
                  readOnly={!canEdit}
                  label="Coût journalier (€) *"
                />
              </GridItem>
            )}
            {(values.role === 'admin' || values.role === 'conductor') && (
              <GridItem width="50%">
                <CheckboxField
                  label="Afficher dans le planning techniciens"
                  name="settings.production"
                  readOnly={!canEdit}
                />
              </GridItem>
            )}
          </DynamicGridItem>
          <GridItem width="100%">
            <GroupField
              name="groups"
              label="Groupes associés"
              multi
              readOnly={!canEdit}
            />
          </GridItem>
          <GridItem width="100%">
            <TextInputField
              textArea
              readOnly={!canEdit}
              name="settings.description"
              label="Informations supplémentaires"
              minRows={3}
            />
          </GridItem>
        </Grid>
      )}
    </EntityModal>
  );
};

export default UserModal;

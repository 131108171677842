import styled from 'styled-components';
import { FilterBar } from '../Filters';

const BoxFilter = styled.div`
  border: ${({ theme }) => theme.borderInput};
  border-radius: ${({ theme }) => theme.borderButtonRadius};
  flex-grow: 1;
  width: auto;
`.withComponent(FilterBar);

export default BoxFilter;

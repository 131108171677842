import React, { useEffect, useCallback, Fragment, useMemo } from 'react';
import { Route, useHistory, useRouteMatch, useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import isEqual from 'lodash/isEqual';
import { Text } from 'components/v3/ui';
import { List, ListItem } from 'components/v3/ui/list';
import Table from 'components/v3/List/Table';
import Avatar from 'components/v3/Avatar';
import { useListFetcher } from 'components/v3/utils';
import { printDateRange } from 'utils/dates';
import State from './State';
import PhaseModal from './Modal';
import Option from 'components/v3/Option';
import { features, useAccountConfig } from 'config';
import { PhaseSelector } from 'features/Phases/data';

const AvatarWrapper = styled(List)`
  list-style-type: none;
  margin: 0;
  padding: 0;
  height: 100%;
  display: flex;
  align-items: center;

  display: flex;
  padding-right: 0;
  justify-content: flex-end;
  box-sizing: border-box;

  li {
    margin: 0 calc(((75% / 25) - 8px) + 5%);
    min-width: 25px;
  }

  li:last-child {
    margin-right: auto;
  }
`;

const columns = [
  {
    Header: 'Durée',
    accessor: 'duration',
    width: '5%',
    maxWidth: 56,
    textAlign: 'center',
    Cell: ({ item }) => <Text variant="small">{`${item.duration}j`}</Text>
  },
  {
    Header: 'Pers. assigné',
    accessor: 'users',
    width: '10%',
    tooltip: "Personnel assigné à l'intervention",
    maxWidth: 116,
    Cell: ({ item }) => {
      const totalCount = item.users?.length;
      return (
        <AvatarWrapper>
          {item.users?.slice(0, 3).map((u, index) => {
            let user = u;
            if (index > 1 && totalCount > 3) {
              user = {
                id: u.id,
                settings: {
                  abbr: `+${totalCount - 2}`
                }
              };
            }
            return (
              <ListItem key={user.id}>
                <Avatar user={user} />
              </ListItem>
            );
          })}
        </AvatarWrapper>
      );
    },
    feature: [features.PLANNING_MEMBERS, features.PLANNING_CALENDAR]
  },
  {
    Header: 'Machines',
    accessor: 'machines',
    width: '15%',
    Cell: ({ item }) => (
      <Text variant="small">
        <List>
          {item.machines?.map(m => (
            <ListItem key={m.id}>{m.displayName}</ListItem>
          ))}
        </List>
      </Text>
    ),
    feature: features.PLANNING_MACHINES
  },
  {
    Header: 'Equipe',
    accessor: 'team',
    width: '10%',
    feature: features.PLANNING_TEAMS,
    Cell: ({ item }) => (
      <Option
        variant="small"
        option={{
          label: item.team?.displayName,
          color: item.team?.color
        }}
      />
    )
  },
  {
    Header: "Nom de l'intervention",
    accessor: 'displayName',
    grow: true,
    Cell: ({ item }) => (
      <Text variant="small">
        {item.displayName ||
          item.activity?.displayName ||
          item.team?.displayName}
      </Text>
    )
  },
  {
    Header: 'Dates',
    accessor: 'dates',
    width: '20%',
    maxWidth: 400,
    Cell: ({ item }) => (
      <Text variant="caption" color="theme">
        {printDateRange(
          [item.startAt, item.endAt],
          'EEEE dd MMMM yyyy',
          ' au '
        )}
      </Text>
    )
  },
  {
    Header: 'Coût',
    accessor: 'totalCost',
    width: '10%',
    maxWidth: 150,
    textAlign: 'end',
    padding: '4px 12px 4px 8px',
    Cell: ({ item }) => (
      <Text currency variant="small">
        {item.totalCost}
      </Text>
    )
  },
  {
    Header: 'État',
    accessor: 'state',
    width: '10%',
    maxWidth: 150,
    textAlign: 'center',
    Cell: ({ item }) => <State value={item.state} phaseId={item.id} />
  }
];

const PhaseList = ({
  selector,
  action,
  options = {
    fetchOnMount: false,
    useLocalState: true
  },
  params = {
    sort: '-created_at'
  },
  perPage = 25,
  filters,
  onCountChanged,
  detailUrlPrefix = '/interventions',
  ...props
}) => {
  const location = useLocation();
  const history = useHistory();
  const { url, path } = useRouteMatch();
  const { hasFeature } = useAccountConfig();

  const [phases, paginationHelper, state] = useListFetcher({
    selector,
    action,
    options,
    params,
    perPage,
    filters
  });

  const phaseStates = useSelector(PhaseSelector.selectPhaseStates, isEqual);

  const memoColumns = useMemo(() => {
    return columns.filter(column => {
      if (column.accessor === 'state') {
        return phaseStates.length > 0;
      } else {
        return hasFeature(column.feature);
      }
    });
  }, [hasFeature, phaseStates]);

  const detailLinkFormatter = useCallback(
    item => `${url}${detailUrlPrefix}/${item.id}`,
    [url, detailUrlPrefix]
  );

  useEffect(() => {
    if (paginationHelper.total != null && onCountChanged) {
      onCountChanged(paginationHelper.total);
    }
  }, [paginationHelper.total, onCountChanged]);

  return (
    <Fragment>
      <Table
        columns={memoColumns}
        items={phases}
        isLoading={state.isLoading}
        linkFormatter={detailLinkFormatter}
        centerMessage={state.errorMessage}
        estimatedItemSize={48 + (props.spacing || 0) / 2}
        rowWrap={false}
        {...paginationHelper}
        {...props}
      />
      <Route
        path={[
          `${path}${detailUrlPrefix}/nouvelle-phase`,
          `${path}${detailUrlPrefix}/:phaseId`
        ]}
      >
        <PhaseModal
          onRequestClose={() => {
            if (location.key) {
              history.goBack();
            } else {
              history.replace(url);
            }
          }}
        />
      </Route>
    </Fragment>
  );
};

export default PhaseList;

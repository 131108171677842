import React, { useCallback } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { MdNavigateBefore, MdNavigateNext } from 'react-icons/md';
import DatePicker from 'components/v3/DatePicker';
import ActionGroup, { Action } from 'components/v3/ActionGroup';
import { Dot, Icon, Text } from 'components/v3/ui';
import Tooltip from './Tooltip';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
`;

const HintDot = styled(Dot)`
  position: absolute;
  top: 4px;
  ${({ right }) => (right ? 'right: 4px;' : 'left: 4px;')}
  background-color: ${({ theme }) => theme.accent};
`;

const DateNavigation = ({
  date,
  onDateChanged,
  leftActionTooltip = null,
  rightActionTooltip = null,
  hintLeftVisible,
  hintLeftTooltip,
  hintRightVisible,
  hintRightTooltip,
  dateTodayHidden,
  dateTodayOffset,
  dateAlignment,
  dateIncrement,
  datePickerProps = {
    collapsedIfBlank: true,
    tooltip: 'Aller à la date',
    asIcon: true
  }
}) => {
  const handleGoToToday = useCallback(() => {
    let today = moment();
    if (dateTodayOffset) {
      const offsetUnit = Object.keys(dateTodayOffset)[0];
      const offset = dateTodayOffset[offsetUnit];
      today = today.add(-offset, offsetUnit).startOf(offsetUnit);
    }
    onDateChanged(today.startOf(dateAlignment || 'day').format());
  }, [onDateChanged, dateAlignment, dateTodayOffset]);

  const handleDateNavigation = useCallback(
    next => {
      const incrementUnit = Object.keys(dateIncrement)[0];
      const increment = dateIncrement[incrementUnit];
      let newDate = moment(date);
      if (next) {
        newDate = newDate.add(increment, incrementUnit);
      } else {
        newDate = newDate.add(-increment, incrementUnit);
      }
      onDateChanged(newDate.startOf(dateAlignment || 'day').format());
    },
    [onDateChanged, date, dateIncrement, dateAlignment]
  );

  const navigatePrevious = useCallback(() => {
    handleDateNavigation(false);
  }, [handleDateNavigation]);

  const navigateNext = useCallback(() => {
    handleDateNavigation(true);
  }, [handleDateNavigation]);

  const goToDate = useCallback(
    date => {
      onDateChanged(moment(date).format());
    },
    [onDateChanged]
  );

  return (
    <Wrapper>
      <ActionGroup>
        <Action tooltip={leftActionTooltip} onClick={navigatePrevious}>
          <Icon>
            {hintLeftVisible && (
              <Tooltip content={hintLeftTooltip}>
                <HintDot size={8} />
              </Tooltip>
            )}
            <MdNavigateBefore />
          </Icon>
        </Action>
        {!dateTodayHidden && (
          <Action tooltip="Retourner à aujourd'hui" onClick={handleGoToToday}>
            <Text style={{ padding: '0px 8px' }}>Aujourd'hui</Text>
          </Action>
        )}
        <Action>
          <DatePicker
            value=""
            onChange={goToDate}
            variant="flat.small"
            {...(datePickerProps || {})}
          />
        </Action>
        <Action tooltip={rightActionTooltip} onClick={navigateNext}>
          <Icon>
            {hintRightVisible && (
              <Tooltip content={hintRightTooltip}>
                <HintDot size={8} right />
              </Tooltip>
            )}
            <MdNavigateNext />
          </Icon>
        </Action>
      </ActionGroup>
    </Wrapper>
  );
};

export default DateNavigation;

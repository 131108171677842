import React, { useCallback, useRef, Fragment } from 'react';
import styled from 'styled-components';
import { SelectInputLayout, TextInputLayout } from 'components/v3/InputLayout';
import Card, { CardHeader, CardContent } from 'components/v3/ui/card';
import { MdAdd, MdClose } from 'react-icons/md';
import { Button, Grid, GridItem, Text, ButtonIcon } from 'components/v3/ui';
import { features } from 'config';
import Tooltip from 'components/v3/Tooltip';

// const RESOURCE_PROJECT_LINE_OPTIONS = {
//   ProjectDisplayName: {
//     value: 'displayName',
//     label: 'Nom du projet',
//     type: 'text'
//   },
//   ProjectReference: {
//     value: 'reference',
//     label: 'Reference du projet',
//     type: 'text'
//   },
//   ProjectAddress: {
//     value: 'address.content',
//     label: 'Adresse du projet',
//     type: 'text'
//   },
//   ProjectDescription: {
//     value: 'description',
//     label: 'Description du projet',
//     type: 'text'
//   },
//   ProjectKind: {
//     value: 'projectKind.displayName',
//     label: 'Type du projet',
//     type: 'text'
//   },
//   ProjectRevenues: {
//     value: 'estimatedRevenues',
//     label: 'Montant estimé du projet',
//     type: 'currency'
//   }
// };

export const PHASE_LINE_ATTRIBUTES = {
  ProjectDisplayName: {
    value: 'project.displayName',
    label: 'Nom du projet',
    type: 'text'
  },
  ProjectReference: {
    value: 'project.reference',
    label: 'Reference du projet',
    type: 'text'
  },
  ProjectRevenues: {
    value: 'project.estimatedRevenues',
    label: 'Montant estimé du projet',
    type: 'currency'
  },
  PhaseDisplayName: {
    value: 'displayName',
    label: 'Nom de la phase',
    type: 'text'
  },
  PhaseActivity: {
    value: 'activity.displayName',
    label: 'Activité',
    type: 'text'
  },
  PhaseUsers: {
    value: 'users',
    label: 'Personnel assigné',
    type: 'object'
  },
  PhaseMachines: {
    value: 'machines',
    label: 'Machines',
    type: 'object'
  },
  PhaseProducts: {
    value: 'orders',
    label: 'Enrobés + Tonnage',
    type: 'object'
  },
  PhaseTeam: {
    value: 'team.displayName',
    label: 'Equipe',
    type: 'text'
  }
};

const PHASE_LINE_OPTIONS = Object.values(PHASE_LINE_ATTRIBUTES);

const PHASE_LINE_STYLE = [
  {
    label: 'Bold',
    value: 'bold'
  },
  {
    label: 'SemiBold',
    value: 'semiBold'
  },
  {
    label: 'Regular',
    value: 'regular'
  }
];

// const PHASE_COLOR_OPTIONS = [
//   {
//     label: "Couleur de l'équipe",
//     value: 'team.color'
//   },
//   {
//     label: "Statut de l'intervention",
//     value: 'state.color'
//   },
//   {
//     label: 'Statut du projet',
//     value: 'project.state.color'
//   },
//   { label: 'Couleur du groupe', value: 'project.group.color' }
// ];

const LineContentWrapper = styled(GridItem)`
  display: flex;
  align-items: center;
  > *:first-child {
    flex: 1;
  }
  > *:not(:last-child) {
    margin-right: 8px;
  }
`;

const LineContent = ({ line, onChange }) => {
  const { position } = line;

  return (
    <Fragment>
      <SelectInputLayout
        label={`Ligne ${line.position + 1}`}
        multi
        value={line.elements?.map((element) => element.value).filter(Boolean) || []}
        sortOptions={false}
        options={PHASE_LINE_OPTIONS}
        mapOptionValue
        onChange={(values) =>
          onChange({
            position,
            elements: values.map((v) => PHASE_LINE_OPTIONS.find((option) => option.value === v))
          })
        }
      />
      <SelectInputLayout
        style={{
          maxWidth: 100,
          minWidth: 100
        }}
        label="Style"
        isClearable={false}
        value={line.style}
        sortOptions={false}
        options={PHASE_LINE_STYLE}
        mapOptionValue
        onChange={(style) =>
          onChange({
            ...line,
            style
          })
        }
      />
      <TextInputLayout
        style={{
          maxWidth: 100,
          minWidth: 100
        }}
        number
        decimalScale={0}
        label="Maxlines"
        tooltip="Nombre max de lignes, 0 = infini"
        value={line.maxLines}
        onChange={(maxLines) =>
          onChange({
            ...line,
            maxLines
          })
        }
      />
    </Fragment>
  );
};

const PlanningContentCard = ({ title, planningName, planningValues, onChange }) => {
  const eventLines = planningValues[`${planningName}EventLines`] || [];
  // const resourceLines = planningValues[`${planningName}ResourceLines`] || [];

  const eventLinesRef = useRef();
  eventLinesRef.current = eventLines;

  const handleLineChange = useCallback(
    (line) => {
      onChange({
        ...planningValues,
        [`${planningName}EventLines`]: eventLinesRef.current.map((l) => {
          if (l.position === line.position) {
            return line;
          } else {
            return l;
          }
        })
      });
    },
    [planningName, planningValues, onChange]
  );

  return (
    <Card>
      <CardHeader title={title} style={{ display: 'flex', justifyContent: 'space-between' }} />
      <CardContent>
        <Grid vertical spacing={8}>
          <LineContentWrapper width="100%">
            <Text weight="bold">Contenu des phases</Text>
            <Button
              icon={MdAdd}
              small
              onClick={() => {
                onChange({
                  ...planningValues,
                  [`${planningName}EventLines`]: [
                    ...eventLinesRef.current,
                    {
                      position: eventLinesRef.current.length,
                      elements: []
                    }
                  ]
                });
              }}
            >
              Ajouter une ligne
            </Button>
          </LineContentWrapper>
          {eventLines.map((line) => {
            return (
              <LineContentWrapper key={line.position} width="100%">
                <LineContent line={line} onChange={handleLineChange} />
                <Tooltip content="Supprimer la ligne">
                  <ButtonIcon
                    onClick={() => {
                      onChange({
                        ...planningValues,
                        [`${planningName}EventLines`]: eventLinesRef.current.filter((l) => l.position !== line.position)
                      });
                    }}
                  >
                    <MdClose />
                  </ButtonIcon>
                </Tooltip>
              </LineContentWrapper>
            );
          })}
        </Grid>
      </CardContent>
    </Card>
  );
};

const CUSTOM_CONTENT_PLANNINGS = [
  {
    value: features.PLANNING_PROJECTS,
    label: 'Planning projets',
    name: 'projects'
  },
  {
    value: features.PLANNING_MACHINES,
    label: 'Planning machines',
    name: 'machines'
  },
  {
    value: features.PLANNING_TEAMS,
    label: 'Planning équipes',
    name: 'teams'
  },
  {
    value: features.PLANNING_MEMBERS,
    label: 'Planning techniciens',
    name: 'users'
  }
];

const PlanningContent = ({ features, planningValues, onChange }) => {
  return CUSTOM_CONTENT_PLANNINGS.map((planning) => {
    if (!features.includes(planning.value)) {
      return null;
    }
    return (
      <GridItem key={planning.value} width="50%" minWidth={280}>
        <PlanningContentCard
          title={planning.label}
          planningName={planning.name}
          planningValues={planningValues}
          onChange={onChange}
        />
      </GridItem>
    );
  });
};

export default PlanningContent;

// "machinesEventLines": [
//       {
//          elements: [{"name": "project.reference",
//          "type": "attribut"}]
//          "position": "0"
//       },
//       {
//          "name": "project.displayName",
//          "type": "attribut",
//          "position": "1"
//       },
//       {
//          "name": "users",
//          "type": "object",
//          "position": "2"
//       }
//    ],
//    "machinesResourceLines": [
//       {
//          "name": "displayName",
//          "type": "attribut",
//          "position": "0"
//       },
//       {
//          "name": "description",
//          "type": "attribut",
//          "variant": "secondary",
//          "position": "1"
//       }
//    ],
//    "usersEventLines": [
//       {
//          "name": "project.reference",
//          "type": "attribut",
//          "position": "0"
//       },
//       {
//          "name": "project.displayName",
//          "type": "attribut",
//          "position": "1"
//       },
//    ],
//    "usersResourceLines": [
//       {
//          "name": "displayName",
//          "type": "attribut",,
//          "position": "0"
//       },
//       {
//          "name": "displayTopRole",
//          "type": "attribut",
//          "variant": "secondary",
//          "position": "1"
//       }
//    ]

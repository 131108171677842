import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { spacing, fontSize, theme } from 'ui';
import { Dot, Text } from 'components/v3/ui';
import { TiWarningOutline } from 'react-icons/ti';
import Tooltip from 'components/v3/Tooltip';
import Avatar from 'components/v3/Avatar';
import { UiSelector } from 'features/UiPreferences';
import isEqual from 'lodash/isEqual';

export const ResourceContainer = styled.div.attrs(({ style, borderColor }) => ({
  style: {
    borderLeft: Boolean(borderColor) ? `6px solid ${borderColor}` : undefined,
    ...(style || {})
  }
}))`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  cursor: pointer;
  white-space: initial;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-right: ${spacing(0.5)};
  overflow: hidden;

  &:hover {
    background-color: ${theme.separator} !important;
  }

  > * {
    margin-left: 10px;
  }

  ${({ isHoliday }) =>
    isHoliday &&
    css`
      cursor: default;
      &:hover {
        background-color: transparent !important;
      }
    `}

  ${({ hasBorder }) =>
    hasBorder &&
    css`
      border-left: ${props => props.color};
    `}
`;

export const ResourceContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  > *:not(:first-child):not(:only-child) {
    margin-top: 2px;
  }

  font-size: ${fontSize(14)};
  > * {
    font-size: ${fontSize(14)};
  }
`;

const Indicator = styled.span`
  display: flex;
  align-items: center;
  height: 100%;
`;

const Resource = ({ resource, el, onClick }) => {
  const { extendedProps } = resource;
  const {
    displayName,
    color,
    reference,
    description,
    group,
    state,
    checked,
    source
  } = extendedProps;
  const resourceColor = color || (group && group.color);
  const uiSelector = useMemo(
    () => state =>
      UiSelector.selectUiKeys('planning.showProjectsChecked')(state)
        ?.planning || {},
    []
  );

  const { showProjectsChecked } = useSelector(uiSelector, isEqual);
  const checkValidation =
    source === 'projects' && showProjectsChecked && !checked;

  return (
    <ResourceContainer
      borderColor={resourceColor}
      isHoliday={source === 'holiday'}
      onClick={onClick}
    >
      {source === 'users' && (
        <Avatar size="medium" user={extendedProps} borderHidden />
      )}
      <ResourceContent>
        <Text weight="semiBold" maxLines={2}>
          {displayName}
        </Text>
        {reference && (
          <Text variant="caption" color="theme">
            {reference}
          </Text>
        )}
        {description && !reference && (
          <Text variant="hint" color="hint">
            {description}
          </Text>
        )}
      </ResourceContent>
      {(state || checkValidation) && (
        <Tooltip
          placement="right"
          content={
            checkValidation ? 'En attente de validation' : state.displayName
          }
        >
          <Indicator>
            {checkValidation ? (
              <TiWarningOutline size={24} />
            ) : (
              <Dot color={state.color} size={12} />
            )}
          </Indicator>
        </Tooltip>
      )}
    </ResourceContainer>
  );
};

export default Resource;

import React, { useCallback, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import * as Yup from 'yup';
import { EntityModal } from 'components/v3/Modal';
import { TextInputField, ERRORS } from 'components/v3/Form';
import { Grid, GridItem } from 'components/v3/ui';
import {
  MaterialSelector,
  MaterialAction,
  extractionDestinationAsValues
} from '../data';
import { permissions, useAccountConfig } from 'config';

const validationSchema = Yup.object().shape({
  name: Yup.string().required(ERRORS.REQUIRED)
});

export const ExtractionDestinationModal = ({
  onRequestClose,
  onSubmitSuccess,
  extractionDestination: propsExtractionDestination,
  ...props
}) => {
  const { hasPermission } = useAccountConfig();
  const dispatch = useDispatch();
  const { params } = useRouteMatch();
  let { extractionDestinationId } = params;
  if (propsExtractionDestination) {
    extractionDestinationId = propsExtractionDestination.id;
  }
  const isNew = !Boolean(extractionDestinationId);

  const selectExtractionDestination = useMemo(
    () => state =>
      MaterialSelector.selectExtractionDestination(state, {
        id: extractionDestinationId
      }),
    [extractionDestinationId]
  );

  let extractionDestination = useSelector(selectExtractionDestination, isEqual);
  if (!extractionDestination) {
    extractionDestination = propsExtractionDestination;
  }

  const createRequest = useCallback(
    payload =>
      dispatch(MaterialAction.createExtractionDestination({ payload })),
    [dispatch]
  );

  const updateRequest = useCallback(
    (id, payload) =>
      dispatch(MaterialAction.updateExtractionDestination({ id, payload })),
    [dispatch]
  );

  const deleteRequest = useCallback(
    id => dispatch(MaterialAction.deleteExtractionDestination({ id })),
    [dispatch]
  );

  const canEdit = hasPermission([
    permissions.RESOURCES_CONFIG,
    isNew ? 'extraction_destination.create' : 'extraction_destination.update'
  ]);
  const canDelete = hasPermission([
    permissions.RESOURCES_CONFIG,
    'extraction_destination.delete'
  ]);

  return (
    <EntityModal
      isOpen
      size="small"
      onRequestClose={onRequestClose}
      title={
        isNew
          ? 'Nouvelle destination de sortant'
          : 'Édition destination de sortant'
      }
      id={extractionDestinationId}
      model="extractionDestination"
      entity={extractionDestination}
      validationSchema={validationSchema}
      entityAsValue={extractionDestinationAsValues}
      createRequest={createRequest}
      updateRequest={updateRequest}
      deleteRequest={canDelete ? deleteRequest : undefined}
      onSubmitSuccess={onSubmitSuccess}
      autoSubmit={false}
      readOnly={!canEdit}
      {...props}
    >
      {() => (
        <Grid spacing={16} padded={false}>
          <GridItem width="100%">
            <TextInputField
              name="name"
              label="Nom de la destination"
              required
              readOnly={!canEdit}
            />
          </GridItem>
        </Grid>
      )}
    </EntityModal>
  );
};

import styled, { css } from 'styled-components';

export const Row = styled.div`
  display: inline-flex;
  position: relative;
  align-items: center;
  min-height: 34px;
  min-width: 100%;
  flex-wrap: nowrap;
  transition: background-color 150ms ease, box-shadow 250ms ease;

  ${props => {
    switch (props['data-color']) {
      case 'dark':
        return css`
          background-color: ${({ theme }) => theme.backgroundDark};
        `;
      case 'transparent':
        return css`
          background-color: transparent;
        `;
      default:
        return css`
          background-color: ${({ theme }) => theme.backgroundLight};
        `;
    }
  }}

  ${props =>
    !props['data-spacing'] &&
    !props['data-last-item'] &&
    css`
      &:before {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        content: '';
        display: block;
        border-bottom: 1px solid ${({ theme }) => theme.separator};
      }
    `}
    
  ${props =>
    !!props['data-spacing'] &&
    css`
      border: 1px solid ${({ theme }) => theme.separator};
      border-radius: 10px;
    `}

  ${props =>
    props['data-archived'] &&
    css`
      &:before {
        content: '';
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.5) 40%, #d9d9d9 40%, #d9d9d9 50%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0.5) 90%, #d9d9d9 90%, #d9d9d9 100%) !important;
        background-size: 10.00px 10.00px !important;
        repeat top left;
        opacity: 1;
      }
      opacity: 1;
    `}

  ${props =>
    (props['data-deletable'] || props['data-archivable']) &&
    css`
      padding-right: 16px;
    `}

  ${({ onClick, to, theme }) =>
    (onClick || to) &&
    css`
      text-decoration: none;
      color: inherit;
      &:visited {
        color: inherit;
      }
      a:active {
        color: inherit;
      }
      cursor: pointer;
      &:hover {
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08);
        background-color: ${theme.hoverColor};
      }
      &:active {
        background-color: ${theme.hoverColorDark};
      }
    `};
`;

import { createDeepEqualSelector, getParams, getProps } from 'common/selector';
import { getState, selectRelations } from 'common/selector';

export const getMachines = state => state.machines;

export const selectMachines = createDeepEqualSelector(
  [getMachines, getState],
  ({ machinesIds, machineById }, state) =>
    machinesIds
      .map(id => selectRelations('machine', machineById[id], state))
      .filter(Boolean)
);

export const selectMachine = createDeepEqualSelector(
  [getMachines, getParams, getProps],
  ({ machineById }, { machineId }, { machine }) => {
    return machineById[machineId || (machine && machine.id)];
  }
);

export const selectMachineV3 = createDeepEqualSelector(
  [getMachines, getProps, getState],
  ({ machineById }, { machineId }, state) =>
    selectRelations('machine', machineById[machineId], state)
);

import React, { useEffect, useCallback, Fragment, useMemo } from 'react';
import { useRouteMatch, Route, useHistory } from 'react-router-dom';
import { Icon, Text } from 'components/v3/ui';
import Table from 'components/v3/List/Table';
import { useListFetcher } from 'components/v3/utils';
import { printDate } from 'utils/dates';
import State from './InvoiceState';
import InvoiceModal from './InvoiceModal';
import { INVOICE_KINDS } from 'features/Accounting';
import { features, useAccountConfig } from 'config';
import { MdCheckCircle } from 'react-icons/md';
import { theme } from 'ui';
import styled from 'styled-components';

const TitleLayout = styled.div`
  display: flex;
  flex-direction: column;
`;

const pageVariant = {
  project: {
    signed: '50px',
    createdAt: '10%',
    kind: '10%',
    number: '22.5%',
    displayName: '27.5%',
    total: '15%',
    state: '15%'
  },
  default: {
    signed: '50px',
    createdAt: '7.5%',
    organization: '10%',
    contact: '9.5%',
    kind: '10%',
    number: '15%',
    displayName: '27.5%',
    total: '8%',
    state: '12.5%'
  }
};

const columns = [
  {
    Header: 'Créé le',
    accessor: 'createdAt',
    width: '7.5%',
    Cell: ({ item }) => (
      <Text variant="small" color="caption">
        {printDate(item.createdAt)}
      </Text>
    )
  },
  {
    Header: 'Organisation',
    accessor: 'organization',
    width: '12.5%',
    Cell: ({ item }) => <Text variant="small">{item?.organization?.displayName}</Text>
  },
  {
    Header: 'Contact',
    accessor: 'contact',
    width: '12.5%',
    Cell: ({ item }) => <Text variant="small">{item?.contact?.displayName}</Text>
  },
  {
    Header: 'Type',
    accessor: 'kind',
    width: '12.5%',
    Cell: ({ item }) => <Text variant="small">{INVOICE_KINDS.find((i) => item.kind === i.value).label}</Text>
  },
  {
    Header: 'Numéro',
    accessor: 'number',
    width: '20%',
    Cell: ({ item }) => (
      <Text variant="caption" color="theme">
        {item.number}
      </Text>
    )
  },
  {
    Header: 'Intitulé',
    accessor: 'displayName',
    width: '20%',
    Cell: ({ item }) => (
      <TitleLayout>
        <Text variant="small" weight="semiBold">
          {item.project?.displayName}
        </Text>
        <Text variant="small">{item.displayName}</Text>
      </TitleLayout>
    )
  },
  {
    Header: 'Total HT',
    accessor: 'total',
    width: '12.5%',
    textAlign: 'end',
    Cell: ({ item }) => (
      <Text currency variant="small">
        {item.total}
      </Text>
    )
  },
  {
    Header: 'État',
    accessor: 'state',
    width: '15%',
    textAlign: 'center',
    Cell: ({ item }) => <State value={item.state} invoiceId={item.id} invoiceKind={item.kind} />
  },
  {
    Header: 'Signé',
    accessor: 'signed',
    width: '50px',
    Cell: ({ item }) =>
      item.signed && (
        <Icon>
          <MdCheckCircle color={theme.success} />
        </Icon>
      )
  }
];

const InvoiceList = ({
  projectId,
  selector,
  action,
  page = 'default',
  options = {
    fetchOnMount: false,
    useLocalState: true
  },
  params = {
    sort: '-created_at'
  },
  perPage = 25,
  filters,
  onCountChanged,
  detailUrlPrefix = '/comptabilite',
  onSubmitSuccess,
  ...props
}) => {
  const { path, url } = useRouteMatch();
  const history = useHistory();
  const { hasFeature } = useAccountConfig();
  const hasSignFeature = hasFeature(features.SIGN_INVOICE);

  const detailLinkFormatter = useCallback((item) => `${url}${detailUrlPrefix}/${item.id}`, [url, detailUrlPrefix]);

  const memoColumns = useMemo(() => {
    const variant = pageVariant[page] || pageVariant.default;
    return columns
      .filter((column) => {
        if (!hasSignFeature && column.accessor === 'signed') {
          return false;
        } else {
          return Boolean(variant[column.accessor]);
        }
      })
      .map((column) => {
        let width = variant[column.accessor];
        if (hasSignFeature && column.accessor === 'number') {
          width = `calc(${width} - 50px)`;
        }

        if (page === 'project') {
          if (column.accessor === 'state') {
            column.Cell = ({ item }) => (
              <State value={item.state} invoiceId={item.id} invoiceKind={item.kind} onSuccess={onSubmitSuccess} />
            );
          } else if (column.accessor === 'displayName') {
            column.Cell = ({ item }) => <Text variant="small">{item.displayName}</Text>;
          }
        }
        return {
          ...column,
          width: width
        };
      });
  }, [page, hasSignFeature, onSubmitSuccess]);

  const [invoices, paginationHelper, state] = useListFetcher({
    selector,
    action,
    options,
    params,
    perPage,
    filters
  });

  useEffect(() => {
    if (paginationHelper.total != null && onCountChanged) {
      onCountChanged(paginationHelper.total);
    }
  }, [paginationHelper.total, onCountChanged]);

  return (
    <Fragment>
      <Table
        columns={memoColumns}
        items={invoices}
        isLoading={state.isLoading}
        linkFormatter={detailLinkFormatter}
        centerMessage={state.errorMessage}
        estimatedItemSize={48 + (props.spacing || 0) / 2}
        {...paginationHelper}
        {...props}
      />
      <Route path={[`${path}${detailUrlPrefix}/nouveau`, `${path}${detailUrlPrefix}/:invoiceId`]}>
        <InvoiceModal
          onRequestClose={() => {
            history.replace(url);
          }}
          onSubmitSuccess={onSubmitSuccess}
        />
      </Route>
    </Fragment>
  );
};

export default InvoiceList;

import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import Form, {
  ERRORS,
  TextInputField,
  PasswordField,
  InputLayoutVariant
} from 'components/v3/Form';
import { toaster } from 'components/v3/Toast';
import PasswordStrength from 'components/v3/PasswordStrength';
import { Grid, GridItem } from 'components/v3/ui';
import { useQuery, useRedux } from 'components/v3/utils';
import { FormFooter, FormHeader } from './';
import { AuthAction } from 'features/Auth';

const Schema = Yup.object().shape({
  firstName: Yup.string()
    .required(ERRORS.REQUIRED)
    .trim(),
  lastName: Yup.string()
    .required(ERRORS.REQUIRED)
    .trim(),
  password: Yup.string().required(ERRORS.REQUIRED)
});

export const AcceptInvitationForm = () => {
  const { email, invitationToken, firstName, lastName } = useQuery();

  const history = useHistory();
  const [, acceptInvitation] = useRedux(null, AuthAction.acceptInvite, {
    fetchOnMount: false
  });

  const handleSubmit = useCallback(
    (values, form) =>
      acceptInvitation(values)
        .onSuccess(res => {
          switch (res) {
            case 'logged':
              history.replace('/');
              break;
            default:
              toaster.info(
                "Vous pouvez vous connecter avec l'adresse enregistrée"
              );
              history.replace('/connexion');
              break;
          }
        })
        .onError(e => toaster.error(e, { position: 'bottom-center' }))
        .onEnd(() => form.setSubmitting(false)),
    [acceptInvitation, history]
  );

  return (
    <Form
      onSubmit={handleSubmit}
      validationSchema={Schema}
      initialValues={{
        firstName: firstName || '',
        lastName: lastName || '',
        password: '',
        email,
        invitationToken
      }}
    >
      {({ values, isSubmitting, isValid }) => (
        <Grid spacing={16}>
          <FormHeader
            title="Première connexion"
            subtitle="Merci de vérifier ou compléter vos informations et de définir votre mot de passe."
          />
          <GridItem width="100%">
            <TextInputField
              variant={InputLayoutVariant.fill}
              name="firstName"
              label="Prénom"
            />
          </GridItem>
          <GridItem width="100%">
            <TextInputField
              variant={InputLayoutVariant.fill}
              label="Nom"
              name="lastName"
            />
          </GridItem>
          <GridItem width="100%">
            <input type="hidden" />
            <input name="email" type="hidden" />
            <PasswordField
              autoComplete="off"
              variant={InputLayoutVariant.fill}
              label="Votre mot de passe"
              hint="Pour un maximum de sécurité, choisissez un mot de passe fort, en suivant notre indicateur."
              name="password"
            />
            <PasswordStrength password={values.password} />
          </GridItem>
          <FormFooter
            isSubmitting={isSubmitting}
            isValid={isValid}
            primaryAction={{
              text: 'Valider'
            }}
          />
        </Grid>
      )}
    </Form>
  );
};

import React, { useState, useCallback } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { MdAdd } from 'react-icons/md';
import SubHeader from 'components/v3/SubHeader';
import { FilterBar } from 'components/v3/Filters';
import { Container, Title, Button } from 'components/v3/ui';
import { TeamSelector, TeamAction } from 'features/Teams';
import TeamList from 'features/Teams/components/v3/List';

const defaultParams = { sort: 'position,displayName' };

const Teams = () => {
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  const [count, setCount] = useState();
  const [filters, setFilters] = useState();

  const handleDelete = useCallback(
    item => dispatch(TeamAction.deleteTeam({ id: item.id })),
    [dispatch]
  );

  const handleSort = useCallback(
    (items, item) =>
      dispatch(
        TeamAction.updateTeamsOrder({
          payload: {
            id: item.id,
            position: item.position
          },
          payloads: items
        })
      ),
    [dispatch]
  );

  const handleArchive = useCallback(
    item =>
      dispatch(
        TeamAction.updateTeam({
          id: item.id,
          payload: {
            archived: item.archived
          }
        })
      ),
    [dispatch]
  );

  return (
    <Container vertical>
      <SubHeader>
        <Title>{`Équipes${count != null ? ` (${count})` : ''}`}</Title>
        <Button small to={`${path}/nouvelle-equipe`} icon={MdAdd}>
          Nouvelle équipe
        </Button>
      </SubHeader>
      <Container vertical huge padded>
        <FilterBar
          big
          searchQuery="displayNameContAny"
          onChanges={setFilters}
        />
        <TeamList
          spacing={8}
          headerVariant="transparent"
          selector={TeamSelector.selectTeams}
          action={TeamAction.getTeams}
          onCountChanged={setCount}
          params={defaultParams}
          filters={filters}
          perPage={50}
          detailUrlPrefix=""
          isDeletable
          deleteRequest={handleDelete}
          isSortable
          onSortChanged={handleSort}
          isArchivable
          onToggleArchive={handleArchive}
        />
      </Container>
    </Container>
  );
};

export default Teams;

import React, { useCallback, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import get from 'lodash/get';
import { MdError } from 'react-icons/md';
import { fontSize } from 'ui';
import { inputFontSize, Text, Icon as RawIcon } from '../ui';
import { ChipWrapper } from '../Chip/Chip';
import { ChipLayout } from '../Chip/ChipLayout';
import Tooltip from 'components/v3/Tooltip';

const labelFontSize = inputFontSize;
const floatingLabelScale = 0.75;
const floatingLabelSize = floatingLabelScale * labelFontSize;

export const InputLayoutVariant = {
  default: 'fill',
  getDefault: variant => variant.split('.')[0],
  outline: 'outline',
  fill: 'fill',
  flat: {
    normal: 'flat.normal',
    medium: 'flat.medium',
    small: 'flat.small'
  }
};

export const InputLayoutTheme = {
  lightContainer: 'lightContainer',
  darkContainer: 'darkContainer'
};

export const FieldBackground = styled.div`
  width: 100%;
  pointer-events: none;
  display: none;
`;

const floatingLabelStyle = {
  fill: css`
    top: 5px;
    left: 9px;
    transform: scale(${floatingLabelScale});
    color: ${({ theme }) => theme.accent};
    font-weight: 600;

    ${({ hasTrailingIcon }) =>
      css`
        max-width: calc(125% - ${hasTrailingIcon ? '42px' : '24px'});
      `}
  `,
  outline: css`
    top: 0px;
    left: ${fontSize(floatingLabelSize / 2)} !important;
    transform: scale(${floatingLabelScale}) translateY(-50%);
    color: ${({ theme }) => theme.accent};
    font-weight: 600;
    max-width: calc(125% - 42px);

    ${({ hasTrailingIcon }) =>
      css`
        max-width: calc(125% - ${hasTrailingIcon ? '42px' : '24px'});
      `}
  `,
  flat: css`
    opacity: 0;
  `
};

const Variations = {
  outline: {
    icon: {
      leading: css`
        & ~ label {
          left: 41px;
        }
      `
    },
    default: css`
      margin-top: calc(${fontSize(floatingLabelSize / 2)} + 2px);

      > label {
        border-radius: ${({ theme }) => theme.borderInputRadius};
      }

      > input,
      * > input {
        padding: 14px 16px;
      }
      > textarea,
      * > textarea {
        padding: 14px 16px;
      }
      * > .rm-progress {
        left: 5px;
        width: calc(100% - 10px);
      }

      > ${FieldBackground} {
        display: block;
        border-radius: ${({ theme }) => theme.borderInputRadius};
        border: ${({ theme }) => theme.borderInput};
        border-color: ${({ theme, error }) =>
          error ? theme.error : theme.borderInputColor};

        position: absolute;
        left: 0px;
        top: 0px;
        right: 0px;
        bottom: 0px;
      }

      &:focus:not([readOnly]):not([disabled]),
      &:focus-within:not([readOnly]):not([disabled]) {
        > label {
          ${floatingLabelStyle.outline};
        }
        > ${FieldBackground} {
          border: 2px solid
            ${({ theme, error }) => (error ? theme.error : theme.primaryLight)};
        }
      }

      ${({ focused }) =>
        focused &&
        css`
          &:not([readOnly]):not([disabled]) {
            > label {
              ${floatingLabelStyle.outline};
            }
            > ${FieldBackground} {
              border: 2px solid
                ${({ theme, error }) =>
                  error ? theme.error : theme.primaryLight};
            }
          }
        `}

      &:hover:not(:focus):not(:focus-within):not([readOnly]):not([disabled]) {
        > ${FieldBackground} {
          border-color: ${({ theme, error }) =>
            error ? theme.error : theme.textSecondary};
        }
      }
    `,
    lightContainer: css`
      > label {
        background-color: ${({ theme }) => theme.backgroundLight};
      }
    `,
    darkContainer: css`
      > label {
        background-color: ${({ theme }) => theme.background};
      }
    `
  },
  fill: {
    icon: {
      leading: css`
        & ~ label {
          left: 41px !important;
        }
      `
    },
    default: css`
      border-top-left-radius: ${({ theme }) => theme.borderInputRadius};
      border-top-right-radius: ${({ theme }) => theme.borderInputRadius};
      > input {
        min-height: 50px;
      }
      > input,
      > input.rm-inputLayoutComponent,
      * > input.rm-inputLayoutComponent {
        padding: calc(${fontSize(floatingLabelSize / 2)} + 11px + 5px) 16px 6px;
      }
      > textarea {
        min-height: 50px;
      }
      > textarea,
      > textarea.rm-inputLayoutComponent,
      * > textarea.rm-inputLayoutComponent {
        padding: calc(${fontSize(floatingLabelSize / 2)} + 14px + 5px) 16px 7px;
      }
      > .rm-inputLayoutComponent:not(textarea):not(input),
      * > .rm-inputLayoutComponent:not(textarea):not(input) {
        padding-top: calc(${fontSize(floatingLabelSize / 2)} + 10px);
        min-height: 50px;
        > input,
        * > input {
          padding: 6px 16px 6px;
        }
        > textarea,
        * > textarea {
          padding: 8px 16px 8px;
        }
      }
      * > .rm-progress {
        left: 0;
        width: 100%;
        bottom: 0;
      }
      > label {
        background-color: transparent;
      }
      > ${FieldBackground} {
        display: none;
        position: relative;
        border-bottom: 1px solid
          ${({ theme, error }) =>
            error ? `${theme.error} !important` : theme.borderInputColor};
      }
      > ${FieldBackground}:before, > ${FieldBackground}:after {
        content: '';
        height: 2px;
        width: 0px;
        bottom: 0px;
        position: absolute;
        background: ${({ theme, error }) =>
          error ? theme.error : theme.primaryLight};
        transition: 0.2s ease all;
      }

      > ${FieldBackground}:before {
        left: 50%;
      }
      > ${FieldBackground}:after {
        right: 50%;
      }

      &:focus:not([readOnly]):not([disabled]),
      &:focus-within:not([readOnly]):not([disabled]) {
        > label {
          ${floatingLabelStyle.fill}
        }
        > ${FieldBackground}:after, > ${FieldBackground}:before {
          width: 50%;
        }
      }
    `,
    lightContainer: css`
      background-color: #f6f6f6;
      &:focus:not([readOnly]):not([disabled]),
      &:focus-within:not([readOnly]):not([disabled]) {
        background-color: #eaeaea;
      }
      &:hover:not(:focus):not(:focus-within):not([readOnly]):not([disabled]) {
        background-color: #f0f0f0;
      }
    `,
    darkContainer: css`
      background-color: #ffffff;
      &:hover:not(:focus):not(:focus-within):not([readOnly]):not([disabled]) {
        > ${FieldBackground} {
          border-bottom: 1px solid ${({ theme }) => theme.textSecondary};
        }
      }
    `
  },
  flat: {
    // icon: {
    //   leading: css`
    //     & ~ label {
    //       left: 41px !important;
    //     }
    //   `
    // },
    default: css`
      &:focus,
      &:focus-within {
        > label {
          ${floatingLabelStyle.flat}
        }
      }
    `,
    normal: css`
      min-height: 46px;
      > .rm-inputLayoutComponent:not(textarea):not(input),
      * > .rm-inputLayoutComponent:not(textarea):not(input) {
        min-height: 46px;
        display: flex;
        align-items: center;
      }
      > input,
      * > input {
        padding: 12px 16px;
      }
      > textarea,
      * > textarea {
        padding: 14px 16px;
      }
      * > .rm-progress {
        width: 100%;
      }
      ${ChipLayout} {
        input,
        textarea {
          min-height: 30px;
        }
      }
    `,
    medium: css`
      font-size: 0.9375rem;
      min-height: 40px;
      > .rm-inputLayoutComponent:not(textarea):not(input),
      * > .rm-inputLayoutComponent:not(textarea):not(input) {
        min-height: 40px;
        display: flex;
        align-items: center;
      }
      > *,
      > {
        font-size: 0.9375rem;
      }
      ${ChipWrapper} {
        padding-top: 2px;
        padding-bottom: 2px;
      }
      ${ChipLayout} {
        input,
        textarea {
          min-height: 28px;
        }
      }
      > label {
        font-size: 0.9375rem;
        padding-left: 5px;
      }
      > input,
      * > input {
        padding: 9px 13px;
      }
      > textarea,
      * > textarea {
        padding: 11px 13px;
      }
      * > .rm-progress {
        width: 100%;
      }
    `,
    small: css`
      font-size: 0.875rem;
      min-height: 34px;
      > .rm-inputLayoutComponent:not(textarea):not(input),
      * > .rm-inputLayoutComponent:not(textarea):not(input) {
        min-height: 34px;
        display: flex;
        align-items: center;
      }
      > label {
        padding-left: 0px;
      }
      * > *,
      > *,
      > label {
        font-size: 0.875rem;
      }
      ${ChipWrapper} {
        padding-top: 1px;
        padding-bottom: 1px;
      }
      ${ChipLayout} {
        input,
        textarea {
          min-height: 26px;
        }
      }
      > input,
      * > input {
        padding: 6px 10px;
      }
      > textarea,
      * > textarea {
        padding: 8px 10px;
      }
      * > .rm-progress {
        width: 100%;
      }
    `
  }
};

export const FieldGroup = styled.div`
  position: relative;
  transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1) background-color;

  > label {

    color: ${({ theme, error }) =>
      error ? `${theme.error} !important` : theme.textSecondary};

    ${({ value, ...props }) => {
      if (value != null && value !== '') {
        return css`
          ${get(floatingLabelStyle, props['variant-type'].split('.')[0])};
          color: ${({ theme }) => theme.primaryLight};
        `;
      }
    }}
  }

  ${({ focused, ...props }) =>
    focused === undefined &&
    css`
      input:not([value='']) ~ label {
        ${get(floatingLabelStyle, props['variant-type'].split('.')[0])};
      }

      input:-webkit-autofill ~ label {
        ${get(floatingLabelStyle, props['variant-type'].split('.')[0])};
        color: ${({ theme }) => theme.primaryLight};
      }

      &:not([readOnly]) input:not([value='']) ~ label {
        color: ${({ theme }) => theme.primaryLight};
      }

      &[readOnly] input:not([value='']) ~ label {
        color: ${({ theme }) => theme.textSecondary};
      }
    `}

  ${({ focused, ...props }) =>
    focused &&
    css`
      > label {
        ${get(floatingLabelStyle, props['variant-type'].split('.')[0])};
        color: ${({ theme }) => theme.accent};
      }
    `}

  &[disabled] {
    opacity: 0.64;
    cursor: not-allowed;

    > label {
      color: ${({ theme }) => theme.textSecondary} !important;
    }
  }

  ${props => Variations[props['variant-type'].split('.')[0]].default}
  ${props => get(Variations, props['variant-type'])}
  ${props => get(Variations, props['variant-type'])[props['variant-theme']]}
`;

const FieldLabel = styled.label`
  position: absolute;
  max-width: 100%;
  left: 10px;
  top: 50%;
  z-index: 1;
  font-size: ${fontSize(labelFontSize)};
  padding-left: 10px;
  padding-right: 10px;
  transform: translateY(-50%);
  transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1) all;
  // will-change: transform; // TODO: check ca, car ca "blurry" le label
  transform-origin: left top;
  pointer-events: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  ${({ hasTrailingIcon }) =>
    css`
      max-width: calc(100% - ${hasTrailingIcon ? '42px' : '24px'});
    `}
`;

const Icon = styled(RawIcon)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  opacity: 0.84;

  ${({ position }) =>
    position === 'leading' &&
    css`
      & ~ input,
      & ~ textarea {
        padding-left: 48px !important;
      }
      & ~ .rm-inputLayoutComponent:not(textarea):not(input) {
        padding-left: 32px !important;
      }

      & ~ label {
        left: 35px;
      }
      ${props => {
        const variant = props['variant-type'];
        switch (variant && variant.split('.')[1]) {
          case 'small':
            return css`
              ${variant.split('.')[0] !== 'outline' &&
                css`
                  & ~ label {
                    left: 28px !important;
                  }
                `}
              left: 4px;
              & ~ input,
              & ~ textarea {
                padding-left: 28px !important;
              }

              & ~ .rm-inputLayoutComponent:not(textarea):not(input) {
                padding-left: 12px !important;
                ${ChipLayout} {
                  margin-left: 12px;
                }
              }

              height: 24px;
              width: 24px;
              svg {
                height: 20px;
                width: 20px;
              }
            `;
          case 'medium':
            return css`
              ${variant.split('.')[0] !== 'outline' &&
                css`
                  & ~ label {
                    left: 33px !important;
                  }
                `}
              left: 6px;
              & ~ input,
              & ~ textarea {
                padding-left: 36px !important;
              }
              & ~ .rm-inputLayoutComponent:not(textarea):not(input) {
                padding-left: 20px !important;
                ${ChipLayout} {
                  margin-left: 20px;
                }
              }
              height: 24px;
              width: 24px;
              svg {
                height: 20px;
                width: 20px;
              }
            `;
          default:
            return css`
              ${variant.split('.')[0] !== 'outline' &&
                css`
                  & ~ label {
                    left: 41px !important;
                  }
                `}
              & ~ .rm-inputLayoutComponent:not(textarea):not(input) {
                ${ChipLayout} {
                  margin-left: 4px;
                }
              }
              left: 8px;
            `;
        }
      }}
    `}

  ${({ position }) =>
    position === 'trailing' &&
    css`
      right: 6px;
      & ~ input,
      & ~ textarea {
        padding-right: 36px;
      }
      & ~ .rm-inputLayoutComponent:not(textarea):not(input) {
        padding-right: 24px;
      }
    `}
  ${({ kind }) =>
    kind === 'error' &&
    css`
      color: ${({ theme }) => theme.error};
    `}

  ${({ clickEnabled, position }) =>
    clickEnabled &&
    css`
      cursor: pointer;
      opacity: ${position === 'leading' ? 1 : 0.38};
      z-index: 1;

      &:hover {
        opacity: 1;
      }
    `}

`;

const MessageText = styled(Text)`
  padding-top: 4px;
  padding-left: 16px;
  padding-right: 16px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  ${props =>
    props['fill-width'] &&
    css`
      width: 100%;
    `}
`;

const InputLayout = React.forwardRef(
  (
    {
      label,
      hint,
      variant = InputLayoutVariant.default,
      theme = InputLayoutTheme.lightContainer,
      onLeadingIconClick,
      iconLeading: IconLeading,
      leadingIconClickable,
      onTrailingIconClick,
      trailingTooltip,
      iconTrailing: IconTrailing,
      trailingIconClickable,
      style,
      focused = undefined,

      component: Component,
      tooltip: propsTooltip,

      error,
      fillWidth,
      ...props
    },
    ref
  ) => {
    const labelRef = useRef();
    const [tooltip, setTooltip] = useState(propsTooltip);
    const { name, value, required, disabled, readOnly } = props;

    const handleLeadingIconClick = useCallback(
      e => {
        onLeadingIconClick && onLeadingIconClick(e, { name, value });
      },
      [onLeadingIconClick, name, value]
    );
    const handleTrailingIconClick = useCallback(
      e => {
        if (onTrailingIconClick) {
          e.stopPropagation();
          onTrailingIconClick(e, { name, value });
        }
      },
      [onTrailingIconClick, name, value]
    );

    let variantType;
    if (variant) {
      variantType =
        typeof get(InputLayoutVariant, variant) === 'string'
          ? variant
          : variant.normal;
    } else {
      variantType = InputLayoutVariant.default;
    }

    const handleLabelMouseEnter = useCallback(() => {
      if (
        !propsTooltip &&
        label &&
        labelRef.current?.offsetWidth < labelRef.current.scrollWidth
      ) {
        setTooltip(label);
      }
    }, [propsTooltip, label]);

    const hasTrailingIcon = IconTrailing || error;

    return (
      <Tooltip content={tooltip}>
        <Container
          fill-width={fillWidth ? 'true' : undefined}
          onMouseEnter={handleLabelMouseEnter}
        >
          <FieldGroup
            variant-type={variantType}
            variant-theme={theme}
            style={style}
            error={error}
            disabled={disabled}
            readOnly={readOnly}
            hasTrailingIcon={hasTrailingIcon}
            value={
              Array.isArray(value)
                ? value.length > 0
                  ? 'true'
                  : undefined
                : value != null && value !== ''
                ? 'true'
                : undefined
            }
            focused={focused}
          >
            {IconLeading && (
              <Icon
                variant-type={variantType}
                size="medium"
                onClick={handleLeadingIconClick}
                position="leading"
                clickEnabled={
                  (Boolean(onLeadingIconClick) &&
                    leadingIconClickable === undefined) ||
                  leadingIconClickable
                }
              >
                {typeof IconLeading === 'function' ? (
                  <IconLeading />
                ) : (
                  IconLeading
                )}
              </Icon>
            )}
            {IconTrailing && !error && (
              <Tooltip content={trailingTooltip}>
                <Icon
                  position="trailing"
                  size="small"
                  onClick={handleTrailingIconClick}
                  clickEnabled={
                    (Boolean(onTrailingIconClick) &&
                      trailingIconClickable === undefined) ||
                    trailingIconClickable
                  }
                >
                  {typeof IconTrailing === 'function' ? (
                    <IconTrailing />
                  ) : (
                    IconTrailing
                  )}
                </Icon>
              </Tooltip>
            )}
            {error && (
              <Tooltip content={error}>
                <Icon position="trailing" kind="error">
                  <MdError size={24} />
                </Icon>
              </Tooltip>
            )}
            <Component
              variant-type={variantType}
              type="text"
              tabIndex={disabled || readOnly ? '-1' : '0'}
              className="rm-inputLayoutComponent"
              ref={ref}
              {...props}
            />
            {label && (
              <FieldLabel
                hasTrailingIcon={hasTrailingIcon}
                ref={labelRef}
                htmlFor={props.name}
              >{`${label}${required ? '*' : ''}`}</FieldLabel>
            )}
            <FieldBackground />
          </FieldGroup>
          {hint && (
            <MessageText variant="hint" color="hint">
              {hint}
            </MessageText>
          )}
        </Container>
      </Tooltip>
    );
  }
);

export default React.memo(InputLayout);

import React from 'react';
import InputLayout from '../InputLayout';
import { DebouncedInput } from '../ui';

const TextInputLayout = React.forwardRef(
  ({ textArea = false, ...props }, ref) => {
    return (
      <InputLayout
        {...props}
        ref={ref}
        textArea={textArea}
        component={DebouncedInput}
      />
    );
  }
);

export default React.memo(TextInputLayout);

import React, { Fragment, useCallback, useState } from 'react';
import { GrDocumentText } from 'react-icons/gr';
import { Icon, Text } from 'components/v3/ui';
import Table from 'components/v3/List/Table';
import VoucherModal from './VoucherModal';
import VoucherCheck from './VoucherCheck';
import Author from 'features/Users/components/v3/Author';
import { printDate } from 'utils/dates';
import { VoucherAction } from '../data';
import { useDispatch } from 'react-redux';
import { useAccountConfig } from 'config';

const columns = [
  {
    Header: ' ',
    accessor: 'icon',
    width: '56px',
    Cell: () => (
      <Icon>
        <GrDocumentText />
      </Icon>
    )
  },
  {
    Header: 'Document',
    accessor: 'voucherKind',
    width: '30%',
    Cell: ({ item }) => <Text variant="small">{item.voucherKind?.name}</Text>
  },
  {
    Header: 'Version',
    accessor: 'versions',
    width: 80,
    minWidth: 80,
    maxWidth: 80,
    Cell: ({ item }) => <Text variant="small">{`Version ${String((item.voucherVersions ?? []).length)}`}</Text>
  },
  {
    Header: 'Auteur',
    accessor: 'email',
    minWidth: 220,
    Cell: ({ item }) => (
      <Author
        user={item.lastVersion?.createdBy}
        size="small"
        subtitle={printDate(item.lastVersion?.createdAt)}
        variant="small"
      />
    )
  },
  {
    Header: 'Valideur',
    accessor: 'createdBy',
    width: 'calc(40% - 56px)',
    minWidth: 250,
    Cell: ({ item }) => {
      return item.lastVersion ? <VoucherCheck voucherVersion={item.lastVersion} editable /> : null;
    }
  }
];

const VoucherList = ({ voucherableId, voucherableType, vouchers, spacing = 0, ...props }) => {
  const [voucherIdOpen, setVoucherIdOpen] = useState();
  const dispatch = useDispatch();
  const { hasPermission } = useAccountConfig();
  const handleDelete = useCallback((item) => dispatch(VoucherAction.deleteVoucher({ id: item.id })), [dispatch]);

  return (
    <Fragment>
      <Table
        columns={columns}
        items={vouchers}
        maxHeight={500}
        onClick={(voucher) => {
          setVoucherIdOpen(voucher?.id);
        }}
        estimatedItemSize={54 + spacing / 2}
        spacing={spacing}
        isDeletable={hasPermission('voucher.delete')}
        deleteRequest={handleDelete}
        {...props}
      />
      {voucherIdOpen && (
        <VoucherModal
          voucherId={voucherIdOpen}
          voucherableId={voucherableId}
          voucherableType={voucherableType}
          onRequestClose={() => setVoucherIdOpen(undefined)}
        />
      )}
    </Fragment>
  );
};

export default VoucherList;

import React, { useState, useCallback } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { MdAdd } from 'react-icons/md';
import SubHeader from 'components/v3/SubHeader';
import { FilterBar } from 'components/v3/Filters';
import { Container, Title, Button } from 'components/v3/ui';
import {
  MaterialSelector,
  MaterialAction,
  ExtractionMaterialList
} from 'features/Materials';

const defaultParams = { sort: 'name' };

export const ExtractionMaterials = () => {
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  const [count, setCount] = useState();
  const [filters, setFilters] = useState();

  const handleDelete = useCallback(
    item => dispatch(MaterialAction.deleteExtractionMaterial({ id: item.id })),
    [dispatch]
  );

  return (
    <Container vertical>
      <SubHeader>
        <Title>{`Materiaux sortants${
          count != null ? ` (${count})` : ''
        }`}</Title>
        <Button small to={`${path}/nouveau-sortant`} icon={MdAdd}>
          Nouveau sortant
        </Button>
      </SubHeader>
      <Container vertical huge padded>
        <FilterBar big searchQuery="nameContAny" onChanges={setFilters} />
        <ExtractionMaterialList
          spacing={8}
          headerVariant="transparent"
          selector={MaterialSelector.selectExtractionMaterials}
          action={MaterialAction.getExtractionMaterials}
          onCountChanged={setCount}
          params={defaultParams}
          filters={filters}
          perPage={50}
          detailUrlPrefix=""
          isDeletable
          deleteRequest={handleDelete}
        />
      </Container>
    </Container>
  );
};

import flatten from 'lodash/flatten';
import { createDeepEqualSelector } from 'common/selector';
import { selectCalendarCheckedIds } from 'features/UiPreferences/data/selector';
import { PlanningSelector } from 'features/Plannings';
import isSameIds from 'utils/idUtils';

export const selectPhases = createDeepEqualSelector(
  [PlanningSelector.selectCalendarEvents, selectCalendarCheckedIds],
  (events, calendarCheckedIds) => {
    return flatten(
      events.map(event => {
        switch (event.source) {
          case 'holiday':
          case 'phase':
            return (
              event.users
                ?.filter(user =>
                  Boolean(calendarCheckedIds.find(id => isSameIds(id, user.id)))
                )
                .map(user => ({
                  ...event,
                  userId: user.id,
                  id: `${event.id}#${user.id}`,
                  color:
                    (event.source !== 'holiday' && user.settings?.color) ||
                    event.color
                })) || []
            );
          default:
            return event;
        }
      })
    );
  }
);

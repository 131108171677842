import { post, destroy } from 'common/api';
import { jsonApiToObject } from 'common/api';

export const postAttachments = async (attachments, kind) => {
  if (!attachments || attachments.length === 0) {
    return [];
  }
  const attachmentsToDelete = attachments.filter(
    attachment => attachment._destroy
  );
  const attachmentsToCreate = attachments.filter(
    attachment => !attachment._destroy && attachment.isLocal
  );

  // Send destroy request to all attachments with _destroy mark.
  await Promise.all(
    attachmentsToDelete.map(attachment => {
      return deleteAttachment(attachment.id);
    })
  );

  let createdAttachments = [];

  for (const file of attachmentsToCreate) {
    const formData = new FormData();
    formData.append('attachment[source]', file);
    if (kind) {
      formData.append('attachment[kind]', kind);
    }
    try {
      const result = await post('/v5/attachments', formData);
      const attachment = jsonApiToObject(result);
      createdAttachments = [...createdAttachments, attachment];
    } catch (e) {}
  }

  return [
    ...attachments.filter(
      attachment => !attachment._destroy && !attachment.isLocal
    ),
    ...createdAttachments
  ];
};

export const deleteAttachment = (id, config) =>
  destroy(`/v5/attachments/${id}`, config);

import React, {
  useCallback,
  useMemo,
  Fragment,
  useState,
  useEffect
} from 'react';
import { SelectField } from 'components/v3/Form';
import { SelectInputLayout } from 'components/v3/InputLayout';
import { useRedux } from 'components/v3/utils';
import UnitModal from './UnitModal';
import { jsonApiToObject } from 'common/api';
import {
  AccountingSelector,
  AccountingAction,
  unitAsOption
} from 'features/Accounting';
import { permissions, useAccountConfig } from 'config';

const formatCreateLabel = input => `Créer l'unité ${input}`;

const UnitSelect = ({ value, onChange, fetchOnMount = true, ...props }) => {
  const { hasPermission } = useAccountConfig();

  const [unitModal, setUnitModal] = useState();
  const [units, fetch] = useRedux(
    AccountingSelector.selectUnits,
    AccountingAction.getUnits,
    {
      fetchOnMount: false,
      useLocalState: true,
      initialParams: {
        page: 1,
        perPage: 500,
        useCache: true
      }
    }
  );

  const handleCreateOption = useCallback(input => {
    setUnitModal({
      displayName: input
    });
  }, []);

  const handleEditOption = useCallback(value => {
    setUnitModal(value);
  }, []);

  const options = useMemo(() => unitAsOption(units), [units]);

  const currentValue = useMemo(() => unitAsOption(value), [value]);

  useEffect(() => {
    if (!fetchOnMount) return;
    fetch(params => ({
      ...params,
      page: 1,
      useCache: true
    }));
  }, [fetch, fetchOnMount]);

  return (
    <Fragment>
      <SelectInputLayout
        label="Unité"
        value={currentValue}
        options={options}
        isSearchable
        onChange={onChange}
        inputCreatable={hasPermission([permissions.ACCOUNTING, 'unit.create'])}
        createLabel="Créer une unité"
        formatCreateLabel={formatCreateLabel}
        onCreateOption={handleCreateOption}
        optionEditable={hasPermission([permissions.ACCOUNTING, 'unit.update'])}
        onEditOptionClick={handleEditOption}
        {...props}
      />
      {unitModal && (
        <UnitModal
          unit={unitModal}
          onRequestClose={() => setUnitModal(null)}
          onSubmitSuccess={response => {
            const unit = jsonApiToObject(response);
            if (props.multi) {
              onChange([...currentValue, unit]);
            } else {
              onChange(unit);
            }
          }}
        />
      )}
    </Fragment>
  );
};

export const UnitField = props => {
  return <SelectField component={UnitSelect} {...props} />;
};

export default UnitSelect;

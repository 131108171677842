import React, { Fragment, useEffect, useCallback, useMemo } from 'react';
import {
  useRouteMatch,
  Route,
  useHistory,
  useLocation
} from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import List from 'components/v3/List/List';
import Table from 'components/v3/List/Table';
import Tooltip from 'components/v3/Tooltip';
import { Text, Dot, Container, NavLink, ButtonIcon } from 'components/v3/ui';
import { List as RawList, ListItem } from 'components/v3/ui/list';
import { useListFetcher } from 'components/v3/utils';
import PmvModal from './Modal';
import PmvCard from './Card';
import { PMV_STATE, PMV_ERROR, PMV_LINE_STYLES } from 'features/Pmvs';
import { MdRefresh } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { readPmv } from 'features/Pmvs/data/action';

const COLUMNS = [
  {
    Header: ' ',
    accessor: 'error',
    width: 24,
    minWidth: 24,
    Cell: ({ item }) => {
      const state = PMV_STATE[item.state];
      return (
        <Tooltip
          content={
            <Container vertical>
              {state?.message}
              {item.lastError && <div>{PMV_ERROR[item.lastError]}</div>}
            </Container>
          }
        >
          <Dot color={state.color} size={14} />
        </Tooltip>
      );
    }
  },
  {
    Header: 'Nom',
    accessor: 'ip',
    width: 'calc(40% - 24px)',
    Cell: ({ item }) => (
      <Text variant="caption" color="theme">
        {item.ip}
      </Text>
    )
  },
  {
    Header: 'Message',
    accessor: 'message',
    width: 'calc(60% - 56px)',
    Cell: ({ item }) => {
      const hasLines = item.lines.find(({ text }) => Boolean(text?.trim()));
      if (!hasLines) {
        return null;
      }
      return (
        <RawList>
          {item.lines.map(({ text, style, id }) => {
            return (
              <ListItem key={id}>
                <Text
                  variant="small"
                  className={style}
                  style={{ whiteSpace: text?.trim() ? undefined : 'pre-wrap' }}
                  weight={PMV_LINE_STYLES.find(s => s.value === style)?.weight}
                  alignCenter
                >
                  {text?.trim() || ' '}
                </Text>
              </ListItem>
            );
          })}
        </RawList>
      );
    }
  }
];

const PmvList = ({
  selector,
  action,
  options = {
    fetchOnMount: false,
    useLocalState: true
  },
  params = {
    sort: 'ip'
  },
  perPage = 25,
  filters,
  onCountChanged,
  detailUrlPrefix = '/pmvs',
  asCard,
  onListChanged,
  onStateChanged,
  ...props
}) => {
  const { path, url } = useRouteMatch();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const shouldUseCardView = useMediaQuery({ query: '(max-width: 600px)' });
  const isCardView = asCard || shouldUseCardView;
  const Component = isCardView ? List : Table;

  const [pmvs, paginationHelper, state] = useListFetcher({
    selector,
    action,
    options,
    params,
    perPage,
    filters
  });

  const detailLinkFormatter = useCallback(
    item => `${path}${detailUrlPrefix}/${item.id}`,
    [path, detailUrlPrefix]
  );

  const cardRenderer = useCallback(
    ({ item }) => (
      <PmvCard as={NavLink} to={detailLinkFormatter(item)} pmv={item} />
    ),
    [detailLinkFormatter]
  );

  useEffect(() => {
    if (paginationHelper.total != null && onCountChanged) {
      onCountChanged(paginationHelper.total);
    }
  }, [paginationHelper.total, onCountChanged]);

  useEffect(() => {
    if (onListChanged) {
      onListChanged(pmvs);
    }
  }, [onListChanged, pmvs]);

  useEffect(() => {
    if (onStateChanged) {
      onStateChanged(state);
    }
  }, [onStateChanged, state]);

  const columns = useMemo(() => {
    return [
      ...COLUMNS,
      {
        Header: '',
        accessor: 'refresh',
        width: 56,
        minWidth: 56,
        Cell: ({ item }) => (
          <ButtonIcon
            tooltip="Rafraichir l'état"
            isLoading={item.isLoading}
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              dispatch(readPmv(item.id));
            }}
          >
            {!item.isLoading && <MdRefresh />}
          </ButtonIcon>
        )
      }
    ];
  }, [dispatch]);

  return (
    <Fragment>
      <Component
        scrollOverlay={isCardView}
        columns={columns}
        items={pmvs}
        isLoading={state.isLoading}
        linkFormatter={detailLinkFormatter}
        centerMessage={state.errorMessage}
        {...paginationHelper}
        {...props}
        rowRenderer={isCardView ? cardRenderer : undefined}
        estimatedItemSize={isCardView ? 120 : props.estimatedItemSize}
      />
      <Route
        path={[
          `${path}${detailUrlPrefix}/nouveau-panneau`,
          `${path}${detailUrlPrefix}/:pmvId`
        ]}
      >
        <PmvModal
          onRequestClose={() => {
            if (location.key) {
              history.goBack();
            } else {
              history.replace(url);
            }
          }}
        />
      </Route>
    </Fragment>
  );
};

export default PmvList;

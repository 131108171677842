import { getParams, createDeepEqualSelector, getProps } from 'common/selector';
import { getState, selectRelations } from 'common/selector';

export const getProjectKinds = state => state.projectKinds;
export const getProjectActivities = state => state.projectActivities;

export const selectProjectKinds = createDeepEqualSelector(
  [getProjectKinds, getState],
  ({ projectKindsIds, projectKindById }, state) =>
    projectKindsIds
      .map(id => selectRelations('projectKind', projectKindById[id], state))
      .filter(Boolean)
);

export const selectProjectKind = createDeepEqualSelector(
  [getProjectKinds, getParams, getProps, getState],
  (
    { projectKindById },
    { projectKindId },
    { projectKind: propsKind },
    state
  ) => {
    const projectKind =
      projectKindById[projectKindId || (propsKind && propsKind.id)];

    if (!projectKind) {
      return {};
    }
    return selectRelations('projectKind', projectKind, state);
  }
);

export const selectProjectKindV3 = createDeepEqualSelector(
  [getProjectKinds, getProps, getState],
  ({ projectKindById }, { projectKindId }, state) => {
    return selectRelations(
      'projectKind',
      projectKindById[projectKindId],
      state
    );
  }
);

export const selectProjectActivity = createDeepEqualSelector(
  [getProjectActivities, getProps, getState],
  ({ projectActivityById }, { activityId }, state) =>
    selectRelations('projectActivity', projectActivityById[activityId], state)
);

export {
  OrganizationApi,
  OrganizationAction,
  OrganizationReducer,
  OrganizationSelector
} from './data';

export const organizationAsOption = organization => {
  if (Array.isArray(organization)) {
    return organization.map(organizationAsOption);
  } else if (organization) {
    return {
      value: organization.id,
      color: organization.color,
      label: organization.displayName,
      original: organization
    };
  } else {
    return null;
  }
};

export const organizationAsValues = organization => {
  return {
    id: organization?.id || null,
    displayName: organization?.displayName || '',
    reference: organization?.reference || '',
    address: organization?.address || null,
    phone: organization?.phone || '',
    email: organization?.email || ''
  };
};

import React from 'react';
import { TextInputLayout, SelectInputLayout } from 'components/v3/InputLayout';
import Checkbox from 'components/v3/Checkbox';
import DatePicker from 'components/v3/DatePicker';
import isEqual from 'lodash/isEqual';

const CustomField = React.memo(
  ({ field, data, dataPrefix, onChange, readOnly }) => {
    const handleChange = data => {
      onChange({
        ...data,
        name: `${dataPrefix || ''}${data.name}`
      });
    };

    switch (field.kind) {
      case 'text':
      case 'textarea': {
        return (
          <TextInputLayout
            label={field.label}
            name={field.name}
            hint={field.hint}
            value={data.value || ''}
            required={field.required}
            readOnly={readOnly}
            textArea={field.kind === 'textarea'}
            minRows={3}
            onChange={value => {
              handleChange({
                ...data,
                value
              });
            }}
          />
        );
      }
      case 'checkbox': {
        let value = data.value;
        return (
          <Checkbox
            label={field.label}
            name={field.name}
            readOnly={readOnly}
            value={value || false}
            onChange={value =>
              handleChange({
                ...data,
                value
              })
            }
          />
        );
      }
      case 'select': {
        const settings = field.settings;
        if (!settings) {
          return null;
        }
        return (
          <SelectInputLayout
            value={settings.multi ? data.value || [] : data.value}
            label={field.label}
            options={settings.options}
            multi={settings.multi}
            inputCreatable={false}
            optionEditable={false}
            isClearable={settings.clearable}
            isSearchable={false}
            onChange={value => {
              handleChange({
                ...data,
                value
              });
            }}
          />
        );
      }
      case 'select_or_text': {
        const settings = field.settings;
        if (!settings) {
          return null;
        }
        return (
          <SelectInputLayout
            freeSolo
            label={field.label}
            value={data.value}
            options={settings.options}
            emptyMessage={false}
            inputCreatable={false}
            optionEditable={false}
            isClearable={settings.clearable}
            onChange={value => {
              handleChange({
                ...data,
                value
              });
            }}
            onInputChange={value => {
              handleChange({
                ...data,
                value: { value: value, label: value || '' }
              });
            }}
          />
        );
      }
      case 'datetime':
      case 'date':
      case 'daterange': {
        return (
          <DatePicker
            disableTime={field.kind !== 'datetime'}
            mode={field.kind === 'daterange' ? 'range' : 'single'}
            label={field.label}
            placeholder={field.placeholder}
            name={field.name}
            value={data.value}
            readOnly={readOnly}
            disabled={readOnly}
            onChange={value => {
              handleChange({
                ...data,
                value
              });
            }}
          />
        );
      }
      default: {
        return null;
      }
    }
  },
  isEqual
);

export default CustomField;

import { useCallback, useEffect, useMemo } from 'react';
import { camelize } from 'humps';
import { FormikValues } from 'formik';
import { useSelector, DefaultRootState } from 'react-redux';
import isEqual from 'lodash/isEqual';
import flatten from 'lodash/flatten';
import get from 'lodash/get';
import uniq from 'lodash/uniq';
import { useAction, usePrevious } from '../utils';
import { AccountSelector } from 'features/Accounts';
import { NamingRuleApi } from 'features/NamingRules';
import { getPlural } from 'common';

// https://github.com/kimamula/ts-transformer-keys

interface NamingRuleProps {
  id?: any;
  model?: string;
  values: FormikValues;
  modified: boolean;
  canTriggerNamingRules?: boolean | ((props: FormikValues) => boolean);
  onFetchingValue: (fetching: boolean) => void;
  onValueChanged: (name: string, value: any | undefined | null) => void;
}

interface NamingRuleReturn {
  computeRules: (values: FormikValues) => void;
}

const namingRulesSelector: (state: DefaultRootState, model?: string) => [any] =
  (state: DefaultRootState, model?: string) => {
    if (!model) {
      return [];
    }
    const account = AccountSelector.selectCurrentAccount(state);
    const rules = (account?.namingRules || [])
      .filter(
        (rule: any) => rule.table === model || rule.table === getPlural(model)
      )
      .map((rule: any) => {
        return {
          ...rule,
          triggers: rule.triggers.map((trigger: string) => {
            if (trigger.includes('customData')) {
              return trigger;
            } else {
              return camelize(trigger);
            }
          })
        };
      });
    return rules;
  };

const useNamingRules: (props: NamingRuleProps) => NamingRuleReturn = ({
  id,
  model,
  values,
  modified,
  onFetchingValue,
  onValueChanged,
  canTriggerNamingRules
}) => {
  const action = useAction();
  const modelRulesSelector = useMemo(
    () => (state: DefaultRootState) => namingRulesSelector(state, model),
    [model]
  );
  const namingRules = useSelector(modelRulesSelector, isEqual);
  const triggers = useMemo(
    () => flatten(uniq(namingRules.map((rule: any) => rule.triggers))),
    [namingRules]
  );
  const triggersValues = useMemo(
    () =>
      triggers.reduce((acc, key) => {
        if (key.includes('customData')) {
          const name = key.replace('customData.', '');
          const value = values.customData?.find((d: any) => d.name === name);
          if (value) {
            acc[key] = value?.value;
          }
        } else {
          acc[key] = get(values, key);
        }
        return acc;
      }, {}),
    [triggers, values]
  );
  const canTriggerRules = useMemo(() => {
    if (canTriggerNamingRules instanceof Function) {
      return canTriggerNamingRules(values);
    } else {
      return canTriggerNamingRules === undefined || canTriggerNamingRules;
    }
  }, [canTriggerNamingRules, values]);

  const prevTriggersValues = usePrevious(triggersValues);

  const modifiedTriggers = useMemo(
    () =>
      triggers.filter(trigger => {
        return !isEqual(
          get(prevTriggersValues, trigger),
          get(triggersValues, trigger)
        );
      }),
    [triggers, prevTriggersValues, triggersValues]
  );

  const computeNamingRule = useCallback(
    (rule, values) => {
      if (rule.table === 'invoices' && rule.column === 'number') {
        return;
      }

      onFetchingValue(true);
      action(
        () =>
          NamingRuleApi.getNamingRule({
            ...rule,
            args: [
              ...rule.triggers.map((trigger: string) => {
                if (trigger === 'id') {
                  return id;
                }
                return get(values, trigger) || '';
              })
            ]
          }),
        rule.column,
        true
      )
        .onSuccess(result => {
          onFetchingValue(false);
          const nextValue = result.data?.attributes?.nextValue;
          if (nextValue != null) {
            onValueChanged(camelize(rule.column), nextValue);
          }
        })
        .onError(error => {
          // toaster.error(error);
          console.error(error);
          onFetchingValue(false);
        });
    },
    [id, action, onFetchingValue, onValueChanged]
  );

  useEffect(() => {
    if (
      !modified ||
      !modifiedTriggers ||
      modifiedTriggers.length === 0 ||
      !prevTriggersValues
    ) {
      return;
    }
    namingRules
      .filter(rule => {
        if (rule.table === 'invoices' && rule.column === 'number') {
          return false;
        }
        return rule.triggers.some((trigger: string) =>
          modifiedTriggers.includes(trigger)
        );
      })
      .forEach(rule => {
        if (!canTriggerRules) {
          return;
        }
        computeNamingRule(rule, triggersValues);
      });
  }, [
    id,
    namingRules,
    modified,
    modifiedTriggers,
    triggersValues,
    prevTriggersValues,
    computeNamingRule,
    canTriggerRules
  ]);

  const computeRules = useCallback(
    values => {
      namingRules.forEach(rule => computeNamingRule(rule, values));
    },
    [namingRules, computeNamingRule]
  );

  const memo = useMemo(() => ({ computeRules }), [computeRules]);

  return memo;
};

export default useNamingRules;

import React, { useCallback, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import * as Yup from 'yup';
import { EntityModal } from 'components/v3/Modal';
import {
  TextInputField,
  ColorField,
  ERRORS,
  DatePickerField
} from 'components/v3/Form';
import { Grid, GridItem } from 'components/v3/ui';
import { PeriodSelector, PeriodAction, periodAsValues } from 'features/Periods';
import { stringToColor } from 'utils';
import { permissions, useAccountConfig } from 'config';

const validationSchema = Yup.object().shape({
  description: Yup.string().required(ERRORS.REQUIRED),
  startAt: Yup.string().required(ERRORS.REQUIRED).nullable()
});

const valuesDependencies = {
  color: {
    modifier: (values, form) => {
      if (!form.status?.color) {
        return stringToColor(values.displayName);
      } else {
        return values.color;
      }
    },
    triggers: ['displayName']
  }
};

const PeriodModal = ({
  onRequestClose,
  onSubmitSuccess,
  period: propsPeriod,
  ...props
}) => {
  const { hasPermission } = useAccountConfig();
  const dispatch = useDispatch();
  const { params } = useRouteMatch();
  let { periodId } = params;
  if (propsPeriod) {
    periodId = propsPeriod.id;
  }
  const isNew = !Boolean(periodId);

  const selectPeriod = useMemo(
    () => state => PeriodSelector.selectPeriod(state, { periodId }),
    [periodId]
  );

  let period = useSelector(selectPeriod, isEqual);
  if (!period) {
    period = propsPeriod;
  }

  const createRequest = useCallback(
    payload => dispatch(PeriodAction.createPeriod({ payload })),
    [dispatch]
  );

  const fetchRequest = useCallback(
    () => dispatch(PeriodAction.getPeriod({ id: periodId })),
    [periodId, dispatch]
  );

  const updateRequest = useCallback(
    (id, payload) => dispatch(PeriodAction.updatePeriod({ id, payload })),
    [dispatch]
  );

  const deleteRequest = useCallback(
    id => dispatch(PeriodAction.deletePeriod({ id })),
    [dispatch]
  );

  const canEdit = hasPermission([
    permissions.RESOURCES_CONFIG,
    isNew ? 'exceptional_period.create' : 'exceptional_period.update'
  ]);
  const canDelete = hasPermission([
    permissions.RESOURCES_CONFIG,
    'exceptional_period.delete'
  ]);

  return (
    <EntityModal
      isOpen
      size="small"
      onRequestClose={onRequestClose}
      title={
        isNew
          ? 'Nouvelle période exceptionnelle'
          : 'Édition de la période exceptionnelle'
      }
      subtitle="Une periode exceptionnelle est un indicateur sur votre planning pour vous rappeler qu'il y a un évènement."
      id={periodId}
      model="exceptionalPeriod"
      entity={period}
      initialStatus={{
        color: Boolean(period?.id)
      }}
      validationSchema={validationSchema}
      entityAsValue={periodAsValues}
      fetchRequest={fetchRequest}
      createRequest={createRequest}
      updateRequest={updateRequest}
      deleteRequest={canDelete ? deleteRequest : undefined}
      onSubmitSuccess={onSubmitSuccess}
      autoSubmit={false}
      readOnly={!canEdit}
      valuesDependencies={valuesDependencies}
      {...props}
    >
      {({ setStatus, status }) => (
        <Grid spacing={16} padded={false}>
          <GridItem width="100%">
            <TextInputField
              name="description"
              label="Informations / Description"
              required
              readOnly={!canEdit}
              textArea
              iconLeading={
                <ColorField
                  name="color"
                  onColorSelected={color => setStatus({ ...status, color })}
                />
              }
              leadingIconClickable
            />
          </GridItem>
          <GridItem width="100%">
            <DatePickerField
              label="Date"
              name="startAt,endAt"
              readOnly={!canEdit}
              mode="range"
              hint="Ce peut être un jour comme une période."
            />
          </GridItem>
        </Grid>
      )}
    </EntityModal>
  );
};

export default PeriodModal;

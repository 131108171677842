import React from 'react';
import styled, { css } from 'styled-components';
import { MdArrowDropDown } from 'react-icons/md';
import { Icon, PopoverItem } from 'components/v3/ui';
import Option from 'components/v3/Option';
import Popover from 'components/v3/Popover';

const Wrapper = styled.div`
  position: relative;
  outline: none;
  max-width: 100%;
  display: flex;
  align-items: center;
`;

const IndicatorIcon = styled(Icon)`
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  margin-right: 6px;
  opacity: 0.38;
  z-index: 1;

  svg {
    height: 1rem;
    width: 1rem;
    min-height: 1rem;
    min-width: 1rem;
  }

  &:hover {
    opacity: 0.76;
  }

  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
    `}

  ${({ onClick }) =>
    !onClick &&
    css`
      pointer-events: none;
    `}
`;

const isOptionDisabled = option =>
  option.disabled || (option.original && option.original.disabled);

export default ({
  value,
  onChange,
  options,

  onClick,
  isOpen,
  onRequestClose,
  renderOption = props => <Option {...props} />,
  closeOnSelect,

  indicator,
  showIndicator = true,
  indicatorColor,
  onIndicatorClick,

  readOnly,
  disabled,
  maxWidth,
  minWidth,
  children,
  ...props
}) => {
  if (isOpen !== undefined && !onRequestClose) {
    throw new Error('isOpen must be used with onRequestClose');
  }

  const enabled = !disabled && !readOnly;
  const hasOptions = options && options.length > 0 && enabled;
  const activeValue = value && value.value != null ? value.value : null;

  const indicatorVisible =
    ((hasOptions && showIndicator) || indicator) && enabled;

  const child = React.cloneElement(children, {
    style: indicatorVisible
      ? { paddingRight: 16, maxWidth: '100%', marginRight: 4 } // maxWidth: 'calc(100% - 16px)'
      : undefined
  });

  return (
    <Popover
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      closeOnSelect={closeOnSelect}
      readOnly={readOnly}
      disabled={disabled}
      maxWidth={maxWidth}
      minWidth={minWidth}
      isEmpty={!hasOptions}
      {...props}
    >
      <Wrapper {...props} onClick={onClick}>
        {child}
        {indicatorVisible && (
          <IndicatorIcon
            size="small"
            tint={indicatorColor}
            onClick={onIndicatorClick}
          >
            {indicator ? indicator : <MdArrowDropDown />}
          </IndicatorIcon>
        )}
      </Wrapper>
      {(isOpen === undefined || isOpen) &&
        hasOptions &&
        options.map(option => {
          const active = activeValue === option.value;
          const disabled = isOptionDisabled(option);
          return (
            <PopoverItem
              key={option.value}
              active={active}
              disabled={disabled}
              onClick={() => onChange(option)}
            >
              {typeof option.label === 'function'
                ? option.label({ currentValue: value, active, disabled })
                : renderOption({
                    option,
                    currentValue: value,
                    active,
                    disabled
                  })}
            </PopoverItem>
          );
        })}
    </Popover>
  );
};

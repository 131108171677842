import useMountedRef from './useMountedRef';
import usePrevious from './usePrevious';
import useQuery from './useQuery';
import useAction from './useAction';
import useRedux from './useRedux';
import useDelay from './useDelay';
import useDeepEffect from './useDeepEffect';
import useListFetcher from './useListFetcher';
import { useDeepMemo } from './useDeepMemo';

export {
  useMountedRef,
  usePrevious,
  useQuery,
  useAction,
  useRedux,
  useDelay,
  useDeepEffect,
  useDeepMemo,
  useListFetcher
};

const getElementFont = element => {
  var prop = [
    'font-style',
    'font-variant',
    'font-weight',
    'font-size',
    'font-family'
  ];
  var font = '';
  for (var x in prop) {
    font +=
      window.getComputedStyle(element, null).getPropertyValue(prop[x]) + ' ';
  }
  return font;
};

export const measureInputWidth = (input, text) => {
  if (!input) {
    return 0;
  }
  var canvas = document.createElement('canvas');
  var ctx = canvas.getContext('2d');
  ctx.font = getElementFont(input);
  return ctx.measureText(input.value || text).width;
};

import React from 'react';
import styled, { css } from 'styled-components';
import { spacing } from 'ui';
import Button from './button';
import { Title, Subtitle } from './text';

export const Variant = {
  dark: 'dark'
};

const CardButtonsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${({ center }) =>
    center &&
    css`
      justify-content: center;
    `}
`;

const CardHeaderWrapper = styled.div`
  ${({ variant }) => {
    if (variant === 'tight') {
      return css`
        padding-top: ${spacing(0.5)};
        padding-bottom: ${spacing(0.25)};
        padding-left: ${spacing(0.75)};
        padding-right: ${spacing(0.75)};
      `;
    } else {
      return css`
        padding: ${spacing(1)};
      `;
    }
  }}
`;

export const CardContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  overflow: hidden;
  padding: ${spacing()};
  color: ${({ theme }) => theme.textSecondary};
`;

const Buttons = ['positive', 'negative', 'neutral'];

const NegativeButton = styled(Button)`
  color: ${({ theme }) => theme.error};
`;

const NeutralButton = styled(Button)`
  color: ${({ theme }) => theme.textSecondary};
`;

const NeutralButtonWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
`;

export const CardHeader = ({
  title = null,
  subtitle = null,
  children = null,
  ...props
}) => {
  return (
    <CardHeaderWrapper {...props}>
      {title && <Title>{title}</Title>}
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
      {children}
    </CardHeaderWrapper>
  );
};

export const CardButtons = ({
  positiveText = null,
  neutralText = null,
  negativeText = null,
  onButtonClick,
  buttonNameLoading = null,
  positiveType = 'submit',
  contextMenu = null,
  positiveDisabled = false,
  neutralDisabled = false,
  negativeDisabled = false,
  ...props
}) => {
  const handleClick = name => e => {
    if (name !== 'positive') {
      e.preventDefault();
    }
    e.stopPropagation();
    if (onButtonClick) {
      onButtonClick({
        name,
        index: Buttons.indexOf(name)
      });
    }
  };

  return (
    <CardButtonsWrapper {...props}>
      {positiveText && (
        <Button
          type={positiveType}
          variant="text"
          disabled={Boolean(buttonNameLoading) || positiveDisabled}
          isLoading={buttonNameLoading === 'positive'}
          onClick={handleClick('positive')}
        >
          {positiveText}
        </Button>
      )}
      {negativeText && (
        <NegativeButton
          variant="text"
          disabled={Boolean(buttonNameLoading) || negativeDisabled}
          isLoading={buttonNameLoading === 'negative'}
          onClick={handleClick('negative')}
        >
          {negativeText}
        </NegativeButton>
      )}
      {(neutralText || contextMenu) && (
        <NeutralButtonWrapper>
          {neutralText && (
            <NeutralButton
              variant="text"
              disabled={Boolean(buttonNameLoading) || neutralDisabled}
              isLoading={buttonNameLoading === 'neutral'}
              onClick={handleClick('neutral')}
            >
              {neutralText}
            </NeutralButton>
          )}
          {contextMenu}
        </NeutralButtonWrapper>
      )}
    </CardButtonsWrapper>
  );
};

export const CardFooter = styled.div`
  padding: ${spacing()};
  border-top: ${({ theme }) => theme.borderInput};
`;

export default styled.div`
  background-color: ${({ theme, variant }) =>
    variant === 'dark' ? theme.background : theme.backgroundLight};
  border-radius: ${({ theme }) => theme.borderInputRadius};
  display: flex;
  flex-direction: column;
  width: 100%;
  transition: box-shadow 150ms ease;
  box-shadow: ${({ theme }) => theme.boxShadowIdle};

  ${({ to, onClick, onMouseOver }) =>
    (to || onClick || onMouseOver) &&
    css`
      &:hover {
        box-shadow: ${({ theme }) => theme.boxShadow};
      }
    `}

  ${CardHeaderWrapper} + ${CardContent},
  ${CardHeaderWrapper} ~ ${CardContent} {
    padding: 0px ${spacing()} ${spacing()};
  }

  ${CardFooter} > ${CardButtonsWrapper} {
    margin: -${spacing(0.5)} -${spacing(0.5)};
  }
`;

import { mergeJsonApi } from 'common/reducers';
import omit from 'lodash/omit';

const initialAttachmentsState = {
  attachmentById: {},
  attachmentsIds: []
};

export const attachments = (state = initialAttachmentsState, action) => {
  const { payload, type } = action;
  switch (type) {
    case 'DELETE_ATTACHMENT_SUCCESS':
      return {
        ...state,
        attachmentById: omit(state.attachmentById, payload),
        attachmentsIds: state.attachmentsIds.filter(
          id => parseInt(id) !== payload
        )
      };
    case 'LOGOUT':
    case 'SELECT_ACCOUNT':
      return initialAttachmentsState;
    default:
      return mergeJsonApi('attachment', state, action);
  }
};

const initialDocumentTemplatesState = {
  documentTemplateById: {},
  documentTemplatesIds: []
};

export const documentTemplates = (
  state = initialDocumentTemplatesState,
  action
) => {
  const { type } = action;

  switch (type) {
    case 'LOGOUT':
    case 'SELECT_ACCOUNT':
      return initialDocumentTemplatesState;
    default:
      return mergeJsonApi('documentTemplate', state, action);
  }
};

import pick from 'lodash/pick';
import omit from 'lodash/omit';
import uniq from 'lodash/uniq';
import { mergeJsonApi } from 'common/reducers';

const initialState = {
  userById: {},
  usersIds: [],
  searchIds: []
};

const users = (state = initialState, action) => {
  const { payload, type } = action;
  const pagination = payload?.meta?.pagination || {};
  const { page } = pagination;
  switch (type) {
    case 'NEW_USER_SUCCESS':
      return {
        ...mergeJsonApi('user', state, action),
        usersIds: [payload.data.id, ...state.usersIds]
      };
    case 'DELETE_USER_SUCCESS':
      return {
        ...state,
        userById: omit(state.userById, payload),
        usersIds: state.usersIds.filter(id => parseInt(id) !== payload)
      };
    case 'SEARCH_USERS_SUCCESS':
      let searchIds = state.searchIds || [];
      if (page > 1) {
        searchIds = uniq([...searchIds, ...payload.data.map(user => user.id)]);
      } else {
        searchIds = payload.data.map(user => user.id);
      }
      return {
        ...state,
        ...mergeJsonApi('user', state, action),
        [type]: {
          fetchAt: new Date().getTime()
        },
        searchIds
      };
    case 'SELECT_ACCOUNT':
      return {
        ...initialState,
        userById: pick(state.userById, payload.userId)
      };
    case 'LOGOUT':
      return initialState;
    default:
      return mergeJsonApi('user', state, action);
  }
};

export default users;

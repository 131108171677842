import React from 'react';
import styled from 'styled-components';
import Tag, { TagContainer } from 'components/v3/Tag';
import Tooltip from 'components/v3/Tooltip';
import Card, { CardHeader, CardContent } from 'components/v3/ui/card';
import { Text, Overline, Container } from 'components/v3/ui';
import { List, ListItem } from 'components/v3/ui/list';
import { printDate } from 'utils/dates';
import { PMV_STATE, PMV_ERROR, PMV_LINE_STYLES } from 'features/Pmvs';

const Header = styled(CardHeader)`
  padding-top: 8px;
`;
const Message = styled(List)`
  padding: 8px;
  border: 1px solid ${({ theme }) => theme.borderInputColor};
  margin-bottom: 8px;
`;
const NoMessage = styled.span`
  padding: 8px;
  margin-bottom: 8px;
`.withComponent(Text);
const Ip = styled(Overline)`
  margin-top: 8px;
  flex: 0;
  flex-grow: 1;
  margin-right: 16px;
`;

const PmvCard = ({ pmv, ...props }) => {
  const state = PMV_STATE[pmv.state];
  const lines = pmv.lines?.filter(({ text }) => Boolean(text)) || [];
  return (
    <Card {...props}>
      <Header>
        <TagContainer alignEnd>
          {state && (
            <Tooltip
              content={
                <Container vertical>
                  {state?.message}
                  {pmv.lastError && <div>{PMV_ERROR[pmv.lastError]}</div>}
                </Container>
              }
            >
              <Tag size="small" displayName={state.label} color={state.color} />
            </Tooltip>
          )}
        </TagContainer>
        <Ip color="theme">{pmv.ip}</Ip>
      </Header>
      <CardContent>
        {lines.length > 0 && (
          <Message>
            {pmv.lines.map(({ text, style, id }) => {
              return (
                <ListItem key={id}>
                  <Text
                    variant="small"
                    style={{
                      whiteSpace: text?.trim() ? undefined : 'pre-wrap'
                    }}
                    weight={
                      PMV_LINE_STYLES.find(s => s.value === style)?.weight
                    }
                    alignCenter
                  >
                    {text?.trim() || ' '}
                  </Text>
                </ListItem>
              );
            })}
          </Message>
        )}
        {lines.length === 0 && (
          <NoMessage variant="caption">Aucun message</NoMessage>
        )}
        <Text variant="caption" color="caption">
          {`Dernière mise à jour: ${printDate(
            pmv.lastSeenAt,
            "EEE dd MMM yyyy 'à' HH:mm"
          )}`}
        </Text>
      </CardContent>
    </Card>
  );
};

export default PmvCard;

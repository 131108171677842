import React, { useState, useCallback } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { MdAdd } from 'react-icons/md';
import SubHeader from 'components/v3/SubHeader';
import { FilterBar } from 'components/v3/Filters';
import { Container, Title, Button } from 'components/v3/ui';
import { UserSelector, UserAction } from 'features/Users';
import UserList from 'features/Users/components/v3/List';
import { ROLES } from 'config';

const defaultParams = { sort: 'first_name,last_name' };

const FILTERS = [
  {
    kind: 'select',
    key: 'role',
    value: null,
    asQueryParam: value => ({
      rolesNameEq: value
    }),
    props: {
      label: 'Role',
      options: ROLES,
      mapOptionValue: true
    }
  }
];

const Users = () => {
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  const [count, setCount] = useState();
  const [filters, setFilters] = useState(FILTERS);

  const handleDelete = useCallback(
    item => dispatch(UserAction.deleteUser({ id: item.id })),
    [dispatch]
  );

  const handleArchive = useCallback(
    item =>
      dispatch(
        UserAction.updateUser({
          id: item.id,
          payload: {
            settings: {
              archived: !Boolean(item.settings?.archived)
            }
          }
        })
      ),
    [dispatch]
  );

  const isArchivable = useCallback(user => {
    return !user.isCurrent;
  }, []);

  const isArchived = useCallback(user => {
    return user.settings?.archived;
  }, []);

  return (
    <Container vertical>
      <SubHeader>
        <Title>{`Membres${count != null ? ` (${count})` : ''}`}</Title>
        <Button small to={`${path}/nouveau-membre`} icon={MdAdd}>
          Nouveau membre
        </Button>
      </SubHeader>
      <Container vertical huge padded>
        <FilterBar
          big
          filters={filters}
          searchQuery="byQuery"
          onChanges={setFilters}
        />
        <UserList
          spacing={8}
          headerVariant="transparent"
          selector={UserSelector.selectUsers}
          action={UserAction.getUsers}
          onCountChanged={setCount}
          params={defaultParams}
          filters={filters}
          perPage={50}
          detailUrlPrefix=""
          isDeletable
          deleteRequest={handleDelete}
          isArchivable={isArchivable}
          isArchived={isArchived}
          onToggleArchive={handleArchive}
        />
      </Container>
    </Container>
  );
};

export default Users;

import { createDeepEqualSelector } from 'common/selector';
import { initialStateValue } from './reducer';
import { isEmpty, pick, uniq, uniqWith } from 'lodash';
import { UserSelector } from 'features/Users';
import { createSelector } from 'reselect';
import isSameIds from 'utils/idUtils';

export const getUi = state => state.ui;

export const selectUiKeys = (...keys) =>
  createSelector([getUi], ui => {
    const { currentAccountId } = ui;
    if (!currentAccountId) {
      return initialStateValue;
    }
    let state = ui[currentAccountId];
    if (isEmpty(state)) {
      state = initialStateValue;
    }
    if (keys && keys.length > 0) {
      return pick(isEmpty(state) ? initialStateValue : state, keys);
    } else {
      return state;
    }
  });

export const selectUi = createDeepEqualSelector([getUi], ui => {
  const { currentAccountId } = ui;
  if (!currentAccountId) {
    return initialStateValue;
  }
  const state = ui[currentAccountId];
  if (isEmpty(state)) {
    return initialStateValue;
  } else {
    return state;
  }
});

export const selectCalendarUserIds = createDeepEqualSelector([selectUi], ui => {
  if (isEmpty(ui.calendar) || isEmpty(ui.calendar.userIds)) {
    return [];
  }
  return uniqWith(ui.calendar.userIds, isSameIds);
});

export const selectCalendarCheckedIds = createDeepEqualSelector(
  [selectUi],
  ui => {
    if (isEmpty(ui.calendar) || isEmpty(ui.calendar.checkedIds)) {
      return [];
    }
    return uniqWith(ui.calendar.checkedIds, isSameIds);
  }
);

export const selectCalendarUsers = createDeepEqualSelector(
  [selectCalendarUserIds, selectCalendarCheckedIds, UserSelector.getUsers],
  (userIds, checkedIds, { userById }) =>
    uniq([...(userIds || []), ...(checkedIds || [])])
      .map(userId => userById[userId])
      .filter(Boolean)
      .map(user => {
        return {
          ...user,
          checked: Boolean(checkedIds.find(id => isSameIds(id, user.id)))
        };
      })
      .sort((a, b) => {
        if (a.displayName < b.displayName) {
          return -1;
        }
        if (a.displayName > b.displayName) {
          return 1;
        }
        return 0;
      })
);

import { omit } from 'lodash';
import { mergeJsonApi } from 'common/reducers';

const initialState = {
  extractionMaterialById: {},
  extractionMaterialsIds: []
};

export const extractionMaterials = (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case 'EXTRACTION_MATERIALS_LOADING':
      return {
        ...state,
        isLoading: true
      };
    case 'EXTRACTION_MATERIALS_ERROR':
      return {
        ...state,
        isLoading: false
      };
    case 'NEW_EXTRACTION_MATERIAL_SUCCESS':
      return {
        ...mergeJsonApi('extractionMaterial', state, action),
        extractionMaterialsIds: [
          ...state.extractionMaterialsIds,
          payload.data.id
        ]
      };
    case 'DELETE_EXTRACTION_MATERIAL_SUCCESS':
      return {
        ...state,
        extractionMaterialById: omit(state.extractionMaterialById, payload),
        extractionMaterialsIds: state.extractionMaterialsIds.filter(
          id => parseInt(id) !== payload
        )
      };
    case 'LOGOUT':
    case 'SELECT_ACCOUNT':
      return initialState;
    default:
      return mergeJsonApi('extractionMaterial', state, action);
  }
};

const extractionItemInitialState = {
  extractionItemById: {}
};

export const extractionItems = (state = extractionItemInitialState, action) => {
  const { type } = action;

  switch (type) {
    case 'LOGOUT':
    case 'SELECT_ACCOUNT':
      return initialState;
    default:
      return mergeJsonApi('extractionItem', state, action);
  }
};

import React, { Fragment, useEffect, useCallback } from 'react';
import { useRouteMatch, Route, useHistory, useLocation } from 'react-router-dom';
import Table from 'components/v3/List/Table';
import { useListFetcher } from 'components/v3/utils';
import RawMaterialModal from './RawMaterialModal';

const columns = [
  {
    Header: 'Nom',
    accessor: 'name',
    width: '100%'
  }
];

const RawMaterialList = ({
  selector,
  action,
  options = {
    fetchOnMount: false,
    useLocalState: true
  },
  params = {
    sort: 'position'
  },
  perPage = 25,
  filters,
  onCountChanged,
  detailUrlPrefix = '/matieres-premieres',
  ...props
}) => {
  const { path, url } = useRouteMatch();
  const history = useHistory();
  const location = useLocation();

  const [rawMaterials, paginationHelper, state] = useListFetcher({
    selector,
    action,
    options,
    params,
    perPage,
    filters
  });

  const detailLinkFormatter = useCallback((item) => `${path}${detailUrlPrefix}/${item.id}`, [path, detailUrlPrefix]);

  useEffect(() => {
    if (paginationHelper.total != null && onCountChanged) {
      onCountChanged(paginationHelper.total);
    }
  }, [paginationHelper.total, onCountChanged]);

  return (
    <Fragment>
      <Table
        columns={columns}
        items={rawMaterials}
        isLoading={state.isLoading}
        linkFormatter={detailLinkFormatter}
        centerMessage={state.errorMessage}
        {...paginationHelper}
        {...props}
      />
      <Route path={[`${path}${detailUrlPrefix}/nouveau`, `${path}${detailUrlPrefix}/:rawMaterialId`]}>
        <RawMaterialModal
          onRequestClose={() => {
            if (location.key) {
              history.goBack();
            } else {
              history.replace(url);
            }
          }}
        />
      </Route>
    </Fragment>
  );
};

export default RawMaterialList;

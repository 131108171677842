import { createAction } from 'redux-actions';
import omit from 'lodash/omit';
import * as Api from './api';
import { AttachmentApi } from 'features/Attachments';
import { prepareAttributes } from 'common/actions';

const preparePayload = (payload) =>
  prepareAttributes(
    {
      ...payload,
      state: payload.state?.value || payload.state,
      activityId: payload.activity?.id || null,
      teamId: payload.team?.id || null,
      projectId: payload.project?.id || payload.projectId,
      userIds: payload.users?.map((u) => u.id),
      extractionItems: payload.extractionItems
        ?.map((extractionItem) =>
          prepareAttributes(omit(extractionItem, 'extractionMaterial', 'extractionDestination'))
        )
        .filter(({ extractionMaterialId, quantity }) => extractionMaterialId && quantity),
      inputItems: payload.inputItems
        ?.map((extractionItem) => prepareAttributes(omit(extractionItem, 'inputMaterial', 'purveyor')))
        .filter(({ inputMaterialId, quantity }) => inputMaterialId && quantity),
      orders: payload.orders?.map(({ orderDays, purveyor, product, ...order }) =>
        prepareAttributes(
          {
            ...order,
            orderDays: Object.keys(orderDays || {}).map((date) => {
              return {
                date: date,
                ...(orderDays[date] || {})
              };
            })
          },
          'orderDays'
        )
      ),
      carrierOrders: payload.carrierOrders?.map(({ carrierOrderDays, carrier, ...carrierOrder }) =>
        prepareAttributes(
          {
            ...carrierOrder,
            carrierOrderDays: Object.keys(carrierOrderDays || {}).map((date) => {
              return {
                date: date,
                ...(carrierOrderDays[date] || {})
              };
            })
          },
          'carrierOrderDays'
        )
      )
    },
    'orders',
    'carrierOrders',
    'layers',
    'address',
    'machinePhases',
    'deliveries',
    'extractionItems',
    'inputItems'
  );

export const receivePhases = createAction('PHASES_SUCCESS');
export const getPhases = (payload) => async (dispatch) => {
  const response = await Api.getPhases(payload);
  dispatch(receivePhases(response));
  return response;
};

const newPhaseLoading = createAction('NEW_PHASE_LOADING');
const receiveCreatePhase = createAction('NEW_PHASE_SUCCESS');
export const createPhase =
  ({ payload, ...config }) =>
  async (dispatch) => {
    dispatch(newPhaseLoading());
    const { attachments } = payload;
    const attachmentIds = attachments ? (await AttachmentApi.postAttachments(attachments)).map((a) => a.id) : [];

    const result = await Api.createPhase(
      {
        ...omit(preparePayload(payload), ['attachments', 'project']),
        attachmentIds
      },
      config
    );
    dispatch(receiveCreatePhase(result));
    return result;
  };

const receivePhase = createAction('PHASE_SUCCESS');
export const getPhase =
  ({ id, ...payload }) =>
  async (dispatch) => {
    const result = await Api.getPhase(id, payload);
    dispatch(receivePhase(result));
    return result;
  };

export const receiveUpdatePhase = createAction('UPDATE_PHASE_SUCCESS');
export const updatePhaseState =
  ({ id, payload, ...config }) =>
  async (dispatch) => {
    const result = await Api.putPhase(
      id,
      {
        ...payload,
        state: payload.state?.value || payload.state
      },
      config
    );
    dispatch(receiveUpdatePhase(result));
    return result;
  };

export const updatePhase =
  ({ id, payload, ...config }) =>
  async (dispatch, getState) => {
    const oldValue = getState().phases.phaseById[id];
    try {
      const { machinePhasesAttributes, layersAttributes, addressAttributes, deliveriesAttributes, ...phase } = payload;
      if (machinePhasesAttributes) {
        phase.machinePhases = machinePhasesAttributes;
      }
      if (layersAttributes) {
        phase.layers = layersAttributes;
      }
      if (addressAttributes) {
        phase.address = addressAttributes;
      }
      if (deliveriesAttributes) {
        phase.deliveries = deliveriesAttributes;
      }
      const result = await Api.putPhase(
        id,
        {
          ...omit(preparePayload(phase), ['attachments', 'project'])
        },
        config
      );
      await dispatch(receiveUpdatePhase(result));
      return result;
    } catch (e) {
      const { relationships, ...attributes } = oldValue || {};
      dispatch(
        receiveUpdatePhase({
          data: { id: id, type: 'phase', relationships, attributes }
        })
      );
      throw e;
    }
  };

export const preUpdatePhase = (phaseId, phase) => (dispatch) => {
  dispatch(
    receiveUpdatePhase({
      data: { id: phaseId, type: 'phase', attributes: phase }
    })
  );
};

const receiveDeletePhase = createAction('DELETE_PHASE_SUCCESS');
export const deletePhase =
  ({ id, ...config }) =>
  async (dispatch) => {
    const response = await Api.deletePhase(id, config);
    dispatch(receiveDeletePhase(id));
    return response;
  };

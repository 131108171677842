import React, { useCallback, useMemo } from 'react';
import { Field, FastField } from 'formik';
import {
  ActivityAction,
  ActivitySelector
} from 'features/ProjectKindsActivities';
import { useRedux } from 'components/v3/utils';
import { GridItem } from 'components/v3/ui';
import DatePicker from 'components/v3/DatePicker';
import isSameIds from 'utils/idUtils';

export const AdministrativeActivities = ({
  field,
  form: { setFieldValue },
  projectKindId,
  readOnly,
  ...props
}) => {
  const { name, value: fieldValue } = field;
  const handleChange = useCallback(value => setFieldValue(name, value), [
    setFieldValue,
    name
  ]);

  const selectActivities = useMemo(
    () => state =>
      ActivitySelector.selectActivitiesByProjectKind(state, {
        projectKindId,
        administrative: true
      }),
    [projectKindId]
  );

  const [activities] = useRedux(
    selectActivities,
    ActivityAction.getActivities,
    {
      fetchOnMount: true,
      useLocalState: true,
      initialParams: {
        page: 1,
        perPage: 500,
        useCache: true
      }
    }
  );

  const value = fieldValue || [];

  return activities
    .map(
      activity =>
        value.find(pa =>
          isSameIds(pa.activity?.id || pa.activityId, activity.id)
        ) || {
          ...activity,
          id: undefined,
          activity: activity,
          activityId: activity.id
        }
    )
    .map(projectActivity => {
      const activityId =
        projectActivity.activity?.id || projectActivity.activityId;
      return (
        <GridItem width="50%" key={activityId}>
          <DatePicker
            label={
              projectActivity.displayName ||
              projectActivity.activity?.displayName
            }
            mode="range"
            readOnly={readOnly}
            value={projectActivity}
            isClearable
            onChange={dates =>
              handleChange([
                ...value.filter(
                  pa => (pa.activity?.id || pa.activityId) !== activityId
                ),
                {
                  ...projectActivity,
                  activityId,
                  startAt: dates?.startAt || null,
                  endAt: dates?.endAt || null
                }
              ])
            }
          />
        </GridItem>
      );
    });
};

export const AdministrativeActivitiesField = ({
  fastField = false,
  ...props
}) => {
  const Component = fastField ? FastField : Field;
  return <Component {...props} component={AdministrativeActivities} />;
};

export default AdministrativeActivitiesField;

import { createDeepEqualSelector, getProps } from 'common/selector';
import { getState, selectRelations } from 'common/selector';
import { AccountSelector } from 'features/Accounts';

export const getIssues = state => state.issues;

export const selectIssues = createDeepEqualSelector(
  [getIssues, getState],
  ({ issuesIds, issueById }, state) => {
    return issuesIds
      .map(id => {
        const issue = selectRelations('issue', issueById[id], state);
        if (issue) {
          let template = issue.documentTemplate?.data || issue.documentTemplate;
          if (template) {
            template = {
              ...template,
              ...(template.attributes || {})
            };
          }
          return {
            ...issue,
            documentTemplate: template
          };
        } else {
          return null;
        }
      })
      .filter(Boolean);
  }
);

export const selectIssue = createDeepEqualSelector(
  [getIssues, getProps, getState],
  ({ issueById }, { issueId }, state) => {
    const issue = issueById[issueId];
    if (!issue) {
      return null;
    }
    return selectRelations('issue', issue, state);
  }
);

export const selectMapIssues = createDeepEqualSelector(
  [getIssues, getState],
  ({ mapIssuesIds, issueById }, state) => {
    return mapIssuesIds
      .map(id => selectRelations('issue', issueById[id], state))
      .filter(Boolean);
  }
);

export const selectIssueStates = createDeepEqualSelector(
  [AccountSelector.selectCurrentAccount],
  currentAccount => {
    if (!currentAccount) {
      return [];
    }
    return currentAccount.issuesStates ? currentAccount.issuesStates : [];
  }
);

import React from 'react';
import styled, { css } from 'styled-components';
import { splitN } from 'utils';
import { Text } from 'components/v3/ui';

export { AddressReducer } from './data';

const LinesContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${props =>
    props.alignEnd &&
    css`
      align-items: flex-end;
    `}
`;

export const addressBlock = (
  address,
  coordinates = false,
  alignEnd = false
) => {
  if (!address || !address.content) {
    return null;
  }

  return (
    <LinesContainer alignEnd={alignEnd}>
      <Text>{splitN(address.content)}</Text>
      {coordinates && <Text>{`${address.lat},${address.lng}`}</Text>}
    </LinesContainer>
  );
};

export const fullAddress = address => {
  if (!address) {
    return '';
  }
  const { content } = address;
  if (content) {
    return content;
  } else {
    return '';
  }
};

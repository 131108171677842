import { createAction } from 'redux-actions';
import * as Api from './api';
import { AttachmentApi } from 'features/Attachments';
import omit from 'lodash/omit';
import { prepareAttributes, shouldFetch } from 'common/actions';
import { flatten } from 'lodash';

const preparePayload = payload =>
  prepareAttributes(
    {
      ...payload,
      state: payload.state?.value || payload.state,
      ownerId: payload.owner?.id,
      assistantId: payload.assistant?.id,
      projectKindId: payload.projectKind?.id,
      groupId: payload.group?.id,
      userId: payload.user?.id || null,
      organizationId: payload.organization?.id || null,
      contactId: payload.contact?.id || null,
      clientIds: payload.clients?.map(client => client.id),
      team: payload.team ? omit(payload.team, 'users') : null,
      directions: payload.directions
        ? flatten(payload.directions)
        : payload.directions,
      deviations: payload.deviations?.map(({ directions, ...d }) => ({
        ...d,
        directions: directions ? flatten(directions) : directions
      }))
    },
    'address',
    'projectActivities',
    'deviations',
    'signs'
  );

const formatPayloadProject = data => {
  let payload = preparePayload(data);

  if (payload.signsAttributes) {
    payload = {
      ...payload,
      signsAttributes: payload.signsAttributes.map(sign => {
        if (sign.kind === 'deviation_sign') {
          return {
            ...sign,
            kind: sign.name,
            name: undefined,
            src: undefined
          };
        } else {
          return sign;
        }
      })
    };
  }

  return {
    ...payload,
    state: payload.state?.value || payload.state
  };
};

const projectsLoading = createAction('PROJECTS_LOADING');
const projectsSuccess = createAction('PROJECTS_SUCCESS');
const searchProjectsSuccess = createAction('SEARCH_PROJECTS_SUCCESS');
const projectsError = createAction('PROJECTS_ERROR');
export const getProjects = payload => async (dispatch, getState) => {
  const { useCache, isSearchRequest, ...params } = payload || {};
  const state = getState();
  const action = isSearchRequest
    ? 'SEARCH_PROJECTS_SUCCESS'
    : 'PROJECTS_SUCCESS';
  if (!useCache || shouldFetch('project', params, state, action)) {
    return fetchProjects(params, isSearchRequest, dispatch);
  } else {
    return {
      fromCache: true
    };
  }
};
const fetchProjects = async (payload, isSearchRequest, dispatch) => {
  if (!isSearchRequest) {
    dispatch(projectsLoading());
  }
  try {
    const result = await Api.getProjects(payload);
    if (isSearchRequest) {
      dispatch(
        searchProjectsSuccess({
          ...result,
          requestParams: omit(payload, 'cancelToken')
        })
      );
    } else {
      dispatch(
        projectsSuccess({
          ...result,
          requestParams: omit(payload, 'cancelToken')
        })
      );
    }
    return result;
  } catch (error) {
    dispatch(projectsError(error));
    throw error;
  }
};

export const sendInvitation =
  ({ projectId, payload }) =>
  async dispatch => {
    return await Api.sendInvitation(projectId, payload);
  };

// export const receiveProjects = createAction('PROJECTS_SUCCESS');
// export const getProjects = payload => async dispatch => {
//   const projects = await Api.getProjects(payload);
//   dispatch(receiveProjects(projects, payload));
//   return projects;
// };

export const receivedFindProject = createAction('FIND_PROJECTS_SUCCESS');
export const findProjects = payload => async dispatch => {
  const projects = await Api.getProjects(payload);
  dispatch(receivedFindProject(projects));
  return projects;
};

export const receiveMapProjects = createAction('MAP_PROJECTS_SUCCESS');
export const getMapProjects = payload => async dispatch => {
  const projects = await Api.getProjects(payload);
  dispatch(receiveMapProjects(projects, payload));
  return projects;
};

const receiveCreateProject = createAction('NEW_PROJECT_SUCCESS');
export const createProject =
  ({ payload, ...config }) =>
  async dispatch => {
    const { attachments } = payload;
    const attachmentIds = attachments
      ? (
          await AttachmentApi.postAttachments(
            attachments.filter(a => a.kind !== 'template')
          )
        ).map(a => a.id)
      : [];

    const result = await Api.createProject(
      {
        ...formatPayloadProject(omit(payload, ['attachments'])),
        attachmentIds
      },
      config
    );
    dispatch(receiveCreateProject(result));
    return result;
  };

const receiveProject = createAction('PROJECT_SUCCESS');
export const getProject =
  ({ id, ...payload }) =>
  async dispatch => {
    const result = await Api.getProject(id, payload);
    dispatch(receiveProject(result));
    return result;
  };

export const uploadProjectAttachmentSuccess = createAction(
  'UPLOAD_PROJECT_ATTACHMENT_SUCCESS'
);

const receiveUpdateProject = createAction('UPDATE_PROJECT_SUCCESS');
export const updateProjectState =
  ({ id, payload, ...config }) =>
  async dispatch => {
    const result = await Api.putProject(
      id,
      {
        ...payload,
        state: payload.state?.value || payload.state
      },
      config
    );
    dispatch(receiveUpdateProject(result));
    return result;
  };

export const updateProject =
  ({ id, payload, ...config }) =>
  async dispatch => {
    const { attachments, omitAttachments } = payload;
    if (omitAttachments) {
      payload = omit(payload, ['omitAttachments']);
    } else {
      const attachmentIds = attachments
        ? (
            await AttachmentApi.postAttachments(
              attachments.filter(a => a.kind !== 'template')
            )
          ).map(a => a.id)
        : [];

      payload = {
        ...omit(payload, ['attachments']),
        attachmentIds
      };
    }
    const result = await Api.putProject(
      id,
      formatPayloadProject(payload),
      config
    );
    dispatch(receiveUpdateProject(result));
    return result;
  };

const receiveDeleteProject = createAction('DELETE_PROJECT_SUCCESS');
export const deleteProject =
  ({ id, config }) =>
  async dispatch => {
    const response = await Api.deleteProject(id, config);
    dispatch(receiveDeleteProject(id));
    return response;
  };

const receiveAcceptProject = createAction('ACCEPT_PROJECT_SUCCESS');
const failAcceptProject = createAction('ACCEPT_PROJECT_ERROR');
export const acceptProject = id => dispatch => {
  return Api.acceptProject(id).then(
    response => {
      dispatch(receiveAcceptProject(response));
    },
    error => dispatch(failAcceptProject(error))
  );
};

const receiveBillProject = createAction('BILL_PROJECT_SUCCESS');
const failBillProject = createAction('BILL_PROJECT_ERROR');
export const billProject = id => dispatch => {
  return Api.billProject(id).then(
    response => {
      dispatch(receiveBillProject(response));
    },
    error => dispatch(failBillProject(error))
  );
};

const receiveFinishProject = createAction('FINISH_PROJECT_SUCCESS');
const failFinishProject = createAction('FINISH_PROJECT_ERROR');
export const finishProject = id => dispatch => {
  return Api.finishProject(id).then(
    response => {
      dispatch(receiveFinishProject(response));
    },
    error => dispatch(failFinishProject(error))
  );
};

const receivePlanProject = createAction('PLAN_PROJECT_SUCCESS');
const failPlanProject = createAction('PLAN_PROJECT_ERROR');
export const planProject = id => dispatch => {
  return Api.planProject(id).then(
    response => {
      dispatch(receivePlanProject(response));
    },
    error => dispatch(failPlanProject(error))
  );
};

const receiveProposeProject = createAction('PROPOSE_PROJECT_SUCCESS');
const failProposeProject = createAction('PROPOSE_PROJECT_ERROR');
export const proposeProject = id => dispatch => {
  return Api.proposeProject(id).then(
    response => {
      dispatch(receiveProposeProject(response));
    },
    error => dispatch(failProposeProject(error))
  );
};

const receiveValidateProject = createAction('VALIDATE_PROJECT_SUCCESS');
const failValidateProject = createAction('VALIDATE_PROJECT_ERROR');
export const validateProject = id => dispatch => {
  return Api.validateProject(id).then(
    response => {
      dispatch(receiveValidateProject(response));
    },
    error => dispatch(failValidateProject(error))
  );
};

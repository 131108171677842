import React, { Fragment, useEffect, useCallback } from 'react';
import {
  useRouteMatch,
  Route,
  useHistory,
  useLocation
} from 'react-router-dom';
import { MdAdd } from 'react-icons/md';
import { Grid, GridItem, Text, Button } from 'components/v3/ui';
import Table from 'components/v3/List/Table';
import { useListFetcher } from 'components/v3/utils';
import { ExtractionMaterialModal } from './ExtractionMaterialModal';

const columns = [
  {
    Header: 'Nom',
    accessor: 'name',
    width: '100%',
    Cell: ({ item }) => <Text variant="small">{item.name}</Text>
  }
];

export const ExtractionMaterialList = ({
  selector,
  action,
  options = {
    fetchOnMount: false,
    useLocalState: true
  },
  params = {
    sort: 'name'
  },
  perPage = 25,
  filters,
  onCountChanged,
  detailUrlPrefix = '/materiaux-sortants',
  ...props
}) => {
  const { path, url } = useRouteMatch();
  const history = useHistory();
  const location = useLocation();

  const [extractionMaterials, paginationHelper, state] = useListFetcher({
    selector,
    action,
    options,
    params,
    perPage,
    filters
  });

  const detailLinkFormatter = useCallback(
    item => `${path}${detailUrlPrefix}/${item.id}`,
    [path, detailUrlPrefix]
  );

  useEffect(() => {
    if (paginationHelper.total != null && onCountChanged) {
      onCountChanged(paginationHelper.total);
    }
  }, [paginationHelper.total, onCountChanged]);

  return (
    <Fragment>
      <Table
        columns={columns}
        items={extractionMaterials}
        isLoading={state.isLoading}
        linkFormatter={detailLinkFormatter}
        centerMessage={
          state.errorMessage ||
          (!state.isLoading && extractionMaterials.length === 0 && (
            <Grid justifyCenter>
              <GridItem width="100%" justifyCenter alignCenter>
                <Text variant="hint">Il n'y a pas de sortant</Text>
              </GridItem>
              <GridItem width="100%" justifyCenter alignCenter>
                <div>
                  <Button
                    small
                    variant="text"
                    icon={MdAdd}
                    onClick={() => history.push(`${url}/nouveau-sortant`)}
                  >
                    Nouveau sortant
                  </Button>
                </div>
              </GridItem>
            </Grid>
          ))
        }
        {...paginationHelper}
        {...props}
      />
      <Route
        path={[
          `${path}${detailUrlPrefix}/nouveau-sortant`,
          `${path}${detailUrlPrefix}/:extractionMaterialId`
        ]}
      >
        <ExtractionMaterialModal
          onRequestClose={() => {
            if (location.key) {
              history.goBack();
            } else {
              history.replace(url);
            }
          }}
        />
      </Route>
    </Fragment>
  );
};

import React from 'react';
import Tag from 'components/v3/Tag';
import Tooltip from 'components/v3/Tooltip';
import { theme } from 'ui';

const getBatteryColor = percentage => {
  if (percentage > 80) {
    return theme.success;
  } else if (percentage > 40) {
    return theme.warning;
  } else {
    return theme.error;
  }
};

const BatteryTag = ({ gpsBox, ...props }) => {
  return (
    <Tooltip content={`Batterie du GPS est chargée a ${gpsBox.battery}%`}>
      <Tag
        {...props}
        size="small"
        displayName={`${gpsBox.battery}%`}
        color={getBatteryColor(gpsBox.battery)}
      />
    </Tooltip>
  );
};

export default BatteryTag;

import { createAction } from 'redux-actions';
import omit from 'lodash/omit';
import * as Api from './api';
import { prepareAttributes, shouldFetch } from 'common/actions';

const preparePayload = payload =>
  prepareAttributes({
    ...payload,
    userIds: payload.users?.map(user => user.id)
  });

  
const teamsLoading = createAction('TEAMS_LOADING');
const teamsSuccess = createAction('TEAMS_SUCCESS');
const teamsError = createAction('TEAMS_ERROR');
export const getTeams = payload => async (dispatch, getState) => {
  const { useCache = true, ...params } = payload || {};
  const state = getState();
  if (!useCache || shouldFetch('team', params, state)) {
    return fetchTeams(params, dispatch);
  } else {
    return {
      fromCache: true,
      isLoading: getState().teams.isLoading
    };
  }
};
const fetchTeams = async (payload, dispatch) => {
  dispatch(teamsLoading());
  try {
    const result = await Api.getTeams(payload);
    dispatch(
      teamsSuccess({ ...result, requestParams: omit(payload, 'cancelToken') })
    );
    return result;
  } catch (error) {
    dispatch(teamsError(error));
    throw error;
  }
};

const receiveUpdateTeamsOrder = createAction('REORDER_TEAMS_SUCCESS');
export const updateTeamsOrder = ({
  payload: team,
  payloads: teams,
  ...config
}) => async dispatch => {
  dispatch(receiveUpdateTeamsOrder(teams));
  const result = await Api.reorderTeam(team.id, team, config);
  return result;
};

const receiveCreateTeam = createAction('NEW_TEAM_SUCCESS');
export const createTeam = ({ payload, ...config }) => async dispatch => {
  const result = await Api.createTeam(preparePayload(payload), config);
  dispatch(receiveCreateTeam(result));
  return result;
};

const receiveTeam = createAction('TEAM_SUCCESS');
export const getTeam = ({ id, ...payload }) => async dispatch => {
  const result = await Api.getTeam(id, payload);
  dispatch(receiveTeam(result));
  return result;
};

const receiveUpdateTeam = createAction('UPDATE_TEAM_SUCCESS');
export const updateTeam = ({
  id,
  payload: team,
  ...config
}) => async dispatch => {
  const result = await Api.putTeam(id, preparePayload(team), config);
  dispatch(receiveUpdateTeam(result));
  return result;
};

const receiveDeleteTeam = createAction('DELETE_TEAM_SUCCESS');
export const deleteTeam = ({ id, ...config }) => async dispatch => {
  const response = await Api.deleteTeam(id, config);
  dispatch(receiveDeleteTeam(id));
  return response;
};

import { GroupApi, GroupAction, GroupReducer, GroupSelector } from './data';

export { GroupApi, GroupAction, GroupReducer, GroupSelector };

export const groupAsOption = group => {
  if (Array.isArray(group)) {
    return group.map(groupAsOption);
  } else if (group) {
    return {
      value: group.id,
      color: group.color,
      label: group.displayName,
      original: group
    };
  } else {
    return null;
  }
};

export const groupAsValues = group => ({
  id: group?.id || null,
  displayName: group?.displayName || '',
  color: group?.color || ''
});

import { ReportApi, ReportAction, ReportReducer, ReportSelector } from './data';
import { ReportList, ReportModal, ReportsBox } from './components';

export { ReportApi, ReportAction, ReportReducer, ReportSelector };
export { ReportList, ReportModal, ReportsBox };

export const reportAsValues = report => ({
  id: report?.id || '',
  projectId: report?.projectId || null,
  project: report?.project || null,
  reportKind: report?.reportKind || null,
  reportKindId: report?.reportKindId || null,
  customData: report?.customData || []
  // ...(report?.customData || []).reduce((acc, item) => {
  //   acc[`customDataValidation_${item.name}`] = item.value;
  //   return acc;
  // }, {})
});

export const reportKindAsOption = reportKind => {
  if (Array.isArray(reportKind)) {
    return reportKind.map(reportKindAsOption);
  } else if (reportKind) {
    return {
      value: reportKind.id,
      label: reportKind.displayName,
      original: reportKind
    };
  } else {
    return null;
  }
};

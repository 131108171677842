import { createAction } from 'redux-actions';
import * as Api from './api';

const receiveDeviations = createAction('PROJECTS_DEVIATIONS_SUCCESS');
export const getDeviations = payload => async dispatch => {
  const projects = await Api.getDeviations(payload);
  dispatch(receiveDeviations(projects));
  return projects;
};

const receiveDeviation = createAction('DEVIATION_SUCCESS');
export const getDeviation = ({ id, ...payload }) => async dispatch => {
  const project = await Api.getDeviation(id, payload);
  dispatch(receiveDeviation(project));
  return project;
};

const receiveSigns = createAction('SIGNS_SUCCESS');
export const getSigns = payload => async dispatch => {
  const response = await Api.getSigns(payload);
  dispatch(receiveSigns(response));
  return response;
};

import React from 'react';
import styled, { css } from 'styled-components';
import { Link as RawLink } from 'react-router-dom';
import { spacing } from 'ui';
import { List } from './list';

export const PopoverContainer = styled(List)`
  background-color: #fff;
  min-width: 12rem;
  padding-inline-start: 0;
  border-radius: ${({ theme }) => theme.borderButtonRadius};
  box-shadow: 0 6px 24px rgba(0, 0, 0, 0.1);

  ${({ focusedIndex, theme }) =>
    focusedIndex !== -1 &&
    css`
      li:nth-child(${focusedIndex + 1}) {
        background-color: ${theme.hoverColorDark};
        outline: 1px solid #eeeeee;
      }
    `}
`;

const PopoverItemWrapper = styled.li`
  list-style-type: none;
  position: relative;
  display: flex;
  align-items: center;
  padding: ${spacing(0.75)} ${spacing(1.5)} ${spacing(0.75)} ${spacing()};
  min-height: 44px;
  color: ${({ theme }) => theme.text};

  :first-child:not(:only-child) {
    border-radius: ${({ theme }) => theme.borderButtonRadius}
      ${({ theme }) => theme.borderButtonRadius} 0 0;
  }
  :last-child:not(:only-child) {
    border-radius: 0 0 ${({ theme }) => theme.borderButtonRadius}
      ${({ theme }) => theme.borderButtonRadius};
  }
  :only-child {
    border-radius: ${({ theme }) => theme.borderButtonRadius};
  }

  + *:before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    content: '';
    display: block;
    border-top: 1px solid ${({ theme }) => theme.separator};
  }

  ${({ onClick, theme }) =>
    onClick &&
    css`
      cursor: pointer;
      &:hover {
        background-color: ${theme.hoverColor};
      }
    `}

  ${({ active, theme }) =>
    active &&
    css`
      color: ${theme.textLight};
      > * {
        color: ${theme.textLight};
      }
      * > * {
        color: ${theme.textLight};
      }
      background-color: ${theme.primaryLight} !important;
      &:hover {
        background-color: ${theme.primary};
      }
    `}


  ${({ disabled, theme }) =>
    disabled &&
    css`
      background-color: ${theme.disabled};
      opacity: 0.5;
      &:hover {
        background-color: ${theme.disabled};
      }
      &:active {
        cursor: not-allowed;
      }
    `}
`;

const Link = styled(PopoverItemWrapper)`
  padding: ${spacing()} ${spacing()};
  cursor: pointer;
  display: block;
  text-decoration: none;
`.withComponent(RawLink);

export const PopoverItem = React.forwardRef(
  ({ onClick, closeOnSelect = true, ...props }, ref) => {
    const disabled = props.disabled || (!onClick && !Boolean(props.to));
    return (
      <PopoverItemWrapper
        ref={ref}
        {...props}
        as={Boolean(props.to) ? Link : Boolean(props.as) ? props.as : null}
        // onMouseDown={e => {
        //   e.preventDefault();
        // }}
        onClick={e => {
          if (disabled || !closeOnSelect) {
            e.stopPropagation();
          }
          onClick && onClick(e);
        }}
        disabled={disabled}
      >
        {props.children}
      </PopoverItemWrapper>
    );
  }
);

import React, { Fragment, useCallback } from 'react';
import { orderBy } from 'lodash';
import { GrDocumentTime } from 'react-icons/gr';
import { Icon, Text } from 'components/v3/ui';
import Table from 'components/v3/List/Table';
import Author from 'features/Users/components/v3/Author';
import { printDate } from 'utils/dates';
import { authenticateUrl } from 'utils';
import VoucherCheck from './VoucherCheck';
import { VoucherAction } from '../data';
import { useDispatch } from 'react-redux';
import { useAccountConfig } from 'config';

const columns = [
  {
    Header: ' ',
    accessor: 'icon',
    width: '56px',
    Cell: () => (
      <Icon>
        <GrDocumentTime />
      </Icon>
    )
  },
  {
    Header: 'Version',
    accessor: 'version',
    width: '20%',
    Cell: ({ item }) => <Text variant="small">{`Version ${String(item.version)}`}</Text>
  },
  {
    Header: 'Auteur',
    accessor: 'email',
    width: '40%',
    Cell: ({ item }) => (
      <Author user={item?.createdBy} size="small" subtitle={printDate(item.createdAt)} variant="small" />
    )
  },
  {
    Header: 'Valideur',
    accessor: 'createdBy',
    width: 'calc(40% - 56px)',
    Cell: ({ item }) => <VoucherCheck voucherVersion={item} editable />
  }
];

const VoucherVersionList = ({ items, spacing = 0, ...props }) => {
  const dispatch = useDispatch();
  const { hasPermission } = useAccountConfig();
  const detailLinkFormatter = useCallback(
    (item) => ({
      external: true,
      url: authenticateUrl(item.attachment?.fileUrl)
    }),
    []
  );
  const handleDelete = useCallback((item) => dispatch(VoucherAction.deleteVoucherVersion({ id: item.id })), [dispatch]);

  return (
    <Fragment>
      <Table
        columns={columns}
        items={orderBy(items, 'createdAt', 'desc').map((version, idx) => ({
          ...version,
          version: items.length - idx
        }))}
        spacing={spacing}
        estimatedItemSize={54 + spacing / 2}
        linkFormatter={detailLinkFormatter}
        isDeletable={hasPermission('voucher_version.delete') && items.length > 1}
        deleteRequest={handleDelete}
        {...props}
      />
    </Fragment>
  );
};

export default VoucherVersionList;

import React, { useMemo, useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useLocation, useRouteMatch, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import flatten from 'lodash/flatten';
import { MdDashboard, MdGpsFixed, MdSupervisorAccount, MdBusiness } from 'react-icons/md';
import { AiFillTool } from 'react-icons/ai';
import { useAccountConfig, features, permissions } from 'config';
import { SidebarMenuItem, SidebarMenuLink, SubMenu, MainMenu } from 'ui/sidebar';
import { Container } from 'components/v3/ui';
import { usePrevious } from 'components/v3/utils';
import { MAIN_SIDEBAR_WIDTH } from 'ui';
import BillingStats from './BillingStats';
import Teams from './Teams';
import Machines from './Machines';
import Account from './Account';
import Users from './Users';
import AccountSetting from './AccountSettings';
import Groups from './Groups';
import Carriers from './Carriers';
import GpsBoxes from './GpsBoxes';
import Pmvs from './Pmvs';
import ProjectKinds from './ProjectKinds';
import Purveyors from './Purveyors';
import Scrollbars from 'react-custom-scrollbars';
import Stats from './SurfacingStats';
import Organizations from './Organizations';
import Contacts from './Contacts';
import Holidays from './Holidays';
import Invoices from './Invoices';
import Periods from './Periods';
import Services from './Services';
import Products from './Products';
import RawMaterials from './RawMaterials';
import { ExtractionDestinations } from './ExtractionDestinations';
import { ExtractionMaterials } from './ExtractionMaterials';
import { InputMaterials } from './InputMaterials';

const Sidebar = styled.aside`
  z-index: 2;
  box-shadow: ${({ theme }) => theme.shadow};
  background-color: ${({ theme }) => theme.primaryDark};
  width: ${MAIN_SIDEBAR_WIDTH}px;
  min-width: ${MAIN_SIDEBAR_WIDTH}px;
`;

const MENU = [
  {
    label: 'Dashboard',
    value: 'dashboard',
    Icon: MdDashboard,
    children: [
      {
        label: 'Tonnages',
        pathname: 'tonnages',
        Component: Stats,
        feature: features.ORDERS_COATING,
        permission: permissions.STATISTICS_VIEW
      },
      {
        label: 'En-cours',
        pathname: 'en-cours',
        Component: BillingStats,
        feature: features.BILLING_STATS,
        permission: permissions.STATISTICS_VIEW
      }
    ]
  },
  {
    label: 'Gestion',
    value: 'gestion',
    Icon: MdBusiness,
    children: [
      {
        label: 'Congés',
        pathname: 'conges',
        Component: Holidays,
        feature: features.MANAGEMENT_HOLIDAYS,
        permission: permissions.HOLIDAYS
      },
      {
        label: 'Organisations',
        pathname: 'organisations',
        Component: Organizations,
        feature: features.CRM,
        permission: permissions.CRM
      },
      {
        label: 'Contacts',
        pathname: 'contacts',
        Component: Contacts,
        feature: features.CRM,
        permission: permissions.CRM
      },
      {
        label: 'Comptabilité',
        pathname: 'comptabilite',
        Component: Invoices,
        feature: features.ACCOUNTING,
        permission: permissions.ACCOUNTING
      },
      {
        label: 'Services',
        pathname: 'services',
        Component: Services,
        feature: features.ACCOUNTING,
        permission: permissions.ACCOUNTING
      }
    ]
  },
  {
    label: 'Configuration',
    value: 'config',
    Icon: AiFillTool,
    children: [
      {
        label: 'Types de projet',
        pathname: 'types-de-projet',
        Component: ProjectKinds,
        permission: [permissions.RESOURCES_CONFIG, 'project_kind.update']
      },
      {
        label: 'Groupes',
        pathname: 'groupes',
        Component: Groups,
        permission: [permissions.RESOURCES_CONFIG, 'group.update']
      },
      {
        label: 'Equipes',
        pathname: 'equipes',
        Component: Teams,
        feature: features.PLANNING_TEAMS,
        permission: [permissions.RESOURCES_CONFIG, 'team.update']
      },
      {
        label: 'Machines',
        pathname: 'machines',
        Component: Machines,
        feature: features.PLANNING_MACHINES,
        permission: [permissions.RESOURCES_CONFIG, 'machine.update']
      },
      {
        label: 'Fournisseurs',
        pathname: 'fournisseurs',
        Component: Purveyors,
        feature: features.ORDERS_COATING,
        permission: [permissions.RESOURCES_CONFIG, 'purveyor.update']
      },
      {
        label: 'Produit',
        pathname: 'produits',
        Component: Products,
        feature: features.ORDERS_COATING,
        permission: [permissions.RESOURCES_CONFIG, 'product.update']
      },
      {
        label: 'Matières premières',
        pathname: 'matieres-premieres',
        Component: RawMaterials,
        feature: features.ORDERS_COATING,
        permission: [permissions.RESOURCES_CONFIG, 'raw_material.update']
      },
      {
        label: 'Matériaux - entrants',
        pathname: 'materiaux-entrants',
        Component: InputMaterials,
        feature: features.MATERIALS,
        permission: [permissions.RESOURCES_CONFIG, 'input_material.update']
      },
      {
        label: 'Matériaux - sortants',
        pathname: 'materiaux-sortants',
        Component: ExtractionMaterials,
        feature: features.MATERIALS,
        permission: [permissions.RESOURCES_CONFIG, 'extraction_material.update']
      },
      {
        label: 'Matériaux - destinations sortants',
        pathname: 'materiaux-destinations-sortants',
        Component: ExtractionDestinations,
        feature: features.MATERIALS,
        permission: [permissions.RESOURCES_CONFIG, 'extraction_destination.update']
      },
      {
        label: 'Transporteurs',
        pathname: 'transporteurs',
        Component: Carriers,
        feature: features.ORDERS_DELIVERIES,
        permission: [permissions.RESOURCES_CONFIG, 'carrier.update']
      },
      {
        label: 'Périodes',
        pathname: 'periodes-exceptionnelles',
        Component: Periods,
        permission: [permissions.RESOURCES_CONFIG, 'exceptional_period.update']
      }
    ]
  },
  {
    label: 'Objets connectés',
    value: 'connected',
    Icon: MdGpsFixed,
    children: [
      {
        label: 'GPS',
        pathname: 'gps',
        Component: GpsBoxes,
        feature: features.GPS_BOX
      },
      {
        label: 'PMV',
        pathname: 'pmvs',
        Component: Pmvs,
        feature: features.PMV
      }
    ]
  },
  {
    label: 'Compte',
    value: 'account',
    Icon: MdSupervisorAccount,
    children: [
      {
        label: 'Informations',
        pathname: 'compte',
        Component: Account,
        permission: 'account.update'
      },
      {
        label: 'Membres',
        pathname: 'membres',
        Component: Users,
        permission: permissions.MANAGE_USER
      },
      {
        label: 'Paramètrage',
        pathname: 'parametrage-du-compte',
        Component: AccountSetting,
        permission: permissions.ADMIN
      }
    ]
  }
];

const Administration = () => {
  const { pathname: currentPath } = useLocation();
  const history = useHistory();
  const { path } = useRouteMatch();
  const { hasPermission, hasFeature, accountId } = useAccountConfig();
  const prevAccountId = usePrevious(accountId);

  const menu = useMemo(
    () =>
      MENU.map((menu) => {
        const { feature, permission, children } = menu;
        if (hasFeature(feature) && hasPermission(permission)) {
          return {
            ...menu,
            children: children?.filter(({ feature, permission }) => hasFeature(feature) && hasPermission(permission))
          };
        } else {
          return null;
        }
      }).filter(({ children }) => (children ? children.length > 0 : true)),
    [hasFeature, hasPermission]
  );

  const routes = useMemo(
    () => flatten(menu.map((m) => [m, ...(m.children || [])])).filter((route) => route && route.pathname),
    [menu]
  );

  useEffect(() => {
    if (prevAccountId && accountId !== prevAccountId) {
      const validPaths = routes.map((r) => `${path}/${r.pathname}`);
      if (!validPaths.includes(currentPath)) {
        history.replace(validPaths.find((path) => currentPath.includes(path)) || '/administration/compte');
      }
    }
  }, [history, prevAccountId, accountId, routes, path, currentPath]);

  if (routes.length === 0) {
    return null;
  }

  return (
    <Container>
      <Sidebar>
        <Scrollbars>
          <MainMenu>
            {menu.map(({ label, children, Icon, pathname }) => {
              const hasChildren = children && children.length > 0;
              const isMenuActive = hasChildren
                ? children.find((i) => currentPath.startsWith(`${path}/${i.pathname}`)) !== undefined
                : currentPath.startsWith(`${path}/${pathname}`);

              return (
                <SidebarMenuItem key={label}>
                  <SidebarMenuLink
                    main="true"
                    isActive={() => isMenuActive}
                    to={`/administration/${hasChildren ? children[0].pathname : pathname}`}
                  >
                    {Icon && <Icon size={24} />}
                    <span>{label}</span>
                  </SidebarMenuLink>

                  {(children || []).map(({ label, pathname }) => (
                    <SubMenu key={pathname} active={isMenuActive}>
                      <SidebarMenuItem
                        isActive={() => currentPath.startsWith(`${path}/${pathname}`)}
                        hasAccentBorder="true"
                      >
                        <SidebarMenuLink sub="true" to={`/administration/${pathname}`}>
                          <span>{label}</span>
                        </SidebarMenuLink>
                      </SidebarMenuItem>
                    </SubMenu>
                  ))}
                </SidebarMenuItem>
              );
            })}
          </MainMenu>
        </Scrollbars>
      </Sidebar>
      <Container vertical>
        <Switch>
          {routes.map(({ pathname, Component }) => (
            <Route key={pathname} path={`${path}/${pathname}`} component={Component} />
          ))}
          <Redirect to={`${path}/compte`} />
        </Switch>
      </Container>
    </Container>
  );
};

export default Administration;

import React, { useCallback } from 'react';
import { v4 } from 'uuid';
import { TextInputLayout, InputLayoutVariant } from 'components/v3/InputLayout';
import { FormTable } from 'components/v3/Form/TableField';
import MachineSelect from 'features/Machines/components/v3/Select';

export const machinePhaseRowCreator = () => ({
  machine: null,
  description: null,
  isNew: true,
  id: v4()
});

const isRowValid = row => Boolean(row.machine);

export const COLUMNS = [
  {
    Header: 'Machine',
    accessor: 'machine',
    width: '50%',
    dashed: true,
    isEditable: true,
    Cell: ({ item, onChange }) => (
      <MachineSelect
        variant={InputLayoutVariant.flat.medium}
        value={item.machine}
        fillWidth
        onChange={machine => {
          onChange({
            ...item,
            machine,
            machineId: machine?.id || null
          });
        }}
      />
    )
  },
  {
    Header: 'Description',
    accessor: 'description',
    width: '50%',
    dashed: true,
    isEditable: true,
    Cell: ({ item, onChange }) => (
      <TextInputLayout
        variant={InputLayoutVariant.flat.medium}
        label="Description"
        fillWidth
        value={item.description}
        onChange={description =>
          onChange({
            ...item,
            description
          })
        }
      />
    )
  }
];

const MachineTable = ({ id, machinePhases, readOnly, onChange }) => {
  const rowCreator = useCallback(
    () => ({
      ...machinePhaseRowCreator(),
      phaseId: id
    }),
    [id]
  );

  return (
    <FormTable
      label="Machines"
      asSection
      columns={COLUMNS}
      rowCreator={rowCreator}
      useWindow={false}
      value={machinePhases}
      addRowLabel="Ajouter une machine"
      onChange={onChange}
      isRowValid={isRowValid}
      minHeight={50}
      readOnly={readOnly}
    />
  );
};

export default MachineTable;

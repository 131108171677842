import { get, put, post, destroy } from 'common/api';

export const getOrganizations = params => get('/v5/organizations', params);

export const createOrganization = (organization, config) =>
  post('/v5/organizations', { organization }, config);

export const getOrganization = (id, config) =>
  get(`/v5/organizations/${id}`, config);

export const getProjects = config => get(`/v5/projects`, config);

export const getContacts = config => get(`/v5/contacts`, config);

export const putOrganization = (id, organization, config) =>
  put(`/v5/organizations/${id}`, { organization }, config);

export const deleteOrganization = (id, config) =>
  destroy(`/v5/organizations/${id}`, config);

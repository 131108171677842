import React, { useState, useCallback } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { MdAdd } from 'react-icons/md';
import SubHeader from 'components/v3/SubHeader';
import { FilterBar } from 'components/v3/Filters';
import { Container, Title, Button } from 'components/v3/ui';
import { ContactAction, ContactSelector } from 'features/Contacts';
import ContactList from 'features/Contacts/components/v3/List';
import { useDispatch } from 'react-redux';

const defaultParams = { sort: 'first_name,last_name' };

const Contacts = () => {
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  const [count, setCount] = useState();
  const [filters, setFilters] = useState();

  const handleDelete = useCallback(
    item => dispatch(ContactAction.deleteContact({ id: item.id })),
    [dispatch]
  );

  return (
    <Container vertical>
      <SubHeader>
        <Title>{`Contacts${count != null ? ` (${count})` : ''}`}</Title>
        <Button small to={`${path}/nouveau-contact`} icon={MdAdd}>
          Nouveau contact
        </Button>
      </SubHeader>
      <Container vertical huge padded>
        <FilterBar
          big
          searchQuery="first_name_or_last_name_or_email_cont_any"
          onChanges={setFilters}
        />
        <ContactList
          spacing={8}
          headerVariant="transparent"
          selector={ContactSelector.selectContacts}
          action={ContactAction.getContacts}
          onCountChanged={setCount}
          params={defaultParams}
          filters={filters}
          perPage={50}
          detailUrlPrefix=""
          isDeletable
          deleteRequest={handleDelete}
        />
      </Container>
    </Container>
  );
};

export default Contacts;

import { omit } from 'lodash';
import { mergeJsonApi } from 'common/reducers';
import isSameIds from 'utils/idUtils';

const initialState = {
  pmvById: {},
  pmvsIds: [],
  loading: {}
};

const pmvs = (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case 'NEW_PMV_SUCCESS':
      return {
        ...mergeJsonApi('pmv', state, action),
        pmvsIds: [payload.data.id, ...state.pmvsIds]
      };
    case 'DELETE_PMV_SUCCESS':
      return {
        ...state,
        pmvById: omit(state.pmvById, payload),
        pmvsIds: state.pmvsIds.filter(id => !isSameIds(id, payload))
      };
    case 'READ_PMVS_LOADING':
      return {
        ...state,
        loading: {
          ...state.loading,
          [payload.id]: payload.loading
        }
      };
    case 'LOGOUT':
    case 'SELECT_ACCOUNT':
      return initialState;
    default:
      return mergeJsonApi('pmv', state, action);
  }
};

export default pmvs;

import { omit } from 'lodash';
import { mergeJsonApi } from 'common/reducers';
import uniq from 'lodash/uniq';
import isSameIds from 'utils/idUtils';

const initialState = {
  projectById: {},
  projectsIds: [],
  searchIds: [],
  mapProjectsIds: []
};

const projects = (state = initialState, action) => {
  const { payload, type } = action;
  const pagination = payload?.meta?.pagination || {};
  const { page } = pagination;

  switch (type) {
    case 'SET_SEARCH_MAP_QUERY':
    case 'SELECT_GROUPS':
    case 'SELECT_PROJECT_KIND':
    case 'SELECT_PROJECT_STATE':
    case 'SET_MAP_DATES':
    case 'SELECT_USER':
      return {
        ...state,
        projectsIds: [],
        mapProjectsIds: []
      };
    case 'MAP_PROJECTS_SUCCESS':
      return {
        ...state,
        ...mergeJsonApi('project', state, action),
        mapProjectsIds:
          payload.meta?.pagination?.page === 1
            ? payload.data.map(d => d.id)
            : uniq([...state.mapProjectsIds, ...payload.data.map(d => d.id)])
      };
    case 'NEW_PROJECT_SUCCESS':
      return {
        ...mergeJsonApi('project', state, action),
        projectsIds: [parseInt(payload.data.id), ...state.projectsIds]
      };
    case 'DELETE_PROJECT_SUCCESS':
      return {
        ...state,
        projectById: omit(state.projectById, payload),
        projectsIds: state.projectsIds.filter(id => !isSameIds(id, payload))
      };
    case 'SEARCH_PROJECTS_SUCCESS':
      let searchIds = state.searchIds || [];
      if (page > 1) {
        searchIds = uniq([
          ...searchIds,
          ...payload.data.map(project => project.id)
        ]);
      } else {
        searchIds = payload.data.map(project => project.id);
      }
      return {
        ...state,
        ...mergeJsonApi('project', state, action),
        [type]: {
          fetchAt: new Date().getTime()
        },
        searchIds
      };
    case 'UPLOAD_PROJECT_ATTACHMENT_SUCCESS':
      if (!payload.projectId || !payload.data) {
        return state;
      }
      const project = state.projectById[payload.projectId];
      if (!project) {
        return state;
      }
      return {
        ...state,
        projectById: {
          ...state.projectById,
          [payload.projectId]: {
            ...project,
            relationships: {
              ...(project.relationships || {}),
              attachments: {
                data: [
                  ...(project.relationships.attachments?.data || []),
                  {
                    id: payload.data.id,
                    type: payload.data.type
                  }
                ]
              }
            }
          }
        }
      };
    case 'SELECT_ACCOUNT':
    case 'LOGOUT':
      return initialState;
    default:
      return mergeJsonApi('project', state, action);
  }
};

export default projects;

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import uniq from 'lodash/uniq';
import { deepMerge } from 'utils';
import { decamelize } from 'humps';

import { invoices, services, groupServices, units, subcontractings } from 'features/Accounting/data/reducer';
import accounts from 'features/Accounts/data/reducer';
import activities from 'features/ProjectKindsActivities/data/reducer';
import addresses from 'features/Addresses/data/reducer';
import { attachments, documentTemplates } from 'features/Attachments/data/reducer';
import { carriers, carrierOrders, carrierOrderDays, carriageTypes } from 'features/Carriers/data/reducer';
import contacts from 'features/Contacts/data/reducer';
import { customFieldGroups, customFields } from 'features/CustomFieldGroups/data/reducer';
import deliveries from 'features/Deliveries/data/reducer';
import { deviations, signs } from 'features/Deviation/data/reducer';
import groups from 'features/Groups/data/reducer';
import gpsBoxes from 'features/GpsBoxes/data/reducer';
import holidays from 'features/Holidays/data/reducer';
import { issues, issueEvents } from 'features/Issues/data/reducer';
import localizations from 'features/Localizations/data/reducer';
import machines from 'features/Machines/data/reducer';
import machinePhases from 'features/MachinePhases/data/reducer';
import namingRules from 'features/NamingRules/data/reducer';
import organizations from 'features/Organizations/data/reducer';
import phases from 'features/Phases/data/reducer';
import pmvs from 'features/Pmvs/data/reducer';
import exceptionalPeriods from 'features/Periods/data/reducer';
import plannings from 'features/Plannings/data/reducer';
import projects from 'features/Projects/data/reducer';
import projectActivities from 'features/ProjectKindsActivities/data/projectActivityReducer';
import projectKinds from 'features/ProjectKinds/data/reducer';
import {
  purveyors,
  rawMaterials,
  products,
  orders,
  orderDays,
  productRawMaterials
} from 'features/Surfacing/data/reducer';
import layers from 'features/Layers/data/reducer';
import { reports, reportKinds } from 'features/Reports/data/reducer';
import teams from 'features/Teams/data/reducer';
import ui from 'features/UiPreferences/data/reducer';
import users from 'features/Users/data/reducer';
import * as vouchers from 'features/Vouchers/data/reducer';
import { MaterialReducer } from 'features/Materials/data';
import { getPlural } from 'common';

export default (history) =>
  combineReducers({
    accounts,
    activities,
    addresses,
    attachments,
    carriers,
    carrierOrders,
    carriageTypes,
    carrierOrderDays,
    rawMaterials,
    products,
    productRawMaterials,
    customFields,
    customFieldGroups,
    contacts,
    deliveries,
    deviations,
    documentTemplates,
    exceptionalPeriods,
    groups,
    groupServices,
    gpsBoxes,
    holidays,
    invoices,
    issueEvents,
    issues,
    layers,
    localizations,
    machines,
    machinePhases,
    namingRules,
    orders,
    orderDays,
    organizations,
    phases,
    pmvs,
    plannings,
    projectActivities,
    projects,
    projectKinds,
    purveyors,
    reports,
    reportKinds,
    router: connectRouter(history),
    services,
    subcontractings,
    teams,
    signs,
    ui,
    units,
    users,
    ...vouchers,
    ...MaterialReducer
  });

export const idsWithPagination = (state, payload, key) => {
  let ids;
  if (payload.pagination && payload.pagination.page > 1) {
    ids = uniq([...state[key], ...payload.result]);
  } else {
    ids = payload.result;
  }
  return ids;
};

const mergeData = (rootType, state, data) => {
  if (!Array.isArray(data)) {
    if (!data || data.type !== rootType) {
      return state;
    }
    const { id, type, attributes, ...item } = data;
    return {
      ...state,
      [`${type}ById`]: deepMerge(state[`${type}ById`], {
        [id]: {
          ...item,
          ...attributes,
          id
        }
      })
    };
  }

  const filteredData = data.filter((i) => i.type === rootType);

  if (filteredData.length > 0) {
    const type = filteredData[0].type;
    return {
      ...state,
      [`${type}ById`]: deepMerge(
        state[`${type}ById`],
        filteredData.reduce((acc, { id, type, attributes, ...item }) => {
          acc[id] = {
            ...item,
            ...attributes,
            id
          };
          return acc;
        }, {})
      )
    };
  } else {
    return state;
  }
};

export const mergeJsonApi = (type, state, action) => {
  if (Array.isArray(type)) {
    let newState = state;
    let partialState = null;
    type.forEach((t) => {
      partialState = mergeJsonApi(t, newState, action);
      if (partialState !== newState) {
        newState = {
          ...newState,
          ...partialState
        };
      }
    });
    return newState;
  }
  const plural = getPlural(type);
  const typeIds = `${plural}Ids`;
  const actionPrefix = `${decamelize(plural).toUpperCase()}`;
  const { payload } = action;

  let newState = state;
  if (!payload) {
    if (action.type === `${actionPrefix}_LOADING`) {
      newState = {
        ...newState,
        isLoading: true
      };
    }
    if (action.type === `${actionPrefix}_ERROR`) {
      newState = {
        ...newState,
        isLoading: false
      };
    }
    return newState;
  }
  newState = mergeData(type, newState, payload.included);
  newState = mergeData(type, newState, payload.data);

  if (Array.isArray(payload.data) && action.type === `${actionPrefix}_SUCCESS`) {
    if (payload.meta && payload.meta.pagination && payload.meta.pagination.page > 1) {
      newState = {
        ...newState,
        [typeIds]: uniq([...newState[typeIds], ...payload.data.map((i) => i.id)]),
        isLoading: false,
        [action.type]: {
          fetchAt: new Date().getTime()
          // fetchPayload: JSON.stringify(payload.payload)
        }
      };
    } else {
      newState = {
        ...newState,
        [typeIds]: payload.data.map((i) => i.id),
        isLoading: false,
        [action.type]: {
          fetchAt: new Date().getTime()
          // fetchPayload: JSON.stringify(payload.payload)
        }
      };
    }
  }
  // if (
  //   action.type === `${decamelize(type.toUpperCase())}_LOADING` &&
  //   action.payload
  // ) {
  //   newState = {
  //     ...newState,
  //     [`${type}ById`]: merge(state[`${type}ById`], {
  //       [action.payload]: { isLoading: true }
  //     })
  //   };
  // }
  return newState;
};

import { createAction } from 'redux-actions';
import omit from 'lodash/omit';
import * as Api from './api';
import { shouldFetch } from 'common/actions';

const preparePayload = payload => {
  return {
    ...payload,
    projectKindId: payload?.projectKind?.id || null
  };
};

const machinesLoading = createAction('MACHINES_LOADING');
const machinesSuccess = createAction('MACHINES_SUCCESS');
const machinesError = createAction('MACHINES_ERROR');
export const getMachines = payload => async (dispatch, getState) => {
  const { useCache, ...params } = payload || {};
  const state = getState();
  if (!useCache || shouldFetch('machine', params, state)) {
    return fetchMachines(params, dispatch);
  } else {
    return {
      fromCache: true,
      isLoading: getState().machines.isLoading
    };
  }
};
const fetchMachines = async (payload, dispatch) => {
  dispatch(machinesLoading());
  try {
    const result = await Api.getMachines(payload);
    dispatch(
      machinesSuccess({
        ...result,
        requestParams: omit(payload, 'cancelToken')
      })
    );
    return result;
  } catch (error) {
    dispatch(machinesError(error));
    throw error;
  }
};

const receiveCreateMachine = createAction('NEW_MACHINE_SUCCESS');
export const createMachine = ({ payload, ...config }) => async dispatch => {
  const result = await Api.createMachine(preparePayload(payload), config);
  dispatch(receiveCreateMachine(result));
  return result;
};

const receiveMachine = createAction('MACHINE_SUCCESS');
export const getMachine = ({ id, ...payload }) => async dispatch => {
  const result = await Api.getMachine(id, payload);
  dispatch(receiveMachine(result));
  return result;
};

const receiveUpdateMachinesOrder = createAction('REORDER_MACHINES_SUCCESS');
export const updateMachinesOrder = ({
  id,
  payload: machine,
  payloads: machines,
  ...config
}) => async dispatch => {
  dispatch(receiveUpdateMachinesOrder(machines));
  const result = await Api.reorderMachine(machine.id, machine, config);
  return result;
};

const receiveUpdateMachine = createAction('UPDATE_MACHINE_SUCCESS');
export const updateMachine = ({ id, payload, ...config }) => async dispatch => {
  const result = await Api.putMachine(id, preparePayload(payload), config);
  dispatch(receiveUpdateMachine(result));
  return result;
};

const receiveDeleteMachine = createAction('DELETE_MACHINE_SUCCESS');
export const deleteMachine = ({ id, ...config }) => async dispatch => {
  const response = await Api.deleteMachine(id, config);
  dispatch(receiveDeleteMachine(id));
  return response;
};

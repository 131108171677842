import React from 'react';
import styled, { css } from 'styled-components';
import { NavLink as RawNavLink } from 'react-router-dom';
import RawSpinner from 'react-md-spinner';
import { Icon } from './icon';
import { fontSize } from 'ui';

const iconStyle = css`
  border-color: transparent;
  min-height: 32px;
  min-width: 32px;
  padding: 0px;
  * > svg {
    opacity: 0.54;
  }
  &:hover {
    * > svg {
      opacity: 1;
    }
  }
  > i {
    margin-right: 0px;
  }

  &:disabled {
    * > svg {
      fill: ${({ theme }) => theme.textDisabled};
    }
  }
`;

const VariantCss = {
  primary: css`
    background-color: ${({ theme }) => theme.primaryLight};
    color: ${({ theme }) => theme.textLight};
    border-color: ${({ theme }) => theme.primaryLight};

    &:hover:not(:active):not(:disabled) {
      background-color: ${({ theme }) => theme.primary};
    }
    &:active {
      background-color: ${({ theme }) => theme.primaryDark};
    }
  `,
  outline: css`
    background: none;
    color: ${({ theme }) => theme.primaryLight};
    box-shadow: none;
    border-color: ${({ theme }) => theme.primaryLight};

    &:hover:not(:active):not(:disabled) {
      background-color: ${({ theme }) => theme.hoverColor};
    }
    &:active {
      background-color: ${({ theme }) => theme.hoverColorDark};
    }
  `,
  accent: css`
    background-color: ${({ theme }) => theme.accent};
    color: ${({ theme }) => theme.textLight};
    border-color: ${({ theme }) => theme.backgroundLight};

    &:hover:not(:active):not(:disabled) {
      background-color: ${({ theme }) => theme.accentLight};
    }
    &:active {
      background-color: ${({ theme }) => theme.accentDark};
    }
  `,
  accentOutline: css`
    background: none;
    color: ${({ theme }) => theme.accent};
    box-shadow: none;
    border-color: ${({ theme }) => theme.accent};

    &:hover:not(:active):not(:disabled) {
      background-color: ${({ theme }) => theme.hoverColor};
    }
    &:active {
      background-color: ${({ theme }) => theme.hoverColorDark};
    }
  `,
  light: css`
    background-color: ${({ theme }) => theme.backgroundLight};
    color: ${({ theme }) => theme.primaryLight};
    border-color: ${({ theme }) => theme.backgroundLight};

    &:hover:not(:active):not(:disabled) {
      background-color: ${({ theme }) => theme.hoverColor};
    }
    &:active {
      background-color: ${({ theme }) => theme.hoverColorDark};
    }
  `,
  lightOutline: css`
    background: none;
    color: ${({ theme }) => theme.backgroundLight};
    box-shadow: none;
    border-color: ${({ theme }) => theme.backgroundLight};

    &:hover:not(:active):not(:disabled) {
      background-color: ${({ theme }) => theme.hoverColorBlack};
      color: ${({ theme }) => theme.textLight};
    }

    &:active {
      background-color: ${({ theme }) => theme.hoverColorBlacker};
    }
  `,
  text: css`
    color: ${({ theme }) => theme.primaryLight};
    box-shadow: none;
    border-color: transparent;
    background: none;

    &:hover:not(:active):not(:disabled) {
      background-color: ${({ theme }) => theme.hoverColor};
      box-shadow: none;
    }
    &:active {
      background-color: ${({ theme }) => theme.hoverColorDark};
      box-shadow: none;
    }
    &:disabled {
      background-color: transparent;
    }
    ${({ underline }) =>
      underline &&
      css`
        text-decoration: underline !important;
        text-decoration-color: ${({ theme }) => theme.primaryLight};
      `}
  `,
  textLight: css`
    color: ${({ theme }) => theme.backgroundLight};
    box-shadow: none;
    border-color: transparent;
    background-color: transparent;
    background: none;

    &:hover:not(:active):not(:disabled) {
      background-color: ${({ theme }) => theme.hoverColorBlack};
      box-shadow: none;
    }
    &:active {
      background-color: ${({ theme }) => theme.hoverColorBlacker};
      box-shadow: none;
    }
    &:disabled {
      background-color: transparent;
    }
    ${({ underline }) =>
      underline &&
      css`
        text-decoration: underline !important;
        text-decoration-color: ${({ theme }) =>
          theme.backgroundLight} !important;
      `}
  `,
  textCaption: css`
    color: ${({ theme }) => theme.textCaption};
    box-shadow: none;
    border-color: transparent;
    background: none;
    text-transform: initial;

    &:hover:not(:active):not(:disabled) {
      background-color: ${({ theme }) => theme.hoverColor};
      box-shadow: none;
    }
    &:active {
      background-color: ${({ theme }) => theme.hoverColorDark};
      box-shadow: none;
    }
    &:disabled {
      background-color: transparent;
    }
    ${({ underline }) =>
      underline &&
      css`
        text-decoration: underline !important;
        text-decoration-color: ${({ theme }) => theme.textCaption};
      `}
  `,
  icon: css`
    ${iconStyle}
    box-shadow: none;
    border-color: transparent;
    background: none;
    background-color: transparent;
    &:disabled {
      background-color: transparent;
    }
  `,
  iconLight: css`
    ${iconStyle}
    * > svg {
      opacity: 1;
    }
    background-color: ${({ theme }) => theme.backgroundLight};
    color: ${({ theme }) => theme.primaryLight};
    border-color: ${({ theme }) => theme.backgroundLight};

    &:hover:not(:active):not(:disabled) {
      background-color: ${({ theme }) => theme.hoverColor};
    }
    &:active {
      background-color: ${({ theme }) => theme.hoverColorDark};
    }
  `
};

const SpinnerCss = {
  primary: css`
    color: ${({ theme }) => theme.primaryLight};
  `,
  outline: css`
    color: ${({ theme }) => theme.primaryLight};
  `,
  accent: css`
    color: ${({ theme }) => theme.accent};
  `,
  accentOutline: css`
    color: ${({ theme }) => theme.accent};
  `,
  light: css`
    color: ${({ theme }) => theme.primaryLight};
  `,
  lightOutline: css`
    color: ${({ theme }) => theme.primaryLight};
  `,
  text: css`
    color: ${({ theme }) => theme.primaryLight};
  `,
  textLight: css`
    color: ${({ theme }) => theme.primaryLight};
  `,
  textCaption: css`
    color: ${({ theme }) => theme.textCaption};
  `,
  icon: css`
    color: ${({ theme }) => theme.primaryLight};
    margin-left: 0px;
  `
};
export const ButtonVariant = {
  primary: 'primary',
  outline: 'outline',
  accent: 'accent',
  accentOutline: 'accentOutline',
  light: 'light',
  lightOutline: 'lightOutline',
  text: 'text',
  textLight: 'textLight',
  textCaption: 'textCaption',
  icon: 'icon'
};

const Spinner = styled(RawSpinner)`
  margin-left: 12px;
  ${({ variant }) => SpinnerCss[variant] || SpinnerCss.primary}
`.withComponent(RawSpinner);

export const NavLink = styled(RawNavLink)`
  text-decoration: none;
`;

export const RawButton = styled.button`
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
  min-height: ${({ small }) => (small ? '32px' : '38px')};
  outline: none;
  padding: 4px 16px;
  min-width: 64px;
  font-weight: 600;
  border-radius: ${({ theme }) => theme.borderButtonRadius};
  border: solid 1px;
  transition: 300ms ease;
  transition-property: box-shadow, height, width, background, background-color;
  box-shadow: ${({ theme }) => theme.boxShadowIdle};
  cursor: pointer;
  text-transform: uppercase;
  font-size: ${({ small }) => (small ? fontSize(12) : fontSize(14))};
  text-align: center;

  &:disabled {
    box-shadow: none;
    background-color: ${({ theme }) => theme.disabled};
    color: ${({ theme }) => theme.textDisabled};
    cursor: not-allowed;
  }

  &:hover:not(:active):not(:disabled) {
    box-shadow: ${({ theme }) => theme.boxShadow};
  }
  &:active {
    box-shadow: ${({ theme }) => theme.boxShadowFocused};
  }

  ${({ small, ...props }) =>
    props['leading-icon'] &&
    css`
      ${small ? 'padding: 4px 14px 4px 6px' : 'padding: 4px 16px 4px 8px'};
    `}

  > i {
    margin-right: 4px;

    ${({ small }) =>
      small &&
      css`
        height: 20px;
        width: 20px;
      `}
  }

  ${({ variant }) => VariantCss[variant] || VariantCss.primary}
`;

export const ButtonLink = styled(RawButton)`
  cursor: pointer;
  text-decoration: none;
`.withComponent(NavLink);

const Button = React.forwardRef(
  (
    {
      isLoading,
      icon: LeadingIcon,
      underline,
      disabled,
      children,
      as,
      iconSize = 'small',
      small,
      ...props
    },
    ref
  ) => (
    <RawButton
      {...props}
      ref={ref}
      small={small ? 'true' : undefined}
      underline={underline ? 'true' : undefined}
      leading-icon={LeadingIcon ? 'true' : undefined}
      as={Boolean(props.to) ? ButtonLink : Boolean(as) ? as : null}
      type={props.type || Boolean(props.to) ? 'text' : 'button'}
      disabled={disabled || isLoading}
    >
      {LeadingIcon && (
        <Icon size={iconSize}>
          {typeof LeadingIcon === 'function' ? <LeadingIcon /> : LeadingIcon}
        </Icon>
      )}
      {children}
      {isLoading && (
        <Spinner size={16} variant={props.variant} singleColor="currentColor" />
      )}
    </RawButton>
  )
);

const _ButtonIcon = (props, ref) => {
  const { children, size, ...rest } = props;
  return (
    <Button ref={ref} variant={ButtonVariant.icon} {...rest}>
      {children && <Icon size={size}>{children}</Icon>}
    </Button>
  );
};

export const ButtonIcon = React.forwardRef(_ButtonIcon);

export default React.memo(Button);

import React, { useState, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { v4 } from 'uuid';
import isEqual from 'lodash/isEqual';
import { MdContentCopy } from 'react-icons/md';
import Scroller from 'components/v3/Scroller';
import Tooltip from 'components/v3/Tooltip';
import { Container, Text, ButtonIcon, Overline } from 'components/v3/ui';
import SwitchButton from 'components/SwitchButton';
import { AccountSelector } from 'features/Accounts';
import EditorPanel from './EditorPanel';
import Map from './Map';
import { toaster } from 'components/v3/Toast';

const PanelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 20%;
  min-width: 280px;
  max-width: 360px;
  @media screen and (max-width: 680px) {
    display: none;
  }
`;

const EditorWrapper = styled.div`
  flex-grow: 1;
  flex: 1;
`;

const PublishSwitch = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  ${Overline} {
    align-self: center;
    margin-left: 16px;
  }
`;

const CopyPublicUrlWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  span {
    align-self: center;
  }
`;

const PublishWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0;
  ${({ published }) =>
    published &&
    css`
      ${CopyPublicUrlWrapper} {
        opacity: 1;
        pointer-events: initial;
      }
    `}
`;

const MapWrapper = styled.div`
  position: relative;
  display: flex;
  flex: 1;
`;

const Deviation = ({
  project,
  readOnly,

  setFieldValue, // form related

  onProjectPathChanged, // "directions" + "points"
  onDeviationsChanged, // "deviations"
  onSignsChanged // "signs"
}) => {
  const mapRef = useRef();
  const [activeRouteId, setActiveRouteId] = useState();
  const { id, signs } = project || {};
  const account = useSelector(AccountSelector.selectCurrentAccount, isEqual);
  const { subdomain } = account || {};

  const handleDeviationsChange = useCallback(
    deviations => {
      if (setFieldValue) {
        setFieldValue('deviations', deviations);
      } else if (onDeviationsChanged) {
        onDeviationsChanged(deviations);
      } else {
        console.warn('there is not callback for deviation changes');
      }
    },
    [onDeviationsChanged, setFieldValue]
  );

  const handleProjectPathChanges = useCallback(
    ({ points, directions }) => {
      if (setFieldValue) {
        setFieldValue('points', points);
        setFieldValue('directions', directions);
      } else if (onProjectPathChanged) {
        onProjectPathChanged({ points, directions });
      } else {
        console.warn('there is not callback for project paths changes');
      }
    },
    [onProjectPathChanged, setFieldValue]
  );

  const handleAddSign = useCallback(
    sign => {
      const mapViewport = mapRef.current.mapViewport();
      if (!mapViewport) {
        return;
      }
      const { latitude, longitude } = mapViewport;
      const updated = [
        ...signs,
        {
          ...sign,
          isNew: true,
          id: v4(),
          attachmentId: sign.id,
          lat: latitude,
          lng: longitude
        }
      ];
      if (setFieldValue) {
        setFieldValue('signs', updated);
      } else if (onSignsChanged) {
        onSignsChanged(updated);
      }
    },
    [signs, onSignsChanged, setFieldValue]
  );

  const handleSignsChanges = useCallback(
    signs => {
      if (setFieldValue) {
        setFieldValue('signs', signs);
      } else if (onSignsChanged) {
        onSignsChanged(signs);
      } else {
        console.warn('there is not callback for signs changes');
      }
    },
    [onSignsChanged, setFieldValue]
  );

  const handleCopyPublicLink = useCallback(async () => {
    try {
      const url = `https://app.roadmapper.fr/public/${subdomain}/deviations/${id}`;
      await navigator.clipboard.writeText(url);
      toaster('Lien publique copié !', {
        position: 'bottom-center',
        autoClose: 2000
      });
    } catch (err) {
      toaster.warn('La copie du lien a échoué', {
        position: 'bottom-center'
      });
    }
  }, [id, subdomain]);

  return (
    <Container>
      <PanelWrapper>
        <EditorWrapper>
          <Scroller>
            <EditorPanel
              project={project}
              activeRouteId={activeRouteId}
              onRouteIdSelected={setActiveRouteId}
              onDeviationsChanged={handleDeviationsChange}
              onSignClick={handleAddSign}
              readOnly={readOnly}
            />
          </Scroller>
        </EditorWrapper>
        {!readOnly && (
          <PublishWrapper published={project?.deviationsPublished}>
            <PublishSwitch>
              <SwitchButton
                value={project?.deviationsPublished}
                onChange={checked =>
                  setFieldValue('deviationsPublished', checked)
                }
              />
              <Overline
                variant="caption"
                color={project?.deviationsPublished ? 'theme' : 'secondary'}
              >
                Déviation publique
              </Overline>
            </PublishSwitch>
            <CopyPublicUrlWrapper>
              <Tooltip content="Copier le lien publique de la déviation">
                <ButtonIcon onClick={handleCopyPublicLink}>
                  <MdContentCopy />
                </ButtonIcon>
              </Tooltip>
              <Text variant="caption" color="caption">
                Copier le lien
              </Text>
            </CopyPublicUrlWrapper>
          </PublishWrapper>
        )}
      </PanelWrapper>
      <MapWrapper>
        <Map
          mapRef={mapRef}
          height="100%"
          width="100%"
          project={project}
          activeRouteId={activeRouteId}
          onRouteIdSelected={setActiveRouteId}
          onDeviationsChanged={handleDeviationsChange}
          onProjectPathChanged={handleProjectPathChanges}
          onSignsChanged={handleSignsChanges}
          readOnly={readOnly}
        />
      </MapWrapper>
    </Container>
  );
};

export default Deviation;

import React, { Fragment } from 'react';
import styled from 'styled-components';
import RawSpinner from 'react-md-spinner';
import { DeviationAction, otherSigns } from 'features/Deviation';
import { Text, Button } from 'components/v3/ui';
import { authenticateUrl } from 'utils';
import { SIGN_SIZE } from 'features/Projects/components/v3/Deviation/utils';
import { AttachmentSelector } from 'features/Attachments';
import { useRedux } from 'components/v3/utils';

const SignsContainer = styled.div`
  display: grid;
  justify-items: center;
  grid-gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(75px, 1fr));
  margin: 8px;
`;

const SignButton = styled.div`
  display: inline-block;
  border: ${({ theme }) => theme.borderInput};
`.withComponent(Button);

const Sign = styled.img`
  object-fit: contains;
`;

const Spinner = styled.div`
  color: ${({ theme }) => theme.primaryLight};
  align-self: center;
  margin-top: 32px;
`.withComponent(RawSpinner);

const EditorSigns = ({ isEditable, selectedSign, onSignClick, ...props }) => {
  const [signs, , state] = useRedux(
    AttachmentSelector.selectSigns,
    DeviationAction.getSigns,
    {
      fetchOnMount: true,
      useLocalState: true
    }
  );

  const { isLoading, errorMessage } = state;

  const allSigns = [...(signs || []), ...otherSigns];
  const isEmpty = allSigns.length === 0 && !isLoading;
  if (errorMessage && isEmpty) {
    return (
      <Fragment>
        <Text variant="caption">
          Un problème est survenu lors de la récupération des panneaux...
        </Text>
        <Text variant="caption" color="hint">
          {errorMessage}
        </Text>
      </Fragment>
    );
  } else if (isEmpty) {
    return (
      <Text>
        Vous n'avez pas de panneaux disponible, veuillez contacter un
        administrateur pour en ajouter.
      </Text>
    );
  } else if (isLoading) {
    return <Spinner size={32} singleColor="currentColor" />;
  }

  return (
    <SignsContainer>
      {allSigns.map((sign, i) => (
        <SignButton
          variant="light"
          key={`${sign.name}-${i}`}
          onClick={e => {
            if (!isEditable) {
              return;
            }
            e.stopPropagation();
            e.preventDefault();
            onSignClick(sign);
          }}
        >
          <Sign
            width={SIGN_SIZE}
            height={SIGN_SIZE}
            src={
              sign.kind === 'deviation_sign'
                ? sign.src
                : authenticateUrl(sign.fileUrl)
            }
            alt={sign.name}
          />
        </SignButton>
      ))}
    </SignsContainer>
  );
};

export default EditorSigns;

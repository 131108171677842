import { post } from 'common/api';

export const getNamingRule = payload => {
  const { table, column, args } = payload;
  if (!Array.isArray(args)) {
    throw new Error('args must be an array');
  }
  return post(`/v5/naming_rules/${table}/${column}/next_value`, {
    naming_rules: { args }
  });
};

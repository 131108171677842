import { createAction } from 'redux-actions';
import omit from 'lodash/omit';
import * as Api from './api';
import { prepareAttributes, shouldFetch } from 'common/actions';

const carriersLoading = createAction('CARRIERS_LOADING');
const carriersSuccess = createAction('CARRIERS_SUCCESS');
const carriersError = createAction('CARRIERS_ERROR');
export const getCarriers = payload => async (dispatch, getState) => {
  const { useCache, ...params } = payload || {};
  const state = getState();
  if (!useCache || shouldFetch('carrier', params, state)) {
    return fetchCarriers(params, dispatch);
  } else {
    return {
      fromCache: true,
      isLoading: getState().carriers.isLoading
    };
  }
};
const fetchCarriers = async (payload, dispatch) => {
  dispatch(carriersLoading());
  try {
    const result = await Api.getCarriers(payload);
    dispatch(
      carriersSuccess({
        ...result,
        requestParams: omit(payload, 'cancelToken')
      })
    );
    return result;
  } catch (error) {
    dispatch(carriersError(error));
    throw error;
  }
};

const receiveCreateCarrier = createAction('NEW_CARRIER_SUCCESS');
export const createCarrier = ({ payload, ...config }) => async dispatch => {
  const result = await Api.createCarrier(payload, config);
  dispatch(receiveCreateCarrier(result));
  return result;
};

const receiveCarrier = createAction('CARRIER_SUCCESS');
export const getCarrier = ({ id, ...payload }) => async dispatch => {
  const result = await Api.getCarrier(id, payload);
  dispatch(receiveCarrier(result));
  return result;
};

const receiveUpdateCarrier = createAction('UPDATE_CARRIER_SUCCESS');
export const updateCarrier = ({
  id,
  payload: carrier,
  ...config
}) => async dispatch => {
  const result = await Api.putCarrier(id, carrier, config);
  dispatch(receiveUpdateCarrier(result));
  return result;
};

const receiveDeleteCarrier = createAction('DELETE_CARRIER_SUCCESS');
export const deleteCarrier = ({ id, ...config }) => async dispatch => {
  const response = await Api.deleteCarrier(id, config);
  dispatch(receiveDeleteCarrier(id));
  return response;
};

const prepareCarrierOrderPayload = ({ project, phase, carrier, ...payload }) =>
  prepareAttributes(
    {
      ...payload,
      carrierOrderDays: Object.keys(payload.carrierOrderDays || {}).map(
        date => {
          return {
            date,
            ...(payload.carrierOrderDays[date] || {})
          };
        }
      )
    },
    'carrierOrderDays'
  );

const receiveCarrierOrders = createAction('CARRIER_ORDERS_SUCCESS');
export const getCarrierOrders = payload => async dispatch => {
  const result = await Api.getCarrierOrders(payload);
  dispatch(receiveCarrierOrders(result));
  return result;
};

export const receiveUpdateCarrierOrder = createAction(
  'UPDATE_CARRIER_ORDER_SUCCESS'
);
export const updateCarrierOrder = ({
  id,
  payload: carrierOrder,
  ...config
}) => async dispatch => {
  const result = await Api.putCarrierOrder(
    id,
    prepareCarrierOrderPayload(carrierOrder),
    config
  );
  dispatch(receiveUpdateCarrierOrder(result));
  return result;
};

const receiveWeekCarrierOrders = createAction('WEEK_CARRIER_ORDERS_SUCCESS');
export const getWeekCarrierOrders = payload => async dispatch => {
  const result = await Api.getCarrierOrders(payload);
  dispatch(receiveWeekCarrierOrders(result));
  return result;
};

const carriageTypesLoading = createAction('CARRIAGE_TYPES_LOADING');
const carriageTypesSuccess = createAction('CARRIAGE_TYPES_SUCCESS');
const carriageTypesError = createAction('CARRIAGE_TYPES_ERROR');
export const getCarriageTypes = payload => async (dispatch, getState) => {
  const { useCache, ...params } = payload || {};
  const state = getState();
  if (!useCache || shouldFetch('carriageType', params, state)) {
    return fetchCarriageTypes(params, dispatch);
  } else {
    return {
      fromCache: true,
      isLoading: getState().carriageTypes.isLoading
    };
  }
};
const fetchCarriageTypes = async (payload, dispatch) => {
  dispatch(carriageTypesLoading());
  try {
    const result = await Api.getCarriageTypes(payload);
    dispatch(
      carriageTypesSuccess({
        ...result,
        requestParams: omit(payload, 'cancelToken')
      })
    );
    return result;
  } catch (error) {
    dispatch(carriageTypesError(error));
    throw error;
  }
};

const receiveCreateCarriageType = createAction('NEW_CARRIAGE_TYPE_SUCCESS');
export const createCarriageType = ({
  payload,
  ...config
}) => async dispatch => {
  const result = await Api.createCarriageType(payload, config);
  dispatch(receiveCreateCarriageType(result));
  return result;
};

const receiveCarriageType = createAction('CARRIAGE_TYPE_SUCCESS');
export const getCarriageType = ({ id, ...payload }) => async dispatch => {
  const result = await Api.getCarriageType(id, payload);
  dispatch(receiveCarriageType(result));
  return result;
};

const receiveUpdateCarriageType = createAction('UPDATE_CARRIAGE_TYPE_SUCCESS');
export const updateCarriageType = ({
  id,
  payload: carriageType,
  ...config
}) => async dispatch => {
  const result = await Api.putCarriageType(id, carriageType, config);
  dispatch(receiveUpdateCarriageType(result));
  return result;
};

const receiveDeleteCarriageType = createAction('DELETE_CARRIAGE_TYPE_SUCCESS');
export const deleteCarriageType = ({ id, ...config }) => async dispatch => {
  const response = await Api.deleteCarriageType(id, config);
  dispatch(receiveDeleteCarriageType(id));
  return response;
};

import React, {
  Fragment,
  useState,
  useCallback,
  useEffect,
  useMemo
} from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { formatDistanceToNow } from 'date-fns';
import fr from 'date-fns/locale/fr';
import { MdSend, MdArrowBack } from 'react-icons/md';
import { TextInputField, AddressField } from 'components/v3/Form';
import {
  SectionTitle,
  Grid,
  GridItem as RawGridItem,
  Button,
  Subtitle,
  Text
} from 'components/v3/ui';
import RawBox from 'components/v3/Box';
import { useQuery } from 'components/v3/utils';
import { Permission, permissions, useAccountConfig, features } from 'config';
import { ProjectKindField } from 'features/ProjectKinds/components/v3/Select';
import { OrganizationField } from 'features/Organizations/components/v3/Select';
import { ContactField } from 'features/Contacts/components/v3/Select';
import { UserField } from 'features/Users/components/v3/UserSelect';
import AdministrativeActivitiesField from 'features/Projects/components/v3/Activities';
import CustomFields from 'features/CustomFieldGroups/components/v3/CustomFields';
import InvitationModal from 'features/Projects/components/v3/InvitationModal';
import Deviation from 'features/Projects/components/v3/Deviation';
import { parseDate, printDate } from 'utils/dates';

const Box = styled(RawBox)`
  ${({ grow }) =>
    grow &&
    css`
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      height: calc(100% - 32px);
      align-items: start;
    `}
`;

const Section = styled(RawGridItem)`
  min-width: 280px;
  @media screen and (min-width: 800px) {
    min-width: 520px;
  }
`;

const GridItem = styled(RawGridItem)`
  min-width: 280px;
  @media screen and (min-width: 800px) {
    min-width: 360px;
  }
`;

const Informations = ({
  projectId,
  project,
  values,
  fetching,
  setFieldValue
}) => {
  const location = useLocation();
  const { url } = location;
  const history = useHistory();
  const { view } = useQuery();
  const { hasPermission, hasFeature, translate } = useAccountConfig();
  const { organization, contact } = values;
  const hasDeviationFeature = useMemo(
    () => hasFeature(features.DEVIATIONS),
    [hasFeature]
  );
  const [invitationModalOpen, setInvitationModalOpen] = useState(false);
  const isDeviationVisible = hasDeviationFeature && view === 'deviation';

  const handleShowInvitationModal = useCallback(
    () => setInvitationModalOpen(true),
    []
  );

  const handleMapClick = useMemo(() => {
    if (hasDeviationFeature) {
      return () =>
        history.push({
          pathname: url,
          search: '?view=deviation'
        });
    } else {
      return null;
    }
  }, [hasDeviationFeature, history, url]);

  useEffect(() => {
    if (organization?.id !== contact?.organization?.id) {
      if (organization && contact) {
        setFieldValue('contact', null);
      } else if (contact?.organization) {
        setFieldValue('organization', contact.organization);
      }
    }
  }, [setFieldValue, organization, contact]);

  const canEdit = hasPermission(
    !Boolean(projectId) ? 'project.create' : 'project.update',
    { target: values }
  );

  const _descriptionUpdatedAt = project?.descriptionUpdatedAt;
  const descriptionUpdatedAt = useMemo(
    () => parseDate(_descriptionUpdatedAt),
    [_descriptionUpdatedAt]
  );

  return (
    <Fragment>
      <Box marged padded={isDeviationVisible} grow={isDeviationVisible}>
        {isDeviationVisible ? (
          <Fragment>
            <Button
              variant="text"
              icon={MdArrowBack}
              onClick={() => {
                if (location.key) {
                  history.goBack();
                } else {
                  history.replace(url);
                }
              }}
            >
              Retour
            </Button>
            <Deviation
              project={values}
              readOnly={!canEdit}
              setFieldValue={setFieldValue}
            />
          </Fragment>
        ) : (
          <Grid spacing={8}>
            <Section width="50%">
              <GridItem width="100%" minWidth={312}>
                <Grid spacing={16}>
                  <GridItem width="100%">
                    <SectionTitle>Informations Générales</SectionTitle>
                  </GridItem>
                  <GridItem width="100%">
                    <TextInputField
                      name="displayName"
                      label="Nom du projet"
                      readOnly={!canEdit}
                    />
                  </GridItem>
                  <GridItem width="50%">
                    <TextInputField
                      name="reference"
                      label="Référence"
                      readOnly={!canEdit}
                    />
                  </GridItem>
                  <GridItem width="50%">
                    <ProjectKindField
                      name="projectKind"
                      required
                      useDefault
                      label="Type de projet"
                      readOnly={!canEdit || fetching}
                      isClearable={false}
                      hint={
                        Boolean(projectId)
                          ? null
                          : 'Le projet sera créé quand vous aurez selectionné un type.'
                      }
                    />
                  </GridItem>
                  <Permission feature={features.CRM}>
                    <GridItem width="50%">
                      <OrganizationField
                        name="organization"
                        label="Organisation"
                        readOnly={!canEdit}
                        onTrailingIconClick={() => {
                          setFieldValue('contact', null);
                        }}
                      />
                    </GridItem>
                    <GridItem width="50%">
                      <ContactField
                        name="contact"
                        label="Contact"
                        readOnly={!canEdit}
                        organizationId={values.organization?.id}
                      />
                    </GridItem>
                  </Permission>
                  <Permission feature={features.PROJECT_MANAGERS}>
                    <GridItem width="50%">
                      <UserField
                        name="owner"
                        label="Conducteur"
                        role={['conductor', 'admin']}
                        readOnly={!canEdit}
                      />
                    </GridItem>
                    <GridItem width="50%">
                      <UserField
                        name="assistant"
                        label="Secrétaire"
                        role={['conductor', 'admin']}
                        readOnly={!canEdit}
                      />
                    </GridItem>
                  </Permission>
                  <Permission permission={permissions.COMPANY_DATA}>
                    <GridItem width="100%">
                      <TextInputField
                        textArea
                        name="description"
                        label="Description / Informations supplémentaires"
                        minRows={4}
                        readOnly={!canEdit}
                        hint={
                          descriptionUpdatedAt
                            ? `dernière mise à jour: ${formatDistanceToNow(
                                descriptionUpdatedAt,
                                { addSuffix: true, locale: fr }
                              )} (${printDate(
                                descriptionUpdatedAt,
                                "dd/MM/yyyy 'à' HH:mm"
                              )})`
                            : ''
                        }
                      />
                    </GridItem>
                  </Permission>
                  <AdministrativeActivitiesField
                    name="projectActivities"
                    projectKindId={values.projectKind?.id}
                    readOnly={!canEdit}
                  />
                  <CustomFields
                    name="customData"
                    fieldableType="Project"
                    readOnly={!canEdit}
                  />
                </Grid>
              </GridItem>
            </Section>
            <Section width="50%">
              <Grid spacing={16}>
                <GridItem width="100%">
                  <SectionTitle>
                    {translate({
                      value: 'projects.address_title',
                      fallback: 'Lieu du projet'
                    })}
                  </SectionTitle>
                </GridItem>
                <GridItem width="100%">
                  <AddressField
                    name="address"
                    mapHeight={336}
                    mapRatio={3}
                    readOnly={!canEdit}
                    useAccountAddressIfEmpty={false}
                    onMapClick={handleMapClick}
                  />
                </GridItem>
              </Grid>
            </Section>
            {values.creator?.displayName && (
              <Permission permission={permissions.COMPANY_DATA}>
                <GridItem
                  width="100%"
                  style={{ textAlign: 'right', marginTop: -8, marginRight: 16 }}
                >
                  <Text variant="caption" color="hint">
                    Créé par
                  </Text>{' '}
                  <Text variant="caption" color="caption">
                    {values.creator?.displayName}
                  </Text>
                </GridItem>
              </Permission>
            )}
          </Grid>
        )}
        {invitationModalOpen && (
          <InvitationModal
            projectId={projectId}
            onRequestClose={() => setInvitationModalOpen(false)}
          />
        )}
      </Box>
      {!isDeviationVisible && (
        <Permission
          feature={features.CLIENT_SHARING}
          permissions={permissions.COMPANY_DATA}
        >
          <Box marged padded>
            <Grid spacing={8}>
              <GridItem width="100%">
                <SectionTitle>Partage client</SectionTitle>
              </GridItem>
              {canEdit && (
                <GridItem width="100%">
                  <Subtitle variant="small">{`En invitant des clients vous leur donnez la possibilité de voir ce projet. Vous pourrez également leur envoyer un email d'invitation${
                    Boolean(projectId) ? '.' : ' une fois le projet créé.'
                  }`}</Subtitle>
                </GridItem>
              )}
              <GridItem minWidth="50%">
                <UserField
                  name="clients"
                  label="Clients"
                  role={['user', 'client']}
                  multi
                  avatarHidden
                  readOnly={!canEdit}
                  trailingTooltip="Envoyer un email d'invitation au projet"
                  onTrailingIconClick={handleShowInvitationModal}
                  iconTrailing={
                    canEdit && Boolean(projectId) && values.clients?.length > 0
                      ? MdSend
                      : null
                  }
                />
              </GridItem>
            </Grid>
          </Box>
        </Permission>
      )}
    </Fragment>
  );
};

export default Informations;

import React from 'react';
import { SelectField } from 'components/v3/Form';
import { SelectInputLayout } from 'components/v3/InputLayout';

const KINDS = [
  { value: 'internal', label: 'Interne' },
  { value: 'external', label: 'Externe' }
];

const CarrierKindSelect = ({ value, onChange, ...props }) => {
  return (
    <SelectInputLayout
      label="Type de transporteur"
      value={value}
      options={KINDS}
      isSearchable={false}
      onChange={onChange}
      mapOptionValue
      sortOptions={false}
      {...props}
    />
  );
};

export const CarrierKindField = props => {
  return <SelectField component={CarrierKindSelect} {...props} />;
};

export default CarrierKindSelect;

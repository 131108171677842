import { createDeepEqualSelector, getParams, getProps } from 'common/selector';
import { getState, selectRelations } from 'common/selector';
import isSameIds from 'utils/idUtils';
import {
  startOfDay,
  startOfWeek,
  endOfWeek,
  startOfYear,
  endOfYear,
  getISOWeek,
  addWeeks
} from 'date-fns';

export const getCarriers = state => state.carriers;
export const getCarrierOrders = state => state.carrierOrders;
export const getCarriageTypes = state => state.carriageTypes;

export const selectCarriers = createDeepEqualSelector(
  [getCarriers, getState],
  ({ carriersIds, carrierById }, state) =>
    carriersIds
      .map(id => selectRelations('carrier', carrierById[id], state))
      .filter(Boolean)
);

export const selectCarrier = createDeepEqualSelector(
  [getCarriers, getParams, getProps],
  ({ carrierById }, { carrierId }, { carrier }) => {
    return carrierById[carrierId || (carrier && carrier.id)];
  }
);

export const selectCarrierV3 = createDeepEqualSelector(
  [getCarriers, getProps, getState],
  ({ carrierById }, { carrierId }, state) =>
    selectRelations('carrier', carrierById[carrierId], state)
);

export const selectCarriageTypes = createDeepEqualSelector(
  [getCarriageTypes, getState],
  ({ carriageTypesIds, carriageTypeById }, state) =>
    carriageTypesIds
      .map(id => selectRelations('carriageType', carriageTypeById[id], state))
      .filter(Boolean)
);

export const selectCarriageType = createDeepEqualSelector(
  [getCarriageTypes, getProps, getState],
  ({ carriageTypeById }, { carriageTypeId }, state) =>
    selectRelations('carriageType', carriageTypeById[carriageTypeId], state)
);

export const selectCarrierOrdersWeeksByYear = createDeepEqualSelector(
  [getCarrierOrders, getProps, getState],
  ({ carrierOrderById }, { carrierId, year }, state) => {
    const carrierOrders = Object.values(carrierOrderById || [])
      .map(carrierOrder => selectRelations('carrierOrder', carrierOrder, state))
      .filter(
        carrierOrder =>
          (isSameIds(carrierOrder?.carrier?.id, carrierId) ||
            isSameIds(carrierOrder?.carrierId, carrierId)) &&
          carrierOrder.years.includes(year)
      );

    const yearDate = new Date(year, 1, 1);

    let week = startOfDay(
      startOfWeek(startOfYear(yearDate), {
        weekStartsOn: 1
      })
    );
    const lastWeek = endOfWeek(endOfYear(yearDate), {
      weekStartsOn: 1
    });

    let carrierOrdersByWeek = [];
    let weekCarrierOrders = [];
    while (week <= lastWeek) {
      let isoWeek = getISOWeek(week);
      weekCarrierOrders = carrierOrders.filter(o => o.weeks.includes(isoWeek));
      carrierOrdersByWeek.push({
        date: week,
        isEmpty: weekCarrierOrders.length === 0
      });
      week = addWeeks(week, 1);
    }
    return carrierOrdersByWeek;
  }
);

export const selectCarrierOrdersByWeek = createDeepEqualSelector(
  [getCarrierOrders, getProps, getState],
  ({ carrierOrderById }, { carrierId, year, week }, state) => {
    return Object.values(carrierOrderById || [])
      .map(carrierOrder => selectRelations('carrierOrder', carrierOrder, state))
      .filter(
        carrierOrder =>
          (isSameIds(carrierOrder?.carrier?.id, carrierId) ||
            isSameIds(carrierOrder?.carrierId, carrierId)) &&
          carrierOrder.years.includes(year) &&
          carrierOrder.weeks.includes(week)
      );
  }
);

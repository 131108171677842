import React from 'react';
import styled from 'styled-components';
import { ProgressBar } from '../ui';
import { Wrapper as FilterBarWrapper } from '../Filters/FilterBar';

const Wrapper = styled.div`
  position: relative;
  display: inline-flex;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.backgroundLight};
  padding: 4px 16px;
  min-height: 48px;
  align-items: center;

  &:before {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    content: '';
    display: block;
    border-bottom: 1px solid ${({ theme }) => theme.separator};
  }

  ${FilterBarWrapper}, > ${FilterBarWrapper} {
    margin: 0px 32px;
    width: auto;
    // flex: 0;
    // flex-grow: 1;
    border: 1px solid ${({ theme }) => theme.borderInputColor};
    border-radius: ${({ theme }) => theme.borderButtonRadius};
  }
`;

const SubHeaderProgressBar = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
`.withComponent(ProgressBar);

const SubHeader = ({ isLoading, children, ...props }) => {
  return (
    <Wrapper {...props}>
      {children}
      {isLoading && <SubHeaderProgressBar />}
    </Wrapper>
  );
};

export default SubHeader;

import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Subtitle, Text, Title } from 'components/v3/ui';
import { HolidaySelector } from 'features/Holidays';
import { WorkRecordSplit } from './WorkRecordSplit';
import { get, sumBy, values } from 'lodash';
import { asNumber } from 'utils';

const Container = styled.div`
  display: flex;
`;

const Week = styled.div`
  width: 200px;
  padding: 4px 8px;
  border: 1px solid ${({ theme }) => theme.separator};
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  color: ${({ theme }) => theme.textDark};
  td {
    border: 1px solid ${({ theme }) => theme.separator};
    font-size: 0.875rem;
    text-align: center;
    padding: 4px 8px;
    &[tabindex='1'],
    &:focus-within {
      outline: 2px solid ${({ theme }) => theme.primaryLight};
      outline-offset: -2px;
    }
  }
  thead {
    font-weight: 600;
    color: ${({ theme }) => theme.textDarkSecondary};
  }
  .split {
    width: 17.5%;
  }
  .day {
    width: 30%;
    text-align: start;
  }
`;

export const WorkRecordsWeekTable = ({ week, days, formik }) => {
  const holidayKinds = useSelector(HolidaySelector.selectHolidayKinds);
  const holidayKindByValue = useMemo(() => {
    return holidayKinds.reduce((acc, kind) => {
      acc[kind.value] = kind;
      return acc;
    }, {});
  }, [holidayKinds]);

  return (
    <Container>
      <Week>
        <Title>{week.label}</Title>
        <Subtitle>{week.description}</Subtitle>
      </Week>
      <Table>
        <thead>
          <tr>
            <td className="day">Date</td>
            <td className="split">Matin</td>
            <td className="split">Après-midi</td>
            <td className="split">Heures sup.</td>
            <td className="split">Total</td>
          </tr>
        </thead>
        <tbody>
          {days.map(day => {
            const dayTotal = sumBy(
              values(get(formik.values, day.dateKey)),
              ({ duration }) => asNumber(duration) || 0
            );
            return (
              <tr key={day.dateKey}>
                <td className="day">{day.label}</td>
                <WorkRecordSplit
                  formik={formik}
                  dateKey={day.dateKey}
                  split="morning"
                  holidayKindByValue={holidayKindByValue}
                />
                <WorkRecordSplit
                  formik={formik}
                  dateKey={day.dateKey}
                  split="afternoon"
                  holidayKindByValue={holidayKindByValue}
                />
                <WorkRecordSplit
                  formik={formik}
                  dateKey={day.dateKey}
                  split="overtime"
                  withoutKindPicker
                  holidayKindByValue={holidayKindByValue}
                />
                <td>
                  <Text number variant="small" decimalScale={2}>
                    {dayTotal === 0 ? '' : dayTotal}
                  </Text>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Container>
  );
};

import { createDeepEqualSelector, getProps } from 'common/selector';
import { getState, selectRelations } from 'common/selector';
import { values } from 'lodash';

export const getVoucherKinds = state => state.voucherKinds;
export const getVouchers = state => state.vouchers;
export const getVoucherVersions = state => state.voucherVersions;

export const selectVoucherKinds = createDeepEqualSelector(
  [getVoucherKinds, getProps],
  ({ voucherKindById }, { voucherableType }) =>
    values(voucherKindById).filter(
      kind => !voucherableType || voucherableType === kind.voucherableType
    )
);

export const selectVoucher = createDeepEqualSelector(
  [getVouchers, getProps, getState],
  ({ voucherById }, { id }, state) =>
    selectRelations('voucher', voucherById[id], state)
);

export const selectVoucherVersion = createDeepEqualSelector(
  [getVoucherVersions, getProps, getState],
  ({ voucherVersionById }, { id }, state) =>
    selectRelations('voucherVersion', voucherVersionById[id], state)
);

export const selectVoucherKind = createDeepEqualSelector(
  [getVoucherKinds, getProps],
  ({ voucherKindById }, { id }) => voucherKindById[id]
);

import React, { useState, useCallback } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { MdAdd } from 'react-icons/md';
import SubHeader from 'components/v3/SubHeader';
import { FilterBar } from 'components/v3/Filters';
import { Container, Title, Button } from 'components/v3/ui';
import {
  MaterialSelector,
  MaterialAction,
  ExtractionDestinationList
} from 'features/Materials';

const defaultParams = { sort: 'name' };

export const ExtractionDestinations = () => {
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  const [count, setCount] = useState();
  const [filters, setFilters] = useState();

  const handleDelete = useCallback(
    item =>
      dispatch(MaterialAction.deleteExtractionDestination({ id: item.id })),
    [dispatch]
  );

  return (
    <Container vertical>
      <SubHeader>
        <Title>{`Destinations de sortant${
          count != null ? ` (${count})` : ''
        }`}</Title>
        <Button small to={`${path}/nouvelle-destination-sortant`} icon={MdAdd}>
          Nouvelle destination de sortant
        </Button>
      </SubHeader>
      <Container vertical huge padded>
        <FilterBar big searchQuery="nameContAny" onChanges={setFilters} />
        <ExtractionDestinationList
          spacing={8}
          headerVariant="transparent"
          selector={MaterialSelector.selectExtractionDestinations}
          action={MaterialAction.getExtractionDestinations}
          onCountChanged={setCount}
          params={defaultParams}
          filters={filters}
          perPage={50}
          detailUrlPrefix=""
          isDeletable
          deleteRequest={handleDelete}
        />
      </Container>
    </Container>
  );
};

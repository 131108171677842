import React from 'react';
import Planning from './';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { Text } from '../ui';
import { printDate } from 'utils/dates';

const plugins = [timeGridPlugin, interactionPlugin];

const headerLeftContent = ({ startAt }) => (
  <Text>{printDate(startAt, "'Semaine' I — MMMM — yyyy")}</Text>
);

const Calendar = React.forwardRef((props, ref) => {
  return (
    <Planning
      ref={ref}
      plugins={plugins}
      slotEventOverlap={false}
      headerLeftContent={headerLeftContent}
      {...props}
    />
  );
});

export default Calendar;

import { omit } from 'lodash';
import { mergeJsonApi } from 'common/reducers';
import isSameIds from 'utils/idUtils';

const initialState = {
  holidayById: {},
  holidaysIds: [],
  isUpdating: false
};

const holidays = (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case 'UPDATE_HOLIDAY_LOADING':
      return {
        ...state,
        isUpdating: true
      };
    case 'UPDATE_HOLIDAY_SUCCESS':
      return {
        ...mergeJsonApi('holiday', state, action),
        isUpdating: false
      };
    case 'UPDATE_HOLIDAY_ERROR':
      return {
        ...state,
        isUpdating: false
      };
    case 'NEW_HOLIDAY_SUCCESS':
      return {
        ...mergeJsonApi('holiday', state, action),
        holidaysIds: [payload.data.id, ...state.holidaysIds]
      };
    case 'DELETE_HOLIDAY_SUCCESS':
      return {
        ...state,
        holidayById: omit(state.holidayById, payload),
        holidaysIds: state.holidaysIds.filter(id => !isSameIds(id, payload))
      };
    case 'LOGOUT':
    case 'SELECT_ACCOUNT':
      return initialState;
    default:
      return mergeJsonApi('holiday', state, action);
  }
};

export default holidays;

import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { AccountSelector } from 'features/Accounts';
import LogoInfo from './LogoInfo';

const CurrentAccountLogoInfo = ({
  disableName = false,
  disableLogo = false,
  account = undefined,
  contact = undefined,
  onAccountReceived = undefined,
  ...props
}) => {
  const { currentAccount } = props;

  const onAccountReceivedRef = useRef();
  onAccountReceivedRef.current = onAccountReceived;

  useEffect(() => {
    if (onAccountReceivedRef.current && currentAccount) {
      onAccountReceivedRef.current(currentAccount);
    }
  }, [currentAccount]);

  if (!currentAccount && !account) {
    return null;
  }

  const info = account ? account : currentAccount;

  return (
    <LogoInfo contact={contact} {...{ disableName, disableLogo, ...info }} />
  );
};

const mapStateToProps = (state, props) => ({
  currentAccount: AccountSelector.selectCurrentAccount(state) || props.account
});

export default connect(
  mapStateToProps,
  null
)(CurrentAccountLogoInfo);

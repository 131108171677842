import React from 'react';
import get from 'lodash/get';
import { Text, Dashed } from '../ui';

const Column = ({ column, item, onChange, readOnly, index }) => {
  const { accessor, displayValue, cellProps: props } = column;
  const value = displayValue ? displayValue({ item }) : get(item, accessor);

  let editable = !readOnly;
  if (column.isEditable && editable) {
    if (typeof column.isEditable === 'function') {
      editable = column.isEditable({ item });
    }
  }

  const content = column.Cell ? (
    column.Cell({
      item,
      onChange,
      readOnly,
      index
    })
  ) : (
    <Text variant="small" {...(props || {})}>
      {value}
    </Text>
  );

  if (column.dashed && editable) {
    return <Dashed>{content}</Dashed>;
  } else {
    return content;
  }
};

export default React.memo(Column);

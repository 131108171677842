import React, { useState } from 'react';
import { v4 } from 'uuid';
import { Button, Text } from 'components/v3/ui';
import { MenuItem } from 'components/v3/Menu';
import { AccountingAction, getInvoiceKind } from '..';
import Popover from 'components/v3/Popover';
import ProjectSelect from 'features/Projects/components/v3/Select';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import Spinner from 'react-md-spinner';

export const InvoiceDuplicateButton = ({ values, onDuplicate }) => {
  const [open, setOpen] = useState(false);
  const [showDuplicateOptions, setShowDuplicateOptions] = useState(false);
  const [showProjectPicker, setShowProjectPicker] = useState(false);
  const [targetProject, setTargetProject] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const options = getInvoiceKind(values.kind).duplicableKinds.map(getInvoiceKind);

  const close = () => {
    setOpen(false);
    setShowDuplicateOptions(false);
    setShowProjectPicker(false);
    setTargetProject(null);
    setLoading(false);
  };
  if (!values.id) {
    return null;
  }
  return (
    <Popover
      isOpen={open}
      closeOnSelect={false}
      onRequestClose={() => {
        console.log('close');
        const opened = (!showDuplicateOptions && !showProjectPicker) || targetProject;
        if (!opened) {
          close();
        }
      }}
    >
      <span
        style={{ margin: 0, alignSelf: 'center' }}
        onClick={() => {
          if (open) {
            close();
          } else {
            setOpen(true);
          }
        }}
      >
        <Button small variant="outline">
          Dupliquer
        </Button>
      </span>
      {!showDuplicateOptions && !showProjectPicker && (
        <>
          <MenuItem
            onMouseDown={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
            onClick={() => setShowDuplicateOptions(true)}
          >
            Sur ce projet
          </MenuItem>
          <MenuItem
            onMouseDown={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
            onClick={() => setShowProjectPicker(true)}
          >
            Sur un autre projet
          </MenuItem>
        </>
      )}
      {!showDuplicateOptions && showProjectPicker && (
        <>
          <li style={{ padding: 8 }}>
            <Text variant="caption" style={{ padding: 16 }}>
              Rechercher un projet
            </Text>
          </li>
          <li style={{ padding: 16 }}>
            <ProjectSelect
              inputCreatable={false}
              onChange={(p) => {
                setTargetProject(p);
                setShowProjectPicker(false);
                setShowDuplicateOptions(true);
              }}
            />
          </li>
        </>
      )}
      {targetProject && (
        <li style={{ padding: 8, display: 'flex', alignItems: 'center', gap: 8 }}>
          {loading && <Spinner style={{ flexShrink: 0 }} />}
          <Text variant="caption">Dupliquer sur le projet {targetProject.displayName}</Text>
        </li>
      )}
      {showDuplicateOptions &&
        options.map(({ value, label }) => (
          <MenuItem
            key={value}
            onClick={() => {
              const invoice = {
                ...values,
                id: undefined,
                isNew: true,
                number: undefined,
                kind: value,
                state: '',
                signed: false,
                valueDate: new Date(),
                duplicate: true,
                invoice: values,
                invoiceId: values.id,
                discount: -parseFloat(values.discount || 0),
                secondDiscount: -parseFloat(values.secondDiscount || 0),
                invoiceLines: values.invoiceLines.map(({ id, invoiceId, ...line }) => ({
                  ...line,
                  id: v4(),
                  isNew: true
                }))
              };
              if (targetProject) {
                setLoading(true);
                dispatch(
                  AccountingAction.createInvoice({
                    payload: {
                      ...invoice,
                      projectId: targetProject.id
                    }
                  })
                ).then(({ data }) => {
                  history.push(`/projets/${targetProject.id}/comptabilite/${data.id}`);
                  close();
                });
              } else if (onDuplicate) {
                onDuplicate(invoice);
              }
            }}
          >
            {label}
          </MenuItem>
        ))}
    </Popover>
  );
};

import { createAction } from 'redux-actions';
import * as Api from './api';

const preparePayload = payload => ({
  ...payload,
  lines: (payload.lines || []).map(({ id, ...line }) => ({
    ...line,
    index: id
  }))
});

const receivePmvs = createAction('PMVS_SUCCESS');
export const getPmvs = payload => async dispatch => {
  const result = await Api.getPmvs(payload);
  dispatch(receivePmvs(result));
  return result;
};

const pvmLoading = createAction('READ_PMVS_LOADING');
const receiveReadPmv = createAction('READ_PMV_SUCCESS');
export const readPmv = id => async dispatch => {
  dispatch(pvmLoading({ id, loading: true }));
  let result;
  try {
    const result = await Api.readPmv(id);
    dispatch(receiveReadPmv(result));
  } catch {}
  dispatch(pvmLoading({ id, loading: false }));
  return result;
};

const receiveReadPmvs = createAction('READ_PMVS_SUCCESS');
export const readPmvs = payload => async dispatch => {
  const result = await Api.readPmvs(payload);
  dispatch(receiveReadPmvs(result));
  return result;
};

const receiveCreatePmv = createAction('NEW_PMV_SUCCESS');
export const createPmv =
  ({ payload, ...config }) =>
  async dispatch => {
    const result = await Api.createPmv(preparePayload(payload), config);
    dispatch(receiveCreatePmv(result));
    return result;
  };

const receivePmv = createAction('PMV_SUCCESS');
export const getPmv =
  ({ id, ...payload }) =>
  async dispatch => {
    const result = await Api.getPmv(id, payload);
    dispatch(receivePmv(result));
    return result;
  };

const receiveUpdatePmv = createAction('UPDATE_PMV_SUCCESS');
export const updatePmv =
  ({ id, payload, ...config }) =>
  async dispatch => {
    const result = await Api.putPmv(id, preparePayload(payload), config);
    dispatch(receiveUpdatePmv(result));
    return result;
  };

const receiveDeletePmv = createAction('DELETE_PMV_SUCCESS');
export const deletePmv =
  ({ id, ...config }) =>
  async dispatch => {
    const response = await Api.deletePmv(id, config);
    dispatch(receiveDeletePmv(id));
    return response;
  };

import React, {
  useCallback,
  useMemo,
  Fragment,
  useState,
  useEffect
} from 'react';
import { SelectField } from 'components/v3/Form';
import { SelectInputLayout } from 'components/v3/InputLayout';
import { useRedux } from 'components/v3/utils';
import { ExtractionMaterialModal } from './ExtractionMaterialModal';
import { jsonApiToObject } from 'common/api';
import {
  MaterialSelector,
  MaterialAction,
  extractionMaterialAsOption
} from '../data';
import { permissions, useAccountConfig } from 'config';

const formatCreateLabel = input => `Créer le sortant: ${input}`;

export const ExtractionMaterialSelect = ({ value, onChange, ...props }) => {
  const { accountId, hasPermission } = useAccountConfig();
  const [extractionMaterialModal, setExtractionMaterialModal] = useState();
  const [extractionMaterials, fetch] = useRedux(
    MaterialSelector.selectExtractionMaterials,
    MaterialAction.getExtractionMaterials,
    {
      fetchOnMount: false,
      useLocalState: true,
      initialParams: {
        page: 1,
        perPage: 500,
        useCache: true
      }
    }
  );

  const handleCreateOption = useCallback(name => {
    setExtractionMaterialModal({
      name
    });
  }, []);

  const handleEditOption = useCallback(value => {
    setExtractionMaterialModal(value);
  }, []);

  const options = useMemo(
    () => extractionMaterialAsOption(extractionMaterials),
    [extractionMaterials]
  );

  const currentValue = useMemo(
    () => extractionMaterialAsOption(value),
    [value]
  );

  useEffect(() => {
    fetch(params => params);
  }, [fetch, accountId]);

  return (
    <Fragment>
      <SelectInputLayout
        label="Sortant"
        value={currentValue}
        options={options}
        isSearchable
        onChange={onChange}
        inputCreatable={hasPermission([
          permissions.RESOURCES_CONFIG,
          'extraction_material.create'
        ])}
        createLabel="Créer un sortant"
        formatCreateLabel={formatCreateLabel}
        onCreateOption={handleCreateOption}
        optionEditable={hasPermission([
          permissions.RESOURCES_CONFIG,
          'extraction_material.update'
        ])}
        onEditOptionClick={handleEditOption}
        {...props}
      />
      {extractionMaterialModal && (
        <ExtractionMaterialModal
          extractionMaterial={extractionMaterialModal}
          onRequestClose={() => setExtractionMaterialModal(null)}
          onSubmitSuccess={response => {
            const extractionMaterial = jsonApiToObject(response);
            if (props.multi) {
              onChange([...currentValue, extractionMaterial]);
            } else {
              onChange(extractionMaterial);
            }
          }}
        />
      )}
    </Fragment>
  );
};

export const ExtractionMaterialField = props => {
  return <SelectField component={ExtractionMaterialSelect} {...props} />;
};

import React from 'react';
import DatePicker from '../DatePicker';
import { SelectInputLayout } from '../InputLayout';
import Checkbox from '../Checkbox';

const defaultSelectStyle = { minWidth: '120px', xWidth: '300px' };

const Filter = ({ kind, variant, component, value, props, onChange }) => {
  switch (kind) {
    case 'date':
      return (
        <DatePicker
          collapsedIfBlank={props?.collapsedIfBlank}
          variant={variant}
          disableTime
          mode="range"
          value={value}
          onChange={onChange}
          fitCurrentOptionWidth
          {...(props || {})}
        />
      );
    case 'select':
      const Component = component ? component : SelectInputLayout;
      return (
        <Component
          variant={variant}
          onChange={onChange}
          style={defaultSelectStyle}
          value={value ? value : props?.multi ? [] : null}
          fitCurrentOptionWidth
          inline
          optionEditable={false}
          inputCreatable={false}
          {...(props || {})}
        />
      );
    case 'checkbox':
      return <Checkbox onChange={onChange} value={value} {...(props || {})} />;
    default:
      if (typeof component === 'function') {
        return component({ onChange, value, ...(props || {}) });
      } else if (component) {
        const Component = component;
        return (
          <Component onChange={onChange} value={value} {...(props || {})} />
        );
      } else {
        return (
          <SelectInputLayout
            variant={variant}
            onChange={onChange}
            style={defaultSelectStyle}
            value={value ? value : props?.multi ? [] : null}
            fitCurrentOptionWidth
            inline
            {...(props || {})}
          />
        );
      }
  }
};

export default Filter;

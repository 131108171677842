import { mergeJsonApi } from 'common/reducers';

const initialGroupsState = {
  customFieldGroupById: {},
  customFieldGroupsIds: []
};

export const customFieldGroups = (state = initialGroupsState, action) => {
  const { type } = action;

  switch (type) {
    case 'LOGOUT':
    case 'SELECT_ACCOUNT':
      return initialGroupsState;
    default:
      return mergeJsonApi('customFieldGroup', state, action);
  }
};

const initialFieldsState = {
  customFieldById: {},
  customFieldsIds: []
};

export const customFields = (state = initialFieldsState, action) => {
  const { type } = action;

  switch (type) {
    case 'LOGOUT':
    case 'SELECT_ACCOUNT':
      return initialFieldsState;
    default:
      return mergeJsonApi('customField', state, action);
  }
};

import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { canUseDOM } from 'exenv';
import { values } from 'lodash';

export default class Portal extends React.Component {
  static propTypes = {
    onOutClick: PropTypes.func,
    rootElement: PropTypes.any
  };

  constructor(props, context) {
    super(props, context);

    if (canUseDOM) {
      if (this.node) {
        this.prevNode = this.node;
      }
      this.node = document.createElement('div');
      this.root = null;
      this.handleRootRef = root => {
        this.root = root;
      };

      this.handleOutClick = e => {
        const { onOutClick } = this.props;
        setTimeout(() => {
          if (e.defaultPrevented) {
            return;
          }
          if (typeof onOutClick === 'function') {
            if (this.root && !this.root.contains(e.target)) {
              onOutClick(e);
              e.stopPropagation();
            }
            if (!this.root) {
              onOutClick(e);
              e.stopPropagation();
            }
          }
        }, 16);

        // if (typeof onOutClick === 'function') {
        //   if (this.root && !this.root.contains(e.target)) {
        //     e.preventDefault();
        //     onOutClick(e);
        //   } else if (!this.root) {
        //     e.preventDefault();
        //     onOutClick(e);
        //   }
        // }
      };

      document.addEventListener('mousedown', this.handleOutClick, true);
      document.addEventListener('touchstart', this.handleOutClick, true);
    }
  }
  componentDidMount() {
    if (canUseDOM) {
      const element = this.props.rootElement || document.body;
      if (this.element) {
        this.prevElement = this.element;
      }
      this.element = element;
      element.appendChild(this.node);
    }
  }

  componentWillUnmount() {
    if (canUseDOM) {
      document.removeEventListener('mousedown', this.handleOutClick, true);
      document.removeEventListener('touchstart', this.handleOutClick, true);

      try {
        if (
          this.node &&
          this.node.parentNode &&
          values(this.node.parentNode.childNodes).find(n => this.node === n)
        ) {
          this.node.parentNode.removeChild(this.node);
        } else if (
          this.prevElement &&
          values(this.prevElement.childNodes).find(n => this.node === n)
        ) {
          this.prevElement.removeChild(this.node);
        } else if (
          this.prevElement &&
          this.prevElement.parentNode &&
          values(this.node.parentNode.childNodes).find(n => this.node === n)
        ) {
          this.prevElement.parentNode.removeChild(this.node);
        }
      } catch (e) {
        console.warn(e);
      }
    }
  }

  render() {
    const { onOutClick, rootElement, ...props } = this.props;

    return ReactDOM.createPortal(
      <div {...props} ref={this.handleRootRef} />,
      this.node
    );
  }
}

import React, { useState, useCallback } from 'react';
import { Field, FastField } from 'formik';
import { MdVisibilityOff, MdVisibility } from 'react-icons/md';
import { TextInputLayout, SelectInputLayout } from '../InputLayout';
import Checkbox from '../Checkbox';
import DatePicker from '../DatePicker';
import AddressPicker from '../AddressPicker';
import ColorPicker from '../ColorPicker';

const TextInput = ({
  field,
  onChange,
  form: { dirty, submitCount, touched, errors, setFieldValue },
  ...props
}) => {
  const { name, onBlur } = field;
  const handleChange = useCallback(
    value => {
      setFieldValue(name, value);
      if (onChange) onChange(value);
    },
    [setFieldValue, name, onChange]
  );
  const handleBlur = useCallback(
    e => {
      setFieldValue(name, e.target.value.trim());
      onBlur(e);
    },
    [setFieldValue, onBlur, name]
  );
  return (
    <TextInputLayout
      {...field}
      {...props}
      onChange={handleChange}
      onBlur={handleBlur}
      error={
        (dirty && touched[name]) || submitCount > 0 ? errors[name] : undefined
      }
    />
  );
};

export const TextInputField = ({ fastField = false, ...props }) => {
  const Component = fastField ? FastField : Field;
  return <Component {...props} component={TextInput} />;
};

export const PasswordField = props => {
  const [visible, setVisible] = useState(false);
  const type = visible ? 'text' : 'password';
  const toggleVisible = useCallback(() => {
    setVisible(v => !v);
  }, []);
  return (
    <TextInputField
      {...props}
      type={type}
      iconTrailing={visible ? MdVisibility : MdVisibilityOff}
      onTrailingIconClick={toggleVisible}
    />
  );
};

const CheckboxInput = ({
  field,
  form: { dirty, submitCount, touched, errors, setFieldValue },
  ...props
}) => {
  const { name } = field;
  const handleChange = useCallback(value => setFieldValue(name, value), [
    setFieldValue,
    name
  ]);
  return (
    <Checkbox
      {...field}
      {...props}
      onChange={handleChange}
      error={
        (dirty && touched[name]) || submitCount > 0 ? errors[name] : undefined
      }
    />
  );
};

export const CheckboxField = ({ fastField = false, ...props }) => {
  const Component = fastField ? FastField : Field;
  return <Component {...props} component={CheckboxInput} />;
};

const SelectInput = ({
  field: { onChange, onBlur, ...field },
  form: { dirty, submitCount, touched, errors, setFieldValue },
  selectComponent: SelectComponent,
  onInputChange,
  ...props
}) => {
  const { name } = field;
  const { freeSolo } = props;

  const handleChange = useCallback(value => setFieldValue(name, value), [
    name,
    setFieldValue
  ]);

  const handleInputChange = useCallback(
    value => {
      if (freeSolo) {
        setFieldValue(name, value);
      }
      if (onInputChange) {
        onInputChange(value);
      }
    },
    [freeSolo, name, setFieldValue, onInputChange]
  );
  const Component = SelectComponent ? SelectComponent : SelectInputLayout;
  return (
    <Component
      {...field}
      onChange={handleChange}
      onInputChange={handleInputChange}
      {...props}
      error={
        (dirty && touched[name]) || submitCount > 0 ? errors[name] : undefined
      }
      debounceInput
    />
  );
};

export const SelectField = ({ fastField = false, component, ...props }) => {
  const Component = fastField ? FastField : Field;
  return (
    <Component {...props} component={SelectInput} selectComponent={component} />
  );
};

const DatePickerComponent = ({
  field,
  form: { dirty, submitCount, touched, errors, values, setFieldValue },
  ...props
}) => {
  const { name, value } = field;
  const { mode } = props;
  let fieldName = name;
  let fieldValue = value;
  if (mode === 'range') {
    const split = fieldName.split(',');
    fieldName = fieldName.split(',')[0];
    fieldValue = {
      startAt: values[split[0]],
      endAt: values[split[1]]
    };
  }
  const handleChange = useCallback(
    value => {
      if (mode === 'range') {
        const split = name.split(',');
        setFieldValue(split[0], value?.startAt);
        setFieldValue(split[1], value?.endAt);
      } else {
        setFieldValue(name, value);
      }
    },
    [setFieldValue, name, mode]
  );

  return (
    <DatePicker
      {...field}
      name={fieldName}
      value={fieldValue}
      {...props}
      onChange={handleChange}
      error={
        (dirty && touched[name]) || submitCount > 0 ? errors[name] : undefined
      }
    />
  );
};

export const DatePickerField = ({ fastField = false, ...props }) => {
  const Component = fastField ? FastField : Field;
  return <Component {...props} component={DatePickerComponent} />;
};

export const AddressPickerComponent = ({
  field,
  form: { dirty, submitCount, touched, errors, setFieldValue },
  ...props
}) => {
  const { name } = field;
  const handleChange = useCallback(value => setFieldValue(name, value), [
    setFieldValue,
    name
  ]);
  return (
    <AddressPicker
      {...field}
      {...props}
      onChange={handleChange}
      error={
        (dirty && touched[name]) || submitCount > 0 ? errors[name] : undefined
      }
    />
  );
};

export const AddressField = ({ fastField = false, ...props }) => {
  const Component = fastField ? FastField : Field;
  return <Component {...props} component={AddressPickerComponent} />;
};

export const ColorPickerComponent = ({
  field,
  form: { setFieldValue },
  ...props
}) => {
  const { name } = field;
  const handleChange = useCallback(value => setFieldValue(name, value), [
    setFieldValue,
    name
  ]);
  return <ColorPicker {...field} {...props} onChange={handleChange} />;
};

export const ColorField = ({ fastField = false, ...props }) => {
  const Component = fastField ? FastField : Field;
  return <Component {...props} component={ColorPickerComponent} />;
};

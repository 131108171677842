import {
  MachineApi,
  MachineAction,
  MachineReducer,
  MachineSelector
} from './data';

export { MachineApi, MachineAction, MachineReducer, MachineSelector };

export const machineAsOption = machine => {
  if (Array.isArray(machine)) {
    return machine.map(machineAsOption);
  } else if (machine) {
    return {
      value: machine.id,
      label: machine.displayName,
      original: machine
    };
  } else {
    return null;
  }
};

export const machineAsValues = machine => ({
  displayName: machine?.displayName || '',
  description: machine?.description || '',
  dailyCost: machine?.dailyCost || 0,
  projectKind: machine?.projectKind || null
});

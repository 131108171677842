import { createDeepEqualSelector, getProps } from 'common/selector';
import { getState, selectRelations } from 'common/selector';
import { sortBy } from 'lodash';

export const getUsers = state => state.users;

export const selectUsers = createDeepEqualSelector(
  [getUsers, getState],
  ({ usersIds, userById }, state) =>
    usersIds
      .map(id => selectRelations('user', userById[id], state))
      .filter(Boolean)
);

export const selectUsersById = createDeepEqualSelector(
  [getUsers, getProps, getState],
  ({ userById }, { userIds }, state) =>
    (userIds || [])
      .map(id => selectRelations('user', userById[id], state))
      .filter(Boolean)
);

export const selectAllUsers = createDeepEqualSelector(
  [getUsers, getState],
  ({ userById }, state) =>
    Object.values(userById)
      .filter(
        u => u.settings && !u.settings.archived && u.topRole !== 'super_admin'
      )
      .map(user => selectRelations('user', user, state))
      .filter(Boolean)
);

export const selectAllProductionUsers = createDeepEqualSelector(
  [getUsers, getState],
  ({ userById }, state) =>
    Object.values(userById)
      .filter(
        u =>
          (u.production || u.topRole === 'technician') &&
          u.settings &&
          !u.settings.archived
      )
      .map(user => selectRelations('user', user, state))
      .filter(Boolean)
);

export const selectProductionUsers = (...args) =>
  selectUsers(...args).filter(
    u => u.production && u.settings && !u.settings.archived
  );

export const selectUserV3 = createDeepEqualSelector(
  [getUsers, getProps, getState],
  ({ userById }, { userId }, state) =>
    selectRelations('user', userById[userId], state)
);

export const selectSearchUsers = createDeepEqualSelector(
  [getUsers, getProps, getState],
  ({ userById }, { role }, state) =>
    sortBy(Object.values(userById), 'displayName')
      .map(user => selectRelations('user', user, state))
      .filter(user => filterUser(user, role))
      .slice(0, 50)
);

export const filterUser = (user, role) => {
  if (!user || user.settings?.archived) {
    return false;
  }
  if (!role || (Array.isArray(role) && role.filter(Boolean).length === 0)) {
    return true;
  }
  const userRole = user.role || user.topRole;
  const isTechnicianRole = Array.isArray(role)
    ? role.includes('technician')
    : role === 'technician';
  const hasRole = Array.isArray(role)
    ? role.includes(userRole)
    : userRole === role;
  if (isTechnicianRole) {
    return hasRole || user.settings?.production;
  } else {
    return hasRole;
  }
};

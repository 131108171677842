import React, { useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { MdAdd } from 'react-icons/md';
import Box, { BoxHeader, BoxFilter } from 'components/v3/Box';
import Menu, { MenuItem } from 'components/v3/Menu';
import { Button } from 'components/v3/ui';
import InvoiceList from './InvoiceList';
import { INVOICE_KINDS } from 'features/Accounting';
import State from './InvoiceState';

const FILTERS = [
  {
    kind: 'select',
    key: 'invoiceKind',
    value: null,
    asQueryParam: value => ({
      kindEq: value
    }),
    props: {
      label: 'Type',
      options: INVOICE_KINDS,
      mapOptionValue: true,
      isSearchable: false,
      style: { minWidth: 100, maxWidth: 200 }
    }
  },
  {
    kind: 'select',
    key: 'select',
    component: State,
    value: null,
    asQueryParam: value => ({
      stateEq: value?.value
    }),
    props: {
      inputCreatable: false,
      asTag: false,
      isClearable: true,
      style: { minWidth: 100, maxWidth: 220 }
    }
  }
];

const InvoiceBox = ({
  fetchAction,
  selector,
  params,
  creatable,
  marged,
  padded,
  ...props
}) => {
  const { url } = useRouteMatch();
  const [count, setCount] = useState(null);
  const [filters, setFilters] = useState(FILTERS);

  return (
    <Box marged={marged} padded={padded}>
      <BoxHeader
        bigPadded
        title={`Comptabilité${count != null ? ` (${count})` : ''}`}
      >
        {fetchAction && (
          <BoxFilter
            searchQuery="byQuery"
            filters={filters}
            onChanges={setFilters}
          />
        )}
        {creatable && (
          <Menu>
            <Button small icon={MdAdd}>
              Ajouter
            </Button>
            {INVOICE_KINDS.map(({ label, value }) => (
              <MenuItem key={value} to={`${url}/nouveau?kind=${value}`}>
                {label}
              </MenuItem>
            ))}
          </Menu>
        )}
      </BoxHeader>
      <InvoiceList
        rowColor="light"
        selector={selector}
        action={fetchAction}
        params={params}
        filters={filters}
        onCountChanged={setCount}
        headerVariant="transparent"
        minRows={5}
        maxHeight={500}
        {...props}
      />
    </Box>
  );
};

export default InvoiceBox;

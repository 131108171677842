export { GpsBoxAction, GpsBoxApi, GpsBoxReducer, GpsBoxSelector } from './data';

export const gpsBoxAsValues = gpsBox => ({
  id: gpsBox?.id,
  machine: gpsBox?.machine || null
});

export const gpsBoxAsMarker = gpsBox => {
  if (
    !gpsBox ||
    !gpsBox.address ||
    !gpsBox.address.lat ||
    !gpsBox.address.lng
  ) {
    return null;
  }
  return { ...gpsBox.address, id: gpsBox.id, kind: 'gpsBox', item: gpsBox };
};

export const gpsHistoriesAsRoute = gpsBoxHistories => {
  if (!gpsBoxHistories) {
    return [];
  }
  const routeId = -1;
  const color = '#3943ae';

  const points = [];
  const markers = [];
  gpsBoxHistories.forEach(history => {
    points.push([history.lat, history.lng]);
    markers.push({
      id: history.id,
      lat: parseFloat(history.lat),
      lng: parseFloat(history.lng),
      editable: false,
      color,
      kind: 'gpsPoint',
      draggable: false,
      routeId,
      item: history,
      hidden: false
    });
  });

  return {
    id: routeId,
    name: 'Historique',
    color,
    kind: 'gpsBox',
    directions: points,
    markers
  };
};

import React from 'react';
import styled from 'styled-components';
import { GridItem } from 'components/v3/ui';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
`;

const Title = styled(GridItem)`
  color: ${({ theme }) => theme.textLight};
  padding: 4px 6px;
  &:nth-child(2) {
    border-top-left-radius: ${({ theme }) => theme.borderInputRadius};
  }
  &:nth-last-child(2) {
    border-top-right-radius: ${({ theme }) => theme.borderInputRadius};
  }
  &:not(:first-child):not(:last-child) {
    background-color: ${({ theme }) => theme.primaryLight};
    border-right: 2px solid ${({ theme }) => theme.backgroundLight};
  }
  &:first-child {
    background-color: ${({ theme }) => theme.backgroundLight};
  }
  pointer-events: none;
`;

const Header = () => {
  return (
    <Wrapper>
      <Title width="48px" minWidth="48px"></Title>
      <Title width="48%">Service</Title>
      <Title width="8%">Quantité</Title>
      <Title width="12%">Unité</Title>
      <Title width="12%">P.U. HT</Title>
      <Title width="12%">Total HT</Title>
      <Title width="8%">TVA</Title>
      <Title width="48px" minWidth="48px"></Title>
      <Title width="14px" minWidth="14px"></Title>
    </Wrapper>
  );
};

export default React.memo(Header);

import { createDeepEqualSelector, getProps } from 'common/selector';
import { getState, selectRelations } from 'common/selector';
import isSameIds from 'utils/idUtils';

export const getReports = state => state.reports;
export const getReportKinds = state => state.reportKinds;

export const selectReportKinds = createDeepEqualSelector(
  [getReportKinds, getProps, getState],
  ({ reportKindsIds, reportKindById }, { projectKindId }, state) =>
    reportKindsIds
      .map(id => selectRelations('reportKind', reportKindById[id], state))
      .filter(Boolean)
      .filter(
        ({ projectKinds, projectKindIds }) =>
          Boolean(projectKinds?.find(pk => isSameIds(projectKindId, pk.id))) ||
          Boolean(projectKindIds?.find(id => isSameIds(projectKindId, id)))
      )
);

export const selectReports = createDeepEqualSelector(
  [getReports, getState],
  ({ reportsIds, reportById }, state) =>
    reportsIds
      .map(id => selectRelations('report', reportById[id], state))
      .filter(Boolean)
);

export const selectReport = createDeepEqualSelector(
  [getReports, getProps, getState],
  ({ reportById }, { reportId }, state) =>
    selectRelations('report', reportById[reportId], state)
);

export const selectProjectReports = createDeepEqualSelector(
  [getReports, getProps, getState],
  ({ reportById }, { projectId }, state) => {
    if (!projectId) {
      return [];
    }
    return Object.values(reportById)
      .filter(
        report =>
          isSameIds(report.projectId, projectId) ||
          isSameIds(report.relationships?.project?.id, projectId)
      )
      .map(report => selectRelations('report', reportById[report.id], state))
      .filter(Boolean);
  }
);

import React from 'react';
import styled, { css, FlattenInterpolation, ThemedStyledProps } from 'styled-components';
import NumberFormat from 'react-number-format';
import { fontSize } from 'ui';
import { upperFirstLetter } from 'utils';

export interface ThemeProps {
  [field: string]: FlattenInterpolation<ThemedStyledProps<any, any>>;
}

const TextVariantCss: ThemeProps = {
  small: css`
    font-size: ${fontSize(14)};
  `,
  hint: css`
    font-size: ${fontSize(13)};
  `,
  caption: css`
    font-size: ${fontSize(12)};
    opacity: 0.8;
    letter-spacing: 0.025rem;
    color: ${({ theme }) => theme.textCaption};
    ${(props: ThemeProps) => {
      if (typeof props.weight === 'string') {
        return (TextWeightCss[props.weight] as any) || TextWeightCss.semiBold;
      } else {
        return TextWeightCss.semiBold;
      }
    }}
  `
};

export const TextVariant = {
  small: 'small',
  hint: 'hint',
  caption: 'caption'
};

export const TextColor = {
  secondary: 'secondary',
  hint: 'hint',
  lightPrimary: 'lightPrimary',
  lightSecondary: 'lightSecondary',
  lightHint: 'lightHint',
  caption: 'caption',
  theme: 'theme',
  themeLight: 'themeLight'
};

const TextWeightCss = {
  light: css`
    font-weight: 300;
  `,
  regular: css`
    font-weight: 400;
  `,
  semiBold: css`
    font-weight: 600;
  `,
  bold: css`
    font-weight: 700;
  `,
  extraBold: css`
    font-weight: 800;
  `
};

export type TextWeight = 'light' | 'regular' | 'semiBold' | 'bold' | 'extraBold';

export const Headline = styled.h2<{ weight?: TextWeight }>`
  font-size: ${fontSize(30)};
  ${({ weight }) => (weight && TextWeightCss[weight]) || TextWeightCss.bold}
  color: ${({ theme }) => theme.textTheme};
  letter-spacing: 0.035rem;
  margin-block-start: 0;
  margin-block-end: 0;
`;

export const SectionTitle = styled.h3<{ weight?: TextWeight }>`
  font-size: ${fontSize(20)};
  ${({ weight }) => (weight && TextWeightCss[weight]) || TextWeightCss.bold}
  color: ${({ theme }) => theme.textTheme};
  letter-spacing: 0.025rem;
  margin-block-start: 0px;
  margin-block-end: 0px;
`;

export const Overline = styled.div<{ weight?: TextWeight }>`
  font-size: ${fontSize(12)};
  ${({ weight }) => (weight && TextWeightCss[weight]) || TextWeightCss.semiBold}
  text-transform: uppercase;
  letter-spacing: 0.1rem;

  color: ${({ color, theme }) => theme[`text${upperFirstLetter(color)}`] || theme.textHint};
`;

export const Title = styled.div<{ weight?: TextWeight; ellipsis?: boolean }>`
  font-size: ${fontSize(20)};
  line-height: 1.75rem;
  letter-spacing: 0.0125rem;
  ${({ weight }) => (weight && TextWeightCss[weight]) || TextWeightCss.semiBold}
  color: ${({ color, theme }) => theme[`text${upperFirstLetter(color)}`] || theme.text};

  ${({ ellipsis }) =>
    ellipsis &&
    css`
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    `}
`;

export const Subtitle = styled.div<{ weight?: TextWeight; variant?: string }>`
  font-size: ${fontSize(16)};
  ${({ weight }) => (weight && TextWeightCss[weight]) || TextWeightCss.regular}
  letter-spacing: 0.025rem;
  color: ${({ color, theme }) => theme[`text${upperFirstLetter(color) || 'Secondary'}`]};

  ${({ variant }) => {
    switch (variant) {
      case 'small':
        return css`
          font-size: ${fontSize(14)};
        `;
      default:
        return null;
    }
  }}
`;

const Text = styled.span<{
  variant?: string;
  weight?: TextWeight;
  verticalCenter?: boolean;
  'vertical-center'?: boolean;
  alignCenter?: boolean;
  'align-center'?: boolean;
  alignEnd?: boolean;
  'align-end'?: boolean;
  ellipsis?: boolean;
  noWrap?: boolean;
  'no-wrap'?: boolean;
  maxLines?: number;
  'max-lines'?: number;
}>`
  font-size: ${fontSize(16)};

  ${({ weight }) => (weight && TextWeightCss[weight]) || TextWeightCss.regular}

  ${({ variant }) => (variant && TextVariantCss[variant]) || null}

  color: ${({ color, theme }) => theme[`text${upperFirstLetter(color)}`] || theme.text};

  ${({ verticalCenter, ...props }) =>
    (verticalCenter || props['vertical-center']) &&
    css`
      align-items: center;
    `};

  ${({ alignEnd, ...props }) =>
    (alignEnd || props['align-end']) &&
    css`
      width: 100%;
      justify-content: flex-end;
      align-self: flex-end;
      text-align: end;
    `}

  ${({ alignCenter, ...props }) =>
    (alignCenter || props['align-center']) &&
    css`
      text-align: center;
      justify-content: center;
    `};

  ${({ ellipsis }) =>
    ellipsis &&
    css`
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    `}

  ${({ noWrap, ...props }) =>
    (noWrap || props['no-wrap']) &&
    css`
      white-space: nowrap;
    `}

  ${({ maxLines, ...props }) =>
    (maxLines || props['max-lines']) &&
    css`
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      max-height: ${(maxLines || props['max-lines'] || 1) * 1.25}rem;
      -webkit-line-clamp: ${maxLines || props['max-lines']};
      -webkit-box-orient: vertical;
    `}
`;

export const Paragraph = styled.p<{ weight?: TextWeight }>`
  ${({ weight }) => (weight && TextWeightCss[weight]) || TextWeightCss.regular}
  font-size: ${fontSize(16)};
  color: ${({ color, theme }) => (color === 'light' ? theme.lightPrimary : theme.text)};
`;

interface Props extends React.ComponentProps<any> {}

const Component: React.FunctionComponent<Props> = React.forwardRef(
  (
    { number = false, currency = false, percentage = false, variant = '', color = null, weight = undefined, ...props },
    ref
  ) => {
    let textRef = ref as React.MutableRefObject<HTMLInputElement>;

    let additionalProps = {};
    if (number || currency || percentage) {
      additionalProps = {
        as: NumberFormat,
        isNumericString: true,
        suffix: currency ? '€' : percentage ? '%' : '',
        decimalScale: currency ? 2 : percentage ? 1 : 0,
        decimalSeparator: ',',
        thousandSeparator: ' ',
        fixedDecimalScale: true,
        displayType: 'text',
        value: props.skipZero && Number(props.children) === 0 ? '' : props.children,
        ellipsis: 'true',
        'no-wrap': 'true',
        'align-end': 'true',
        weight: 'semiBold',
        onKeyPress: (e: any) => {
          if (e.key === 'Enter') e.preventDefault();
        }
      };
    }
    return <Text ref={textRef} {...additionalProps} {...props} weight={weight} color={color} variant={variant} />;
  }
);

Component.displayName = 'Text';

export default React.memo(Component);

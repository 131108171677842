import { createDeepEqualSelector, selectId } from 'common/selector';
import { getState, selectRelations } from 'common/selector';
import { AccountSelector } from 'features/Accounts';
import { uniq } from 'lodash';

export const getHolidays = state => state.holidays;

export const selectHolidays = createDeepEqualSelector(
  [getHolidays, getState],
  ({ holidaysIds, holidayById }, state) =>
    uniq(holidaysIds)
      .map(id => selectRelations('holiday', holidayById[id], state))
      .filter(Boolean)
);

export const selectHoliday = createDeepEqualSelector(
  [getHolidays, selectId('holiday'), getState],
  ({ holidayById }, holidayId, state) =>
    selectRelations('holiday', holidayById[holidayId], state)
);

export const selectHolidayKinds = createDeepEqualSelector(
  [AccountSelector.selectCurrentAccount],
  currentAccount => currentAccount?.holidayKinds || []
);

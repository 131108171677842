import React, {
  useCallback,
  useMemo,
  Fragment,
  useState,
  useEffect
} from 'react';
import { SelectField } from 'components/v3/Form';
import { SelectInputLayout } from 'components/v3/InputLayout';
import { useRedux } from 'components/v3/utils';
import {
  OrganizationAction,
  OrganizationSelector,
  organizationAsOption
} from 'features/Organizations';
import OrganizationModal from './Modal';
import { jsonApiToObject } from 'common/api';
import { permissions, useAccountConfig } from 'config';

const formatCreateLabel = input => `Créer l'organisation ${input}`;

const organizationOptionsFormatter = response => {
  return (jsonApiToObject(response) || []).map(organizationAsOption);
};

const OrganizationSelect = ({ value, onChange, ...props }) => {
  const { accountId, hasPermission } = useAccountConfig();
  const [organizationModal, setOrganizationModal] = useState();
  const [organizations, fetch] = useRedux(
    OrganizationSelector.selectSearchOrganizations,
    OrganizationAction.getOrganizations,
    {
      fetchOnMount: false,
      useLocalState: false,
      initialParams: {
        page: 1,
        perPage: 25,
        sort: 'display_name',
        useCache: true,
        isSearchRequest: true
      }
    }
  );

  const handleSearch = useCallback(
    ({ query }) =>
      new Promise((resolve, reject) => {
        fetch(params => ({
          ...params,
          displayNameOrReferenceContAny: query,
          page: 1,
          useCache: false
        }))
          .onError(reject)
          .onSuccess(res => resolve(organizationOptionsFormatter(res)));
      }),
    [fetch]
  );

  const handleCreateOption = useCallback(input => {
    setOrganizationModal({
      displayName: input
    });
  }, []);

  const handleEditOption = useCallback(value => {
    setOrganizationModal(value);
  }, []);

  const options = useMemo(() => organizations.map(organizationAsOption), [
    organizations
  ]);

  const currentValue = useMemo(() => organizationAsOption(value), [value]);

  useEffect(() => {
    fetch(params => ({
      ...params,
      displayNameOrReferenceContAny: undefined,
      page: 1,
      useCache: true
    }));
  }, [fetch, accountId]);

  return (
    <Fragment>
      <SelectInputLayout
        label="Organisation"
        value={currentValue}
        options={options}
        isSearchable
        loadAsyncOptions={handleSearch}
        filterOptions={false}
        onChange={onChange}
        inputCreatable={hasPermission([permissions.CRM, 'organization.create'])}
        createLabel="Créer une organisation"
        formatCreateLabel={formatCreateLabel}
        onCreateOption={handleCreateOption}
        optionEditable={hasPermission([permissions.CRM, 'organization.update'])}
        onEditOptionClick={handleEditOption}
        {...props}
      />
      {organizationModal && (
        <OrganizationModal
          organization={organizationModal}
          onRequestClose={() => setOrganizationModal(null)}
          onSubmitSuccess={response => {
            const organization = jsonApiToObject(response);
            if (props.multi) {
              onChange([...currentValue, organization]);
            } else {
              onChange(organization);
            }
          }}
        />
      )}
    </Fragment>
  );
};

export const OrganizationField = props => {
  return <SelectField component={OrganizationSelect} {...props} />;
};

export default OrganizationSelect;

import React from 'react';

const IcExternalLink = ({ size }) => (
  <svg
    viewBox="0 0 20 20"
    fill="currentColor"
    width={`${size || 18}px`}
    height={`${size || 18}px`}
  >
    <path d="M17 17H3V3h5V1H3a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-5h-2z" />
    <path d="M19 1h-8l3.29 3.29-5.73 5.73 1.42 1.42 5.73-5.73L19 9V1z" />
  </svg>
);

export default IcExternalLink;

import { createDeepEqualSelector } from 'common/selector';
import { MeSelector } from 'features/Me';
import { v4 } from 'uuid';
import { getState, selectRelations } from 'common/selector';
import { createSelector } from 'reselect';

export const accountsState = state => state.accounts;

export const selectLoading = createSelector(
  [accountsState],
  ({ isMeLoading, isLoading }) => ({ isMeLoading, isLoading })
);

export const selectCurrentAccount = createSelector(
  [accountsState, MeSelector.getAuthState, getState],
  ({ accountById }, authState, state) => {
    const { currentAccountId } = authState;
    if (!currentAccountId) {
      return null;
    }
    const account = accountById[currentAccountId];
    if (!account) {
      return null;
    }
    return selectRelations('account', account, state);
  }
);

export const selectAccounts = createDeepEqualSelector(
  [accountsState, getState],
  ({ accountsIds, accountById }, state) =>
    accountsIds
      .map(id => selectRelations('account', accountById[id], state))
      .filter(Boolean)
);

export const selectAddress = createDeepEqualSelector(
  [accountsState, MeSelector.getAuthState, getState],
  ({ accountById }, authState, state) => {
    const { currentAccountId } = authState;
    if (!currentAccountId) {
      return null;
    }

    const currentAccount = selectRelations(
      'account',
      accountById[currentAccountId],
      state
    );
    if (!currentAccount) {
      return null;
    }

    const address = currentAccount.address;
    if (address && address.lat && address.lng) {
      return address;
    } else {
      return {
        id: v4(),
        lat: 0,
        lng: 0
      };
    }
  }
);

export const selectFieldGroups = createDeepEqualSelector(
  [selectCurrentAccount],
  currentAccount => {
    if (!currentAccount) {
      return [];
    }
    if (!currentAccount.customFieldGroups) {
      return [];
    }
    return currentAccount.customFieldGroups;
  }
);

export const selectJobs = createDeepEqualSelector(
  [selectCurrentAccount],
  currentAccount => currentAccount?.jobs || []
);

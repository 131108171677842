import React from 'react';
import axios from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';
import { API_BASE } from '../constants';
import store from 'store';
import { AuthApi } from 'features/Auth';
import { List, ListItem } from 'components/v3/ui/list';
import isSameIds from 'utils/idUtils';

export const api = axios.create({ baseURL: API_BASE });

const isFormData = val =>
  typeof FormData !== 'undefined' && val instanceof FormData;

api.interceptors.request.use(config => {
  const accessToken = localStorage.getItem('accessToken');
  // voir si c'est la bonne chose à faire de se taper tout le state à parser
  const state = JSON.parse(localStorage.getItem('state'));

  if (Boolean(accessToken)) {
    config.headers['Authorization'] = `Bearer ${accessToken}`;
  }
  if (
    state &&
    state.ui &&
    state.ui.currentAccountId &&
    !(config.params && config.params.tenant)
  ) {
    config.headers['AccountId'] = state.ui.currentAccountId;
  }

  config.headers['Accept'] = 'application/json';

  if (!isFormData(config.data)) {
    config.data = decamelizeKeys(config.data);
  }

  return config;
});

let isRefreshing = false;
let subscribers = [];

api.interceptors.response.use(
  response => response,
  err => {
    if (!err.response) {
      return Promise.reject(err);
    }
    const {
      config,
      response: { status }
    } = err;
    const originalRequest = config;
    const refreshTokenValue = localStorage.getItem('refreshToken');
    if (!status) {
      // network error
      return Promise.reject(err);
    }

    if (status === 401) {
      if (originalRequest.url.indexOf('oauth/token') !== -1) {
        return Promise.reject(err);
      }
      if (!refreshTokenValue || refreshTokenValue === 'undefined') {
        store.dispatch({
          type: 'LOGOUT'
        });
        return Promise.reject(err);
      }
      if (!isRefreshing) {
        isRefreshing = true;
        AuthApi.refreshToken({
          refreshToken: `${refreshTokenValue}`
        }).then(
          ({ accessToken, refreshToken }) => {
            isRefreshing = false;
            localStorage.setItem('accessToken', accessToken);
            localStorage.setItem('refreshToken', refreshToken);
            onRefreshed(accessToken);
            subscribers = [];
          },
          error => {
            if (!error.response || !error.response.status) {
              // network error
              return Promise.reject(err);
            }
            store.dispatch({
              type: 'LOGOUT'
            });
            subscribers = [];
            return Promise.reject(err);
          }
        );
      }
      const requestSubscribers = new Promise(resolve => {
        subscribeTokenRefresh(accessToken => {
          if (subscribers)
            originalRequest.headers.Authorization = `Bearer ${accessToken}`;
          resolve(axios(originalRequest));
        });
      });
      return requestSubscribers;
    }
    return Promise.reject(err);
  }
);

function subscribeTokenRefresh(cb) {
  subscribers.push(cb);
}

function onRefreshed(accessToken) {
  subscribers.map(cb => cb(accessToken));
}

const formatResponse = (response, config) => {
  if (!Boolean(response)) {
    return response;
  }
  var data = response.data;

  if (config && config.perPage && config.page) {
    let total = null;
    if (response.headers['total']) {
      total = response.headers['total'];
    } else if (data.meta && data.meta.pagination) {
      total = data.meta.pagination.total;
    }

    if (total) {
      const { page, perPage, totalPages, ...params } = config;
      data = {
        ...data,
        pagination: {
          page,
          perPage,
          total: Number(total),
          params
        }
      };
    }
  }
  if (config && config.skipCamelize) {
    return data;
  }
  return camelizeKeys(data);
};

const formatConfig = ({ cancelToken, ...parameters } = {}) => {
  const params = parameters
    ? parameters.params
      ? parameters.params
      : parameters
    : {};
  if (cancelToken) {
    return {
      cancelToken,
      params: decamelizeKeys(params)
    };
  }
  return { params: decamelizeKeys(params) };
};

export const get = (uri, config = {}) =>
  api
    .get(uri, formatConfig(config))
    .then(response => formatResponse(response, config));

export const post = (uri, payload = {}, config) =>
  api
    .post(uri, payload, formatConfig(config))
    .then(response => formatResponse(response, config));

export const put = (uri, payload = {}, config) => {
  return api
    .put(uri, payload, formatConfig(config))
    .then(response => formatResponse(response, config));
};

export const destroy = (uri, payload = {}, config = {}) =>
  api.delete(uri, payload, formatConfig(config)).then(formatResponse);

export const getErrorMessage = (
  resp,
  fallback = 'Une erreur est survenue, veuillez contacter un administrateur.'
) => {
  const error = resp && ((resp.error && resp.error.response) || resp.response);
  if (!error) {
    return (
      fallback ||
      'Une erreur est survenue, veuillez contacter un administrateur.'
    );
  }
  let message = '';
  const { data, status } = error;
  if (status === 422) {
    message = data && data.error && data.error.message;
    if (Array.isArray(message)) {
      message = (
        <List>
          {message.map(m => (
            <ListItem key={m}>{m}</ListItem>
          ))}
        </List>
      );
    }
  } else if (status === 400 && data.error === 'invalid_grant') {
    message = 'La combinaison email / mot de passe est invalide';
  }
  return message || fallback;
};

export const jsonApiToObject = response => {
  if (!response) {
    return null;
  }
  const { data, included } = response;
  if (!data) {
    return null;
  }
  if (Array.isArray(response.data)) {
    return response.data.map(d => jsonApiToObject({ data: d, included }));
  } else {
    const { id, attributes, relationships } = response.data;
    if (relationships && included) {
      const object = Object.keys(relationships).reduce(
        (item, key) => {
          const relation = relationships[key];
          if (Array.isArray(relation.data)) {
            item[key] = relation.data
              .map(({ id, type }) =>
                included.find(i => isSameIds(i.id, id) && i.type === type)
              )
              .filter(Boolean)
              .map(({ id, attributes }) => ({
                ...attributes,
                id
              }));
          } else {
            const relationAttrs = relation.data
              ? included.find(
                  i =>
                    isSameIds(i.id, relation.data.id) &&
                    i.type === relation.data.type
                )
              : null;
            item[key] = relationAttrs
              ? {
                  ...relationAttrs.attributes,
                  id: relationAttrs.id
                }
              : null;
          }
          return item;
        },
        {
          ...attributes,
          id
        }
      );
      return object;
    }
    return {
      ...attributes,
      id
    };
  }
};

import { mergeJsonApi } from 'common/reducers';
import uniq from 'lodash/uniq';

const initialDeviationState = {
  deviationById: {},
  projectsIds: []
};

const deviations = (state = initialDeviationState, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'PROJECTS_DEVIATIONS_SUCCESS':
      return {
        ...state,
        ...mergeJsonApi('deviation', state, action),
        projectsIds: uniq([
          ...state.projectsIds,
          ...payload.data.map(d => d.id)
        ])
      };
    case 'LOGOUT':
    case 'SELECT_ACCOUNT':
      return initialDeviationState;
    default:
      return mergeJsonApi('deviation', state, action);
  }
};

const initialSignState = {
  signById: {},
  signsIds: []
};

const signs = (state = initialSignState, action) => {
  const { type } = action;
  switch (type) {
    case 'LOGOUT':
    case 'SELECT_ACCOUNT':
      return initialSignState;
    default:
      return mergeJsonApi('sign', state, action);
  }
};

export { deviations, signs };

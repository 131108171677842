import moment from 'moment';
import { features } from 'config';
import Project from './Timeline/Project';
import Machine from './Timeline/Machine';
import Team from './Timeline/Team';
import Member from './Timeline/Member';
import Calendar from './Calendar';

export { Project, Machine, Team, Member, Calendar };

export const PHASE_COLOR_OPTIONS = [
  {
    label: "Couleur de l'équipe",
    value: 'team.color'
  },
  {
    label: "Statut de l'intervention",
    value: 'state.color'
  },
  {
    label: 'Statut du projet',
    value: 'project.state.color'
  },
  {
    label: "Couleur d'activité de l'intervention",
    value: 'activity.color'
  },
  { label: 'Couleur du groupe', value: 'project.group.color' }
];

export const MODES = [
  {
    label: 'Planning',
    name: 'timeline',
    features: [features.PLANNING_PROJECTS],
    option: () => ({
      value: features.PLANNING_PROJECTS,
      pathname: '/plannings/projets',
      Component: Project
    })
  },
  {
    label: 'Plan de charge',
    name: 'scheduler',
    features: [
      features.PLANNING_TEAMS,
      features.PLANNING_MEMBERS,
      features.PLANNING_MACHINES
    ],
    option: featureName => {
      switch (featureName) {
        case features.PLANNING_MACHINES:
          return {
            mode: 'scheduler',
            resource: 'machine',
            label: 'Ajouter au planning machine',
            value: featureName,
            pathname: '/plannings/machines',
            Component: Machine
          };
        case features.PLANNING_TEAMS:
          return {
            mode: 'scheduler',
            resource: 'team',
            label: 'Ajouter au planning équipe',
            value: featureName,
            pathname: '/plannings/equipes',
            Component: Team
          };
        default:
          return {
            mode: 'scheduler',
            resource: 'user',
            label: 'Ajouter au planning technicien',
            value: featureName,
            pathname: '/plannings/techniciens',
            Component: Member
          };
      }
    }
  },
  {
    label: 'Calendrier',
    name: 'calendar',
    features: [features.PLANNING_CALENDAR],
    option: () => ({
      mode: 'calendar',
      label: 'Ajouter au calendrier',
      value: features.PLANNING_CALENDAR,
      pathname: '/plannings/calendrier',
      Component: Calendar
    })
  }
];

export const VIEWS = {
  week: {
    value: 'week',
    name: 'week',
    position: 0,
    label: 'Semaine',
    type: 'resourceTimeline',
    duration: {
      weeks: 1
    },
    dateIncrement: {
      weeks: 1
    },
    dateDecrement: {
      weeks: -1
    },
    slotLabelFormat: [
      info => moment(info.date).format('[semaine] WW — MMMM — YYYY'),
      { weekday: 'short', day: 'numeric' },
      info => (info.date.hour === 0 ? 'mat.' : 'a-m')
    ],
    slotWidth: 60,
    slotDuration: '12:00:00',
    slotLabelInterval: '12:00:00',
    noWeekendBackground: false,
    dateAlignment: 'week'
  },
  twoWeeks: {
    value: 'twoWeeks',
    name: 'twoWeeks',
    position: 1,
    label: '2 Semaines',
    type: 'resourceTimeline',
    duration: {
      weeks: 2
    },
    dateIncrement: {
      weeks: 1
    },
    dateDecrement: {
      weeks: -1
    },
    slotLabelFormat: [
      info => moment(info.date).format('[semaine] WW — MMMM — YYYY'),
      { weekday: 'short', day: 'numeric' },
      info => (info.date.hour === 0 ? 'mat.' : 'a-m')
    ],
    slotWidth: 60,
    slotDuration: '12:00:00',
    slotLabelInterval: '12:00:00',
    dateAlignment: 'week',
    noWeekendBackground: false
  },
  month: {
    value: 'month',
    name: 'month',
    position: 2,
    label: 'Mois',
    type: 'resourceTimeline',
    duration: {
      weeks: 4
    },
    dateIncrement: {
      weeks: 2
    },
    dateDecrement: {
      weeks: -2
    },
    slotLabelFormat: [
      { month: 'long', year: 'numeric' },
      { week: 'short' },
      { weekday: 'narrow', day: 'numeric' }
    ],
    slotWidth: 42,
    slotDuration: '12:00:00',
    slotLabelInterval: '24:00:00',
    dateAlignment: 'day',
    noWeekendBackground: false
  },
  trimester: {
    value: 'trimester',
    name: 'trimester',
    position: 3,
    label: 'Trimestre',
    type: 'resourceTimeline',
    duration: {
      months: 3
    },
    dateIncrement: {
      months: 1
    },
    dateDecrement: {
      months: -1
    },
    slotLabelFormat: [
      { month: 'long', year: 'numeric' },
      { week: 'short' },
      { day: 'numeric' }
    ],
    slotWidth: 30,
    slotDuration: '12:00:00',
    slotLabelInterval: '24:00:00',
    noWeekendBackground: false,
    dateAlignment: 'day'
  },
  semester: {
    value: 'semester',
    name: 'semester',
    position: 4,
    label: 'Semestre',
    type: 'resourceTimeline',
    duration: {
      months: 6
    },
    dateIncrement: {
      months: 3
    },
    dateDecrement: {
      months: -3
    },
    slotLabelFormat: [
      { year: 'numeric' },
      { month: 'long' },
      { week: 'short' }
    ],
    slotWidth: 20,
    slotDuration: '24:00:00',
    slotLabelInterval: `${24 * 7}:00:00`,
    noWeekendBackground: false,
    dateAlignment: 'week'
  },
  year: {
    value: 'year',
    name: 'year',
    position: 5,
    label: 'Année',
    type: 'resourceTimeline',
    duration: {
      years: 1
    },
    dateIncrement: {
      months: 6
    },
    dateDecrement: {
      months: -6
    },
    slotLabelFormat: [
      { year: 'numeric' },
      { month: 'short' },
      { week: 'numeric' }
    ],
    slotWidth: 40,
    slotDuration: `${24 * 7}:00:00`,
    slotLabelInterval: `${24 * 7}:00:00`,
    noWeekendBackground: true,
    dateAlignment: 'week'
  }
};

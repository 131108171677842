import {
  CarrierApi,
  CarrierAction,
  CarrierReducer,
  CarrierSelector
} from './data';
import CarrierSelect from './components/v3/Select';
import CarriersOrders from './components/v3/CarriersOrders';

import { printDate } from 'utils/dates';

export {
  CarrierApi,
  CarrierAction,
  CarrierReducer,
  CarrierSelector,
  CarrierSelect,
  CarriersOrders
};

export const carrierAsOption = carrier => {
  if (Array.isArray(carrier)) {
    return carrier.map(carrierAsOption);
  } else if (carrier) {
    return {
      value: carrier.id,
      label: carrier.displayName,
      original: carrier
    };
  } else {
    return null;
  }
};

export const carriageTypeAsOption = carriageType => {
  if (Array.isArray(carriageType)) {
    return carriageType.map(carriageTypeAsOption);
  } else if (carriageType) {
    return {
      value: carriageType.id,
      label: carriageType.displayName,
      original: carriageType
    };
  } else {
    return null;
  }
};

export const carriageTypeAsValues = carriageType => ({
  id: carriageType?.id || null,
  displayName: carriageType?.displayName || ''
});

export const carrierAsValues = carrier => ({
  id: carrier?.id || null,
  displayName: carrier?.displayName || '',
  kind: carrier?.kind || ''
});

export const carrierOrderExportHistoryAsOption = history => {
  if (Array.isArray(history)) {
    return history.map(carrierOrderExportHistoryAsOption);
  } else if (history) {
    return {
      value: history.id,
      label: `Version ${history.version} du ${printDate(
        history.createdAt,
        "dd/MM/yyyy 'à' HH:mm"
      )}`,
      subtitle: history.user?.displayName,
      original: history
    };
  } else {
    return null;
  }
};

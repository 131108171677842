import { omit } from 'lodash';
import { mergeJsonApi } from 'common/reducers';
import isSameIds from 'utils/idUtils';

const initialState = {
  exceptionalPeriodById: {},
  exceptionalPeriodsIds: []
};

const periods = (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case 'NEW_PERIOD_SUCCESS':
      return {
        ...mergeJsonApi('exceptionalPeriod', state, action),
        exceptionalPeriodsIds: [payload.data.id, ...state.exceptionalPeriodsIds]
      };
    case 'DELETE_PERIOD_SUCCESS':
      return {
        ...state,
        exceptionalPeriodById: omit(state.exceptionalPeriodById, payload),
        exceptionalPeriodsIds: state.exceptionalPeriodsIds.filter(
          id => !isSameIds(id, payload)
        )
      };
    case 'LOGOUT':
    case 'SELECT_ACCOUNT':
      return initialState;
    default:
      return mergeJsonApi('exceptionalPeriod', state, action);
  }
};

export default periods;

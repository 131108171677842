import { differenceInSeconds } from 'date-fns';
import omitBy from 'lodash/omitBy';
import isUndefined from 'lodash/isUndefined';
import { getPlural } from 'common';
import { decamelize } from 'humps';

export const shouldFetch = (
  type,
  payload,
  state,
  successAction,
  delayInSeconds = 60
) => {
  if (true) return true;
  const plural = getPlural(type);
  const action =
    successAction || `${decamelize(getPlural(type)).toUpperCase()}_SUCCESS`;
  const typeState = state[plural];
  if (
    !typeState ||
    (typeState?.isLoading &&
      (!successAction || !successAction.includes('SEARCH_')))
  ) {
    return false;
  }
  const fetchAt = typeState[action]?.fetchAt;
  if (!fetchAt) {
    return true;
  } else {
    return (
      differenceInSeconds(new Date(), fetchAt) >= delayInSeconds
      //  ||  !isEqual(JSON.stringify(payload), typeState.fetchPayload)
    );
  }
};

export const prepareAttributes = (payload, ...keys) => {
  let updated = payload ? { ...payload } : {};
  keys.forEach(key => {
    updated[`${key}Attributes`] = prepareNewAttribute(updated[key]);
    updated[key] = undefined;
  });
  return omitBy(updated, isUndefined);
};

export const prepareNewAttributes = (payload, ...key) => {
  if (!payload) {
    return payload;
  }
  let p = payload;
  key.forEach(k => {
    p = {
      ...p,
      [k]: prepareNewAttribute(payload[k])
    };
  });
  return p;
};

const prepareNewAttribute = attribute => {
  if (!attribute) {
    return attribute;
  }
  if (Array.isArray(attribute)) {
    return attribute.map(prepareNewAttribute);
  } else {
    const { isNew, id, ...attr } = attribute;
    if (isNew) {
      return attr;
    } else {
      return {
        id,
        ...attr
      };
    }
  }
};

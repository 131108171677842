import React, { Fragment, useCallback, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { SectionTitle as RawSectionTitle } from 'components/v3/ui';
import DeliveryTable from 'features/Phases/components/v3/DeliveryTable';
import { useAction, useDelay } from 'components/v3/utils';
import { spacing } from 'ui';
import { CarrierAction } from '../../data';

const SectionTitle = styled(RawSectionTitle)`
  margin-top: ${spacing()};
  margin-left: ${spacing(0.5)};
  margin-bottom: ${spacing(0.5)};
`;

const CarrierOrderTable = ({ title, carrierOrders, date }) => {
  const action = useAction();
  const dispatch = useDispatch();
  const delay = useDelay();

  const [data, setData] = useState({
    carrierOrders,
    edited: false
  });

  const handleUpdateCarrierOrder = useCallback(
    (carrierOrders, carrierOrder) => {
      setData({
        carrierOrders,
        edited: true
      });
      delay(
        () =>
          action(
            () =>
              dispatch(
                CarrierAction.updateCarrierOrder({
                  id: carrierOrder.id,
                  payload: carrierOrder
                })
              ),
            `${carrierOrder.id}`
          ),
        500,
        `${carrierOrder.id}`
      );
    },
    [action, delay, dispatch]
  );

  useEffect(() => {
    setData(prev => {
      if (prev.edited) {
        return prev;
      } else {
        return {
          carrierOrders: carrierOrders,
          edited: false
        };
      }
    });
  }, [carrierOrders]);

  const { carrierOrders: localCarrierOrders } = data;

  return (
    <Fragment key={title}>
      <SectionTitle>{title}</SectionTitle>
      <DeliveryTable
        startAt={date}
        carrierOrders={localCarrierOrders}
        globalView
        maxHeight="unset"
        onChange={handleUpdateCarrierOrder}
        isDeletable={false}
      />
    </Fragment>
  );
};

CarrierOrderTable.displayName = CarrierOrderTable;

export default React.memo(CarrierOrderTable);

import React, { useState } from 'react';
import { MdAdd } from 'react-icons/md';
import Box, { BoxHeader } from 'components/v3/Box';
import { Button } from 'components/v3/ui';
import VoucherVersionList from './VoucherVersionList';
import VoucherVersionModal from './VoucherVersionModal';
import { permissions, useAccountConfig } from 'config';

const VoucherVersionsBox = ({
  voucherableId,
  voucherableType,
  voucher,
  creatable,
  marged,
  padded,
  flat,
  withoutTitle,
  ...props
}) => {
  const { hasPermission } = useAccountConfig();
  const [createVersionOpened, setCreateVersionOpened] = useState(false);
  return (
    <Box marged={marged} padded={padded} flat={flat}>
      <BoxHeader
        bigPadded
        title={
          withoutTitle
            ? undefined
            : `${voucher?.voucherKind?.name}\u00a0: versions`
        }
      >
        {hasPermission(permissions.COMPANY_DATA) && creatable && (
          <Button
            small
            icon={MdAdd}
            onClick={() => setCreateVersionOpened(true)}
          >
            Ajouter une version
          </Button>
        )}
      </BoxHeader>
      <VoucherVersionList
        rowColor="light"
        items={voucher.voucherVersions}
        headerVariant="transparent"
        maxHeight={500}
        minRows={1}
        {...props}
      />
      {createVersionOpened && (
        <VoucherVersionModal
          voucher={voucher}
          voucherableId={voucherableId}
          voucherableType={voucherableType}
          onRequestClose={() => setCreateVersionOpened(false)}
        />
      )}
    </Box>
  );
};

export default VoucherVersionsBox;

import React, { useCallback, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import * as Yup from 'yup';
import { EntityModal } from 'components/v3/Modal';
import { TextInputField, ColorField, ERRORS } from 'components/v3/Form';
import { Grid, GridItem } from 'components/v3/ui';
import { TeamSelector, TeamAction, teamAsValues } from 'features/Teams';
import { stringToColor } from 'utils';
import { UserField } from 'features/Users/components/v3/UserSelect';
import { features, Permission, permissions, useAccountConfig } from 'config';

const validationSchema = Yup.object().shape({
  displayName: Yup.string().required(ERRORS.REQUIRED)
});

const valuesDependencies = {
  color: {
    modifier: (values, form) => {
      if (!form.status?.color) {
        return stringToColor(values.displayName);
      } else {
        return values.color;
      }
    },
    triggers: ['displayName']
  }
};

const TeamModal = ({
  onRequestClose,
  onSubmitSuccess,
  team: propsTeam,
  ...props
}) => {
  const { hasPermission } = useAccountConfig();
  const dispatch = useDispatch();
  const { params } = useRouteMatch();
  let { teamId } = params;
  if (propsTeam) {
    teamId = propsTeam.id;
  }
  const isNew = !Boolean(teamId);

  const selectTeam = useMemo(
    () => state => TeamSelector.selectTeam(state, { teamId }),
    [teamId]
  );

  let team = useSelector(selectTeam, isEqual);
  if (!team) {
    team = propsTeam;
  }

  const createRequest = useCallback(
    payload => dispatch(TeamAction.createTeam({ payload })),
    [dispatch]
  );

  const fetchRequest = useCallback(
    () => dispatch(TeamAction.getTeam({ id: teamId })),
    [teamId, dispatch]
  );

  const updateRequest = useCallback(
    (id, payload) => dispatch(TeamAction.updateTeam({ id, payload })),
    [dispatch]
  );

  const deleteRequest = useCallback(
    id => dispatch(TeamAction.deleteTeam({ id })),
    [dispatch]
  );

  const canEdit = hasPermission([
    permissions.RESOURCES_CONFIG,
    isNew ? 'team.create' : 'team.update'
  ]);
  const canDelete = hasPermission([
    permissions.RESOURCES_CONFIG,
    'team.delete'
  ]);

  return (
    <EntityModal
      isOpen
      size="small"
      onRequestClose={onRequestClose}
      title={isNew ? 'Nouvelle équipe' : "Édition de l'équipe"}
      id={teamId}
      model="team"
      entity={team}
      initialStatus={{
        color: Boolean(team?.id)
      }}
      validationSchema={validationSchema}
      entityAsValue={teamAsValues}
      fetchRequest={fetchRequest}
      createRequest={createRequest}
      updateRequest={updateRequest}
      deleteRequest={canDelete ? deleteRequest : undefined}
      onSubmitSuccess={onSubmitSuccess}
      autoSubmit={false}
      valuesDependencies={valuesDependencies}
      readOnly={!canEdit}
      {...props}
    >
      {({ setStatus, status }) => (
        <Grid spacing={16} padded={false}>
          <GridItem width="100%">
            <TextInputField
              name="displayName"
              label="Nom de l'équipe"
              required
              readOnly={!canEdit}
              iconLeading={
                <ColorField
                  name="color"
                  onColorSelected={color => setStatus({ ...status, color })}
                />
              }
              leadingIconClickable
            />
          </GridItem>
          <GridItem width="100%">
            <TextInputField
              name="phone"
              readOnly={!canEdit}
              label="Numéro de téléphone"
            />
          </GridItem>
          <Permission
            feature={[features.PLANNING_MEMBERS, features.PLANNING_CALENDAR]}
          >
            <GridItem width="100%">
              <UserField
                name="users"
                readOnly={!canEdit}
                label="Membre associés"
                multi
              />
            </GridItem>
          </Permission>
        </Grid>
      )}
    </EntityModal>
  );
};

export default TeamModal;

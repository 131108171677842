import { PeriodApi, PeriodAction, PeriodReducer, PeriodSelector } from './data';

export { PeriodApi, PeriodAction, PeriodReducer, PeriodSelector };

export const periodAsValues = period => ({
  id: period?.id || null,
  description: period?.description || '',
  color: period?.color || '',
  startAt: period?.startAt || '',
  endAt: period?.endAt || ''
});

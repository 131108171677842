import React from 'react';
import styled from 'styled-components';
import Avatar from 'components/v3/Avatar';
import { Text } from 'components/v3/ui';

const Wrapper = styled.span`
  position: relative;
  display: flex;
  align-items: center;
`;

const Content = styled.span`
  display: flex;
  flex-direction: column;
  margin-left: 12px;
  > *:not(:first-child):not(:only-child) {
    margin-top: 4px;
  }
`;

const Author = ({
  user,
  subtitle,
  avatarHidden,
  avatarTooltipHidden,
  size = 'medium'
}) => {
  if (!user) {
    return null;
  }
  const { displayName, displayRole, displayTopRole } = user;
  return (
    <Wrapper color={user.settings?.color}>
      {!avatarHidden && (
        <Avatar
          size={size}
          user={user}
          borderHidden
          tooltip={!avatarTooltipHidden}
        />
      )}
      <Content>
        <Text variant={size === 'small' ? 'small' : undefined}>
          {displayName}
        </Text>
        <Text variant="caption" color="caption">
          {subtitle || displayRole || displayTopRole}
        </Text>
      </Content>
    </Wrapper>
  );
};

export default Author;

export {
  ActivityApi,
  ActivityAction,
  ActivityReducer,
  ActivitySelector
} from './data';

export const activityAsOption = activity => {
  if (Array.isArray(activity)) {
    return activity.map(activityAsOption);
  } else if (activity) {
    return {
      value: activity.id,
      label: activity.displayName,
      color: activity.color,
      original: activity
    };
  } else {
    return null;
  }
};

export const activityAsValues = activity => {
  return {
    id: activity?.id || null,
    displayName: activity?.displayName || '',
    administrative: Boolean(activity?.administrative),
    color: activity?.color || '',
    colorInitialized: Boolean(activity.color)
  };
};

import { get, post, put, destroy } from 'common/api';

export const sendInvitation = (projectId, payload) =>
  post(`/v5/projects/${projectId}/send_invitation`, { receiver: payload });

export const getTimelineProjects = ({ body, ...config }) => post('/v5/plannings/projects', body, config);

export const getProjects = (params) => get('/v5/projects', params);

export const getProject = (id) => get(`/v5/projects/${id}`);

export const putProject = (projectId, project) => put(`/v5/projects/${projectId}`, { project });

export const createProject = (project) => post('/v5/projects', { project });

export const acceptProject = (id, payload) => post(`/v5/projects/${id}/accept`, payload);

export const billProject = (id, payload) => post(`/v5/projects/${id}/bill`, payload);

export const finishProject = (id, payload) => post(`/v5/projects/${id}/finish`, payload);

export const planProject = (id, payload) => post(`/v5/projects/${id}/plan`, payload);

export const proposeProject = (id, payload) => post(`/v5/projects/${id}/propose`, payload);

export const validateProject = (id, payload) => post(`/v5/projects/${id}/validate`, payload);

export const deleteProject = (id, config) => destroy(`/v5/projects/${id}`, config);

export const getProjectCsvUrl = (params) => get(`/v5/projects/csv_url`, params);

import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { MdMoreVert } from 'react-icons/md';
import { differenceInHours } from 'date-fns';
import isEqual from 'lodash/isEqual';
import * as Yup from 'yup';
import {
  ERRORS,
  EntityForm,
  DatePickerField,
  TextInputField
} from 'components/v3/Form';
import Menu, { MenuItem } from 'components/v3/Menu';
import CalendarPrompt, { PromptHeader } from 'components/v3/CalendarPrompt';
import { Grid, GridItem, ButtonIcon, Text } from 'components/v3/ui';
import { CardFooter, CardContent, CardButtons } from 'components/v3/ui/card';
import {
  HolidaySelector,
  HolidayAction,
  holidayAsValues
} from 'features/Holidays';
import { UserField } from 'features/Users/components/v3/UserSelect';
import { permissions, useAccountConfig } from 'config';
import { parseDate } from 'utils/dates';
import HolidayKindSelect from './HolidayKindSelect';

const validationSchema = Yup.object().shape({
  // users: Yup.object().nullable().required(ERRORS.REQUIRED),
  kind: Yup.object().nullable().required(ERRORS.REQUIRED)
});

const HolidayPrompt = ({
  onRequestClose,
  onSubmitSuccess,
  holiday: propsHoliday,
  showUserSelect,
  ...props
}) => {
  const { translate, hasPermission } = useAccountConfig();
  const [isConfirmDelete, setConfirmDelete] = useState(false);

  const dispatch = useDispatch();
  const { params } = useRouteMatch();
  let { holidayId } = params;
  if (propsHoliday) {
    holidayId = propsHoliday.id;
  }
  const isNew = !Boolean(holidayId);

  const selectHoliday = useMemo(
    () => state => HolidaySelector.selectHoliday(state, { holidayId }),
    [holidayId]
  );

  let holiday = useSelector(selectHoliday, isEqual);
  if (!holiday) {
    holiday = propsHoliday;
  }

  const createRequest = useCallback(
    payload => dispatch(HolidayAction.createHoliday({ payload })),
    [dispatch]
  );

  const fetchRequest = useCallback(
    () => dispatch(HolidayAction.getHoliday({ id: holidayId })),
    [holidayId, dispatch]
  );

  const updateRequest = useCallback(
    (id, payload) => dispatch(HolidayAction.updateHoliday({ id, payload })),
    [dispatch]
  );

  const deleteRequest = useCallback(
    id => dispatch(HolidayAction.deleteHoliday({ id })),
    [dispatch]
  );

  const canEdit = hasPermission(permissions.HOLIDAYS);

  return (
    <CalendarPrompt
      maxWidth={532}
      size="small"
      onRequestClose={onRequestClose}
      {...props}
    >
      <EntityForm
        isNew={isNew}
        id={holidayId}
        model="holiday"
        entity={holiday}
        validationSchema={validationSchema}
        entityAsValue={holidayAsValues}
        fetchRequest={fetchRequest}
        createRequest={createRequest}
        updateRequest={updateRequest}
        deleteRequest={deleteRequest}
        onSubmitSuccess={onSubmitSuccess || onRequestClose}
        showConfirmDelete={isConfirmDelete}
        onDeleteConfirmed={setConfirmDelete}
        onDeleteSuccess={onRequestClose}
        autoSubmit={false}
        readOnly={!canEdit}
      >
        {({ values, setFieldValue, isSubmitting, isValid }) => {
          const days =
            Math.round(
              (differenceInHours(
                parseDate(values.endAt),
                parseDate(values.startAt)
              ) /
                24) *
                10
            ) / 10;
          return (
            <Fragment>
              <PromptHeader
                title="Congés"
                subtitle={
                  showUserSelect ? undefined : values.users?.[0]?.displayName
                }
              />
              <CardContent>
                <Grid spacing={16} padded={false}>
                  {showUserSelect && (
                    <GridItem width="100%">
                      <UserField
                        name="users"
                        multi
                        readOnly={!canEdit}
                        label={translate({
                          value: 'phase.user',
                          fallback: 'Technicien'
                        })}
                        isClearable={false}
                      />
                    </GridItem>
                  )}
                  <GridItem width="100%">
                    <HolidayKindSelect
                      label="Type de congé"
                      value={values.kind}
                      readOnly={!canEdit}
                      onChange={kind => setFieldValue('kind', kind)}
                    />
                    {/* <SelectField
                      label="Type de congé"
                      name="kind"
                      options={KINDS}
                      mapOptionValue
                      isClearable={false}
                    /> */}
                  </GridItem>
                  <GridItem width="50%" minWidth={250}>
                    <DatePickerField
                      label="Date de début"
                      name="startAt"
                      disableTime={false}
                      hourIncrement={12}
                      readOnly={!canEdit}
                      isClearable={false}
                      minuteIncrement={12 * 60}
                    />
                  </GridItem>
                  <GridItem width="50%" minWidth={250}>
                    <DatePickerField
                      label="Date de fin"
                      name="endAt"
                      disableTime={false}
                      isClearable={false}
                      readOnly={!canEdit}
                      hourIncrement={12}
                      minuteIncrement={12 * 60}
                      isEndDate
                    />
                  </GridItem>
                  <GridItem width="100%">
                    <Text color="secondary">Soit&nbsp;:&nbsp;</Text>
                    <Text weight="semiBold" color="theme">
                      {`${days} ${days < 2 ? 'jour' : 'jours'}`}
                    </Text>
                  </GridItem>
                  <GridItem width="100%">
                    <TextInputField
                      textArea
                      readOnly={!canEdit}
                      name="description"
                      label="Informations supplémentaires"
                      minRows={2}
                    />
                  </GridItem>
                </Grid>
              </CardContent>
              <CardFooter>
                <CardButtons
                  positiveText="Enregistrer"
                  negativeText="Annuler"
                  positiveDisabled={!isValid || !canEdit}
                  buttonNameLoading={isSubmitting ? 'positive' : null}
                  contextMenu={
                    holidayId && (
                      <Menu minWidth={150} maxWidth={300}>
                        <ButtonIcon size="medium">
                          <MdMoreVert />
                        </ButtonIcon>
                        <MenuItem onClick={() => setConfirmDelete(true)}>
                          Supprimer
                        </MenuItem>
                      </Menu>
                    )
                  }
                  onButtonClick={({ name }) => {
                    switch (name) {
                      case 'positive':
                        // form.submitForm();
                        break;
                      default:
                        onRequestClose();
                    }
                  }}
                />
              </CardFooter>
            </Fragment>
          );
        }}
      </EntityForm>
    </CalendarPrompt>
  );
};

export default HolidayPrompt;

import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useImperativeHandle,
  useMemo
} from 'react';
import styled, { css } from 'styled-components';
import TextareaAutosize from 'react-textarea-autosize';
import NumberFormat from 'react-number-format';
import { fontSize } from 'ui';
import { useDelay } from '../utils';

export const inputFontSize = 16;

const inputStyle = css`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  background-color: transparent;
  background: none;
  outline: none;
  color: ${({ theme }) => theme.text};
  font-weight: 400;
  font-size: ${fontSize(inputFontSize)};
  border: none;
  box-shadow: none;

  &:read-only {
    cursor: default;
  }

  ${({ disableEvents }) =>
    disableEvents &&
    css`
      pointer-events: none;
    `}

  &:disabled {
    cursor: not-allowed;
  }

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    color: inherit !important;
    border-radius: inherit;
  }
`;

export const Input = styled.input`
  ${inputStyle}
`;

export const InputArea = styled(TextareaAutosize)`
  ${inputStyle}
  resize: none;
  overflow: hidden;
`;

const onKeyPress = e => {
  if (e.key === 'Enter') e.stopPropagation();
};

const _DebouncedInput = React.forwardRef(
  (
    {
      value,
      onChange,
      onChangeDebounce,
      debounced,
      debounceDelay = 500,
      textArea,
      number = false,
      currency = false,
      percentage = false,
      lowercase,
      textAlign,
      ...props
    },
    ref
  ) => {
    const useNumberFormat = number || currency || percentage;
    const lowercased = lowercase || props.name === 'email';
    const inputRef = useRef();
    const [inputValue, setInputValue] = useState(null);
    const delay = useDelay();
    const onChangeDebounceRef = useRef();
    onChangeDebounceRef.current = onChangeDebounce;

    const runDebounce = useCallback(
      v => {
        delay(
          () => onChangeDebounceRef && onChangeDebounceRef.current(v),
          debounceDelay
        );
      },
      [delay, debounceDelay]
    );

    const handleChange = useCallback(
      e => {
        let value = e.target.value;
        if (lowercased) {
          value = value.toLowerCase();
        }
        if (debounced) {
          setInputValue(value);
          runDebounce(value);
        }
        if (onChange) {
          onChange(value, e);
        }
      },
      [runDebounce, debounced, onChange, lowercased]
    );

    const handleNumberChange = useCallback(
      value => {
        if (debounced) {
          const number = value.floatValue;
          setInputValue(number);
          runDebounce(number);
        }
        if (onChange) {
          onChange(value.floatValue);
        }
      },
      [debounced, onChange, runDebounce]
    );

    const reset = useCallback(() => {
      delay.cancel();
      setInputValue(null);
    }, [delay]);

    useImperativeHandle(
      ref,
      () => ({
        reset: () => reset(),
        focus: () => inputRef.current?.focus(),
        blur: () => inputRef.current?.blur(),
        value: () => inputValue || inputRef.current.value,
        view: inputRef.current
      }),
      [inputValue, reset]
    );

    useEffect(() => {
      return () => {
        delay.cancel();
      };
    }, [delay]);

    let InputComponent;
    let additionalProps = {};
    if (useNumberFormat) {
      InputComponent = NumberFormat;
      additionalProps = {
        customInput: Input,
        isNumericString: true,
        suffix: currency ? '€' : percentage ? '%' : '',
        decimalScale:
          props.decimalScale == null
            ? percentage
              ? 1
              : 2
            : props.decimalScale,
        decimalSeparator: ',',
        thousandSeparator: ' ',
        fixedDecimalScale: props.fixedDecimalScale ?? true,
        displayType: 'input',
        onValueChange: handleNumberChange,
        onKeyPress,
        getInputRef: inputRef,
        onBlur: undefined
      };
    } else if (textArea) {
      additionalProps = {
        inputRef,
        onKeyPress,
        onChange: handleChange
      };
      InputComponent = InputArea;
    } else {
      InputComponent = Input;
      additionalProps = {
        ref: inputRef,
        onChange: handleChange
      };
    }

    const style = useMemo(() => {
      if (textAlign) {
        return {
          ...(props.style || {}),
          textAlign
        };
      } else {
        return props.style;
      }
    }, [props.style, textAlign]);

    return (
      <InputComponent
        value={inputValue == null ? (value == null ? '' : value) : inputValue}
        {...props}
        {...additionalProps}
        style={style}
      />
    );
  }
);

export const DebouncedInput = React.memo(_DebouncedInput);

import React, { Fragment, useCallback, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { Text } from '../ui';
import Chip, { ChipWrapper } from './Chip';

const ChipsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: -4px;
  margin-left: -4px;

  ${({ inline }) =>
    inline &&
    css`
      display: inline-flex;
      flex-wrap: nowrap;
      max-width: calc(100% + 4px);
    `}

  > ${ChipWrapper} {
    margin-left: 4px;
    margin-top: 4px;
    max-width: 100%;
    overflow: hidden;
    flex: 0 1 auto;
    z-index: 1;
  }
  > input,
  > textArea {
    margin-top: 4px;
  }
`;

export const ChipLayout = styled.div`
  overflow-x: hidden;
`;

const LimitLabel = styled(Text)`
  align-self: center;
  padding-left: 2px;
  padding-right: 2px;
  font-weight: 600;
`;

const LimitWrapper = styled(ChipWrapper)`
  background-color: transparent;
  overflow: unset !important;
  margin-left: 0px !important;
`;

export default React.memo(
  ({
    values,
    onChange,
    onChipClick,
    enabled,
    limitChip = 0,
    inline,
    variant,
    ...props
  }) => {
    const handleDelete = useCallback(
      option => onChange((values || []).filter(v => v.value !== option.value)),
      [onChange, values]
    );

    const memoValues = useMemo(() => {
      if (limitChip > 0) {
        return (values || []).slice(0, limitChip);
      }
      return values || [];
    }, [limitChip, values]);

    return (
      <ChipLayout {...props}>
        <ChipsContainer inline={inline}>
          <Fragment>
            {memoValues.map(option => (
              <Chip
                key={option.value}
                option={option}
                enabled={enabled}
                onClick={onChipClick}
                onDeleteClick={handleDelete}
                variant={variant}
              />
            ))}
            {values && limitChip > 0 && values.length > limitChip && (
              <LimitWrapper>
                <LimitLabel color="secondary">{`+${values.length -
                  limitChip}`}</LimitLabel>
              </LimitWrapper>
            )}
            {props.children}
          </Fragment>
        </ChipsContainer>
      </ChipLayout>
    );
  }
);

import React, { useCallback, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import * as Yup from 'yup';
import { EntityModal } from 'components/v3/Modal';
import { TextInputField, ERRORS } from 'components/v3/Form';
import { Grid, GridItem } from 'components/v3/ui';
import {
  AccountingSelector,
  AccountingAction,
  subcontractingAsValues
} from 'features/Accounting';
import { OrganizationField } from 'features/Organizations/components/v3/Select';

const validationSchema = Yup.object().shape({
  displayName: Yup.string().required(ERRORS.REQUIRED),
  organization: Yup.object()
    .nullable()
    .required(ERRORS.REQUIRED)
});

const SubcontractingModal = ({
  onRequestClose,
  onSubmitSuccess,
  subcontracting: propsSubcontracting,
  ...props
}) => {
  const dispatch = useDispatch();
  const { params } = useRouteMatch();
  let { subcontractingId, projectId } = params;
  if (propsSubcontracting) {
    subcontractingId = propsSubcontracting.id;
  }
  const isNew = !Boolean(subcontractingId);

  const selectSubcontracting = useMemo(
    () => state =>
      AccountingSelector.selectSubcontracting(state, {
        subcontractingId
      }),
    [subcontractingId]
  );

  let subcontracting = useSelector(selectSubcontracting, isEqual);
  if (!subcontracting) {
    subcontracting = propsSubcontracting;
  }

  const createRequest = useCallback(
    payload => dispatch(AccountingAction.createSubcontracting({ payload })),
    [dispatch]
  );

  const fetchRequest = useCallback(
    () =>
      dispatch(AccountingAction.getSubcontracting({ id: subcontractingId })),
    [subcontractingId, dispatch]
  );

  const updateRequest = useCallback(
    (id, payload) =>
      dispatch(AccountingAction.updateSubcontracting({ id, payload })),
    [dispatch]
  );

  const deleteRequest = useCallback(
    id => dispatch(AccountingAction.deleteSubcontracting({ id })),
    [dispatch]
  );

  const entityAsValue = useCallback(
    entity => {
      const values = subcontractingAsValues(entity);
      return {
        ...values,
        projectId
      };
    },
    [projectId]
  );

  return (
    <EntityModal
      isOpen
      size="small"
      onRequestClose={onRequestClose}
      title={isNew ? 'Nouvelle sous-traitance' : 'Édition de la sous-traitance'}
      id={subcontractingId}
      model="subcontracting"
      entity={subcontracting}
      validationSchema={validationSchema}
      entityAsValue={entityAsValue}
      fetchRequest={fetchRequest}
      createRequest={createRequest}
      updateRequest={updateRequest}
      deleteRequest={deleteRequest}
      onSubmitSuccess={onSubmitSuccess}
      autoSubmit={false}
      {...props}
    >
      {() => (
        <Grid spacing={16} padded={false}>
          <GridItem width="100%">
            <TextInputField
              name="displayName"
              label="Nom de la sous-traitance"
              required
            />
          </GridItem>
          <GridItem width="100%">
            <OrganizationField name="organization" required />
          </GridItem>
          <GridItem width="100%">
            <TextInputField name="amount" currency label="Coût (€)" />
          </GridItem>
        </Grid>
      )}
    </EntityModal>
  );
};

export default SubcontractingModal;

import React, { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import * as Yup from 'yup';
import { AuthAction } from 'features/Auth';
import Form, {
  ERRORS,
  TextInputField,
  PasswordField,
  InputLayoutVariant
} from 'components/v3/Form';
import { Grid, GridItem } from 'components/v3/ui';
import { toaster } from 'components/v3/Toast';
import { useQuery, useRedux } from 'components/v3/utils';
import { FormFooter, FormHeader } from './';

const Schema = Yup.object().shape({
  email: Yup.string().trim().required(ERRORS.EMAIL).email(ERRORS.EMAIL),
  password: Yup.string().trim().required()
});

export const LoginForm = () => {
  const [, login] = useRedux(null, AuthAction.login, { fetchOnMount: false });
  const history = useHistory();

  let { email: initialEmail } = useQuery();
  const { state } = useLocation();
  if (!initialEmail && state) {
    initialEmail = state.email;
  }

  const handleSubmit = useCallback(
    (values, form) => {
      const { email, password } = values;
      login(() => ({ email, password }))
        .onSuccess(() => {
          const { redirect } = queryString.parse(window.location.search);
          if (redirect) {
            history.replace(redirect);
          } else {
            history.replace('/');
          }
        })
        .onError(e => toaster.error(e, { position: 'bottom-center' }))
        .onEnd(() => form.setSubmitting(false));
    },
    [login, history]
  );

  return (
    <Form
      onSubmit={handleSubmit}
      validationSchema={Schema}
      initialValues={{
        email: initialEmail || '',
        password: ''
      }}
    >
      {({ values: { email }, isSubmitting, isValid }) => (
        <Grid spacing={16}>
          <FormHeader
            title="Connexion"
            subtitle="Connectez-vous à votre espace de gestion de chantier"
          />
          <GridItem width="100%">
            <TextInputField
              variant={InputLayoutVariant.fill}
              name="email"
              label="Adresse e-mail"
              type="email"
              autoComplete="email"
            />
          </GridItem>
          <GridItem width="100%">
            <PasswordField
              variant={InputLayoutVariant.fill}
              name="password"
              label="Mot de passe"
              autoComplete="current-password"
            />
          </GridItem>
          <FormFooter
            isSubmitting={isSubmitting}
            isValid={isValid}
            primaryAction={{
              text: 'Se connecter'
            }}
            secondaryAction={{
              text: 'Mot de passe oublié',
              to: {
                pathname: '/mot-de-passe-oublie',
                state: { email }
              }
            }}
          />
        </Grid>
      )}
    </Form>
  );
};

import { mergeJsonApi } from 'common/reducers';

const initialState = {
  machinePhaseById: {},
  machinePhasesIds: []
};

const machinePhases = (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case 'LOGOUT':
    case 'SELECT_ACCOUNT':
      return initialState;
    default:
      return mergeJsonApi('machinePhase', state, action);
  }
};

export default machinePhases;

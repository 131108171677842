import React from 'react';
import FileIcon, { defaultStyles } from 'react-file-icon';

const AttachmentIcon = ({ attachment, size = 32 }) => {
  const type = attachment.sourceContentType || attachment.type;
  let iconType = 'document';
  if (type === 'application/pdf') {
    iconType = 'pdf';
  } else if (type && type.includes('image/')) {
    iconType = 'image';
  }
  return (
    <FileIcon extension={iconType} {...defaultStyles[iconType]} size={size} />
  );
};

export default AttachmentIcon;

import React, {
  useCallback,
  useMemo,
  Fragment,
  useState,
  useEffect
} from 'react';
import { SelectField } from 'components/v3/Form';
import { SelectInputLayout } from 'components/v3/InputLayout';
import { useRedux } from 'components/v3/utils';
import CarrierModal from './Modal';
import { jsonApiToObject } from 'common/api';
import {
  CarrierSelector,
  CarrierAction,
  carrierAsOption
} from 'features/Carriers';
import { permissions, useAccountConfig } from 'config';

const formatCreateLabel = input => `Créer le transporteur ${input}`;

const CarrierSelect = ({ value, onChange, ...props }) => {
  const { accountId, hasPermission } = useAccountConfig();
  const [carrierModal, setCarrierModal] = useState();
  const [carriers, fetch] = useRedux(
    CarrierSelector.selectCarriers,
    CarrierAction.getCarriers,
    {
      fetchOnMount: false,
      useLocalState: true,
      initialParams: {
        page: 1,
        perPage: 50,
        useCache: true
      }
    }
  );

  const handleCreateOption = useCallback(input => {
    setCarrierModal({
      displayName: input
    });
  }, []);

  const handleEditOption = useCallback(value => {
    setCarrierModal(value);
  }, []);

  const options = useMemo(() => carrierAsOption(carriers), [carriers]);

  const currentValue = useMemo(() => carrierAsOption(value), [value]);

  useEffect(() => {
    fetch(params => params);
  }, [accountId, fetch]);

  return (
    <Fragment>
      <SelectInputLayout
        label="Transporteur"
        value={currentValue}
        options={options}
        isSearchable
        onChange={onChange}
        inputCreatable={hasPermission([
          permissions.RESOURCES_CONFIG,
          'carrier.create'
        ])}
        createLabel="Créer un transporteur"
        formatCreateLabel={formatCreateLabel}
        onCreateOption={handleCreateOption}
        optionEditable={hasPermission([
          permissions.RESOURCES_CONFIG,
          'carrier.update'
        ])}
        onEditOptionClick={handleEditOption}
        {...props}
      />
      {carrierModal && (
        <CarrierModal
          carrier={carrierModal}
          onRequestClose={() => setCarrierModal(null)}
          onSubmitSuccess={response => {
            const carrier = jsonApiToObject(response);
            if (props.multi) {
              onChange([...currentValue, carrier]);
            } else {
              onChange(carrier);
            }
          }}
        />
      )}
    </Fragment>
  );
};

export const CarrierField = props => {
  return <SelectField component={CarrierSelect} {...props} />;
};

export default CarrierSelect;

import React, { useState, useCallback } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { MdAdd } from 'react-icons/md';
import SubHeader from 'components/v3/SubHeader';
import { FilterBar } from 'components/v3/Filters';
import { Container, Title, Button } from 'components/v3/ui';
import {
  AccountingSelector,
  AccountingAction,
  ServiceList
} from 'features/Accounting';

const defaultParams = { sort: '-updated_at' };

const Services = () => {
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  const [count, setCount] = useState();
  const [filters, setFilters] = useState([]);

  const handleDelete = useCallback(
    item =>
      dispatch(
        AccountingAction.deleteService({
          id: item.id
        })
      ),
    [dispatch]
  );

  return (
    <Container vertical>
      <SubHeader>
        <Title>{`Services${count != null ? ` (${count})` : ''}`}</Title>
        <Button small to={`${path}/nouveau-service`} icon={MdAdd}>
          Nouveau service
        </Button>
      </SubHeader>
      <Container vertical huge padded>
        <FilterBar
          big
          filters={filters}
          searchQuery="displayNameOrDescriptionOrReferenceContAny"
          onChanges={setFilters}
        />
        <ServiceList
          spacing={8}
          headerVariant="transparent"
          selector={AccountingSelector.selectServices}
          action={AccountingAction.getServices}
          onCountChanged={setCount}
          params={defaultParams}
          filters={filters}
          perPage={50}
          detailUrlPrefix=""
          isDeletable
          deleteRequest={handleDelete}
        />
      </Container>
    </Container>
  );
};

export default Services;

import React from 'react';
import styled, { css } from 'styled-components';
import { HEADER_HEIGHT } from './';
import { spacing, NavLink } from './';
import { List } from 'components/v3/ui/list';

export const Sidebar = styled.div`
  background-color: #fff;
  position: relative;
  width: 100%;

  min-height: 100%;
  flex: 0 0 300px;
  border-right: 1px solid ${({ theme }) => theme.separator};

  ${props =>
    props.hasHeader &&
    css`
      padding-top: ${HEADER_HEIGHT + 2}px;
    `};
`;

export const MainMenu = styled(List)`
  padding-bottom: ${spacing(2)};
`;

export const SubMenu = styled(List)`
  color: #fff;
  overflow: hidden;
  transition: all 0.8s ease-out;
  background-color: ${({ theme }) => theme.primary};
`;

export const SidebarMenuItem = styled.li`
  position: relative;
  font-size: 0.85rem;
  transition: all 0.5s ease;
  color: ${({ theme }) => theme.textLightSecondary};

  ${({ hasAccentBorder }) =>
    hasAccentBorder &&
    css`
      .active {
        &:before {
          content: '';
          position: absolute;
          background-color: ${({ theme }) => theme.accent};
          top: 0px;
          bottom: 0px;
          left: 0px;
          width: 4px;
        }
      }
    `}
`;

export const SidebarMenuLinkWrapper = styled(NavLink)`
  display: flex;
  align-items: center;
  padding: ${spacing(0.25)} ${6 + spacing(1, false)}px;
  color: ${({ theme }) => theme.sidebarLinkColor};
  font-weight: ${({ theme }) => theme.sidebarMenuActiveLinkFontWeight};

  &:hover:not(.active):not([disabled]) {
    color: ${({ theme }) => theme.textLightPrimary};
  }

  &:not(:hover):not(.active):not([disabled]) {
    color: ${({ theme }) => theme.textLightSecondary};
  }

  &[disabled] {
    color: ${({ theme }) => theme.textLightHint};
    &:hover {
      cursor: not-allowed;
    }
  }

  > span {
    width: 100%;
    border-radius: ${({ theme }) => theme.borderRadius};
    display: block;
    transition: visibility 0s, opacity 0.5s linear;

    ${({ main }) =>
      main &&
      css`
        padding: ${spacing(0.5)} ${spacing(0.75)};
      `}

    ${({ sub }) =>
      sub &&
      css`
        padding-top: ${spacing(0.5)};
        padding-bottom: ${spacing(0.5)};
        padding-left: ${spacing(1.5)};
      `}
  }
`;

export const SidebarMenuLink = ({
  to: propsTo,
  children,
  isActive,
  hasAccentBorder,
  ...props
}) => {
  const to = props.disabled ? null : propsTo;
  return (
    <SidebarMenuLinkWrapper
      to={to}
      as={to ? undefined : 'div'}
      isActive={isActive}
      {...props}
    >
      {children}
    </SidebarMenuLinkWrapper>
  );
};

import { mergeJsonApi } from 'common/reducers';

const initialState = {
  projectActivityById: {}
};

const activities = (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case 'LOGOUT':
    case 'SELECT_ACCOUNT':
      return initialState;
    default:
      return mergeJsonApi('projectActivity', state, action);
  }
};

export default activities;

import React, { Fragment, useState } from 'react';
import styled, { css } from 'styled-components';
import { MdMoreVert, MdClose } from 'react-icons/md';
import Dialog from 'components/v3/Dialog';
import Menu, { MenuItem } from 'components/v3/Menu';
import {
  InputLayoutVariant,
  TextInputLayout,
  InputLayoutContainer
} from 'components/v3/InputLayout';
import { ButtonIcon } from 'components/v3/ui';
import ColorPicker from 'components/v3/ColorPicker';

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  ${InputLayoutContainer} {
    background-color: #f6f6f6;
    border-top-left-radius: ${({ theme }) => theme.borderInputRadius};
    border-top-right-radius: ${({ theme }) => theme.borderInputRadius};
  }
  ${InputLayoutContainer}:focus-within:not ([readOnly]):not([disabled]) {
    background-color: #eaeaea;
  }
  ${InputLayoutContainer}:hover:not (:focus-within):not([readOnly]):not([disabled]) {
    background-color: #f0f0f0;
  }
  ${({ active }) =>
    active &&
    css`
      ${InputLayoutContainer} {
        box-shadow: 0px 0px 5px 3px ${({ theme }) => theme.primaryLight};
        i {
          opacity: 1;
        }
        * > svg {
          fill: ${({ theme }) => theme.primaryLight};
        }
      }
    `}

  > *:only-child {
    margin-right: 8px;
  }
`;

const EditorRouteRow = ({
  route,
  onChange,
  active,
  onRouteIdSelected,
  onDelete,
  editable = true,
  readOnly
}) => {
  const [confirmDelete, showConfirmDelete] = useState();

  const handleSelectRoute = e => {
    e.stopPropagation();
    onRouteIdSelected(route.id);
  };
  const handleUnselectRoute = e => {
    e.stopPropagation();
    onRouteIdSelected(null);
  };

  return (
    <Fragment>
      <Wrapper active={active} onClick={handleSelectRoute}>
        <TextInputLayout
          required
          fillWidth
          variant={InputLayoutVariant.flat}
          readOnly={readOnly || !editable}
          value={route.displayName || route.name}
          onChange={displayName =>
            onChange({
              ...route,
              displayName
            })
          }
          leadingIconClickable={!readOnly}
          iconLeading={
            <ColorPicker
              readOnly={readOnly || !editable}
              value={route.color}
              onChange={color =>
                onChange({
                  ...route,
                  color
                })
              }
            />
          }
          trailingTooltip={active ? 'Ne plus surligner' : null}
          onTrailingIconClick={handleUnselectRoute}
          iconTrailing={active ? MdClose : null}
        />
        {!readOnly && onDelete && (
          <Menu>
            <ButtonIcon>
              <MdMoreVert />
            </ButtonIcon>
            <MenuItem onClick={() => showConfirmDelete(true)}>
              Supprimer
            </MenuItem>
          </Menu>
        )}
      </Wrapper>
      {confirmDelete && (
        <Dialog
          isOpen={true}
          onRequestClose={() => showConfirmDelete(false)}
          title="Confirmer la suppression"
          content="Toute suppression est définitive et les données associées seront perdues."
          positiveText="Annuler"
          negativeText="Supprimer"
          negativeAction={() => onDelete(route)}
        />
      )}
    </Fragment>
  );
};

export default EditorRouteRow;

import React, { Fragment, useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  InvoicesBox,
  AccountingSelector,
  AccountingAction,
  SubcontractingsBox
} from 'features/Accounting';
import { ProjectAction } from 'features/Projects';
import { features, Permission } from 'config';

const Finances = ({ projectId }) => {
  const dispatch = useDispatch();

  const invoicesSelector = useMemo(
    () => state =>
      AccountingSelector.selectProjectInvoices(state, { projectId }),
    [projectId]
  );

  const handleDeleteInvoice = useCallback(
    item => dispatch(AccountingAction.deleteInvoice({ id: item.id })),
    [dispatch]
  );

  const subcontractingSelector = useMemo(
    () => state =>
      AccountingSelector.selectProjectSubcontractings(state, { projectId }),
    [projectId]
  );

  const handleDeleteSubcontracting = useCallback(
    item => dispatch(AccountingAction.deleteSubcontracting({ id: item.id })),
    [dispatch]
  );

  const refreshProject = useCallback(
    () => dispatch(ProjectAction.getProject({ id: projectId })),
    [dispatch, projectId]
  );

  return (
    <Fragment>
      <InvoicesBox
        selector={invoicesSelector}
        creatable
        marged
        page="project"
        projectId={projectId}
        onSubmitSuccess={refreshProject}
        onDeleteSuccess={refreshProject}
        isDeletable
        detailUrlPrefix=""
        deleteRequest={handleDeleteInvoice}
        useWindow={false}
        minHeight={200}
      />
      <Permission feature={features.SUBCONTRACTING}>
        <SubcontractingsBox
          selector={subcontractingSelector}
          creatable
          marged
          projectId={projectId}
          onSubmitSuccess={refreshProject}
          onDeleteSuccess={refreshProject}
          isDeletable
          deleteRequest={handleDeleteSubcontracting}
          useWindow={false}
          minHeight={200}
        />
      </Permission>
    </Fragment>
  );
};

export default Finances;

import { createAction } from 'redux-actions';
import { batch } from 'react-redux';
import omit from 'lodash/omit';
import * as Api from './api';
import { UserApi } from 'features/Users';
import { TeamApi } from 'features/Teams';
import { MachineApi } from 'features/Machines';
import { PhaseApi } from 'features/Phases';
import { ProjectApi } from 'features/Projects';
import { PeriodApi } from 'features/Periods';
import { HolidayApi } from 'features/Holidays';
import { updateHolidaySuccess } from 'features/Holidays/data/action';
import { merge } from 'utils';
import { SCHEDULER_TYPE } from 'pages/Plannings/Timeline/View';

const planningLoading = createAction('PLANNING_LOADING');

export const receivedPlanningData = createAction('PLANNING_DATA_SUCCESS');
const receivedEmptyProjects = createAction('PLANNING_EMPTY_PROJECTS_SUCCESS');

export const getPlanningData = args => async dispatch => {
  dispatch(planningLoading(true));
  const payload = omit(args, ['schedulerType']);
  const fetchProjects =
    args?.schedulerType?.name === SCHEDULER_TYPE.PROJECTS.name;

  try {
    const promises = [];
    let phasesIndex = -1;
    let projectsIndex = -1;
    let periodsIndex = -1;
    let holidaysIndex = -1;
    if (!payload.usersIdIn || payload.usersIdIn.length) {
      phasesIndex = promises.length;
      promises.push(Api.getPlanningPhases(payload));
    }
    periodsIndex = promises.length;
    promises.push(
      PeriodApi.getPeriods({
        ...omit(payload, 'projectKindIdEq', 'groupIdIn'),
        perPage: 500,
        page: 1,
        includePublicHolidays: true
      })
    );
    holidaysIndex = promises.length;
    promises.push(
      HolidayApi.getHolidays({
        ...omit(payload, 'projectKindIdEq', 'groupIdIn'),
        perPage: 500,
        page: 1
      })
    );
    if (fetchProjects) {
      projectsIndex = promises.length;
      promises.push(
        ProjectApi.getProjects({
          stateNotEqOrNull: 'archived',
          withoutSource: 'projects',
          page: 1,
          perPage: 500
        })
      );
    }
    const fetched = await Promise.all(promises);

    const phases = phasesIndex === -1 ? { data: [] } : fetched[phasesIndex];
    const periods = periodsIndex === -1 ? { data: [] } : fetched[periodsIndex];
    const holidays =
      holidaysIndex === -1 ? { data: [] } : fetched[holidaysIndex];
    const emptyProjects =
      projectsIndex === -1 ? { data: [] } : fetched[projectsIndex];

    const phasesIncludes = (phases.included || []).reduce((acc, data) => {
      acc[`${data.type}-${data.id}`] = data;
      return acc;
    }, {});
    const holidaysIncludes = (holidays.included || []).reduce((acc, data) => {
      acc[`${data.type}-${data.id}`] = data;
      return acc;
    }, {});

    batch(() => {
      dispatch(
        receivedPlanningData({
          data: [...phases.data, ...periods.data, ...holidays.data],
          included: Object.values(merge(phasesIncludes, holidaysIncludes))
        })
      );
      if (fetchProjects) {
        dispatch(receivedEmptyProjects(emptyProjects));
      }
    });
    return phases;
  } catch (e) {
    dispatch(planningLoading(false));
    throw e;
  }
};
const receivedSearchEmptyProjects = createAction(
  'PLANNING_SEARCH_EMPTY_PROJECTS_SUCCESS'
);
const receivedPlanningSearchProjects = createAction('PLANNING_SEARCH_PROJECTS');
export const searchProjects = payload => async dispatch => {
  dispatch(planningLoading(true));
  try {
    const [response, emptyProjects] = await Promise.all([
      ProjectApi.getProjects(payload),
      ProjectApi.getProjects({
        ...payload,
        stateNotEqOrNull: 'archived',
        page: 1,
        perPage: 500,
        withoutSource: 'projects',
        'between[start_at]': undefined,
        'between[end_at]': undefined
      })
    ]);

    batch(() => {
      dispatch(receivedPlanningSearchProjects(response));
      dispatch(receivedSearchEmptyProjects(emptyProjects));
    });
    return response;
  } catch (e) {
    dispatch(planningLoading(false));
    throw e;
  }
};

export const clearSearch = createAction('PLANNING_CLEAR_SEARCH_PROJECTS');

const receivedUsers = createAction('PLANNING_USERS_SUCCESS');
export const getUsers = payload => async dispatch => {
  dispatch(planningLoading(true));
  const response = await UserApi.getUsers(payload);
  dispatch(receivedUsers(response));
  return response;
};

const receivedTeams = createAction('PLANNING_TEAMS_SUCCESS');
export const getTeams = payload => async dispatch => {
  dispatch(planningLoading(true));
  const response = await TeamApi.getTeams(payload);
  dispatch(receivedTeams(response));
  return response;
};

const receivedMachines = createAction('PLANNING_MACHINES_SUCCESS');
export const getMachines = payload => async dispatch => {
  dispatch(planningLoading(true));
  const response = await MachineApi.getMachines(payload);
  dispatch(receivedMachines(response));
  return response;
};

const planningUpdating = createAction('PLANNING_UPDATING');
const planningUpdateSuccess = createAction('PLANNING_UPDATE_EVENT_SUCCESS');
export const updatePlanningPhase =
  ({ id, payload, ...config }) =>
  async (dispatch, getState) => {
    const oldValue = getState().phases.phaseById[id];
    try {
      dispatch(planningUpdating(id));
      // await dispatch(receiveUpdatePhase({ id: Number(id), ...payload }, null));
      // dispatch(planningUpdateSuccess({ id: Number(id), ...payload }));
      const result = await PhaseApi.putPhase(id, payload, config);
      await dispatch(planningUpdateSuccess(result));
      return result;
    } catch (e) {
      console.error(e);
      const { relationships, ...attributes } = oldValue || {};
      dispatch(
        planningUpdateSuccess({
          data: { id: id, type: 'phase', relationships, attributes }
        })
      );
      throw e;
    }
  };

export const updateHoliday =
  ({ id, payload: holiday, ...config }) =>
  async dispatch => {
    dispatch(planningUpdating());
    const result = await HolidayApi.putHoliday(id, holiday, config);
    dispatch(updateHolidaySuccess(result));
    return result;
  };

const reduceOverlaps = createAction('PLANNING_UPDATE_OVERLAPS');
export const updateOverlaps =
  overlapsToUpdate => async (dispatch, getState) => {
    if (!overlapsToUpdate || overlapsToUpdate.length === 0) {
      return;
    }
    dispatch(reduceOverlaps({ overlapsToUpdate, globalState: getState() }));
  };

import React, { useEffect, useCallback, Fragment } from 'react';
import {
  useRouteMatch,
  Route,
  useHistory,
  useLocation
} from 'react-router-dom';
import { Text } from 'components/v3/ui';
import Table from 'components/v3/List/Table';
import { useListFetcher } from 'components/v3/utils';
import { printDate } from 'utils/dates';
import SubcontractingModal from './SubcontractingModal';

const columns = [
  {
    Header: 'Créé le',
    accessor: 'createdAt',
    width: '10%',
    Cell: ({ item }) => (
      <Text variant="small" color="caption">
        {printDate(item.createdAt)}
      </Text>
    )
  },
  {
    Header: 'Organisation',
    accessor: 'organization',
    width: '30%',
    Cell: ({ item }) => (
      <Text variant="small">{item.organization?.displayName}</Text>
    )
  },
  {
    Header: 'Nom',
    accessor: 'displayName',
    width: '40%',
    Cell: ({ item }) => <Text variant="small">{item.displayName}</Text>
  },
  {
    Header: 'Montant',
    accessor: 'amount',
    width: '15%',
    textAlign: 'end',
    Cell: ({ item }) => (
      <Text currency variant="small">
        {item.amount}
      </Text>
    )
  }
];

const SubcontractingList = ({
  projectId,
  selector,
  action,
  page = 'default',
  options = {
    fetchOnMount: false,
    useLocalState: true
  },
  params = {
    sort: '-created_at'
  },
  perPage = 25,
  filters,
  onCountChanged,
  detailUrlPrefix = '/sous-traitances',
  onSubmitSuccess,
  ...props
}) => {
  const { path, url } = useRouteMatch();
  const history = useHistory();
  const location = useLocation();
  const [subcontractings, paginationHelper, state] = useListFetcher({
    selector,
    action,
    options,
    params,
    perPage,
    filters
  });

  const detailLinkFormatter = useCallback(
    item => `${url}${detailUrlPrefix}/${item.id}`,
    [url, detailUrlPrefix]
  );

  useEffect(() => {
    if (paginationHelper.total != null && onCountChanged) {
      onCountChanged(paginationHelper.total);
    }
  }, [paginationHelper.total, onCountChanged]);

  return (
    <Fragment>
      <Table
        columns={columns}
        items={subcontractings}
        isLoading={state.isLoading}
        linkFormatter={detailLinkFormatter}
        centerMessage={state.errorMessage}
        estimatedItemSize={48 + (props.spacing || 0) / 2}
        {...paginationHelper}
        {...props}
      />
      <Route
        path={[
          `${path}${detailUrlPrefix}/nouveau`,
          `${path}${detailUrlPrefix}/:subcontractingId`
        ]}
      >
        <SubcontractingModal
          onRequestClose={() => {
            if (location.key) {
              history.goBack();
            } else {
              history.replace(url);
            }
          }}
          onSubmitSuccess={onSubmitSuccess}
        />
      </Route>
    </Fragment>
  );
};

export default SubcontractingList;

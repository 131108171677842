import { useContext } from 'react';
import ConfigProvider from './Provider';
import Context from './Context';
import Permission from './components/Permission';

export const useAccountConfig = () => useContext(Context);

// export const ability = {
//   EDIT: 'EDIT',
//   ADMIN: 'ADMIN',
//   OPERATIONS: 'OPERATIONS',
//   PRODUCTS: 'PRODUCTS',
//   SUPERADMIN: 'SUPERADMIN',
//   SHOW_PHASE: 'SHOW_PHASE',
//   CAN_SIGN: 'CAN_SIGN',
//   COMPANY_DATA: 'COMPANY_DATA' // en gros ceux qui sont de l'entreprise, pas les clients
// };

// export const abilities = [
//   {
//     name: ability.ADMIN,
//     roles: ['admin']
//   },
//   {
//     name: ability.OPERATIONS,
//     roles: ['admin', 'conductor']
//   },
//   {
//     name: ability.EDIT,
//     roles: ['admin', 'conductor']
//   },
//   {
//     name: ability.PRODUCTS,
//     roles: ['admin', 'conductor']
//   },
//   {
//     name: ability.SUPERADMIN,
//     roles: ['super_admin']
//   },
//   {
//     name: ability.SHOW_PHASE,
//     roles: ['admin', 'conductor']
//   },
//   {
//     name: ability.COMPANY_DATA,
//     roles: ['admin', 'conductor', 'technician']
//   },
//   {
//     name: ability.CAN_SIGN,
//     predicate: user => user.settings?.canSign
//   }
// ];

export const permissions = {
  ADMINISTRATION_VIEW: 'administration_view',
  STATISTICS_VIEW: 'statistics_view',
  ORDERS_VIEW: 'orders_view',
  COMPANY_DATA: 'company_data',
  CHECK_DOCUMENTS: 'CHECK_DOCUMENTS',

  CRM: 'crm',
  ACCOUNTING: 'accounting',
  HOLIDAYS: 'holidays',

  RESOURCES_CONFIG: 'resources_config',
  MANAGE_USER: 'manage_users',

  SUPERADMIN: 'SUPERADMIN'
};

export const features = {
  ADMIN_CHECK: 'admin_check',

  PLANNING_CALENDAR: 'planning_calendar',
  PLANNING_MACHINES: 'planning_machines',
  PLANNING_MEMBERS: 'planning_members',
  PLANNING_PROJECTS: 'planning_projects',
  PLANNING_TEAMS: 'planning_teams',
  SPLIT_PLANNING_SOURCES: 'split_planning_sources',

  GLOBAL_MAP: 'global_map',
  PROJECT_LIST: 'project_list',
  IMPORT_PROJECTS_CSV: 'import_projects_csv',
  GPS_BOX: 'gps_box', // les gps sur les camions

  MANAGEMENT_HOLIDAYS: 'management_holidays',

  CRM: 'crm', // organisations & contacts
  CONTACT_ADDRESSABLE: 'contact_addressable',
  CLIENT_SHARING: 'client_sharing',
  ACCOUNTING: 'accounting', // compta & services & les couts sur les projets
  SEND_INVOICE_BY_EMAIL: 'send_invoice_by_email',
  SIGN_INVOICE: 'sign_invoice',
  SECOND_DISCOUNT: 'second_discount',
  INVOICE_DIVIDERS_SUBTOTAL: 'invoice_dividers_subtotal',
  INVOICE_DEPOSIT: 'invoice_deposit',
  PRINT_INVOICE_WITHOUT_PRICES: 'print_invoice_without_prices',
  SUBCONTRACTING: 'subcontracting',

  ISSUES: 'issues', // signalements
  REPORTS: 'reports',

  BILLING_STATS: 'billing_stats', // stats, commandes centrales, fournisseurs
  ORDERS_COATING: 'orders_coating', // stats, commandes centrales, fournisseurs
  MATERIALS: 'materials', //
  ORDERS_DELIVERIES: 'orders_deliveries', // transporteurs & commandes transporteur
  DEVIATIONS: 'deviations', // les déviations eurovia
  PMV: 'pmv', // les panneaux eurovia

  PHASE_ACTIVITIES: 'phase_activities',

  VOUCHERS: 'vouchers',
  PROJECT_MANAGERS: 'project_managers',
  PROJECT_TABLE: 'project_table',
  ATTACHMENTS: 'attachments'
};

// export const features = {
//   // CLIENTS: 'clients',

// };

export const ROLES = [
  {
    value: 'admin',
    label: 'Administrateur'
  },
  {
    value: 'conductor',
    label: 'Conducteur '
  },
  {
    value: 'technician',
    label: 'Technicien'
  },
  {
    value: 'user',
    label: 'Client'
  }
];

export { ConfigProvider, Permission };

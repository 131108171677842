import { createAction } from 'redux-actions';
import omit from 'lodash/omit';
import * as Api from './extraction_material_api';
import { shouldFetch } from 'common/actions';

const prepareExtractionMaterialPayload = payload => payload;

const extractionMaterialsLoading = createAction('EXTRACTION_MATERIALS_LOADING');
const extractionMaterialsSuccess = createAction('EXTRACTION_MATERIALS_SUCCESS');
const extractionMaterialsError = createAction('EXTRACTION_MATERIALS_ERROR');
export const getExtractionMaterials = payload => async (dispatch, getState) => {
  const { useCache = true, ...params } = payload || {};
  const state = getState();
  if (!useCache || shouldFetch('extractionMaterial', params, state)) {
    return fetchExtractionMaterials(params, dispatch);
  } else {
    return {
      fromCache: true,
      isLoading: getState().extractionMaterials.isLoading
    };
  }
};
const fetchExtractionMaterials = async (payload, dispatch) => {
  dispatch(extractionMaterialsLoading());
  try {
    const result = await Api.getExtractionMaterials(payload);
    dispatch(
      extractionMaterialsSuccess({
        ...result,
        requestParams: omit(payload, 'cancelToken')
      })
    );
    return result;
  } catch (error) {
    dispatch(extractionMaterialsError(error));
    throw error;
  }
};

const receiveCreateExtractionMaterial = createAction(
  'NEW_EXTRACTION_MATERIAL_SUCCESS'
);
export const createExtractionMaterial =
  ({ payload, ...config }) =>
  async dispatch => {
    const result = await Api.createExtractionMaterial(
      prepareExtractionMaterialPayload(payload),
      config
    );
    dispatch(receiveCreateExtractionMaterial(result));
    return result;
  };

const receiveUpdateExtractionMaterial = createAction(
  'UPDATE_EXTRACTION_MATERIAL_SUCCESS'
);
export const updateExtractionMaterial =
  ({ id, payload: extractionMaterial, ...config }) =>
  async dispatch => {
    const result = await Api.putExtractionMaterial(
      id,
      prepareExtractionMaterialPayload(extractionMaterial),
      config
    );
    dispatch(receiveUpdateExtractionMaterial(result));
    return result;
  };

const receiveDeleteExtractionMaterial = createAction(
  'DELETE_EXTRACTION_MATERIAL_SUCCESS'
);
export const deleteExtractionMaterial =
  ({ id, ...config }) =>
  async dispatch => {
    const response = await Api.deleteExtractionMaterial(id, config);
    dispatch(receiveDeleteExtractionMaterial(id));
    return response;
  };

import React, { useCallback, useMemo, Fragment, useState, useEffect } from 'react';
import { SelectField } from 'components/v3/Form';
import { SelectInputLayout } from 'components/v3/InputLayout';
import { useRedux } from 'components/v3/utils';
import PurveyorModal from './PurveyorModal';
import { jsonApiToObject } from 'common/api';
import { SurfacingSelector, SurfacingAction, purveyorAsOption } from 'features/Surfacing';
import { permissions, useAccountConfig } from 'config';

const formatCreateLabel = (input) => `Créer le fournisseur ${input}`;

const PurveyorSelect = ({ value, onChange, colored, fetchDisabled, ...props }) => {
  const { accountId, hasPermission } = useAccountConfig();
  const [purveyorModal, setPurveyorModal] = useState();
  const [purveyors, fetch] = useRedux(SurfacingSelector.selectPurveyors, SurfacingAction.getPurveyors, {
    fetchOnMount: false,
    useLocalState: true,
    initialParams: {
      page: 1,
      perPage: 500,
      useCache: true
    }
  });

  const handleCreateOption = useCallback((input) => {
    setPurveyorModal({
      displayName: input
    });
  }, []);

  const handleEditOption = useCallback((value) => {
    setPurveyorModal(value);
  }, []);

  const options = useMemo(() => purveyorAsOption(purveyors, colored), [purveyors, colored]);

  const currentValue = useMemo(() => purveyorAsOption(value, colored), [value, colored]);

  useEffect(() => {
    if (fetchDisabled) return;
    fetch((params) => params);
  }, [fetch, accountId, fetchDisabled]);

  return (
    <Fragment>
      <SelectInputLayout
        label="Fournisseur"
        value={currentValue}
        options={options}
        isSearchable
        onChange={onChange}
        inputCreatable={false}
        createLabel="Créer un fournisseur"
        formatCreateLabel={formatCreateLabel}
        onCreateOption={handleCreateOption}
        optionEditable={hasPermission([permissions.RESOURCES_CONFIG, 'purveyor.update'])}
        onEditOptionClick={handleEditOption}
        {...props}
      />
      {purveyorModal && (
        <PurveyorModal
          purveyor={purveyorModal}
          onRequestClose={() => setPurveyorModal(null)}
          onSubmitSuccess={(response) => {
            const purveyor = jsonApiToObject(response);
            if (props.multi) {
              onChange([...currentValue, purveyor]);
            } else {
              onChange(purveyor);
            }
          }}
        />
      )}
    </Fragment>
  );
};

export const PurveyorField = (props) => {
  return <SelectField component={PurveyorSelect} {...props} />;
};

export default PurveyorSelect;

import React, {
  useCallback,
  useMemo,
  Fragment,
  useState,
  useEffect
} from 'react';
import { SelectField } from 'components/v3/Form';
import { SelectInputLayout } from 'components/v3/InputLayout';
import { useRedux } from 'components/v3/utils';
import ActivityModal from './Modal';
import { jsonApiToObject } from 'common/api';
import {
  ActivitySelector,
  ActivityAction,
  activityAsOption
} from 'features/ProjectKindsActivities';
import { permissions, useAccountConfig } from 'config';

const formatCreateLabel = input => `Créer l'activité ${input}`;

const ActivitySelect = ({ value, projectKind, onChange, ...props }) => {
  const { accountId, hasPermission } = useAccountConfig();
  const [activityModal, setActivityModal] = useState();

  const projectKindId = projectKind?.id;

  const selectActivities = useMemo(
    () => state =>
      ActivitySelector.selectActivitiesByProjectKind(state, {
        projectKindId,
        administrative: false
      }),
    [projectKindId]
  );
  const [activities, fetch] = useRedux(
    selectActivities,
    ActivityAction.getActivities,
    {
      fetchOnMount: false,
      useLocalState: true,
      initialParams: {
        page: 1,
        perPage: 500,
        useCache: true
      }
    }
  );

  const handleCreateOption = useCallback(input => {
    setActivityModal({
      displayName: input
    });
  }, []);

  const handleEditOption = useCallback(value => {
    setActivityModal(value);
  }, []);

  const options = useMemo(() => activityAsOption(activities), [activities]);

  const currentValue = useMemo(() => activityAsOption(value), [value]);

  useEffect(() => {
    fetch(params => params);
  }, [fetch, accountId]);

  return (
    <Fragment>
      <SelectInputLayout
        label="Activité"
        value={currentValue}
        options={options}
        isSearchable
        onChange={onChange}
        inputCreatable={
          Boolean(projectKind) &&
          hasPermission([permissions.RESOURCES_CONFIG, 'activity.create'])
        }
        createLabel="Créer une activité"
        formatCreateLabel={formatCreateLabel}
        onCreateOption={handleCreateOption}
        optionEditable={hasPermission([
          permissions.RESOURCES_CONFIG,
          'activity.update'
        ])}
        onEditOptionClick={handleEditOption}
        {...props}
      />
      {activityModal && projectKind && (
        <ActivityModal
          size="small"
          activity={activityModal}
          projectKind={projectKind}
          onRequestClose={() => setActivityModal(null)}
          onSubmitSuccess={response => {
            const activity = jsonApiToObject(response);
            if (props.multi) {
              onChange([...currentValue, activity]);
            } else {
              onChange(activity);
            }
          }}
        />
      )}
    </Fragment>
  );
};

export const ActivityField = props => {
  return <SelectField component={ActivitySelect} {...props} />;
};

export default ActivitySelect;

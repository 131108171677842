import React, { useCallback } from 'react';
import { BlockPicker } from 'react-color';
import styled from 'styled-components';
import RawPopover from '../Popover';
import { convertHex } from 'utils';
import { ListItem } from '../ui/list';

const Popover = styled.div`
  min-width: 100%;
  * > .block-picker {
    width: 100% !important;
  }
`.withComponent(RawPopover);

const Color = styled.span.attrs(({ style, color }) => ({
  style: {
    backgroundColor: color,
    ...(style || {})
  }
}))`
  display: flex;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s ease;

  &[readOnly] {
    cursor: default;
  }

  &:hover:not([readOnly]) {
    box-shadow: 0px 0px 2px 2px ${({ theme }) => theme.hint};
  }
`;

const ColorPicker = ({ value, onChange, onColorSelected, ...props }) => {
  const { readOnly } = props;
  const color = value || convertHex('#ccc');
  const handleChange = useCallback(
    color => {
      onChange(color.hex);
      if (onColorSelected) {
        onColorSelected(color.hex);
      }
    },
    [onChange, onColorSelected]
  );
  return (
    <Popover readOnly={readOnly} closeOnSelect={false}>
      <Color color={color} {...props} />
      <ListItem>
        <BlockPicker
          triangle="hide"
          color={color}
          onChangeComplete={handleChange}
        />
      </ListItem>
    </Popover>
  );
};

export default ColorPicker;

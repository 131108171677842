import React, { useCallback, useMemo, Fragment } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import styled from 'styled-components';
import { formatDistanceToNow } from 'date-fns';
import fr from 'date-fns/locale/fr';
import isEqual from 'lodash/isEqual';
import { IcExternalLink } from 'common/images/icons';
import { EntityModal } from 'components/v3/Modal';
import { TextInputField, DatePickerField, CheckboxField, AddressField } from 'components/v3/Form';
import { MenuItem } from 'components/v3/Menu';
import { Grid, GridItem as RawGridItem, SectionTitle } from 'components/v3/ui';
import { useRedux } from 'components/v3/utils';
import { PhaseSelector, PhaseAction, phaseAsValues, phaseValuesDependencies, duplicate } from 'features/Phases';
import { ProjectSelector, ProjectAction } from 'features/Projects';
import { ActivityField } from 'features/ProjectKindsActivities/components/v3/Select';
import { Permission, features, useAccountConfig } from 'config';
import { TeamField } from 'features/Teams/components/v3/Select';
import { UserField } from 'features/Users/components/v3/UserSelect';
import AttachmentBox from 'features/Attachments/components/v3/Box';
import MachineTable from './MachineTable';
import State from './State';
import { ProjectField } from 'features/Projects/components/v3/Select';
import ProductOrderTable from './ProductOrderTable';
import DeliveryTable from './DeliveryTable';
import { parseDate, printDate } from 'utils/dates';
import { ExtractionTable } from './ExtractionTable';
import { InputTable } from './InputTable';
import CustomFields from 'features/CustomFieldGroups/components/v3/CustomFields';

const GridItem = styled(RawGridItem)`
  min-width: 280px;
`;

const ActivityWrapper = styled(GridItem)`
  transition: 0.3s ease max-height, padding;
  max-height: 300px;
  overflow: hidden;
  &[disabled] {
    max-height: 0px;
    padding: 0px;
  }
`;

const SectionGridItem = styled(RawGridItem)`
  margin-top: 16px;
`;

const HeaderState = (form, fetchStatus) => {
  if (!fetchStatus.hasFetch) {
    return null;
  }
  return (
    <div style={{ marginRight: 16 }}>
      <State
        size="medium"
        phaseId={form.values.id}
        value={form.values.state}
        right={0}
        onChange={fetchStatus.hasFetch ? (state) => form.setFieldValue('state', state) : undefined}
      />
    </div>
  );
};

const PhaseModal = ({
  onRequestClose,
  organization,
  showSelectProject,
  parentPath,
  project: propsProject,
  phase: propsPhase,
  hideTeam,
  hideMembers,
  hideMachines,
  source,
  ...props
}) => {
  const { translate, features: accountFeatures, hasPermission } = useAccountConfig();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { params, ...match } = useRouteMatch();
  let { phaseId, projectId } = params;
  const phaseParam = location.state?.phase || propsPhase;
  if (phaseParam) {
    phaseId = phaseParam.id;
  }
  const isNew = !Boolean(phaseId);

  const selectProject = useMemo(() => (state) => ProjectSelector.selectProject(state, { projectId }), [projectId]);

  const [project] = useRedux(selectProject, ProjectAction.getProject, {
    fetchOnMount: false,
    useLocalState: false
  });

  const selectPhase = useMemo(() => (state) => PhaseSelector.selectPhase(state, { phaseId }), [phaseId]);

  let phase = useSelector(selectPhase, isEqual);
  if (!phase) {
    phase = phaseParam;
  }

  const createRequest = useCallback((values) => dispatch(PhaseAction.createPhase({ payload: values })), [dispatch]);

  const fetchRequest = useCallback(() => dispatch(PhaseAction.getPhase({ id: phaseId })), [phaseId, dispatch]);

  const updateRequest = useCallback(
    (id, values) => dispatch(PhaseAction.updatePhase({ id, payload: values })),
    [dispatch]
  );

  const deleteRequest = useCallback((id) => dispatch(PhaseAction.deletePhase({ id })), [dispatch]);

  const { hourIncrement, minuteIncrement } = useMemo(() => {
    if ((accountFeatures || []).includes(features.PLANNING_CALENDAR)) {
      return {
        hourIncrement: 1,
        minuteIncrement: 15
      };
    } else {
      return {
        hourIncrement: 12,
        minuteIncrement: 12 * 60
      };
    }
  }, [accountFeatures]);

  const canEdit = hasPermission([isNew ? 'phase.create' : 'phase.update'], {
    target: phase
  });

  const canDelete = hasPermission(['phase.delete'], { target: phase });

  const entity = useMemo(() => {
    const obj = phase || { project };
    if (source) {
      obj.source = source;
    }
    return obj;
  }, [phase, project, source]);

  const _descriptionUpdatedAt = phase?.descriptionUpdatedAt;
  const descriptionUpdatedAt = useMemo(() => parseDate(_descriptionUpdatedAt), [_descriptionUpdatedAt]);
  return (
    <EntityModal
      size="xlarge"
      isOpen
      onRequestClose={onRequestClose}
      title={isNew ? 'Nouvelle intervention' : "Édition de l'intervention"}
      subtitle={project?.displayName}
      positiveText={isNew ? 'Créer' : 'Enregistrer'}
      id={phaseId}
      model="phase"
      entity={entity}
      entityAsValue={phaseAsValues}
      fetchRequest={fetchRequest}
      createRequest={createRequest}
      updateRequest={updateRequest}
      deleteRequest={canDelete ? deleteRequest : undefined}
      autoSubmit={false}
      headerRightContent={HeaderState}
      valuesDependencies={phaseValuesDependencies}
      readOnly={!canEdit}
      contextMenu={(form) => (
        <Fragment>
          <MenuItem
            onClick={() => {
              let url = match.url.split('/');
              const path = match.path.split('/');
              const phaseIdIndex = path.indexOf(':phaseId');
              url.splice(phaseIdIndex, 1, 'nouvelle-intervention');
              history.replace({
                pathname: url.join('/'),
                state: {
                  phase: duplicate({
                    ...form.values,
                    project: form.values.project || project
                  })
                }
              });
            }}
          >
            Dupliquer
          </MenuItem>
        </Fragment>
      )}
      {...props}
    >
      {({ values, setFieldValue }) => {
        const projectKind = values.projectKind || values.project?.projectKind;
        return (
          <Grid spacing={0} padded={false}>
            <GridItem width="50%" minWidth={550}>
              <Grid spacing={16} padded={false}>
                <GridItem width="100%">
                  <SectionTitle>Informations générales</SectionTitle>
                </GridItem>
                <GridItem width="50%">
                  <TextInputField name="displayName" label="Nom de l'intervention" readOnly={!canEdit} />
                </GridItem>
                <Permission feature={features.PHASE_ACTIVITIES}>
                  <ActivityWrapper width="100%" disabled={!Boolean(projectKind)}>
                    <ActivityField name="activity" label="Activité" projectKind={projectKind} readOnly={!canEdit} />
                  </ActivityWrapper>
                </Permission>
                {(showSelectProject || phase?.isDuplicate) && (
                  <GridItem width="100%">
                    <ProjectField
                      name="project"
                      required
                      isClearable={false}
                      readOnly={!canEdit}
                      trailingTooltip="Voir le détail du projet"
                      onTrailingIconClick={() => {
                        window.open(`${window.location.origin}/projets/${values.project?.id}`, '_blank');
                      }}
                      iconTrailing={Boolean(values.project?.id) ? IcExternalLink : null}
                    />
                  </GridItem>
                )}
                {!hideTeam && (
                  <Permission feature={features.PLANNING_TEAMS}>
                    <GridItem width="50%">
                      <TeamField name="team" readOnly={!canEdit} label="Équipe" optionEditable={false} />
                    </GridItem>
                  </Permission>
                )}
                <Permission feature={[features.PLANNING_MEMBERS, features.PLANNING_CALENDAR]}>
                  <GridItem width="50%">
                    <UserField
                      name="users"
                      label={translate({
                        value: 'phase.user',
                        fallback: 'Personnel assigné',
                        plural: true
                      })}
                      readOnly={!canEdit}
                      role={['technician', 'admin', 'conductor']}
                      multi
                    />
                  </GridItem>
                </Permission>
                <Grid padded={false} spacing={16}>
                  <GridItem width="40%">
                    <DatePickerField
                      label="Date de début"
                      name="startAt"
                      disableTime={false}
                      hourIncrement={hourIncrement}
                      readOnly={
                        !canEdit ||
                        !hasPermission('phase.update', {
                          target: phase,
                          attribute: 'dates'
                        })
                      }
                      minuteIncrement={minuteIncrement}
                      isClearable={false}
                    />
                  </GridItem>
                  <GridItem width="40%">
                    <DatePickerField
                      label="Date de fin"
                      name="endAt"
                      disableTime={false}
                      hourIncrement={hourIncrement}
                      minuteIncrement={minuteIncrement}
                      isEndDate
                      readOnly={
                        !canEdit ||
                        !hasPermission('phase.update', {
                          target: phase,
                          attribute: 'dates'
                        })
                      }
                      isClearable={false}
                    />
                  </GridItem>
                  <GridItem width="20%" minWidth={140}>
                    <CheckboxField name="night" label="Intervention de nuit" readOnly={!canEdit} />
                  </GridItem>
                </Grid>
                <CustomFields name="customData" fieldableType="Phase" readOnly={!canEdit} />
                <GridItem width="100%">
                  <TextInputField
                    textArea
                    name="description"
                    label="Description / Informations"
                    readOnly={!canEdit}
                    minRows={3}
                    hint={
                      descriptionUpdatedAt
                        ? `dernière mise à jour: ${formatDistanceToNow(descriptionUpdatedAt, {
                            addSuffix: true,
                            locale: fr
                          })} (${printDate(descriptionUpdatedAt, "dd/MM/yyyy 'à' HH:mm")})`
                        : ''
                    }
                  />
                </GridItem>
              </Grid>
            </GridItem>
            <GridItem width="50%" minWidth={550}>
              <Grid spacing={16} padded={false}>
                <GridItem width="100%">
                  <SectionTitle>Lieu d'intervention</SectionTitle>
                </GridItem>
                <GridItem width="100%">
                  <AddressField name="address" mapHeight={180} readOnly={!canEdit} />
                </GridItem>
              </Grid>
            </GridItem>
            <Permission feature={[features.PLANNING_MACHINES]}>
              <SectionGridItem width="100%">
                <Grid spacing={16} padded={false}>
                  <GridItem width="100%">
                    <MachineTable
                      {...{
                        id: values.id,
                        machinePhases: values.machinePhases
                      }}
                      readOnly={!canEdit}
                      onChange={(machinePhases) => setFieldValue('machinePhases', machinePhases)}
                    />
                  </GridItem>
                </Grid>
              </SectionGridItem>
            </Permission>
            <Permission feature={[features.MATERIALS]}>
              <SectionGridItem width="100%">
                <Grid spacing={16} padded={false}>
                  <GridItem width="100%">
                    <ExtractionTable
                      {...{
                        phaseId: values.id,
                        extractionItems: values.extractionItems
                      }}
                      readOnly={!canEdit}
                      onChange={(extractionItems) => setFieldValue('extractionItems', extractionItems)}
                    />
                  </GridItem>
                </Grid>
              </SectionGridItem>
              <SectionGridItem width="100%">
                <Grid spacing={16} padded={false}>
                  <GridItem width="100%">
                    <InputTable
                      {...{
                        phaseId: values.id,
                        inputItems: values.inputItems
                      }}
                      readOnly={!canEdit}
                      onChange={(inputItems) => setFieldValue('inputItems', inputItems)}
                    />
                  </GridItem>
                </Grid>
              </SectionGridItem>
            </Permission>
            <Permission feature={[features.ORDERS_COATING]}>
              <SectionGridItem width="100%">
                <Grid spacing={16} padded={false}>
                  <GridItem width="100%">
                    <ProductOrderTable
                      {...{
                        id: values.id,
                        startAt: values.startAt,
                        endAt: values.endAt,
                        orders: values.orders
                      }}
                      readOnly={!canEdit}
                      onChange={(orders) => setFieldValue('orders', orders)}
                    />
                  </GridItem>
                </Grid>
              </SectionGridItem>
            </Permission>

            <Permission feature={[features.ORDERS_DELIVERIES]}>
              <SectionGridItem width="100%">
                <Grid spacing={16} padded={false}>
                  <GridItem width="100%">
                    <DeliveryTable
                      {...{
                        id: values.id,
                        startAt: values.startAt,
                        endAt: values.endAt,
                        carrierOrders: values.carrierOrders
                      }}
                      readOnly={!canEdit}
                      onChange={(carrierOrders) => setFieldValue('carrierOrders', carrierOrders)}
                    />
                  </GridItem>
                </Grid>
              </SectionGridItem>
            </Permission>
            {phaseId && (
              <Permission feature={[features.ATTACHMENTS]}>
                <GridItem width="100%">
                  <Grid spacing={16} padded={false}>
                    <SectionGridItem width="100%">
                      <AttachmentBox
                        attachments={values.attachments}
                        flat
                        maxHeight={180}
                        readOnly={!canEdit}
                        minHeight={100}
                        parentId={phaseId}
                        parentModel="Phase"
                      />
                    </SectionGridItem>
                  </Grid>
                </GridItem>
              </Permission>
            )}
          </Grid>
        );
      }}
    </EntityModal>
  );
};

export default PhaseModal;

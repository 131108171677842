import React, { Fragment } from 'react';
import Switch from 'react-switch';
import { css, createGlobalStyle } from 'styled-components';
import { theme } from 'ui';
import { MdClose, MdCheck } from 'react-icons/md';

export const Styles = createGlobalStyle`
  .react-switch-handle {
    height: calc(100% - 2px) !important;
  }

  .react-switch-bg, .react-switch-handle {
    border-radius: ${({ theme }) => theme.borderButtonRadius} !important;
  }

  transition: 300ms ease;
  transition-property: box-shadow, height, width, background, background-color;
  
  .react-switch-bg {
    height: 100% !important;

    background-color: ${props =>
      props.onlyCheck &&
      css`
        ${theme.primaryLight} !important
      `};

    &:hover {
      box-shadow: ${({ theme }) => theme.boxShadow} !important;
    }
  }
`;

const checkedIcon = (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      fontSize: 19,
      color: '#ffffff',
      paddingRight: 2
    }}
  >
    <MdCheck />
  </div>
);

const uncheckedIcon = (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      fontSize: 19,
      color: '#ffffff',
      paddingRight: 2
    }}
  >
    <MdClose />
  </div>
);
const SwitchButton = ({
  onChange,
  onlyCheck = false,
  checked,
  value,
  disabled
}) => {
  return (
    <Fragment>
      <Styles onlyCheck={onlyCheck} />
      <Switch
        checked={value || checked || false}
        disabled={disabled}
        onChange={onChange}
        height={33}
        width={60}
        onColor={theme.primaryLight}
        uncheckedIcon={onlyCheck ? checkedIcon : uncheckedIcon}
        checkedIcon={checkedIcon}
      />
    </Fragment>
  );
};

export default SwitchButton;

import { groupBy, values, uniq, sortBy, uniqWith, omit } from 'lodash';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import flatten from 'lodash/flatten';
import intersection from 'lodash/intersection';
import { phaseResources } from 'features/Phases';
import { getPlural } from 'common';

const moment = extendMoment(Moment);

const initialState = {
  phasesIds: [],
  projectsIds: [],
  holidaysIds: [],
  exceptionalPeriodsIds: [],
  emptyProjectIds: [],
  searchProjectIds: null,
  searchEmptyProjectIds: [],
  teamsIds: [],
  usersIds: [],
  machinesIds: [],
  overlaps: {},
  overlapsToUpdate: [],
  isLoading: false,
  loadingCount: 0,
  isUpdating: false
};

const plannings = (state = initialState, action) => {
  const { payload, type } = action;
  let loadingCount = state.loadingCount;
  switch (type) {
    case 'PLANNING_LOADING':
      const newCount = payload ? loadingCount + 1 : loadingCount - 1;
      return {
        ...state,
        loadingCount: newCount,
        isLoading: newCount !== 0
      };
    case 'PLANNING_UPDATING':
    case 'NEW_PHASE_LOADING':
      return {
        ...state,
        isUpdating: true
      };
    case 'DELETE_PHASE_SUCCESS':
      return {
        ...state,
        overlaps: clearOverlapOnDelete(state.overlaps, {
          typedId: `phase#${payload}`
        })
      };
    case 'DELETE_HOLIDAY_SUCCESS':
      return {
        ...state,
        overlaps: clearOverlapOnDelete(state.overlaps, {
          typedId: `holiday#${payload}`
        })
      };
    case 'PLANNING_UPDATE_EVENT_SUCCESS':
    case 'UPDATE_PHASE_SUCCESS':
    case 'UPDATE_HOLIDAY_SUCCESS':
      return {
        ...state,
        isUpdating: false,
        overlapsToUpdate: payload.data
          ? [
              ...state.overlapsToUpdate,
              {
                type: payload.data.type,
                id: payload.data.id
              }
            ]
          : state.overlapsToUpdate
      };
    case 'PLANNING_SEARCH_PROJECTS':
      return {
        ...state,
        loadingCount: loadingCount - 1,
        isLoading: loadingCount > 1,
        searchProjectIds: payload.data.map(d => d.id)
      };
    case 'PLANNING_EMPTY_PROJECTS_SUCCESS':
      return {
        ...state,
        emptyProjectIds: payload.data.map(d => d.id)
      };
    case 'PLANNING_SEARCH_EMPTY_PROJECTS_SUCCESS':
      return {
        ...state,
        searchEmptyProjectIds: payload.data.map(d => d.id)
      };
    case 'PLANNING_CLEAR_SEARCH_PROJECTS':
      return {
        ...state,
        searchProjectIds: null,
        searchEmptyProjectIds: []
      };
    case 'NEW_HOLIDAY_SUCCESS':
      return {
        ...state,
        isUpdating: false,
        holidaysIds: [payload.data.id, ...state.holidaysIds],
        overlapsToUpdate: [
          ...state.overlapsToUpdate,
          {
            type: payload.data.type,
            id: payload.data.id
          }
        ]
      };
    case 'NEW_USER_SUCCESS':
      return {
        ...state,
        isUpdating: false,
        usersIds: [payload.data.id, ...state.usersIds]
      };
    case 'NEW_MACHINE_SUCCESS':
      return {
        ...state,
        isUpdating: false,
        machinesIds: [payload.data.id, ...state.machinesIds]
      };
    case 'NEW_TEAM_SUCCESS':
      return {
        ...state,
        isUpdating: false,
        teamsIds: [payload.data.id, ...state.teamsIds]
      };
    case 'NEW_PROJECT_SUCCESS':
      return {
        ...state,
        isUpdating: false,
        emptyProjectIds: [...state.emptyProjectIds, parseInt(payload.data.id)],
        searchProjectIds:
          state.searchProjectIds?.length > 0
            ? [...state.searchProjectIds, parseInt(payload.data.id)]
            : null
      };
    case 'NEW_PHASE_SUCCESS':
      return {
        ...state,
        isUpdating: false,
        phasesIds: [payload.data.id, ...state.phasesIds],
        overlapsToUpdate: [
          ...state.overlapsToUpdate,
          {
            type: payload.data.type,
            id: payload.data.id
          }
        ]
      };
    case 'PLANNING_DATA_SUCCESS':
      const group = groupBy(payload.data, 'type');

      const phases = (group['phase'] || []).map(
        ({ id, attributes, relationships }) => ({
          id,
          ...attributes,
          projectId: relationships?.project?.data?.id,
          teamId: relationships?.team?.data?.id
        })
      );

      // const now = moment().startOf('day');
      const phasesGrouped = groupBy(
        sortBy(
          phases, //.filter(p => moment(p.startAt).startOf('day') >= now),
          phase => phase.startAt
        ),
        phase => phase.teamId
      );
      const projectsIds = uniq(
        flatten(values(phasesGrouped))
          .map(phase => phase?.projectId)
          .filter(Boolean)
      );
      return {
        ...state,
        phasesIds: phases.map(phase => phase.id),
        projectsIds: projectsIds,
        exceptionalPeriodsIds: (group['exceptionalPeriod'] || []).map(
          period => period.id
        ),
        holidaysIds: (group['holiday'] || []).map(holiday => holiday.id),
        loadingCount: loadingCount - 1,
        isLoading: loadingCount > 1,
        overlaps: initEventsOverlap({}, payload.data, true)
      };
    case 'PLANNING_UPDATE_OVERLAPS':
      return updateOverlaps(state, payload);
    case 'PLANNING_TEAMS_SUCCESS':
      return {
        ...state,
        teamsIds: payload.data.map(d => d.id),
        loadingCount: loadingCount - 1,
        isLoading: loadingCount > 1
      };
    case 'PLANNING_USERS_SUCCESS':
      return {
        ...state,
        usersIds: payload.data.map(d => d.id),
        loadingCount: loadingCount - 1,
        isLoading: loadingCount > 1
      };
    case 'PLANNING_MACHINES_SUCCESS':
      return {
        ...state,
        machinesIds: payload.data.map(d => d.id),
        loadingCount: loadingCount - 1,
        isLoading: loadingCount > 1
      };
    case 'PLANNING_OVERLAPS_UPDATED':
      return {
        ...state,
        overlaps: payload
      };
    case 'LOGOUT':
    case 'SELECT_ACCOUNT':
      return initialState;
    default:
      return state;
  }
};

export const updateOverlaps = (state, { overlapsToUpdate, globalState }) => {
  if (overlapsToUpdate.length === 0) {
    return state;
  }
  const newOverlaps = {
    ...state.overlaps
  };
  const eventsToUpdate = uniqWith(
    overlapsToUpdate,
    event => `${event.type}#${event.id}`
  );
  const phases = state.phasesIds
    .map(id => {
      const phase = globalState.phases.phaseById[id];
      if (phase) {
        return {
          ...phase,
          type: 'phase',
          range: moment.range(phase.startAt, phase.endAt),
          typedId: `phase#${phase.id}`
        };
      } else {
        return null;
      }
    })
    .filter(Boolean);

  const holidays = state.holidaysIds
    .map(id => {
      const holiday = globalState.holidays.holidayById[id];
      if (holiday) {
        return {
          ...holiday,
          type: 'holiday',
          range: moment.range(holiday.startAt, holiday.endAt),
          typedId: `holiday#${holiday.id}`
        };
      } else {
        return null;
      }
    })
    .filter(Boolean);

  eventsToUpdate.forEach(({ type, id }) => {
    const event = globalState[getPlural(type)][`${type}ById`][id];
    event.range = moment.range(event.startAt, event.endAt);
    event.typedId = `${type}#${id}`;
    phases.forEach(phase => {
      initOverlaps(newOverlaps, event, phase, true);
    });
    holidays.forEach(holiday => {
      initOverlaps(newOverlaps, event, holiday, true);
    });
  });
  return {
    ...state,
    overlaps: newOverlaps,
    overlapsToUpdate: []
  };
};

export const initEventsOverlap = (overlaps, events, isJsonApi) => {
  const resourceableEvents = events.filter(event => {
    switch (event.type) {
      case 'holiday':
      case 'phase': {
        if (isJsonApi) {
          event.typedId = `${event.type}#${event.id}`;
          event.range = moment.range(
            event.attributes.startAt,
            event.attributes.endAt
          );
        } else {
          event.typedId = event.id;
          event.range = moment.range(event.startAt, event.endAt);
        }
        return true;
      }
      default:
        return false;
    }
  });
  const eventsCount = resourceableEvents.length;
  for (var i = 0; i < eventsCount; i++) {
    for (var j = i + 1; j < eventsCount; j++) {
      initOverlaps(
        overlaps,
        resourceableEvents[i],
        resourceableEvents[j],
        isJsonApi
      );
    }
  }
  return overlaps;
};

const getResourceIds = (resource, event, isJsonApi) => {
  const relation = isJsonApi
    ? event.relationships[resource]?.data
    : event[resource];
  if (!relation) {
    return [];
  }
  if (Array.isArray(relation)) {
    return relation.map(d => d.id);
  } else {
    return relation ? [relation.id] : [];
  }
};

const getProjectId = event =>
  event.relationships.project?.id ||
  event.attributes?.projectId ||
  event.projectId ||
  event.project?.id;

export const initOverlaps = (overlaps, event1, event2, isJsonApi) => {
  let ids1 = [];
  let ids2 = [];
  let intersectedIds = [];
  phaseResources.forEach(resource => {
    clearOverlap(overlaps, resource, event1, event2);
    ids1 = getResourceIds(resource, event1, isJsonApi);
    if (ids1.length !== 0) {
      ids2 =
        event2.typedId !== event1.typedId
          ? getResourceIds(resource, event2, isJsonApi)
          : [];
      intersectedIds = intersection(ids1, ids2);
      if (intersectedIds.length > 0) {
        if (
          event1.range &&
          event2.range &&
          event1.range.overlaps(event2.range)
        ) {
          addOverlap(
            overlaps,
            resource,
            intersectedIds,
            event1,
            event2,
            isJsonApi
          );
        }
      }
    }
  });
};

const addOverlap = (
  overlaps,
  resource,
  resourceIds,
  event1,
  event2,
  isJsonApi
) => {
  if (!overlaps[event1.typedId]) {
    overlaps[event1.typedId] = {};
  }
  if (!overlaps[event1.typedId][resource]) {
    overlaps[event1.typedId][resource] = [];
  }
  if (!overlaps[event2.typedId]) {
    overlaps[event2.typedId] = {};
  }
  if (!overlaps[event2.typedId][resource]) {
    overlaps[event2.typedId][resource] = [];
  }
  overlaps[event1.typedId] = {
    ...overlaps[event1.typedId],
    [resource]: [
      ...overlaps[event1.typedId][resource],
      {
        ids: resourceIds,
        event: {
          id: event2.typedId,
          displayName: event2.displayName,
          projectId: getProjectId(event2, isJsonApi)
        }
      }
    ]
  };

  overlaps[event2.typedId] = {
    ...overlaps[event2.typedId],
    [resource]: [
      ...overlaps[event2.typedId][resource],
      {
        ids: resourceIds,
        event: {
          id: event1.typedId,
          displayName: event1.displayName,
          projectId: getProjectId(event1, isJsonApi)
        }
      }
    ]
  };
};

const clearOverlapOnDelete = (overlaps, event) => {
  const updated = omit(overlaps, event.typedId);
  Object.keys(updated).forEach(id => {
    if (id !== event.typedId) {
      phaseResources.forEach(resource => {
        clearOverlap(updated, resource, event, {
          typedId: id
        });
      });
    }
  });
  return updated;
};

const clearOverlap = (overlaps, resource, event1, event2) => {
  if (overlaps[event1.typedId] && overlaps[event1.typedId][resource]) {
    overlaps[event1.typedId] = {
      ...overlaps[event1.typedId],
      [resource]: overlaps[event1.typedId][resource].filter(
        r => r.event?.id !== event2.typedId
      )
    };
  }

  if (overlaps[event2.typedId] && overlaps[event2.typedId][resource]) {
    overlaps[event2.typedId] = {
      ...overlaps[event2.typedId],
      [resource]: overlaps[event2.typedId][resource].filter(
        r => r.event?.id !== event1.typedId
      )
    };
  }
};

export default plannings;

import React, {
  useCallback,
  useMemo,
  Fragment,
  useState,
  useEffect
} from 'react';
import { SelectField } from 'components/v3/Form';
import { SelectInputLayout } from 'components/v3/InputLayout';
import { useRedux } from 'components/v3/utils';
import CarriageTypeModal from './CarriageTypeModal';
import { jsonApiToObject } from 'common/api';
import {
  CarrierSelector,
  CarrierAction,
  carriageTypeAsOption
} from 'features/Carriers';
import { useAccountConfig } from 'config';

const formatCreateLabel = input => `Créer le type de camion ${input}`;

const CarriageTypeSelect = ({ value, onChange, ...props }) => {
  const { accountId } = useAccountConfig();
  const [carriageTypeModal, setCarriageTypeModal] = useState();
  const [carriageTypes, fetch] = useRedux(
    CarrierSelector.selectCarriageTypes,
    CarrierAction.getCarriageTypes,
    {
      fetchOnMount: false,
      useLocalState: true,
      initialParams: {
        page: 1,
        perPage: 50,
        useCache: true
      }
    }
  );

  const handleCreateOption = useCallback(input => {
    setCarriageTypeModal({
      displayName: input
    });
  }, []);

  const handleEditOption = useCallback(value => {
    setCarriageTypeModal(value);
  }, []);

  const options = useMemo(() => carriageTypeAsOption(carriageTypes), [
    carriageTypes
  ]);

  const currentValue = useMemo(() => carriageTypeAsOption(value), [value]);

  useEffect(() => {
    fetch(params => params);
  }, [accountId, fetch]);

  return (
    <Fragment>
      <SelectInputLayout
        label="Type de camion"
        value={currentValue}
        options={options}
        isSearchable
        onChange={onChange}
        inputCreatable
        createLabel="Créer un type de camion"
        formatCreateLabel={formatCreateLabel}
        onCreateOption={handleCreateOption}
        optionEditable
        onEditOptionClick={handleEditOption}
        {...props}
      />
      {carriageTypeModal && (
        <CarriageTypeModal
          carriageType={carriageTypeModal}
          onRequestClose={() => setCarriageTypeModal(null)}
          onSubmitSuccess={response => {
            const carriageType = jsonApiToObject(response);
            if (props.multi) {
              onChange([...currentValue, carriageType]);
            } else {
              onChange(carriageType);
            }
          }}
        />
      )}
    </Fragment>
  );
};

export const CarriageTypeField = props => {
  return <SelectField component={CarriageTypeSelect} {...props} />;
};

export default CarriageTypeSelect;

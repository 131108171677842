import { get, put, post, destroy } from 'common/api';

export const getReportKinds = params => get('/v5/report_kinds', params);

export const getReports = params => get('/v5/reports', params);

export const createReport = (report, config) =>
  post('/v5/reports', { report }, config);

export const getReport = (id, config) => get(`/v5/reports/${id}`, config);

export const putReport = (id, report, config) =>
  put(`/v5/reports/${id}`, { report }, config);

export const deleteReport = (id, config) =>
  destroy(`/v5/reports/${id}`, config);

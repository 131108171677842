import React from 'react';
import styled, { css } from 'styled-components';
import { isEqual } from 'lodash';
import { Dashed, DebouncedInput, GridItem } from 'components/v3/ui';
import DragHandle from './DragHandle';
import { ServiceSelect, TaxSelect, UnitSelect } from 'features/Accounting';
import { InputLayoutVariant } from 'components/v3/InputLayout';
import isSameIds from 'utils/idUtils';
import { CurrentOption } from 'components/v3/Select/Select';
import { useAccountConfig, features } from 'config';

const OptionMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  min-width: 48px;
  align-self: center;
`;

const Cell = styled(Dashed).attrs(({ width, style }) => ({
  style: {
    width,
    ...(style || {})
  }
}))`
  &:not(:last-child) {
    margin-right: 2px;
    margin-left: 2px;
  }
`;

const RowWrapper = styled.div`
  width: 100%;
  display: flex;

  * > ${Dashed} {
    width: 100%;
    min-height: 30px;
  }
  
  .line_content {
    flex-grow: 1;
  }

  .line {
    display: flex;
    flex-wrap: nowrap;
    flex-grow: 1;
    border-left: 1px solid ${({ theme }) => theme.separator};
    border-right: 1px solid ${({ theme }) => theme.separator};
    border-bottom: 1px solid ${({ theme }) => theme.separator};
  }

  .financial {
    display: flex;
    flex-wrap: nowrap;
  }


  > input,
  > textarea,
  * > input,
  * > textarea {
    padding-left: 4px;
    padding-right: 4px;
    padding-top: 6px;
    padding-bottom 6px;
    font-size: 0.875rem !important;
    font-weight: 600;
  }
  ${CurrentOption} {
    span {
      font-weight: 600;
      font-size: 0.875rem !important;
    }
  }

  * > label {
    left: 3px;
    font-size: 0.875rem !important;
  }
  
  * > *:not(textarea) {
    min-height: unset !important;
  }

  * > textarea {
    min-height: 30px !important;
  }

  ${({ divider }) =>
    divider &&
    css`
      background-color: #f2f2f2;
    `}
`;

const TaxWrapper = styled.div`
  * > ${CurrentOption} {
    > * {
      width: 100%;
    }
  }
`;

const SimpleInput = styled.input`
  min-height: 30px;
  font-weight: 600;
`.withComponent(DebouncedInput);

const NoteArea = styled(Dashed)`
  margin: 4px;
  margin-top: ${({ divider }) => (divider ? '4px' : '2px')};
  width: calc(100% - 4px) !important;
  textarea {
    font-weight: 400;
  }

  ${({ divider }) =>
    divider &&
    css`
      textarea {
        font-weight: 600;
      }
    `}

  textarea {
    min-height: 30px;
    font-size: 0.875rem !important;
    padding: 6px 4px 6px 4px;
  }
`;

const InvoiceLine = ({ item, index, groupId, projectKindId, onChange, onChanged, readOnly, optionMenu, ...rest }) => {
  const isDivider = item.kind === 'divider' ? 'true' : undefined;
  const { hasFeature } = useAccountConfig();
  const hasPriceDivider = isDivider && hasFeature(features.INVOICE_DIVIDERS_SUBTOTAL);

  return (
    <RowWrapper divider={isDivider} hasDescription={Boolean(item.description)} readOnly={readOnly} {...rest}>
      {!readOnly && <DragHandle />}
      {readOnly && <div style={{ background: 'white', width: 32, minWith: 32 }}></div>}
      <div className="line">
        <div className="line_content">
          {!isDivider && (
            <div className="financial">
              <Cell width="48%">
                <ServiceSelect
                  value={{
                    id: item.serviceId || item.service?.id,
                    displayName: item.displayName,
                    price: item.unitPrice || 0,
                    taxRate: item.taxRate,
                    description: item.description,
                    unit: item.unit?.displayName || '',
                    unitId: item.unit?.id || '',
                    label: item.displayName
                  }}
                  variant={InputLayoutVariant.flat.medium}
                  required
                  fillWidth
                  freeSolo
                  fetchOnMount={false}
                  textArea
                  groupId={groupId}
                  projectKindId={projectKindId}
                  isClearable={false}
                  optionEditable={false}
                  inputCreatable={false}
                  onInputChange={(displayName) => {
                    if (item.displayName !== displayName) {
                      onChanged({
                        ...item,
                        displayName
                      });
                    }
                  }}
                  onChange={(service) => {
                    onChanged({
                      ...item,
                      service: undefined,
                      displayName: service?.reference
                        ? `${service.reference} - ${service.displayName}`
                        : service?.displayName,
                      serviceId: service?.id,
                      unitId: service?.unit?.id || '',
                      unit: service?.unit?.displayName || '',
                      unitPrice:
                        parseFloat(
                          service?.groupServices?.find((gs) => isSameIds(gs.groupId, groupId))?.price || service?.price
                        ) || 0,
                      taxRate: parseFloat(service?.taxRate) || 0,
                      description: service?.description,
                      quantity: item.quantity != null ? item.quantity : 1
                    });
                  }}
                />
              </Cell>
              <Cell width="8%">
                <SimpleInput
                  variant={InputLayoutVariant.flat.medium}
                  number
                  value={item.quantity != null ? item.quantity : 1}
                  fillWidth
                  readOnly={readOnly}
                  label=""
                  textAlign="end"
                  onChange={(quantity) => {
                    onChanged({
                      ...item,
                      quantity
                    });
                  }}
                />
              </Cell>
              <Cell width="12%">
                <UnitSelect
                  variant={InputLayoutVariant.flat.medium}
                  currency
                  isClearable
                  optionEditable={false}
                  fetchOnMount={false}
                  value={
                    item.unit
                      ? {
                          id: item.unitId,
                          displayName: item.unit
                        }
                      : null
                  }
                  readOnly={readOnly}
                  fillWidth
                  label=""
                  onChange={(unit) =>
                    onChanged({
                      ...item,
                      unit: unit?.displayName || '',
                      unitId: unit?.id || null
                    })
                  }
                />
              </Cell>
              <Cell width="12%">
                <SimpleInput
                  variant={InputLayoutVariant.flat.medium}
                  currency
                  value={item.unitPrice}
                  fillWidth
                  textAlign="end"
                  readOnly={readOnly}
                  label=""
                  onChange={(unitPrice) => {
                    onChanged({
                      ...item,
                      unitPrice
                    });
                  }}
                />
              </Cell>
              <GridItem width="12%">
                <SimpleInput
                  variant={InputLayoutVariant.flat.medium}
                  currency
                  value={item.total}
                  fillWidth
                  readOnly
                  disableEvents
                  textAlign="end"
                  label=""
                />
              </GridItem>
              <Cell width="8%">
                <TaxWrapper>
                  <TaxSelect
                    variant={InputLayoutVariant.flat.medium}
                    value={item.taxRate}
                    fillWidth
                    isClearable={false}
                    optionEditable={false}
                    label=""
                    readOnly={readOnly}
                    onChange={(taxRate) =>
                      onChanged({
                        ...item,
                        taxRate
                      })
                    }
                  />
                </TaxWrapper>
              </Cell>
            </div>
          )}
          <NoteArea divider={isDivider}>
            <DebouncedInput
              readOnly={readOnly}
              placeholder={isDivider ? 'Nom de la séparation' : 'Description'}
              label={isDivider ? 'Nom de la séparation' : 'Description'}
              value={item.description || ''}
              textArea
              debounced
              style={hasPriceDivider ? { width: '80%' } : {}}
              onChangeDebounce={(description) => {
                onChanged({
                  ...item,
                  description
                });
              }}
            />
            {hasPriceDivider && (
              <>
                <GridItem width="12%" style={{ marginLeft: 'auto' }}>
                  <SimpleInput
                    variant={InputLayoutVariant.flat.medium}
                    currency
                    value={item.total}
                    fillWidth
                    readOnly
                    disableEvents
                    textAlign="end"
                    label=""
                  />
                </GridItem>
                <GridItem width="8%" />
              </>
            )}
          </NoteArea>
        </div>
        <OptionMenu>{optionMenu({ item, index })}</OptionMenu>
      </div>
    </RowWrapper>
  );
};

export default React.memo(InvoiceLine, isEqual);

import React, { useCallback, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { SVGOverlay } from 'react-map-gl';
import { withOpacity } from 'utils';

const round = (x, n) => {
  const tenN = Math.pow(10, n);
  return Math.round(x * tenN) / tenN;
};

const PolylineGroup = styled.g`
  .polyline-hover {
    display: none;
  }

  ${props =>
    props['data-editable'] &&
    css`
      cursor: pointer;
      pointer-events: mouseover;

      &:hover {
        .polyline-hover {
          display: block;
        }
      }
    `}

  ${props =>
    props['data-active'] &&
    css`
      .polyline-hover {
        display: block;
      }
    `}
`;

const Polyline = styled.polyline`
  stroke-width: 16px;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
  cursor: pointer;
`;

const drawPolyline = ({ id, points, color, active, editable }) => {
  return (
    <PolylineGroup
      id={id}
      key={id}
      data-editable={editable}
      data-active={active}
    >
      {(active || editable) && (
        <Polyline
          className="polyline-hover"
          points={`${points.join(' ')}`}
          style={{
            stroke: withOpacity(color, 0.3)
          }}
        />
      )}
      <Polyline
        points={`${points.join(' ')}`}
        style={{
          stroke: withOpacity(color, 0.7),
          strokeWidth: 6
        }}
      />
    </PolylineGroup>
  );
};

const PolylineOverlay = ({
  routes,
  activeRouteId,
  editable,
  useRouteWaypointsApi
}) => {
  const activeEditingRoute = useMemo(() => {
    if (useRouteWaypointsApi || !activeRouteId || !editable) {
      return [];
    }
    const route = routes?.find(r => r.id === activeRouteId);
    if (!route) {
      return [];
    }
    const lines = [];
    const pointsLength = route.directions.length;
    const points = route.directions;
    points.forEach((p, index) => {
      if (index < pointsLength - 1) {
        lines.push({
          points: [p, points[index + 1]],
          routeId: route.id,
          color: route.color
        });
      }
    });
    return lines;
  }, [routes, editable, activeRouteId, useRouteWaypointsApi]);

  const draw = useCallback(
    ({ project }) => {
      const polylines = [];
      let formattedPoints;
      routes
        .filter(r => {
          if (!editable) {
            return true;
          } else {
            return useRouteWaypointsApi || r.id !== activeRouteId;
          }
        })
        .forEach(r => {
          formattedPoints = (r.directions || [])
            .map(p => [round(p[1], 5), round(p[0], 5)])
            .map(project)
            .map(p => [round(p[0], 1), round(p[1], 1)]);

          const active = activeRouteId === r.id;

          polylines.push(
            drawPolyline({
              id: `handled-route-${r.id}`,
              points: formattedPoints,
              color: r.color,
              editable,
              active
            })
          );
        });

      activeEditingRoute.forEach(line => {
        formattedPoints = (line.points || [])
          .map(p => [round(p[1], 5), round(p[0], 5)])
          .map(project)
          .map(p => [round(p[0], 1), round(p[1], 1)]);

        const routeId = line.routeId;
        const color = line.color;
        const p1 = line.points[0];
        const p2 = line.points[1];

        polylines.push(
          drawPolyline({
            id: `handled-route-${routeId}#${p1}:${p2}`,
            points: formattedPoints,
            color: color,
            active: true,
            editable
          })
        );
      });
      return polylines;
    },
    [useRouteWaypointsApi, activeEditingRoute, routes, editable, activeRouteId]
  );

  if (!routes) {
    return null;
  }
  return <SVGOverlay redraw={draw} />;
};

export default PolylineOverlay;

import React, { useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { MdArrowDropDown } from 'react-icons/md';
import Tag from 'components/v3/Tag';
import { AccountSelector, jobAsOption } from 'features/Accounts';
import {
  SelectInputLayout,
  InputLayoutVariant
} from 'components/v3/InputLayout';
import { SelectField } from 'components/v3/Form';

const JobSelect = ({
  value,
  onChange,
  size,
  asTag = true,
  readOnly,
  container,
  ...props
}) => {
  const jobs = useSelector(AccountSelector.selectJobs, isEqual);

  const options = useMemo(() => jobs.map(jobAsOption), [jobs]);

  const currentValue = useMemo(
    () =>
      options.find(o => o.value === value || o.value === value?.value) ||
      jobAsOption(value),
    [value, options]
  );

  const currentOptionRenderer = useCallback(
    ({ option }) => (
      <Tag
        displayName={option.label}
        color={option.color}
        size={size}
        trailingIcon={readOnly ? null : MdArrowDropDown}
        clickable={!readOnly}
        wide
      />
    ),
    [readOnly, size]
  );

  const handleChange = useCallback(
    job => {
      if (readOnly) {
        return;
      }
      if (onChange) {
        onChange(job ? job.value : null);
      }
    },
    [readOnly, onChange]
  );

  if (!Boolean(options?.length)) {
    return null;
  }

  const content = (
    <SelectInputLayout
      label={asTag ? null : 'Emploi'}
      isClearable={!asTag}
      showIndicator={!asTag}
      value={currentValue}
      options={options}
      isSearchable={asTag ? false : undefined}
      onChange={handleChange}
      // inputCreatable
      // createLabel="Créer un groupe"
      // formatCreateLabel={formatCreateLabel}
      // onCreateOption={handleCreateOption}
      // optionEditable={!asTag}
      // onEditOptionClick={handleEditOption}
      currentOptionRenderer={asTag ? currentOptionRenderer : undefined}
      variant={asTag ? InputLayoutVariant.flat.medium : undefined}
      readOnly={readOnly}
      {...props}
    />
  );
  if (container) {
    return container(content);
  }
  return content;
};

export const JobField = props => {
  return <SelectField component={JobSelect} {...props} />;
};

export default JobSelect;

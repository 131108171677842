import React, { Fragment } from 'react';
import styled from 'styled-components';
import Tag, { TagContainer } from 'components/v3/Tag';
import Card, { CardHeader, CardContent } from 'components/v3/ui/card';
import { Text, Overline, NavLink, Icon } from 'components/v3/ui';
import State from './State';
import Tooltip from 'components/v3/Tooltip';
import { IoMdMoon } from 'react-icons/io';

const Content = styled(CardContent)`
  margin-top: 8px;
`;

const SectionTitle = styled(Overline)`
  margin-top: 8px;
  margin-bottom: 4px;
`;

const PhaseCard = ({ phase, readOnly, showResources, hiddenResource, children, hideState, ...props }) => {
  const { night, users, team, machines, orders, project, activity } = phase;

  return (
    <Card {...props}>
      <CardHeader variant="tight">
        <TagContainer alignEnd>
          {night && (
            <Icon size="small">
              <IoMdMoon />
            </Icon>
          )}
          {project && (
            <Tooltip
              content={
                <div>
                  <Text color="light">{`Projet: ${project.displayName}`}</Text>
                  {project.reference && <Overline color="lightSecondary">{project.reference}</Overline>}
                </div>
              }
            >
              <Tag
                as={NavLink}
                to={
                  window.location.pathname.includes('plannings/projets')
                    ? `projets/detail/${project.id}`
                    : `${window.location.pathname}/projets/${project.id}`
                }
                size="small"
                clickable
                displayName={project.reference || project.displayName}
                color={project.group?.color || '#8A8A8D'}
              />
            </Tooltip>
          )}
          {activity && (
            <span>
              <Tag size="small" displayName={activity?.displayName} color={activity?.color || '#8A8A8D'} />
            </span>
          )}
          {!hideState && <State phaseId={phase.id} value={phase.state} asTag readOnly={readOnly} wide={false} />}
        </TagContainer>
      </CardHeader>
      <Content>
        {phase.displayName?.trim()?.length > 0 && <Text weight="semiBold">{phase.displayName}</Text>}
        {showResources && hiddenResource !== 'teams' && team && <SectionTitle>Équipe</SectionTitle>}
        {showResources && hiddenResource !== 'teams' && team && <Text variant="small">{team.displayName}</Text>}
        {showResources && hiddenResource !== 'users' && (users || []).length > 0 && (
          <SectionTitle>Personnel assigné</SectionTitle>
        )}
        {showResources &&
          hiddenResource !== 'users' &&
          (users || []).map((user) => (
            <Text variant="small" key={user.id}>
              {user.displayName}
            </Text>
          ))}
        {showResources && hiddenResource !== 'machines' && (machines || []).length > 0 && (
          <SectionTitle>Machines</SectionTitle>
        )}
        {showResources &&
          hiddenResource !== 'machines' &&
          (machines || []).map((machine) => (
            <Text variant="small" key={machine.id}>
              {machine.displayName}
            </Text>
          ))}
        {showResources && (orders || []).length > 0 && <SectionTitle>Enrobés</SectionTitle>}
        {showResources &&
          (orders || []).map((order) => (
            <Fragment key={order.id}>
              {order.purveyor?.displayName && (
                <Text variant="small" color="caption">
                  {order.purveyor.displayName}
                </Text>
              )}
              <Text variant="small">
                {'AQP: '}
                {order.product?.aqpNumber} {`(${order.estimatedTotal || order.total || 0} t)`}
              </Text>
            </Fragment>
          ))}
        {children}
      </Content>
    </Card>
  );
};

export default PhaseCard;

import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { EntityModal } from 'components/v3/Modal';
import { Grid, GridItem } from 'components/v3/ui';
import { ProjectSelector, ProjectAction } from 'features/Projects';
import { UserField } from 'features/Users/components/v3/UserSelect';

const asValues = data => ({
  clients: data?.clients || []
});

const InvitationModal = ({ onRequestClose, projectId, ...props }) => {
  const dispatch = useDispatch();

  const selectProject = useMemo(
    () => state => {
      const project = ProjectSelector.selectProject(state, { projectId });
      if (!project) {
        return null;
      }
      return {
        clients: project.clients
      };
    },
    [projectId]
  );

  const project = useSelector(selectProject, isEqual);

  const createRequest = useCallback(
    values =>
      dispatch(
        ProjectAction.sendInvitation({
          projectId,
          payload: {
            ...values,
            clientIds: values.clients?.map(client => client.id)
          }
        })
      ),
    [dispatch, projectId]
  );

  return (
    <EntityModal
      isOpen
      onRequestClose={onRequestClose}
      title={'Envoi du projet par email'}
      entity={project}
      entityAsValue={asValues}
      createRequest={createRequest}
      autoSubmit={false}
      positiveText="Envoyer l'email"
      {...props}
    >
      {() => (
        <Grid spacing={16} padded={false}>
          <GridItem width="100%">
            <UserField
              name="clients"
              label="Destinataire de l'email"
              required
              multi
              role={['client', 'user']}
              hint="En validant un email sera envoyé aux destinataires ci-dessus avec un lien vers votre projet."
            />
          </GridItem>
        </Grid>
      )}
    </EntityModal>
  );
};

export default InvitationModal;

import React, { Fragment, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { v4 } from 'uuid';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import { SelectField, CheckboxField, EntityForm } from 'components/v3/Form';
import { Grid, Title, SectionTitle, GridItem, Button } from 'components/v3/ui';
import Box from 'components/v3/Box';
import RawScrollcer from 'components/v3/Scroller';
import SubHeader from 'components/v3/SubHeader';
import { PHASE_COLOR_OPTIONS } from 'pages/Plannings';
import { AccountSelector, AccountAction } from 'features/Accounts';
import { VIEWS } from 'pages/Plannings';
import PlanningCard from './AccountSettingsPlanningCard';
import { MdAdd } from 'react-icons/md';
import isSameIds from 'utils/idUtils';
import PlanningContent from './AccountSettingsPlanningContent';
import { get } from 'lodash';
import HolidayKinds from './HolidayKinds';
import States from './States';
import AccountSettingsPlanningFilter from './AccountSettingsPlanningFilter';
import { prepareNewAttributes } from 'common/actions';
import { features } from 'config';

const DEFAULT_FEATURES = [
  { value: 'planning_machines', label: 'Planning machines' },
  { value: 'planning_members', label: 'Planning techniciens' },
  { value: 'planning_projects', label: 'Planning projets' },
  { value: 'planning_teams', label: 'Planning équipes' },
  { value: 'planning_calendar', label: 'Calendrier' }
];

const RESOURCES = [
  { value: 'projects', label: 'Planning projets' },
  { value: 'users', label: 'Planning techniciens' },
  { value: 'teams', label: 'Planning équipes' },
  { value: 'machines', label: 'Planning machines' }
];

const formValues = [
  'id',
  'displayName',
  'availableFeatures',
  'features',
  'projectsStates',
  'phasesStates',
  'issuesStates',
  'invoiceStates',
  'jobs',
  'holidayKinds',
  'ui.planning',
  'ui.defaultFeature',
  'ui.projectList.hideArchived'
];

const prepareStates = states =>
  Array.isArray(states)
    ? states.map((state, position) => ({
        ...state,
        isNew: true,
        id: v4(),
        position
      }))
    : [];

const accountSettingsSelector = state => {
  const account = AccountSelector.selectCurrentAccount(state);
  if (!account) {
    return null;
  }
  const values = pick(account, formValues);
  return {
    ...values,
    projectsStates: prepareStates(values.projectsStates),
    phasesStates: prepareStates(values.phasesStates),
    issuesStates: prepareStates(values.issuesStates),
    invoiceStates: prepareStates(values.invoiceStates),
    holidayKinds: prepareStates(values.holidayKinds),
    jobs: prepareStates(values.jobs)
  };
};

const asValue = account => ({
  ...(account || {}),
  ui: {
    ...(account?.ui || {}),
    planning: {
      ...(account?.ui?.planning || {}),
      filters: account?.ui?.planning?.filters || [],
      views: Object.values(account?.ui?.planning?.views || {})
        .filter(view => view.name)
        .map((view, index) => {
          let formattedView = view;
          if (Object.keys(view).length === 0) {
            formattedView =
              VIEWS[Object.keys(account?.ui?.planning?.views)[index]];
          }
          return {
            ...formattedView,
            id: view.name
          };
        })
    }
  }
});

const Scroller = styled(RawScrollcer)`
  display: flex;
  flex-grow: 1;
`;

const Account = () => {
  const account = useSelector(accountSettingsSelector, isEqual);
  const dispatch = useDispatch();

  const updateRequest = useCallback(
    (id, values) => {
      let payload = omit(values, 'availableFeatures');
      return dispatch(
        AccountAction.updateSettingAccount({
          id,
          payload: {
            ...payload,
            ...prepareNewAttributes(
              payload,
              'projectsStates',
              'phasesStates',
              'issuesStates',
              'invoiceStates',
              'holidayKinds',
              'jobs'
            ),
            ui: {
              ...payload.ui,
              planning: {
                ...payload.ui.planning,
                views: payload.ui.planning.views
                  .filter(v => !v._destroy)
                  .reduce((acc, view) => {
                    acc[view.value] = view;
                    return acc;
                  }, {})
              }
            }
            // ui: {
            //   ...values.ui,
            //   planning: {
            //     ...values.ui.planning,
            //     views: {
            //       ...values.ui.planning.views,
            //       semester: {
            //         dateIncrement: {
            //           months: 1
            //         },
            //         dateDecrement: {
            //           months: -1
            //         },
            //         slotLabelFormat: [
            //           { month: 'long', year: 'numeric' },
            //           { week: 'short' },
            //           { day: 'numeric' }
            //         ],
            //         slotWidth: 20,
            //         slotDuration: '12:00:00',
            //         slotLabelInterval: '24:00:00'
            //       },
            //       year: {
            //         dateIncrement: {
            //           months: 1
            //         },
            //         dateDecrement: {
            //           months: -1
            //         },
            //         slotLabelFormat: [
            //           { month: 'long', year: 'numeric' },
            //           { week: 'short' },
            //           { day: 'numeric' }
            //         ],
            //         slotWidth: 20,
            //         slotDuration: '12:00:00',
            //         slotLabelInterval: '24:00:00',
            //         noWeekendBackground: false
            //       }
            //     }
            //   }
            // }
          }
        })
      );
    },
    [dispatch]
  );

  if (!account) {
    return null;
  }
  const availableFeatures = account.availableFeatures;
  return (
    <EntityForm
      id={account.id}
      updateRequest={updateRequest}
      entity={account}
      entityAsValue={asValue}
      autoSubmit={false}
    >
      {({
        setFieldValue,
        values,
        isSubmitting,
        setStatus,
        status,
        ...form
      }) => {
        return (
          <Fragment>
            <SubHeader isLoading={isSubmitting}>
              <Title>Paramètrage du compte {account.displayName}</Title>
            </SubHeader>
            <Scroller>
              <Box marged>
                <Grid spacing={16}>
                  <GridItem width="100%">
                    <SectionTitle>Fonctionnalités</SectionTitle>
                  </GridItem>
                  <GridItem width="100%">
                    <SelectField
                      name="features"
                      label="Fonctionnalités du compte"
                      options={availableFeatures}
                      mapOptionValue
                      multi
                    />
                  </GridItem>
                  <GridItem width="100%">
                    <CheckboxField
                      label="Cacher les projets archivés dans la liste projet"
                      name="ui.projectList.hideArchived"
                    />
                  </GridItem>
                  <GridItem width="100%">
                    <SectionTitle>Planning</SectionTitle>
                  </GridItem>
                  <GridItem width="50%">
                    <SelectField
                      name="ui.defaultFeature"
                      label="Planning par défaut"
                      options={DEFAULT_FEATURES}
                      mapOptionValue
                    />
                  </GridItem>
                  <GridItem width="50%">
                    <SelectField
                      name="ui.planning.phaseColor"
                      label="Couleur des phases"
                      options={PHASE_COLOR_OPTIONS}
                      mapOptionValue
                    />
                  </GridItem>
                  <GridItem width="50%">
                    <CheckboxField
                      label="Afficher toutes les resources planning (Attention ca affiche tous les projets)"
                      name="ui.planning.showAllResources"
                    />
                  </GridItem>
                  <GridItem width="50%">
                    <CheckboxField
                      label="Afficher les conflits"
                      name="ui.planning.showOverlap"
                    />
                  </GridItem>
                  <GridItem width="50%">
                    <CheckboxField
                      label="Afficher les week-ends"
                      name="ui.planning.showWeekend"
                    />
                  </GridItem>
                  {values.features?.includes('admin_check') && (
                    <GridItem width="50%">
                      <CheckboxField
                        label="Afficher le triangle nécessitant une validation des phases par un administrateur"
                        name="ui.planning.showPhasesChecked"
                      />
                    </GridItem>
                  )}
                  {values.features?.includes('management_holidays') && (
                    <GridItem width="50%">
                      <CheckboxField
                        label="Afficher les congés en haut"
                        name="ui.planning.holidaysTop"
                      />
                    </GridItem>
                  )}
                  {values.features?.includes('admin_check') && (
                    <GridItem width="50%">
                      <CheckboxField
                        label="Afficher le triangle nécessitant une validation des projets par un administrateur"
                        name="ui.planning.showProjectsChecked"
                      />
                    </GridItem>
                  )}
                  {values.features?.includes('split_planning_sources') && (
                    <GridItem width="50%">
                      <SelectField
                        name="ui.planning.commonSources"
                        label="Resources planning communes"
                        options={RESOURCES}
                        mapOptionValue
                        multi
                      />
                    </GridItem>
                  )}
                  {values.features?.includes(features.ORDERS_COATING) && (
                    <GridItem width="50%">
                      <CheckboxField
                        label="Afficher la différence jour / nuit sur les commandes d'enrobés."
                        name="ui.orders.show_day_and_night"
                      />
                    </GridItem>
                  )}
                  <GridItem
                    width="100%"
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <SectionTitle>Filtres planning</SectionTitle>
                    <Button
                      small
                      icon={MdAdd}
                      onClick={() =>
                        setFieldValue('ui.planning.filters', [
                          ...values.ui.planning.filters,
                          {
                            table: '',
                            column: '',
                            name: '',
                            queryName: '',
                            queryFormat: '%1$s',
                            kind: '',
                            label: '',
                            tooltip: ''
                          }
                        ])
                      }
                    >
                      Ajouter
                    </Button>
                  </GridItem>
                  <GridItem width="100%">
                    <AccountSettingsPlanningFilter
                      onChange={filters => {
                        setFieldValue('ui.planning.filters', filters);
                      }}
                      filters={values.ui.planning.filters}
                    />
                  </GridItem>
                  <GridItem width="50%">
                    <States name="projectsStates" label="États projets" />
                  </GridItem>
                  <GridItem width="50%">
                    <States name="phasesStates" label="États phases" />
                  </GridItem>
                  <GridItem width="50%">
                    <States name="issuesStates" label="États signalement" />
                  </GridItem>
                  <GridItem width="50%">
                    <States
                      name="invoiceStates"
                      label="États invoices"
                      editableCheckbox
                    />
                  </GridItem>
                  <GridItem width="50%">
                    <States name="jobs" label="Emplois" />
                  </GridItem>
                  <GridItem width="50%">
                    <HolidayKinds name="holidayKinds" />
                  </GridItem>
                  <GridItem
                    width="100%"
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <SectionTitle>Mode(s) planning</SectionTitle>
                    <Button
                      small
                      icon={MdAdd}
                      onClick={() =>
                        setFieldValue('ui.planning.views', [
                          ...values.ui.planning.views,
                          {
                            id: v4(),
                            default: values.ui.planning.views.length === 0
                          }
                        ])
                      }
                    >
                      Ajouter
                    </Button>
                  </GridItem>
                  {values.ui.planning?.views?.map(item => {
                    return (
                      <GridItem width="50%" minWidth={360} key={item.name}>
                        <PlanningCard
                          item={item}
                          onChange={({ prevId, ...value }) => {
                            setFieldValue(
                              'ui.planning.views',
                              values.ui.planning.views.map(i => {
                                if (isSameIds(i.id, prevId || value.id)) {
                                  return value;
                                } else {
                                  return i;
                                }
                              })
                            );
                          }}
                          onDelete={item =>
                            setFieldValue(
                              'ui.planning.views',
                              values.ui.planning.views.filter(
                                v => v.name !== item.name
                              )
                            )
                          }
                        />
                      </GridItem>
                    );
                  })}
                  <GridItem
                    width="100%"
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <SectionTitle>Contenus planning</SectionTitle>
                  </GridItem>
                  <PlanningContent
                    planningValues={get(values, 'ui.planning')}
                    features={values.features}
                    onChange={planning => {
                      setFieldValue('ui.planning', planning);
                    }}
                  />
                </Grid>
                <div
                  style={{
                    textAlign: 'center',
                    margin: '16px'
                  }}
                >
                  <Button disabled={!form.dirty} type="submit">
                    Enregistrer les modifications
                  </Button>
                </div>
              </Box>
            </Scroller>
          </Fragment>
        );
      }}
    </EntityForm>
  );
};

export default Account;

import React from 'react';
import styled, { css } from 'styled-components';

const Wrapper = styled.div<{ sticky: boolean }>`
  ${({ sticky }) =>
    sticky &&
    css`
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      z-index: 2;
    `}
  ${({ sticky }) =>
    !sticky &&
    css`
      z-index: 3;
    `}
`;

export type SectionProps<S> = {
  section: S | undefined;
  sticky?: boolean;
  render: (props: { section: S }) => React.ReactNode;
};

function Section<S>({ section, render, sticky }: SectionProps<S>) {
  if (!section) return null;
  return <Wrapper sticky={Boolean(sticky)}>{render({ section })}</Wrapper>;
}

export default Section;

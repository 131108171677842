import React, { memo, useCallback } from 'react';
import { LocationDescriptor } from 'history';
import styled, { css, CSSProperties } from 'styled-components';
import './item_styles.css';
import { ButtonLink } from 'components/v3/ui/button';

export const cssClickEffect = css`
  cursor: pointer;
  transition: 100ms ease;
  &:hover:not(:active):not(:disabled) {
    background-color: ${({ theme }) => theme.hoverColor};
  }
  &:active {
    background-color: ${({ theme }) => theme.hoverColorDark};
  }
  &:disabled {
    cursor: not-allowed;
    background-color: ${({ theme }) => theme.disabled};
  }
`;

const UL = styled.ul`
  list-style-type: none;
  margin: 0;
  padding-left: 0;
  padding-inline-start: 0;
`;

const LI = styled.li`
  list-style-type: none;
  width: 100%;
`;

const ItemWrapper = styled.div`
  position: relative;
  margin-right: 15px;

  a {
    > *:first-child {
      ${cssClickEffect}
    }
  }
`;

type Spacing = { top?: number; right?: number; bottom?: number; left?: number };
type GetItemProps = React.HTMLAttributes<HTMLElement> & {
  key?: string | number;
  ref?: React.RefObject<HTMLLIElement>;
};
export type ListProps<T> = {
  items: Array<T>;
  listRef?: React.RefObject<HTMLElement | undefined>;
  totalSize?: number;
  renderItemWrapper?: (props: {
    item: T;
    index: number;
    children: React.ReactNode;
    props: GetItemProps;
  }) => React.ReactNode;
  renderItem: (props: { item: T; index: number }) => React.ReactNode;
  style?: React.CSSProperties;
  getItemProps?: (props: { item: T; index: number }) => GetItemProps;
  onScroll?: React.UIEventHandler<HTMLElement>;
  to?: (props: { item: T; index: number }) => LocationDescriptor | null;
  itemSpacing?:
    | Spacing
    | ((props: { item: T; index: number }) => Spacing | undefined);
};

type ItemProps<T = any> = {
  item: T;
  index: number;
  itemMargin: (props: { item: T; index: number }) => CSSProperties | undefined;
} & Pick<ListProps<T>, 'renderItem' | 'to'>;

const Item = memo(({ item, index, renderItem, itemMargin, to }: ItemProps) => {
  const link = to ? to({ index, item }) : undefined;
  return (
    <ItemWrapper style={itemMargin({ index, item })}>
      {link && <ButtonLink to={link}>{renderItem({ item, index })}</ButtonLink>}
      {!link && renderItem({ item, index })}
    </ItemWrapper>
  );
});

function List<T>({
  items,
  listRef,
  style,
  totalSize,
  renderItemWrapper,
  renderItem,
  getItemProps,
  onScroll,
  to,
  itemSpacing,
  ...rest
}: ListProps<T>) {
  const itemMargin = useCallback(
    args => {
      let spacing: Spacing | undefined;
      if (itemSpacing instanceof Function) {
        spacing = itemSpacing(args);
      } else {
        spacing = itemSpacing;
      }
      if (!spacing) return undefined;
      return {
        marginTop: spacing.top,
        marginRight: spacing.right,
        marginBottom: spacing.bottom,
        marginLeft: spacing.left
      };
    },
    [itemSpacing]
  );

  return (
    <UL ref={listRef as any} onScroll={onScroll} style={style} {...rest}>
      {totalSize !== undefined && (
        <li key="total-size" style={{ height: totalSize }} />
      )}
      {items.map((item, index) => {
        if (renderItemWrapper) {
          return renderItemWrapper({
            item,
            index,
            props: getItemProps?.({ item, index }) ?? {},
            children: <Item {...{ item, index, renderItem, itemMargin, to }} />
          });
        } else {
          const itemProps = getItemProps?.({ item, index }) ?? {};
          return (
            <LI {...itemProps}>
              <Item {...{ item, index, renderItem, itemMargin, to }} />
            </LI>
          );
        }
      })}
    </UL>
  );
}

export default memo(List) as <T>(props: ListProps<T>) => JSX.Element;

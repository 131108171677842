import React, { useCallback, useMemo } from 'react';
import { isEqual } from 'lodash';
import { ProjectSelector } from 'features/Projects';
import { SelectField } from 'components/v3/Form';
import {
  SelectInputLayout,
  InputLayoutVariant
} from 'components/v3/InputLayout';
import { invoiceAsOption } from '..';
import { useSelector } from 'react-redux';
import { MdArrowDropDown } from 'react-icons/md';
import Tag from 'components/v3/Tag';

const InvoiceSelect = ({
  projectId,
  value,
  onChange,
  size,
  asTag = true,
  readOnly,
  ...props
}) => {
  const projectSelector = useMemo(
    () => state => ProjectSelector.selectProject(state, { projectId }),
    [projectId]
  );
  const project = useSelector(projectSelector, isEqual);
  const options = useMemo(
    () => invoiceAsOption(project?.invoices ?? []),
    [project]
  );

  const currentValue = useMemo(
    () =>
      invoiceAsOption(value) || {
        value: 'none',
        label: 'Devis/Commande/Facture associé',
        color: '#EEEEEE'
      },
    [value]
  );

  const currentOptionRenderer = useCallback(
    ({ option }) => (
      <Tag
        displayName={option.label || 'Devis/Commande/Facture associé'}
        color={option.color || '#EEEEEE'}
        size={size}
        wide
        trailingIcon={readOnly ? null : MdArrowDropDown}
        clickable={!readOnly}
      />
    ),
    [readOnly, size]
  );

  return (
    <SelectInputLayout
      label={asTag ? null : 'Devis/Commande/Facture'}
      isSearchable={!asTag}
      variant={asTag ? InputLayoutVariant.flat.medium : null}
      isClearable={false}
      showIndicator={!asTag}
      sortOptions={false}
      currentOptionRenderer={asTag ? currentOptionRenderer : null}
      readOnly={readOnly}
      value={currentValue}
      options={options}
      onChange={onChange}
      {...props}
    />
  );
};

export const InvoiceField = props => {
  return <SelectField component={InvoiceSelect} {...props} />;
};

export default InvoiceSelect;

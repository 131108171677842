import React, { Fragment } from 'react';
import styled, { css } from 'styled-components';
import { Text, Title, Icon } from 'components/v3/ui';
import { MdLocalPhone, MdEmail } from 'react-icons/md';
import { splitN } from 'utils';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;

  > {
    margin-right: 16px;
  }
`;

const Logo = styled.img`
  max-width: 150px;
  max-height: 150px;
  height: auto;
  width: 100%;
  margin-bottom: auto;
  margin-top: auto;
  margin-right: 16px;
`;

const InfoWrapper = styled.div`
  max-width: 300px;

  ${(props) =>
    props.alignEnd &&
    css`
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    `}
`;

const TextAttribute = styled.div`
  display: flex;
  ${Icon} {
    margin-right: 4px;
  }
`.withComponent(Text);

const LogoInfo = ({ disableLogo = false, logoUrl, displayName, address, email, phone, group, alignEnd, editable }) => {
  if (!displayName) {
    return null;
  }
  return (
    <Wrapper>
      {!disableLogo && Boolean(logoUrl) && <Logo src={logoUrl} />}
      <InfoWrapper alignEnd={alignEnd}>
        <Title>{displayName}</Title>
        {group && <Title secondary>{group.displayName}</Title>}
        {address && <Text>{splitN(address)}</Text>}
        {email && (
          <Fragment>
            {email && (
              <TextAttribute verticalCenter>
                <Icon size="tiny">
                  <MdEmail />
                </Icon>
                {email}
              </TextAttribute>
            )}
            {phone && (
              <TextAttribute verticalCenter>
                <Icon size="tiny">
                  <MdLocalPhone />
                </Icon>
                {phone}
              </TextAttribute>
            )}
          </Fragment>
        )}
      </InfoWrapper>
    </Wrapper>
  );
};

export default LogoInfo;

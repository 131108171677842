import React, {
  useState,
  useCallback,
  useRef,
  useMemo,
  useEffect
} from 'react';
import { useHistory, Link, Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import isEqual from 'lodash/isEqual';
import { MdNavigateBefore, MdNavigateNext } from 'react-icons/md';
import FullCalendar from '@fullcalendar/react';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import frLocale from '@fullcalendar/core/locales/fr';
import SubHeader from 'components/v3/SubHeader';
import { FilterBar } from 'components/v3/Filters';
import { Container, Title, Icon, Text, Dot } from 'components/v3/ui';
import { HolidayAction, HolidaySelector } from 'features/Holidays';
import { UserSelector, UserAction } from 'features/Users';
import { useRedux, useAction } from 'components/v3/utils';
import DragHeader from 'components/v3/Planning/Utils/DragHeader';
import ActionGroup, { Action } from 'components/v3/ActionGroup';
import DatePicker from 'components/v3/DatePicker';
import { printDateRange, formatISODate } from 'utils/dates';
import sumBy from 'lodash/sumBy';
import HolidayPrompt from 'features/Holidays/components/v3/Prompt';
import { toaster } from 'components/v3/Toast';
import {
  addMilliseconds,
  getMilliseconds,
  setMilliseconds,
  getMinutes
} from 'date-fns';
import { UserWorkRecordsModal } from 'features/Users/components/WorkRecords/UserWorkRecordsModal';
import { useRouteMatch } from 'react-router';

const plugins = [resourceTimelinePlugin, listPlugin, interactionPlugin];

const FILTERS = [
  // {
  //   kind: 'user',
  //   value: [],
  //   asQueryParam: value => ({
  //     userIdIn: value ? value.map(v => v.id) : undefined
  //   }),
  //   props: {
  //     multi: true,
  //     inputCreatable: false,
  //     optionsCheckable: true,
  //     limitValues: 2,
  //     style: {
  //       minWidth: 120,
  //       maxWidth: 360
  //     }
  //   }
  // },
];

const slotLabelFormat = [
  { year: 'numeric' },
  { month: 'long' },
  { week: 'short' },
  { weekday: 'short', day: 'numeric' },
  info => (info.date.hour === 0 ? 'mat.' : 'a-m')
];

const ResourceWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-left: 12px;
  text-decoration: none;

  span {
    margin-right: 12px;
  }
`;

const createHoliday = info => {
  const { end, start } = info;
  return {
    id: info.event ? info.event.id : null,
    // kind: 'paid_leave',
    startAt: info.event ? null : formatISODate(start.getTime()),
    endAt: info.event
      ? null
      : formatISODate(addMilliseconds(end.getTime(), -1)),
    users: info.resource
      ? [
          {
            id: info.resource.id,
            ...(info.resource.extendedProps || {})
          }
        ]
      : undefined,
    ...(info.event?.extendedProps || {}),
    bounds: info.el ? info.el.getBoundingClientRect() : null
  };
};

const Holidays = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { path } = useRouteMatch();
  const action = useAction();
  const [filters, setFilters] = useState(FILTERS);
  const calendarRef = useRef();
  const [customDates, setCustomDates] = useState();
  const [calendarDates, setCalendarDates] = useState();
  const [currentHoliday, setCurrentHoliday] = useState(null);

  const isLoadingUsers = useSelector(state => state.users.isLoading);
  const isLoading = useSelector(
    ({ holidays }) => holidays.isLoading || holidays.isUpdating
  );
  const holidayKinds = useSelector(HolidaySelector.selectHolidayKinds, isEqual);

  const [users, fetchUsers] = useRedux(
    UserSelector.selectUsers,
    UserAction.getUsers,
    {
      fetchOnMount: false,
      initialParams: {
        perPage: 500,
        page: 1
      }
    }
  );

  const [holidays, fetchHolidays] = useRedux(
    HolidaySelector.selectHolidays,
    HolidayAction.getHolidays,
    {
      fetchOnMount: false,
      initialParams: {
        perPage: 500,
        page: 1
      }
    }
  );

  const handleDatesRenderChange = useCallback(({ view }) => {
    const { activeStart, activeEnd } = view;
    setCalendarDates({
      startAt: formatISODate(activeStart.getTime()),
      endAt: formatISODate(activeEnd.getTime())
    });
  }, []);

  const handleCreateItem = useCallback(info => {
    setCurrentHoliday(createHoliday(info));
  }, []);

  const handleItemClick = useCallback(info => {
    setCurrentHoliday(createHoliday(info));
  }, []);

  const handleUpdateItem = useCallback(
    ({ event, newResource, ...info }) => {
      let endAt = event.end.getTime();
      if (getMinutes(endAt) % 15 === 0) {
        endAt = addMilliseconds(endAt, -1);
      } else if (getMilliseconds(endAt) !== 999) {
        endAt = setMilliseconds(endAt, 999);
      }
      let payload = {
        startAt: formatISODate(event.start),
        endAt: formatISODate(endAt)
      };
      if (newResource) {
        payload = { ...payload, userId: newResource.id };
      }
      action(() =>
        dispatch(
          HolidayAction.updateHoliday({
            id: event.id,
            payload
          })
        )
      ).onError(toaster.error);
    },
    [action, dispatch]
  );

  const resourceColumns = useMemo(
    () => [
      {
        headerContent: 'Nom',
        field: 'displayName',
        width: 80
      },
      ...holidayKinds.map(kind => ({
        headerContent: kind.abbr,
        field: kind.value,
        width: 15
      }))
    ],
    [holidayKinds]
  );

  const data = useMemo(() => {
    const formattedHolidays = holidays.map(holiday => {
      return {
        // ...holiday,
        id: holiday.id,
        duration: holiday.duration,
        kind: holiday.kind,
        resourceIds: holiday.users?.map(u => u.id) || [],
        title: holiday.kind?.displayName,
        start: holiday.startAt,
        end: holiday.endAt,
        color: holiday.kind?.color
      };
    });

    const formattedUsers = users
      .filter(user => !user.settings?.archived)
      .map(user => {
        // const archived = user.settings?.archived;
        // if (archived) {
        //   formattedHolidays.push({
        //     daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
        //     display: 'background',
        //     classNames: ['data-archived'],
        //     allDay: true,
        //     editable: false,
        //     resourceId: user.id
        //   });
        // }
        return {
          ...user,
          // classNames: archived ? ['data-archived'] : null,
          ...holidayKinds.reduce((acc, kind) => {
            acc[kind.value] = sumBy(
              formattedHolidays.filter(
                h =>
                  h.kind.value === kind.value && h.resourceIds.includes(user.id)
              ),
              'duration'
            );
            return acc;
          }, {})
        };
      });
    return { holidays: formattedHolidays, users: formattedUsers };
  }, [holidays, users, holidayKinds]);

  useEffect(() => {
    if (calendarDates && calendarRef.current) {
      fetchHolidays({
        'between[start_at]': calendarDates.startAt,
        'between[end_at]': calendarDates.endAt
      });
    }
  }, [calendarDates, fetchHolidays]);

  useEffect(() => {
    if (customDates) {
      calendarRef.current.getApi().changeView('resourceTimeline', {
        start: customDates.startAt,
        end: customDates.endAt
      });
    } else {
      calendarRef.current.getApi().changeView('resourceTimelineMonth', {
        start: new Date()
      });
    }
  }, [customDates]);

  useEffect(() => {
    fetchUsers(params => ({
      ...params,
      ...filters.reduce((acc, filter) => {
        return {
          ...acc,
          ...filter.asQueryParam(filter.value)
        };
      }, {})
    }));
  }, [fetchUsers, filters]);

  const resourceRenderer = useCallback(info => {
    const { displayName, settings } = info.resource.extendedProps;
    return (
      <ResourceWrapper
        as={Link}
        to={`conges/${info.resource.id}`}
        data-archived={settings?.archived ? 'true' : undefined}
      >
        <Dot color={settings?.color} size={12} />
        <Text variant="small">{displayName}</Text>
      </ResourceWrapper>
    );
  }, []);

  return (
    <Container vertical>
      <SubHeader isLoading={isLoading || isLoadingUsers}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Title>Congés&nbsp;</Title>
          <Title color="caption">
            {printDateRange(calendarDates, 'dd MMMM yyyy')?.toLowerCase()}
          </Title>
        </div>
        <FilterBar
          searchMinWidth={240}
          searchQuery="byQuery"
          onChanges={setFilters}
          searchLabel="Rechercher un utilisateur"
          filters={filters}
        />
        <ActionGroup>
          <Action
            tooltip="Voir le mois précédent"
            disabled={Boolean(customDates)}
          >
            <Icon onClick={() => calendarRef.current?.getApi().prev()}>
              <MdNavigateBefore />
            </Icon>
          </Action>
          <Action tooltip="Choissez une plage de date à afficher">
            <DatePicker
              variant="flat.medium"
              label="Plage de date"
              collapsedIfBlank
              value={customDates}
              onChange={setCustomDates}
              mode="range"
            />
          </Action>
          <Action
            tooltip="Voir le mois suivant"
            disabled={Boolean(customDates)}
          >
            <Icon onClick={() => calendarRef.current?.getApi().next()}>
              <MdNavigateNext />
            </Icon>
          </Action>
        </ActionGroup>
        <Title style={{ display: 'none' }}>Congés</Title>
      </SubHeader>
      <Container
        vertical
        className="holidays"
        style={{ pointerEvents: isLoading ? 'none' : undefined }}
      >
        <DragHeader />
        <FullCalendar
          schedulerLicenseKey={'GPL-My-Project-Is-Open-Source'}
          ref={calendarRef}
          selectable
          resourceOrder="displayName"
          nowIndicator
          editable
          locale={frLocale}
          initialView="resourceTimelineMonth"
          datesSet={handleDatesRenderChange}
          plugins={plugins}
          headerToolbar={false}
          timeZone="local"
          height="parent"
          resourceAreaWidth="360px"
          resourceAreaHeaderContent="Membres"
          snapDuration="12:00:00"
          slotDuration="12:00:00"
          slotLabelFormat={slotLabelFormat}
          resourceLabelContent={resourceRenderer}
          resourceAreaColumns={resourceColumns}
          resources={data.users}
          handleWindowResize
          events={data.holidays}
          eventResize={handleUpdateItem}
          eventDrop={handleUpdateItem}
          eventClick={handleItemClick}
          select={handleCreateItem}
          unselectAuto={false}
        />
        {currentHoliday && (
          <HolidayPrompt
            holiday={currentHoliday}
            showUserSelect={false}
            bounds={currentHoliday.bounds}
            elementRef={
              currentHoliday.bounds
                ? null
                : () => document.getElementsByClassName('fc-highlight')[0]
            }
            top={5}
            minWidth={360}
            onRequestClose={() => {
              calendarRef.current.getApi().unselect();
              setCurrentHoliday(null);
            }}
          />
        )}
      </Container>
      <Route path={`${path}/:userId`}>
        <UserWorkRecordsModal
          onRequestClose={() => history.replace('/administration/conges')}
          date={calendarDates?.startAt}
        />
      </Route>
    </Container>
  );
};

export default Holidays;

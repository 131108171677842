import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import chunk from 'lodash/chunk';
import isEqual from 'lodash/isEqual';
import { MAP_BOX_TOKEN } from '../../../constants';
import { ExternalLink } from '../ui';
import MapStylePicker, { MapStyles } from './MapStylePicker';
import { AccountSelector } from 'features/Accounts';
import { DEVIATIONS_COLORS } from 'features/Projects/components/v3/Deviation/utils';

var polyline = require('@mapbox/polyline');

const StaticMapImage = styled.img`
  object-fit: cover;
  height: 100%;
  width: 100%;
  border-radius: ${({ theme }) => theme.borderInputRadius};

  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
    `}
`;

const Wrapper = styled.div.attrs(({ height }) => ({
  style: {
    height
  }
}))`
  position: relative;
  width: 100%;
  background-color: #cccccc;
  border-radius: ${({ theme }) => theme.borderInputRadius};
`;

const StaticAddressMap = ({
  height = 300,
  address,
  project,
  imageRatio = 4,
  useAccountAddressIfEmpty = false,
  onClick,
  disableLink
}) => {
  const account = useSelector(AccountSelector.selectCurrentAccount, isEqual);
  const [mapStyle, setMapStyle] = useState(MapStyles[0].value);

  const projectPath =
    project?.directions?.length > 3
      ? `path-5+ff0000-0.7(${encodeURIComponent(
          polyline.encode(
            chunk(project.directions.map(parseFloat).map(c => c.toFixed(5)), 2)
          )
        )})`
      : null;

  const deviationsPath = (project?.deviations || [])
    .filter(d => d?.directions.length > 3)
    .map((d, index) => {
      const color = d.color || DEVIATIONS_COLORS[index] || '#cccccc';
      return `path-5+${color.replace('#', '')}-0.65(${encodeURIComponent(
        polyline.encode(
          chunk(d.directions.map(parseFloat).map(c => c.toFixed(5)), 2)
        )
      )})`;
    });

  let paths = [];
  if (projectPath) {
    paths = [projectPath];
  }

  if (deviationsPath.length > 0) {
    paths = [...paths, ...deviationsPath];
  }

  const accountAddress =
    useAccountAddressIfEmpty && account?.address?.lat && account.address.lng
      ? account.address
      : { lat: 0, lng: 0 };

  const safeAddress = address?.lat && address.lng ? address : accountAddress;

  const imageHeight = Math.min(1280, height);
  const imageWidth = Math.min(1280, parseInt(imageHeight * imageRatio));

  let pin = '';
  if (safeAddress !== accountAddress) {
    const pinColor = project?.color ? project.color.replace('#', '') : '3943ae';
    pin = `/pin-s+${pinColor}(${safeAddress.lng},${safeAddress.lat})`;
  }

  let imageUrl;
  let baseUrl = `https://api.mapbox.com/styles/v1/mapbox/${mapStyle}/static`;
  let endUrl = `/${imageWidth}x${imageHeight}@2x?access_token=${MAP_BOX_TOKEN}`;
  if (paths.length > 0) {
    imageUrl = `${baseUrl}/${paths.join(',')}/auto${endUrl}`;
  } else {
    imageUrl = `${baseUrl}${pin}/${safeAddress.lng},${
      safeAddress.lat
    },14${endUrl}`;
  }

  let linkUrl = 'https://google.com/maps?q=';
  if (safeAddress.lat && safeAddress.lng) {
    linkUrl += `${safeAddress.lat},${safeAddress.lng}`;
  } else if (safeAddress.content) {
    linkUrl += `${safeAddress.content?.replace('\n', '') || ''}`;
  } else {
    linkUrl = null;
  }

  return (
    <Wrapper height={imageHeight}>
      {onClick || disableLink ? (
        <StaticMapImage height={height} src={imageUrl} onClick={onClick} />
      ) : (
        <ExternalLink to={linkUrl}>
          <StaticMapImage height={height} src={imageUrl} />
        </ExternalLink>
      )}
      <MapStylePicker onChange={setMapStyle} currentStyle={mapStyle} />
    </Wrapper>
  );
};

export default StaticAddressMap;

import { get, put, post, destroy } from 'common/api';

export const getMachines = params =>
  get('/v5/machines', {
    ...(params || {}),
    sort: (params || {}).sort ? params.sort : 'position,display_name'
  });

export const createMachine = (machine, config) =>
  post('/v5/machines', { machine }, config);

export const getMachine = (id, config) => get(`/v5/machines/${id}`, config);

export const putMachine = (id, machine, config) =>
  put(`/v5/machines/${id}`, { machine }, config);

export const deleteMachine = (id, config) =>
  destroy(`/v5/machines/${id}`, config);

export const reorderMachine = (id, machine, config) =>
  put(`/v5/machines/${id}/reorder`, { machine }, config);

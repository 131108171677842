import React, { memo, useCallback } from 'react';
import { LocationDescriptor } from 'history';
import { VirtualItem } from 'react-virtual';
import styled from 'styled-components';
import RawList from './List';
import ItemMeasurer from './ItemMeasurer';

const List = styled(RawList)`
  position: relative;
  height: 100%;
  overflow-y: overlay;
  ::-webkit-scrollbar-thumb {
    z-index: 10;
  }
`.withComponent(RawList) as typeof RawList;

type Spacing = { top?: number; right?: number; bottom?: number; left?: number };

export type VirtualListProps<T> = {
  items: Array<T>;
  virtualItems: VirtualItem[];
  listRef: React.RefObject<HTMLElement | undefined>;
  totalSize: number;
  renderItem: (props: { item: T; index: number }) => React.ReactNode;
  listStyle?: React.CSSProperties;
  getItemStyle?: (props: { item: T; index: number }) => React.CSSProperties;
  onScroll?: React.UIEventHandler<HTMLUListElement>;
  to?: (props: { item: T; index: number }) => LocationDescriptor | null;
  itemSpacing?:
    | Spacing
    | ((props: { item: T; index: number }) => Spacing | undefined);
};

function VirtualList<T>({
  items,
  virtualItems,
  listRef,
  listStyle,
  totalSize,
  renderItem,
  getItemStyle,
  onScroll,
  to,
  itemSpacing,
  ...rest
}: VirtualListProps<T>) {
  const toImpl = useCallback(
    ({ item: virtualItem }: { item: VirtualItem }) => {
      if (!to) return null;
      const index = virtualItem.index;
      const item = items[index];
      return to({ item: item as T, index });
    },
    [to, items]
  );

  const renderItemWrapperImpl = useCallback(
    ({ item: virtualItem, children, props }) => {
      const index = virtualItem.index;
      const item = items[index];
      return (
        <ItemMeasurer
          key={`${String(index)}:${(item as any).id}`}
          measure={virtualItem.measureRef}
          as="li"
          {...props}
          style={{
            position: 'absolute',
            left: 0,
            top: `${virtualItem.start}px`,
            width: '100%',
            minHeight: virtualItem.size,
            ...(getItemStyle?.({ item, index }) || {})
          }}
        >
          {children}
        </ItemMeasurer>
      );
    },
    [items, getItemStyle]
  );

  const renderItemImpl = useCallback(
    ({ item: virtualItem }: { item: VirtualItem; index: number }) => {
      const index = virtualItem.index;
      const item = items[index];
      return renderItem({ item: item as T, index });
    },
    [renderItem, items]
  );

  const itemSpacingImpl = useCallback(
    ({ item: virtualItem }: { item: VirtualItem }) => {
      const index = virtualItem.index;
      const item = items[index];
      return itemSpacing instanceof Function
        ? itemSpacing({ item, index })
        : itemSpacing;
    },
    [itemSpacing, items]
  );

  return (
    <List
      items={virtualItems}
      listRef={listRef}
      onScroll={onScroll}
      totalSize={totalSize}
      renderItemWrapper={renderItemWrapperImpl}
      renderItem={renderItemImpl}
      style={listStyle}
      itemSpacing={itemSpacingImpl}
      to={toImpl}
      {...rest}
    />
  );
}

export default memo(VirtualList) as <T>(
  props: VirtualListProps<T>
) => JSX.Element;

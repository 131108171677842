import React, { Fragment, useCallback, useMemo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import * as Yup from 'yup';
import { ERRORS, EntityForm, DatePickerField, TextInputField, CheckboxField } from 'components/v3/Form';
import CalendarPrompt, { PromptHeader } from 'components/v3/CalendarPrompt';
import { Grid, GridItem } from 'components/v3/ui';
import { CardFooter, CardContent as RawCardContent, CardButtons } from 'components/v3/ui/card';
import { UserField } from 'features/Users/components/v3/UserSelect';
import { useAccountConfig, Permission, features, permissions } from 'config';
import { ProjectSelector, ProjectAction } from 'features/Projects';
import { phaseAsValues, phaseValuesDependencies } from 'features/Phases';
import { PhaseAction } from 'features/Phases/data';
import { ProjectField } from 'features/Projects/components/v3/Select';
import { ActivityField } from 'features/ProjectKindsActivities/components/v3/Select';
import { TeamField } from 'features/Teams/components/v3/Select';
import { MachineField } from 'features/Machines/components/v3/Select';
import { useDeepMemo, useRedux } from 'components/v3/utils';
import State from './State';
import { spacing } from 'ui';
import { pick } from 'lodash';
import { IcExternalLink } from 'common/images/icons';

const CardHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > *:last-child:not(:only-child) {
    margin-right: 32px;
  }
`;

const CardContent = styled(RawCardContent)`
  padding: 0px ${spacing()} ${spacing()};
`;

const NameAndActivityWrapper = styled(GridItem)`
  display: flex;
  ${GridItem}:first-child:not(:only-child) {
    margin-right: 16px;
    width: 50%;
  }
  ${GridItem}:only-child {
    width: 100%;
  }
  ${GridItem}:not(:only-child):last-child {
    transition: width 0.3s ease;
  }
`;

const PhasePrompt = ({
  onRequestClose,
  onSubmitSuccess,
  phase,
  resource,
  projectId: propsProjectId,
  requireMembers,
  requireProject = true,
  users,
  hideTeam,
  hideMembers,
  hideMachines,
  hideProject,
  source,
  ...props
}) => {
  const { translate, features: accountFeatures, hasPermission } = useAccountConfig();
  const dispatch = useDispatch();
  const projectId = phase?.project?.id || phase?.projectId || propsProjectId;

  const selectProject = useMemo(
    () => (state) => {
      const project = ProjectSelector.selectProjectV3(state, { projectId });
      return project ? pick(project, ['projectKind', 'id', 'address', 'displayName']) : null;
    },
    [projectId]
  );

  const [project, fetchProject] = useRedux(selectProject, ProjectAction.getProject, { fetchOnMount: false });

  const createRequest = useCallback((payload) => dispatch(PhaseAction.createPhase({ payload })), [dispatch]);

  useEffect(() => {
    if (projectId) {
      fetchProject({ id: projectId });
    }
  }, [projectId, fetchProject]);

  const { hourIncrement, minuteIncrement } = useMemo(() => {
    if ((accountFeatures || []).includes(features.PLANNING_CALENDAR)) {
      return {
        hourIncrement: 1,
        minuteIncrement: 15
      };
    } else {
      return {
        hourIncrement: 12,
        minuteIncrement: 12 * 60
      };
    }
  }, [accountFeatures]);

  const handleSuccess = (...args) => {
    if (onSubmitSuccess) {
      onSubmitSuccess(...args);
    }
    onRequestClose(...args);
  };

  const userIds = (users || []).map(({ id }) => id);
  const entityAsValue = useDeepMemo(
    () => (entity) => {
      const values = phaseAsValues(entity);
      if (userIds.length === 0) {
        return values;
      }
      return {
        ...values,
        users,
        userIds
      };
    },
    [userIds]
  );

  const validationSchema = useMemo(() => {
    if (requireProject) {
      return Yup.object().shape({
        project: Yup.object().nullable().required(ERRORS.REQUIRED)
      });
    }
    return undefined;
  }, [requireProject]);

  const canEdit = hasPermission([permissions.RESOURCES_CONFIG, phase?.id ? 'phase.create' : 'phase.update'], {
    target: phase
  });

  const entity = useMemo(() => {
    const obj = phase || { project };
    if (source) {
      obj.source = source;
    }
    return obj;
  }, [phase, project, source]);

  return (
    <CalendarPrompt maxWidth={632} onRequestClose={onRequestClose} {...props}>
      <EntityForm
        isNew
        model="phase"
        entity={entity}
        entityAsValue={entityAsValue}
        createRequest={createRequest}
        onSubmitSuccess={handleSuccess}
        validationSchema={validationSchema}
        valuesDependencies={phaseValuesDependencies}
        autoSubmit={false}
        readOnly={!canEdit}
      >
        {({ values, setFieldValue, isSubmitting }) => {
          const projectKind = project?.projectKind || values.projectKind || values.project?.projectKind;
          return (
            <Fragment>
              <CardHeaderWrapper>
                <PromptHeader
                  title="Nouvelle intervention"
                  subtitle={phase.resource?.displayName || values.project?.displayName}
                />
                <State
                  size="small"
                  value={values.state}
                  right={0}
                  onChange={(state) => setFieldValue('state', state)}
                />
              </CardHeaderWrapper>
              <CardContent>
                <Grid spacing={16} padded={false}>
                  {!hideProject && (
                    <GridItem width="100%">
                      <ProjectField
                        name="project"
                        required={requireProject}
                        isClearable={false}
                        trailingTooltip="Voir le détail du projet"
                        onTrailingIconClick={() =>
                          window.open(`${window.location.origin}/projets/${values.project?.id}`, '_blank')
                        }
                        iconTrailing={Boolean(values.project?.id) ? IcExternalLink : null}
                      />
                    </GridItem>
                  )}
                  <NameAndActivityWrapper width="100%">
                    <GridItem>
                      <TextInputField textArea name="displayName" label="Nom de l'intervention" />
                    </GridItem>
                    <Permission feature={features.PHASE_ACTIVITIES}>
                      {Boolean(projectKind) && (
                        <GridItem width="50%">
                          <ActivityField name="activity" label="Activité" projectKind={projectKind} />
                        </GridItem>
                      )}
                    </Permission>
                  </NameAndActivityWrapper>
                  {!hideTeam && (
                    <Permission feature={features.PLANNING_TEAMS}>
                      <GridItem width="50%">
                        <TeamField name="team" label="Équipe" optionEditable={false} />
                      </GridItem>
                    </Permission>
                  )}
                  {!hideMembers && (
                    <Permission feature={[features.PLANNING_MEMBERS, features.PLANNING_CALENDAR]}>
                      <GridItem width="100%">
                        <UserField
                          name="users"
                          label={translate({
                            value: 'phase.user',
                            fallback: 'Personnel assigné',
                            plural: true
                          })}
                          role={['technician', 'admin', 'conductor']}
                          multi
                        />
                      </GridItem>
                    </Permission>
                  )}
                  {!hideMachines && (
                    <Permission feature={[features.PLANNING_MACHINES]}>
                      <GridItem width="100%">
                        <MachineField name="machines" multi />
                      </GridItem>
                    </Permission>
                  )}
                  <GridItem width="50%">
                    <DatePickerField
                      label="Date de début"
                      name="startAt"
                      disableTime={false}
                      hourIncrement={hourIncrement}
                      minuteIncrement={minuteIncrement}
                      isClearable={false}
                    />
                  </GridItem>
                  <GridItem width="50%">
                    <DatePickerField
                      label="Date de fin"
                      name="endAt"
                      disableTime={false}
                      hourIncrement={hourIncrement}
                      minuteIncrement={minuteIncrement}
                      isEndDate
                      isClearable={false}
                    />
                  </GridItem>
                  <GridItem width="20%" minWidth={100}>
                    <CheckboxField name="night" label="De nuit" />
                  </GridItem>
                </Grid>
              </CardContent>
              <CardFooter>
                <CardButtons
                  positiveText="Enregistrer"
                  negativeText="Annuler"
                  positiveDisabled={requireMembers && !Boolean(values.users?.length > 0)}
                  buttonNameLoading={isSubmitting ? 'positive' : null}
                  onButtonClick={({ name }) => {
                    switch (name) {
                      case 'positive':
                        // form.submitForm();
                        break;
                      default:
                        onRequestClose();
                    }
                  }}
                />
              </CardFooter>
            </Fragment>
          );
        }}
      </EntityForm>
    </CalendarPrompt>
  );
};

export default PhasePrompt;

import React, {
  useCallback,
  useMemo,
  Fragment,
  useState,
  useEffect
} from 'react';
import { SelectField } from 'components/v3/Form';
import { SelectInputLayout } from 'components/v3/InputLayout';
import { useRedux } from 'components/v3/utils';
import ServiceModal from './ServiceModal';
import {
  AccountingSelector,
  AccountingAction,
  serviceAsOption
} from 'features/Accounting';
import { jsonApiToObject } from 'common/api';
import { filterService } from '../data/selector';
import { permissions, useAccountConfig } from 'config';

const formatCreateLabel = input => `Créer le service ${input}`;

const serviceOptionsFormatter = (response, groupId, projectKindId) => {
  return (jsonApiToObject(response) || [])
    .filter(s => filterService(s, groupId, projectKindId))
    .map(s => serviceAsOption(s));
};

const ServiceSelect = ({
  groupId,
  projectKindId,
  value,
  onChange,
  fetchOnMount = true,
  ...props
}) => {
  const { accountId, hasPermission } = useAccountConfig();
  const [serviceModal, setServiceModal] = useState();
  const selectServices = useMemo(
    () => state =>
      AccountingSelector.selectSearchServices(state, {
        groupId,
        projectKindId
      }),
    [groupId, projectKindId]
  );

  const [services, fetch] = useRedux(
    selectServices,
    AccountingAction.getServices,
    {
      fetchOnMount: false,
      useLocalState: false,
      initialParams: {
        page: 1,
        perPage: 50,
        sort: 'display_name',
        useCache: true,
        isSearchRequest: true,
        without_or_within_groups: [groupId],
        project_kind_id_eq_or_project_kind_id_null: projectKindId
      }
    }
  );

  const handleCreateOption = useCallback(input => {
    setServiceModal({
      displayName: input
    });
  }, []);

  const handleEditOption = useCallback(value => {
    setServiceModal(value);
  }, []);

  const handleSearch = useCallback(
    ({ query }) =>
      new Promise((resolve, reject) => {
        fetch(params => ({
          ...params,
          displayNameOrDescriptionOrReferenceContAny: query,
          page: 1,
          useCache: false,
          without_or_within_groups: [groupId],
          project_kind_id_eq_or_project_kind_id_null: projectKindId
        }))
          .onError(reject)
          .onSuccess(res =>
            resolve(serviceOptionsFormatter(res, groupId, projectKindId))
          );
      }),
    [fetch, groupId, projectKindId]
  );

  const options = useMemo(() => serviceAsOption(services), [services]);

  const currentValue = useMemo(() => serviceAsOption(value), [value]);

  useEffect(() => {
    if (!fetchOnMount) return;
    fetch(params => ({
      ...params,
      displayNameOrDescriptionOrReferenceContAny: undefined,
      page: 1,
      useCache: true
    }));
  }, [fetch, accountId, fetchOnMount]);

  return (
    <Fragment>
      <SelectInputLayout
        label="Service"
        value={currentValue}
        options={options}
        isSearchable
        loadAsyncOptions={handleSearch}
        filterOptions={false}
        onChange={onChange}
        inputCreatable={hasPermission([
          permissions.ACCOUNTING,
          'service.create'
        ])}
        createLabel="Créer un service"
        formatCreateLabel={formatCreateLabel}
        onCreateOption={handleCreateOption}
        optionEditable={hasPermission([
          permissions.ACCOUNTING,
          'service.update'
        ])}
        onEditOptionClick={handleEditOption}
        {...props}
      />
      {serviceModal && (
        <ServiceModal
          service={serviceModal}
          onRequestClose={() => setServiceModal(null)}
          onSubmitSuccess={response => {
            const service = jsonApiToObject(response);
            if (props.multi) {
              onChange([...currentValue, service]);
            } else {
              onChange(service);
            }
          }}
        />
      )}
    </Fragment>
  );
};

export const ServiceField = props => {
  return <SelectField component={ServiceSelect} {...props} />;
};

export default ServiceSelect;

import React, { useCallback, useMemo, useRef } from 'react';
import { v4 } from 'uuid';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import isEqual from 'lodash/isEqual';
import { MdAdd } from 'react-icons/md';
import * as Yup from 'yup';
import { TextInputField, ERRORS } from 'components/v3/Form';
import {
  Grid,
  GridItem,
  Button,
  DashContainer,
  Text,
  Dashed
} from 'components/v3/ui';
import { TextInputLayout, InputLayoutVariant } from 'components/v3/InputLayout';
import Table from 'components/v3/List/Table';
import { EntityModal } from 'components/v3/Modal';
import {
  AccountingSelector,
  AccountingAction,
  serviceAsValues
} from 'features/Accounting';
import { useDelay } from 'components/v3/utils';
import GroupSelect from 'features/Groups/components/v3/Select';
import { ProjectKindField } from 'features/ProjectKinds/components/v3/Select';
import { UnitField } from './UnitSelect';
import { TaxField } from './TaxSelect';
import { permissions, useAccountConfig } from 'config';

const ACTIVITY_COLUMNS = [
  {
    Header: 'Groupe',
    accessor: 'group',
    width: '50%',
    dashed: true,
    isEditable: true,
    Cell: ({ item, onChange }) => (
      <GroupSelect
        value={item.group}
        variant={InputLayoutVariant.flat.medium}
        label="Groupe"
        required
        fillWidth
        onChange={group =>
          onChange({
            ...item,
            group
          })
        }
      />
    )
  },
  {
    Header: 'Coût',
    accessor: 'price',
    width: '50%',
    dashed: true,
    isEditable: true,
    tooltip: 'Prix du service pour le groupe selectionné',
    Cell: ({ item, onChange }) => (
      <TextInputLayout
        variant={InputLayoutVariant.flat.medium}
        name="price"
        currency
        value={item.price}
        fillWidth
        label="Coût en euros (€)"
        onChange={price =>
          onChange({
            ...item,
            price
          })
        }
      />
    )
  }
];

const GroupPricesLabel = styled(Text)`
  padding-left: 16px;
  padding-top: 6px;
  padding-right: 16px;
  padding-bottom: 6px;
`;

const GroupPricesGridItem = styled(GridItem)`
  display: flex;
  flex-direction: column;

  &:focus,
  &:focus-within {
    ${GroupPricesLabel} {
      color: ${({ theme }) => theme.primaryLight};
    }
  }
`;

const GroupPricesContainer = styled(DashContainer)`
  border: ${({ theme }) => theme.borderInput};
  border-top-left-radius: ${({ theme }) => theme.borderInputRadius};
  border-top-right-radius: ${({ theme }) => theme.borderInputRadius};
  overflow: hidden;

  * > input,
  * > textarea {
    padding-left: 8px;
    padding-right: 8px;
    font-size: 1rem;
  }
  * > label {
    left: 3px;
    font-size: 0.875rem;
  }
  * > ${Dashed} {
    margin-left: -6px;
    width: calc(100% + 12px);
  }
`;

const AddServiceGroupButton = styled.div`
  align-self: flex-end;
`.withComponent(Button);

const validationSchema = Yup.object().shape({
  displayName: Yup.string().required(ERRORS.REQUIRED),
  price: Yup.string().required(ERRORS.REQUIRED)
});

const ServiceModal = ({
  onRequestClose,
  onSubmitSuccess,
  service: propsService,
  ...props
}) => {
  const { hasPermission } = useAccountConfig();
  const dispatch = useDispatch();
  const delay = useDelay();
  const { params } = useRouteMatch();
  let { serviceId } = params;
  if (propsService) {
    serviceId = propsService.id;
  }
  const isNew = !Boolean(serviceId);
  const groupServicesRef = useRef();

  const selectService = useMemo(
    () => state => AccountingSelector.selectService(state, { serviceId }),
    [serviceId]
  );

  let service = useSelector(selectService, isEqual);
  if (!service) {
    service = propsService;
  }

  const createRequest = useCallback(
    payload => dispatch(AccountingAction.createService({ payload })),
    [dispatch]
  );

  const fetchRequest = useCallback(
    () => dispatch(AccountingAction.getService({ id: serviceId })),
    [serviceId, dispatch]
  );

  const updateRequest = useCallback(
    (id, payload) => dispatch(AccountingAction.updateService({ id, payload })),
    [dispatch]
  );

  const deleteRequest = useCallback(
    id => dispatch(AccountingAction.deleteService({ id })),
    [dispatch]
  );

  const canEdit = hasPermission([
    permissions.ACCOUNTING,
    isNew ? 'service.create' : 'service.update'
  ]);

  const canDelete = hasPermission([permissions.ACCOUNTING, 'service.delete']);

  return (
    <EntityModal
      isOpen
      onRequestClose={onRequestClose}
      title={isNew ? 'Nouveau service' : 'Édition du service'}
      id={serviceId}
      model="service"
      entity={service}
      validationSchema={validationSchema}
      entityAsValue={serviceAsValues}
      fetchRequest={fetchRequest}
      createRequest={createRequest}
      updateRequest={updateRequest}
      deleteRequest={canDelete ? deleteRequest : undefined}
      onSubmitSuccess={onSubmitSuccess}
      autoSubmit={false}
      readOnly={!canEdit}
      {...props}
    >
      {({ values, setFieldValue }) => (
        <Grid spacing={16} padded={false}>
          <GridItem width="100%">
            <TextInputField
              readOnly={!canEdit}
              name="displayName"
              label="Nom"
              textArea
              required
            />
          </GridItem>
          <GridItem width="100%">
            <TextInputField
              readOnly={!canEdit}
              name="reference"
              label="Référence"
            />
          </GridItem>
          <GridItem width="100%">
            <ProjectKindField
              readOnly={!canEdit}
              name="projectKind"
              label="Type de projet"
              hint="Le service ne sera visible que pour le type de projet selectionné."
            />
          </GridItem>
          <GridItem width="37.5%">
            <TextInputField
              name="price"
              readOnly={!canEdit}
              label="Prix en €"
              currency
              required
            />
          </GridItem>
          <GridItem width="37.5%">
            <UnitField name="unit" readOnly={!canEdit} label="Unité" />
          </GridItem>
          <GridItem width="25%">
            <TaxField name="taxRate" readOnly={!canEdit} label="Taxe (TVA)" />
          </GridItem>
          <GridItem width="100%">
            <TextInputField
              name="description"
              textArea
              readOnly={!canEdit}
              label="Informations / Description"
              minRows={3}
            />
          </GridItem>
          <GroupPricesGridItem width="100%">
            <GroupPricesLabel color="secondary" weight="semiBold">
              Prix par groupe
            </GroupPricesLabel>
            <GroupPricesContainer readOnly={false} fillWidth>
              <Table
                listRef={groupServicesRef}
                columns={ACTIVITY_COLUMNS}
                items={values.groupServices || []}
                readOnly={!canEdit}
                hasDeleteConfirmation={false}
                headerVariant="dark"
                onChange={groupServices =>
                  setFieldValue('groupServices', groupServices)
                }
                isDeletable
                estimatedItemSize={48}
                maxHeight={400}
                minRows={1}
              />
            </GroupPricesContainer>
            {canEdit && (
              <AddServiceGroupButton
                disabled={
                  values.groupServices?.filter(s => !s._destroy && !s.group)
                    ?.length > 0
                }
                variant="text"
                iconLeading={MdAdd}
                onClick={() => {
                  setFieldValue('groupServices', [
                    ...(values.groupServices || []),
                    {
                      id: v4(),
                      group: null,
                      price: '',
                      isNew: true
                    }
                  ]);
                  delay(
                    groupServicesRef.current?.scroller().scrollToBottom,
                    50
                  );
                }}
              >
                Ajouter un prix de groupe
              </AddServiceGroupButton>
            )}
          </GroupPricesGridItem>
        </Grid>
      )}
    </EntityModal>
  );
};

export default ServiceModal;

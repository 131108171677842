import { css } from 'styled-components';
import { round } from 'lodash';

export const base = 16;

export const spacing = (input = 1, asPx = true) => {
  if (typeof input === 'boolean') {
    return asPx ? `${base}px` : base;
  }
  return asPx ? `${input * base}px` : input * base;
};

export const fontSize = (fontSize, asEm = true) => {
  return `${round(0.0625 * fontSize, 3)}${asEm ? 'rem' : ''}`;
};

export const lineHeight = (fontSize, asEm = true) => {
  return `${round(0.0625 * fontSize * 1.25, 3)}${asEm ? 'em' : ''}`;
};

export const viewportSizes = {
  desktop: 1366,
  medium: 1100,
  tablet: 800,
  tabletMini: 500
};

const mediaQuery = (...query) => (...rules) =>
  css`
    @media ${css(...query)} {
      ${css(...rules)};
    }
  `;

export const media = {
  tabletMini: mediaQuery`(min-width: ${viewportSizes.tabletMini / 16}em)`,
  tablet: mediaQuery`(min-width: ${viewportSizes.tablet / 16}em)`,
  medium: mediaQuery`(min-width: ${viewportSizes.medium / 16}em)`,
  desktop: mediaQuery`(min-width: ${viewportSizes.desktop / 16}em)`
};

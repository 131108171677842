import React, { useCallback, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import isEqual from 'lodash/isEqual';
import * as Yup from 'yup';
import { TextInputField, ERRORS } from 'components/v3/Form';
import { InputLayoutVariant } from 'components/v3/InputLayout';
import Table from 'components/v3/List/Table';
import { EntityModal } from 'components/v3/Modal';
import { Grid, GridItem, DashContainer, Text } from 'components/v3/ui';
import { SurfacingSelector, SurfacingAction, purveyorAsValues } from 'features/Surfacing';
import { permissions, useAccountConfig } from 'config';
import ProductSelect from './ProductSelect';
import { theme } from 'ui';

const PRODUCT_COLUMNS = [
  {
    Header: 'Nom',
    accessor: 'displayName',
    width: '100%'
  }
];

const ProductsLabel = styled(Text)`
  padding-left: 16px;
  padding-top: 6px;
  padding-right: 16px;
  padding-bottom: 6px;
`;

const ProductsGridItem = styled(GridItem)`
  display: flex;
  flex-direction: column;

  &:focus,
  &:focus-within {
    ${ProductsLabel} {
      color: ${({ theme }) => theme.primaryLight};
    }
  }
`;

const ProductsContainer = styled(DashContainer)`
  border: ${({ theme }) => theme.borderInput};
  border-top-left-radius: ${({ theme }) => theme.borderInputRadius};
  border-top-right-radius: ${({ theme }) => theme.borderInputRadius};
  overflow: hidden;
  max-height: 500px;
`;

const TitleAndAction = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 16px;
`;

const ProductTable = ({ productIds, setFieldValue }) => {
  const selectProducts = useMemo(
    () => (state) => SurfacingSelector.selectProductsByIds(state, { ids: productIds }),
    [productIds]
  );
  const products = useSelector(selectProducts, isEqual);

  return (
    <>
      <TitleAndAction>
        <ProductsLabel color="secondary" weight="semiBold">
          {`Produits associés (${productIds.length})`}
        </ProductsLabel>
        <ProductSelect
          label="Ajouter un produit"
          style={{ width: 300, background: theme.background }}
          onChange={(product) => product && setFieldValue('productIds', [...(productIds ?? []), product.id])}
          variant={InputLayoutVariant.flat.small}
        />
      </TitleAndAction>
      <ProductsContainer readOnly={false} fillWidth>
        <Table
          columns={PRODUCT_COLUMNS}
          items={products}
          readOnly={false}
          hasDeleteConfirmation={false}
          headerVariant="dark"
          useWindow={false}
          onChange={(products) => {
            return setFieldValue(
              'productIds',
              products.filter(({ _destroy }) => !_destroy).map(({ id }) => id)
            );
          }}
          isDeletable
          maxHeight={400}
          minRows={1}
        />
      </ProductsContainer>
    </>
  );
};

const validationSchema = Yup.object().shape({
  displayName: Yup.string().required(ERRORS.REQUIRED)
});

const PurveyorModal = ({ onRequestClose, onSubmitSuccess, purveyor: propsPurveyor, ...props }) => {
  const { hasPermission } = useAccountConfig();
  const dispatch = useDispatch();
  const { params } = useRouteMatch();
  let { purveyorId } = params;
  if (propsPurveyor) {
    purveyorId = propsPurveyor.id;
  }
  const isNew = !Boolean(purveyorId);

  const selectPurveyor = useMemo(
    () => (state) => SurfacingSelector.selectPurveyor(state, { purveyorId }),
    [purveyorId]
  );

  let purveyor = useSelector(selectPurveyor, isEqual);
  if (!purveyor) {
    purveyor = propsPurveyor;
  }

  const createRequest = useCallback((payload) => dispatch(SurfacingAction.createPurveyor({ payload })), [dispatch]);

  const fetchRequest = useCallback(
    () => dispatch(SurfacingAction.getPurveyor({ id: purveyorId })),
    [purveyorId, dispatch]
  );

  const updateRequest = useCallback(
    (id, payload) => dispatch(SurfacingAction.updatePurveyor({ id, payload })),
    [dispatch]
  );

  const deleteRequest = useCallback((id) => dispatch(SurfacingAction.deletePurveyor({ id })), [dispatch]);

  const canEdit = hasPermission([permissions.RESOURCES_CONFIG, isNew ? 'purveyor.create' : 'purveyor.update']);
  const canDelete = hasPermission([permissions.RESOURCES_CONFIG, 'purveyor.delete']);

  return (
    <EntityModal
      isOpen
      onRequestClose={onRequestClose}
      title={isNew ? 'Nouveau fournisseur' : 'Édition du fournisseur'}
      id={purveyorId}
      model="purveyor"
      entity={purveyor}
      validationSchema={validationSchema}
      entityAsValue={purveyorAsValues}
      fetchRequest={fetchRequest}
      createRequest={createRequest}
      updateRequest={updateRequest}
      deleteRequest={canDelete ? deleteRequest : undefined}
      onSubmitSuccess={onSubmitSuccess}
      autoSubmit={false}
      readOnly={!canEdit}
      {...props}
    >
      {({ values, setFieldValue }) => (
        <Grid spacing={16} padded={false}>
          <GridItem width="100%">
            <TextInputField name="displayName" label="Nom du fournisseur" required />
          </GridItem>
          <GridItem width="100%">
            <TextInputField name="phoneNumber" label="Numéro de téléphone" />
          </GridItem>
          <GridItem width="100%">
            <TextInputField name="faxNumber" label="Fax" />
          </GridItem>
          <ProductsGridItem width="100%">
            <ProductTable productIds={values.productIds ?? []} setFieldValue={setFieldValue} />
          </ProductsGridItem>
        </Grid>
      )}
    </EntityModal>
  );
};

export default PurveyorModal;

import * as ExtractionMaterialApi from './extraction_material_api';
import * as ExtractionMaterialAction from './extraction_material_actions';
import * as ExtractionMaterialReducer from './extraction_material_reducer';
import * as ExtractionMaterialSelector from './extraction_material_selector';

import * as ExtractionDestinationApi from './extraction_destination_api';
import * as ExtractionDestinationAction from './extraction_destination_actions';
import * as ExtractionDestinationReducer from './extraction_destination_reducer';
import * as ExtractionDestinationSelector from './extraction_destination_selector';

import * as InputMaterialApi from './input_material_api';
import * as InputMaterialAction from './input_material_actions';
import * as InputMaterialReducer from './input_material_reducer';
import * as InputMaterialSelector from './input_material_selector';

export const MaterialApi = {
  ...ExtractionMaterialApi,
  ...ExtractionDestinationApi,
  ...InputMaterialApi
};

export const MaterialAction = {
  ...ExtractionMaterialAction,
  ...ExtractionDestinationAction,
  ...InputMaterialAction
};

export const MaterialReducer = {
  ...ExtractionMaterialReducer,
  ...ExtractionDestinationReducer,
  ...InputMaterialReducer
};

export const MaterialSelector = {
  ...ExtractionMaterialSelector,
  ...ExtractionDestinationSelector,
  ...InputMaterialSelector
};

export const extractionMaterialAsOption = extractionMaterial => {
  if (Array.isArray(extractionMaterial)) {
    return extractionMaterial.map(extractionMaterialAsOption);
  } else if (extractionMaterial) {
    return {
      value: extractionMaterial.id,
      label: extractionMaterial.name,
      original: extractionMaterial
    };
  } else {
    return null;
  }
};

export const extractionMaterialAsValues = extractionMaterial => ({
  id: extractionMaterial?.id || null,
  name: extractionMaterial?.name || ''
});

export const extractionDestinationAsOption = extractionDestination => {
  if (Array.isArray(extractionDestination)) {
    return extractionDestination.map(extractionDestinationAsOption);
  } else if (extractionDestination) {
    return {
      value: extractionDestination.id,
      label: extractionDestination.name,
      original: extractionDestination
    };
  } else {
    return null;
  }
};

export const extractionDestinationAsValues = extractionDestination => ({
  id: extractionDestination?.id || null,
  name: extractionDestination?.name || ''
});

export const inputMaterialAsOption = inputMaterial => {
  if (Array.isArray(inputMaterial)) {
    return inputMaterial.map(inputMaterialAsOption);
  } else if (inputMaterial) {
    return {
      value: inputMaterial.id,
      label: inputMaterial.name,
      original: inputMaterial
    };
  } else {
    return null;
  }
};

export const inputMaterialAsValues = inputMaterial => ({
  id: inputMaterial?.id || null,
  name: inputMaterial?.name || ''
});

import { v4 } from 'uuid';
import {
  ProjectAction,
  ProjectApi,
  ProjectReducer,
  ProjectSelector
} from './data';

export { ProjectAction, ProjectApi, ProjectReducer, ProjectSelector };

export const projectAsOption = project => {
  if (Array.isArray(project)) {
    return project.map(projectAsOption);
  } else if (project) {
    return {
      value: project.id,
      color: project.groupColor,
      label: project.reference
        ? `${project.reference} - ${project.displayName}`
        : project.displayName,
      original: project
    };
  } else {
    return null;
  }
};

export const projectStateAsOption = state => {
  if (Array.isArray(state)) {
    return state.map(projectStateAsOption);
  } else if (state) {
    return {
      value: state.value,
      color: state.color,
      label: state.displayName || state.label,
      original: state
    };
  } else {
    return null;
  }
};

export const projectAsValues = project => ({
  id: project?.id,
  displayName: project?.displayName || '',
  estimatedDate: project?.estimatedDate || '',
  state: project?.state || null,
  reference: project?.reference || '',
  projectKind: project?.projectKind || null,
  group: project?.group || null,
  address: project?.address || null,
  description: project?.description || '',
  attachments: project?.attachments || [],
  customData: Array.isArray(project?.customData) ? project.customData : [],
  projectActivities: project?.projectActivities || [],
  organization: project?.organization || '',
  contact: project?.contact || '',
  user: project?.user || '',
  points: project?.points || [],
  directions: project?.directions || [],
  checked: project?.checked,
  deviations: project?.deviations || [],
  signs: project?.signs || [],
  deviationsPublished: project?.deviationsPublished || false,
  documentTemplates: project?.documentTemplates || [],
  clients: project?.clients || [],
  creator: project?.creator || null,
  owner: project?.owner || undefined,
  assistant: project?.assistant || undefined
});

export const projectAsMarker = project => {
  if (
    !project ||
    !project.address ||
    !project.address.lat ||
    !project.address.lng
  ) {
    return null;
  }
  return { ...project.address, id: v4(), kind: 'project', item: project };
};

import React, { useCallback, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import * as Yup from 'yup';
import { TextInputField, ERRORS } from 'components/v3/Form';
import { Grid, GridItem } from 'components/v3/ui';
import { EntityModal } from 'components/v3/Modal';
import {
  CarrierSelector,
  CarrierAction,
  carrierAsValues
} from 'features/Carriers';
import { CarrierKindField } from './CarrierKindSelect';
import { permissions, useAccountConfig } from 'config';

const validationSchema = Yup.object().shape({
  displayName: Yup.string().required(ERRORS.REQUIRED),
  kind: Yup.string().required(ERRORS.REQUIRED)
});

const CarrierModal = ({
  onRequestClose,
  onSubmitSuccess,
  carrier: propsCarrier,
  ...props
}) => {
  const dispatch = useDispatch();
  const { params } = useRouteMatch();
  const { hasPermission } = useAccountConfig();
  let { carrierId } = params;
  if (propsCarrier) {
    carrierId = propsCarrier.id;
  }
  const isNew = !Boolean(carrierId);

  const selectCarrier = useMemo(
    () => state => CarrierSelector.selectCarrierV3(state, { carrierId }),
    [carrierId]
  );

  let carrier = useSelector(selectCarrier, isEqual);
  if (!carrier) {
    carrier = propsCarrier;
  }

  const createRequest = useCallback(
    payload => dispatch(CarrierAction.createCarrier({ payload })),
    [dispatch]
  );

  const fetchRequest = useCallback(
    () => dispatch(CarrierAction.getCarrier({ id: carrierId })),
    [carrierId, dispatch]
  );

  const updateRequest = useCallback(
    (id, payload) => dispatch(CarrierAction.updateCarrier({ id, payload })),
    [dispatch]
  );

  const deleteRequest = useCallback(
    id => dispatch(CarrierAction.deleteCarrier({ id })),
    [dispatch]
  );

  const canEdit = hasPermission([
    permissions.RESOURCES_CONFIG,
    isNew ? 'carrier.create' : 'carrier.update'
  ]);

  const canDelete = hasPermission([
    permissions.RESOURCES_CONFIG,
    'carrier.delete'
  ]);

  return (
    <EntityModal
      isOpen
      size="small"
      onRequestClose={onRequestClose}
      title={isNew ? 'Nouveau transporteur' : 'Édition du transporteur'}
      id={carrierId}
      model="carrier"
      entity={carrier}
      validationSchema={validationSchema}
      entityAsValue={carrierAsValues}
      fetchRequest={fetchRequest}
      createRequest={createRequest}
      updateRequest={updateRequest}
      deleteRequest={canDelete ? deleteRequest : undefined}
      onSubmitSuccess={onSubmitSuccess}
      autoSubmit={false}
      readOnly={!canEdit}
      {...props}
    >
      {() => (
        <Grid spacing={16} padded={false}>
          <GridItem width="100%">
            <TextInputField
              name="displayName"
              readOnly={!canEdit}
              label="Nom"
              required
            />
          </GridItem>
          <GridItem width="100%">
            <CarrierKindField name="kind" readOnly={!canEdit} />
          </GridItem>
        </Grid>
      )}
    </EntityModal>
  );
};

export default CarrierModal;

import React, { Fragment, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { GridItem, Text } from 'components/v3/ui';
import { resourceableCallbackFilter } from './utils';
import CustomField from './CustomField';
import { List, ListItem } from 'components/v3/ui/list';

const Tab = styled(ListItem)`
  display: inline-block;
  > * {
    color: ${({ theme }) => theme.textSecondary};
  }
  cursor: pointer;
  border-bottom: 2px solid #f0f0f0;
  transition: all 0.5s;
  padding: 6px 10px;
  ${({ selected }) =>
    selected &&
    css`
      > * {
        color: ${({ theme }) => theme.accent};
        font-weight: 600;
        transform: scale(1.5, 1.5);
        transition: all 0.2s;
      }
      border-bottom: 2px solid ${({ theme }) => theme.accent};
    `}
`;

const Tabs = styled(List)`
  > ${Tab} {
    margin-right: 8px;
  }
`;

const RepeatableTabs = ({ values, customFieldGroup, data, onChange, readOnly }) => {
  const [index, setIndex] = useState(0);
  const dataRef = useRef();
  dataRef.current = data;

  let dependency = values.customData?.find((d) => d.name === customFieldGroup.repeatKey);
  let count = 1;
  if (dependency) {
    count = parseInt(dependency.value?.label || dependency.value || 1);
    if (isNaN(count)) {
      count = 1;
    }
  }

  return (
    <Fragment>
      <GridItem width="100%">
        <Tabs>
          {Array(count)
            .fill()
            .map((_, i) => (
              <Tab key={i} onClick={() => setIndex(i)} selected={index === i}>
                <Text>{i + 1}</Text>
              </Tab>
            ))}
        </Tabs>
      </GridItem>
      <CustomFieldGroup
        customFieldGroup={{
          ...customFieldGroup,
          repeat: false
        }}
        dataPrefix={`${customFieldGroup.name || customFieldGroup.id}.${index}.`}
        data={data}
        values={values}
        readOnly={readOnly}
        onChange={onChange}
      />
    </Fragment>
  );
};

const CustomFieldGroup = ({ values, customFieldGroup, dataPrefix = '', data, onChange, readOnly }) => {
  const getPreviousData = (customField, data, dataPrefix) => {
    const name = `${dataPrefix || ''}${customField.name}`;
    const prevData = Array.isArray(data) && data.find((d) => d.name === name);
    return {
      value: prevData ? prevData.value : '',
      name,
      kind: customField.kind,
      label: customField.label
    };
  };

  if (customFieldGroup.repeat) {
    return (
      <RepeatableTabs
        {...{
          customFieldGroup,
          data,
          onChange,
          values,
          readOnly
        }}
      />
    );
  } else {
    return customFieldGroup.customFields
      .filter((customField) => resourceableCallbackFilter(customField, values))
      .sort((a, b) => (a.sort > b.sort ? 1 : -1))
      .map((customField) => (
        <GridItem
          width={customField.kind === 'checkbox' ? '20%' : '50%'}
          minWidth={customField.kind === 'checkbox' ? 200 : 280}
          wrap={customField.kind === 'checkbox' ? 'true' : undefined}
          key={customField.id}
          justifyCenter={customField.kind === 'checkbox'}
        >
          <CustomField
            field={customField}
            data={getPreviousData(customField, data, dataPrefix)}
            onChange={onChange}
            readOnly={readOnly}
          />
        </GridItem>
      ));
  }
};

export default CustomFieldGroup;

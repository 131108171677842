import React, { Fragment, useEffect, useCallback } from 'react';
import { useRouteMatch, Route, useHistory } from 'react-router-dom';
import { Text } from 'components/v3/ui';
import Table from 'components/v3/List/Table';
import { useListFetcher } from 'components/v3/utils';
import GroupModal from './Modal';

const columns = [
  {
    Header: ' ',
    accessor: 'color'
  },
  {
    Header: 'Nom',
    accessor: 'displayName',
    width: 'calc(100% - 24px)',
    Cell: ({ item }) => <Text variant="small">{item.displayName}</Text>
  }
];

const GroupList = ({
  selector,
  action,
  options = {
    fetchOnMount: false,
    useLocalState: true
  },
  params = {
    sort: 'display_name'
  },
  perPage = 25,
  filters,
  onCountChanged,
  detailUrlPrefix = '/groupes',
  ...props
}) => {
  const { path, url } = useRouteMatch();
  const history = useHistory();

  const [groups, paginationHelper, state] = useListFetcher({
    selector,
    action,
    options,
    params,
    perPage,
    filters
  });

  const detailLinkFormatter = useCallback(
    item => `${path}${detailUrlPrefix}/${item.id}`,
    [path, detailUrlPrefix]
  );

  useEffect(() => {
    if (paginationHelper.total != null && onCountChanged) {
      onCountChanged(paginationHelper.total);
    }
  }, [paginationHelper.total, onCountChanged]);

  return (
    <Fragment>
      <Table
        columns={columns}
        items={groups}
        isLoading={state.isLoading}
        linkFormatter={detailLinkFormatter}
        centerMessage={state.errorMessage}
        {...paginationHelper}
        {...props}
      />
      <Route
        path={[
          `${path}${detailUrlPrefix}/nouveau-groupe`,
          `${path}${detailUrlPrefix}/:groupId`
        ]}
      >
        <GroupModal
          onRequestClose={() => {
            history.replace(url);
          }}
        />
      </Route>
    </Fragment>
  );
};

export default GroupList;

import { get } from 'common/api';
import { CLIENT_ID_PUBLIC } from '../../../constants';

export const getSigns = params =>
  get('/v5/attachments?kind_eq=sign_road', params);

export const getDeviations = params =>
  get('/v5/deviations/projects', {
    ...params,
    clientId: CLIENT_ID_PUBLIC
  });

export const getDeviation = (id, params) =>
  get(`/v5/deviations/projects/${id}`, {
    ...params,
    clientId: CLIENT_ID_PUBLIC
  });

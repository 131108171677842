import { createDeepEqualSelector, getProps } from 'common/selector';
import { AccountSelector } from 'features/Accounts';
import { getState, selectRelations } from 'common/selector';
import isSameIds from 'utils/idUtils';

export const getPhases = state => state.phases;

export const selectOverlaps = createDeepEqualSelector(
  [getPhases],
  ({ overlapById }) => overlapById
);

export const selectPhases = createDeepEqualSelector(
  [getPhases, getState],
  ({ phasesIds, phaseById }, state) => {
    return phasesIds
      .map(id => selectRelations('phase', phaseById[id], state))
      .filter(Boolean)
      .sort((a, b) => a.activityId - b.activityId);
  }
);

export const selectPhase = createDeepEqualSelector(
  [getPhases, getProps, getState],
  ({ phaseById }, { phaseId }, state) =>
    selectRelations('phase', phaseById[phaseId], state)
);

export const selectPhaseStates = createDeepEqualSelector(
  [AccountSelector.selectCurrentAccount],
  currentAccount => {
    if (!currentAccount) {
      return [];
    }
    return currentAccount.phasesStates ? currentAccount.phasesStates : [];
  }
);

export const selectProjectPhases = createDeepEqualSelector(
  [getPhases, getProps, getState],
  ({ phaseById, phasesIds }, { projectId }, state) => {
    if (!projectId) {
      return [];
    }
    return phasesIds
      .map(id => selectRelations('phase', phaseById[id], state))
      .filter(
        phase =>
          phase &&
          (isSameIds(phase.projectId, projectId) ||
            isSameIds(phase.project?.id, projectId)) &&
          Boolean(phase.administrative) === false
      )
      .map(phase => selectRelations('phase', phase, state))
      .filter(Boolean);
  }
);

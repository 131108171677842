import { createDeepEqualSelector, getProps } from 'common/selector';
import { getState, selectRelations } from 'common/selector';

export const getPmvs = state => state.pmvs;

const formatPmv = (pmv, isLoading) => {
  if (!pmv) {
    return null;
  }
  return {
    ...pmv,
    position: {
      lat: parseFloat(pmv.lat),
      lng: parseFloat(pmv.lng)
    },
    isLoading: Boolean(isLoading),
    lines: (pmv.lines || Array(4).fill({})).map((line, index) => ({
      id: line.index || index,
      text: line.text || '',
      style: line.style || ''
    }))
  };
};

export const selectPmvs = createDeepEqualSelector(
  [getPmvs, getState],
  ({ pmvsIds, pmvById, loading }, state) =>
    pmvsIds
      .map(id =>
        formatPmv(selectRelations('pmv', pmvById[id], state), loading[id])
      )
      .filter(Boolean)
);

export const selectPmv = createDeepEqualSelector(
  [getPmvs, getProps, getState],
  ({ pmvById }, { pmvId }, state) =>
    formatPmv(selectRelations('pmv', pmvById[pmvId], state))
);

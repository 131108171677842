import React, { useMemo, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { MdArrowDropDown } from 'react-icons/md';
import Tag from 'components/v3/Tag';
import { PhaseSelector, phaseStateAsOption } from 'features/Phases';
import { useAction, useDelay } from 'components/v3/utils';
import { PhaseAction } from 'features/Phases';
import {
  SelectInputLayout,
  InputLayoutVariant
} from 'components/v3/InputLayout';
import Dropdown from 'components/v3/Dropdown';

const State = ({
  phaseId,
  value,
  onChange,
  size,
  asTag = true,
  wide = true,
  readOnly,
  ...props
}) => {
  const delay = useDelay();
  const action = useAction();
  const dispatch = useDispatch();
  const phaseStates = useSelector(PhaseSelector.selectPhaseStates, isEqual);

  const options = useMemo(() => phaseStates.map(phaseStateAsOption), [
    phaseStates
  ]);

  const currentValue = useMemo(() => phaseStateAsOption(value), [value]);

  const currentOptionRenderer = useCallback(
    ({ option }) => (
      <Tag
        displayName={option.label}
        color={option.color}
        size={size}
        wide={wide}
        trailingIcon={readOnly ? null : MdArrowDropDown}
        clickable={!readOnly}
      />
    ),
    [readOnly, size, wide]
  );

  const handleChange = useCallback(
    state => {
      if (readOnly) {
        return;
      }
      if (onChange) {
        onChange(state);
      } else if (phaseId) {
        action(() =>
          dispatch(
            PhaseAction.updatePhaseState({
              id: phaseId,
              payload: { state: state?.value, omitAttachments: true }
            })
          )
        );
      }
    },
    [readOnly, action, dispatch, phaseId, onChange]
  );

  useEffect(() => {
    if (!readOnly && asTag && !value && onChange) {
      if (options.length === 0) {
        return;
      }
      const state = options.find(option => option?.original?.default)?.original;
      delay(() => onChange(state || options[0]?.original), 32);
    }
  }, [readOnly, delay, asTag, value, onChange, options]);

  if ((!options || options.length === 0) && !value) {
    return null;
  }
  if (asTag) {
    return (
      <Dropdown
        value={currentValue}
        onChange={option => handleChange(option?.original)}
        isClearable={false}
        sortOptions={false}
        showIndicator={false}
        options={options}
        readOnly={readOnly}
      >
        <Tag
          displayName={currentValue.label}
          color={currentValue.color}
          size={size}
          wide={wide}
          trailingIcon={readOnly ? null : MdArrowDropDown}
          clickable={!readOnly}
        />
      </Dropdown>
    );
  }

  return (
    <SelectInputLayout
      label={asTag ? null : 'État'}
      isSearchable={!asTag}
      variant={asTag ? InputLayoutVariant.flat.medium : null}
      isClearable={false}
      showIndicator={!asTag}
      sortOptions={false}
      options={options}
      currentOptionRenderer={asTag ? currentOptionRenderer : null}
      readOnly={readOnly}
      {...props}
    />
  );
};

export default State;

import React from 'react';
import styled, { css } from 'styled-components';
import Tooltip from '../Tooltip';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  background: #fff;
  cursor: pointer;
  opacity: 0.84;

  &:hover:not([disabled]) {
    opacity: 1;
    box-shadow: ${({ theme }) => theme.boxShadow};
  }

  ${({ checked }) =>
    checked &&
    css`
      opacity: 1;
      background-color: ${({ theme }) => theme.primaryLight};
      color: ${({ theme }) => theme.textLight};
      > * {
        color: ${({ theme }) => theme.textLight};
      }
    `}

  &[disabled] {
    * {
      opacity: 0.5;
    }
    cursor: not-allowed;
  }
`;

const Action = ({ tooltip, checked, ...props }) => {
  return (
    <Tooltip content={tooltip}>
      <Wrapper checked={checked} {...props} />
    </Tooltip>
  );
};

export default Action;

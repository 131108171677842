import { mergeJsonApi } from 'common/reducers';
import omit from 'lodash/omit';

const initialState = {
  isLoading: false,
  isMeLoading: true,
  accountById: {},
  accountsIds: []
};

const accounts = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'ME_SUCCESS':
      return {
        ...state,
        isMeLoading: false
      };
    case 'ACCOUNTS_LOADING':
      return {
        ...state,
        isLoading: true
      };
    case 'ACCOUNTS_SUCCESS':
      return mergeJsonApi('account', state, {
        type,
        payload: {
          ...payload,
          data: payload.data.reduce((acc, account) => {
            acc[account.id] = omit(account, ['attributes.ui', 'relationships']);
            return acc;
          }, [])
        }
      });
    case 'ACCOUNT_SUCCESS':
      const prevData = state.accountById[payload.data.id];
      if (!prevData) {
        return mergeJsonApi('account', state, action);
      }
      return {
        ...mergeJsonApi(
          'account',
          {
            ...state,
            accountById: {
              ...state.accountById,
              [payload.data.id]: omit(prevData, 'ui')
            }
          },
          action
        )
      };
    case 'SELECT_ACCOUNT':
      return {
        ...mergeJsonApi('account', state, {
          payload: action.payload.account,
          type: action.type
        })
      };
    case 'LOGOUT':
      return initialState;
    default:
      return mergeJsonApi('account', state, action);
  }
};

export default accounts;

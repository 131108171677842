import { mergeJsonApi } from 'common/reducers';

const initialState = {
  layerById: {},
  layersIds: []
};

const layers = (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case 'LOGOUT':
    case 'SELECT_ACCOUNT':
      return initialState;
    default:
      return mergeJsonApi('layer', state, action);
  }
};

export default layers;

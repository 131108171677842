import { omit } from 'lodash';
import { mergeJsonApi } from 'common/reducers';
import isSameIds from 'utils/idUtils';

const initialState = {
  phaseById: {},
  phasesIds: [],
  overlapById: {}
};

const phases = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case 'NEW_PHASE_SUCCESS':
      return {
        ...mergeJsonApi('phase', state, action),
        phasesIds: [payload.data.id, ...state.phasesIds]
      };
    case 'DELETE_PHASE_SUCCESS':
      return {
        ...state,
        phaseById: omit(state.phaseById, payload),
        phasesIds: state.phasesIds.filter(id => !isSameIds(id, payload))
      };
    case 'LOGOUT':
    case 'SELECT_ACCOUNT':
      return initialState;
    default:
      return mergeJsonApi('phase', state, action);
  }
};

export default phases;

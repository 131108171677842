import React, { Fragment, useMemo } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import { PeriodSelector } from 'features/Periods';
import { List, ListItem } from '../ui/list';
import Tooltip from '../Tooltip';
import Tag from '../Tag';

const Content = styled.span.attrs(({ style, color }) => ({
  style: {
    borderColor: `transparent ${color} transparent transparent`,
    ...(style || {})
  }
}))`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 20px 20px 0;
  right: 0;
  top: 0;
  position: absolute;
  opacity: 0.5;
  cursor: help;

  &:hover {
    opacity: 1;
  }
`;

const DayHeaderView = React.memo(({ text: textProps, date }) => {
  const canShowDayHint = textProps?.includes('#DAY_HINT');
  let text = textProps;
  if (canShowDayHint) {
    text = text.replace('#DAY_HINT', '');
  }

  const periodsSelector = useMemo(
    () => state => PeriodSelector.selectPeriodsByDate(state, { date }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [date.toJSON()]
  );
  const periods = useSelector(periodsSelector, isEqual);
  const color =
    periods.length > 0
      ? periods[0]?.color !== '#000'
        ? periods[0].color
        : '#608a32'
      : null;

  return (
    <Fragment>
      {text}
      {canShowDayHint && periods.length > 0 && (
        <Tooltip
          variant="light"
          content={
            <List>
              {periods.map(period => (
                <ListItem key={period.id}>
                  <span>
                    <Tag
                      wrap
                      size="small"
                      displayName={period?.description}
                      color={
                        period?.color !== '#000' ? period.color : '#608a32'
                      }
                    />
                  </span>
                </ListItem>
              ))}
            </List>
          }
        >
          <Content color={color} />
        </Tooltip>
      )}
    </Fragment>
  );
});

DayHeaderView.displayName = 'DayHeaderView';

export default DayHeaderView;

import React, { useCallback, useMemo, useRef } from 'react';
import { v4 } from 'uuid';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import isEqual from 'lodash/isEqual';
import { MdAdd } from 'react-icons/md';
import * as Yup from 'yup';
import { TextInputField, ColorField, ERRORS } from 'components/v3/Form';
import {
  Grid,
  GridItem,
  Button,
  DashContainer,
  Text,
  Dashed
} from 'components/v3/ui';
import { TextInputLayout, InputLayoutVariant } from 'components/v3/InputLayout';
import ColorPicker from 'components/v3/ColorPicker';
import Table from 'components/v3/List/Table';
import { EntityModal } from 'components/v3/Modal';
import Checkbox from 'components/v3/Checkbox';
import {
  ProjectKindSelector,
  ProjectKindAction,
  projectKindAsValues
} from 'features/ProjectKinds';
import { activityAsValues } from 'features/ProjectKindsActivities';
import { stringToColor } from 'utils';
import { Permission, features, useAccountConfig, permissions } from 'config';
import { useDelay } from 'components/v3/utils';

const ACTIVITY_COLUMNS = [
  {
    Header: ' ',
    accessor: 'colorPicker',
    width: 48,
    textAlign: 'center',
    Cell: ({ item, onChange }) => (
      <ColorPicker
        style={{ height: 32, width: 32 }}
        value={item.color}
        onChange={color =>
          onChange({
            ...item,
            color,
            colorInitialized: true
          })
        }
      />
    )
  },
  {
    Header: 'Nom',
    accessor: 'displayName',
    width: 'calc(70% - 48px)',
    dashed: true,
    isEditable: true,
    Cell: ({ item, onChange }) => (
      <TextInputLayout
        variant={InputLayoutVariant.flat.medium}
        label="Nom de l'activité"
        required
        value={item.displayName}
        fillWidth
        onChange={displayName =>
          onChange({
            ...item,
            displayName,
            color: item.colorInitialized
              ? item.color
              : stringToColor(displayName)
          })
        }
      />
    )
  },
  {
    Header: 'Administrative?',
    accessor: 'administrative',
    width: '30%',
    textAlign: 'center',
    tooltip:
      "Activité à titre indicative, il n'y a pas de technicien / machine associé",
    Cell: ({ item, onChange }) => {
      return (
        <Checkbox
          value={Boolean(item.administrative)}
          onChange={checked => {
            onChange({
              ...item,
              administrative: checked
            });
          }}
        />
      );
    }
  }
];

const ActivityLabel = styled(Text)`
  padding-left: 16px;
  padding-top: 6px;
  padding-right: 16px;
  padding-bottom: 6px;
`;

const ActivitiesGridItem = styled(GridItem)`
  display: flex;
  flex-direction: column;

  &:focus,
  &:focus-within {
    ${ActivityLabel} {
      color: ${({ theme }) => theme.primaryLight};
    }
  }
`;

const ActivitiesContainer = styled(DashContainer)`
  border: ${({ theme }) => theme.borderInput};
  border-top-left-radius: ${({ theme }) => theme.borderInputRadius};
  border-top-right-radius: ${({ theme }) => theme.borderInputRadius};
  overflow: hidden;

  * > input,
  * > textarea {
    padding-left: 8px;
    padding-right: 8px;
    font-size: 1rem;
  }
  * > label {
    left: 3px;
    font-size: 0.875rem;
  }
  * > ${Dashed} {
    margin-left: -6px;
    width: calc(100% + 12px);
  }
`;

const AddActivityButton = styled.div`
  align-self: flex-end;
`.withComponent(Button);

const validationSchema = Yup.object().shape({
  displayName: Yup.string().required(ERRORS.REQUIRED)
});

const valuesDependencies = {
  color: {
    modifier: (values, form) => {
      if (!form.status?.color) {
        return stringToColor(values.displayName);
      } else {
        return values.color;
      }
    },
    triggers: ['displayName']
  }
};

const ProjectKindModal = ({
  onRequestClose,
  onSubmitSuccess,
  projectKind: propsProjectKind,
  ...props
}) => {
  const { hasPermission } = useAccountConfig();
  const dispatch = useDispatch();
  const delay = useDelay();
  const { params } = useRouteMatch();
  let { projectKindId } = params;
  if (propsProjectKind) {
    projectKindId = propsProjectKind.id;
  }
  const isNew = !Boolean(projectKindId);
  const activitiesRef = useRef();

  const selectProjectKind = useMemo(
    () => state =>
      ProjectKindSelector.selectProjectKindV3(state, { projectKindId }),
    [projectKindId]
  );

  let projectKind = useSelector(selectProjectKind, isEqual);
  if (!projectKind) {
    projectKind = propsProjectKind;
  }

  const createRequest = useCallback(
    payload => dispatch(ProjectKindAction.createProjectKind({ payload })),
    [dispatch]
  );

  const fetchRequest = useCallback(
    () => dispatch(ProjectKindAction.getProjectKind({ id: projectKindId })),
    [projectKindId, dispatch]
  );

  const updateRequest = useCallback(
    (id, payload) =>
      dispatch(ProjectKindAction.updateProjectKind({ id, payload })),
    [dispatch]
  );

  const deleteRequest = useCallback(
    id => dispatch(ProjectKindAction.deleteProjectKind({ id })),
    [dispatch]
  );

  const canEdit = hasPermission([
    permissions.RESOURCES_CONFIG,
    isNew ? 'project_kind.create' : 'project_kind.update'
  ]);
  const canDelete = hasPermission([
    permissions.RESOURCES_CONFIG,
    'project_kind.delete'
  ]);

  return (
    <EntityModal
      isOpen
      onRequestClose={onRequestClose}
      title={isNew ? 'Nouveau type de projet' : 'Édition du type de projet'}
      id={projectKindId}
      model="projectKind"
      entity={projectKind}
      initialStatus={{
        color: Boolean(projectKind?.id)
      }}
      validationSchema={validationSchema}
      entityAsValue={projectKindAsValues}
      fetchRequest={fetchRequest}
      createRequest={createRequest}
      updateRequest={updateRequest}
      deleteRequest={canDelete ? deleteRequest : undefined}
      onSubmitSuccess={onSubmitSuccess}
      autoSubmit={false}
      valuesDependencies={valuesDependencies}
      readOnly={!canEdit}
      {...props}
    >
      {({ values, setStatus, status, setFieldValue }) => (
        <Grid spacing={16} padded={false}>
          <GridItem width="100%">
            <TextInputField
              name="displayName"
              label="Nom"
              required
              readOnly={!canEdit}
              iconLeading={
                <ColorField
                  name="color"
                  onColorSelected={color => setStatus({ ...status, color })}
                />
              }
              leadingIconClickable
            />
          </GridItem>
          <GridItem width="100%">
            <TextInputField
              name="abbr"
              label="Abréviation"
              readOnly={!canEdit}
            />
          </GridItem>
          <Permission feature={features.PHASE_ACTIVITIES}>
            <ActivitiesGridItem width="100%">
              <ActivityLabel color="secondary" weight="semiBold">
                Activités
              </ActivityLabel>
              <ActivitiesContainer readOnly={false} fillWidth>
                <Table
                  listRef={activitiesRef}
                  columns={ACTIVITY_COLUMNS}
                  items={values.activities || []}
                  readOnly={!canEdit}
                  hasDeleteConfirmation={false}
                  headerVariant="dark"
                  onChange={activities =>
                    setFieldValue('activities', activities)
                  }
                  isDeletable
                  estimatedItemSize={48}
                  maxHeight={400}
                  minRows={1}
                />
              </ActivitiesContainer>
              {canEdit && (
                <AddActivityButton
                  disabled={
                    values.activities?.filter(
                      a => !a._destroy && !a.displayName
                    )?.length > 0
                  }
                  variant="text"
                  iconLeading={MdAdd}
                  onClick={() => {
                    setFieldValue('activities', [
                      ...(values.activities || []),
                      {
                        ...activityAsValues({
                          id: v4()
                        }),
                        isNew: true
                      }
                    ]);
                    delay(activitiesRef.current?.scroller().scrollToBottom, 50);
                  }}
                >
                  Ajouter une nouvelle activité
                </AddActivityButton>
              )}
            </ActivitiesGridItem>
          </Permission>
        </Grid>
      )}
    </EntityModal>
  );
};

export default ProjectKindModal;

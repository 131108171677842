import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';
import { sum, uniqBy } from 'lodash';
import { endOfDay, isWithinInterval } from 'date-fns';
import { formatISODate, parseDate } from 'utils/dates';
import { ExternalLink, Text } from 'components/v3/ui';
import { authenticateUrl } from 'utils';
import { API_BASE } from '../../../constants';
import { MdPrint } from 'react-icons/md';

const Container = styled.div`
  display: flex;
  width: 100%;
  padding: 0px 8px;
  justify-content: space-between;
  align-items: center;
`;

const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.textHint} !important;
  svg {
    margin-left: 8px;
  }
  ${({ to }) =>
    to &&
    css`
      &:hover {
        color: ${({ theme }) => theme.text} !important;
      }
    `}
  ${({ to }) =>
    !to &&
    css`
      cursor: default;
    `}
`;

const DayHeaderView = ({ text, userIds, phases, date: start }) => {
  const end = useMemo(
    () => endOfDay(start),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [start.toJSON()]
  );

  const phasesWithin = useMemo(
    () =>
      phases.filter(phase =>
        isWithinInterval(parseDate(phase.startAt), {
          start,
          end
        })
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [start.toJSON(), end.toJSON(), phases]
  );

  const total = useMemo(() => {
    return sum(
      uniqBy(
        phasesWithin.map(phase => phase.project),
        'id'
      ).map(
        project => (project && parseFloat(project.estimatedRevenues, '0')) || 0
      )
    );
  }, [phasesWithin]);

  return (
    <Container>
      <TextWrapper
        to={
          phasesWithin.length > 0
            ? authenticateUrl(
                `${API_BASE}/v5/plannings/print?user_ids=${userIds.join(
                  ','
                )}&between[start_at]=${formatISODate(
                  start
                )}&between[end_at]=${formatISODate(end)}`
              )
            : ''
        }
        as={phasesWithin.length ? ExternalLink : undefined}
      >
        {text}
        {phasesWithin.length > 0 && <MdPrint size={18} />}
      </TextWrapper>
      <Text currency variant="small" style={{ width: 'unset' }}>
        {total}
      </Text>
    </Container>
  );
};

export default DayHeaderView;

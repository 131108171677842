import styled, { css } from 'styled-components';

const Row = styled.div<{
  'border-bottom'?: string;
  padded?: boolean;
}>`
  position: relative;
  overflow: hidden;
  background-color: ${({ theme }) => theme.backgroundLight};
  border-radius: ${({ theme }) => theme.borderInputRadius};
  transition: all 200ms ease;
  border: 1px solid ${({ theme }) => theme.separatorLight};

  ${({ padded }) =>
    padded &&
    css`
      padding: 6px 8px;
    `}

  ${({ theme, ...props }) =>
    props['border-bottom'] === 'true' &&
    css`
    &:after {
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      content: '';
      display: block;
      border-bottom: 1px solid ${theme.separator};
  `}
`;

export default Row;

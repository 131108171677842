import React, {
  useCallback,
  useMemo,
  Fragment,
  useState,
  useEffect
} from 'react';
import { SelectField } from 'components/v3/Form';
import { SelectInputLayout } from 'components/v3/InputLayout';
import { useRedux } from 'components/v3/utils';
import TeamModal from './Modal';
import { jsonApiToObject } from 'common/api';
import { TeamSelector, TeamAction, teamAsOption } from 'features/Teams';
import { permissions, useAccountConfig } from 'config';

const formatCreateLabel = input => `Créer l'équipe ${input}`;

const TeamSelect = ({ value, onChange, ...props }) => {
  const { accountId, hasPermission } = useAccountConfig();
  const [teamModal, setTeamModal] = useState();
  const [teams, fetch] = useRedux(
    TeamSelector.selectTeams,
    TeamAction.getTeams,
    {
      fetchOnMount: false,
      useLocalState: true,
      initialParams: {
        page: 1,
        perPage: 500,
        useCache: true
      }
    }
  );

  const handleCreateOption = useCallback(input => {
    setTeamModal({
      displayName: input
    });
  }, []);

  const handleEditOption = useCallback(value => {
    setTeamModal(value);
  }, []);

  const options = useMemo(() => teamAsOption(teams), [teams]);

  const currentValue = useMemo(() => teamAsOption(value), [value]);

  useEffect(() => {
    fetch(params => params);
  }, [fetch, accountId]);

  return (
    <Fragment>
      <SelectInputLayout
        label="Equipe"
        value={currentValue}
        options={options}
        isSearchable
        onChange={onChange}
        inputCreatable={hasPermission([
          permissions.RESOURCES_CONFIG,
          'team.create'
        ])}
        createLabel="Créer une équipe"
        formatCreateLabel={formatCreateLabel}
        onCreateOption={handleCreateOption}
        optionEditable={hasPermission([
          permissions.RESOURCES_CONFIG,
          'team.update'
        ])}
        onEditOptionClick={handleEditOption}
        {...props}
      />
      {teamModal && (
        <TeamModal
          team={teamModal}
          onRequestClose={() => setTeamModal(null)}
          onSubmitSuccess={response => {
            const team = jsonApiToObject(response);
            if (props.multi) {
              onChange([...currentValue, team]);
            } else {
              onChange(team);
            }
          }}
        />
      )}
    </Fragment>
  );
};

export const TeamField = props => {
  return <SelectField component={TeamSelect} {...props} />;
};

export default TeamSelect;

import React, { useMemo, useState, Fragment, useCallback, useEffect } from 'react';
import { SelectField } from 'components/v3/Form';
import { SelectInputLayout } from 'components/v3/InputLayout';
import { useRedux } from 'components/v3/utils';
import { jsonApiToObject } from 'common/api';
import { SurfacingAction, SurfacingSelector, productAsOption } from 'features/Surfacing';
import { permissions, useAccountConfig } from 'config';
import ProductModal from './ProductModal';

const formatCreateLabel = (input) => `Créer le produit ${input}`;

const ProductSelect = ({ value, onChange, purveyorId, fetchDisabled, ...props }) => {
  const { accountId, hasPermission } = useAccountConfig();
  const [productModal, setProductModal] = useState();
  const selectProducts = useMemo(
    () => (state) => SurfacingSelector.selectProducts(state, { purveyorId }),
    [purveyorId]
  );

  const [products, fetchProducts] = useRedux(selectProducts, SurfacingAction.getProducts, {
    fetchOnMount: false,
    useLocalState: true,
    initialParams: {
      page: 1,
      sort: 'display_name',
      perPage: 1000,
      useCache: true
    }
  });

  const options = useMemo(() => products.map(productAsOption), [products]);

  const currentValue = useMemo(() => productAsOption(value), [value]);

  const handleCreateOption = useCallback((input) => {
    setProductModal({ name: input });
  }, []);

  const handleEditOption = useCallback((value) => {
    setProductModal(value);
  }, []);

  useEffect(() => {
    if (fetchDisabled) return;
    fetchProducts((params) => params);
  }, [fetchProducts, accountId, fetchDisabled]);

  return (
    <Fragment>
      <SelectInputLayout
        label="Enrobé"
        value={currentValue}
        options={options}
        isSearchable
        onChange={onChange}
        inputCreatable
        createLabel="Créer un produit"
        formatCreateLabel={formatCreateLabel}
        onCreateOption={handleCreateOption}
        optionEditable={hasPermission([permissions.RESOURCES_CONFIG, 'product.update'])}
        onEditOptionClick={handleEditOption}
        {...props}
      />
      {productModal && (
        <ProductModal
          product={productModal}
          onRequestClose={() => setProductModal(null)}
          onSubmitSuccess={(response) => {
            const product = jsonApiToObject(response);
            if (props.multi) {
              onChange([...currentValue, product]);
            } else {
              onChange(product);
            }
          }}
        />
      )}
    </Fragment>
  );
};

export const ProductField = (props) => {
  return <SelectField component={ProductSelect} {...props} />;
};

export default ProductSelect;

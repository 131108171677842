import { useCallback, useRef, useEffect, useMemo, useReducer } from 'react';
import { useSelector, DefaultRootState, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import isEqual from 'lodash/isEqual';
import uniq from 'lodash/uniq';
import useAction, { ActionBuilder } from './useAction';
import { getErrorMessage } from 'common/api';

export interface State {
  isLoading: boolean;
  result?: any | null;
  rawError?: Error | null;
  errorMessage?: string | null;
  params?: any | null;
  pagination?: any;
  reset: () => void;
}

export interface Options {
  initialParams?: any;
  fetchOnMount?: boolean;
  useLocalState?: boolean;
}

const initialState: State = {
  isLoading: true,
  result: null,
  rawError: null,
  errorMessage: null,
  params: null,
  pagination: null,
  reset: () => ''
};

const reducer = (state: State, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case 'loading':
      return {
        ...state,
        params: payload.params,
        isLoading: payload.loading,
        pagination: payload.resetPagination ? null : state.pagination,
        rawError: null,
        errorMessage: null
      };
    case 'success':
      if (!payload || payload.fromCache) {
        return {
          ...state,
          isLoading: payload.isLoading
        };
      }
      const usePagination = Array.isArray(payload.data);
      let pagination: any;
      if (usePagination) {
        pagination = payload?.meta?.pagination || {};
        const { page, totalPages } = pagination;
        let ids: Array<string> = state.pagination?.ids || [];
        if (page > 1) {
          ids = uniq([...ids, ...payload.data.map((i: any) => i.id)]);
        } else {
          ids = payload.data.map((i: any) => i.id);
        }
        pagination = {
          ...pagination,
          ids,
          hasMoreItems: page < totalPages
        };
      }
      return {
        ...state,
        isLoading: false,
        result: payload,
        rawError: null,
        errorMessage: null,
        pagination
      };
    case 'error':
      return {
        ...state,
        isLoading: false,
        rawError: payload,
        errorMessage: getErrorMessage(payload)
      };
    case 'reset':
      return { ...initialState, reset: state.reset };
    default:
      return state;
  }
};

const fallbackSelector = createSelector(
  () => '',
  () => ''
); // au cas ou on veut juste dispatch (login par exemple)

const useRedux = (
  selector?: (state: DefaultRootState) => any,
  action?: (params?: any | null) => Promise<any>,
  options?: Options
) => {
  const { initialParams, fetchOnMount = true, useLocalState } = options || {};

  const [localState, dispatchState] = useReducer(reducer, {
    ...initialState,
    params: initialParams,
    isLoading: fetchOnMount,
    reset: () => dispatchState({ type: 'reset' })
  });

  const localStateRef = useRef(localState);
  localStateRef.current = localState;
  const actionRef = useRef(action); // on utilise une ref pour ca au cas ou ce serait pas useCallback || static
  actionRef.current = action;

  const actionRunner = useAction();
  const dispatcher = useDispatch();
  const reduxValue = useSelector(selector || fallbackSelector, isEqual);

  const dispatchLocalState = useCallback(
    action => {
      if (useLocalState) {
        dispatchState(action);
      }
    },
    [useLocalState]
  );

  const dispatch: (
    params?: any | ((prevParams?: any) => any),
    requestPayload?: string,
    cancelPrevious?: boolean
  ) => ActionBuilder<any> | null = useCallback(
    (
      params?: any | ((prevParams?: any) => any),
      requestPayload?: string,
      cancelPrevious: boolean = true
    ) => {
      const action = actionRef.current;
      if (!action) {
        return null;
      }
      let requestParams: any = null;
      if (params instanceof Function) {
        requestParams = params(localStateRef.current.params);
      } else {
        requestParams = params;
      }
      dispatchLocalState({
        type: 'loading',
        payload: {
          loading: true,
          params: requestParams
        }
      });

      return actionRunner(
        cancelToken => dispatcher(action({ ...requestParams, cancelToken })),
        requestPayload,
        cancelPrevious
      )
        .onSuccess(r => dispatchLocalState({ type: 'success', payload: r }))
        .onError(e => dispatchLocalState({ type: 'error', payload: e }));
    },
    [actionRunner, dispatcher, dispatchLocalState]
  );

  useEffect(() => {
    if (fetchOnMount) {
      dispatch(localStateRef.current.params);
    }
  }, [fetchOnMount, dispatch]);

  const loadMoreItems = useCallback(() => {
    if (!localStateRef.current?.pagination || localStateRef.current.isLoading) {
      return;
    }
    dispatch((prevParams?: any) => ({
      ...(prevParams || {}),
      page: localStateRef.current.pagination.nextPage
    }));
  }, [dispatch]);

  if (localState.pagination) {
    localState.pagination.loadMoreItems = loadMoreItems;
  }

  const memoReturn = useMemo(
    () => [reduxValue, dispatch, localState],
    [reduxValue, dispatch, localState]
  );

  return memoReturn;
};

export default useRedux;

import React, { useMemo, useCallback } from 'react';
import { MdClose } from 'react-icons/md';
import InputLayout, { InputLayoutVariant } from '../InputLayout';
import Select from '../Select';

const SelectInputLayout = ({
  variant = InputLayoutVariant.default,
  isClearable = true,
  ...props
}) => {
  const { multi, onChange, value, error, onTrailingIconClick } = props;

  const currentValueStyle = useMemo(() => {
    switch (variant) {
      case InputLayoutVariant.fill:
        return multi ? { padding: '6px 12px 4px 8px' } : null;
      case InputLayoutVariant.outline:
        return multi
          ? { padding: '10px 12px 6px 8px', minHeight: '50px' }
          : null;
      case InputLayoutVariant.flat:
        return multi ? { padding: '8px 12px 8px 8px' } : null;
      case InputLayoutVariant.flat.medium:
        return multi ? { padding: '6px 10px 6px 6px' } : null;
      case InputLayoutVariant.flat.small:
        return multi ? { padding: '4px 8px 4px 4px' } : null;
      default:
        return {};
    }
  }, [variant, multi]);

  const clearable =
    isClearable &&
    !multi &&
    value != null &&
    value !== '' &&
    !error &&
    !props.readOnly;

  const handleTrailingIconClick = useCallback(() => {
    if (onChange && clearable) {
      onChange(null);
    }
    if (onTrailingIconClick) {
      onTrailingIconClick();
    }
  }, [clearable, onChange, onTrailingIconClick]);

  const clearIcon = useMemo(
    () => (clearable ? <MdClose /> : props.iconTrailing),
    [clearable, props.iconTrailing]
  );

  return (
    <InputLayout
      {...props}
      variant={variant}
      component={Select}
      currentValueStyle={currentValueStyle}
      iconTrailing={clearIcon}
      onTrailingIconClick={handleTrailingIconClick}
    />
  );
};

export default React.memo(SelectInputLayout);

import React, { useEffect, useCallback, Fragment, useMemo } from 'react';
import { Route, useRouteMatch, useHistory, useLocation } from 'react-router-dom';
import Scheduler, { SCHEDULER_TYPE } from './View';
import { PlanningSelector, PlanningAction } from 'features/Plannings';
import UserModal from 'features/Users/components/v3/Modal';
import { useRedux } from 'components/v3/utils';
import { useAccountConfig } from 'config';

const Member = () => {
  const history = useHistory();
  const location = useLocation();
  const { path, url } = useRouteMatch();
  const accountId = useAccountConfig();

  const [users, fetch] = useRedux(PlanningSelector.selectPlanningUsers, PlanningAction.getUsers, {
    fetchOnMount: false,
    initialParams: {
      perPage: 500,
      page: 1
    }
  });

  useEffect(() => {
    fetch((prevParams) => ({
      ...prevParams,
      sort: 'first_name,last_name'
    }));
  }, [accountId, fetch]);

  const handleSearch = useCallback(
    (query) => {
      fetch((prevParams) => ({
        ...prevParams,
        byQuery: query
      }));
    },
    [fetch]
  );

  const handleResourceClick = useCallback((id) => history.push(`${path}/${id}`), [history, path]);

  const resources = useMemo(() => [...users, { id: 'none', displayName: 'Sans technicien' }], [users]);

  return (
    <Fragment>
      <Scheduler
        schedulerType={SCHEDULER_TYPE.USERS}
        resources={resources}
        onResourceClick={handleResourceClick}
        resourceLabelText={`Techniciens (${users.length})`}
        onSearch={handleSearch}
      />
      <Route exact path={[`${path}/new`, `${path}/:userId`]}>
        <UserModal
          onRequestClose={() => {
            if (location.key) {
              history.goBack();
            } else {
              history.replace(url);
            }
          }}
        />
      </Route>
    </Fragment>
  );
};

export default Member;

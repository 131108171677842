import React from 'react';
import styled from 'styled-components';
import Tag, { TagContainer } from 'components/v3/Tag';
import Card, { CardHeader, CardContent } from 'components/v3/ui/card';
import { Text } from 'components/v3/ui';
import EventUsers from 'pages/Plannings/Timeline/View/EventUsers';

const Content = styled(CardContent)`
  margin-top: 8px;
`;

const DescriptionText = styled(Text)`
  margin-bottom: 8px;
`;

const HolidayCard = ({ holiday, children, overlaps, ...props }) => {
  const { users, description, kind } = holiday;
  return (
    <Card {...props}>
      <CardHeader variant="tight">
        <TagContainer alignEnd>
          {kind && (
            <span>
              <Tag
                size="small"
                displayName={kind?.displayName}
                color={kind?.color || '#8A8A8D'}
              />
            </span>
          )}
        </TagContainer>
      </CardHeader>
      <Content>
        {Boolean(description?.trim().length) && (
          <DescriptionText weight="semiBold">{description}</DescriptionText>
        )}
        {Boolean(users?.length) && (
          <EventUsers users={users} overlaps={overlaps} maxCount={20} />
        )}
        {children}
      </Content>
    </Card>
  );
};

export default HolidayCard;

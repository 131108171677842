import { createDeepEqualSelector, getProps } from 'common/selector';
import { getState, selectRelations } from 'common/selector';
import { startOfDay, startOfWeek, endOfWeek, startOfYear, endOfYear, getISOWeek, addWeeks } from 'date-fns';
import { sortBy } from 'lodash';
import isSameIds from 'utils/idUtils';

export const getPurveyors = (state) => state.purveyors;
export const getRawMaterials = (state) => state.rawMaterials;
export const getProducts = (state) => state.products;
export const getOrders = (state) => state.orders;

export const selectPurveyors = createDeepEqualSelector(
  [getPurveyors, getState],
  ({ purveyorsIds, purveyorById }, state) =>
    purveyorsIds.map((id) => selectRelations('purveyor', purveyorById[id], state)).filter(Boolean)
);

export const selectPurveyor = createDeepEqualSelector(
  [getPurveyors, getProps, getState],
  ({ purveyorById }, { purveyorId } = {}, state) => {
    if (!purveyorId) return null;
    return {
      ...selectRelations('purveyor', purveyorById[purveyorId], state),
      productIds: purveyorById[purveyorId]?.relationships?.products?.data?.map(({ id }) => id)
    };
  }
);

export const selectProduct = createDeepEqualSelector(
  [getProducts, getProps, getState],
  ({ productById }, { productId }, state) => {
    return selectRelations('product', productById[productId], state);
  }
);

export const selectProducts = createDeepEqualSelector(
  [getProducts, selectPurveyor, getState],
  ({ productById }, purveyor, state) => {
    let products;
    if (purveyor) {
      products = purveyor.productIds?.map((id) => productById[id]) ?? [];
    } else {
      products = Object.values(productById);
    }
    return products.map((product) => selectRelations('product', product, state)).filter(Boolean);
  }
);

export const selectProductsByIds = createDeepEqualSelector(
  [getProducts, getProps, getState],
  ({ productById }, { ids }, state) => {
    return ids.map((id) => selectRelations('product', productById[id], state)).filter(Boolean);
  }
);

export const selectRawMaterial = createDeepEqualSelector(
  [getRawMaterials, getProps, getState],
  ({ rawMaterialById }, { rawMaterialId }, state) => {
    return selectRelations('rawMaterial', rawMaterialById[rawMaterialId], state);
  }
);
export const selectRawMaterials = createDeepEqualSelector([getRawMaterials], ({ rawMaterialById }) =>
  sortBy(Object.values(rawMaterialById).filter(Boolean), 'position')
);

export const selectOrdersWeeksByYear = createDeepEqualSelector(
  [getOrders, getProps, getState],
  ({ orderById }, { purveyorId, year }, state) => {
    const orders = Object.values(orderById || [])
      .map((order) => selectRelations('order', order, state))
      .filter(
        (order) =>
          (isSameIds(order?.purveyor?.id, purveyorId) || isSameIds(order?.purveyorId, purveyorId)) &&
          order.years.includes(year)
      );

    const yearDate = new Date(year, 1, 1);

    let week = startOfDay(
      startOfWeek(startOfYear(yearDate), {
        weekStartsOn: 1
      })
    );
    const lastWeek = endOfWeek(endOfYear(yearDate), {
      weekStartsOn: 1
    });

    let ordersByWeek = [];
    let weekOrders = [];
    while (week <= lastWeek) {
      let isoWeek = getISOWeek(week);
      weekOrders = orders.filter((o) => o.weeks.includes(isoWeek));
      ordersByWeek.push({
        date: week,
        // isEmpty: weekOrders.filter(o => o.hasItems).length === 0,
        isEmpty: weekOrders.length === 0
      });
      week = addWeeks(week, 1);
    }
    return ordersByWeek;
  }
);

export const selectOrdersByWeek = createDeepEqualSelector(
  [getOrders, getProps, getState],
  ({ orderById }, { purveyorId, year, week }, state) => {
    return Object.values(orderById || [])
      .map((order) => selectRelations('order', order, state))
      .filter(
        (order) =>
          (isSameIds(order?.purveyor?.id, purveyorId) || isSameIds(order?.purveyorId, purveyorId)) &&
          order.years.includes(year) &&
          order.weeks.includes(week) &&
          order.phase
      );
  }
);

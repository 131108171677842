export const getPlural = type => {
  switch (type) {
    case 'address':
      return 'addresses';
    case 'gpsBox':
      return 'gpsBoxes';
    case 'activity':
      return 'activities';
    case 'projectActivity':
      return 'projectActivities';
    case 'tax':
      return 'taxes';
    case 'delivery':
      return 'deliveries';
    default:
      return `${type}s`;
  }
};

export const getStateName = type => {
  return getPlural(type);
};

import React, { Fragment, useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import isEqual from 'lodash/isEqual';
import { IoIosMoon } from 'react-icons/io';
import { MdWarning } from 'react-icons/md';
import { BiMailSend } from 'react-icons/bi';
import { fontSize } from 'ui';
import { Text } from 'components/v3/ui';
import { UiSelector } from 'features/UiPreferences';
import ChipLayout from 'components/v3/Chip/ChipLayout';
import { PHASE_LINE_ATTRIBUTES } from 'pages/Administration/AccountSettings/AccountSettingsPlanningContent';
import EventWrapper from './EventWrapper';
import EventUsers from './EventUsers';
import { isReportSent } from './utils';
import Tooltip from 'components/v3/Tooltip';

const Item = styled.div`
  position: sticky;
  left: 0px;
  right: 0px;
  bottom: 0px;
  top: 0px;
  overflow: hidden;
`;

const ItemLine = styled.div`
  font-size: ${fontSize(14)};
  display: flex;
  align-items: center;

  ${({ singleLine }) =>
    singleLine &&
    css`
      height: 100%;
      white-space: nowrap;
    `}

  > svg {
    min-width: 18px;
  }

  > *:not(:only-child):not(:last-child) {
    margin-right: 4px;
  }
`;

const LineContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const LineContent = ({ maxLines, style, elements, overlaps, singleLine }) => {
  return (
    elements?.map(({ data, value, type }) => {
      switch (type) {
        case 'text':
          return (
            <Text key={value} variant="small" color="light" weight={style || 'semiBold'} maxLines={maxLines}>
              {data}
            </Text>
          );
        case 'object':
          switch (value) {
            case PHASE_LINE_ATTRIBUTES.PhaseUsers.value:
              return (
                <EventUsers
                  tooltip={false}
                  size={singleLine ? 'icon' : 'small'}
                  key={value}
                  users={data || []}
                  overlaps={overlaps}
                />
              );
            case PHASE_LINE_ATTRIBUTES.PhaseMachines.value:
              return (
                <ChipLayout
                  key={value}
                  values={data.map((d) => ({
                    value: d.id,
                    label: d.displayName
                  }))}
                  variant="caption"
                />
              );
            case PHASE_LINE_ATTRIBUTES.PhaseProducts.value:
              return (
                <LineContentWrapper key={value}>
                  {data?.map((d) => {
                    return (
                      <Fragment key={d.id}>
                        {d.purveyor?.displayName && (
                          <Text variant="small" color="lightSecondary" weight={style || 'semiBold'}>
                            {d.purveyor.displayName}
                          </Text>
                        )}
                        <Text variant="small" color="light" weight={style || 'semiBold'}>
                          {'AQP: '}
                          {d.product?.aqpNumber} {`(${d.estimatedTotal || d.total || 0} t)`}
                        </Text>
                      </Fragment>
                    );
                  })}
                </LineContentWrapper>
              );
            default:
              return (
                <Text key={value} variant="small" color="light" weight={style || 'semiBold'} maxLines={maxLines}>
                  {data}
                </Text>
              );
          }
        default:
          return (
            <Text key={value} variant="small" color="light" weight="semiBold">
              {data}
            </Text>
          );
      }
    }) || null
  );
};

const SchedulerEvent = React.memo(({ linesData, night, customData, overlaps, warning }) => {
  const singleLine = linesData.length === 1;
  return (
    <Item>
      {linesData.map(({ position, ...line }) => {
        return (
          <ItemLine singleLine={singleLine} key={position || 0}>
            {position === 0 && night && <IoIosMoon size={18} />}
            {position === 0 && warning && <MdWarning size={18} />}
            {position === 0 && isReportSent(customData) && (
              <Tooltip content="Rapport envoyé">
                <span style={{ cursor: 'help' }}>
                  <BiMailSend size={18} />
                </span>
              </Tooltip>
            )}
            <LineContent singleLine={singleLine} overlaps={overlaps} {...line} />
          </ItemLine>
        );
      })}
    </Item>
  );
}, isEqual);

const Content = React.memo(({ id, extendedProps }) => {
  const uiSelector = useMemo(
    () => (state) => UiSelector.selectUiKeys('planning.showPhasesChecked')(state)?.planning || {},
    []
  );
  const { showPhasesChecked } = useSelector(uiSelector, isEqual);

  return (
    <EventWrapper id={id}>
      <SchedulerEvent
        key={id}
        linesData={extendedProps.lines}
        night={extendedProps.night}
        customData={extendedProps.customData}
        warning={extendedProps.source === 'phase' && showPhasesChecked && !extendedProps.checked}
      />
    </EventWrapper>
  );
}, isEqual);

const Event = ({ event }) => {
  return <Content id={event.id} extendedProps={event.extendedProps || {}} />;
};

export default Event;

import { createAction } from 'redux-actions';
import omit from 'lodash/omit';
import * as Api from './api';
import { prepareAttributes, shouldFetch } from 'common/actions';

const preparePayload = payload =>
  prepareAttributes(
    {
      ...payload,
      organizationId: payload.organization?.id || null
    },
    'address'
  );

const contactsLoading = createAction('CONTACTS_LOADING');
const contactsSuccess = createAction('CONTACTS_SUCCESS');
const searchContactsSuccess = createAction('SEARCH_CONTACTS_SUCCESS');
const contactsError = createAction('CONTACTS_ERROR');
export const getContacts = payload => async (dispatch, getState) => {
  const { useCache, isSearchRequest, ...params } = payload || {};
  const state = getState();
  const action = isSearchRequest
    ? 'SEARCH_CONTACTS_SUCCESS'
    : 'CONTACTS_SUCCESS';
  let canUseCache = true;
  if (isSearchRequest) {
    const prevOrganizationId = getState().contacts?.SEARCH_CONTACTS_SUCCESS
      ?.organizationId;
    if (prevOrganizationId !== params.organizationId) {
      canUseCache = false;
    }
  }
  if (
    !useCache ||
    !canUseCache ||
    shouldFetch('contact', params, state, action)
  ) {
    return fetchContacts(params, isSearchRequest, dispatch);
  } else {
    return {
      fromCache: true,
      isLoading: getState().contacts.isLoading
    };
  }
};
const fetchContacts = async (payload, isSearchRequest, dispatch) => {
  if (!isSearchRequest) {
    dispatch(contactsLoading());
  }
  try {
    const result = await Api.getContacts(payload);
    if (isSearchRequest) {
      dispatch(
        searchContactsSuccess({
          ...result,
          organizationId: payload.organizationId,
          requestParams: omit(payload, 'cancelToken')
        })
      );
    } else {
      dispatch(
        contactsSuccess({
          ...result,
          requestParams: omit(payload, 'cancelToken')
        })
      );
    }
    return result;
  } catch (error) {
    dispatch(contactsError(error));
    throw error;
  }
};

const receiveCreateContact = createAction('NEW_CONTACT_SUCCESS');
export const createContact = ({ payload, ...config }) => async dispatch => {
  const result = await Api.createContact(preparePayload(payload), config);
  dispatch(receiveCreateContact(result));
  return result;
};

const receiveContact = createAction('CONTACT_SUCCESS');
export const getContact = ({ id, ...payload }) => async dispatch => {
  const result = await Api.getContact(id, payload);
  dispatch(receiveContact(result));
  return result;
};

const receiveUpdateContact = createAction('UPDATE_CONTACT_SUCCESS');
export const updateContact = ({
  id,
  payload: contact,
  ...config
}) => async dispatch => {
  const result = await Api.putContact(id, preparePayload(contact), config);
  dispatch(receiveUpdateContact(result));
  return result;
};

const receiveDeleteContact = createAction('DELETE_CONTACT_SUCCESS');
export const deleteContact = ({ id, ...config }) => async dispatch => {
  const response = await Api.deleteContact(id, config);
  dispatch(receiveDeleteContact(id));
  return response;
};

import { createAction } from 'redux-actions';
import * as Api from './api';

const preparePayload = payload => {
  return {
    ...payload,
    machineId: payload.machine?.id || null
  };
};

const receiveGpsBoxes = createAction('GPS_BOXES_SUCCESS');
export const getGpsBoxes = payload => async dispatch => {
  const gpsBoxes = await Api.getGpsBoxes(payload);
  dispatch(receiveGpsBoxes(gpsBoxes, payload));
  return gpsBoxes;
};

const receiveGpsBox = createAction('GPS_BOX_SUCCESS');
export const getGpsBox = ({ id, ...payload }) => async dispatch => {
  const result = await Api.getGpsBox(id, payload);
  dispatch(receiveGpsBox(result));
  return result;
};

const receiveUpdateGpsBox = createAction('UPDATE_GPS_BOX_SUCCESS');
export const updateGpsBox = ({ id, payload, ...config }) => async dispatch => {
  const result = await Api.putGpsBox(id, preparePayload(payload), config);
  dispatch(receiveUpdateGpsBox(result));
  return result;
};

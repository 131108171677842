import React from 'react';
import styled from 'styled-components';
import Option from './Option';
import RawCheckbox from '../Checkbox';

const CheckableWrapper = styled.div`
  display: inline-flex;
  align-items: center;
`;

const Checkbox = styled(RawCheckbox)`
  padding: 0px;
  padding-right: 12px;
  pointer-events: none;
`;

export default React.memo(({ checked, ...props }) => (
  <CheckableWrapper>
    <Checkbox size={20} value={checked} />
    <Option {...props} />
  </CheckableWrapper>
));

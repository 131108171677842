import React, { Fragment } from 'react';
import { camelize } from 'humps';
import { get } from 'lodash';
import { parseISO, format } from 'date-fns';
import fr from 'date-fns/locale/fr';

export const getParams = ({ search }) =>
  search
    .slice(1)
    .split('&')
    .map(chunk => {
      const keyValue = chunk.split('=');
      if (keyValue.length === 1) return chunk;

      return {
        [camelize(keyValue[0])]: keyValue[1]
      };
    })
    .reduce((acc, curr) => ({ ...acc, ...curr }), {});

export const merge = (empirical = {}, payload = {}, transform) => {
  let output = empirical;

  for (const key in payload) {
    output = {
      ...output,
      [key]: {
        ...(output[key] || {}),
        ...(transform ? transform(payload[key] || {}) : payload[key] || {})
      }
    };
  }

  return output;
};

export const deepMerge = (empirical = {}, payload = {}, transform) => {
  let output = empirical;

  for (const key in payload) {
    if (
      output[key] !== null &&
      typeof output[key] === 'object' &&
      !Array.isArray(output[key])
    ) {
      output = {
        ...output,
        [key]: payload[key]
          ? deepMerge(
              output[key],
              transform ? transform(payload[key]) : payload[key]
            )
          : null
      };
    } else {
      output = {
        ...output,
        [key]: transform ? transform(payload[key]) : payload[key]
      };
    }
  }
  return output;
};

export const capitalize = (input = '') => {
  if (!Boolean(input)) {
    return '';
  }
  if (input.toLowerCase() === input) {
    return input
      .replace(/(?:^|[\s-/.'])\w/g, match => match.toUpperCase())
      .replace(/\s[a-zA-Z{1,2}]\s/g, match => match.toLowerCase());
  }
  if (input.toUpperCase() === input) {
    return input
      .toLowerCase()
      .replace(/(?:^|[\s-/.'])[\wéàèáùòóôûêâ]/g, match => match.toUpperCase())
      .replace(/\s[a-zA-Z{1,2}]\s/g, match => match.toLowerCase());
  }
  return input;
};

export const lightenDarkenColor = (col, amt) => {
  if (!col) {
    return '';
  }

  var usePound = false;

  if (col[0] === '#') {
    col = col.slice(1);
    usePound = true;
  }

  var num = parseInt(col, 16);

  var r = (num >> 16) + amt;

  if (r > 255) r = 255;
  else if (r < 0) r = 0;

  var b = ((num >> 8) & 0x00ff) + amt;

  if (b > 255) b = 255;
  else if (b < 0) b = 0;

  var g = (num & 0x0000ff) + amt;

  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
};

export const lightenDarkenRgb = (color, amt) => {
  if (!!!color || !color.includes('rgb')) {
    return '';
  }
  color = color
    .replace(/[() +rgba]/g, '')
    .split(',')
    .map(Number);
  return `rgba(${color[0] + amt}, ${color[1] + amt}, ${color[2] + amt}, ${
    color[3] ? color[3] : 1
  })`;
};

export const authenticateUrl = url => {
  if (!url) {
    return null;
  }
  try {
    let newUrl = new URL(url);
    const params = newUrl.searchParams;
    params.append('access_token', localStorage.getItem('accessToken'));
    params.append(
      'account_id',
      get(JSON.parse(localStorage.getItem('state')), 'ui.currentAccountId')
    );
    return `${newUrl.origin}${newUrl.pathname}?${params}`;
  } catch (e) {
    console.warn(url, e);
    return null;
  }
};

export const formatDate = (date, frmt) =>
  format(parseISO(date), frmt, { locale: fr });

export const calculateAspectRatioFit = (
  srcWidth,
  srcHeight,
  maxWidth,
  maxHeight
) => {
  var ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
  return { width: srcWidth * ratio, height: srcHeight * ratio };
};

export const withOpacity = (color, opacity) => {
  if (!color) {
    return convertHex('#CCCCCC', opacity);
  }
  if (color.includes('rgb')) {
    color = color.replace(/[() +rgba]/g, '').split(',');
    return `rgba(${color[0]}, ${color[1]}, ${color[2]}, ${opacity})`;
  }
  return convertHex(color, opacity);
};

export const convertHex = (hex, opacity) => {
  if (!hex) {
    return convertHex('#CCCCCC');
  }
  if (hex.includes('rgb')) {
    return hex;
  }
  hex = hex.replace('#', '');
  if (hex.length === 8) {
    opacity = parseInt(hex.substring(0, 2), 16) / 255;
    hex = hex.substring(2, 8);
  } else if (!opacity) {
    opacity = 1;
  }
  let r;
  let g;
  let b;
  if (hex.length === 6) {
    r = parseInt(hex.slice(0, 2), 16);
    g = parseInt(hex.slice(2, 4), 16);
    b = parseInt(hex.slice(4, 6), 16);
  } else {
    r = parseInt(`${hex.slice(0, 1)}${hex.slice(0, 1)}`, 16);
    g = parseInt(`${hex.slice(1, 2)}${hex.slice(1, 2)}`, 16);
    b = parseInt(`${hex.slice(2, 3)}${hex.slice(2, 3)}`, 16);
  }
  return `rgba(${r},${g},${b},${opacity})`;
};

export const getContrastedTextColor = (color, simple) => {
  const rgba = convertHex(color).match(/\d+/g);
  const r = rgba[0];
  const g = rgba[1];
  const b = rgba[2];
  const a = rgba.length > 2 ? rgba[3] : 1;

  const brightness = r * 0.299 + g * 0.587 + b * 0.114 + (1 - a) * 255;

  return brightness > 186 ? 'rgba(0, 0, 0, 0.87)' : 'rgb(255, 255, 255)';
};

export const normalizeString = string =>
  string
    ? string
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
    : undefined;

export const upperFirstLetter = string => {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : '';
};

export const getInitials = string => {
  let names = string.split(' '),
    initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
    initials += names[names.length - 1].slice(-1).toUpperCase();
  }
  return initials;
};

export const debounce = (fn, delay) => {
  let timeoutId;
  return function (...args) {
    clearInterval(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), delay);
  };
};

export const stringToColor = str => {
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  var colour = '#';
  for (var j = 0; j < 3; j++) {
    var value = (hash >> (j * 8)) & 0xff;
    colour += ('00' + value.toString(16)).substr(-2);
  }
  return colour;
};

export const splitN = text =>
  text &&
  typeof text.split === 'function' && (
    <Fragment>
      {text.split('\n').map((item, key) => {
        return (
          <Fragment key={key}>
            {item}
            <br />
          </Fragment>
        );
      })}
    </Fragment>
  );

export const parameterizedString = (...args) => {
  const str = args[0];
  const params = args.filter((arg, index) => index !== 0);
  if (!str) return '';
  return str.replace(/%[0-9][$][a-z]+/g, matchedStr => {
    const variableIndex = matchedStr.replace(/[^[0-9]/gi, '') - 1;
    const variable = params[variableIndex];
    if (typeof params[variableIndex] === 'string') {
      return `"${variable}"`;
    } else {
      return variable;
    }
  });
};

export const asNumber = num => (num == null ? null : Number(num || 0));

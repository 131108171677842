import React, {
  useState,
  useRef,
  useMemo,
  useEffect,
  useCallback
} from 'react';
import styled, { css } from 'styled-components';
import { FaEdit } from 'react-icons/fa';
import { ProgressBar, ButtonIcon } from '../ui';
import { normalizeString } from 'utils';
import Dropdown from '../Dropdown';
import Option, { CheckableOption } from '../Option';
import ChipLayout from '../Chip/ChipLayout';
import { AvatarWrapper } from '../Avatar/Avatar';
import { toaster } from '../Toast';
import { Text, Button } from '../ui';
import { DebouncedInput } from '../ui/input';
import { measureInputWidth, useAction } from '../utils';
import { sortString } from 'utils/sort';
import { fontSize } from 'ui';

const CREATE_VALUE = 'Select.Create';
const EMPTY_VALUE = 'Select.Empty';

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  outline: none;

  * > ${AvatarWrapper} {
    width: 26px;
    min-width: 26px;
    height: 26px;
    min-height: 26px;
    font-size: ${fontSize(12)};
    border-width: 1px;
  }
  ${({ isSearchable }) =>
    !isSearchable &&
    css`
      cursor: pointer;
      align-items: center;
    `}
`;

const LoadingIndicator = styled(ProgressBar)`
  position: absolute;
  bottom: 0px;
  z-index: 1;
`;

export const CurrentOption = styled.div`
  padding-left: 16px;
  padding-right: ${({ hasIndicator }) => (hasIndicator ? 24 : 8)}px;
`.withComponent(Option);

const EditOptionButton = styled.div`
  transition: all 0.2s ease;
  min-width: 0px;
  min-height: 0px;
  overflow: hidden;
`.withComponent(ButtonIcon);

const CurrentOptionWrapper = styled.div`
  outline: none;
  max-width: 100%;
  display: flex;
  z-index: 1;
  width: 100%;
  ${({ isSearchable, fitCurrentOptionWidth }) =>
    isSearchable && !fitCurrentOptionWidth
      ? css`
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        `
      : css`
          position: relative;
          display: flex;
        `};
  opacity: ${({ inputValue, isMenuOpen }) =>
    inputValue ? 0 : isMenuOpen ? 0.7 : 1};

  ${EditOptionButton} {
    width: 0px !important;
    & ~ ${CurrentOption} {
      transition: padding-left 0.2s ease;
    }
  }

  ${({ isMenuOpen }) =>
    isMenuOpen &&
    css`
      ${EditOptionButton} {
        & ~ ${CurrentOption} {
          padding-left: 0px;
        }
        margin-left: 16px;
        margin-right: 8px;
        width: 24px !important;
        min-width: 24px;
      }
    `}

  &:hover {
    ${EditOptionButton} {
      & ~ ${CurrentOption} {
        padding-left: 0px;
      }
      margin-left: 16px;
      margin-right: 8px;
      width: 24px !important;
      min-width: 24px;
    }
  }
`;

const CreateButton = styled.div`
  padding: 0px;
  min-height: unset;
  text-transform: initial;
`.withComponent(Button);

const CreateMessage = ({
  inputValue,

  createLabel,
  formatCreateLabel
}) => {
  const hasInput = inputValue.length > 0;
  return (
    <CreateButton variant="text">
      {hasInput ? formatCreateLabel(inputValue) : createLabel}
    </CreateButton>
  );
};

const NoOptionsMessage = ({ inputValue, formatEmptyLabel }) => {
  const hasInput = inputValue.length > 0;
  return (
    <Text>
      {hasInput ? formatEmptyLabel(inputValue) : 'Aucune option disponible'}
    </Text>
  );
};

const isOptionChecked = (values, option) =>
  Array.isArray(values) && Boolean(values.find(v => v.value === option.value));

const renderCheckableOption = props => {
  const { currentValue, option } = props;
  return (
    <CheckableOption
      checked={isOptionChecked(currentValue, option)}
      {...props}
    />
  );
};

const MultiInput = styled.div`
  display: flex;
  flex: 0 0 auto;
  align-self: center;
  padding: 2px 4px 2px 10px !important;
  min-width: 50px;
  min-height: 30px;

  ${({ position }) => css`
    position: ${position};
  `}
`.withComponent(DebouncedInput);

const Input = styled.div`
  ${props =>
    props['fill-option'] &&
    css`
      position: absolute;
      width: 100%;
      height: 100%;
    `}
`.withComponent(DebouncedInput);

const Select = ({
  value: propsValue,
  onChange,
  options,
  loadAsyncOptions,
  optionsFormatter,
  currentOptionRenderer,

  onInputChange,

  mapOptionValue,
  multi = false,
  inline = false,
  optionsCheckable = false,
  limitValues = 0,
  freeSolo = false,

  fitCurrentOptionWidth,
  textArea = false,
  maxRows = undefined,

  isLoading = false,
  isSearchable = true,
  isClearable,
  closeOnSelect = true,
  showIndicator = true,

  inputCreatable = false,
  createLabel = 'Créer',
  formatCreateLabel = inputValue => `Créer ${inputValue}`,
  onCreateOption,
  optionEditable,
  onEditOptionClick,

  emptyMessage = true,
  formatEmptyLabel = inputValue =>
    `Il n'y a pas de résultat pour: ${inputValue}`,

  debounceInput = false,
  debounceDelay = 300,

  filter = undefined, // function
  filterOptions = isSearchable,
  sortOptions = true,
  sortOptionsBy = 'label',

  style,
  currentValueStyle,

  readOnly,
  disabled,
  maxWidth = '100%',

  ...props
}) => {
  if (optionsCheckable && !multi) {
    throw new Error('optionsCheckable must be used with multi === true');
  }
  if (multi && propsValue && !Array.isArray(propsValue)) {
    throw new Error('multi must be used with an Array value');
  }

  const inputRef = useRef();
  const action = useAction();

  const value = useMemo(() => {
    if (!mapOptionValue) {
      return propsValue;
    } else if (!options) {
      return propsValue;
    } else if (multi) {
      return Array.isArray(propsValue)
        ? propsValue.map(v => options.find(o => o.value === v)).filter(Boolean)
        : [];
    } else {
      return options.find(o => o.value === propsValue);
    }
  }, [mapOptionValue, multi, propsValue, options]);

  const activeOptionValue =
    freeSolo || mapOptionValue ? (value != null ? value : null) : value?.value;

  const hasValue = freeSolo
    ? Boolean(value)
    : !(activeOptionValue === undefined || activeOptionValue === null) ||
      (multi && Array.isArray(value) && value.length > 0);

  const activeOptionLabel = freeSolo
    ? value?.label != null
      ? value.label
      : value
    : value?.label || '';
  const enabled = !disabled && !readOnly;

  const [state, setState] = useState({
    isMenuOpen: false,
    inputValue: freeSolo ? activeOptionLabel || '' : '',
    loadingAsync: false,
    asyncOptions: null
  });
  const { isMenuOpen, inputValue, loadingAsync, asyncOptions } = state;
  const loading = loadingAsync || isLoading;

  const setMenuOpen = useCallback(
    open => {
      setState(s => {
        const isOpen = typeof open === 'function' ? open(s.isMenuOpen) : open;
        if (s.isMenuOpen === isOpen) {
          return s;
        }
        return {
          ...s,
          isMenuOpen: isOpen,
          inputValue: freeSolo ? s.inputValue : ''
        };
      });
    },
    [freeSolo]
  );

  const setInputValue = useCallback(v => {
    if (typeof v === 'function') {
      setState(s => {
        const newInputValue = v(s.inputValue);
        if (newInputValue === s.inputValue) {
          return s;
        } else {
          return { ...s, inputValue: newInputValue };
        }
      });
    } else {
      setState(s => ({ ...s, inputValue: v }));
    }
  }, []);

  const handleChange = useCallback(
    value => {
      if (multi) {
        onChange(value.map(v => (mapOptionValue ? v.value : v.original || v)));
      } else {
        onChange(
          value != null
            ? mapOptionValue
              ? value.value
              : value.original || value
            : null
        );
      }
    },
    [multi, onChange, mapOptionValue]
  );

  const handleSelectOption = option => {
    if (option && option.value === EMPTY_VALUE) {
      return;
    }
    if (option && option.value === CREATE_VALUE) {
      onCreateOption(inputValue);
      setMenuOpen(false);
      return;
    }
    if (multi) {
      if (optionsCheckable && isOptionChecked(value, option)) {
        handleChange((value || []).filter(v => v.value !== option.value));
      } else {
        handleChange([...(value || []), option]);
      }
    } else {
      if (freeSolo) {
        setState({
          isMenuOpen: false,
          inputValue: (option && option.label) || ''
        });
        if (inputRef.current) {
          inputRef.current.reset();
        }
      }
      handleChange(option);
    }
    if (!freeSolo) {
      setInputValue('');
    }
    if (inputRef.current) {
      inputRef.current.reset();
    }
    if ((!multi && closeOnSelect) || option.closeOnSelect) {
      setMenuOpen(false);
    }
  };

  const handleInputChange = useCallback(
    input => {
      if (onInputChange) {
        onInputChange(input);
      }
    },
    [onInputChange]
  );

  const handleDebouncedInputChange = useCallback(
    input => {
      if (loadAsyncOptions) {
        setState(s => ({
          ...s,
          asyncOptions: input ? s.asyncOptions : null,
          loadingAsync: input ? Boolean(loadAsyncOptions) : false
        }));
      }
      handleInputChange(input);
    },
    [handleInputChange, loadAsyncOptions]
  );

  useEffect(() => {
    if (!inputRef.current || !isMenuOpen) {
      return;
    }
    if (freeSolo && activeOptionLabel) {
      setInputValue(v => (activeOptionLabel !== v ? activeOptionLabel : v));
    }
  }, [isMenuOpen, freeSolo, activeOptionLabel, setInputValue]);

  useEffect(() => {
    if (!inputRef.current || !isMenuOpen) {
      return;
    }
    if (freeSolo && activeOptionLabel !== inputValue) {
      setInputValue(inputValue);
      handleInputChange(inputValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMenuOpen, setInputValue, handleInputChange, freeSolo, inputValue]);

  useEffect(() => {
    if (!inputRef.current) {
      return;
    }
    if (freeSolo && activeOptionLabel !== inputValue) {
      setInputValue(activeOptionLabel);
      inputRef.current.blur();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [freeSolo, activeOptionLabel, setInputValue]);

  useEffect(() => {
    if (!inputRef.current || isMenuOpen) {
      return;
    }
    if (!freeSolo) {
      inputRef.current.reset();
      setInputValue('');
    }
  }, [freeSolo, isMenuOpen, setInputValue]);

  // useEffect(() => {
  //   if (!inputRef.current || isMenuOpen) {
  //     return;
  //   }
  //   if (freeSolo && activeOptionLabel !== inputValue) {
  //     handleInputChange(inputRef.current.value());
  //     inputRef.current.reset();
  //   } else if (!freeSolo) {
  //     inputRef.current.reset();
  //   }
  // }, [isMenuOpen, freeSolo, inputValue, activeOptionLabel, handleInputChange]);

  useEffect(() => {
    if (loadingAsync) {
      action(
        () => loadAsyncOptions({ query: inputValue }),
        'loadAsyncOptions',
        true
      )
        .onSuccess(result => {
          setState(s => ({
            ...s,
            asyncOptions: result,
            loadingAsync: false
          }));
        })
        .onError(e => {
          toaster.error(e);
          setState(s => ({
            ...s,
            loadingAsync: false
          }));
        });
    }
  }, [action, loadAsyncOptions, loadingAsync, inputValue]);

  useEffect(() => {
    if (!inputRef.current) {
      return;
    }
    if (isMenuOpen) {
      inputRef.current.focus();
    } else {
      inputRef.current.blur();
    }
  }, [isMenuOpen]);

  useEffect(() => {
    if (!inputRef.current) {
      return;
    }
    if (!hasValue) {
      inputRef.current.reset();
      setInputValue('');
    }
  }, [hasValue, setInputValue]);

  const memoOptions = useMemo(() => {
    let opts = (inputValue ? asyncOptions || options : options) || [];
    if (filterOptions) {
      const normalizedInput = normalizeString(inputValue);
      opts = opts.filter(option => {
        if (filter) {
          return filter(option);
        } else {
          if (!normalizedInput || normalizedInput.trim().length === 0) {
            return true;
          }
          if (option.label) {
            if (
              normalizedInput &&
              normalizeString(option.label).indexOf(normalizedInput) !== -1
            ) {
              return true;
            } else {
              return false;
            }
          }
          return true;
        }
      });
    }
    if (Array.isArray(value) && !optionsCheckable) {
      opts = opts.filter(option => !value.find(v => v.value === option.value));
    }
    opts = sortOptions ? opts.sort(sortString(sortOptionsBy)) : opts;
    if (inputCreatable && (createLabel || inputValue?.length)) {
      opts = [
        {
          value: CREATE_VALUE,
          closeOnSelect: true,
          label: () => (
            <CreateMessage
              inputValue={inputValue}
              createLabel={createLabel}
              formatCreateLabel={formatCreateLabel}
            />
          )
        },
        ...opts
      ];
    }
    if (opts.length === 0 && !freeSolo && emptyMessage) {
      opts = [
        {
          value: EMPTY_VALUE,
          closeOnSelect: true,
          label: () => (
            <NoOptionsMessage
              inputValue={inputValue}
              formatEmptyLabel={formatEmptyLabel}
            />
          )
        }
      ];
    }
    return opts.slice(0, 500); // on va éviter d'en render trop
  }, [
    value,
    options,
    freeSolo,
    emptyMessage,
    asyncOptions,
    inputValue,
    filterOptions,
    filter,
    sortOptions,
    sortOptionsBy,
    inputCreatable,
    createLabel,
    formatCreateLabel,
    formatEmptyLabel,
    optionsCheckable
  ]);

  const isInputVisible = !multi && (isSearchable || !hasValue);

  return (
    <Dropdown
      value={value}
      maxWidth="400px"
      options={memoOptions}
      isOpen={isMenuOpen}
      onChange={handleSelectOption}
      closeOnSelect={!multi && closeOnSelect}
      style={style}
      showIndicator={showIndicator && !hasValue && !props.error}
      indicator={
        (enabled && hasValue && !multi) || props.error ? null : undefined
      }
      renderOption={optionsCheckable ? renderCheckableOption : undefined}
      onRequestClose={(e, index) => {
        if (
          index === undefined ||
          index === -1 ||
          index >= memoOptions.length ||
          !multi
        ) {
          setMenuOpen(false);
        } else {
          setMenuOpen(options[index].closeOnSelect);
        }
      }}
      disabled={disabled}
      readOnly={readOnly}
      {...props}
      onDoubleClick={() => setMenuOpen(true)}
      onClick={e => {
        if (enabled && (!isInputVisible || !freeSolo)) {
          e.stopPropagation();
          e.preventDefault();
          setMenuOpen(open => !open);
        }
      }}
    >
      <Wrapper
        isSearchable={isSearchable}
        disabled={disabled}
        readOnly={readOnly}
        // onFocus={e => {
        //   setMenuOpen(true);
        // }}
        // onDoubleClick={() => setMenuOpen(true)}
      >
        {!multi && !freeSolo && hasValue && (
          <CurrentOptionWrapper
            tabIndex={(enabled || isMenuOpen) && !isSearchable ? '0' : '-1'}
            isSearchable={isSearchable}
            fitCurrentOptionWidth={fitCurrentOptionWidth}
            inputValue={inputValue}
            isMenuOpen={isMenuOpen}
            hasIndicator={showIndicator}
          >
            {onEditOptionClick && optionEditable && !readOnly && (
              <EditOptionButton
                size="small"
                onMouseDown={e => {
                  e.preventDefault();
                }}
                onClick={e => {
                  e.stopPropagation();
                  e.preventDefault();
                  onEditOptionClick(value?.original || value);
                }}
              >
                <FaEdit />
              </EditOptionButton>
            )}
            {currentOptionRenderer ? (
              currentOptionRenderer({ option: value })
            ) : (
              <CurrentOption ellipsis option={value} currentOption />
            )}
          </CurrentOptionWrapper>
        )}
        {multi && (
          <ChipLayout
            values={value}
            onChange={handleChange}
            enabled={enabled}
            inline={inline}
            style={currentValueStyle}
            limitChip={limitValues}
            verticalScrollLeft
          >
            <MultiInput
              textArea={textArea}
              ref={inputRef}
              disabled={disabled}
              readOnly={readOnly}
              hasValue={hasValue}
              position={
                !isMenuOpen && value && value.length > 0
                  ? 'absolute'
                  : 'relative'
              }
              style={{
                width:
                  measureInputWidth(inputRef.current && inputRef.current.view) +
                  14
              }}
              type="text"
              tabIndex={enabled || isMenuOpen ? '0' : '-1'}
              value={inputValue}
              onChange={setInputValue}
              onChangeDebounce={handleDebouncedInputChange}
              debounced={debounceInput || Boolean(loadAsyncOptions)}
              debounceDelay={debounceDelay}
              autoComplete="roadmapper"
              onMouseDown={e => e.stopPropagation() || e.preventDefault()}
              onFocus={e => {
                if (enabled) {
                  e.preventDefault();
                  e.stopPropagation();
                  setMenuOpen(true);
                }
              }}
              onBlur={e => setMenuOpen(false)}
            />
          </ChipLayout>
        )}
        {isInputVisible && (
          <Input
            ref={inputRef}
            textArea={textArea}
            autoComplete="roadmapper"
            disabled={disabled}
            readOnly={!isSearchable || readOnly}
            fill-option={fitCurrentOptionWidth && hasValue}
            type="text"
            tabIndex={enabled || isMenuOpen ? '0' : '-1'}
            value={!freeSolo && !isMenuOpen ? '' : inputValue}
            onChange={setInputValue}
            onChangeDebounce={handleDebouncedInputChange}
            debounced={debounceInput || Boolean(loadAsyncOptions)}
            debounceDelay={debounceDelay}
            onClick={e => {
              e.stopPropagation();
              if (enabled) {
                setMenuOpen(open => (isSearchable ? true : !open));
              }
            }}
            onMouseDown={e => {
              if (!freeSolo) {
                e.stopPropagation();
                if (!isSearchable) {
                  e.preventDefault();
                }
              }
            }}
            onFocus={e => {
              if (enabled) {
                setMenuOpen(true);
              }
            }}
            onBlur={e => setMenuOpen(false)}
          />
        )}
        {loading && isMenuOpen && (
          <LoadingIndicator
            accent
            className="rm-progress"
            noBackgroundLine
            height="2px"
          />
        )}
      </Wrapper>
    </Dropdown>
  );
};

export default React.memo(Select);

import React from 'react';

type ItemMeasurerProps = {
  measure: (el: HTMLElement | null) => void;
  as: React.ElementType | keyof JSX.IntrinsicElements;
  style: React.CSSProperties;
};

const ItemMeasurer: React.FC<ItemMeasurerProps> = ({
  children,
  measure,
  as: EL,
  ...restProps
}) => {
  const roRef = React.useRef<ResizeObserver | null>(null);
  const elRef = React.useRef<HTMLElement | null>(null);

  const measureRef = React.useRef(measure);
  measureRef.current = measure;

  const refSetter = React.useCallback(el => {
    const ro = roRef.current;

    if (ro != null && elRef.current != null) {
      ro.unobserve(elRef.current);
    }

    elRef.current = el;

    if (ro != null && elRef.current != null) {
      ro.observe(elRef.current);
    }
  }, []);

  React.useLayoutEffect(() => {
    const update = () => {
      measureRef.current(elRef.current);
    };

    // sync measure for initial render ?
    update();

    const ro = roRef.current ? roRef.current : new ResizeObserver(update);

    const el = elRef.current;
    if (el !== null) {
      ro.observe(el);
    }
    roRef.current = ro;

    return () => {
      ro.disconnect();
    };
  });

  return (
    <EL ref={refSetter} {...restProps}>
      {children}
    </EL>
  );
};

// function RowVirtualizerDynamic({ rows }) {
//   const parentRef = React.useRef();

//   const rowVirtualizer = useVirtual({
//     size: rows.length,
//     parentRef
//   });

//   return (
//     <>
//       <div
//         ref={parentRef}
//         className="List"
//         style={{
//           height: `200px`,
//           width: `400px`,
//           overflow: "auto"
//         }}
//       >
//         <div
//           style={{
//             height: `${rowVirtualizer.totalSize}px`,
//             width: "100%",
//             position: "relative"
//           }}
//         >
//           {rowVirtualizer.virtualItems.map((virtualRow) => (
//             <ItemMeasurer
//               tagName="div"
//               key={virtualRow.index}
//               measure={virtualRow.measureRef}
//               className={virtualRow.index % 2 ? "ListItemOdd" : "ListItemEven"}
//               style={{
//                 position: "absolute",
//                 top: 0,
//                 left: 0,
//                 width: "100%",
//                 transform: `translateY(${virtualRow.start}px)`,
//                 minHeight: `${rows[virtualRow.index]}px`
//               }}
//             >
//               <div>
//                 <div contentEditable="true">kdza,od ,aodiak,z</div>
//                 Row {virtualRow.index}
//               </div>
//             </ItemMeasurer>
//           ))}
//         </div>
//       </div>
//     </>
//   );
// }

// function ColumnVirt
export default ItemMeasurer;

import React, { useCallback, useMemo, useRef, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import { DatePickerField, InputLayoutVariant, TextInputField } from 'components/v3/Form';
import { Grid, GridItem, Text, DashContainer, Dashed } from 'components/v3/ui';
import { EntityModal } from 'components/v3/Modal';
import { AccountingSelector, AccountingAction, invoiceAsValues, getInvoiceKind } from 'features/Accounting';
import { useRedux, useQuery } from 'components/v3/utils';
import Lines from './InvoiceLines';
import Amounts from './InvoiceModalAmounts';
import Toolbar from './InvoiceModalToolbar';
import { AccountSelector } from 'features/Accounts';
import { ProjectSelector, ProjectAction } from 'features/Projects';
import { useAccountConfig } from 'config';
import LogoInfo from 'features/Accounts/components/LogoInfo';
import useNamingRules from 'components/v3/Form/useNamingRules';
import { AddNewLine, AddNewSeparator } from './InvoiceLines/AddLineButton';
import { createLine, createSeparator } from './InvoiceLines/utils';

export const InformationHeader = styled(GridItem)`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const Sender = styled.div`
  max-width: 400px;
  width: 400px;
  * > * {
    font-size: 0.875rem;
  }
`;

export const Receiver = styled.div`
  margin-top: 85px;
  width: 400px;
  max-width: 400px;
  * > * {
    font-size: 0.875rem;
  }
`;

const DescriptionWrapper = styled(GridItem)`
  margin-top: -8px;
`;

const InvoiceNameField = styled.div`
  color: ${({ theme }) => theme.primary};
  font-weight: 600;
  font-size: 1.25em;
`.withComponent(TextInputField);

const InvoiceDescriptionField = styled.div`
  color: ${({ theme }) => theme.textDarkSecondary};
  font-weight: 600;
`.withComponent(TextInputField);

const AttributeWrapper = styled(GridItem)`
  display: flex;
  align-items: center;
  margin-left: 16px;
  margin-top: ${({ date }) => (date ? '-8px' : '8px')};
  padding-bottom: 2px !important;
  > :first-child {
    margin-right: 0.5em;
  }
`;

const AmountsContainer = styled.div`
  margin-top: 24px;
  display: flex;
  width: 100%;
  opacity: 1;
  z-index: 1;
  ${({ floating }) =>
    floating &&
    css`
      opacity: 0;
    `}
`;

const FloatingContainer = styled.div`
  margin-top: 24px;
  display: inline-flex;
  position: fixed;
  bottom: ${({ readOnly }) => (readOnly ? 8 : 63)}px;
  padding: 8px;
  background: ${({ theme }) => theme.backgroundLight};
  box-shadow: ${({ theme }) => theme.boxShadow};
  right: 8px;
  z-index: 0;
  display: none;
  ${({ floating }) =>
    floating &&
    css`
      display: inline-flex;
      z-index: 4;
    `}
`;

// const isAmountsFloating = (amountsRef, floatingAmountsRef) => {
//   if (!amountsRef.current || !floatingAmountsRef.current) {
//     return false;
//   }
//   return (
//     Math.ceil(amountsRef.current.getBoundingClientRect().top) - 8 >
//     Math.ceil(floatingAmountsRef.current.getBoundingClientRect().top)
//   );
// };

const canTriggerNamingRules = (values) => false;

const InvoiceRulesObserver = ({ isNew, values, setFieldValue, duplicate }) => {
  const handleNamingRuleChange = useCallback((name, value) => setFieldValue(name, value), [setFieldValue]);

  const handleNamingRuleLoading = useCallback((loading) => {}, []);

  const { computeRules } = useNamingRules({
    modified: isNew,
    model: 'invoice',
    values,
    canTriggerNamingRules: true,
    onValueChanged: handleNamingRuleChange,
    onFetchingValue: handleNamingRuleLoading
  });

  const isValuesEmpty = isEmpty(values);
  useEffect(() => {
    if (isNew && !isValuesEmpty && !duplicate) {
      computeRules(values);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [computeRules, isNew, isValuesEmpty, duplicate]);
  return null;
};

const InvoiceModal = ({ onRequestClose, onSubmitSuccess, project: propsProject, invoice: propsInvoice, ...props }) => {
  const formikBagRef = useRef();
  const dispatch = useDispatch();
  const { translate } = useAccountConfig();
  // const [amountsFloating, setAmountsFloating] = useState(false);
  const floatingAmountsRef = useRef();
  const amountsRef = useRef();
  const { params } = useRouteMatch();
  let { invoiceId, projectId: paramsProjectId } = params;
  if (propsInvoice) {
    invoiceId = propsInvoice.id;
  }
  const { kind } = useQuery();
  const [duplicate, setDuplicate] = useState();
  const id = duplicate ? null : invoiceId;
  const isNew = !Boolean(id);

  const account = useSelector(AccountSelector.selectCurrentAccount, isEqual);
  const selectInvoice = useMemo(() => (state) => AccountingSelector.selectInvoice(state, { invoiceId: id }), [id]);

  let invoice = useSelector(selectInvoice, isEqual);
  if (!invoice) {
    invoice = propsInvoice;
  }

  const projectId = duplicate ? duplicate.projectId : paramsProjectId || invoice?.projectId;

  const shouldFetchProject = !Boolean(propsProject);
  const selectProject = useMemo(
    () => (state) => {
      const project = ProjectSelector.selectProjectV3(state, { projectId });
      return project
        ? pick(project, [
            'id',
            'projectKindId',
            'projectKind',
            'group',
            'groupId',
            'user',
            'organization',
            'contact',
            'displayName',
            'address'
          ])
        : null;
    },
    [projectId]
  );

  const [project, fetchProject] = useRedux(selectProject, ProjectAction.getProject, {
    fetchOnMount: false,
    useLocalState: false
  });

  useEffect(() => {
    if (shouldFetchProject && projectId) {
      fetchProject({ id: projectId });
    }
  }, [shouldFetchProject, fetchProject, projectId]);

  const createRequest = useCallback((payload) => dispatch(AccountingAction.createInvoice({ payload })), [dispatch]);

  const fetchRequest = useCallback(() => dispatch(AccountingAction.getInvoice({ id })), [id, dispatch]);

  const updateRequest = useCallback(
    (id, payload) => dispatch(AccountingAction.updateInvoice({ id, payload })),
    [dispatch]
  );

  const deleteRequest = useCallback((id) => dispatch(AccountingAction.deleteInvoice({ id })), [dispatch]);

  const readOnly = (!isNew && !invoice?.state?.editable) || invoice?.signed;

  const entityAsValues = useCallback(
    (invoice) => invoiceAsValues({ invoice, project, isNew, readOnly, kind, account }) || {},
    [account, project, isNew, kind, readOnly]
  );

  const closeOnSubmit = useCallback((form) => {
    return !form.status?.includes('printing') && !form.status?.includes('signing');
  }, []);

  const handleVerticalScroll = useCallback(() => {
    // const floating = isAmountsFloating(amountsRef, floatingAmountsRef);
    // setAmountsFloating(floating);
  }, []);

  // const top = amountsRef.current?.getBoundingClientRect().top;

  // useEffect(() => {
  //   const floating = isAmountsFloating(amountsRef, floatingAmountsRef);
  //   if (floating !== amountsFloating) {
  //     setAmountsFloating(floating);
  //   }
  // }, [amountsFloating, top]);

  const entityId = id;
  const entity = duplicate ? duplicate : invoice;

  const renderToolbar = useCallback(
    (form) => {
      return (
        <Toolbar
          {...form}
          readOnly={readOnly}
          projectId={paramsProjectId ? null : projectId}
          onDuplicate={(duplicate) => {
            if (formikBagRef.current) {
              formikBagRef.current.resetForm(duplicate);
            }
            setDuplicate(duplicate);
          }}
        />
      );
    },
    [readOnly, paramsProjectId, projectId]
  );

  return (
    <EntityModal
      isOpen
      formikBagRef={formikBagRef}
      size="large"
      onRequestClose={onRequestClose}
      id={entityId}
      model="invoice"
      entity={entity}
      readOnly={readOnly}
      closeOnSubmit={closeOnSubmit}
      entityAsValue={entityAsValues}
      fetchRequest={fetchRequest}
      createRequest={createRequest}
      updateRequest={updateRequest}
      deleteRequest={entity?.state === 'draft' ? deleteRequest : null}
      onSubmitSuccess={onSubmitSuccess}
      autoSubmit={false}
      onVerticalScroll={handleVerticalScroll}
      customHeader={renderToolbar}
      canTriggerNamingRules={canTriggerNamingRules}
      {...props}
    >
      {({ values, setFieldValue }) => {
        return (
          <DashContainer readOnly={readOnly}>
            <InvoiceRulesObserver
              isNew={isNew}
              values={values}
              setFieldValue={setFieldValue}
              duplicate={entity?.duplicate}
            />
            <Grid spacing={16} padded={false}>
              <InformationHeader width="100%">
                <Sender>
                  <LogoInfo {...(values.sender || {})} logoUrl={account?.logoUrl || ''} />
                </Sender>
                <Receiver>
                  <Dashed fullWidth>
                    <TextInputField
                      name="receiver.displayName"
                      fillWidth
                      variant={InputLayoutVariant.flat.medium}
                      label="Nom destinataire"
                      onChange={(value) => setFieldValue('receiver.displayNameEdited', Boolean(value))}
                      readOnly={readOnly}
                    />
                  </Dashed>
                  <Dashed fullWidth>
                    <TextInputField
                      name="receiver.address"
                      textArea
                      fillWidth
                      minRows={3}
                      variant={InputLayoutVariant.flat.medium}
                      onChange={(value) => setFieldValue('receiver.addressEdited', Boolean(value))}
                      label="Adresse destinataire"
                      readOnly={readOnly}
                    />
                  </Dashed>
                  <LogoInfo
                    disableLogo
                    displayName={values.receiver?.organization?.displayName}
                    address={values.receiver?.organization?.address}
                    editable
                  />
                </Receiver>
              </InformationHeader>
              <GridItem width="100%">
                <Dashed fillWidth>
                  <InvoiceNameField
                    name="displayName"
                    textArea
                    fillWidth
                    variant={InputLayoutVariant.flat.medium}
                    label="Intitulé"
                    readOnly={readOnly}
                  />
                </Dashed>
              </GridItem>
              <DescriptionWrapper width="100%">
                <Dashed fillWidth>
                  <InvoiceDescriptionField
                    name="description"
                    textArea
                    fillWidth
                    variant={InputLayoutVariant.flat.medium}
                    label="Description"
                    readOnly={readOnly}
                  />
                </Dashed>
              </DescriptionWrapper>
              <AttributeWrapper width="100%" number>
                <Text variant="small" color="secondary">
                  {translate({
                    value: `invoice.kind.${values.kind}`,
                    fallback: getInvoiceKind(values.kind).name
                  })}
                </Text>
                <Text variant="small" color="theme" weight="semiBold">{`${
                  values.number ? `${values.number}` : ''
                }`}</Text>
              </AttributeWrapper>
              <AttributeWrapper width="100%" date>
                <Text variant="small" color="secondary">
                  Date d'émission:
                </Text>
                <Dashed>
                  <DatePickerField
                    name="valueDate"
                    variant={InputLayoutVariant.flat.medium}
                    dateFormat="dd MMMM yyyy"
                    fitContent
                    readOnly={readOnly}
                  />
                </Dashed>
              </AttributeWrapper>
              <GridItem width="100%" style={{ marginLeft: -24, paddingLeft: 0 }}>
                <Lines
                  group={project?.group}
                  invoiceLines={values.invoiceLines || []}
                  setFieldValue={setFieldValue}
                  readOnly={readOnly}
                  groupId={project?.group?.id || project?.groupId}
                  projectKindId={project?.projectKind?.id || project?.projectKindId}
                />
              </GridItem>
              <GridItem width="100%">
                <AddNewLine
                  readOnly={readOnly}
                  onClick={() => {
                    const invoiceLines = values.invoiceLines || [];
                    setFieldValue('invoiceLines', [
                      ...invoiceLines,
                      createLine(invoiceLines.length + 1, project?.group)
                    ]);
                  }}
                ></AddNewLine>
                <AddNewSeparator
                  readOnly={readOnly}
                  onClick={() => {
                    const invoiceLines = values.invoiceLines || [];
                    setFieldValue('invoiceLines', [...invoiceLines, createSeparator(invoiceLines.length + 1)]);
                  }}
                ></AddNewSeparator>
              </GridItem>

              <FloatingContainer ref={floatingAmountsRef} floating={false} readOnly={readOnly}>
                <Amounts values={values} setFieldValue={setFieldValue} readOnly={readOnly} />
              </FloatingContainer>
              <AmountsContainer ref={amountsRef} floating={false}>
                <Amounts values={values} setFieldValue={setFieldValue} readOnly={readOnly} />
              </AmountsContainer>
            </Grid>
          </DashContainer>
        );
      }}
    </EntityModal>
  );
};

export default InvoiceModal;

import React, { useState, useCallback } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { MdAdd } from 'react-icons/md';
import SubHeader from 'components/v3/SubHeader';
import { FilterBar } from 'components/v3/Filters';
import { Container, Title, Button } from 'components/v3/ui';
import { MachineSelector, MachineAction } from 'features/Machines';
import MachineList from 'features/Machines/components/v3/List';

const defaultParams = { sort: 'position,displayName' };

const Machines = () => {
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  const [count, setCount] = useState();
  const [filters, setFilters] = useState();

  const handleDelete = useCallback(
    item => dispatch(MachineAction.deleteMachine({ id: item.id })),
    [dispatch]
  );

  const handleSort = useCallback(
    (items, item) =>
      dispatch(
        MachineAction.updateMachinesOrder({
          payload: {
            id: item.id,
            position: item.position
          },
          payloads: items
        })
      ),
    [dispatch]
  );

  const handleArchive = useCallback(
    item =>
      dispatch(
        MachineAction.updateMachine({
          id: item.id,
          payload: {
            archived: item.archived
          }
        })
      ),
    [dispatch]
  );

  return (
    <Container vertical>
      <SubHeader>
        <Title>{`Machines${count != null ? ` (${count})` : ''}`}</Title>
        <Button small to={`${path}/nouvelle-machine`} icon={MdAdd}>
          Nouvelle machine
        </Button>
      </SubHeader>
      <Container vertical huge padded>
        <FilterBar
          big
          searchQuery="displayNameContAny"
          onChanges={setFilters}
        />
        <MachineList
          spacing={8}
          headerVariant="transparent"
          selector={MachineSelector.selectMachines}
          action={MachineAction.getMachines}
          onCountChanged={setCount}
          params={defaultParams}
          filters={filters}
          perPage={50}
          detailUrlPrefix=""
          isDeletable
          deleteRequest={handleDelete}
          isSortable
          onSortChanged={handleSort}
          isArchivable
          onToggleArchive={handleArchive}
        />
      </Container>
    </Container>
  );
};

export default Machines;

import React from 'react';
import styled, { css } from 'styled-components';
import { getInitials } from 'utils';
import { Text } from '../ui';
import { fontSize } from 'ui';
import Tooltip from '../Tooltip';

export type AvatarSize =
  | 'giant'
  | 'big'
  | 'large'
  | 'normal'
  | 'medium'
  | 'small'
  | 'tiny';

const SizeCss = {
  giant: css`
    width: 92px;
    min-width: 92px;
    height: 92px;
    span {
      font-size: ${fontSize(20)};
    }
  `,
  big: css`
    width: 72px;
    min-width: 72px;
    height: 72px;
    span {
      font-size: ${fontSize(18)};
    }
  `,
  large: css`
    width: 56px;
    min-width: 56px;
    height: 56px;
    span {
      font-size: ${fontSize(16)};
    }
  `,
  normal: css`
    width: 48px;
    min-width: 48px;
    height: 48px;
    box-shadow: 0px 4px 6px 4px rgba(0, 0, 0, 0.08);
  `,
  medium: css`
    width: 40px;
    min-width: 40px;
    height: 40px;
    box-shadow: 0px 3px 5px 3px rgba(0, 0, 0, 0.08);
  `,
  small: css`
    width: 32px;
    min-width: 32px;
    height: 32px;
    span {
      font-size: ${fontSize(12)};
    }
    border-width: 1px;
    box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.08);
  `,
  icon: css`
    width: 24px;
    min-width: 24px;
    height: 24px;
    span {
      font-size: ${fontSize(10)};
    }
    border-width: 1px;
    box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.08);
  `,
  tiny: css`
    width: 20px;
    min-width: 20px;
    height: 20px;
    span {
      font-size: ${fontSize(10)};
    }
    border-width: 1px;
    box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.08);
  `
};

export const AvatarWrapper = styled('span')<{
  size?: AvatarSize;
  hasAvatar?: boolean;
  borderHidden?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid
    ${({ borderHidden }) => (borderHidden ? 'transparent' : 'white')};
  box-shadow: ${({ theme }) => theme.boxShadowSmall};
  background-color: ${({ theme, hasAvatar }) =>
    hasAvatar ? 'white' : theme.primaryLight};
  border-radius: 50%;
  ${({ size }) => (size && SizeCss[size]) || SizeCss.medium};

  overflow: hidden;
`;

const Image = styled('img')<{ size?: AvatarSize }>`
  object-fit: cover;
  border-radius: 50%;
  height: 100%;
  width: 100%;
`;

interface Props {
  initials?: string;
  user?: any;
  url?: string;
  size?: AvatarSize;
  borderHidden?: boolean;
  tooltip?: boolean;
}

const makeUrl = (url?: string) => {
  if (url?.startsWith('http')) {
    return url;
  } else if (url) {
    return URL.createObjectURL(url);
  } else {
    return null;
  }
};

const Avatar: React.FunctionComponent<Props> = ({
  user,
  initials,
  url,
  size,
  tooltip = true,
  ...props
}) => {
  let safeInitials;
  let safeUrl = null;
  if (user) {
    safeInitials =
      user.settings?.abbr ||
      getInitials(user.displayName || `${user.firstName} ${user.lastName}`);
    safeUrl = makeUrl(user.avatarUrl);
  } else {
    safeInitials = initials;
    safeUrl = makeUrl(url);
  }
  if (safeUrl) {
    return (
      <Tooltip content={tooltip ? user?.displayName : undefined}>
        <AvatarWrapper {...props} size={size} hasAvatar>
          <Image src={safeUrl} alt="" />
        </AvatarWrapper>
      </Tooltip>
    );
  } else {
    return (
      <Tooltip content={tooltip ? user?.displayName : undefined}>
        <AvatarWrapper {...props} size={size}>
          <Text color="light" variant="small">
            {safeInitials}
          </Text>
        </AvatarWrapper>
      </Tooltip>
    );
  }
};

export default Avatar;

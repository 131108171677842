import React, { Fragment, useEffect, useCallback } from 'react';
import { useRouteMatch, Route, useHistory, useLocation } from 'react-router-dom';
import Table from 'components/v3/List/Table';
import { useListFetcher } from 'components/v3/utils';
import ProductModal from './ProductModal';

const columns = [
  {
    Header: 'Formule de marquage CE',
    accessor: 'markingFormulaCe',
    width: '40%'
  },
  {
    Header: 'Numéro AQP',
    accessor: 'aqpNumber',
    width: '15%'
  },
  {
    Header: 'Sous-formule',
    accessor: 'subformula',
    width: '35%'
  },
  {
    Header: 'Densité',
    accessor: 'density',
    width: '10%'
  }
];

const ProductList = ({
  selector,
  action,
  options = {
    fetchOnMount: false,
    useLocalState: true
  },
  params = {
    sort: 'display_name'
  },
  perPage = 25,
  filters,
  onCountChanged,
  detailUrlPrefix = '/produits',
  ...props
}) => {
  const { path, url } = useRouteMatch();
  const history = useHistory();
  const location = useLocation();

  const [products, paginationHelper, state] = useListFetcher({
    selector,
    action,
    options,
    params,
    perPage,
    filters
  });

  const detailLinkFormatter = useCallback((item) => `${path}${detailUrlPrefix}/${item.id}`, [path, detailUrlPrefix]);

  useEffect(() => {
    if (paginationHelper.total != null && onCountChanged) {
      onCountChanged(paginationHelper.total);
    }
  }, [paginationHelper.total, onCountChanged]);

  return (
    <Fragment>
      <Table
        columns={columns}
        items={products}
        isLoading={state.isLoading}
        linkFormatter={detailLinkFormatter}
        centerMessage={state.errorMessage}
        {...paginationHelper}
        {...props}
      />
      <Route path={[`${path}${detailUrlPrefix}/nouveau-produit`, `${path}${detailUrlPrefix}/:productId`]}>
        <ProductModal
          onRequestClose={() => {
            if (location.key) {
              history.goBack();
            } else {
              history.replace(url);
            }
          }}
        />
      </Route>
    </Fragment>
  );
};

export default ProductList;

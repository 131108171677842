import React, { Fragment } from 'react';
import { GridItem, SectionTitle, Subtitle, Button } from 'components/v3/ui';

import { LoginForm } from './LoginForm';
import { LostPasswordForm } from './LostPasswordForm';
import { ResetPasswordForm } from './ResetPasswordForm';
import { AcceptInvitationForm } from './AcceptInvitationForm';

export const FormHeader = ({ title, subtitle }) => {
  return (
    <Fragment>
      <GridItem>
        <SectionTitle>{title}</SectionTitle>
      </GridItem>
      <GridItem>
        <Subtitle>{subtitle}</Subtitle>
      </GridItem>
    </Fragment>
  );
};

export const FormFooter = ({
  isSubmitting,
  isValid,
  secondaryAction,
  primaryAction
}) => {
  const primaryActionText = primaryAction?.text;
  const secondaryActionText = secondaryAction?.text;
  return (
    <Fragment>
      <GridItem width="100%">
        <Button
          disabled={!secondaryActionText}
          isLoading={
            secondaryAction && secondaryAction.type === 'submit' && isSubmitting
          }
          style={{
            marginLeft: -16,
            visibility: secondaryActionText ? undefined : 'hidden'
          }}
          variant="textCaption"
          underline
          type={(secondaryAction && secondaryAction.type) || undefined}
          to={(secondaryAction && secondaryAction.to) || undefined}
        >
          {secondaryActionText}
        </Button>
      </GridItem>
      <GridItem width="100%" alignEnd>
        <Button
          type="submit"
          isLoading={isSubmitting}
          disabled={!isValid || !primaryActionText}
          style={{
            visibility: primaryActionText ? undefined : 'hidden'
          }}
        >
          {primaryActionText}
        </Button>
      </GridItem>
    </Fragment>
  );
};

export { LoginForm, LostPasswordForm, ResetPasswordForm, AcceptInvitationForm };

import { get, put, post, destroy } from 'common/api';

export const getContacts = params => get('/v5/contacts', params);

export const createContact = (contact, config) =>
  post('/v5/contacts', { contact }, config);

export const getContact = (id, config) => get(`/v5/contacts/${id}`, config);

export const putContact = (id, contact, config) =>
  put(`/v5/contacts/${id}`, { contact }, config);

export const deleteContact = (id, config) =>
  destroy(`/v5/contacts/${id}`, config);

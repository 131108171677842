import React, { useCallback, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { MdEmail } from 'react-icons/md';
import isEqual from 'lodash/isEqual';
import * as Yup from 'yup';
import { EntityModal } from 'components/v3/Modal';
import { TextInputField, ERRORS, AddressField } from 'components/v3/Form';
import { Grid, GridItem, ExternalLink } from 'components/v3/ui';
import { OrganizationField } from 'features/Organizations/components/v3/Select';
import {
  ContactSelector,
  ContactAction,
  contactAsValues
} from 'features/Contacts';
import { OrganizationSelector } from 'features/Organizations';
import { features, Permission, permissions, useAccountConfig } from 'config';

const HidableItem = styled(GridItem)`
  transition: ${({ animated }) => `${animated ? 0.5 : 0}s`} ease max-height,
    padding;
  max-height: 1000px;
  overflow: hidden;
  &[disabled] {
    max-height: 0px;
    padding: 0px;
  }
`;

const validationSchema = Yup.object().shape({
  lastName: Yup.string().required(ERRORS.REQUIRED)
});

const ContactModal = ({
  onRequestClose,
  organizationId,
  organization: propsOrganization,
  contact: propsContact,
  ...props
}) => {
  const { hasPermission } = useAccountConfig();
  const dispatch = useDispatch();
  const { params } = useRouteMatch();
  let { contactId } = params;
  if (propsContact) {
    contactId = propsContact.id;
  }
  const isNew = !Boolean(contactId);

  const selectOrganization = useMemo(
    () => state =>
      organizationId
        ? OrganizationSelector.selectOrganization(state, {
            organizationId
          })
        : null,
    [organizationId]
  );

  let organization = useSelector(selectOrganization, isEqual);
  if (!organization) {
    organization = propsOrganization;
  }

  const selectContact = useMemo(
    () => state => ContactSelector.selectContact(state, { contactId }),
    [contactId]
  );

  let contact = useSelector(selectContact, isEqual);
  if (!contact) {
    contact = propsContact;
  }

  const createRequest = useCallback(
    values => dispatch(ContactAction.createContact({ payload: values })),
    [dispatch]
  );

  const fetchRequest = useCallback(
    () => dispatch(ContactAction.getContact({ id: contactId })),
    [contactId, dispatch]
  );

  const updateRequest = useCallback(
    (id, values) =>
      dispatch(ContactAction.updateContact({ id, payload: values })),
    [dispatch]
  );

  const deleteRequest = useCallback(
    id => dispatch(ContactAction.deleteContact({ id })),
    [dispatch]
  );

  const entityAsValue = useCallback(
    entity => {
      const values = contactAsValues(entity);
      return {
        ...values,
        organization: organizationId ? organization : values.organization
      };
    },
    [organizationId, organization]
  );

  const canEdit = hasPermission([
    permissions.CRM,
    isNew ? 'contact.create' : 'contact.update'
  ]);

  const canDelete = hasPermission([permissions.CRM, 'contact.delete']);

  return (
    <EntityModal
      isOpen
      onRequestClose={onRequestClose}
      title={isNew ? 'Nouveau contact' : 'Édition du contact'}
      id={contactId}
      model="contact"
      entity={contact}
      validationSchema={validationSchema}
      entityAsValue={entityAsValue}
      fetchRequest={fetchRequest}
      createRequest={createRequest}
      updateRequest={updateRequest}
      deleteRequest={canDelete ? deleteRequest : undefined}
      autoSubmit={false}
      readOnly={!canEdit}
      {...props}
    >
      {({ initialValues, values }) => (
        <Grid spacing={16} padded={false}>
          <GridItem width="100%">
            <TextInputField
              name="firstName"
              readOnly={!canEdit}
              label="Prénom"
            />
          </GridItem>
          <GridItem width="100%">
            <TextInputField
              name="lastName"
              readOnly={!canEdit}
              label="Nom"
              required
            />
          </GridItem>
          <GridItem width="100%">
            <TextInputField
              name="email"
              readOnly={!canEdit}
              label="Email"
              trailingIconClickable={
                canEdit &&
                initialValues.id &&
                initialValues.email === values.email
              }
              iconTrailing={
                canEdit &&
                Yup.string().email().required().isValidSync(values.email) && (
                  <ExternalLink to={`mailto:${values.email}`}>
                    <MdEmail />
                  </ExternalLink>
                )
              }
            />
          </GridItem>
          <GridItem>
            <OrganizationField
              name="organization"
              label="Organisation"
              readOnly={!canEdit}
              optionEditable={
                !organization || organization.id !== values.organization?.id
              }
            />
          </GridItem>
          <HidableItem
            width="100%"
            disabled={Boolean(
              !values.organization || values.organization?.contactAddressable
            )}
          >
            <TextInputField
              name="position"
              readOnly={!canEdit}
              label="Poste dans l'organisation"
            />
          </HidableItem>
          <GridItem width="100%">
            <TextInputField
              name="mobile"
              label="Tel. portable"
              readOnly={!canEdit}
            />
          </GridItem>
          <GridItem width="100%">
            <TextInputField
              name="phone"
              label="Tel. fixe"
              readOnly={!canEdit}
            />
          </GridItem>
          <Permission feature={features.CONTACT_ADDRESSABLE}>
            <GridItem width="100%">
              <AddressField
                name="address"
                label="Adresse"
                readOnly={!canEdit}
              />
            </GridItem>
          </Permission>
        </Grid>
      )}
    </EntityModal>
  );
};

export default ContactModal;

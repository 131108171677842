import { createAction } from 'redux-actions';
import omit from 'lodash/omit';
import * as Api from './api';
import { shouldFetch } from 'common/actions';

const activitiesLoading = createAction('ACTIVITIES_LOADING');
const activitiesSuccess = createAction('ACTIVITIES_SUCCESS');
const activitiesError = createAction('ACTIVITIES_ERROR');
export const getActivities = payload => async (dispatch, getState) => {
  const { useCache, ...params } = payload || {};
  const state = getState();
  if (!useCache || shouldFetch('activity', params, state)) {
    return fetchActivities(params, dispatch);
  } else {
    return {
      fromCache: true,
      isLoading: getState().activities.isLoading
    };
  }
};
const fetchActivities = async (payload, dispatch) => {
  dispatch(activitiesLoading());
  try {
    const result = await Api.getActivities(payload);
    dispatch(
      activitiesSuccess({
        ...result,
        requestParams: omit(payload, 'cancelToken')
      })
    );
    return result;
  } catch (error) {
    dispatch(activitiesError(error));
    throw error;
  }
};

const receiveCreateActivity = createAction('NEW_ACTIVITY_SUCCESS');
export const createActivity = ({ payload, ...config }) => async dispatch => {
  const result = await Api.createActivity(payload, config);
  dispatch(receiveCreateActivity(result));
  return result;
};

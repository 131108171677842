import { createAction } from 'redux-actions';
import * as Api from './api';
import { UserApi } from 'features/Users';
import { omit, keys } from 'lodash';
import { decamelize } from 'humps';
import { deepMerge } from 'utils';

const receiveMe = createAction('ME_SUCCESS');
export const getMe = args => async dispatch => {
  const me = await Api.getMe();
  const user = await UserApi.getUser(me.data.id);
  dispatch(receiveMe(deepMerge(me, user)));
  return me;
};

const receiveUpdateMe = createAction('UPDATE_ME_SUCCESS');
export const updateMe = payload => async dispatch => {
  const { avatar } = payload;

  const formData = new FormData();
  keys(omit(payload, 'avatar')).forEach(key => {
    return formData.append(`user[${decamelize(key)}]`, payload[key]);
  });

  if (avatar) {
    formData.append('user[avatar]', avatar);
  }

  const response = await Api.updateMe(formData);
  dispatch(receiveUpdateMe(response));
  return response;
};

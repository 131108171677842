import React, { useState, useCallback } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { MdAdd } from 'react-icons/md';
import SubHeader from 'components/v3/SubHeader';
import { FilterBar } from 'components/v3/Filters';
import { Container, Title, Button } from 'components/v3/ui';
import { GroupSelector, GroupAction } from 'features/Groups';
import GroupList from 'features/Groups/components/v3/List';

const Groups = () => {
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  const [count, setCount] = useState();
  const [filters, setFilters] = useState();

  const handleDelete = useCallback(
    item => dispatch(GroupAction.deleteGroup({ id: item.id })),
    [dispatch]
  );

  return (
    <Container vertical>
      <SubHeader>
        <Title>{`Groupes${count != null ? ` (${count})` : ''}`}</Title>
        <Button small to={`${path}/nouveau-groupe`} icon={MdAdd}>
          Nouveau groupe
        </Button>
      </SubHeader>
      <Container vertical huge padded>
        <FilterBar
          big
          searchQuery="displayNameContAny"
          onChanges={setFilters}
        />
        <GroupList
          spacing={8}
          headerVariant="transparent"
          selector={GroupSelector.selectGroups}
          action={GroupAction.getGroups}
          onCountChanged={setCount}
          filters={filters}
          perPage={50}
          detailUrlPrefix=""
          isDeletable
          deleteRequest={handleDelete}
        />
      </Container>
    </Container>
  );
};

export default Groups;

import React, { forwardRef } from 'react';

const Emergency = forwardRef((props, ref) => {
  const color = '#DD1614';
  return (
    <svg ref={ref} style={props.style} height={24} viewBox="0 0 510 510">
      <path
        d="m23.503906 198.367188 174.863282-174.863282c31.242187-31.242187 81.898437-31.242187 113.140624 0l174.863282 174.863282c31.242187 31.242187 31.242187 81.898437 0 113.140624l-174.863282 174.863282c-31.242187 31.242187-81.898437 31.242187-113.140624 0l-174.863282-174.863282c-31.242187-31.242187-31.242187-81.898437 0-113.140624zm0 0"
        fill={color}
      />
      <g fill="#fff">
        <path d="m254.929688 142.9375c8.835937 0 16 7.164062 16 16v128c0 8.835938-7.164063 16-16 16-8.835938 0-16-7.164062-16-16v-128c0-8.835938 7.164062-16 16-16zm0 0" />
        <path d="m238.929688 334.9375h32v32h-32zm0 0" />
      </g>
    </svg>
  );
});

export default Emergency;

import React, { useState, useCallback } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { MdAdd } from 'react-icons/md';
import SubHeader from 'components/v3/SubHeader';
import { FilterBar } from 'components/v3/Filters';
import { Container, Title, Button } from 'components/v3/ui';
import { ProjectKindSelector, ProjectKindAction } from 'features/ProjectKinds';
import ProjectKindList from 'features/ProjectKinds/components/v3/List';

const ProjectKinds = () => {
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  const [count, setCount] = useState();
  const [filters, setFilters] = useState();

  const handleDelete = useCallback(
    item => dispatch(ProjectKindAction.deleteProjectKind({ id: item.id })),
    [dispatch]
  );

  return (
    <Container vertical>
      <SubHeader>
        <Title>{`Types de projet${count != null ? ` (${count})` : ''}`}</Title>
        <Button small to={`${path}/nouveau-type-de-projet`} icon={MdAdd}>
          Nouveau type
        </Button>
      </SubHeader>
      <Container vertical huge padded>
        <FilterBar
          big
          searchQuery="displayNameContAny"
          onChanges={setFilters}
        />
        <ProjectKindList
          spacing={8}
          headerVariant="transparent"
          selector={ProjectKindSelector.selectProjectKinds}
          action={ProjectKindAction.getProjectKinds}
          onCountChanged={setCount}
          filters={filters}
          perPage={50}
          detailUrlPrefix=""
          isDeletable
          deleteRequest={handleDelete}
        />
      </Container>
    </Container>
  );
};

export default ProjectKinds;

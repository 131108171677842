import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { Switch, Route } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import store, { history } from './store';
import { saveState } from './api/localStorage';
import { theme } from './ui/theme';
import 'normalize.css/normalize.css';
import './index.css';
import moment from 'moment';
import 'moment/locale/fr';
import Auth from 'pages/Auth';
import App from './App';
import { APPSIGNAL_KEY } from './constants';
import ScrollToTop from 'utils/hooks/scrollToTop';
import Firebase from 'components/Firebase';
import Appsignal from '@appsignal/javascript';
import { ErrorBoundary } from '@appsignal/react';
import Error from 'pages/Error';
import { unregister as unregisterServiceWorker } from './serviceWorker';
import { ToastContainer } from 'components/v3/Toast';

const appsignal = new Appsignal({
  key: APPSIGNAL_KEY
});

moment.locale('fr');

const handleError = error => {
  const state = store.getState();
  const { ui } = state;

  const span = appsignal.createSpan(span => {
    span.setError(error);
    span.setTags({
      accountId: ui.currentAccountId,
      userId: ui.userId
    });
    return span;
  });

  appsignal.send(span);
  return <Error />;
};

store.subscribe(() => {
  const state = store.getState();
  const { ui } = state;

  saveState({
    ui
  });
});

render(
  <ErrorBoundary instance={appsignal} fallback={handleError}>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <ConnectedRouter history={history}>
          <ScrollToTop />
          <Firebase />
          <Switch>
            <Route
              path={[
                '/mot-de-passe-oublie',
                '/connexion',
                '/callback/:type',
                '/email-envoye'
              ]}
              render={() => <Route component={Auth} />}
            />
            <Route component={App} />
          </Switch>
          <ToastContainer />
        </ConnectedRouter>
      </ThemeProvider>
    </Provider>
  </ErrorBoundary>,
  document.getElementById('app')
);

unregisterServiceWorker();

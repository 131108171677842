import { get, put, post, destroy } from 'common/api';

export const getPmvs = params => get('/v5/pmvs', params);

export const readPmvs = params => get('v5/pmvs/read', params);

export const createPmv = (pmv, config) => post('/v5/pmvs', { pmv }, config);

export const getPmv = (id, config) => get(`/v5/pmvs/${id}`, config);

export const putPmv = (id, pmv, config) =>
  put(`/v5/pmvs/${id}`, { pmv }, config);

export const readPmv = id => get(`/v5/pmvs/${id}/read`);

export const deletePmv = (id, config) => destroy(`/v5/pmvs/${id}`, config);

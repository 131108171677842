import React, { Fragment, useEffect, useState, useCallback, useMemo, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router';
import styled from 'styled-components';
import isEqual from 'lodash/isEqual';
import { MdAdd } from 'react-icons/md';
import { useFilters } from 'components/v3/Filters';
import RawTimeline from 'features/Plannings/components';
import { UiSelector, UiAction } from 'features/UiPreferences';
import { useAccountConfig, features } from 'config';

import { PlanningSelector, PlanningAction } from 'features/Plannings';

import { Container, ButtonIcon } from 'components/v3/ui';

import { useRedux, useDeepEffect, useDelay, useDeepMemo } from 'components/v3/utils';
import GroupSelect from 'features/Groups/components/v3/Select';
import ProjectKindSelect from 'features/ProjectKinds/components/v3/Select';
import EventMultiLines from './EventMultiLines';
import Resource from './Resource';
import DayHeaderView from 'components/v3/Planning/DayHeaderView';
import { TIMELINE_SIDEBAR_WIDTH } from 'ui';
import { parameterizedString } from 'utils';

const FloatingButton = styled.div`
  position: fixed;
  bottom: 0px;
  right: 0px;
  height: 56px;
  width: 56px;
  z-index: 2;
  border-radius: 50%;
  margin: 16px;
  min-width: 56px;
  > i {
    margin-right: 0px;
  }
`.withComponent(ButtonIcon);

const groupFilter = {
  kind: 'select',
  key: 'ui.selectedGroups',
  component: GroupSelect,
  value: [],
  asQueryParam: (value) => ({
    groupIdIn: value?.length > 0 ? value.map((v) => v.id) : undefined
  }),
  props: {
    multi: true,
    inputCreatable: false,
    optionsCheckable: true,
    limitValues: 2,
    style: {
      minWidth: 120,
      maxWidth: 360
    }
  }
};

const projectKindFilter = {
  kind: 'select',
  key: 'ui.selectedProjectKind',
  component: ProjectKindSelect,
  value: null,
  asQueryParam: (value, kind) => {
    if (kind === 'phases') {
      return {
        projectProjectKindIdEq: value?.id
      };
    } else {
      return {
        projectKindIdEq: value?.id
      };
    }
  },
  props: {
    inputCreatable: false,
    style: {
      minWidth: 150,
      maxWidth: 250
    }
  }
};

export const SCHEDULER_TYPE = {
  MACHINE: {
    name: 'machines',
    kind: 'scheduler',
    eventResourceEditable: true,
    includeArchived: true,
    filters: [groupFilter, projectKindFilter]
  },
  USERS: {
    name: 'users',
    kind: 'scheduler',
    eventResourceEditable: true,
    includeArchived: true,
    holidaysInResources: true,
    filters: [groupFilter, projectKindFilter]
  },
  TEAMS: {
    name: 'teams',
    kind: 'scheduler',
    eventResourceEditable: true,
    includeArchived: true,
    filters: [groupFilter, projectKindFilter]
  },
  PROJECTS: {
    name: 'projects',
    kind: 'timeline',
    eventResourceEditable: false,
    includeArchived: false,
    filters: [groupFilter, projectKindFilter]
  }
};

const slotLabelContent = (info) => <DayHeaderView {...info} />;

const Timeline = ({
  schedulerType, // SCHEDULER_TYPE
  resources,
  onResourceClick,
  resourceLabelText, // exemple: Projets (32)
  additionalPhases,
  showArchived,
  onDateRangeChanged,
  onFiltersChange,
  onSearch
}) => {
  const dispatch = useDispatch();
  const delay = useDelay();
  const { accountId } = useAccountConfig();

  const [filters, setFilters] = useFilters([]);
  const [dateRange, setDateRange] = useState();
  const { hasFeature } = useAccountConfig();

  const { path } = useRouteMatch();

  const isUpdating = useSelector(PlanningSelector.selectPlanningUpdating);
  const isLoading = useSelector(PlanningSelector.selectPlanningLoading);

  const uiSelector = useMemo(
    () => (state) =>
      UiSelector.selectUiKeys(
        'planning.views',
        'planning.filters',
        'planning.selectedView',
        'planning.showOverlap',
        'planning.showWeekend',
        'planning.holidaysTop'
      )(state)?.planning || {},
    []
  );

  useEffect(() => {
    dispatch(
      PlanningAction.receivedPlanningData({
        data: [],
        included: []
      })
    );
  }, [dispatch]);

  const {
    views: planningViews,
    filters: planningFilters,
    selectedView: selectedPlanningView,
    showOverlap,
    showWeekend,
    holidaysTop
  } = useSelector(uiSelector, isEqual);

  const eventsSelector = useMemo(
    () => (state) => PlanningSelector.selectPlanningEvents(state, { schedulerType }),
    [schedulerType]
  );
  const [events, fetchEvents] = useRedux(eventsSelector, PlanningAction.getPlanningData, {
    fetchOnMount: false
  });

  useDeepEffect(() => {
    if (!dateRange) {
      return;
    }
    let params = {
      from: dateRange.startAt,
      'between[start_at]': dateRange.startAt,
      to: dateRange.endAt,
      'between[end_at]': dateRange.endAt,
      ...(filters.asQueryParams ? filters.asQueryParams('phases') : {})
    };

    if (hasFeature(features.SPLIT_PLANNING_SOURCES)) {
      params = {
        ...params,
        sourceInOrBlank: [schedulerType.name]
      };
    }

    const { includeArchived } = schedulerType;
    if (!includeArchived && !showArchived) {
      params = {
        ...params,
        projectStateNotEqOrNull: 'archived',
        schedulerType
      };
    }
    delay(() => fetchEvents(params), 200, 'fetchEvents');
  }, [accountId, dateRange, fetchEvents, schedulerType, showArchived, delay, filters, hasFeature]);

  const useFirstMount = useRef();
  useDeepEffect(() => {
    if (onFiltersChange && useFirstMount.current) {
      onFiltersChange(filters);
    }
    useFirstMount.current = true;
  }, [accountId, filters]);

  useDeepEffect(() => {
    if (!dateRange || !onDateRangeChanged) {
      return;
    }
    onDateRangeChanged(dateRange);
  }, [dateRange, onDateRangeChanged]);

  const memoResources = useMemo(() => {
    if (hasFeature(features.MANAGEMENT_HOLIDAYS) && !schedulerType.holidaysInResources) {
      const holiday = {
        id: 'holiday',
        source: 'holiday',
        classNames: ['holiday'],
        displayName: 'Congés',
        resourceOrder: holidaysTop ? -1 : null
      };
      return [
        holiday,
        ...resources.map((res, index) => ({
          ...res,
          source: schedulerType.name,
          resourceOrder: index
        }))
      ];
    } else {
      return resources.map((res, index) => ({
        ...res,
        source: schedulerType.name,
        resourceOrder: index
      }));
    }
  }, [holidaysTop, resources, hasFeature, schedulerType]);

  const memoEvents = useDeepMemo(() => {
    let sourcedEvents = events;
    // if (hasFeature(features.SPLIT_PLANNING_SOURCES)) {
    //   sourcedEvents = events.filter(({ source, planningSource }) => {
    //     if (source !== 'phase' || !planningSource || !schedulerType)
    //       return true;
    //     return schedulerType.name === planningSource;
    //   });
    // }
    return [...sourcedEvents, ...(additionalPhases || [])];
  }, [events, additionalPhases, schedulerType]);

  // const MemoB = useMemo(
  //   () => ({ info }) => {
  //     // const dateTimeFormat = new Intl.DateTimeFormat('fr', props.format);
  //     const date = info.date;
  //     const canShowDayHint = info.text.contains('#DAY_HINT');
  //     return (
  //       <Fragment>
  //         {props.info.text}
  //         {props.canShowDayHint && <DayHeaderView date={date.marker} />}
  //       </Fragment>
  //     );
  //   },
  //   []
  // );

  // const calendarViews = useMemo(() => {
  //   if (!planningViews) {
  //     return { month: VIEWS.month };
  //   }
  //   return Object.keys(planningViews).reduce((acc, viewName) => {
  //     acc[viewName] = {
  //       ...VIEWS[viewName], // TODO REMOVE
  //       ...planningViews[viewName],
  //       slotLabelFormat:
  //         planningViews[viewName].slotLabelFormat?.map(
  //           ({ canShowDayHint, format, ...rest }) => {
  //             if (rest.function) {
  //               // eslint-disable-next-line no-new-func
  //               const func = new Function(
  //                 rest.function.arguments,
  //                 rest.function.body
  //               );
  //               return info => {
  //                 const text = func(info);
  //                 if (canShowDayHint) {
  //                   return `${text}#DAY_HINT`;
  //                 } else {
  //                   return text;
  //                 }
  //               };
  //             } else if (format) {
  //               return info => {
  //                 const text = printDate(info.date.marker, format);
  //                 if (canShowDayHint) {
  //                   return `${text}#DAY_HINT`;
  //                 } else {
  //                   return text;
  //                 }
  //               };
  //             } else {
  //               const formatter = new Intl.DateTimeFormat('fr', rest);
  //               return info => {
  //                 const text = formatter.format(info.date.marker);
  //                 if (canShowDayHint) {
  //                   return `${text}#DAY_HINT`;
  //                 } else {
  //                   return text;
  //                 }
  //               };
  //             }
  //           }
  //         ) || VIEWS[viewName].slotLabelFormat // TODO REMOVE
  //       // slotLabelFormat:
  //       //   planningViews[viewName].slotLabelFormat?.map(format => {
  //       //     if (format.function) {
  //       //       // eslint-disable-next-line no-new-func
  //       //       return new Function(
  //       //         format.function.arguments,
  //       //         format.function.body
  //       //       );
  //       //     } else {
  //       //       return format;
  //       //     }
  //       //   }) || VIEWS[viewName].slotLabelFormat // TODO REMOVE
  //     };
  //     return acc;
  //   }, {});
  // }, [planningViews]);

  const view = planningViews ? planningViews[selectedPlanningView] || Object.values(planningViews)[0] : null;

  useEffect(() => {
    if (planningFilters && planningFilters.length > 0) {
      setFilters([
        ...schedulerType.filters,
        ...planningFilters.map((filter) => {
          return {
            kind: filter.kind,
            key: filter.name,
            props: {
              tooltip: filter.tooltip || filter.hint,
              label: filter.label,
              size: 18
            },
            asQueryParam: (value, schedulerType) => {
              const queryJoinedTable = !schedulerType?.name?.includes(filter.table);
              const queryName = `${queryJoinedTable ? `${filter.table}_` : ''}${filter.column}_${filter.queryName}`;
              if (!value) {
                return {
                  [queryName]: undefined
                };
              }
              return {
                [queryName]: `${parameterizedString(filter.queryFormat, value)}`
              };
            }
          };
        })
      ]);
    } else {
      setFilters(schedulerType.filters);
    }
  }, [setFilters, schedulerType, planningFilters]);

  const eventRender = useCallback(
    (props) => {
      if (props.event.display === 'background') {
        return;
      }
      switch (schedulerType.kind) {
        case 'timeline':
          return <EventMultiLines {...props} />;
        default:
          if (props.event.extendedProps.source === 'holiday') {
            if (schedulerType.holidaysInResources) {
              return <EventMultiLines {...props} isHoliday />;
            } else {
              return <EventMultiLines {...props} />;
            }
          }
          return <EventMultiLines {...props} planningName={schedulerType.name} />;
      }
    },
    [schedulerType]
  );

  const resourceRender = useCallback(
    (props) => {
      let onClick = null;
      if (props.resource.id !== 'holiday') {
        onClick = onResourceClick ? () => onResourceClick(props.resource.id) : undefined;
      }
      return <Resource {...props} onClick={onClick} />;
    },
    [onResourceClick]
  );

  const handleSelectView = useCallback(({ name }) => dispatch(UiAction.selectPlanningView(name)), [dispatch]);

  const phasePromptProps = useMemo(
    () => ({
      hideMachines: schedulerType === SCHEDULER_TYPE.MACHINE,
      hideMembers: schedulerType === SCHEDULER_TYPE.USERS,
      hideTeam: schedulerType === SCHEDULER_TYPE.TEAMS,
      hideProject: schedulerType === SCHEDULER_TYPE.PROJECTS,
      source: schedulerType.name
    }),
    [schedulerType]
  );

  const phaseModalProps = useMemo(
    () => ({
      showSelectProject: schedulerType !== SCHEDULER_TYPE.PROJECTS,
      source: schedulerType.name
    }),
    [schedulerType]
  );

  const containerStyle = useMemo(
    () => ({
      pointerEvents: isUpdating ? 'none' : undefined
    }),
    [isUpdating]
  );

  const [canRender, setCanRender] = useState(false);
  useEffect(() => {
    setCanRender(false);
    setTimeout(() => {
      setCanRender(true);
    });
  }, [schedulerType]);

  return (
    <Fragment>
      <Container vertical className={schedulerType.kind} style={containerStyle}>
        {canRender && (
          <RawTimeline
            currentView={view}
            views={planningViews}
            onSelectView={handleSelectView}
            onDateRangeChanged={setDateRange}
            filters={filters}
            onFiltersChange={setFilters}
            searchLabel={`Rechercher${schedulerType === SCHEDULER_TYPE.PROJECTS ? ' (3 lettres mini)' : ''}`}
            resources={memoResources}
            resourceOrder="resourceOrder"
            resourceAreaHeaderContent={resourceLabelText}
            resourceLabelContent={resourceRender}
            resourceAreaWidth={TIMELINE_SIDEBAR_WIDTH}
            events={memoEvents}
            eventContent={eventRender}
            isLoading={isLoading}
            eventResourceEditable={schedulerType.eventResourceEditable}
            showOverlap={showOverlap}
            weekends={showWeekend}
            phasePromptProps={phasePromptProps}
            phaseModalProps={phaseModalProps}
            slotLabelContent={slotLabelContent}
            onSearch={onSearch}
          />
        )}
      </Container>
      <FloatingButton to={`${path}/new`} variant="accent" size="large">
        <MdAdd size={40} />
      </FloatingButton>
    </Fragment>
  );
};

export default Timeline;

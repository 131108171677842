import React, { Fragment, useEffect, useCallback } from 'react';
import {
  useRouteMatch,
  Route,
  useHistory,
  useLocation
} from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { Text, NavLink } from 'components/v3/ui';
import List from 'components/v3/List/List';
import Table from 'components/v3/List/Table';
import { useListFetcher } from 'components/v3/utils';
import GpsBoxModal from './Modal';
import GpsBoxCard from './Card';

const columns = [
  {
    Header: 'Machine associée',
    accessor: 'displayName',
    width: '50%',
    Cell: ({ item }) => <Text variant="small">{item.machine?.displayName}</Text>
  },
  {
    Header: 'Numéro de serie',
    accessor: 'serial',
    width: '40%',
    Cell: ({ item }) => (
      <Text variant="caption" color="theme">
        {item.serial}
      </Text>
    )
  },
  {
    Header: '% Batterie',
    accessor: 'battery',
    width: '10%',
    minWidth: '2em',
    textAlign: 'end',
    Cell: ({ item }) => <Text variant="small">{item.battery}</Text>
  }
];

const GpsBoxList = ({
  selector,
  action,
  options = {
    fetchOnMount: false,
    useLocalState: true
  },
  params = {
    sort: 'display_name'
  },
  perPage = 25,
  filters,
  onCountChanged,
  detailUrlPrefix = '/gps',
  asCard,
  onListChanged,
  onStateChanged,
  ...props
}) => {
  const { path, url } = useRouteMatch();
  const history = useHistory();
  const location = useLocation();
  const shouldUseCardView = useMediaQuery({ query: '(max-width: 600px)' });
  const isCardView = asCard || shouldUseCardView;
  const Component = isCardView ? List : Table;

  const [gpsBoxes, paginationHelper, state] = useListFetcher({
    selector,
    action,
    options,
    params,
    perPage,
    filters
  });

  const detailLinkFormatter = useCallback(
    item => `${path}${detailUrlPrefix}/${item.id}`,
    [path, detailUrlPrefix]
  );

  const cardRenderer = useCallback(
    ({ item }) => (
      <GpsBoxCard as={NavLink} to={detailLinkFormatter(item)} gpsBox={item} />
    ),
    [detailLinkFormatter]
  );

  useEffect(() => {
    if (paginationHelper.total != null && onCountChanged) {
      onCountChanged(paginationHelper.total);
    }
  }, [paginationHelper.total, onCountChanged]);

  useEffect(() => {
    if (onListChanged) {
      onListChanged(gpsBoxes);
    }
  }, [onListChanged, gpsBoxes]);

  useEffect(() => {
    if (onStateChanged) {
      onStateChanged(state);
    }
  }, [onStateChanged, state]);

  return (
    <Fragment>
      <Component
        scrollOverlay={isCardView}
        columns={columns}
        items={gpsBoxes}
        isLoading={state.isLoading}
        linkFormatter={detailLinkFormatter}
        centerMessage={state.errorMessage}
        {...paginationHelper}
        {...props}
        rowRenderer={isCardView ? cardRenderer : undefined}
        estimatedItemSize={isCardView ? 120 : props.estimatedItemSize}
      />
      <Route path={[`${path}${detailUrlPrefix}/:gpsBoxId`]}>
        <GpsBoxModal
          onRequestClose={() => {
            if (location.key) {
              history.goBack();
            } else {
              history.replace(url);
            }
          }}
        />
      </Route>
    </Fragment>
  );
};

export default GpsBoxList;

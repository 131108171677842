import { createAction } from 'redux-actions';
import * as Api from './api';
import { shouldFetch } from 'common/actions';
import omit from 'lodash/omit';

const reportKindsLoading = createAction('REPORT_KINDS_LOADING');
const reportKindsSuccess = createAction('REPORT_KINDS_SUCCESS');
const reportKindsError = createAction('REPORT_KINDS_ERROR');
export const getReportKinds = payload => async (dispatch, getState) => {
  const { useCache = true, ...params } = payload || {};
  const state = getState();
  if (!useCache || shouldFetch('reportKind', params, state)) {
    return fetchReportKinds(params, dispatch);
  } else {
    return {
      fromCache: true,
      isLoading: getState().reportKinds.isLoading
    };
  }
};
const fetchReportKinds = async (payload, dispatch) => {
  dispatch(reportKindsLoading());
  try {
    const { projectKindId } = payload;
    const result = await Api.getReportKinds(payload);
    dispatch(
      reportKindsSuccess({
        ...result,
        data: result.data.map(reportKind => ({
          ...reportKind,
          relationships: {
            ...reportKind.relationships,
            projectKind: {
              data: {
                id: `${projectKindId}`,
                type: 'projectKind'
              }
            }
          }
        })),
        requestParams: omit(payload, 'cancelToken')
      })
    );
    return result;
  } catch (error) {
    dispatch(reportKindsError(error));
    throw error;
  }
};

const receiveCreateReport = createAction('NEW_REPORT_SUCCESS');
export const createReport = ({ payload, ...config }) => async dispatch => {
  const result = await Api.createReport(payload, config);
  dispatch(receiveCreateReport(result));
  return result;
};

const receiveReport = createAction('REPORT_SUCCESS');
export const getReport = ({ id, ...payload }) => async dispatch => {
  const result = await Api.getReport(id, payload);
  dispatch(receiveReport(result));
  return result;
};

const receiveUpdateReport = createAction('UPDATE_REPORT_SUCCESS');
export const updateReport = ({
  id,
  payload: report,
  ...config
}) => async dispatch => {
  const result = await Api.putReport(id, report, config);
  dispatch(receiveUpdateReport(result));
  return result;
};

const receiveDeleteReport = createAction('DELETE_REPORT_SUCCESS');
export const deleteReport = ({ id, ...config }) => async dispatch => {
  const response = await Api.deleteReport(id, config);
  dispatch(receiveDeleteReport(id));
  return response;
};

import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { MdAdd } from 'react-icons/md';
import { first, orderBy } from 'lodash';
import Box, { BoxHeader } from 'components/v3/Box';
import { Button, Grid, GridItem, Text } from 'components/v3/ui';
import { VoucherSelector } from '../data';
import VoucherList from './VoucherList';
import VoucherVersionModal from './VoucherVersionModal';
import isSameIds from 'utils/idUtils';
import { features, permissions, useAccountConfig } from 'config';

const VouchersBox = ({
  parentId: voucherableId,
  parentType: voucherableType,
  vouchers: rawVouchers,
  creatable,
  marged,
  padded,
  flat,
  ...props
}) => {
  const { hasFeature, hasPermission } = useAccountConfig();
  const [createVersionOpened, setCreateVersionOpened] = useState(false);

  const vouchers = useMemo(() => {
    return orderBy(
      rawVouchers?.map(voucher => {
        const lastVersion = first(
          orderBy(voucher.voucherVersions, 'createdAt', 'desc')
        );
        return {
          ...voucher,
          lastVersion
        };
      }) || [],
      'id',
      'desc'
    );
  }, [rawVouchers]);

  const selectVoucherKinds = useMemo(
    () => state => {
      return VoucherSelector.selectVoucherKinds(state, { voucherableType });
    },
    [voucherableType]
  );
  const voucherKinds = useSelector(selectVoucherKinds);
  const availableVoucherKinds = useMemo(() => {
    return voucherKinds.filter(({ id }) => {
      return !vouchers?.find(({ voucherKindId }) =>
        isSameIds(id, voucherKindId)
      );
    });
  }, [vouchers, voucherKinds]);

  if (voucherKinds.length === 0 || !hasFeature(features.VOUCHERS)) return null;

  return (
    <Box marged={marged} padded={padded} flat={flat}>
      <BoxHeader bigPadded title="Documents officiels">
        {hasPermission(permissions.COMPANY_DATA) &&
          creatable &&
          availableVoucherKinds.length > 0 &&
          vouchers.length > 0 && (
            <Button
              small
              icon={MdAdd}
              onClick={() => setCreateVersionOpened(true)}
            >
              Ajouter un document officiel
            </Button>
          )}
      </BoxHeader>
      <VoucherList
        rowColor="light"
        vouchers={vouchers}
        voucherableId={voucherableId}
        voucherableType={voucherableType}
        headerVariant="transparent"
        centerMessage={
          vouchers.length === 0 && (
            <Grid justifyCenter>
              <GridItem width="100%" justifyCenter alignCenter>
                <Text variant="hint">
                  Il n'y a pas encore de document officiel.
                </Text>
              </GridItem>
              {hasPermission(permissions.COMPANY_DATA) && (
                <GridItem width="100%" justifyCenter alignCenter>
                  <div>
                    <Button
                      small
                      variant="text"
                      icon={MdAdd}
                      onClick={() => setCreateVersionOpened(true)}
                    >
                      Ajouter un document officiel
                    </Button>
                  </div>
                </GridItem>
              )}
            </Grid>
          )
        }
        {...props}
      />
      {createVersionOpened && (
        <VoucherVersionModal
          availableVoucherKinds={availableVoucherKinds}
          voucherableId={voucherableId}
          voucherableType={voucherableType}
          onRequestClose={() => setCreateVersionOpened(false)}
        />
      )}
    </Box>
  );
};

export default VouchersBox;

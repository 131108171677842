import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import isEqual from 'lodash/isEqual';
import { UiSelector } from 'features/UiPreferences';
import { PlanningSelector, isEventOverlap } from 'features/Plannings';

const Wrapper = styled.div`
  border-radius: 5px;
  padding: 0px 2px;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;

  ${({ overlap }) =>
    overlap &&
    css`
      border-top: 3px solid red;
      border-bottom: 1px solid transparent;
    `}
`;

const EventWrapper = ({ id, children }) => {
  const uiSelector = useMemo(
    () => state =>
      UiSelector.selectUiKeys('planning.showOverlap')(state)?.planning || {},
    []
  );
  const { showOverlap } = useSelector(uiSelector, isEqual);

  const overlapSelector = useMemo(
    () => state => PlanningSelector.selectEventOverlaps(state, { eventId: id }),
    [id]
  );
  const overlaps = useSelector(overlapSelector, isEqual);
  const isOverlap = useMemo(() => showOverlap && isEventOverlap(overlaps), [
    showOverlap,
    overlaps
  ]);

  const child = useMemo(
    () =>
      React.cloneElement(children, {
        overlaps
      }),
    [children, overlaps]
  );
  return <Wrapper overlap={isOverlap}>{child}</Wrapper>;
};

export default EventWrapper;

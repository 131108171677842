import React, { useEffect, useCallback, useState } from 'react';
import styled, { css } from 'styled-components';
import { MdPrint, MdSend } from 'react-icons/md';
import { Button, Text } from 'components/v3/ui';
import State from './InvoiceState';
import { authenticateUrl } from 'utils';
import { getInvoiceKind } from '..';
import Checkbox from 'components/v3/Checkbox';
import { features, Permission, permissions } from 'config';
import { IcExternalLink } from 'common/images/icons';
import { useSelector } from 'react-redux';
import { MeSelector } from 'features/Me';
import { isEqual } from 'lodash';
import SendEmailModal from './SendEmailModal';
import { InvoiceField } from './InvoiceSelect';
import { InvoiceDuplicateButton } from './InvoiceDuplicateButton';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-bottom: 8px;
`;

const CreatedBy = styled.div`
  color: #888888;
  font-size: 14px;
  position: absolute;
  top: 8px;
  left: 24px;
`;

const CenterItems = styled.div`
  display: flex;
  * {
    margin: 0px 4px;
  }
`;

const LeftItems = styled.div`
  align-items: center;
  display: flex;
`;

const CheckboxSign = styled.div`
  padding: 4px 8px 4px 6px;
  border-radius: ${({ theme }) => theme.borderButtonRadius};
  width: 5rem;
  margin-left: 12px;
  label {
    margin-left: 4px;
  }
  ${({ value }) =>
    (value === 'true' || value === true) &&
    css`
      background: ${({ theme }) => theme.success};
      color: white;
      label {
        color: white;
      }
    `}
`.withComponent(Checkbox);

const Toolbar = ({ values, setFieldValue, submitForm, status, setStatus, onDuplicate, projectId, readOnly }) => {
  const [emailOpen, setEmailOpen] = useState(false);
  const documentTemplate = values.documentTemplate;

  const currentUser = useSelector(MeSelector.selectMe, isEqual);
  useEffect(() => {
    if (status?.includes('printing') && documentTemplate) {
      const print = async () => {
        await submitForm().then(
          () => {
            setStatus(null);
            window.open(authenticateUrl(`${documentTemplate.fileUrl}${status.split('#')[1] ?? ''}`), '_blank');
          },
          () => setStatus(null)
        );
      };
      print();
    }
  }, [status, submitForm, setStatus, documentTemplate]);

  const handleStateSuccess = useCallback((state) => setFieldValue('state', state), [setFieldValue]);

  const relatedInvoiceKind = values?.invoice?.kind ? getInvoiceKind(values?.invoice?.kind) : undefined;
  if (!values.id) {
    return null;
  }
  return (
    <Wrapper>
      {values?.createdBy?.displayName && (
        <CreatedBy>
          <Text variant="caption" color="hint">
            Créé par
          </Text>{' '}
          <Text variant="caption" color="caption">
            {values.createdBy?.displayName}
          </Text>
        </CreatedBy>
      )}
      <LeftItems>
        {values.number && (
          <Permission permission={permissions.ACCOUNTING} feature={features.SEND_INVOICE_BY_EMAIL}>
            <Button icon={MdSend} small onClick={() => setEmailOpen(true)} style={{ marginRight: 16 }}>
              Envoyer par email
            </Button>
          </Permission>
        )}
        <Button icon={MdPrint} small onClick={() => setStatus('printing#&without_prices=false')}>
          Imprimer
        </Button>
        <Permission feature={features.PRINT_INVOICE_WITHOUT_PRICES}>
          <Button
            icon={MdPrint}
            small
            style={{ marginLeft: 16 }}
            onClick={() => setStatus('printing#&without_prices=true')}
          >
            Imprimer sans les prix
          </Button>
        </Permission>
        {currentUser.settings?.canSign && (
          <Permission feature={features.SIGN_INVOICE}>
            <CheckboxSign
              value={values.signed}
              color={values.signed ? 'white' : undefined}
              label={values.signed ? 'Signé ' : 'Signer'}
              onChange={async (signed) => {
                setStatus('signing');
                setFieldValue('signed', signed);
                submitForm().then(
                  () => setStatus(null),
                  () => setStatus(null)
                );
              }}
            />
          </Permission>
        )}
      </LeftItems>
      <CenterItems>
        <State
          size="medium"
          invoiceId={values.id}
          invoiceKind={values.kind}
          value={values.state}
          onSuccess={handleStateSuccess}
        />
        <span style={{ position: 'relative' }}>
          {relatedInvoiceKind && (
            <div
              style={{
                position: 'absolute',
                top: -14,
                width: '100%',
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <Text variant="caption">{relatedInvoiceKind.label} associé.</Text>
            </div>
          )}

          <InvoiceField
            name="invoice"
            projectId={values.projectId}
            asTag
            onTrailingIconClick={() => setFieldValue('invoiceId', null)}
            size="medium"
          />
        </span>
        <InvoiceDuplicateButton values={values} onDuplicate={onDuplicate} />
      </CenterItems>
      {!projectId && <div style={{ width: 100 }} />}
      {projectId && (
        <Button icon={IcExternalLink} variant="text" to={`/projets/${projectId}`}>
          Voir le projet
        </Button>
      )}
      {emailOpen && (
        <SendEmailModal
          onRequestClose={() => setEmailOpen(false)}
          projectId={projectId || values.projectId}
          invoice={values}
        />
      )}
    </Wrapper>
  );
};

export default Toolbar;

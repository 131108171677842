import React, { useEffect, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { decamelize } from 'humps';
import { isEqual } from 'lodash';
import { Text, NavLink } from 'components/v3/ui';
import Table from 'components/v3/List/Table';
import List from 'components/v3/List/List';
import Tag from 'components/v3/Tag';
import Avatar from 'components/v3/Avatar';
import { useListFetcher } from 'components/v3/utils';
import { printDate } from 'utils/dates';
import { UiSelector } from 'features/UiPreferences';
import State from './State';
import ProjectCard from './Card';
import { features, useAccountConfig } from 'config';

const COLUMNS = [
  {
    Header: 'Groupe',
    accessor: 'groupId',
    width: '7.5%',
    textAlign: 'center',
    sortable: true,
    Cell: ({ item }) => (
      <Tag displayName={item.group?.displayName} color={item.group?.color} />
    )
  },
  {
    Header: 'Créé le',
    accessor: 'createdAt',
    width: '10%',
    textAlign: 'center',
    sortable: true,
    Cell: ({ item }) => (
      <Text variant="small" color="caption">
        {printDate(item.createdAt)}
      </Text>
    )
  },
  {
    Header: 'Resp.',
    accessor: 'owner',
    width: '56px',
    textAlign: 'center',
    Cell: ({ item }) => <Avatar user={item.user} />
  },
  {
    Header: 'Référence',
    accessor: 'reference',
    sortable: true,
    width: '15%',
    Cell: ({ item }) => (
      <Text variant="caption" color="theme">
        {item.reference}
      </Text>
    )
  },
  {
    Header: ' ',
    accessor: 'displayName',
    width: 'calc(35% - 56px)',
    Cell: ({ item }) => <Text variant="small">{item.displayName}</Text>
  },
  {
    Header: 'Coût',
    accessor: 'totalCost',
    sortable: true,
    width: '10%',
    textAlign: 'end',
    Cell: ({ item }) => (
      <Text currency variant="small">
        {item.totalCost}
      </Text>
    )
  },
  {
    Header: 'CA',
    accessor: 'estimatedRevenues',
    sortable: true,
    width: '10%',
    textAlign: 'end',
    Cell: ({ item }) => (
      <Text currency variant="small">
        {item.estimatedRevenues}
      </Text>
    )
  },
  {
    Header: 'État',
    accessor: 'state',
    width: '12.5%',
    textAlign: 'center',
    sortable: true,
    Cell: ({ item }) => <State value={item.state} projectId={item.id} />
  }
];

const getSortParam = ({ accessor, desc }) => {
  if (!accessor) {
    return undefined;
  }
  if (desc) {
    return `-${decamelize(accessor)}`;
  } else {
    return decamelize(accessor);
  }
};

const ProjectList = ({
  selector,
  action,
  options = {
    fetchOnMount: false,
    useLocalState: true
  },
  params = { sort: '-updated_at' },
  perPage = 25,
  filters,
  onCountChanged,
  detailUrlPrefix = '/projets',
  asCard,
  onStateChanged,
  onListChanged,
  ...props
}) => {
  const { hasFeature } = useAccountConfig();
  const shouldUseCardView = useMediaQuery({ query: '(max-width: 600px)' });
  const isCardView = asCard || shouldUseCardView;
  const Component = isCardView ? List : Table;
  const selectUi = useMemo(
    () => state =>
      UiSelector.selectUiKeys('projectList.hideArchived')(state).projectList ||
      {},
    []
  );
  const { hideArchived } = useSelector(selectUi, isEqual);

  const memoParams = useMemo(() => {
    if (!hideArchived) return params;
    return {
      ...params,
      stateNotEqOrNull: 'archived'
    };
  }, [params, hideArchived]);

  const [projects, paginationHelper, state, fetchProjects] = useListFetcher({
    selector,
    action,
    options,
    params: memoParams,
    perPage,
    filters
  });

  const detailLinkFormatter = useCallback(
    item => `${detailUrlPrefix}/${item.id}`,
    [detailUrlPrefix]
  );

  const cardRenderer = useCallback(
    ({ item }) => (
      <ProjectCard as={NavLink} to={detailLinkFormatter(item)} project={item} />
    ),
    [detailLinkFormatter]
  );

  useEffect(() => {
    if (paginationHelper.total != null && onCountChanged) {
      onCountChanged(paginationHelper.total);
    }
  }, [paginationHelper.total, onCountChanged]);

  useEffect(() => {
    if (onListChanged) {
      onListChanged(projects);
    }
  }, [onListChanged, projects]);

  useEffect(() => {
    if (onStateChanged) {
      onStateChanged(state);
    }
  }, [onStateChanged, state]);

  const columns = useMemo(() => {
    if (hasFeature(features.ACCOUNTING)) {
      return COLUMNS;
    }
    return COLUMNS.filter(
      ({ accessor }) => !['totalCost', 'estimatedRevenues'].includes(accessor)
    ).map(col => ({
      ...col,
      width: col.accessor === 'displayName' ? 'calc(55% - 56px)' : col.width
    }));
  }, [hasFeature]);

  return (
    <Component
      scrollOverlay={isCardView}
      columns={columns}
      items={projects}
      isLoading={state.isLoading}
      linkFormatter={detailLinkFormatter}
      centerMessage={state.errorMessage}
      onColumnSortChanged={sort => {
        fetchProjects(params => ({
          ...params,
          sort: getSortParam(sort)
        }));
      }}
      {...paginationHelper}
      {...props}
      rowRenderer={isCardView ? cardRenderer : undefined}
      estimatedItemSize={
        isCardView
          ? 150
          : props.estimatedItemSize || 48 + (props.spacing || 0) / 2
      }
    />
  );
};

export default ProjectList;

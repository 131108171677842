import React, { useRef, useCallback, Fragment, useMemo } from 'react';
import styled from 'styled-components';
import { Field, FastField } from 'formik';
import { DashContainer, Dashed, Text, SectionTitle, Button } from '../ui';
import { useDelay } from '../utils';
import { MdAdd } from 'react-icons/md';
import Table from '../List/Table';
import { CurrentOption } from '../Select/Select';

const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;
  justify-content: space-between;
`;

const Label = styled(Text)`
  padding-left: 16px;
  padding-top: 6px;
  padding-right: 16px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  &:focus,
  &:focus-within {
    ${Label} {
      color: ${({ theme }) => theme.primaryLight};
    }
  }

  * > ${Dashed}:hover:not(:focus-within) {
    background-color: #f6f6f6;
  }
  * > ${Dashed}:focus-within {
    background-color: #eaeaea;
  }
`;

const Container = styled(DashContainer)`
  border: ${({ theme }) => theme.borderInput};
  border-top-left-radius: ${({ theme }) => theme.borderInputRadius};
  border-top-right-radius: ${({ theme }) => theme.borderInputRadius};
  overflow: hidden;
  max-height: 500px;

  * > input,
  * > textarea,
  * > ${CurrentOption} {
    padding-left: 2px;
    padding-right: 2px;
    font-size: 0.875rem;
  }
  * > label {
    left: 3px;
    font-size: 0.875rem;
  }
`;

const AddButton = styled.div`
  margin-top: 8px;
  align-self: flex-end;
`.withComponent(Button);

export const FormTableTitle = ({
  asSection,
  label,
  items,
  isRowValid,
  addRowLabel = 'Ajouter',
  onAddRow,
  children,
  readOnly,
  ...props
}) => {
  return (
    <LabelContainer {...props}>
      {asSection ? (
        <Fragment>
          <SectionTitle>
            {`${label}${
              items != null ? ` (${items.filter(i => !i._destroy).length})` : ''
            }`}
          </SectionTitle>
          {children}
          {!readOnly && onAddRow && (
            <Button
              small
              icon={MdAdd}
              disabled={
                isRowValid && Boolean(items?.find(row => !isRowValid(row)))
              }
              onClick={onAddRow}
            >
              {addRowLabel}
            </Button>
          )}
        </Fragment>
      ) : (
        <Fragment>
          <Label color="secondary" weight="semiBold">
            {`${label}${
              items != null ? ` (${items.filter(i => !i._destroy).length})` : ''
            }`}
          </Label>
          {children}
        </Fragment>
      )}
    </LabelContainer>
  );
};

export const FormTable = ({
  columns,
  rowCreator,
  label = '',
  asSection,
  value,
  onChange,
  onAddRow,
  addRowLabel = 'Ajouter',
  isRowValid,
  readOnly,
  className,
  style,
  subtitle,
  ...props
}) => {
  const { maxHeight } = props;
  const listRef = useRef();
  const delay = useDelay();
  const items = Array.isArray(value) ? value : [];
  const itemsRef = useRef();
  itemsRef.current = items;

  const handleAddRow = useCallback(() => {
    if (onAddRow) {
      onAddRow();
    } else {
      onChange([
        ...itemsRef.current,
        {
          ...rowCreator()
        }
      ]);
    }
    delay(listRef.current?.scroller().scrollToBottom, 50);
  }, [delay, onAddRow, onChange, rowCreator]);

  const containerStyle = useMemo(
    () => ({
      maxHeight
    }),
    [maxHeight]
  );

  return (
    <Wrapper width="100%" className={className} style={containerStyle}>
      {label && (
        <FormTableTitle
          asSection={asSection}
          label={label}
          items={items}
          isRowValid={isRowValid}
          addRowLabel={addRowLabel}
          readOnly={readOnly}
          onAddRow={rowCreator || onAddRow ? handleAddRow : null}
        />
        // <LabelContainer>
        //   {asSection ? (
        //     <Fragment>
        //       <SectionTitle>
        //         {`${label}${
        //           items != null
        //             ? ` (${items.filter(i => !i._destroy).length})`
        //             : ''
        //         }`}
        //       </SectionTitle>
        //       {(rowCreator || onAddRow) && (
        //         <Button
        //           variant="outline"
        //           small
        //           icon={MdAdd}
        //           disabled={
        //             isRowValid && Boolean(items?.find(row => !isRowValid(row)))
        //           }
        //           onClick={handleAddRow}
        //         >
        //           Ajouter une machine
        //         </Button>
        //       )}
        //     </Fragment>
        //   ) : (
        //     <Label color="secondary" weight="semiBold">
        //       {`${label}${
        //         items != null
        //           ? ` (${items.filter(i => !i._destroy).length})`
        //           : ''
        //       }`}
        //     </Label>
        //   )}
        // </LabelContainer>
      )}
      <Container readOnly={readOnly} fillWidth style={containerStyle}>
        <Table
          onChange={onChange}
          listRef={listRef}
          columns={columns}
          items={items}
          readOnly={false}
          hasDeleteConfirmation={false}
          headerVariant="dark"
          isDeletable
          maxHeight={400}
          minRows={1}
          {...props}
        />
      </Container>
      {!asSection && (rowCreator || onAddRow) && (
        <AddButton
          disabled={isRowValid && Boolean(items?.find(row => !isRowValid(row)))}
          variant="text"
          icon={MdAdd}
          onClick={handleAddRow}
        >
          {addRowLabel}
        </AddButton>
      )}
    </Wrapper>
  );
};

const TableFieldComponent = ({ field, form: { setFieldValue }, ...props }) => {
  const { name, value } = field;

  const handleChange = useCallback(
    value => setFieldValue(name, value),
    [setFieldValue, name]
  );

  return (
    <FormTable
      value={value}
      onChange={handleChange}
      onSortChanged={handleChange}
      {...props}
    />
  );
};

const TableField = ({ fastField = false, ...props }) => {
  const Component = fastField ? FastField : Field;
  return <Component component={TableFieldComponent} {...props} />;
};

export default TableField;

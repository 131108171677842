import React, { useCallback, useMemo, Fragment, useState, useEffect } from 'react';
import { SelectField } from 'components/v3/Form';
import { SelectInputLayout } from 'components/v3/InputLayout';
import { useRedux } from 'components/v3/utils';
import RawMaterialModal from './RawMaterialModal';
import { jsonApiToObject } from 'common/api';
import { SurfacingSelector, SurfacingAction, rawMaterialAsOption } from 'features/Surfacing';
import { permissions, useAccountConfig } from 'config';

const formatCreateLabel = (input) => `Créer la matière première ${input}`;

const RawMaterialSelect = ({ value, onChange, colored, fetchDisabled, ...props }) => {
  const { accountId, hasPermission } = useAccountConfig();
  const [rawMaterialModal, setRawMaterialModal] = useState();
  const [rawMaterials, fetch] = useRedux(SurfacingSelector.selectRawMaterials, SurfacingAction.getRawMaterials, {
    fetchOnMount: false,
    useLocalState: true,
    initialParams: {
      page: 1,
      perPage: 500,
      useCache: true
    }
  });

  const handleCreateOption = useCallback((input) => {
    setRawMaterialModal({ name: input });
  }, []);

  const handleEditOption = useCallback((value) => {
    setRawMaterialModal(value);
  }, []);

  const options = useMemo(() => rawMaterialAsOption(rawMaterials, colored), [rawMaterials, colored]);

  const currentValue = useMemo(() => rawMaterialAsOption(value, colored), [value, colored]);

  useEffect(() => {
    if (fetchDisabled) return;
    fetch((params) => params);
  }, [fetch, accountId, fetchDisabled]);

  return (
    <Fragment>
      <SelectInputLayout
        label="Matière première"
        value={currentValue}
        options={options}
        isSearchable
        onChange={onChange}
        inputCreatable
        createLabel="Créer une matière première"
        formatCreateLabel={formatCreateLabel}
        onCreateOption={handleCreateOption}
        optionEditable={hasPermission([permissions.RESOURCES_CONFIG, 'rawMaterial.update'])}
        onEditOptionClick={handleEditOption}
        {...props}
      />
      {rawMaterialModal && (
        <RawMaterialModal
          rawMaterial={rawMaterialModal}
          onRequestClose={() => setRawMaterialModal(null)}
          onSubmitSuccess={(response) => {
            const rawMaterial = jsonApiToObject(response);
            if (props.multi) {
              onChange([...currentValue, rawMaterial]);
            } else {
              onChange(rawMaterial);
            }
          }}
        />
      )}
    </Fragment>
  );
};

export const RawMaterialField = (props) => {
  return <SelectField component={RawMaterialSelect} {...props} />;
};

export default RawMaterialSelect;

import { sortBy } from 'lodash';
import { PmvApi, PmvAction, PmvReducer, PmvSelector } from './data';

export { PmvApi, PmvAction, PmvReducer, PmvSelector };

export const PMV_LINE_STYLES = [
  { value: 'blink', label: 'Clignotant', weight: 'regular' },
  { value: 'normal', label: 'Normal', weight: 'regular' },
  { value: 'bold', label: 'Gras', weight: 'bold' }
];

export const PMV_ERROR = {
  read_timeout: "Impossible d'établir la connexion.",
  write_text_ascii_only: 'Le message contient des caractères non-ASCII.'
};

export const PMV_STATE = {
  unreachable: {
    message: "Le panneau n'a pas l'air d'être connecté",
    label: 'Déconnecté',
    color: '#000'
  },
  reachable: {
    message: 'Le panneau est connecté',
    label: 'Connecté',
    color: '#66cc99'
  }
};

export const pmvAsValues = pmv => {
  return {
    id: pmv?.id,
    ip: pmv?.ip || '',
    address: pmv?.address || '',
    lines: sortBy(
      pmv?.lines?.map(({ style, text }, id) => ({
        id,
        style,
        text
      })) || [],
      'id'
    )
  };
};

export const pmvAsMarker = pmv => {
  if (!pmv || !pmv.lat || !pmv.lng) {
    return null;
  }
  const lat = parseFloat(pmv.lat);
  const lng = parseFloat(pmv.lng);
  if (!lat && !lng) {
    return null;
  }
  return {
    lat,
    lng,
    id: pmv.id,
    kind: 'pmv',
    item: pmv
  };
};

import React, { Fragment } from 'react';
import styled from 'styled-components';
import { v4 } from 'uuid';
import { MdAdd } from 'react-icons/md';
import { SectionTitle, Text, ButtonIcon } from 'components/v3/ui';
import { spacing } from 'ui';
import { DEVIATIONS_COLORS } from 'features/Projects/components/v3/Deviation/utils';
import EditorRouteRow from './EditorRouteRow';
import EditorSigns from './EditorSigns';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 8px;
  padding-right: 16px;
`;

const TitleAndAction = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${spacing()};
  margin-bottom: ${spacing(0.5)};
`;

const RoutesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 2px;
  margin-right: 2px;
  min-height: 80px;
  > * {
    margin-top: ${spacing(0.5)};
  }
`;

const PROJECT_ROUTE = {
  id: -1,
  displayName: 'Zone Chantier',
  color: '#ff0000'
};

const EditorPanel = ({
  project,
  selectedSign,
  onSignClick,

  onDeviationsChanged,

  activeRouteId,
  onRouteIdSelected,
  hoverRouteId,
  onHoverRouteId,

  readOnly
}) => {
  const deviations = project ? project.deviations || [] : [];
  return (
    <Wrapper>
      <TitleAndAction>
        <SectionTitle>Zone de chantier</SectionTitle>
      </TitleAndAction>
      <EditorRouteRow
        route={PROJECT_ROUTE}
        active={activeRouteId === -1}
        editable={false}
        onRouteIdSelected={onRouteIdSelected}
      />
      <TitleAndAction>
        <SectionTitle>Déviations</SectionTitle>
        {!readOnly && (
          <ButtonIcon
            onClick={() => {
              const id = v4();
              onDeviationsChanged([
                ...deviations,
                {
                  id,
                  kind: 'deviation',
                  isNew: true,
                  displayName: `${deviations.length + 1}`,
                  color: DEVIATIONS_COLORS[deviations.length],
                  points: [],
                  directions: []
                }
              ]);
              onRouteIdSelected(id);
            }}
          >
            <MdAdd />
          </ButtonIcon>
        )}
      </TitleAndAction>
      <RoutesWrapper>
        {deviations.length === 0 && (
          <Text variant="caption">
            Il n'y pas de deviation sur ce chantier...
          </Text>
        )}
        {deviations
          .filter(d => !d._destroy)
          .map((deviation, index) => {
            return (
              <EditorRouteRow
                key={deviation.id}
                route={{
                  ...deviation,
                  color: deviation.color || DEVIATIONS_COLORS[index]
                }}
                active={activeRouteId === deviation.id}
                onRouteIdSelected={onRouteIdSelected}
                onChange={deviation =>
                  onDeviationsChanged(
                    deviations.map(d => (d.id === deviation.id ? deviation : d))
                  )
                }
                onDelete={deviation => {
                  if (deviation.isNew) {
                    onDeviationsChanged(
                      deviations.filter(d => d.id !== deviation.id)
                    );
                  } else {
                    onDeviationsChanged(
                      deviations.map(d =>
                        d.id === deviation.id
                          ? {
                              ...d,
                              _destroy: true
                            }
                          : d
                      )
                    );
                  }
                }}
                readOnly={readOnly}
              />
            );
          })}
      </RoutesWrapper>
      {!readOnly && (
        <Fragment>
          <TitleAndAction>
            <SectionTitle>Panneaux</SectionTitle>
          </TitleAndAction>
          <Text variant="hint" color="hint">
            Cliquez sur un panneau pour l'ajouer au centre de la carte
          </Text>
          <EditorSigns
            isEditable={true}
            selectedSign={selectedSign}
            onSignClick={onSignClick}
          />
        </Fragment>
      )}
    </Wrapper>
  );
};

export default EditorPanel;

import { get, put, post, destroy } from 'common/api';

export const getPurveyors = (params) => get('/v5/purveyors', params);

export const getOrdersExportHistories = (params) => get('/v5/orders_export_histories', params);

export const createPurveyor = (purveyor, config) => post('/v5/purveyors', { purveyor }, config);

export const getPurveyor = (id, config) => get(`/v5/purveyors/${id}`, config);

export const putPurveyor = (id, purveyor, config) => put(`/v5/purveyors/${id}`, { purveyor }, config);

export const deletePurveyor = (id, config) => destroy(`/v5/purveyors/${id}`, config);

export const getRawMaterials = (params) => get('/v5/raw_materials?sort=position', params);

export const createRawMaterial = (rawMaterial, config) => post('/v5/raw_materials', { rawMaterial }, config);

export const putRawMaterial = (id, rawMaterial, config) => put(`/v5/raw_materials/${id}`, { rawMaterial }, config);

export const reorderRawMaterial = (id, rawMaterial, config) =>
  put(`/v5/raw_materials/${id}/reorder`, { rawMaterial }, config);

export const deleteRawMaterial = (id, config) => destroy(`/v5/raw_materials/${id}`, config);

export const getProducts = (params) => get('/v5/products?sort=display_name', params);

export const createProduct = (product, config) => post('/v5/products', { product }, config);

export const getProduct = (id, config) => get(`/v5/products/${id}`, config);

export const putProduct = (id, product, config) => put(`/v5/products/${id}`, { product }, config);

export const deleteProduct = (id, config) => destroy(`/v5/products/${id}`, config);

export const getOrders = (params) => get('/v5/orders?per_page=500', params);

export const createOrder = (order, config) => post('/v5/orders', { order }, config);

export const getOrder = (id, config) => get(`/v5/orders/${id}`, config);

export const putOrder = (id, order, config) => put(`/v5/orders/${id}`, { order }, config);

export const orderToPdf = (id, order, config) => post(`/v5/orders/${id}/to_pdf`, { order }, config);

export const getCoatingStats = (params) => get('/v5/orders/stats', params);

export const getMaterialOrders = (params) => get('/v5/raw_materials/orders', params);

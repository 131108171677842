import { get, put, post, destroy } from 'common/api';

export const getInputMaterials = params =>
  get('/v5/input_materials', {
    ...(params || {}),
    sort: (params || {}).sort ? params.sort : 'name'
  });

export const createInputMaterial = (inputMaterial, config) =>
  post('/v5/input_materials', { inputMaterial }, config);

export const getInputMaterial = (id, config) =>
  get(`/v5/input_materials/${id}`, config);

export const putInputMaterial = (id, inputMaterial, config) =>
  put(`/v5/input_materials/${id}`, { inputMaterial }, config);

export const deleteInputMaterial = (id, config) =>
  destroy(`/v5/input_materials/${id}`, config);

import React, { Fragment } from 'react';
import { withRouter } from 'react-router';
import { Switch, Route } from 'react-router-dom';
import Profile from './Profile';
import Logout from './Logout';
import Box from 'components/v3/Box';
import { Container } from 'components/v3/ui';

export default withRouter(props => {
  const { match } = props;
  const { path } = match;

  return (
    <Fragment>
      <Container narrow>
        <Box padded marged>
          <Switch>
            <Route path={`${path}/deconnexion`} component={Logout} />
            <Route path={`${path}/profil`} component={Profile} />
          </Switch>
        </Box>
      </Container>
    </Fragment>
  );
});

import { omit } from 'lodash';
import { mergeJsonApi } from 'common/reducers';

const initialState = {
  groupById: {},
  groupsIds: []
};

const groups = (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case 'NEW_GROUP_SUCCESS':
      return {
        ...mergeJsonApi('group', state, action),
        groupsIds: [payload.data.id, ...state.groupsIds]
      };
    case 'DELETE_GROUP_SUCCESS':
      return {
        ...state,
        groupById: omit(state.groupById, payload),
        groupsIds: state.groupsIds.filter(id => parseInt(id) !== payload)
      };
    case 'LOGOUT':
    case 'SELECT_ACCOUNT':
      return initialState;
    default:
      return mergeJsonApi('group', state, action);
  }
};

export default groups;

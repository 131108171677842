import { mergeJsonApi } from 'common/reducers';

const initialState = {
  activityById: {},
  activitiesIds: []
};

const activities = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case 'NEW_ACTIVITY_SUCCESS':
      return {
        ...mergeJsonApi('activity', state, action),
        activitiesIds: [payload.data.id, ...state.activitiesIds]
      };
    case 'LOGOUT':
    case 'SELECT_ACCOUNT':
      return initialState;
    default:
      return mergeJsonApi('activity', state, action);
  }
};

export default activities;

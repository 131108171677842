import styled, { css } from 'styled-components';
import { spacing } from 'ui';
import { Wrapper as BoxHeader } from './BoxHeader';

export const Box = styled.div`
  background-color: #fff;
  position: relative;
  border-radius: ${({ theme }) => theme.borderBoxRadius};
  box-shadow: 0 3px 16px rgba(0, 0, 0, 0.08);
  border: ${({ theme }) => theme.borderInput};
  overflow: hidden;

  ${({ padded }) =>
    padded &&
    css`
      padding: ${spacing()};
    `}

  ${({ marged }) =>
    marged &&
    css`
      margin: ${spacing()};
    `}

  ${({ flat }) =>
    flat &&
    css`
      box-shadow: none;
      border: none;
      border-radius: 0px;

      ${BoxHeader} {
        padding: 0;
      }
    `}
`;

export default Box;

import React, { useCallback, useRef, useEffect } from 'react';
import useMountedRef from './useMountedRef';
import axios, { CancelTokenSource } from 'axios';

type Action<T> = ((props?: any) => Promise<T>) | Promise<T>;
type SuccessCallback = (props?: any) => void;
type ErrorCallback = (error?: Error) => void;
type VoidCallback = () => void;

export class ActionBuilder<T> {
  private readonly _action: Action<T>;
  private readonly _mountedRef: React.MutableRefObject<boolean>;
  private readonly _actionsRef: React.MutableRefObject<any>;
  private _name?: string;
  private _onSuccess: Array<SuccessCallback> = [];
  private _onError: Array<ErrorCallback> = [];
  private _onBegin: Array<VoidCallback> = [];
  private _onEnd: Array<VoidCallback> = [];
  private _running: boolean = false;
  private _canceled: boolean = false;
  private _cancelToken?: CancelTokenSource;

  constructor(
    action: Action<T>,
    mountedRef: React.MutableRefObject<boolean>,
    actionsRef: React.MutableRefObject<any>,
    name?: string
  ) {
    this._action = action;
    this._mountedRef = mountedRef;
    this._name = name;
    this._actionsRef = actionsRef;
  }

  onSuccess(onSuccess: SuccessCallback) {
    this._onSuccess.push(onSuccess);
    return this;
  }

  onError(onError: ErrorCallback) {
    this._onError.push(onError);
    return this;
  }

  onBegin(onBegin: VoidCallback) {
    this._onBegin.push(onBegin);
    return this;
  }

  onEnd(onEnd: VoidCallback) {
    this._onEnd.push(onEnd);
    return this;
  }

  async run(forceRun = false) {
    if (this._running && !forceRun) {
      return;
    }
    this._running = true;
    try {
      this._cancelToken = axios.CancelToken.source();
      if (!this._canceled && this._mountedRef.current) {
        this._onBegin.forEach(f => f());
      }
      const result = await (this._action instanceof Function
        ? this._action(this._cancelToken.token)
        : this._action);

      if (!this._canceled && this._mountedRef.current) {
        this._onSuccess.forEach(f => f(result));
      }
    } catch (e) {
      const axiosCanceled = axios.isCancel(e);
      if (!axiosCanceled) {
        console.error(e);
      }
      if (!this._canceled && this._mountedRef.current && !axiosCanceled) {
        this._onError.forEach(f => f(e));
      }
    } finally {
      if (!this._canceled && this._mountedRef.current) {
        this._onEnd.forEach(f => f());
      }
    }
    if (this._name) {
      this._actionsRef.current[this._name] = undefined;
    }
  }

  cancel() {
    this._canceled = true;
    this._running = false;
    if (this._cancelToken) {
      this._cancelToken.cancel('new request appeared');
    }
  }
}

interface ActionBuilderMap {
  [index: string]: ActionBuilder<any>;
}

const useAction = () => {
  const mountedRef = useMountedRef();
  const actions = useRef<ActionBuilderMap>({});

  useEffect(() => {
    const ref = actions.current;
    return () => {
      Object.values(ref)
        .filter(Boolean)
        .forEach(ab => ab.cancel());
    };
  }, []);

  const runAction = useCallback(
    (action: Action<any>, name?: string, cancelPrevious?: boolean) => {
      const payload = name || 'request';
      if (cancelPrevious) {
        actions.current[payload]?.cancel();
      }
      const builder = new ActionBuilder(action, mountedRef, actions, payload);
      if (cancelPrevious) {
        actions.current[payload] = builder;
      }
      setTimeout(() => {
        builder.run(cancelPrevious);
      }, 0);
      return builder;
    },
    [mountedRef]
  );
  return runAction;
};

export default useAction;

import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { MdUnfoldMore } from 'react-icons/md';
import { sortBy } from 'lodash';
import { AccountAction, AccountSelector } from 'features/Accounts';
import { capitalize } from 'utils';
import { Text, Icon } from './v3/ui';
import Menu, { MenuItem } from './v3/Menu';
import isSameIds from 'utils/idUtils';

const Trigger = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: center;
  outline: none;
  cursor: pointer;
  max-width: 175px;
  padding-right: 32px;
  padding-left: 8px;
  overflow: hidden;

  &:hover:not([readOnly]) {
    background-color: ${({ theme }) => theme.hoverColorBlack};
  }

  &[readOnly] {
    cursor: default;
  }
`;

const Option = styled.div`
  display: flex;
  flex-direction: column;
`;

const TriggerIconWrapper = styled(Icon)`
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  > svg {
    fill: #fff;
  }
`;

const AccountSwitcher = ({ currentAccount, accounts, ...props }) => {
  const { selectAccount } = props;
  const hasManyAccounts = accounts.length > 0;
  const menuItems = useMemo(() => {
    return sortBy(
      accounts.map(account => ({
        ...account,
        label: account.displayName || account.subdomain
      })),
      'label'
    ).map(account => {
      const isActive = isSameIds(currentAccount?.id, account.id);
      return (
        <MenuItem
          key={account.label}
          onClick={() => !isActive && selectAccount(account)}
          active={isActive}
        >
          <Option>
            <Text>{account.displayName}</Text>
            <Text variant="caption" color="caption">
              {account.subdomain}
            </Text>
          </Option>
        </MenuItem>
      );
    });
  }, [currentAccount, accounts, selectAccount]);

  return (
    <Menu maxWidth={400}>
      <Trigger readOnly={!hasManyAccounts}>
        <Text ellipsis color="light">
          {currentAccount?.displayName || capitalize(currentAccount?.subdomain)}
        </Text>
        <Text variant="caption" color="caption">
          Compte
        </Text>
        {hasManyAccounts && (
          <TriggerIconWrapper size="small">
            <MdUnfoldMore />
          </TriggerIconWrapper>
        )}
      </Trigger>
      {menuItems}
    </Menu>
  );
};

const mapStateToProps = state => ({
  accounts: AccountSelector.selectAccounts(state),
  currentAccount: AccountSelector.selectCurrentAccount(state)
});

const mapDispatchToProps = dispatch => ({
  selectAccount: account => dispatch(AccountAction.selectAccount(account?.id))
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountSwitcher);

import React, { useState, useMemo } from 'react';
import styled, { css } from 'styled-components';
import ReactModal from 'react-modal';
import Spinner from 'react-md-spinner';
import { spacing, theme } from 'ui';
import { MdClose, MdFullscreen, MdFullscreenExit } from 'react-icons/md';
import { ButtonIcon, Title, Subtitle, Icon } from '../ui';
import Card, { CardHeader, CardFooter } from '../ui/card';
import Tooltip from 'components/v3/Tooltip';

ReactModal.setAppElement('#app');

export type ModalSize = 'full' | 'xlarge' | 'large' | 'normal' | 'small' | 'tight';

const modalSizeValue = (size: ModalSize | undefined) => {
  switch (size) {
    case 'full':
      return { maxWidth: 'calc(100% - 32px)', height: '100%' };
    case 'xlarge':
      return { maxWidth: 1600 };
    case 'large':
      return { maxWidth: 1280 };
    case 'small':
      return { maxWidth: 540 };
    case 'tight':
      return { maxWidth: 300 };
    default:
      return { maxWidth: 832 };
  }
};

export const customStyles: ReactModal.Styles = {
  overlay: {
    zIndex: 999,
    backgroundColor: 'rgba(0, 0, 0, 0.6)'
  },
  content: {
    top: '30%',
    left: '50%',
    right: undefined,
    bottom: undefined,
    transform: 'translateX(-50%) translateY(-30%)',
    maxWidth: 832,
    width: 'calc(100% - 32px)',
    maxHeight: 'calc(100vh - 32px)',
    border: undefined,
    overflowY: 'auto',
    boxShadow: theme.boxShadow,
    transition: '500ms ease',
    background: undefined,
    padding: 0,
    display: 'flex',
    zIndex: 999
  }
};

export const CloseModalTriggerButton = styled(ButtonIcon)<{
  children: any;
  onClick: any;
}>`
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 2;
  cursor: pointer;
`;

export const ModalSpinnerWrapper = styled.div`
  position: absolute;
  top: 13px;
  right: 13px;
  z-index: 2;
  cursor: pointer;
`;

export const ExpandButton = styled(ButtonIcon)`
  position: absolute;
  top: 8px;
  right: 40px;
  z-index: 2;
  cursor: pointer;
`;

export const Content = styled.div`
  height: calc(100% - 66px);
  overflow: overlay;
  position: relative;
  padding: ${spacing(1.5)};

  img {
    display: block;
    max-width: 100%;
  }
`;

export const HeaderWrapper = styled(CardHeader)<{ separatorVisible: boolean }>`
  justify-content: space-between;
  display: flex;
  padding-bottom: 0px;
  padding-left: ${spacing(1.5)};
  padding-right: ${spacing(1.5)};
  padding-top: ${spacing(1.5)};
  transition: box-shadow 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 1;

  ${({ separatorVisible }) =>
    css`
      box-shadow: ${separatorVisible ? ({ theme }) => theme.boxShadowSmall : 'none'};
    `}
`;

export interface HeaderProps {
  title?: string | React.ReactNode;
  subtitle?: string;
  rightContent?: React.ReactNode;
  separatorVisible?: boolean;
  children?: React.ReactNode;
}

export const Header: React.FunctionComponent<HeaderProps> = ({
  title,
  subtitle,
  rightContent,
  separatorVisible,
  children
}) => {
  return (
    <HeaderWrapper separatorVisible={Boolean(title || subtitle) && separatorVisible}>
      {(title || subtitle || rightContent) && (
        <div>
          {title && <Title>{title}</Title>}
          {subtitle && <Subtitle>{subtitle}</Subtitle>}
        </div>
      )}
      {rightContent && <div>{rightContent}</div>}
      {children}
    </HeaderWrapper>
  );
};

export const Footer = styled(CardFooter)<{ separatorVisible?: boolean }>`
  transition: box-shadow 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 1;
  padding-bottom: ${spacing()};

  ${({ separatorVisible }) =>
    css`
      box-shadow: ${separatorVisible ? ({ theme }) => theme.boxShadowSmall : 'none'};
    `}
`;

export interface ModalChildProps {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}
export interface ModalBaseProps extends ReactModal.Props {
  isOpen: boolean;
  onRequestClose: (props?: any) => void;
  isLoading?: boolean;
  maxWidth?: number;
  size?: ModalSize;
  expandable?: boolean;
  expanded?: boolean;
}
export interface ModalProps extends ModalBaseProps {
  children: React.ReactNode | ((props: ModalChildProps) => React.ReactNode);
}

const RawModal: React.FunctionComponent<ModalProps> = ({
  isOpen,
  onRequestClose,
  size: propsSize,
  isLoading: isLoadingProps,
  children,
  style,
  expandable,
  expanded,
  ...props
}) => {
  const [isLoading, setLoading] = useState(false);
  const [size, setSize] = useState(propsSize);

  const handleClose = (e: any) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    onRequestClose();
  };

  const memoStyles = useMemo(() => {
    return {
      ...customStyles,
      content: {
        ...customStyles.content,
        ...modalSizeValue(expanded ? 'full' : size)
      },
      ...(style || {})
    };
  }, [expanded, size, style]);

  return (
    <ReactModal isOpen={isOpen} onRequestClose={handleClose} style={memoStyles} {...props}>
      <Card>
        {expandable && (
          <ExpandButton
            onClick={() =>
              setSize((size) => {
                if (size === 'full') {
                  return propsSize;
                } else {
                  return 'full';
                }
              })
            }
          >
            {size === propsSize && (
              <Tooltip content="Passer en plein écran">
                <Icon>
                  <MdFullscreen />
                </Icon>
              </Tooltip>
            )}
            {size === 'full' && (
              <Tooltip content="Quitter le plein écran">
                <Icon>
                  <MdFullscreenExit />
                </Icon>
              </Tooltip>
            )}
          </ExpandButton>
        )}
        {(isLoading || isLoadingProps) && (
          <ModalSpinnerWrapper>
            <Spinner size={24} singleColor={theme.accent} />
          </ModalSpinnerWrapper>
        )}
        {Boolean(onRequestClose) && !(isLoading || isLoadingProps) && (
          <CloseModalTriggerButton onClick={onRequestClose}>
            <MdClose />
          </CloseModalTriggerButton>
        )}
        {children instanceof Function ? children({ setLoading }) : children}
      </Card>
    </ReactModal>
  );
};

RawModal.defaultProps = {
  size: 'normal'
};

export default RawModal;

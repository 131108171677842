import { useEffect, useRef, useCallback } from 'react';

interface DelayMap {
  [index: string]: number;
}

const useDelay = () => {
  const handlerRef = useRef<DelayMap>({});

  useEffect(() => {
    let ref = handlerRef;
    return () => Object.values(ref.current).forEach(h => clearTimeout(h));
  }, []);

  const delay = useCallback(
    (
      runnable: () => void,
      delay: number,
      payload?: string,
      async: boolean = false
    ) => {
      const name = payload || 'delay';
      if (handlerRef.current[name] !== undefined) {
        window.clearTimeout(handlerRef.current[name]);
      }
      if (async) {
        return new Promise(resolve => {
          handlerRef.current[name] = window.setTimeout(resolve, delay);
        }).then(runnable);
      } else {
        const handler = window.setTimeout(runnable, delay);
        handlerRef.current[name] = handler;
        return handler;
      }
    },
    []
  );

  (delay as any).cancel = (payload?: string) => {
    window.clearTimeout(handlerRef.current[payload || 'delay']);
  };
  return delay;
};

export default useDelay;

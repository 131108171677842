import React, {
  useRef,
  useMemo,
  useEffect,
  useState,
  useCallback
} from 'react';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';
import Uppy from '@uppy/core';
// import Tus from '@uppy/tus';
import XHRUpload from '@uppy/xhr-upload';
import { DragDrop } from '@uppy/react';
import '@uppy/core/dist/style.css';
import '@uppy/drag-drop/dist/style.css';
import { MdAdd, MdFileDownload } from 'react-icons/md';
import omit from 'lodash/omit';
import uniqBy from 'lodash/uniqBy';
import RawBox, { BoxHeader } from 'components/v3/Box';
import { Wrapper as BoxHeaderWrapper } from 'components/v3/Box/BoxHeader';
import { toaster } from 'components/v3/Toast';
import { useDeepEffect, useDeepMemo } from 'components/v3/utils';
import { Button, ButtonIcon, ExternalLink } from 'components/v3/ui';
import AttachmentList from './List';
import { jsonApiToObject } from 'common/api';
import { AttachmentAction } from 'features/Attachments/data';
import { authenticateUrl } from 'utils';
import { API_BASE } from '../../../../constants';
import Tooltip from 'components/v3/Tooltip';
import isSameIds from 'utils/idUtils';

const ListWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const Box = styled(RawBox)`
  ${({ flat }) =>
    flat &&
    css`
      box-shadow: none;
      border: none;
      border-radius: 0px;

      ${BoxHeaderWrapper} {
        padding: 0;
      }

      ${ListWrapper} {
        border: ${({ theme }) => theme.borderInput};
        border-radius: ${({ theme }) => theme.borderInputRadius};
        margin-top: 4px;
      }

      * > .uppy-DragDrop-container {
        border-radius: ${({ theme }) => theme.borderInputRadius} !important;
      }
    `}
`;

const UppyWrapper = styled.div`
  position: absolute;
  top: 0px;
  right: 1px;
  bottom: 1px;
  left: 1px;
  display: none;

  ${({ dragging }) =>
    dragging &&
    css`
      display: block;
      z-index: 1;
    `}

  > div {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  * > .uppy-DragDrop-container {
    background: transparent;
    border-radius: ${({ theme }) => theme.borderBoxRadius};
    border-color: ${({ theme }) => theme.borderInputColor};

    > .uppy-DragDrop-inner {
      padding: 0px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: none;
      align-items: center;
      justify-content: center;
      svg {
        fill: ${({ theme }) => theme.primaryLight};
      }
      > .uppy-DragDrop-label {
        display: none;
      }
    }
  }

  * > .uppy-DragDrop--isDraggingOver {
    border-color: ${({ theme }) => theme.primaryLight};
    > .uppy-DragDrop-inner {
      display: flex;
    }
  }

  * > .uppy-DragDrop-container {
    background: transparent;
  }

  * > .uppy-DragDrop--isDraggingOver {
    > .uppy-DragDrop-inner {
      background-color: rgba(255, 255, 255, 0.56);
    }
  }
`;

const RightButtons = styled.div`
  display: flex;
  > :not(:last-child) {
    margin-right: 8px;
  }
`;

const isDeletable = attachment => !attachment.isLocal;

const AttachmentBox = ({
  attachments: propsAttachments,
  marged,
  padded,
  creatable = true,
  title,

  onFileAdded,
  onUpload,
  onFileProgress,
  onFileSuccess,
  onFileError,
  onFileRemoved,
  onUploadComplete,
  uppyOptions = {},
  flat = false,
  endpoint = null,
  parentModel,
  parentId,
  readOnly,
  uppyRef: uppyRefProps,
  ...props
}) => {
  const dispatch = useDispatch();
  const [uppy, setUppy] = useState();
  const [draggingDocument, setDragging] = useState(false);
  const [remoteAttachments, setRemoteAttachments] = useState([]);
  const [uploadingStates, setUploadingStates] = useState({});
  const [uploadingIds, setUploadingIds] = useState([]);
  const dragAndDropRef = useRef();
  const uppyRef = useRef();
  uppyRef.current = uppy;
  if (uppyRefProps) {
    uppyRefProps.current = uppy;
  }
  const onFileAddedRef = useRef();
  onFileAddedRef.current = onFileAdded;
  const onUploadRef = useRef();
  onUploadRef.current = onUpload;
  const onFileProgressRef = useRef();
  onFileProgressRef.current = onFileProgress;
  const onFileSuccessRef = useRef();
  onFileSuccessRef.current = onFileSuccess;
  const onFileErrorRef = useRef();
  onFileErrorRef.current = onFileError;
  const onFileRemovedRef = useRef();
  onFileRemovedRef.current = onFileError;
  const onUploadCompleteRef = useRef();
  onUploadCompleteRef.current = onUploadComplete;

  const handleDragEnter = useCallback(() => {
    setDragging(true);
  }, []);

  const handleDragLeave = useCallback(e => {
    if (!e.fromElement) {
      setDragging(false);
    }
  }, []);

  useDeepEffect(() => {
    setRemoteAttachments(propsAttachments);
  }, [propsAttachments]);

  const attachments = useMemo(() => {
    return [
      ...remoteAttachments,
      ...Object.values(uploadingStates).map(s => s.file)
    ];
  }, [remoteAttachments, uploadingStates]);

  const isItemLoading = useCallback(
    attachment => uploadingIds.find(id => isSameIds(id, attachment.id)),
    [uploadingIds]
  );

  const handleFileAdded = useCallback(file => {
    setUploadingStates(states => ({
      ...states,
      [file.id]: {
        file,
        progress: 0
      }
    }));
  }, []);

  const handleFileProgress = useCallback(
    (file, progress) =>
      setUploadingStates(states => ({
        ...states,
        [file.id]: {
          ...states[file.id],
          progress
        }
      })),
    []
  );

  const handleFileSuccess = useCallback((file, attachment) => {
    setUploadingStates(states => omit(states, file.id));
    setRemoteAttachments(attachments =>
      uniqBy([...attachments, attachment], 'id')
    );
  }, []);

  const deleteRequest = useCallback(
    ({ id }) =>
      dispatch(AttachmentAction.deleteAttachment({ id })).then(() => {
        if (onFileRemovedRef.current) {
          onFileRemovedRef.current(id);
        }
      }),
    [dispatch]
  );

  const memoOpts = useDeepMemo(() => uppyOptions, [uppyOptions]);

  useEffect(() => {
    window.addEventListener('dragenter', handleDragEnter);
    window.addEventListener('dragleave', handleDragLeave);
    window.addEventListener('dragend', handleDragLeave);
    window.addEventListener('drop', handleDragLeave);
    window.addEventListener('dragexit', handleDragLeave);
    const uppy = Uppy({
      bundle: true,
      allowMultipleUploads: true,
      // onBeforeFileAdded: (file, files) => {
      //   console.log(file, files);
      //   return true;
      // },
      restrictions: {
        allowedFileTypes: null
      },
      autoProceed: true,
      ...memoOpts
    })
      .on('file-added', file => {
        const data = file.data;
        try {
          const localUrl = URL.createObjectURL(data);
          Object.assign(data, {
            id: file.id,
            preview: localUrl,
            isLocal: true
          });
          handleFileAdded(data);
          if (onFileAddedRef.current) {
            onFileAddedRef.current(data);
          }
        } catch (e) {
          console.error(e);
        }
      })
      .on('upload', data => {
        setUploadingIds([...data.fileIDs]);
        setDragging(false);
        if (onUploadRef.current) {
          onUploadRef.current(data);
        }
      })
      .on('upload-progress', (file, progress) => {
        const percentage = progress.bytesUploaded / (progress.bytesTotal || 1);
        handleFileProgress(file, progress);
        if (onFileProgressRef.current) {
          onFileProgressRef.current(file, percentage);
        }
      })
      .on('upload-success', (file, response) => {
        const attachment = jsonApiToObject(response.body);
        if (attachment && attachment.id) {
          handleFileSuccess(file, attachment);
          if (onFileSuccessRef.current) {
            onFileSuccessRef.current(file, attachment, response.body);
          }
        }
      })
      .on('upload-error', (file, error) => {
        if (onFileErrorRef.current) {
          onFileErrorRef.current(file, error);
        } else {
          if (error?.request?.status === 422) {
            toaster.error("Le document n'est pas valide.");
          } else {
            toaster.error(error);
          }
        }
        uppy.removeFile(file.id);
        setUploadingStates(states => omit(states, file.id));
      })
      .on('complete', result => {
        if (onUploadCompleteRef.current) {
          onUploadCompleteRef.current(result);
        }
      });

    if (endpoint) {
      uppy.use(XHRUpload, { endpoint, fieldName: 'attachment[source]' });
    } else if (parentId && parentModel) {
      uppy.use(XHRUpload, {
        endpoint: authenticateUrl(
          `${API_BASE}/v5/attachments?parent_id=${parentId}&parent_model=${parentModel}`
        ),
        fieldName: 'attachment[source]'
      });
    }
    setUppy(uppy);
    return () => {
      uppyRef.current.close();
      window.removeEventListener('dragenter', handleDragEnter);
      window.removeEventListener('dragleave', handleDragLeave);
      window.removeEventListener('dragend', handleDragLeave);
      window.removeEventListener('drop', handleDragLeave);
      window.removeEventListener('dragexit', handleDragLeave);
    };
  }, [
    handleDragEnter,
    handleDragLeave,
    endpoint,
    parentId,
    parentModel,
    handleFileAdded,
    handleFileProgress,
    handleFileSuccess,
    memoOpts
  ]);

  return (
    <Box marged={marged} padded={padded} flat={flat}>
      <BoxHeader
        bigPadded
        title={
          title ||
          `Documents (${(attachments || []).filter(a => !a._destroy).length})`
        }
        subtitle={
          readOnly
            ? null
            : 'Vous pouvez glisser un document directement dans la liste.'
        }
      >
        <RightButtons>
          {attachments?.filter(a => !a.isLocal)?.length > 0 && (
            <ExternalLink
              target=""
              to={authenticateUrl(
                `${API_BASE}/v5/attachments/zip?parent_id=${parentId}&parent_model=${parentModel}`
              )}
            >
              <Tooltip content="Télécharger tous les documents.">
                <ButtonIcon>
                  <MdFileDownload />
                </ButtonIcon>
              </Tooltip>
            </ExternalLink>
          )}
          {creatable && !readOnly && (
            <Button
              small
              icon={MdAdd}
              onClick={() => dragAndDropRef.current.plugin.fileInputRef.click()}
            >
              Ajouter
            </Button>
          )}
        </RightButtons>
      </BoxHeader>
      <ListWrapper>
        {!readOnly && uppy && (
          <UppyWrapper dragging={draggingDocument}>
            <DragDrop
              ref={dragAndDropRef}
              uppy={uppy}
              target="body"
              locale={null}
            />
          </UppyWrapper>
        )}
        <AttachmentList
          rowColor="transparent"
          headerVariant="transparent"
          headerHidden
          useWindow={false}
          isItemLoading={isItemLoading}
          attachments={attachments}
          deleteRequest={deleteRequest}
          isDeletable={isDeletable}
          {...props}
        />
      </ListWrapper>
    </Box>
  );
};

export default AttachmentBox;

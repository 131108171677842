import { get, put, post, destroy } from 'common/api';

export const getExtractionMaterials = params =>
  get('/v5/extraction_materials', {
    ...(params || {}),
    sort: (params || {}).sort ? params.sort : 'name'
  });

export const createExtractionMaterial = (extractionMaterial, config) =>
  post('/v5/extraction_materials', { extractionMaterial }, config);

export const getExtractionMaterial = (id, config) =>
  get(`/v5/extraction_materials/${id}`, config);

export const putExtractionMaterial = (id, extractionMaterial, config) =>
  put(`/v5/extraction_materials/${id}`, { extractionMaterial }, config);

export const deleteExtractionMaterial = (id, config) =>
  destroy(`/v5/extraction_materials/${id}`, config);

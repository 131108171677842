import React, {
  useCallback,
  useMemo,
  Fragment,
  useState,
  useEffect
} from 'react';
import { SelectField } from 'components/v3/Form';
import { SelectInputLayout } from 'components/v3/InputLayout';
import { useRedux } from 'components/v3/utils';
import { jsonApiToObject } from 'common/api';
import ProjectModal from './Modal';
import {
  ProjectSelector,
  ProjectAction,
  projectAsOption
} from 'features/Projects';
import { useAccountConfig } from 'config';

const formatCreateLabel = input => `Créer le projet ${input}`;

const projectOptionsFormatter = response => {
  return (jsonApiToObject(response) || []).map(projectAsOption);
};

const ProjectSelect = ({
  value,
  onChange,
  role,
  avatarHidden = false,
  ...props
}) => {
  const { accountId, hasPermission } = useAccountConfig();
  const [projectModal, setProjectModal] = useState();
  const selectSearchProjects = useMemo(
    () => state => ProjectSelector.selectSearchProjects(state, { role }),
    [role]
  );

  const [projects, fetch, state] = useRedux(
    selectSearchProjects,
    ProjectAction.getProjects,
    {
      fetchOnMount: false,
      useLocalState: true,
      initialParams: {
        page: 1,
        perPage: 50,
        sort: '-updated_at',
        useCache: true,
        isSearchRequest: true,
        stateNotEqOrNull: 'archived'
      }
    }
  );

  const handleCreateOption = useCallback(input => {
    setProjectModal({
      displayName: input
    });
  }, []);

  const handleEditOption = useCallback(value => {
    setProjectModal(value);
  }, []);

  const handleSearch = useCallback(
    ({ query }) =>
      new Promise((resolve, reject) => {
        fetch(params => ({
          ...params,
          byQuery: query,
          page: 1,
          useCache: false
        }))
          .onError(reject)
          .onSuccess(res => resolve(projectOptionsFormatter(res)));
      }),
    [fetch]
  );

  const options = useMemo(() => projectAsOption(projects), [projects]);

  const currentValue = useMemo(
    () => projectAsOption(value, avatarHidden),
    [value, avatarHidden]
  );

  useEffect(() => {
    fetch(params => ({
      ...params,
      byQuery: undefined,
      page: 1,
      useCache: true
    }));
  }, [fetch, accountId]);

  return (
    <Fragment>
      <SelectInputLayout
        label="Projet"
        value={currentValue}
        options={options}
        isLoading={state.isLoading}
        isSearchable
        loadAsyncOptions={handleSearch}
        filterOptions={false}
        onChange={onChange}
        inputCreatable={hasPermission('project.create')}
        createLabel="Créer un nouveau projet"
        formatCreateLabel={formatCreateLabel}
        onCreateOption={handleCreateOption}
        sortOptions={false}
        optionEditable={hasPermission('project.update', {
          target: currentValue
        })}
        onEditOptionClick={handleEditOption}
        {...props}
      />
      {projectModal && (
        <ProjectModal
          project={projectModal}
          onRequestClose={() => setProjectModal(null)}
          onSubmitSuccess={response => {
            const project = jsonApiToObject(response);
            if (props.multi) {
              onChange([...currentValue, project]);
            } else {
              onChange(project);
            }
          }}
        />
      )}
    </Fragment>
  );
};

export const ProjectField = props => {
  return <SelectField component={ProjectSelect} {...props} />;
};

export default ProjectSelect;

export { TeamApi, TeamAction, TeamReducer, TeamSelector } from './data';

export const teamAsOption = team => {
  if (Array.isArray(team)) {
    return team.map(teamAsOption);
  } else if (team) {
    return {
      value: team.id,
      color: team.color,
      label: team.displayName,
      original: team
    };
  } else {
    return null;
  }
};

export const teamAsValues = team => ({
  id: team?.id || null,
  displayName: team?.displayName || '',
  color: team?.color || '',
  phone: team?.phone || '',
  users: team?.users || []
});

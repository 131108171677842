import { createAction } from 'redux-actions';
import * as Api from './api';

const receivePeriods = createAction('EXCEPTIONAL_PERIODS_SUCCESS');
export const getPeriods = payload => async dispatch => {
  const result = await Api.getPeriods(payload);
  dispatch(receivePeriods(result));
  return result;
};

const receiveCreatePeriod = createAction('NEW_PERIOD_SUCCESS');
export const createPeriod = ({ payload, ...config }) => async dispatch => {
  const result = await Api.createPeriod(payload, config);
  dispatch(receiveCreatePeriod(result));
  return result;
};

const receivePeriod = createAction('EXCEPTIONAL_PERIOD_SUCCESS');
export const getPeriod = ({ id, ...payload }) => async dispatch => {
  const result = await Api.getPeriod(id, payload);
  dispatch(receivePeriod(result));
  return result;
};

const receiveUpdatePeriod = createAction('UPDATE_PERIOD_SUCCESS');
export const updatePeriod = ({
  id,
  payload: period,
  ...config
}) => async dispatch => {
  const result = await Api.putPeriod(id, period, config);
  dispatch(receiveUpdatePeriod(result));
  return result;
};

const receiveDeletePeriod = createAction('DELETE_PERIOD_SUCCESS');
export const deletePeriod = ({ id, ...config }) => async dispatch => {
  const response = await Api.deletePeriod(id, config);
  dispatch(receiveDeletePeriod(id));
  return response;
};

import { v4 } from 'uuid';
import chunk from 'lodash/chunk';
import flatten from 'lodash/flatten';
import roadworks from 'common/images/roadworks.png';
import closed_road from 'common/images/closed_road.png';
import deviation_left from 'common/images/deviation_left.png';
import deviation_right from 'common/images/deviation_right.png';
import prohibited from 'common/images/prohibited.png';
import prohibited_left_turn from 'common/images/prohibited_left_turn.png';
import prohibited_right_turn from 'common/images/prohibited_right_turn.png';
import { convertHex, calculateAspectRatioFit } from 'utils';

const staticSigns = [
  { kind: 'deviation_sign', src: roadworks, name: 'roadworks' },
  { kind: 'deviation_sign', src: closed_road, name: 'closed_road' },
  { kind: 'deviation_sign', src: deviation_left, name: 'deviation_left' },
  { kind: 'deviation_sign', src: deviation_right, name: 'deviation_right' },
  { kind: 'deviation_sign', src: prohibited, name: 'prohibited' },
  {
    kind: 'deviation_sign',
    src: prohibited_left_turn,
    name: 'prohibited_left_turn'
  },
  {
    kind: 'deviation_sign',
    src: prohibited_right_turn,
    name: 'prohibited_right_turn'
  }
];

export const SIGN_SIZE = 56;

export const DEVIATIONS_COLORS = [
  '#639833',
  '#2F3F73',
  '#882D60',
  '#AA9F39',
  '#236A62',
  '#4C2D73',
  '#AA3939'
];

export const signAsMarker = sign => {
  if (sign.kind === 'custom' || sign.kind === 'sign_road') {
    const { width, height } = calculateAspectRatioFit(
      (sign.attachment && sign.attachment.width) || SIGN_SIZE,
      (sign.attachment && sign.attachment.height) || SIGN_SIZE,
      SIGN_SIZE,
      SIGN_SIZE
    );
    return {
      id: sign.id,
      lat: sign.lat,
      lng: sign.lng,
      kind: sign.kind,
      draggable: false,
      visible: true,
      item: {
        ...sign,
        width,
        height
      }
    };
  } else {
    const staticSign = staticSigns.find(
      s => s.name === sign.kind || s.name === sign.name
    );
    if (!staticSign) {
      return null;
    }
    return {
      id: sign.id,
      isNew: sign.isNew,
      lat: sign.lat,
      lng: sign.lng,
      kind: 'deviation_sign',
      draggable: false,
      visible: true,
      item: {
        ...sign,
        src: staticSign.src,
        width: SIGN_SIZE,
        height: SIGN_SIZE
      }
    };
  }
};

export const deviationAsRoute = deviation => {
  const color = convertHex(deviation.color);
  const waypoints = chunk(flatten(deviation.points || []), 2);
  const directions = chunk(flatten(deviation.directions || []), 2);
  return {
    kind: 'deviation',
    id: deviation.id,
    name: deviation.name || deviation.displayName,
    isNew: deviation.isNew,
    color,
    directions,
    directionsLatLng: directions.map(point => ({
      lat: parseFloat(point[0]),
      lng: parseFloat(point[1])
    })),
    points: waypoints,
    markers: waypoints.map(point => ({
      id: v4(),
      lat: parseFloat(point[0]),
      lng: parseFloat(point[1]),
      color,
      kind: 'point',
      draggable: true,
      routeId: deviation.id,
      item: 'deviationPoint',
      hidden: true
    })),
    _destroy: deviation._destroy
  };
};

export const projectAsRoute = project => {
  if (!project) {
    return null;
  }
  const routeId = -1;
  const color = convertHex('#ff0000');

  const waypoints = chunk(flatten(project.points || []), 2);
  const directions = chunk(flatten(project.directions || []), 2);

  return {
    kind: 'project',
    id: routeId,
    name: 'Chantier',
    color,
    directions,
    directionsLatLng: directions.map(point => ({
      lat: parseFloat(point[0]),
      lng: parseFloat(point[1])
    })),
    points: waypoints,
    markers: waypoints.map(point => ({
      id: v4(),
      lat: parseFloat(point[0]),
      lng: parseFloat(point[1]),
      color,
      kind: 'point',
      draggable: true,
      routeId,
      item: 'projectPoint',
      hidden: true
    }))
  };
};

import React from 'react';
import styled from 'styled-components';
import RawCard, {
  CardHeader,
  CardContent,
  CardButtons,
  CardFooter
} from '../ui/card';

const Card = styled(RawCard)`
  max-width: 320px;
  @media screen and (min-width: 800px) {
    max-width: 420px;
  }
`;

interface ConfirmDeleteProps {
  isDeleting: boolean;
  onDeleteConfirmed: (confirmed: boolean) => void;
}

const ConfirmDelete: React.FunctionComponent<ConfirmDeleteProps> = ({
  isDeleting,
  onDeleteConfirmed,
  ...props
}) => {
  return (
    <Card {...props}>
      <CardHeader title="Confirmer suppression" />
      <CardContent>
        Toute suppression est définitive et les données associées seront
        perdues. Confirmer la suppression&nbsp;?
      </CardContent>
      <CardFooter>
        <CardButtons
          neutralText="Retour"
          negativeText="Supprimer"
          buttonNameLoading={isDeleting ? 'negative' : null}
          onButtonClick={async ({ name }: any) => {
            switch (name) {
              case 'negative': {
                onDeleteConfirmed(true);
                break;
              }
              default:
                onDeleteConfirmed(false);
            }
          }}
        />
      </CardFooter>
    </Card>
  );
};

export default ConfirmDelete;

import { put, post, destroy } from 'common/api';

export const updateVoucher = (voucher, config) =>
  put('/v5/vouchers', { voucher }, config);
export const deleteVoucher = (id, config) =>
  destroy(`/v5/vouchers/${id}`, config);

export const createVoucherVersion = (formData, config) =>
  post('/v5/voucher_versions', formData, config);
export const updateVoucherVersion = (id, voucherVersion, config) =>
  put(`/v5/voucher_versions/${id}`, { voucherVersion }, config);
export const deleteVoucherVersion = (id, config) =>
  destroy(`/v5/voucher_versions/${id}`, config);

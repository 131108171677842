import { omit } from 'lodash';
import { mergeJsonApi } from 'common/reducers';
import { merge } from 'utils';

const initialState = {
  teamById: {},
  teamsIds: []
};

const teams = (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case 'TEAMS_LOADING':
      return {
        ...state,
        isLoading: true
      };
    case 'TEAMS_ERROR':
      return {
        ...state,
        isLoading: false
      };
    case 'NEW_TEAM_SUCCESS':
      return {
        ...mergeJsonApi('team', state, action),
        teamsIds: [...state.teamsIds, payload.data.id]
      };
    case 'DELETE_TEAM_SUCCESS':
      return {
        ...state,
        teamById: omit(state.teamById, payload),
        teamsIds: state.teamsIds.filter(id => parseInt(id) !== payload)
      };
    case 'REORDER_TEAMS_SUCCESS':
      return {
        ...state,
        teamById: merge(
          state.teamById,
          payload.reduce((acc, team) => {
            acc[team.id] = team;
            return acc;
          }, {})
        ),
        teamsIds: payload.map(m => m.id)
      };
    case 'LOGOUT':
    case 'SELECT_ACCOUNT':
      return initialState;
    default:
      return mergeJsonApi('team', state, action);
  }
};

export default teams;

import React, { useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { MdAdd } from 'react-icons/md';
import SubHeader from 'components/v3/SubHeader';
import { FilterBar } from 'components/v3/Filters';
import { Container, Title, Button } from 'components/v3/ui';
import { PmvSelector, PmvAction } from 'features/Pmvs';
import PmvList from 'features/Pmvs/components/v3/List';

const defaultParams = { sort: 'position,displayName' };

const Pmvs = () => {
  const { path } = useRouteMatch();
  const [count, setCount] = useState();
  const [filters, setFilters] = useState();

  return (
    <Container vertical>
      <SubHeader>
        <Title>{`Panneaux à message${
          count != null ? ` (${count})` : ''
        }`}</Title>
        <Button small to={`${path}/nouveau-panneau`} icon={MdAdd}>
          Nouveau panneau
        </Button>
      </SubHeader>
      <Container vertical huge padded>
        <FilterBar
          big
          searchQuery="pages_lines_text_cont_any"
          onChanges={setFilters}
        />
        <PmvList
          spacing={8}
          headerVariant="transparent"
          selector={PmvSelector.selectPmvs}
          action={PmvAction.getPmvs}
          onCountChanged={setCount}
          params={defaultParams}
          filters={filters}
          perPage={50}
          detailUrlPrefix=""
        />
      </Container>
    </Container>
  );
};

export default Pmvs;

import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo
} from 'react';
import styled, { css } from 'styled-components';
import ReactMapGL from 'react-map-gl';
import WebMercatorViewport from 'viewport-mercator-project';
import map from 'lodash/map';
import { MAP_BOX_TOKEN } from '../../../constants';
import MapStylePicker, { MapStyles } from './MapStylePicker';
import { Markers } from './Marker';
import MarkerInfo from './MarkerInfo';
import Polylines from './Polylines';
import { useDeepEffect, useDelay } from '../utils';

const Container = styled.div.attrs(({ height, width }) => ({
  style: {
    height,
    width
  }
}))`
  position: relative;

  .mapboxgl-map {
    display: flex;
    ${({ bottomRadius }) =>
      bottomRadius &&
      css`
        border-radius: 0 0 ${({ theme }) => theme.borderBoxRadius}
          ${({ theme }) => theme.borderBoxRadius};
      `};

    ${({ topRadius }) =>
      topRadius &&
      css`
        border-radius: ${({ theme }) => theme.borderBoxRadius}
          ${({ theme }) => theme.borderBoxRadius} 0 0;
      `};

    ${({ fullRadius }) =>
      fullRadius &&
      css`
        border-radius: ${({ theme }) => theme.borderInputRadius};
      `};
  }

  .mapboxgl-popup-close-button,
  .mapboxgl-ctrl-logo,
  .mapboxgl-ctrl-bottom-right {
    display: none !important;
  }

  .mapboxgl-marker {
    cursor: pointer !important;
  }
  .mapboxgl-popup-content {
    min-width: 150px;
    min-height: 50px;
    max-width: 600px;
    padding: 12px;
    cursor: pointer;
    z-index: 10000;
    border-radius: ${({ theme }) => theme.borderBoxRadius};
    display: flex;
    flex-direction: column;
  }
`;
const MapStyleContainer = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
`;

export const getCursor = ({ isDragging, isHovering }) =>
  isDragging ? 'grabbing' : isHovering ? 'pointer' : 'grab';

const Map = (
  {
    width,
    height,
    latitude,
    longitude,

    useAccountAddressIfEmpty = false,

    autoAdjustBounds = true,
    coordinates, // les positions gps des markers, routes etc.

    markers = [],
    onMarkersChanged,

    onMarkerMove,
    onMarkerDelete,
    onMarkerSelected,
    onMarkerInfoClick,

    routes = [],

    onMapClick,
    onViewportChanged = undefined,
    onBoundsChanged = undefined,

    ...props
  },
  ref
) => {
  const mapGlRef = useRef();
  const delay = useDelay();
  const [isInteracting, setInteracting] = useState(false);
  const [hasInteracted, setInteracted] = useState(false);

  const [mapStyle, setMapStyle] = useState(MapStyles[0].value);
  const [mapViewport, setMapViewport] = useState({
    latitude: (latitude && Number(latitude)) || 50.6330372,
    longitude: (longitude && Number(longitude)) || 3.020054,
    zoom: 12,
    maxZoom: 18,
    ...props,
    width: '100%'
  });
  const [markerId, setDisplayMarkerId] = useState();

  const coords = useMemo(() => {
    if (coordinates) {
      return coordinates;
    } else {
      return markers;
    }
  }, [coordinates, markers]);

  const markerInfo = useMemo(() => {
    if (!markers || !markerId) {
      return null;
    }
    return markers.find(m => m.id === markerId);
  }, [markers, markerId]);

  React.useImperativeHandle(ref, () => ({
    map: () => mapGlRef.current,
    mapViewport: () => mapViewport,
    setInteracted: interacted => setInteracted(interacted)
  }));

  useEffect(() => {
    if (!autoAdjustBounds || hasInteracted) {
      return;
    }
    if (!coords || coords.length === 0 || coords.length > 1) {
      return;
    }
    // on focus sur le seul marker
    const coordinates = coords[0];
    setMapViewport(prev => {
      return {
        ...prev,
        latitude: Number(coordinates.lat),
        longitude: Number(coordinates.lng)
      };
    });
  }, [autoAdjustBounds, hasInteracted, coords]);

  useEffect(() => {
    if (autoAdjustBounds || hasInteracted || !latitude || !longitude) {
      return;
    }
    setMapViewport(prev => {
      return {
        ...prev,
        latitude: Number(latitude),
        longitude: Number(longitude)
      };
    });
  }, [autoAdjustBounds, hasInteracted, coords, latitude, longitude]);

  useEffect(() => {
    let handler = window.setTimeout(() => {
      if (!autoAdjustBounds || hasInteracted) {
        return;
      }
      if (!coords || coords.length < 2) {
        return;
      }
      setMapViewport(viewport => {
        if (viewport.width < 100) {
          return viewport;
        }
        const mercatorViewport = new WebMercatorViewport(viewport);
        const lngs = map(coords, 'lng').map(lng => Number(lng));
        const lats = map(coords, 'lat').map(lat => Number(lat));
        const lngMax = Math.max(...lngs);
        const lngMin = Math.min(...lngs);
        const latMax = Math.max(...lats);
        const latMin = Math.min(...lats);

        let bounds = mercatorViewport.fitBounds(
          [
            [lngMin, latMin],
            [lngMax, latMax]
          ],
          {
            padding: 1,
            offset: [-24, -48]
          }
        );
        // on met un zoom max pour que ce soit cohérent dans la vue
        if (bounds.zoom > 16) {
          bounds = { ...bounds, zoom: 16 };
        }
        return {
          ...viewport,
          ...mercatorViewport,
          ...bounds
        };
      });
    }, 500);
    return () => {
      window.clearTimeout(handler);
    };
  }, [autoAdjustBounds, hasInteracted, coords]);

  const handleViewportChange = useCallback(viewport => {
    setMapViewport(viewport);
  }, []);

  const handleInteractionStateChange = useCallback(
    ({ isPanning, isRotating, isZooming, isDragging, ...other }) => {
      const interacting = isPanning || isRotating || isZooming || isDragging;
      setInteracting(interacting);
      if (interacting && !hasInteracted) {
        setInteracted(true);
      }
    },
    [hasInteracted]
  );

  const handleMarkerClick = useCallback(({ id }) => setDisplayMarkerId(id), []);

  const handleMapClick = useCallback(
    e => {
      e.stopPropagation();
      if (onMapClick) {
        onMapClick(e);
      } else {
        setDisplayMarkerId();
      }
    },
    [onMapClick]
  );

  const bounds = onBoundsChanged
    ? mapGlRef.current?.getMap()?.getBounds()
    : null;

  useDeepEffect(() => {
    if (!isInteracting && bounds && onBoundsChanged) {
      delay(() => onBoundsChanged(bounds), 1000);
    }
  }, [bounds, isInteracting]);

  return (
    <Container fullRadius width={width} height={height}>
      <ReactMapGL
        getCursor={getCursor}
        onClick={handleMapClick}
        {...props}
        {...mapViewport}
        ref={mapGlRef}
        width={width}
        height={height}
        onInteractionStateChange={handleInteractionStateChange}
        mapStyle={`mapbox://styles/mapbox/${mapStyle}`}
        mapboxApiAccessToken={MAP_BOX_TOKEN}
        onViewportChange={handleViewportChange}
      >
        {props.children}
        {Array.isArray(routes) && routes.length > 0 && (
          <Polylines routes={routes} />
        )}
        <Markers
          markers={markers}
          onClick={handleMarkerClick}
          onMove={onMarkerMove}
          onDelete={onMarkerDelete}
        />
        <MarkerInfo
          marker={markerInfo}
          onClick={onMarkerInfoClick}
          onClose={setDisplayMarkerId}
        />
      </ReactMapGL>
      <MapStyleContainer>
        <MapStylePicker onChange={setMapStyle} currentStyle={mapStyle} />
      </MapStyleContainer>
    </Container>
  );
};

Map.displayName = 'Map';

export default React.forwardRef(Map);

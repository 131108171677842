import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
  useRef,
  Fragment
} from 'react';
import styled, { css } from 'styled-components';
import { FormikValues, FormikProps } from 'formik';
import { MdMoreVert } from 'react-icons/md';
import { Classes, Styles } from 'react-modal';
import Modal, {
  Header as ModalHeader,
  Footer as ModalFooter,
  ModalBaseProps
} from './Modal';
import { FormWrapper as RawFormWrapper } from '../Form/Form';
import EntityForm, {
  FetchStatus,
  Entity,
  Props as EntityFormProps
} from '../Form/EntityForm';
import Menu, { MenuItem } from '../Menu';
import RawScroller from '../Scroller';
import { CardContent, CardButtons } from '../ui/card';
import { ButtonIcon } from '../ui';

interface CommonProps extends ModalBaseProps, EntityFormProps<Entity> {
  title?: (
    props: FormikProps<FormikValues>,
    fetchStatus: FetchStatus
  ) => string | string;
  titleValueKey?: string;
  subtitle?: (
    props: FormikProps<FormikValues>,
    fetchStatus: FetchStatus
  ) => string | string;
  subtitleValueKey?: string;
  headerRightContent?: (
    props: FormikProps<FormikValues>,
    fetchStatus: FetchStatus
  ) => React.ReactNode | React.ReactNode;
  customHeader?: (
    props: FormikProps<FormikValues>,
    fetchStatus: FetchStatus
  ) => React.ReactNode | React.ReactNode;
  contextMenu?: (
    props: FormikProps<FormikValues>
  ) => React.ReactNode | React.ReactNode;
  footerHidden?: boolean;
  headerHidden?: boolean;
  onVerticalScroll?: (scrollTop: number, bottomReached: boolean) => void;
  modalStyle?: Styles;
  grow?: boolean;
  positiveText?: string;
  negativeText?: string;

  className?: never;
  modalClassName?: string | Classes;
  style?: never;
}

export interface Props extends CommonProps {
  closeOnSubmit?: ((form: FormikProps<FormikValues>) => boolean) | boolean;
  wrapInForm: never;
}

interface ContentProps extends CommonProps {
  form: FormikProps<FormikValues>;
  onConfirmDelete: (isConfirmDelete: boolean) => void;
  onLoading: (isLoading: boolean) => void;
  archived: boolean;
  onDirty: React.Dispatch<React.SetStateAction<boolean>>;
  fetchStatus: FetchStatus;
}

const Scroller = styled(RawScroller)`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`;

const FormWrapper = styled(RawFormWrapper)<{
  'data-archived': boolean;
  'data-grow'?: boolean;
}>`
  height: 100%;

  ${props =>
    props['data-grow'] &&
    css`
      ${CardContent} {
        flex-grow: 1;
        min-height: 100%;
      }
    `}

  ${props =>
    props['data-archived'] &&
    css`
  
      &:before {
        content: '';
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.5) 40%, #d9d9d9 40%, #d9d9d9 50%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0.5) 90%, #d9d9d9 90%, #d9d9d9 100%) !important;
        background-size: 10.00px 10.00px !important;
        repeat top left;
        opacity: 0.4;
      }
  `}
`;

const ArchivedHint = styled.span`
  color: ${({ theme }) => theme.textCaption};
`;

const EntityModalContent: React.FunctionComponent<ContentProps> = ({
  form,
  id,
  title,
  titleValueKey,
  subtitle,
  subtitleValueKey,
  headerRightContent,
  customHeader,
  onRequestClose,
  onLoading,
  autoSubmit,
  children,
  onDirty,
  contextMenu,
  deleteRequest,
  onConfirmDelete,
  headerHidden,
  footerHidden,
  archived,
  onVerticalScroll,
  readOnly,
  grow,
  positiveText,
  negativeText,
  fetchStatus
}) => {
  const { values, isSubmitting } = form;
  const [topSeparatorVisible, setTopSeparatorVisible] = useState<boolean>(
    false
  );
  const [bottomSeparatorVisible, setBottomSeparatorVisible] = useState<boolean>(
    false
  );

  const handleScroll = useCallback(
    (scrollTop: number, bottomReached: boolean) => {
      setTopSeparatorVisible(scrollTop > 0);
      setBottomSeparatorVisible(!bottomReached);
      if (onVerticalScroll) {
        onVerticalScroll(scrollTop, bottomReached);
      }
    },
    [onVerticalScroll]
  );

  const memoChildren = useMemo(() => children(form, fetchStatus), [
    children,
    form,
    fetchStatus
  ]);

  useEffect(() => {
    onLoading(form.isSubmitting);
  }, [form.isSubmitting, onLoading]);

  useEffect(() => {
    onDirty(wasDirty => wasDirty || form.dirty);
  }, [onDirty, form.dirty]);

  return (
    <FormWrapper data-archived={archived} data-grow={grow}>
      {!headerHidden && (
        <ModalHeader
          separatorVisible={topSeparatorVisible}
          rightContent={
            headerRightContent instanceof Function
              ? headerRightContent(form, fetchStatus)
              : headerRightContent
          }
          title={
            <Fragment>
              {titleValueKey
                ? values[titleValueKey]
                : title instanceof Function
                ? title(form, fetchStatus)
                : title}
              {archived && <ArchivedHint> / Archivé(e)</ArchivedHint>}
            </Fragment>
          }
          subtitle={
            subtitleValueKey
              ? values[subtitleValueKey]
              : subtitle instanceof Function
              ? subtitle(form, fetchStatus)
              : subtitle
          }
        >
          {customHeader instanceof Function
            ? customHeader(form, fetchStatus)
            : customHeader}
        </ModalHeader>
      )}
      <Scroller
        autoHeight
        autoHeightMax="100%"
        grow={grow}
        onVerticalScroll={handleScroll}
      >
        <CardContent>{memoChildren}</CardContent>
      </Scroller>
      {!autoSubmit && !readOnly && !footerHidden && (
        <ModalFooter separatorVisible={bottomSeparatorVisible}>
          <CardButtons
            positiveText={positiveText}
            negativeText={negativeText}
            buttonNameLoading={isSubmitting ? 'positive' : null}
            contextMenu={
              (deleteRequest || contextMenu) && (
                <Menu minWidth={150} maxWidth={300}>
                  <ButtonIcon size="medium">
                    <MdMoreVert />
                  </ButtonIcon>
                  {contextMenu instanceof Function
                    ? contextMenu(form)
                    : contextMenu}
                  {id && deleteRequest && (
                    <MenuItem onClick={() => onConfirmDelete(true)}>
                      Supprimer
                    </MenuItem>
                  )}
                </Menu>
              )
            }
            onButtonClick={({ name }: any) => {
              switch (name) {
                case 'positive':
                  // form.submitForm();
                  break;
                default:
                  onRequestClose();
              }
            }}
          />
        </ModalFooter>
      )}
    </FormWrapper>
  );
};

const EntityModal: React.FunctionComponent<Props> = props => {
  const {
    isOpen,
    title,
    titleValueKey,
    subtitle,
    subtitleValueKey,
    onRequestClose,
    contextMenu,
    children,
    headerHidden,
    footerHidden,
    isLoading,
    closeOnSubmit,
    onSubmitSuccess,
    modalClassName,
    modalStyle,
    size,
    grow,
    expandable,
    expanded,
    positiveText = 'Enregistrer',
    negativeText = 'Annuler',
    parentSelector,
    ...formProps
  } = props;
  const { id, entity } = props;
  const archived = entity?.settings
    ? entity.settings.archived
    : entity?.archived;
  const [isConfirmDelete, setConfirmDelete] = useState(false);
  const [isDirty, setDirty] = useState(false);
  const [loadingCount, setLoadingCount] = useState(0);
  const onCloseRef = useRef(onRequestClose);
  onCloseRef.current = onRequestClose;
  const onSuccessRef = useRef(onSubmitSuccess);
  onSuccessRef.current = onSubmitSuccess;

  const handleLoading = useCallback(isLoading => {
    setLoadingCount(count => Math.max(0, isLoading ? count + 1 : count - 1));
  }, []);

  const handleSubmitSuccess = useCallback(
    (data, fromHelpers, form) => {
      onSuccessRef.current && onSuccessRef.current(data, fromHelpers);
      if (
        closeOnSubmit instanceof Function ? closeOnSubmit(form) : closeOnSubmit
      ) {
        onCloseRef.current && onCloseRef.current();
      }
    },
    [closeOnSubmit]
  );

  const handleDeleteSuccess = useCallback(() => {
    onCloseRef.current && onCloseRef.current();
  }, []);

  return (
    <Modal
      className={modalClassName}
      style={modalStyle}
      size={size}
      isOpen={isOpen}
      isLoading={loadingCount > 0}
      onRequestClose={onRequestClose}
      expandable={expandable}
      expanded={expanded}
      parentSelector={parentSelector}
      shouldCloseOnOverlayClick={!isDirty}
    >
      <EntityForm
        {...formProps}
        wrapInForm={false}
        onFetching={handleLoading}
        onSubmitSuccess={handleSubmitSuccess}
        showConfirmDelete={isConfirmDelete}
        onDeleteConfirmed={setConfirmDelete}
        onDeleteSuccess={handleDeleteSuccess}
      >
        {(form, fetchStatus) => (
          <EntityModalContent
            id={id}
            form={form}
            onLoading={handleLoading}
            onConfirmDelete={setConfirmDelete}
            archived={archived}
            grow={grow}
            positiveText={positiveText}
            negativeText={negativeText}
            onDirty={setDirty}
            fetchStatus={fetchStatus}
            {...props}
          />
        )}
      </EntityForm>
    </Modal>
  );
};

EntityModal.defaultProps = {
  closeOnSubmit: true,
  autoSubmit: false
};

export default EntityModal;

import React from 'react';
import styled from 'styled-components';
import { Title, Text } from 'components/v3/ui';
import img from 'common/images/404.gif';

const Wrapper = styled.div`
  background: #fff;
  height: calc(100vh - 56px);
  text-align: center;

  img {
    width: auto;
  }
} 
`;

const Content = styled.div`
  margin-top: -150px;
`;

const Error = () => {
  return (
    <Wrapper>
      <img src={img} alt="404_img" />
      <Content>
        <Title>Oups, une erreur s'est produite !</Title>
        <Text alignCenter>
          Veuillez réessayer ou contacter le support: contact@roadmapper.fr
        </Text>
      </Content>
    </Wrapper>
  );
};

export default Error;

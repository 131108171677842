import React from 'react';
import styled, { css } from 'styled-components';
import Tippy, { TippyProps } from '@tippyjs/react';
import { Text } from '../ui';
import Card from '../ui/card';

export type Variant = 'light' | 'dark';

interface Props extends TippyProps {
  content: string;
  style?: Object;
  variant?: Variant;
}

const Content = styled(Card)<{ variant?: Variant }>`
  padding: 6px 8px;
  ${({ variant }) => {
    switch (variant) {
      case 'light':
        return css`
          background-color: rgba(255, 255, 255, 0.8);
        `;
      default:
        return css`
          background-color: rgba(0, 0, 0, 0.8);
        `;
    }
  }}
`;

const Tooltip = (props: Props) => {
  const { content, variant, ...rest } = props;
  if (content) {
    return (
      <Tippy
        placement="top"
        touch={false}
        arrow={true}
        delay={[750, 250]}
        hideOnClick={false}
        content={
          <Content variant={variant}>
            <Text variant="hint" color="lightSecondary">
              {content}
            </Text>
          </Content>
        }
        {...rest}
      />
    );
  } else {
    return props.children || null;
  }
};

Tooltip.displayName = 'Tooltip';

export default React.memo(Tooltip);

import React, { useMemo, useCallback, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import isEqual from 'lodash/isEqual';
import AttachmentBox from 'features/Attachments/components/v3/Box';
import { ProjectSelector, ProjectAction } from 'features/Projects';
import { ReportSelector, ReportAction, ReportsBox } from 'features/Reports';
import VouchersBox from 'features/Vouchers/components/VouchersBox';
import { features, Permission, permissions, useAccountConfig } from 'config';

const Documents = ({ projectId, values }) => {
  const dispatch = useDispatch();
  const { hasPermission } = useAccountConfig();

  const reportSelector = useMemo(
    () => state => ReportSelector.selectProjectReports(state, { projectId }),
    [projectId]
  );

  const handleDeleteReport = useCallback(
    item => dispatch(ReportAction.deleteReport({ id: item.id })),
    [dispatch]
  );

  const attachmentsSelector = useMemo(
    () => state => ProjectSelector.selectProjectDocuments(state, { projectId }),
    [projectId]
  );

  const { attachments, vouchers } = useSelector(attachmentsSelector, isEqual);

  return (
    <Fragment>
      <VouchersBox
        vouchers={vouchers}
        marged
        creatable
        minHeight={200}
        useWindow={false}
        parentId={projectId}
        parentType="Project"
      />
      {hasPermission(permissions.COMPANY_DATA) && (
        <>
          <ReportsBox
            selector={reportSelector}
            creatable
            marged
            projectId={projectId}
            projectKindId={values.projectKind?.id}
            isDeletable
            deleteRequest={handleDeleteReport}
            useWindow={false}
            minHeight={200}
          />
          <Permission feature={[features.ATTACHMENTS]}>
            <AttachmentBox
              attachments={attachments}
              marged
              minHeight={200}
              // isItemLoading={isItemLoading}
              // onFileAdded={handleFileAdded}
              // onFileProgress={handleFileProgress}
              // onFileSuccess={handleFileSuccess}
              // onFileAdded={handleFileAdded}
              // onDelete={handleDelete}
              parentId={projectId}
              parentModel="Project"
              onFileSuccess={(_file, _attachment, body) =>
                dispatch(
                  ProjectAction.uploadProjectAttachmentSuccess({
                    projectId,
                    ...body
                  })
                )
              }
            />
          </Permission>
        </>
      )}
    </Fragment>
  );
};

export default Documents;

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import { AccountAction } from 'features/Accounts';

export const AccountSwitcherRedirect = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { params } = useRouteMatch();
  const accountId = params?.accountId;
  const path = params?.path;
  useEffect(() => {
    if (path && accountId !== 'null' && accountId !== 'undefined') {
      dispatch(AccountAction.selectAccount(accountId)).then(() =>
        history.replace({
          pathname: `/${path}`,
          search: window.location.search
        })
      );
    } else {
      history.replace('/');
    }
  }, [path, accountId, dispatch, history]);
  return null;
};

import { createAction } from 'redux-actions';
import omit from 'lodash/omit';
import * as Api from './api';
import { AttachmentApi } from 'features/Attachments';
import { prepareAttributes } from 'common/actions';

const preparePayload = payload =>
  prepareAttributes(
    {
      ...payload,
      state: payload.state?.value || payload.state,
      groupId: payload.group?.id || null
    },
    'address'
  );

const receiveIssues = createAction('ISSUES_SUCCESS');
export const getIssues = payload => async dispatch => {
  const issues = await Api.getIssues(payload);
  dispatch(receiveIssues(issues, payload));
  return issues;
};

export const receiveMapIssues = createAction('MAP_ISSUES_SUCCESS');
export const getMapIssues = payload => async dispatch => {
  const issues = await Api.getIssues(payload);
  dispatch(receiveMapIssues(issues, payload));
  return issues;
};

const receiveCreateIssue = createAction('NEW_ISSUE_SUCCESS');
export const createIssue = ({ payload, ...config }) => async dispatch => {
  const { attachments } = payload;
  const attachmentIds = attachments
    ? (await AttachmentApi.postAttachments(attachments)).map(a => a.id)
    : [];

  payload = {
    ...omit(payload, ['attachments']),
    attachmentIds
  };
  const result = await Api.createIssue(
    {
      ...preparePayload(omit(payload, ['attachments'])),
      attachmentIds
    },
    config
  );
  dispatch(receiveCreateIssue(result));
  return result;
};

const receiveIssue = createAction('ISSUE_SUCCESS');
export const getIssue = ({ id, ...payload }) => async dispatch => {
  const result = await Api.getIssue(id, payload);
  dispatch(receiveIssue(result));
  return result;
};

const receiveUpdateIssue = createAction('UPDATE_ISSUE_SUCCESS');
export const updateIssueState = ({
  id,
  payload,
  ...config
}) => async dispatch => {
  const result = await Api.putIssue(
    id,
    {
      ...payload,
      state: payload.state?.value || payload.state
    },
    config
  );
  dispatch(receiveUpdateIssue(result));
  return result;
};

export const updateIssue = ({ id, payload, ...config }) => async dispatch => {
  payload = {
    ...omit(payload, ['attachments'])
  };

  const result = await Api.putIssue(id, preparePayload(payload), config);
  dispatch(receiveUpdateIssue(result));
  return result;
};

const receiveDeleteIssue = createAction('DELETE_ISSUE_SUCCESS');
export const deleteIssue = ({ id, ...config }) => async dispatch => {
  const response = await Api.deleteIssue(id, config);
  dispatch(receiveDeleteIssue(id));
  return response;
};

import React from 'react';
import { PopoverItem, Icon, Text } from '../ui';

export default ({
  icon = null,
  onClick = null,
  disabled = false,
  children,
  ...props
}) => {
  return (
    <PopoverItem
      disabled={disabled}
      onClick={disabled ? undefined : onClick}
      {...props}
    >
      {icon && <Icon>{icon}</Icon>}
      <Text>{children}</Text>
    </PopoverItem>
  );
};

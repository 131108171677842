import React, { useMemo, Fragment, useCallback, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { MdCheckCircle } from 'react-icons/md';
import * as Yup from 'yup';
import {
  EntityForm,
  TextInputField,
  AddressField,
  ERRORS
} from 'components/v3/Form';
import { Grid, GridItem, Icon } from 'components/v3/ui';
import Box from 'components/v3/Box';
import Scroller from 'components/v3/Scroller';
import SubHeader from 'components/v3/SubHeader';
import Breadcrumb from 'components/v3/Breadcrumb';
import { useDelay } from 'components/v3/utils';
import {
  OrganizationAction,
  OrganizationSelector,
  organizationAsValues
} from 'features/Organizations';
import ContactBox from 'features/Contacts/components/v3/Box';
import ProjectBox from 'features/Projects/components/v3/Box';

const Content = styled.div`
  display: block;
  @media screen and (min-width: 1800px) {
    display: flex;
  }
`;

const FormColumn = styled.div`
  @media screen and (min-width: 1800px) {
    max-width: 600px;
  }
`;

const ListColumn = styled.div`
  flex-grow: 1;
  @media screen and (min-width: 1800px) {
    margin-left: -16px;
  }
`;

const Section = styled(GridItem)`
  min-width: 280px;
  @media screen and (min-width: 800px) {
    min-width: 400px;
  }
`;

const SuccessIcon = styled(Icon)`
  color: ${({ theme }) => theme.success};
  transition: all 0.25s ease;
  transform: scale(0);
  width: 32px;
  height: 32px;

  ${({ visible }) =>
    visible &&
    css`
      transform: scale(1);
    `}
`;

const validationSchema = Yup.object().shape({
  displayName: Yup.string().required(ERRORS.REQUIRED)
});

const Detail = ({ organizationsCount }) => {
  const dispatch = useDispatch();
  const delay = useDelay();
  const { params } = useRouteMatch();
  const { organizationId } = params;
  const [isSuccessIconVisible, setSuccessIconVisible] = useState(false);

  const selectOrganization = useMemo(
    () => state =>
      OrganizationSelector.selectOrganization(state, { organizationId }),
    [organizationId]
  );
  const organization = useSelector(selectOrganization, isEqual);

  const selectContacts = useMemo(
    () => state =>
      OrganizationSelector.selectOrganizationContacts(state, {
        organizationId
      }),
    [organizationId]
  );

  const selectProjects = useMemo(
    () => state =>
      OrganizationSelector.selectOrganizationProjects(state, {
        organizationId
      }),
    [organizationId]
  );

  const fetchRequest = useCallback(
    () => dispatch(OrganizationAction.getOrganization({ id: organizationId })),
    [organizationId, dispatch]
  );

  const updateRequest = useCallback(
    (id, values) =>
      dispatch(OrganizationAction.updateOrganization({ id, payload: values })),
    [dispatch]
  );

  return (
    <EntityForm
      id={organizationId}
      entity={organization}
      fetchRequest={fetchRequest}
      updateRequest={updateRequest}
      entityAsValue={organizationAsValues}
      validationSchema={validationSchema}
      disableSuccessToast={true}
      onSubmitSuccess={() => {
        setSuccessIconVisible(true);
        delay(() => setSuccessIconVisible(false), 2000);
      }}
    >
      {({ isSubmitting }) => {
        return (
          <Fragment>
            <SubHeader isLoading={isSubmitting}>
              <Breadcrumb
                links={[
                  {
                    name: `Organisations${
                      organizationsCount != null
                        ? ` (${organizationsCount})`
                        : ''
                    }`,
                    path: '/administration/organisations'
                  },
                  {
                    name: organization?.displayName || '',
                    path: '/administration/organisations/:organizationId'
                  }
                ]}
              />
              <SuccessIcon size="large" visible={isSuccessIconVisible}>
                <MdCheckCircle />
              </SuccessIcon>
            </SubHeader>
            <Scroller>
              <Content>
                <FormColumn>
                  <Box marged>
                    <Grid spacing={16}>
                      <Section width="50%" minWidth={280}>
                        <Grid spacing={16} padded={false}>
                          <GridItem width="100%">
                            <TextInputField
                              name="displayName"
                              label="Nom du compte"
                            />
                          </GridItem>
                          <GridItem width="100%">
                            <TextInputField
                              name="reference"
                              label="Référence"
                            />
                          </GridItem>
                          <GridItem width="100%">
                            <TextInputField
                              name="email"
                              label="Adresse email"
                            />
                          </GridItem>
                          <GridItem width="100%">
                            <TextInputField
                              name="phone"
                              label="Numéro de téléphone"
                            />
                          </GridItem>
                        </Grid>
                      </Section>
                      <Section width="50%">
                        <Grid spacing={16}>
                          <AddressField
                            name="address"
                            mapHeight={128}
                            mapRatio={3}
                          />
                        </Grid>
                      </Section>
                    </Grid>
                  </Box>
                </FormColumn>
                <ListColumn>
                  <ProjectBox
                    marged
                    selector={selectProjects}
                    fetchAction={OrganizationAction.getProjects}
                    headerVariant="transparent"
                    params={{ organizationId }}
                  />
                  <ContactBox
                    marged
                    selector={selectContacts}
                    fetchAction={OrganizationAction.getContacts}
                    headerVariant="transparent"
                    params={{ organizationId }}
                    organization={organization}
                    page="organization"
                  />
                </ListColumn>
              </Content>
            </Scroller>
          </Fragment>
        );
      }}
    </EntityForm>
  );
};

export default Detail;

import React, { useMemo, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { useRedux } from 'components/v3/utils';
import { DeviationAction, DeviationSelector } from 'features/Deviation';
import Deviation from 'features/Projects/components/v3/Deviation';
import RawBox from 'components/v3/Box';

const Box = styled(RawBox)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const DeviationDetail = ({ onDeviation, onLoading }) => {
  const { params } = useRouteMatch();
  let { deviationId } = params;

  const selectProject = useMemo(
    () => state => DeviationSelector.selectDeviation(state, { deviationId }),
    [deviationId]
  );

  const [deviation, fetchDeviation, state] = useRedux(
    selectProject,
    DeviationAction.getDeviation,
    {
      useLocalState: true,
      fetchOnMount: false
    }
  );

  const { isLoading } = state;

  useEffect(() => {
    if (deviationId) {
      fetchDeviation({ id: deviationId });
    }
  }, [fetchDeviation, deviationId]);

  useEffect(() => {
    onDeviation(deviation);
  }, [onDeviation, deviation]);

  useEffect(() => {
    onLoading(isLoading);
  }, [onLoading, isLoading]);

  return (
    <Box padded marged>
      <Deviation readOnly project={deviation} />
    </Box>
  );
};

export default DeviationDetail;

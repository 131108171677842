import React, { useState, useCallback } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { MdAdd } from 'react-icons/md';
import SubHeader from 'components/v3/SubHeader';
import { Container, Title, Button } from 'components/v3/ui';
import { SurfacingSelector, SurfacingAction, RawMaterialList } from 'features/Surfacing';

const RawMaterials = () => {
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  const [count, setCount] = useState();

  const handleDelete = useCallback((item) => dispatch(SurfacingAction.deleteRawMaterial({ id: item.id })), [dispatch]);

  const handleSort = useCallback(
    (items, item) =>
      dispatch(
        SurfacingAction.updateRawMaterialsOrder({
          payload: {
            id: item.id,
            position: item.position
          },
          payloads: items
        })
      ),
    [dispatch]
  );

  return (
    <Container vertical>
      <SubHeader>
        <Title>{`Matières premières${count != null ? ` (${count})` : ''}`}</Title>
        <Button small to={`${path}/nouveau`} icon={MdAdd}>
          Nouvelle matière première
        </Button>
      </SubHeader>
      <Container vertical huge padded>
        <RawMaterialList
          spacing={8}
          headerVariant="transparent"
          selector={SurfacingSelector.selectRawMaterials}
          action={SurfacingAction.getRawMaterials}
          onCountChanged={setCount}
          perPage={50}
          detailUrlPrefix=""
          isDeletable
          deleteRequest={handleDelete}
          isSortable
          onSortChanged={handleSort}
        />
      </Container>
    </Container>
  );
};

export default RawMaterials;

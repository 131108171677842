import { createDeepEqualSelector, selectId } from 'common/selector';
import { getState, selectRelations } from 'common/selector';

export const getGpsBoxes = state => state.gpsBoxes;

export const selectGpsBoxes = createDeepEqualSelector(
  [getGpsBoxes, getState],
  ({ gpsBoxesIds, gpsBoxById }, state) =>
    gpsBoxesIds
      .map(id => selectRelations('gpsBox', gpsBoxById[id], state))
      .filter(Boolean)
);

export const selectGpsBox = createDeepEqualSelector(
  [getGpsBoxes, selectId('gpsBox'), getState],
  ({ gpsBoxById }, gpsBoxId, state) => {
    const gpsBox = gpsBoxById[gpsBoxId];
    if (!gpsBox) {
      return null;
    }
    return selectRelations('gpsBox', gpsBox, state);
  }
);

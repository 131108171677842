import React, { Fragment } from 'react';
import { Popup } from 'react-map-gl';
import { addressBlock } from 'features/Addresses';
import { Text } from '../ui';
import { printDate } from 'utils/dates';
import ProjectCard from 'features/Projects/components/v3/Card';
import GpsBoxCard from 'features/GpsBoxes/components/v3/Card';
import IssueCard from 'features/Issues/components/v3/Card';
import PmvCard from 'features/Pmvs/components/v3/Card';
import BatteryTag from 'features/GpsBoxes/components/v3/BatteryTag';
import { TagContainer } from '../Tag';

const popupRenderer = ({ kind, item }) => {
  switch (kind) {
    case 'issue': {
      return <IssueCard issue={item} readOnly />;
    }
    case 'project': {
      return <ProjectCard project={item} readOnly />;
    }
    case 'pmv': {
      return <PmvCard pmv={item} />;
    }
    case 'gpsBox': {
      return <GpsBoxCard gpsBox={item} />;
    }
    case 'gpsPoint': {
      return (
        <Fragment>
          <TagContainer alignEnd>
            <BatteryTag gpsBox={item} />
          </TagContainer>
          <Text variant="caption" color="caption">
            Heure: {printDate(item.createdAt, 'HH:mm')}
          </Text>
        </Fragment>
      );
    }
    case 'home': {
      return (
        <Fragment>
          <Text>Adresse de l'agence</Text>
          <Text>{addressBlock(item)}</Text>
        </Fragment>
      );
    }
    default: {
      return null;
    }
  }
};

const MarkerInfo = ({ marker, onClick, onClose }) => {
  if (!marker) {
    return null;
  }
  return (
    <Popup
      tipSize={5}
      anchor="top"
      longitude={parseFloat(marker.lng)}
      latitude={parseFloat(marker.lat)}
      closeOnClick={true}
      onClose={e => {
        if (onClick) {
          onClick(marker);
        }
        if (onClose) {
          onClose();
        }
      }}
    >
      {popupRenderer(marker)}
    </Popup>
  );
};

export default MarkerInfo;

import {
  ContactApi,
  ContactAction,
  ContactReducer,
  ContactSelector
} from './data';

export { ContactApi, ContactAction, ContactReducer, ContactSelector };

export const contactAsOption = contact => {
  if (Array.isArray(contact)) {
    return contact.map(contactAsOption);
  } else if (contact) {
    return {
      value: contact.id,
      color: contact.color,
      label: contact.displayName,
      original: contact
    };
  } else {
    return null;
  }
};

export const contactAsValues = contact => ({
  id: contact?.id || null,
  firstName: contact?.firstName || '',
  lastName: contact?.lastName || '',
  organization: contact?.organization || null,
  position: contact?.position || '',
  email: contact?.email || '',
  mobile: contact?.mobile || '',
  phone: contact?.phone || '',
  address: contact?.address || null
});

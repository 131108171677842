import React, { useRef, useCallback, useState, useEffect } from 'react';
import styled from 'styled-components';
import { spacing } from 'ui';
import { Dot, Text } from 'components/v3/ui';
import Tooltip from 'components/v3/Tooltip';

const Wrapper = styled.div`
  display: inline-flex;
  align-items: center;
  overflow: hidden;
  width: 100%;
  > span:not(:last-child):not(:only-child) {
    margin-right: ${spacing(0.5)};
  }
`;

const LabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
`;

export default React.memo(
  ({
    option: { icon, label, subtitle, color },
    ellipsis,
    currentOption,
    variant,
    ...props
  }) => {
    const labelRef = useRef();
    const iconRef = useRef();
    const [iconWidth, setIconWidth] = useState(0);
    const [tooltip, setTooltip] = useState();

    const handleLabelMouseEnter = useCallback(() => {
      if (
        label &&
        labelRef.current?.offsetWidth < labelRef.current.scrollWidth
      ) {
        setTooltip(label);
      }
    }, [label]);

    useEffect(() => {
      const width = iconRef.current ? iconRef.current.offsetWidth : 0;
      if (width !== 0) {
        setIconWidth(width);
      }
    }, []);

    return (
      <Tooltip content={tooltip}>
        <Wrapper
          {...props}
          className={[props.className, 'option'].filter(Boolean).join(' ')}
          onMouseEnter={handleLabelMouseEnter}
        >
          {icon && (
            <div ref={iconRef} style={{ position: 'absolute' }}>
              {icon}
            </div>
          )}
          {!icon && color && <Dot color={color || '#ccc'} />}
          <LabelWrapper
            style={{ marginLeft: icon ? iconWidth + 8 : undefined }}
            ref={labelRef}
          >
            <Text ellipsis={ellipsis} variant={variant}>
              {label}
            </Text>
            {!currentOption && subtitle && (
              <Text variant="caption" color="caption" ellipsis={ellipsis}>
                {subtitle}
              </Text>
            )}
          </LabelWrapper>
        </Wrapper>
      </Tooltip>
    );
  }
);

import React from 'react';
import styled, { css } from 'styled-components';
import zxcvbn from 'zxcvbn';
import get from 'lodash/get';
import { Text } from './ui';
import { spacing, fontSize } from 'ui';

const Wrapper = styled('div')<{ score: number }>`
  position: relative;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;
  color: ${({ theme }) => theme.errorDark};
  padding-top: ${spacing()};

  ${({ score }) =>
    score > 1 &&
    css`
      color: ${({ theme }) => theme.error};
    `};

  ${({ score }) =>
    score > 2 &&
    css`
      color: ${({ theme }) => theme.warning};
    `};

  ${({ score }) =>
    score > 3 &&
    css`
      color: ${({ theme }) => theme.success};
    `};
`;

const GaugeWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const GaugeComponent = styled('span')<{ active: boolean }>`
  width: 24px;
  height: 4px;
  background-color: ${({ theme }) => theme.separator};
  margin-left: 4px;

  ${({ active }) =>
    active &&
    css`
      background-color: currentColor;
    `};
`;

const Hint = styled(Text)`
  font-weight: 500;
  padding-right: 8px;
  min-height: ${fontSize(14)};
`;

const getScoreLabel = (score: number) => {
  switch (score) {
    case 0:
      return 'Mot de passe trop court';
    case 1:
      return 'Mot de passe faible';
    case 2:
      return 'Mot de passe acceptable';
    case 3:
      return 'Mot de passe sécurisé';
    case 4:
      return 'Mot de passe très sécurisé';
    default:
      return ' ';
  }
};

const Gauge = ({ score }: { score: number }) => (
  <GaugeWrapper>
    <GaugeComponent active={score > 0} />
    <GaugeComponent active={score > 1} />
    <GaugeComponent active={score > 2} />
    <GaugeComponent active={score > 3} />
  </GaugeWrapper>
);

const PasswordStrength = ({ password = '' }) => {
  const passwordEmpty = password.length === 0;
  let score = 0;
  if (password.length >= 6) {
    score = passwordEmpty ? -1 : get(zxcvbn(password), 'score') || 1;
  }
  return (
    <Wrapper score={score}>
      <Hint variant="caption">{passwordEmpty ? '' : getScoreLabel(score)}</Hint>
      <Gauge score={score} />
    </Wrapper>
  );
};

export default PasswordStrength;

import React from 'react';
import { AccountingApi, AccountingAction, AccountingReducer, AccountingSelector } from './data';
import {
  InvoiceModal,
  InvoiceList,
  InvoiceModalAmounts,
  InvoiceModalLines,
  InvoiceModalToolbar,
  InvoicesBox,
  InvoiceState,
  ServiceList,
  ServiceModal,
  ServiceSelect,
  SubcontractingList,
  SubcontractingModal,
  SubcontractingsBox,
  TaxSelect,
  UnitModal,
  UnitSelect
} from './components';
import { Translate } from 'features/Localizations';
import { capitalize } from 'utils';

export { AccountingApi, AccountingAction, AccountingReducer, AccountingSelector };
export {
  InvoiceModal,
  InvoiceList,
  InvoiceModalAmounts,
  InvoiceModalLines,
  InvoiceModalToolbar,
  InvoicesBox,
  InvoiceState,
  ServiceList,
  ServiceModal,
  ServiceSelect,
  SubcontractingList,
  SubcontractingModal,
  SubcontractingsBox,
  TaxSelect,
  UnitModal,
  UnitSelect
};

const generateSenderReceiver = ({ preValues, project, account }) => {
  if (!project || !account) {
    return preValues;
  }
  let receiverName;
  let receiverAddress;
  if (project.contact?.address?.content) {
    receiverName = project.contact?.displayName;
    receiverAddress = project.contact?.address?.content;
  } else {
    receiverName = project.organization?.displayName;
    receiverAddress = project.organization?.address?.content;
  }
  if (preValues?.receiver?.displayNameEdited && preValues?.receiver?.displayName) {
    receiverName = preValues?.receiver?.displayName;
  }
  if (preValues?.receiver?.addressEdited && preValues?.receiver?.address) {
    receiverAddress = preValues?.receiver?.address;
  }

  return {
    sender: {
      displayName: account.displayName || preValues?.sender?.displayName || capitalize(account.subdomain),
      address: account.address?.content || preValues?.sender?.address,
      logoUrl: account.logoUrl || preValues?.sender?.logoUrl,
      email: account.email || preValues?.sender?.email || project.user?.email || null,
      phone: account.phone || preValues?.sender?.phone || project.user?.phone || null
    },
    receiver: {
      organizationId: project.organization?.id || preValues?.receiver?.organizationId || null,
      contactId: project.contact?.id || preValues?.receiver?.contactId || null,
      displayName: receiverName || preValues?.receiver?.displayName || null,
      address: receiverAddress || preValues?.receiver?.address || null,
      email: project.contact?.email || preValues?.receiver?.email || project.organization?.email || null,
      phone: project.contact?.phone || preValues?.receiver?.phone || project.organization?.phone || null,
      displayNameEdited: preValues?.receiver?.displayNameEdited,
      addressEdited: preValues?.receiver?.addressEdited
    }
  };
};

export const invoiceAsValues = ({ invoice, project, isNew, readOnly, kind, account }) => {
  if ((!invoice && !isNew) || !account) {
    return null;
  }
  const values = invoice || {};
  let displayName = values.displayName || project?.displayName;
  if (account.subdomain === 'ciedil' && project?.group?.displayName === 'Bâtiment') {
    displayName = values.displayName || '';
  }
  if (!readOnly && (isNew || values.state?.editable)) {
    return {
      id: values.id,
      ...generateSenderReceiver({ preValues: values, project, account }),
      createdBy: values.createdBy,
      state: values.state || 'draft',
      description: values.description || '',
      displayName,
      valueDate: values.valueDate || new Date(),
      projectId: project?.id,
      footer: account.companyNumber,
      kind: values.kind || kind,
      discount: -parseFloat(values.discount || 0),
      secondDiscount: -parseFloat(values.secondDiscount || 0),
      number: values.number,
      initialized: true,
      documentTemplate: values.documentTemplate,
      invoiceLines: values.invoiceLines,
      signed: values.signed,
      deposit: values.deposit,
      duplicate: values.duplicate,
      invoice: values.invoice,
      invoiceId: values.invoiceId
    };
  } else {
    return {
      initialized: true,
      receiver: {},
      sender: {},
      createdBy: values.createdBy,
      ...values,
      discount: -parseFloat(values.discount || 0),
      secondDiscount: -parseFloat(values.secondDiscount || 0),
      description: values.description,
      displayName,
      documentTemplate: values.documentTemplate,
      invoiceLines: values.invoiceLines,
      signed: values.signed,
      deposit: values.deposit
    };
  }
};

export const invoiceStateAsOption = (state) => {
  if (Array.isArray(state)) {
    return state.map(invoiceStateAsOption);
  } else if (state) {
    return {
      value: state.value,
      color: state.color,
      label: state.displayName || state.label,
      editable: state.editable,
      original: state
    };
  } else {
    return null;
  }
};

export const INVOICE_KINDS = [
  {
    label: <Translate value="invoice.kind.quote" fallback="Devis" />,
    name: 'Devis',
    value: 'quote',
    paramsKind: 'devis',
    duplicableKinds: ['quote', 'order', 'bill', 'credit']
  },
  {
    label: <Translate value="invoice.kind.order" fallback="Bon de commande" />,
    name: 'Bon de commande',
    value: 'order',
    paramsKind: 'bon-de-commande',
    duplicableKinds: ['quote', 'order', 'bill', 'credit']
  },
  {
    label: <Translate value="invoice.kind.bill" fallback="Facture" />,
    name: 'Facture',
    value: 'bill',
    paramsKind: 'facture',
    duplicableKinds: ['quote', 'order', 'bill', 'credit']
  },
  {
    label: <Translate value="invoice.kind.credit" fallback="Avoir" />,
    name: 'Avoir',
    value: 'credit',
    paramsKind: 'avoir',
    duplicableKinds: ['quote', 'order', 'bill', 'credit']
  }
];

export const getInvoiceKind = (kind) => {
  return (
    INVOICE_KINDS.find((m) => m.value === kind) || {
      label: <Translate value={`invoice.kind.${kind}`} fallback={kind} />,
      name: kind,
      value: kind,
      paramsKind: kind,
      duplicableKinds: ['quote', 'order', 'bill']
    }
  );
};

export const serviceAsOption = (service) => {
  if (Array.isArray(service)) {
    return service.map(serviceAsOption);
  } else if (service) {
    return {
      value: service.id,
      label: service.reference ? `${service.reference} - ${service.displayName}` : service.displayName,
      original: service
    };
  } else {
    return null;
  }
};

export const serviceAsValues = (service) => {
  return {
    id: service?.id || null,
    displayName: service?.displayName || '',
    unit: service?.unit || '',
    price: service?.price || '',
    taxRate: (service ? service.taxRate : '0.2') || '',
    reference: service?.reference || '',
    projectKind: service?.projectKind || null,
    groupServices:
      service?.groupServices?.map(({ isNew, id, price, group }) => ({
        id: isNew ? undefined : id,
        group,
        price: price || ''
      })) || []
  };
};

export const unitAsOption = (unit) => {
  if (Array.isArray(unit)) {
    return unit.map(unitAsOption);
  } else if (unit) {
    return {
      value: unit.id,
      label: unit.displayName,
      original: unit
    };
  } else {
    return null;
  }
};

export const unitAsValues = (unit) => ({
  id: unit?.id || null,
  displayName: unit?.displayName || ''
});

export const subcontractingAsValues = (subcontracting) => ({
  id: subcontracting?.id || null,
  displayName: subcontracting?.displayName || '',
  organization: subcontracting?.organization || null,
  amount: subcontracting?.amount || 0
});

export const invoiceAsOption = (invoice) => {
  if (Array.isArray(invoice)) {
    return invoice.map(invoiceAsOption);
  } else if (invoice) {
    return {
      value: invoice.id,
      label: invoice.number,
      subtitle: getInvoiceKind(invoice.kind)?.label,
      original: invoice
    };
  } else {
    return null;
  }
};

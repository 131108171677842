import firebase from 'firebase/app';
import 'firebase/analytics';

import {
  FB_API_KEY,
  FB_AUTH_DOMAIN,
  FB_DB_URL,
  FB_PROJECT_ID,
  FB_STORAGE_BUCKET,
  FB_MESSAGING_SENDER_ID,
  FB_APP_ID,
  FB_MEASUREMENT_ID
} from './../constants';

export default () => {
  try {
    firebase.initializeApp({
      apiKey: FB_API_KEY,
      authDomain: FB_AUTH_DOMAIN,
      databaseURL: FB_DB_URL,
      projectId: FB_PROJECT_ID,
      storageBucket: FB_STORAGE_BUCKET,
      messagingSenderId: FB_MESSAGING_SENDER_ID,
      appId: FB_APP_ID,
      measurementId: FB_MEASUREMENT_ID
    });
    firebase.analytics();
  } catch {}
  return null;
};

import React from 'react';
import styled from 'styled-components';
import { MdLayers } from 'react-icons/md';
import Menu, { MenuItem } from '../Menu';
import { ButtonIcon } from '../ui';
import { spacing } from 'ui';

const MapStyleSelectButton = styled(ButtonIcon)`
    position: absolute;
    top: ${spacing()};
    right: ${spacing()};
}`;

export const MapStyles = [
  {
    value: 'streets-v11',
    label: 'Par défaut'
  },
  {
    value: 'satellite-streets-v10',
    label: 'Satellite'
  }
];

const MapStylePicker = ({ onChange, currentStyle }) => {
  return (
    <Menu>
      <MapStyleSelectButton variant="iconLight" size="medium" type="button">
        <MdLayers />
      </MapStyleSelectButton>
      {MapStyles.map(({ value, label }) => (
        <MenuItem
          key={value}
          isActive={value === currentStyle}
          onClick={() => onChange(value)}
        >
          {label}
        </MenuItem>
      ))}
    </Menu>
  );
};

export default MapStylePicker;

import React, { useState } from 'react';
import styled from 'styled-components';
import Spinner from 'react-md-spinner';
import { spacing, theme } from 'ui';
import { MdClose } from 'react-icons/md';
import Portal from '../Portal';
import { ButtonIcon } from '../ui';
import Card, { CardHeader } from '../ui/card';

export const CloseModalTriggerButton = styled(ButtonIcon)`
  position: absolute;
  top: 2px;
  right: 2px;
  z-index: 2;
  cursor: pointer;
`;

export const ModalSpinnerWrapper = styled.div`
  position: absolute;
  top: 7px;
  right: 7px;
  z-index: 2;
  cursor: pointer;
`;

const Prompt = styled(Card)`
  box-shadow: ${({ theme }) => theme.boxShadowFocused};
`;

export const PromptHeader = styled.div`
  padding-right: ${spacing(1.5)};
  padding-left: ${spacing(1.5)};
`.withComponent(CardHeader);

const CalendarPrompt = ({
  elementRef,
  bounds,
  onRequestClose,
  isLoading: isLoadingProps,
  children,
  maxWidth = 350,
  ...props
}) => {
  const [isLoading, setLoading] = useState(false);
  return (
    <Portal
      elementRef={elementRef}
      bounds={bounds}
      {...props}
      top={5}
      maxWidth={maxWidth}
      zIndex={999}
      onOutClick={onRequestClose}
      useModalOutClick
      rootElement={
        document.fullscreenElement
          ? document.querySelector('.fc-media-screen')
          : null
      }
    >
      <Prompt>
        {(isLoading || isLoadingProps) && (
          <ModalSpinnerWrapper>
            <Spinner size={24} singleColor={theme.accent} />
          </ModalSpinnerWrapper>
        )}
        {Boolean(onRequestClose) && !(isLoading || isLoadingProps) && (
          <CloseModalTriggerButton onClick={onRequestClose}>
            <MdClose />
          </CloseModalTriggerButton>
        )}
        {children instanceof Function ? children({ setLoading }) : children}
      </Prompt>
    </Portal>
  );
};

export default CalendarPrompt;

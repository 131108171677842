import React, { useState, useCallback, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { MdAdd } from 'react-icons/md';
import Box, { BoxHeader, BoxFilter } from 'components/v3/Box';
import { Button } from 'components/v3/ui';
import Dropdown from 'components/v3/Dropdown';
import ReportList from './ReportList';
import { useRedux, useDeepEffect } from 'components/v3/utils';
import {
  ReportAction,
  ReportSelector,
  reportKindAsOption
} from 'features/Reports';
import { useHistory } from 'react-router';

const ReportsBox = ({
  fetchAction,
  selector,
  params,
  creatable,
  marged,
  padded,
  projectKindId,
  projectId,
  ...props
}) => {
  const history = useHistory();
  const { url } = useRouteMatch();
  const [count, setCount] = useState(null);
  const [filters, setFilters] = useState([]);

  const selectReportKinds = useMemo(
    () => state => ReportSelector.selectReportKinds(state, { projectKindId }),
    [projectKindId]
  );

  const [reportKinds, fetchReportKinds] = useRedux(
    selectReportKinds,
    ReportAction.getReportKinds,
    {
      fetchOnMount: false,
      useLocalState: true,
      initialParams: {
        page: 1,
        perPage: 500
      }
    }
  );

  const handleCreateWithReportKind = useCallback(
    option =>
      history.push(`${url}/compte-rendus/nouveau?reportKindId=${option.value}`),
    [history, url]
  );

  const reportKindOptions = useMemo(() => {
    return reportKinds.map(reportKindAsOption);
  }, [reportKinds]);

  useDeepEffect(() => {
    if (projectKindId) {
      fetchReportKinds({
        projectKindsIdIn: [projectKindId]
      });
    }
  }, [projectKindId]);

  if (reportKinds.length === 0) {
    return null;
  }
  return (
    <Box marged={marged} padded={padded}>
      <BoxHeader
        bigPadded
        title={`Compte-rendus${count != null ? ` (${count})` : ''}`}
      >
        {fetchAction && (
          <BoxFilter
            searchQuery="byQuery"
            filters={filters}
            onChanges={setFilters}
          />
        )}
        {reportKindOptions.length > 0 && (
          <Dropdown
            showIndicator={false}
            options={reportKindOptions}
            maxWidth={400}
            onChange={handleCreateWithReportKind}
          >
            <Button small icon={MdAdd}>
              Ajouter
            </Button>
          </Dropdown>
        )}
      </BoxHeader>
      <ReportList
        rowColor="light"
        selector={selector}
        action={fetchAction}
        params={params}
        filters={filters}
        onCountChanged={setCount}
        headerVariant="transparent"
        minRows={5}
        maxHeight={500}
        {...props}
      />
    </Box>
  );
};

export default ReportsBox;

import React, { useMemo } from 'react';
import { Route } from 'react-router';
import NotFound from 'pages/NotFound';
import { useAccountConfig } from 'config';

const Content = ({
  permission,
  feature,
  renderInactiveComponent = false,
  InactiveComponent = <NotFound />,
  children
}) => {
  const context = useAccountConfig();
  const { features } = context;
  const hasPermission = context.hasPermission(permission);
  const hasFeature = context.hasFeature(feature);

  if (hasPermission && hasFeature) {
    return typeof children === 'function'
      ? children({
          ...context,
          features: features.filter(f => {
            if (Array.isArray(feature)) {
              return feature.includes(f);
            } else {
              return feature === f;
            }
          })
        })
      : children;
  }
  if (renderInactiveComponent) {
    return InactiveComponent;
  } else {
    return null;
  }
};

export default ({
  permission,
  feature,
  path,
  renderInactiveComponent = false,
  InactiveComponent = <NotFound />,
  children,
  ...props
}) => {
  const content = useMemo(
    () => (
      <Content
        permission={permission}
        feature={feature}
        renderInactiveComponent={renderInactiveComponent}
        InactiveComponent={InactiveComponent}
        children={children}
      />
    ),
    [permission, feature, renderInactiveComponent, InactiveComponent, children]
  );
  if (path) {
    return (
      <Route path={path} {...props}>
        {content}
      </Route>
    );
  }
  return content;
};

import React, { useEffect, useCallback, useMemo, forwardRef, useState } from 'react';
import { addMilliseconds, endOfMinute, getMinutes } from 'date-fns';
import styled from 'styled-components';
import moment from 'moment';
import omit from 'lodash/omit';
import frLocale from '@fullcalendar/core/locales/fr';
import FullCalendar from '@fullcalendar/react';
import { MdFullscreen, MdViewWeek } from 'react-icons/md';
import DateNavigation from '../DateNavigation';
import { FilterBar } from '../Filters';
import Tooltip from '../Tooltip';
import SubHeader from '../SubHeader';
import { Container, Icon, ButtonIcon } from '../ui';
import { usePrevious, useDeepEffect } from '../utils';
import { formatISODate, printDate } from 'utils/dates';
import { merge } from 'utils';
import EventTooltip from './Tooltip';
import './index.scss';
import Menu, { MenuItem } from '../Menu';

const FullscreenButton = styled.div`
  margin-left: 10px;
`.withComponent(ButtonIcon);

const MemoCalendar = React.memo(React.forwardRef((props, ref) => <FullCalendar ref={ref} {...props} />));

const SubHeaderStyle = { backgroundColor: '#f2f2f4', padding: '6px 16px' };

const createEvent = (info) => {
  const { end, start, resource } = info;
  const { id: resourceId, extendedProps } = resource || {};
  const resourceData = {
    ...(extendedProps || {}),
    id: resourceId
  };
  return {
    displayName: '',
    startAt: formatISODate(start.getTime()),
    endAt: formatISODate(addMilliseconds(end.getTime(), -1)),
    isNew: true,
    resource: resourceData
  };
};

const infoWithBounds = (info) => {
  if (!info || info.event?.extendedProps?.source === 'period') {
    return null;
  }
  const element = info.el.closest('.fc-timeline-event');
  let bounds = null;
  if (element) {
    const rect = element.getBoundingClientRect();
    bounds = {
      top: rect.top + rect.height,
      right: rect.right,
      bottom: rect.bottom,
      left: rect.left,
      width: rect.width,
      height: rect.height,
      x: rect.x,
      y: rect.y
    };
  }
  if (!bounds) {
    bounds = {
      top: info.jsEvent.clientY,
      right: info.jsEvent.clientX + info.el.offsetWidth,
      bottom: info.jsEvent.clientY + 40,
      width: info.el.offsetWidth
    };
  }
  bounds = {
    ...bounds,
    left: info.jsEvent.clientX
  };

  return { ...info, bounds };
};

const Planning = forwardRef(
  (
    {
      currentView,
      views,
      onSelectView,
      eventContent: eventContentProps,
      eventContentBackground,
      events,
      tooltipContent,
      onDateRangeChanged,
      onEventSelect,
      onEventClick,
      onEventUpdate,
      onEventMouseEnter,
      onEventMouseLeave,
      headerLeftContent,
      searchQuery,
      searchLabel,
      filters,
      onFiltersChange,
      onSearch,
      disableSearch,
      isLoading,
      readOnly,
      ...props
    },
    calendarRef
  ) => {
    const [startAt, setStartAt] = useState();
    const [tooltip, setTooltip] = useState();
    const calendar = calendarRef.current?.getApi();
    const calendarViewType = calendar?.currentDataManager?.state?.currentViewType;

    const handleDatesSetChange = useCallback(
      ({ view }) => {
        const { activeStart, activeEnd } = view;
        onDateRangeChanged({
          startAt: moment(activeStart).format(),
          endAt: moment(activeEnd).format()
        });
      },
      [onDateRangeChanged]
    );

    const eventContent = useCallback(
      (props) => {
        if (props.event.display === 'background') {
          if (eventContentBackground) {
            return eventContentBackground(props);
          }
        } else {
          return eventContentProps(props);
        }
      },
      [eventContentProps, eventContentBackground]
    );

    const handleCreateItem = useCallback(
      (info) => {
        setTooltip(null);
        onEventSelect(createEvent(info));
      },
      [onEventSelect]
    );

    const handleUpdateItem = useCallback(
      (info) => {
        let payload = {
          startAt: formatISODate(info.event.start.getTime())
        };
        let end = info.event.end;

        // on va dire qu'on peut modifier à 15min
        if (getMinutes(end) % 15 === 0) {
          end = addMilliseconds(end, -1);
        } else {
          end = endOfMinute(end);
        }

        payload.endAt = formatISODate(end);
        payload = {
          id: info.event.extendedProps.uid,
          payload
        };
        onEventUpdate(info, payload);
      },
      [onEventUpdate]
    );

    const handleItemClick = useCallback(
      (info) => {
        setTooltip(null);
        onEventClick(infoWithBounds(info));
      },
      [onEventClick]
    );

    let shouldForceView = false;

    if (views && calendarViewType && !Object.keys(views).includes(calendarViewType)) {
      shouldForceView = true;
    }

    useEffect(() => {
      if (!shouldForceView && startAt && calendarRef.current) {
        calendarRef.current.getApi().changeView(currentView.name, startAt);
      }
      // on enleve le warning du hook car c'est voulu de ne pas appeler le hook si la view change
      // c'est le hook d'en dessous qui prend le relais
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startAt, calendarRef, currentView]);

    useDeepEffect(() => {
      if (!calendarRef.current) {
        return;
      }
      if (shouldForceView) {
        calendarRef.current.getApi().changeView(currentView.name);
      } else if (currentView.startOffset) {
        const offset = Object.keys(currentView.startOffset)[0];
        setStartAt(
          moment()
            .add(-currentView.startOffset[offset], offset)
            .startOf(currentView.dateAlignment || offset)
            .format()
        );
      } else {
        setStartAt(moment().startOf('day').format());
      }
      // on enleve le warning du hook car c'est voulu de ne pas appeler le hook si le startChange
      // c'est le hook d'en dessus qui prend le relais
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentView, calendarRef, shouldForceView]);

    const canUpdateEvent = useCallback(() => !readOnly, [readOnly]);
    const canCreateEvent = useCallback(() => !readOnly, [readOnly]);

    let calendarViews = useMemo(
      () =>
        views
          ? Object.keys(views).reduce((acc, viewName) => {
              acc[viewName] = omit(
                {
                  ...views[viewName],
                  slotLabelFormat: views[viewName].slotLabelFormat?.map(({ canShowDayHint, format, ...rest }) => {
                    if (rest.function) {
                      // eslint-disable-next-line no-new-func
                      const func = new Function(rest.function.arguments, rest.function.body);
                      return (info) => {
                        const text = func(info);
                        if (canShowDayHint) {
                          return `${text}#DAY_HINT`;
                        } else {
                          return text;
                        }
                      };
                    } else if (format) {
                      return (info) => {
                        const dt = info.date.marker;
                        const dtDateOnly = new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000);
                        const text = printDate(dtDateOnly, format);
                        if (canShowDayHint) {
                          return `${text}#DAY_HINT`;
                        } else {
                          return text;
                        }
                      };
                    } else {
                      if (canShowDayHint) {
                        const formatter = new Intl.DateTimeFormat('fr', rest);
                        return (info) => {
                          const dt = info.date.marker;
                          const dtDateOnly = new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000);
                          return `${formatter.format(dtDateOnly)}#DAY_HINT`;
                        };
                      } else {
                        return rest;
                      }
                    }
                  })
                },
                [
                  'dateDecrement',
                  'label',
                  'name',
                  'id',
                  'slotWidth',
                  'startOffset',
                  'noWeekendBackground',
                  'position',
                  'value',
                  'default'
                ]
              );
              return acc;
            }, {})
          : undefined,
      [views]
    );

    const previousCalendarViews = usePrevious(calendarViews);
    if (previousCalendarViews && !Object.keys(calendarViews).includes(calendarViewType)) {
      calendarViews = merge(previousCalendarViews, calendarViews);
    }

    const handleEventMouseEnter = useCallback((info) => setTooltip(infoWithBounds(info)), []);

    const handleEventMouseLeave = useCallback((info) => setTooltip(null), []);

    // useDeepEffect(() => {
    //   const calendar = calendarRef.current.getApi();
    //   let calendarEvent;
    //   let start;
    //   let end;
    //   let color;
    //   calendar.batchRendering(() => {
    //     events.forEach((event) => {
    //       calendarEvent = calendar.getEventById(event.id);
    //       if (calendarEvent) {
    //         start = event.start;
    //         end = event.end;
    //         color = event.color;

    //         if (!calendarEvent.start || start !== formatISODate(calendarEvent.start)) {
    //           calendarEvent.setStart(start);
    //         }
    //         if (!calendarEvent.end || end !== formatISODate(calendarEvent.end.setMilliseconds(999))) {
    //           calendarEvent.setEnd(end);
    //         }
    //         if (color !== calendarEvent.backgroundColor) {
    //           calendarEvent.setProp('backgroundColor', color);
    //         }
    //         let extendedProps = {};
    //         Object.keys(calendarEvent.extendedProps).forEach((prop) => {
    //           if (!isEqual(event[prop], calendarEvent.extendedProps[prop])) {
    //             extendedProps = {
    //               ...extendedProps,
    //               [prop]: event[prop]
    //             };
    //           }
    //         });
    //         if (!isEmpty(extendedProps)) {
    //           calendarEvent.mutate({ extendedProps });
    //         }
    //       } else {
    //         calendar.addEvent(event);
    //       }
    //     });
    //     const eventById = events.reduce((acc, event) => {
    //       acc[event.id] = event;
    //       return acc;
    //     }, {});
    //     console.log(calendar.getEvents().find((e) => e.id === 'phase#70152'));
    //     console.log('eventById[calendarEvent.id]', eventById['phase#70152']);
    //     calendar.getEvents().forEach((calendarEvent) => {
    //       if (!eventById[calendarEvent.id]) {
    //         calendarEvent.remove();
    //       }
    //     });
    //   });
    // }, [events, calendarRef]);

    return (
      <Container vertical>
        <SubHeader isLoading={isLoading} style={SubHeaderStyle}>
          <div style={{ minWidth: 34, minHeight: 1, display: 'flex' }}>
            {views && Object.values(views).length > 1 && (
              <Menu>
                <ButtonIcon size="medium">
                  <MdViewWeek />
                </ButtonIcon>
                {Object.values(views)
                  .sort((a, b) => a.position - b.position)
                  .map((view) => (
                    <MenuItem
                      key={view.name}
                      onClick={() => onSelectView(view)}
                      active={currentView.name === view.name}
                    >
                      {view.label}
                    </MenuItem>
                  ))}
              </Menu>
            )}
            {/* {views && Object.values(views).length > 1 && (
              <ActionGroup>
                {Object.values(views)
                  .sort((a, b) => a.position - b.position)
                  .map(view => (
                    <Action
                      key={view.name}
                      onClick={() => onSelectView(view)}
                      checked={currentView.name === view.name}
                    >
                      <Text style={{ padding: '7px 12px' }}>{view.label}</Text>
                    </Action>
                  ))}
              </ActionGroup>
            )} */}
            {typeof headerLeftContent === 'function' ? headerLeftContent({ startAt }) : headerLeftContent}
          </div>
          {(filters?.length > 0 || !disableSearch) && (
            <FilterBar
              searchMaxWidth={300}
              variant="flat.small"
              filters={filters}
              disableSearch={disableSearch}
              searchQuery={searchQuery}
              searchLabel={searchLabel}
              onChanges={onFiltersChange}
              onChange={(filter) => {
                if (onSearch && filter.kind === 'search') {
                  onSearch(filter.value);
                }
              }}
            />
          )}
          <div style={{ display: 'flex', alignContent: 'center' }}>
            <DateNavigation
              date={startAt}
              dateAlignment={currentView?.dateAlignment}
              dateTodayOffset={currentView?.startOffset}
              dateIncrement={currentView?.dateIncrement}
              onDateChanged={setStartAt}
            />
            {document.fullscreenEnabled && (
              <FullscreenButton
                onClick={() => {
                  if (!document.fullscreenElement) {
                    const media = document.querySelector('.fc-media-screen');
                    media.requestFullscreen();
                  }
                }}
              >
                <Tooltip content="Passer en plein écran">
                  <Icon>
                    <MdFullscreen />
                  </Icon>
                </Tooltip>
              </FullscreenButton>
            )}
          </div>
        </SubHeader>
        <MemoCalendar
          ref={calendarRef}
          nowIndicator
          headerToolbar={false}
          timeZone="local"
          locale={frLocale}
          height="parent"
          initialView={currentView.name}
          views={calendarViews}
          datesSet={handleDatesSetChange}
          events={events}
          eventContent={eventContent}
          eventResize={handleUpdateItem}
          eventDrop={handleUpdateItem}
          eventClick={handleItemClick}
          eventAllow={canUpdateEvent}
          selectAllow={canCreateEvent}
          selectable={!readOnly}
          editable={!readOnly}
          select={handleCreateItem}
          unselectAuto={false}
          slotMinWidth={currentView.slotWidth}
          eventMouseEnter={handleEventMouseEnter}
          eventMouseLeave={handleEventMouseLeave}
          eventResizeStart={handleEventMouseLeave}
          eventDragStart={handleEventMouseLeave}
          {...props}
        />
        {tooltipContent && (
          <EventTooltip bounds={tooltip?.bounds} event={tooltip?.event} tooltipContent={tooltipContent} />
        )}
      </Container>
    );
  }
);

export default React.memo(Planning);

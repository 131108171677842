import { uniqWith, pick } from 'lodash';
import { deepMerge } from 'utils';
import omit from 'lodash/omit';
import { camelizeKeys } from 'humps';
import isSameIds from 'utils/idUtils';

export const initialStateValue = {
  version: 4,
  defaultFeature: null,

  searchMapQuery: null,
  searchIssueQuery: null,
  searchProjectQuery: null,
  selectedMapMode: null,
  selectedProjectState: null,
  selectedIssueState: null,
  selectedEmergency: null,
  useOldProjectList: false,

  // Global
  selectedProjectKind: null,
  selectedUser: null,
  selectedGroups: [],
  selectedGroup: null,
  selectedPurveyor: null,
  selectedCarrier: null,
  dates: null,

  // Plannings
  planning: {
    selectedView: 'month',
    showWeekend: true,
    showOverlap: false,
    phaseColor: 'state.color',
    avatarSize: 'small',
    views: { week: {}, twoWeeks: {}, month: {} }
  },

  // Calendar
  calendar: {
    userIds: [],
    checkedIds: []
  },

  // Holiday
  selectedHolidayKind: null,
  holidayDates: null,

  // Invoice
  selectedInvoiceKind: null,
  selectedInvoiceState: null,
  searchInvoiceQuery: null,
  searchServiceQuery: null,

  // User
  selectedRole: null,

  selectedYear: new Date().getFullYear(),
  projectOverableCustomData: [],
  edited: false // quand l'utilisateur a modifié lui même
};

export const initialState = {
  isAuthenticated: false,
  userId: null,
  currentAccountId: null,
  ui: initialStateValue
};

const checkAccountUiOldAttributes = accountUi => {
  // eslint-disable-next-line default-case
  switch (accountUi.phaseColor) {
    case 'teamColor':
      accountUi.phaseColor = 'team.color';
      break;
    case 'phaseStateColor':
      accountUi.phaseColor = 'state.color';
      break;
  }
  // eslint-disable-next-line default-case
  return accountUi;
};

const ui = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'LOGOUT':
      localStorage.setItem('accessToken', '');
      localStorage.setItem('refreshToken', '');
      return initialState;
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        isAuthenticated: true
      };
    case 'REFRESH_TOKEN_SUCCESS':
      return {
        ...state,
        ...payload,
        isAuthenticated: true
      };
    case 'ME_SUCCESS':
      return {
        ...state,
        userId: payload.data.id
      };
    case 'ACCOUNT_SUCCESS':
      return {
        ...state,
        needsFetchCurrentAccount: true,
        [payload.data.id]: setAccountUi(state, payload.data)
      };
    case 'ACCOUNTS_SUCCESS':
      const prevAccountId = state.currentAccountId;
      const ids = payload.data.map(d => d.id);
      if (!prevAccountId || !ids.find(id => isSameIds(id, prevAccountId))) {
        const currentAccountId =
          payload.data.length > 0 ? payload.data[0].id : null;
        return {
          ...state,
          needsFetchCurrentAccount: true,
          currentAccountId,
          [currentAccountId]: setAccountUi(
            state,
            payload.data.find(account =>
              isSameIds(account.id, currentAccountId)
            )
          )
        };
      } else {
        return {
          ...state,
          needsFetchCurrentAccount: true,
          currentAccountId: prevAccountId,
          [prevAccountId]: setAccountUi(
            state,
            payload.data.find(account => isSameIds(account.id, prevAccountId))
          )
        };
      }
    case 'SELECT_ACCOUNT':
      return {
        ...state,
        currentAccountId: payload.id,
        [payload.id]: setAccountUi(state, payload.response.data)
      };

    case 'SET_FILTER':
      return updateState(state, {
        ...payload,
        edited: true
      });

    case 'SELECT_EMERGENCY':
      return updateState(state, {
        selectedEmergency: payload,
        edited: true
      });
    case 'SELECT_PLANNING_VIEW':
      return updateState(state, {
        planning: {
          ...state.planning,
          selectedView: payload
        },
        edited: true
      });
    case 'UPDATE_PHASE_COLOR':
      return updateState(state, {
        planning: {
          ...state.planning,
          phaseColor: payload
        },
        edited: true
      });
    case 'UPDATE_OVERLAP':
      return updateState(state, {
        planning: {
          ...state.planning,
          showOverlap: payload
        },
        edited: true
      });
    case 'UPDATE_SHOW_WEEK_END':
      return updateState(state, {
        planning: {
          ...state.planning,
          showWeekend: payload
        },
        edited: true
      });
    case 'UPDATE_AVATAR_SIZE':
      return updateState(state, {
        planning: {
          ...state.planning,
          avatarSize: payload
        },
        edited: true
      });

    case 'SELECT_MAP_MODE':
      return updateState(state, {
        selectedMapMode: payload || 'projects',
        edited: true
      });
    case 'SELECT_STATUS':
      return updateState(state, {
        selectedStatus: payload,
        edited: true
      });
    case 'SELECT_PURVEYOR':
      return updateState(state, {
        selectedPurveyor: payload,
        edited: true
      });
    case 'SELECT_CARRIER':
      return updateState(state, {
        selectedCarrier: payload,
        edited: true
      });
    case 'SELECT_YEAR':
      return updateState(state, {
        selectedYear: payload,
        edited: true
      });
    case 'SELECT_GROUPS':
      return updateState(state, {
        selectedGroups: payload,
        edited: true
      });
    case 'SELECT_GROUP':
      return updateState(state, {
        selectedGroup: payload,
        edited: true
      });
    case 'SET_SEARCH_MAP_QUERY':
      return updateState(state, {
        searchMapQuery: payload,
        edited: true
      });
    case 'SET_SEARCH_ISSUE_QUERY':
      return updateState(state, {
        searchIssueQuery: payload,
        edited: true
      });
    case 'SET_SEARCH_PROJECT_QUERY':
      return updateState(state, {
        searchProjectQuery: payload,
        edited: true
      });
    case 'SELECT_PROJECT_STATE':
      return updateState(state, {
        selectedProjectState: payload,
        edited: true
      });
    case 'SET_DATES':
      return updateState(state, {
        dates: payload,
        edited: true
      });
    case 'SET_HOLIDAY_DATES':
      return updateState(state, {
        holidayDates: payload,
        edited: true
      });
    case 'SELECT_HOLIDAY_KIND':
      return updateState(state, {
        selectedHolidayKind: payload,
        edited: true
      });
    case 'SELECT_ORGANIZATION':
      return updateState(state, {
        selectedOrganization: payload,
        edited: true
      });
    case 'SELECT_INVOICE_KIND':
      return updateState(state, {
        selectedInvoiceKind: payload,
        edited: true
      });
    case 'SELECT_INVOICE_STATE':
      return updateState(state, {
        selectedInvoiceState: payload,
        edited: true
      });
    case 'SET_SEARCH_INVOICE_QUERY':
      return updateState(state, {
        searchInvoiceQuery: payload,
        edited: true
      });
    case 'SET_SEARCH_SERVICE_QUERY':
      return updateState(state, {
        searchServiceQuery: payload,
        edited: true
      });
    case 'SELECT_ROLE':
      return updateState(state, {
        selectedRole: payload,
        edited: true
      });

    case 'SELECT_ISSUE_STATE':
      return updateState(state, {
        selectedIssueState: payload,
        edited: true
      });
    case 'SELECT_USER':
      return updateState(state, {
        selectedUser: payload,
        edited: true
      });
    case 'SELECT_PROJECT_KIND':
      return updateState(state, {
        selectedProjectKind: payload,
        edited: true
      });
    case 'SELECT_PROJECT_OVERABLE_CUSTOM_DATA':
      return updateState(state, {
        projectOverableCustomData: payload,
        edited: true
      });
    case 'ADD_CALENDAR_USERS':
      return updateState(state, currentState => ({
        edited: true,
        calendar: {
          ...currentState.calendar,
          userIds: uniqWith(
            [
              ...((currentState.calendar || {}).userIds || []),
              ...(Array.isArray(payload) ? payload : [payload])
            ],
            isSameIds
          ),
          checkedIds: uniqWith(
            [
              ...((currentState.calendar || {}).checkedIds || []),
              ...(Array.isArray(payload) ? payload : [payload])
            ],
            isSameIds
          )
        }
      }));
    case 'UPDATE_CALENDAR_USERS_CHECK': {
      return updateState(state, currentState => {
        const userId = payload.id;
        let checkedIds;
        if (payload.checked) {
          checkedIds = uniqWith(
            [...currentState.calendar.checkedIds, userId],
            isSameIds
          );
        } else {
          checkedIds = currentState.calendar.checkedIds.filter(
            id => !isSameIds(id, userId)
          );
        }
        return {
          edited: true,
          calendar: {
            ...currentState.calendar,
            userIds: uniqWith(
              [
                ...((currentState.calendar || {}).userIds || []),
                ...(Array.isArray(userId) ? userId : [userId])
              ],
              isSameIds
            ),
            checkedIds
          }
        };
      });
    }
    case 'REMOVE_CALENDAR_USER':
    case 'DELETE_USER_SUCCESS':
      return updateState(state, currentState => ({
        calendar: {
          ...currentState.calendar,
          userIds: currentState.calendar.userIds.filter(
            id => !isSameIds(id, payload)
          ),
          checkedIds: currentState.calendar.checkedIds.filter(
            id => !isSameIds(id, payload)
          )
        }
      }));
    case 'UPDATE_LINE_HEIGHT':
      return updateState(state, {
        lineHeight: payload,
        edited: true
      });
    case 'UPDATE_COMPACT':
      return updateState(state, {
        compact: payload,
        edited: true
      });
    case 'TOGGLE_USE_WAY_POINTS':
      return updateState(state, currentState => ({
        useRouteWaypointsApi: !currentState.useRouteWaypointsApi,
        edited: true
      }));
    case 'SELECT_OLD_PROJECT_LIST':
      return updateState(state, currentState => ({
        useOldProjectList: !currentState.useOldProjectList,
        edited: true
      }));
    case '@@router/LOCATION_CHANGE':
      if (payload.location?.pathname?.startsWith('/projets')) {
        return state;
      }
      return updateState(state, {
        projectState: null,
        projectQuery: null,
        projectOwner: null
      });
    default:
      return state;
  }
};

const updateState = (state, fields) => {
  const { currentAccountId } = state;
  let currentState = state[currentAccountId] || initialStateValue;
  if (currentState.version !== initialStateValue.version) {
    currentState = initialStateValue;
  }
  return {
    ...state,
    [currentAccountId]: deepMerge(
      deepMerge(omit(initialStateValue, 'planning.views'), currentState),
      typeof fields === 'function' ? fields(currentState) : fields
    )
  };
};

const setAccountUi = (state, account) => {
  let prevUiState = state[account.id] || initialStateValue;
  if (prevUiState.version !== initialStateValue.version) {
    prevUiState = initialStateValue;
  }
  let remoteUi = camelizeKeys(account.ui || account.attributes.ui);
  let initial = initialStateValue;
  if (remoteUi && remoteUi.planning && remoteUi.planning.views) {
    initial = omit(initialStateValue, 'planning.views');
  }

  let newState = {};
  if (prevUiState.edited) {
    newState = deepMerge(
      deepMerge(omit(initial, 'planning.views'), remoteUi),
      {
        ...omit(prevUiState, 'planning', 'defaultFeature', 'projectList'),
        ...pick(prevUiState, [
          // "phaseColor",
          // 'showOverlap',
          // 'showWeekend',
          'planning.selectedView'
        ])
      }
      // omit(prevUiState, 'planning.views')
    );
    let selectedView = newState.planning?.selectedView;
    if (selectedView) {
      const viewNames = Object.keys(newState.planning?.views || {});
      newState.planning.selectedView = viewNames.includes(selectedView)
        ? selectedView
        : viewNames[0] || 'month';
    }
  } else {
    newState = deepMerge(omit(prevUiState, 'planning.views'), remoteUi);
  }
  return checkAccountUiOldAttributes(newState);
};

export default ui;

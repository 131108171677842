import { useRef, useCallback, useEffect } from 'react';

// A ameliorer en utilisant la velocité de la souris comme sur l'exemple: https://codepen.io/loxks/details/KKpVvVW

const WHEEL_DELTA = 100;

export default () => {
  const scrollX = useRef(0);
  const onMouseMove = useCallback(evt => {
    let offsetX = scrollX.current - evt.pageX;
    document
      .querySelectorAll('.fc-scroller') //, '.fc-head td.fc-time-area'
      .forEach(scroller => {
        scroller.scrollLeft = scroller.scrollLeft + offsetX;
      });
    scrollX.current = evt.pageX;
  }, []);

  const onMouseDown = useCallback(
    e => {
      scrollX.current = e.pageX;
      window.removeEventListener('mousemove', onMouseMove);
      window.addEventListener('mousemove', onMouseMove);
    },
    [onMouseMove]
  );

  const onMouseUp = useCallback(() => {
    window.removeEventListener('mousemove', onMouseMove);
  }, [onMouseMove]);

  const onWheelEvent = useCallback(e => {
    if (!e.ctrlKey) {
      return;
    }
    let direction = e.detail ? e.detail * -WHEEL_DELTA : e.wheelDelta;
    document.querySelectorAll('.fc-scroller').forEach(scroller => {
      let position = scroller.scrollLeft;
      position += direction > 0 ? -WHEEL_DELTA : WHEEL_DELTA;
      scroller.scrollLeft = position;
    });
    e.preventDefault();
  }, []);

  useEffect(() => {
    const header = document.querySelector('.fc-timeline-header');
    if (!header) {
      return;
    }
    const scroller = header.querySelector('.fc-scrollgrid-sync-table');
    scroller.addEventListener('mousedown', onMouseDown);
    window.addEventListener('mouseup', onMouseUp);
    window.addEventListener('wheel', onWheelEvent);
    return () => {
      scroller.removeEventListener('mousedown', onMouseDown);
      window.removeEventListener('mouseup', onMouseUp);
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('wheel', onWheelEvent);
    };
  });
  return null;
};

import React, { useState } from 'react';
import { useRouteMatch } from 'react-router';
import { MdAdd } from 'react-icons/md';
import Box, { BoxHeader, BoxFilter } from 'components/v3/Box';
import { Button } from 'components/v3/ui';
import PhaseList from './List';

const FILTERS = [
  {
    kind: 'date',
    key: 'date',
    value: null,
    asQueryParam: value => ({
      'between[start_at]': value?.startAt,
      'between[end_at]': value?.endAt
    }),
    props: {
      mode: 'range',
      collapsedIfBlank: true
    }
  }
];

const PhasesBox = ({
  fetchAction,
  selector,
  params,
  marged,
  padded,
  creatable,
  detailUrlPrefix,
  ...props
}) => {
  const { url } = useRouteMatch();
  const [count, setCount] = useState(null);
  const [filters, setFilters] = useState(FILTERS);

  return (
    <Box marged={marged} padded={padded}>
      <BoxHeader
        bigPadded
        title={`Interventions${count != null ? ` (${count})` : ''}`}
      >
        <BoxFilter
          searchQuery="byQuery"
          filters={filters}
          onChanges={setFilters}
        />
        {creatable && (
          <Button
            small
            to={`${url}${detailUrlPrefix}/nouvelle-phase`}
            icon={MdAdd}
          >
            Ajouter
          </Button>
        )}
      </BoxHeader>
      <PhaseList
        rowColor="transparent"
        selector={selector}
        action={fetchAction}
        params={params}
        filters={filters}
        onCountChanged={setCount}
        headerVariant="transparent"
        minRows={5}
        estimatedItemSize={48}
        detailUrlPrefix={detailUrlPrefix}
        {...props}
      />
    </Box>
  );
};

export default PhasesBox;

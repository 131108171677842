import { TIMELINE_SIDEBAR_WIDTH } from 'ui';
import styled, { css } from 'styled-components';

export const UsersContainer = styled.div`
  background-color: white;
  width: 100%;
  height: 100%;
  position: relative;
  top: 0;
`;

export const Content = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  position: relative;
  background-color: white;

  ${props =>
    props.left &&
    css`
      flex-grow: 0;
      overflow: hidden;
      box-shadow: 7px 0px 7px -7px rgba(0, 0, 0, 0.12);
      z-index: 2;
      width: ${TIMELINE_SIDEBAR_WIDTH}px;
      min-width: ${TIMELINE_SIDEBAR_WIDTH}px;
    `};
`;

export const API_BASE = process.env.REACT_APP_API_BASE;
export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
export const CLIENT_ID_PUBLIC = process.env.REACT_APP_CLIENT_ID_PUBLIC;
export const MAP_BOX_TOKEN = process.env.REACT_APP_MAP_BOX_TOKEN;

export const FB_API_KEY = process.env.REACT_APP_FB_API_KEY;
export const FB_AUTH_DOMAIN = process.env.REACT_APP_FB_AUTH_DOMAIN;
export const FB_DB_URL = process.env.REACT_APP_FB_DB_URL;
export const FB_PROJECT_ID = process.env.REACT_APP_FB_PROJECT_ID;
export const FB_STORAGE_BUCKET = process.env.REACT_APP_FB_STORAGE_BUCKET;
export const FB_MESSAGING_SENDER_ID = process.env.REACT_APP_FB_MESSAGING_SENDER_ID;
export const FB_APP_ID = process.env.REACT_APP_FB_APP_ID;
export const FB_MEASUREMENT_ID = process.env.REACT_APP_FB_MEASUREMENT_ID;

export const APPSIGNAL_KEY = process.env.REACT_APP_APPSIGNAL_KEY;
export const GIT_COMMIT_SHORT_SHA = process.env.REACT_APP_GIT_COMMIT_SHORT_SHA;

import React, { useCallback, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import * as Yup from 'yup';
import { EntityModal } from 'components/v3/Modal';
import { TextInputField, ERRORS } from 'components/v3/Form';
import { Grid, GridItem } from 'components/v3/ui';
import {
  MaterialSelector,
  MaterialAction,
  extractionMaterialAsValues
} from '../data';
import { permissions, useAccountConfig } from 'config';

const validationSchema = Yup.object().shape({
  name: Yup.string().required(ERRORS.REQUIRED)
});

export const ExtractionMaterialModal = ({
  onRequestClose,
  onSubmitSuccess,
  extractionMaterial: propsExtractionMaterial,
  ...props
}) => {
  const { hasPermission } = useAccountConfig();
  const dispatch = useDispatch();
  const { params } = useRouteMatch();
  let { extractionMaterialId } = params;
  if (propsExtractionMaterial) {
    extractionMaterialId = propsExtractionMaterial.id;
  }
  const isNew = !Boolean(extractionMaterialId);

  const selectExtractionMaterial = useMemo(
    () => state =>
      MaterialSelector.selectExtractionMaterial(state, {
        id: extractionMaterialId
      }),
    [extractionMaterialId]
  );

  let extractionMaterial = useSelector(selectExtractionMaterial, isEqual);
  if (!extractionMaterial) {
    extractionMaterial = propsExtractionMaterial;
  }

  const createRequest = useCallback(
    payload => dispatch(MaterialAction.createExtractionMaterial({ payload })),
    [dispatch]
  );

  const updateRequest = useCallback(
    (id, payload) =>
      dispatch(MaterialAction.updateExtractionMaterial({ id, payload })),
    [dispatch]
  );

  const deleteRequest = useCallback(
    id => dispatch(MaterialAction.deleteExtractionMaterial({ id })),
    [dispatch]
  );

  const canEdit = hasPermission([
    permissions.RESOURCES_CONFIG,
    isNew ? 'extraction_material.create' : 'extraction_material.update'
  ]);
  const canDelete = hasPermission([
    permissions.RESOURCES_CONFIG,
    'extraction_material.delete'
  ]);

  return (
    <EntityModal
      isOpen
      size="small"
      onRequestClose={onRequestClose}
      title={isNew ? 'Nouveau sortant' : 'Édition du sortant'}
      id={extractionMaterialId}
      model="extractionMaterial"
      entity={extractionMaterial}
      validationSchema={validationSchema}
      entityAsValue={extractionMaterialAsValues}
      createRequest={createRequest}
      updateRequest={updateRequest}
      deleteRequest={canDelete ? deleteRequest : undefined}
      onSubmitSuccess={onSubmitSuccess}
      autoSubmit={false}
      readOnly={!canEdit}
      {...props}
    >
      {() => (
        <Grid spacing={16} padded={false}>
          <GridItem width="100%">
            <TextInputField
              name="name"
              label="Nom du sortant"
              required
              readOnly={!canEdit}
            />
          </GridItem>
        </Grid>
      )}
    </EntityModal>
  );
};

import React, { useCallback, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import * as Yup from 'yup';
import { EntityModal } from 'components/v3/Modal';
import { TextInputField, ERRORS } from 'components/v3/Form';
import { Grid, GridItem } from 'components/v3/ui';
import {
  MachineSelector,
  MachineAction,
  machineAsValues
} from 'features/Machines';
import { ProjectKindField } from 'features/ProjectKinds/components/v3/Select';
import { permissions, useAccountConfig } from 'config';

const validationSchema = Yup.object().shape({
  displayName: Yup.string().required(ERRORS.REQUIRED)
});

const MachineModal = ({
  onRequestClose,
  onSubmitSuccess,
  machine: propsMachine,
  ...props
}) => {
  const { hasPermission } = useAccountConfig();
  const dispatch = useDispatch();
  const { params } = useRouteMatch();
  let { machineId } = params;
  if (propsMachine) {
    machineId = propsMachine.id;
  }
  const isNew = !Boolean(machineId);

  const selectMachine = useMemo(
    () => state => MachineSelector.selectMachineV3(state, { machineId }),
    [machineId]
  );

  let machine = useSelector(selectMachine, isEqual);
  if (!machine) {
    machine = propsMachine;
  }

  const createRequest = useCallback(
    payload => dispatch(MachineAction.createMachine({ payload })),
    [dispatch]
  );

  const fetchRequest = useCallback(
    () => dispatch(MachineAction.getMachine({ id: machineId })),
    [machineId, dispatch]
  );

  const updateRequest = useCallback(
    (id, payload) => dispatch(MachineAction.updateMachine({ id, payload })),
    [dispatch]
  );

  const deleteRequest = useCallback(
    id => dispatch(MachineAction.deleteMachine({ id })),
    [dispatch]
  );

  const canEdit = hasPermission([
    permissions.RESOURCES_CONFIG,
    isNew ? 'machine.create' : 'machine.update'
  ]);

  const canDelete = hasPermission([
    permissions.RESOURCES_CONFIG,
    'machine.delete'
  ]);

  return (
    <EntityModal
      isOpen
      size="small"
      onRequestClose={onRequestClose}
      title={isNew ? 'Nouvelle machine' : 'Édition de la machine'}
      id={machineId}
      model="machine"
      entity={machine}
      validationSchema={validationSchema}
      entityAsValue={machineAsValues}
      fetchRequest={fetchRequest}
      createRequest={createRequest}
      updateRequest={updateRequest}
      deleteRequest={canDelete ? deleteRequest : undefined}
      onSubmitSuccess={onSubmitSuccess}
      autoSubmit={false}
      readOnly={!canEdit}
      {...props}
    >
      {() => (
        <Grid spacing={16} padded={false}>
          <GridItem width="100%">
            <TextInputField
              name="displayName"
              label="Nom de la machine"
              required
              readOnly={!canEdit}
            />
          </GridItem>
          <GridItem width="100%">
            <TextInputField
              name="dailyCost"
              readOnly={!canEdit}
              currency
              label="Coût journalier (€)"
            />
          </GridItem>
          <GridItem width="100%">
            <ProjectKindField
              name="projectKind"
              readOnly={!canEdit}
              label="Types de projet"
            />
          </GridItem>
          <GridItem width="100%">
            <TextInputField
              name="description"
              textArea
              readOnly={!canEdit}
              label="Informations / Description"
              minRows={3}
            />
          </GridItem>
        </Grid>
      )}
    </EntityModal>
  );
};

export default MachineModal;

import React, { useCallback, useMemo, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { EntityModal } from 'components/v3/Modal';
import { SelectField, TextInputField } from 'components/v3/Form';
import { Grid, GridItem } from 'components/v3/ui';
import { stringToColor } from 'utils';
import { MdSend } from 'react-icons/md';
import { selectProject } from 'features/Projects/data/selector';
import { capitalize, uniqBy } from 'lodash';
import { useDeepMemo } from 'components/v3/utils';
import { AccountingAction } from '../data';
import { selectCurrentAccount } from 'features/Accounts/data/selector';

const getKindString = kind => {
  switch (kind) {
    case 'order':
      return 'bon de commande';
    case 'quote':
      return 'devis';
    case 'credit':
      return 'avoir';
    default:
      return 'facture';
  }
};

const valuesDependencies = {
  color: {
    modifier: (values, form) => {
      if (!form.status?.color) {
        return stringToColor(values.displayName);
      } else {
        return values.color;
      }
    },
    triggers: ['displayName']
  }
};

const SendEmailModal = ({
  onRequestClose,
  onSubmitSuccess,
  group: propsGroup,
  invoice,
  projectId,
  ...props
}) => {
  const dispatch = useDispatch();
  const { params } = useRouteMatch();
  const [optionEmails, setOptionEmails] = useState([]);
  let { groupId } = params;
  if (propsGroup) {
    groupId = propsGroup.id;
  }

  const createRequest = useCallback(
    payload => dispatch(AccountingAction.sendInvoiceByEmail({ payload })),
    [dispatch]
  );

  const projectSelector = useMemo(
    () => state => selectProject(state, { projectId }),
    [projectId]
  );
  const project = useSelector(projectSelector, isEqual);
  const account = useSelector(selectCurrentAccount, isEqual);

  const { organization, contact } = project || {};

  const defaultEmails = useMemo(() => {
    const emails = [];
    if (contact && contact?.email) {
      emails.push({
        value: contact?.email,
        label: contact?.displayName
          ? `${contact?.displayName} (${contact?.email})`
          : contact?.email,
        subtitle: contact?.email
      });
    }
    if (
      organization &&
      organization?.email &&
      contact?.email !== organization.email
    ) {
      emails.push({
        value: organization?.email,
        label: organization?.displayName
          ? `${organization?.displayName} (${organization?.email})`
          : organization?.email,
        subtitle: organization?.email
      });
    }
    return emails;
  }, [contact, organization]);

  useDeepMemo(() => {
    setOptionEmails(e => uniqBy([...e, ...defaultEmails], 'value'));
  }, [defaultEmails]);

  const { id, kind, number } = invoice;
  const entityAsValue = useCallback(() => {
    return {
      invoiceId: id,
      emails: defaultEmails.map(({ value }) => value),
      subject: `${capitalize(getKindString(kind))} ${number}`,
      body: `Bonjour,\n\nVous trouverez ci joint votre ${getKindString(
        kind
      )} n° ${number}\n\nCordialement,\n\n${account?.displayName}${
        account?.email ? '\n' + account.email : ''
      }${account?.phone ? '\n' + account.phone : ''}${
        account?.address?.content ? '\n' + account.address.content : ''
      }`
    };
  }, [defaultEmails, kind, number, id, account]);

  return (
    <EntityModal
      isOpen
      size="normal"
      onRequestClose={onRequestClose}
      title={'Envoyer par email'}
      id={groupId}
      model=""
      entity={''}
      entityAsValue={entityAsValue}
      createRequest={createRequest}
      onSubmitSuccess={onSubmitSuccess}
      autoSubmit={false}
      valuesDependencies={valuesDependencies}
      positiveText={
        <>
          <MdSend /> &nbsp;Envoyer
        </>
      }
      {...props}
    >
      {({ setFieldValue, values }) => (
        <Grid spacing={16} padded={false}>
          <GridItem width="100%">
            <SelectField
              multi
              options={optionEmails}
              label="Destinataires"
              mapOptionValue
              name="emails"
              required
              value={values.emails}
              inputCreatable
              createLabel=""
              formatCreateLabel={inputValue => `Ajouter ${inputValue}`}
              onCreateOption={value => {
                setOptionEmails(e =>
                  uniqBy([...e, { value, label: value }], 'value')
                );
                setFieldValue('emails', [...values.emails, value]);
              }}
            />
          </GridItem>
          <GridItem width="100%">
            <TextInputField name="subject" label="Sujet" />
          </GridItem>
          <GridItem width="100%">
            <TextInputField
              textArea
              name="body"
              minRows={4}
              label="Corps de l'email"
            />
          </GridItem>
        </Grid>
      )}
    </EntityModal>
  );
};

export default SendEmailModal;

import React from 'react';
import styled, { css } from 'styled-components';
import { DebouncedInput, Input, InputArea, inputFontSize } from './input';
import Text, {
  Headline,
  Title,
  Subtitle,
  SectionTitle,
  Overline
} from './text';
import Button, { ButtonIcon, NavLink } from './button';
import { PopoverContainer, PopoverItem } from './popover';
import { Icon } from './icon';
import { Container, Grid, GridItem } from './layout';
import { ProgressBar } from './loader';

export { DebouncedInput, Input, InputArea, inputFontSize };
export { Headline, Title, Subtitle, SectionTitle, Overline, Text };
export { Button, ButtonIcon, NavLink };
export { PopoverContainer, PopoverItem };
export { Icon };
export { Container, Grid, GridItem };
export { ProgressBar };

export const Dot = styled.span.attrs(({ style, color, size }) => ({
  style: {
    backgroundColor: color,
    height: size,
    minHeight: size,
    width: size,
    minWidth: size,
    ...(style || {})
  }
}))`
  display: flex;
  align-items: center;
  height: 10px;
  width: 10px;
  min-width: 10px;
  border-radius: 50%;
`;

export const Dashed = styled.div`
  border-radius: ${({ theme }) => theme.borderInputRadius};
  transition: all 200ms;
  display: flex;
  border: 1px dashed rgb(0, 0, 0, 0.2);

  ${({ fillWidth }) =>
    fillWidth &&
    css`
      width: 100%;
    `}
`;

export const DashContainer = styled.div`
  ${Dashed} {
    input,
    textarea {
      border: none;
      &:focus,
      &:active {
        box-shadow: none;
        border: none;
      }
      &:hover:not(:focus) {
        border: none;
      }
    }
  }

  ${({ readOnly }) =>
    readOnly &&
    css`
      ${Dashed} {
        border: 1px dashed rgba(255, 255, 255, 0.01);
      }
    `}

  ${({ readOnly, fillWidth }) =>
    !readOnly &&
    css`
      ${Dashed} {
        border: 1px dashed rgba(255, 255, 255, 0.01);
        ${fillWidth ? `width: 100%;` : null}
        &:focus-within,
        &:active {
          border: 1px dashed rgba(255, 255, 255, 0.01);
          box-shadow: 0px 0px 0px 2px ${({ theme }) => theme.primaryLight};
        }

        &:active {
          border: 1px dashed rgba(255, 255, 255, 0.01);
          box-shadow: 0px 0px 0px 2px ${({ theme }) => theme.primaryLight};
        }
      }

      &:hover,
      &:focus-within {
        ${Dashed} {
          &:not(:focus-within) {
            border: 1px dashed rgb(0, 0, 0, 0.2);
          }
        }
      }
    `}
`;

const ALink = styled.a`
  cursor: pointer;
  text-decoration: none;
`;

export const ExternalLink = ({
  to,
  className,
  style,
  children,
  target = '_blank'
}) => {
  return (
    <ALink href={to} target={target} className={className} style={style}>
      {children}
    </ALink>
  );
};

import { createAction } from 'redux-actions';
import * as Api from './api';

const preparePayload = payload => {
  return {
    ...payload,
    userIds: payload.users?.map(u => u.id),
    kind: payload.kind?.value || payload.kind
  };
};

const receiveHolidays = createAction('HOLIDAYS_SUCCESS');
export const getHolidays = payload => async dispatch => {
  const result = await Api.getHolidays(payload);
  dispatch(receiveHolidays(result));
  return result;
};

const receiveCreateHoliday = createAction('NEW_HOLIDAY_SUCCESS');
export const createHoliday = ({ payload, ...config }) => async dispatch => {
  const result = await Api.createHoliday(preparePayload(payload), config);
  dispatch(receiveCreateHoliday(result));
  return result;
};

const receiveHoliday = createAction('HOLIDAY_SUCCESS');
export const getHoliday = ({ id, ...payload }) => async dispatch => {
  const result = await Api.getHoliday(id, payload);
  dispatch(receiveHoliday(result));
  return result;
};

export const updateHolidayLoading = createAction('UPDATE_HOLIDAY_LOADING');
export const updateHolidaySuccess = createAction('UPDATE_HOLIDAY_SUCCESS');
export const updateHolidayError = createAction('UPDATE_HOLIDAY_ERROR');
export const updateHoliday = ({ id, payload, ...config }) => async dispatch => {
  dispatch(updateHolidayLoading());
  try {
    const result = await Api.putHoliday(id, preparePayload(payload), config);
    dispatch(updateHolidaySuccess(result));
    return result;
  } catch (e) {
    dispatch(updateHolidayError(e));
    throw e;
  }
};

const receiveDeleteHoliday = createAction('DELETE_HOLIDAY_SUCCESS');
export const deleteHoliday = ({ id, ...config }) => async dispatch => {
  const response = await Api.deleteHoliday(id, config);
  dispatch(receiveDeleteHoliday(id));
  return response;
};

const receiveUpdateHoliday = createAction('UPDATE_HOLIDAY_SUCCESS');
export const updateHolidayKind = ({
  id,
  payload,
  ...config
}) => async dispatch => {
  const result = await Api.putHoliday(
    id,
    {
      ...payload,
      kind: payload.kind?.value || payload.kind
    },
    config
  );
  dispatch(receiveUpdateHoliday(result));
  return result;
};

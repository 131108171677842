import React from 'react';
import styled from 'styled-components';
import { MdCancel } from 'react-icons/md';
import RawOption from '../Option';
import { Icon } from '../ui';

const IconDelete = styled(Icon)`
  display: flex;
  align-items: center;
  cursor: pointer;
  > svg {
    opacity: 0.3;
  }
  &:hover {
    > svg {
      color: red;
      opacity: 0.6;
    }
  }
`;

const Option = styled.div`
  width: auto;
`.withComponent(RawOption);

export const ChipWrapper = styled.div`
  position: relative;
  display: flex;
  flex: 0 0 auto;
  // background-color: #e9ebf8;
  background-color: #dde1ff;
  border-radius: ${({ theme }) => theme.borderButtonRadius};
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 6px;
  padding-right: 6px;
`;

const handleMouseDown = e => e.preventDefault();

export default React.memo(
  ({ option, enabled, onClick, onDeleteClick, variant }) => {
    const handleClick = e => {
      if (onClick) {
        e.preventDefault();
        e.stopPropagation();
        onClick(option);
      }
    };
    const handleDelete = e => {
      if (onDeleteClick) {
        e.preventDefault();
        e.stopPropagation();
        onDeleteClick(option);
      }
    };
    return (
      <ChipWrapper enabled={enabled} onClick={handleClick}>
        <Option ellipsis option={option} currentOption variant={variant} />
        {enabled && onDeleteClick && (
          <IconDelete
            size="small"
            onMouseDown={handleMouseDown}
            onClick={handleDelete}
          >
            <MdCancel />
          </IconDelete>
        )}
      </ChipWrapper>
    );
  }
);

import {
  AccountApi,
  AccountAction,
  AccountReducer,
  AccountSelector
} from './data';

import { CurrentAccountLogoInfo } from './components';

export { AccountApi, AccountAction, AccountReducer, AccountSelector };
export { CurrentAccountLogoInfo };

export const jobAsOption = job => {
  if (Array.isArray(job)) {
    return job.map(jobAsOption);
  } else if (job) {
    return {
      value: job.value,
      // color: job.color,
      label: job.displayName || job.label,
      original: job
    };
  } else {
    return null;
  }
};

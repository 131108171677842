import React, { useMemo, useCallback, useState, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import Flatpickr from 'react-flatpickr';
import weekSelect from 'flatpickr/dist/plugins/weekSelect/weekSelect';
import monthSelect from 'flatpickr/dist/plugins/monthSelect';
import 'flatpickr/dist/plugins/monthSelect/style.css';
import { French } from 'flatpickr/dist/l10n/fr.js';
import 'flatpickr/dist/themes/material_blue.css';
import { isEqual, endOfDay, addMilliseconds, startOfDay, setMilliseconds, getMilliseconds } from 'date-fns';
import { MdClose } from 'react-icons/md';
import { GoCalendar } from 'react-icons/go';
import InputLayout from '../InputLayout';
import { Input } from '../ui';
import { parseDate, formatISODate, printDate } from 'utils/dates';
import { measureInputWidth } from '../utils';
import Styles from './Styles';

export const Wrapper = styled.div`
  cursor: pointer;
  &[readOnly] {
    cursor: default;
    pointer-events: none;
  }

  ${({ collapsed, asIcon }) =>
    (collapsed || asIcon) &&
    css`
      * > i {
        width: 48px;
        height: 48px;
        left: 0px;
        text-align: center;
      }
    `}

  ${({ asIcon }) =>
    asIcon &&
    css`
      * > input {
        color: transparent;
      }
    `}
`;

const flatPickerInput = (ref) => ref && ref.current && ref.current.flatpickr && ref.current.flatpickr.input;

const dateTimeFormat = 'EEE dd MMM yyyy, HH:mm';
const dateDayFormat = 'EEE dd MMM yyyy';

const DatePicker = (
  {
    value,
    onChange,
    disableTime = true,
    isClearable = true,
    asIcon = false,
    collapsedIfBlank = false,
    fitContent = false,
    onClickIcon = undefined,
    dateFormat,
    isEndDate,
    asWeekSelect,
    asMonthSelect,
    ...props
  },
  forwardedRef
) => {
  const ref = useRef();
  const { variant, mode, disabled, readOnly } = props;
  const [isOpen, setOpen] = useState(false);
  if (isClearable && onChange === undefined) {
    throw new Error('isClearable must be used with onChange with null check');
  }

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleChange = useCallback(
    (...args) => {
      const dates = args[0];
      if (!Array.isArray(dates)) {
        onChange(null);
        return;
      }
      if (mode === 'range') {
        if (dates.filter(Boolean).length <= 1) {
          return;
        }
        const startAt = dates[0];
        let endAt = dates[1];
        if (startAt.getTime() === endAt.getTime()) {
          endAt = endOfDay(startAt);
        } else if (isEqual(startOfDay(endAt), endAt)) {
          endAt = endOfDay(endAt);
        }
        onChange({
          startAt: formatISODate(startAt),
          endAt: formatISODate(endAt)
        });
      } else {
        let date = dates.length > 0 ? dates[0] : null;
        if (isEndDate && isEqual(startOfDay(date), date)) {
          date = endOfDay(date);
        } else if (isEndDate) {
          date = setMilliseconds(date, 999);
        }
        onChange(formatISODate(date));
      }
      const input = flatPickerInput(ref);
      if (input) {
        input.blur();
      }
    },
    [onChange, mode, isEndDate]
  );

  const handleClose = useCallback(
    (...args) => {
      setOpen(false);
      if (mode !== 'range') {
        return;
      }
      handleChange(...args);
    },
    [mode, handleChange]
  );

  const handleDelete = useCallback(() => {
    setOpen(false);
    if (mode === 'range') {
      handleChange({
        startAt: null,
        endAt: null
      });
    } else {
      handleChange(null);
    }
  }, [mode, handleChange]);

  const dateValue = useMemo(() => {
    if (mode === 'range') {
      const values = [value ? parseDate(value.startAt) : null, value ? parseDate(value.endAt) : null];
      if (values.filter(Boolean).length <= 1) {
        return '';
      } else {
        let endAt = values[1];
        if (endAt && (isEqual(startOfDay(endAt), endAt) || getMilliseconds(endAt) === 0)) {
          endAt = addMilliseconds(endAt, -1);
        }
        return [values[0], endAt];
      }
    } else {
      let date = parseDate(value);
      if (isEndDate && date && (isEqual(startOfDay(date), date) || getMilliseconds(date) === 0)) {
        date = addMilliseconds(date, -1);
      }
      return date;
    }
  }, [mode, value, isEndDate]);

  const dateFormatter = useMemo(() => {
    if (asWeekSelect) {
      return "'Semaine' I — MMMM — yyyy";
    } else if (disableTime) {
      return mode !== 'range' && !dateFormat ? dateDayFormat : dateFormat;
    } else {
      return dateTimeFormat;
    }
  }, [mode, asWeekSelect, disableTime, dateFormat]);

  const formattedDate = useMemo(() => {
    return Array.isArray(dateValue)
      ? dateValue.map((date) => printDate(date, dateFormatter)).join(' - ')
      : printDate(dateValue, dateFormatter);
  }, [dateValue, dateFormatter]);

  const options = useMemo(() => {
    if (asMonthSelect)
      return {
        locale: { ...French },
        dateFormatLocale: 'fr',
        plugins: [
          new monthSelect({
            shorthand: true //defaults to false
          })
        ]
      };
    return {
      weekNumbers: true,
      enableTime: !disableTime,
      time_24hr: true,
      locale: { ...French, rangeSeparator: ' - ' },
      formatDate: (date) => printDate(date, dateFormatter),
      dateFormatLocale: 'fr',
      defaultHour: isEndDate ? 23 : 0,
      defaultMinute: isEndDate ? 59 : 0,
      defaultSeconds: isEndDate ? 59 : 0,
      allowInput: false,
      plugins: asWeekSelect ? [new weekSelect({})] : [],
      // minTime: isEndDate ? '11:59:59' : '00:00:00',
      // maxTime: isEndDate ? '23:59:59' : '12:00:00',
      ...props
    };
  }, [props, disableTime, isEndDate, asWeekSelect, asMonthSelect, dateFormatter]);

  const iconLeading = useMemo(
    () =>
      asIcon || collapsedIfBlank ? (
        <GoCalendar
          onClick={() => {
            const input = flatPickerInput(ref);
            if (input) {
              input.focus();
            }
          }}
        />
      ) : null,
    [asIcon, collapsedIfBlank]
  );

  const isCollapsed = collapsedIfBlank && !dateValue && !isOpen;

  const containerStyle = useMemo(
    () => ({
      ...(props.style || {})
      // transition: '0.5s all'
      // maxWidth: isCollapsed ? 48 : '100%'
    }),
    [props.style]
  );

  const iconTrailing = useMemo(
    () => (isClearable && dateValue && !disabled && !readOnly && !asIcon ? <MdClose /> : null),
    [asIcon, isClearable, dateValue, disabled, readOnly]
  );

  const hasIcon = Boolean(iconTrailing);

  const inputRenderer = useCallback(
    ({ formattedDate, collapsedIfBlank, isCollapsed, disabled, ...props }, forwardedRefSetter) => {
      const input = flatPickerInput(ref);
      const measuredWidth = measureInputWidth(input, formattedDate);
      let width = (collapsedIfBlank || fitContent) && measuredWidth && Math.ceil(measuredWidth);

      switch (variant) {
        case 'fill':
        case 'outline':
          width += 50;
          break;
        case 'flat.medium':
          width += collapsedIfBlank ? 34 : hasIcon ? 50 : 34;
          break;
        case 'flat.small':
          width += collapsedIfBlank ? 24 : hasIcon ? 48 : 24;
          break;
        default:
          width += 48;
      }

      if (collapsedIfBlank) {
        width += 38;
      }
      if (asIcon) {
        width = 48;
      }

      return (
        <Input
          style={{
            maxWidth: (collapsedIfBlank && isCollapsed) || asIcon ? 48 : undefined,
            paddingLeft: isCollapsed || asIcon ? 0 : undefined,
            paddingRight: isCollapsed || asIcon ? 0 : isClearable ? 32 : undefined,
            width: isCollapsed || asIcon ? 48 : collapsedIfBlank || fitContent ? width : undefined,
            transition: '0.5s max-width ease',
            cursor: disabled ? 'default' : 'pointer'
          }}
          readOnly={readOnly}
          ref={forwardedRefSetter}
        />
      );
    },
    [variant, fitContent, readOnly, asIcon, hasIcon, isClearable]
  );

  useEffect(() => {
    const input = flatPickerInput(ref);
    if (input) {
      ref.current.forceUpdate();
    }
  }, [formattedDate]);

  React.useImperativeHandle(forwardedRef, () => ({
    flatPickr: () => ref.current && ref.current.flatpickr
  }));

  const handleClick = useCallback(() => {
    const input = flatPickerInput(ref);
    if (input && !(disabled || readOnly)) {
      input.focus();
    }
  }, [disabled, readOnly]);

  return (
    <Wrapper
      style={containerStyle}
      onClick={handleClick}
      readOnly={disabled || readOnly}
      collapsed={isCollapsed}
      asIcon={asIcon}
    >
      <Styles />
      <InputLayout
        ref={ref}
        component={Flatpickr}
        iconLeading={iconLeading}
        iconTrailing={iconTrailing}
        onTrailingIconClick={handleDelete}
        data-enable-time={!disableTime}
        value={dateValue || ''}
        formattedDate={formattedDate}
        disabled={disabled}
        readOnly={readOnly}
        clickOpens={!(readOnly || disabled)}
        onChange={handleChange}
        onClose={handleClose}
        render={inputRenderer}
        options={options}
        focused={isOpen}
        onOpen={handleOpen}
        collapsedIfBlank={collapsedIfBlank}
        fitContent={fitContent}
        isCollapsed={isCollapsed}
        {...props}
        // variant={collapsedIfBlank ? InputLayoutVariant.flat : props.variant}
        label={collapsedIfBlank ? null : props.label}
        hint={collapsedIfBlank ? null : props.hint}
      />
    </Wrapper>
  );
};

export default React.memo(React.forwardRef(DatePicker));

import React from 'react';
import styled from 'styled-components';

export const InlineActionButtonGroup = styled.div`
  display: flex;
  height: 100%;
  border-radius: ${({ theme }) => theme.borderButtonRadius};
  box-shadow: ${({ theme }) => theme.boxShadowSmall};

  > * {
    display: flex;
    height: auto;
    border: 1px solid ${({ theme }) => theme.separator};
    border-radius: ${({ theme }) => theme.borderButtonRadius};
  }
  > *:first-child:not(:last-child) {
    border-radius: ${({ theme }) => theme.borderButtonRadius} 0 0
      ${({ theme }) => theme.borderButtonRadius};
    border-right: none;
  }
  > *:last-child:not(:first-child) {
    border-radius: 0 ${({ theme }) => theme.borderButtonRadius}
      ${({ theme }) => theme.borderButtonRadius} 0;
    flex: 1;
  }
  > *:not(:last-child):not(:first-child) {
    border-right: none;
    border-radius: 0;
  }
  > *:only-child {
    flex: 1;
  }
`;

const ActionGroup = ({ children, ...props }) => {
  return (
    <InlineActionButtonGroup {...props}>{children}</InlineActionButtonGroup>
  );
};

export default ActionGroup;

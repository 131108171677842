import { useEffect, useRef } from 'react';
import { usePrevious } from '.';
import isEqual from 'lodash/isEqual';

const useDeepEffect = (
  runnable: () => void | (() => void | undefined),
  dependencies: Array<any>
) => {
  const runnableRef = useRef(runnable);
  runnableRef.current = runnable;
  const prevDependencies = usePrevious(dependencies);
  useEffect(() => {
    let result: void | (() => void | undefined);
    if (prevDependencies == null || !isEqual(dependencies, prevDependencies)) {
      result = runnableRef.current();
    }
    return () => {
      if (result instanceof Function) {
        result();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...dependencies, prevDependencies, dependencies]);
};

export default useDeepEffect;

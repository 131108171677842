import { createAction } from 'redux-actions';
import omit from 'lodash/omit';
import * as Api from './api';
import { shouldFetch } from 'common/actions';

const groupsLoading = createAction('GROUPS_LOADING');
const groupsSuccess = createAction('GROUPS_SUCCESS');
const groupsError = createAction('GROUPS_ERROR');
export const getGroups = payload => async (dispatch, getState) => {
  const { useCache, ...params } = payload || {};
  const state = getState();
  if (!useCache || shouldFetch('group', params, state)) {
    return fetchGroups(params, dispatch);
  } else {
    return {
      fromCache: true,
      isLoading: getState().groups.isLoading
    };
  }
};
const fetchGroups = async (payload, dispatch) => {
  dispatch(groupsLoading());
  try {
    const result = await Api.getGroups(payload);
    dispatch(
      groupsSuccess({ ...result, requestParams: omit(payload, 'cancelToken') })
    );
    return result;
  } catch (error) {
    dispatch(groupsError(error));
    throw error;
  }
};

const receiveCreateGroup = createAction('NEW_GROUP_SUCCESS');
export const createGroup = ({ payload, ...config }) => async dispatch => {
  const result = await Api.createGroup(payload, config);
  dispatch(receiveCreateGroup(result));
  return result;
};

const receiveGroup = createAction('GROUP_SUCCESS');
export const getGroup = ({ id, ...payload }) => async dispatch => {
  const result = await Api.getGroup(id, payload);
  dispatch(receiveGroup(result));
  return result;
};

const receiveUpdateGroup = createAction('UPDATE_GROUP_SUCCESS');
export const updateGroup = ({
  id,
  payload: group,
  ...config
}) => async dispatch => {
  const result = await Api.putGroup(id, group, config);
  dispatch(receiveUpdateGroup(result));
  return result;
};

const receiveDeleteGroup = createAction('DELETE_GROUP_SUCCESS');
export const deleteGroup = ({ id, ...config }) => async dispatch => {
  const response = await Api.deleteGroup(id, config);
  dispatch(receiveDeleteGroup(id));
  return response;
};

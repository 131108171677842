import { createDeepEqualSelector, getProps } from 'common/selector';
import { parseDate } from 'utils/dates';
import { isWithinInterval } from 'date-fns';
import { uniqBy } from 'lodash';

export const getPeriods = state => state.exceptionalPeriods;

export const selectPeriods = createDeepEqualSelector(
  [getPeriods],
  ({ exceptionalPeriodsIds, exceptionalPeriodById }) =>
    exceptionalPeriodsIds.map(id => exceptionalPeriodById[id]).filter(Boolean)
);

export const selectPeriod = createDeepEqualSelector(
  [getPeriods, getProps],
  ({ exceptionalPeriodById }, { periodId }) => {
    const period = exceptionalPeriodById[periodId];
    if (period) {
      return {
        ...period,
        displayName: period.description
      };
    } else {
      return null;
    }
  }
);

export const selectPeriodsByDate = createDeepEqualSelector(
  [getPeriods, getProps],
  ({ exceptionalPeriodById }, { date }) =>
    uniqBy(
      Object.values(exceptionalPeriodById).filter(period =>
        isWithinInterval(date, {
          start: parseDate(period.startAt),
          end: parseDate(period.endAt)
        })
      ),
      'description'
    )
);

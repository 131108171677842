import React, { useCallback, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import * as Yup from 'yup';
import { EntityModal } from 'components/v3/Modal';
import { TextInputField, AddressField, ERRORS } from 'components/v3/Form';
import { Grid, GridItem, Button } from 'components/v3/ui';
import {
  OrganizationSelector,
  OrganizationAction,
  organizationAsValues
} from 'features/Organizations';
import ContactBox from 'features/Contacts/components/v3/Box';
import { permissions, useAccountConfig } from 'config';

const validationSchema = Yup.object().shape({
  displayName: Yup.string().required(ERRORS.REQUIRED)
});

const OrganizationModal = ({
  onRequestClose,
  onSubmitSuccess,
  organization: propsOrganization,
  ...props
}) => {
  const { hasPermission } = useAccountConfig();
  const dispatch = useDispatch();
  const { params } = useRouteMatch();
  let { organizationId } = params;
  if (propsOrganization) {
    organizationId = propsOrganization.id;
  }
  const isNew = !Boolean(organizationId);

  const selectOrganization = useMemo(
    () => state =>
      OrganizationSelector.selectOrganization(state, { organizationId }),
    [organizationId]
  );

  let organization = useSelector(selectOrganization, isEqual);
  if (!organization) {
    organization = propsOrganization;
  }

  const createRequest = useCallback(
    values =>
      dispatch(OrganizationAction.createOrganization({ payload: values })),
    [dispatch]
  );

  const fetchRequest = useCallback(
    () => dispatch(OrganizationAction.getOrganization({ id: organizationId })),
    [organizationId, dispatch]
  );

  const updateRequest = useCallback(
    (id, values) =>
      dispatch(OrganizationAction.updateOrganization({ id, payload: values })),
    [dispatch]
  );

  const deleteRequest = useCallback(
    id => dispatch(OrganizationAction.deleteOrganization({ id })),
    [dispatch]
  );

  const headerRightContent = useMemo(
    () =>
      organizationId && (
        <Button
          variant="text"
          to={`/administration/organisations/${organizationId}`}
        >
          Voir le détail
        </Button>
      ),
    [organizationId]
  );

  const selectContacts = useMemo(
    () => state =>
      OrganizationSelector.selectOrganizationContacts(state, {
        organizationId
      }),
    [organizationId]
  );

  const canEdit = hasPermission([
    permissions.CRM,
    isNew ? 'organization.create' : 'organization.update'
  ]);

  const canDelete = hasPermission([permissions.CRM, 'organization.delete']);

  return (
    <EntityModal
      isOpen
      onRequestClose={onRequestClose}
      id={organizationId}
      model="organization"
      title={isNew ? 'Nouvelle organisation' : "Édition de l'organisation"}
      headerRightContent={headerRightContent}
      entity={organization}
      validationSchema={validationSchema}
      entityAsValue={organizationAsValues}
      fetchRequest={fetchRequest}
      createRequest={createRequest}
      updateRequest={updateRequest}
      deleteRequest={canDelete ? deleteRequest : undefined}
      onSubmitSuccess={onSubmitSuccess}
      autoSubmit={false}
      readOnly={!canEdit}
      {...props}
    >
      {() => (
        <Grid spacing={16} padded={false}>
          <GridItem width="100%">
            <TextInputField
              name="displayName"
              label="Nom de l'organisation"
              required
              readOnly={!canEdit}
            />
          </GridItem>
          <GridItem width="100%">
            <TextInputField
              name="reference"
              label="Référence"
              readOnly={!canEdit}
            />
          </GridItem>
          <GridItem width="100%">
            <TextInputField name="email" label="Email" readOnly={!canEdit} />
          </GridItem>
          <GridItem width="100%">
            <TextInputField
              name="phone"
              label="Numéro de téléphone"
              readOnly={!canEdit}
            />
          </GridItem>
          <GridItem width="100%">
            <AddressField name="address" label="Adresse" readOnly={!canEdit} />
          </GridItem>
          {organizationId && organization && (
            <GridItem width="100%">
              <ContactBox
                rowColor="transparent"
                selector={selectContacts}
                fetchAction={OrganizationAction.getContacts}
                params={{ organizationId }}
                organization={organization}
                headerVariant="transparent"
                readOnly={!canEdit}
                page="organization"
                minRows={5}
              />
            </GridItem>
          )}
        </Grid>
      )}
    </EntityModal>
  );
};

export default OrganizationModal;

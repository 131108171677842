import React, { Fragment, useEffect, useCallback } from 'react';
import {
  useRouteMatch,
  Route,
  useHistory,
  useLocation
} from 'react-router-dom';
import { Text } from 'components/v3/ui';
import Table from 'components/v3/List/Table';
import Tag from 'components/v3/Tag';
import { useListFetcher } from 'components/v3/utils';
import ServiceModal from './ServiceModal';

const columns = [
  {
    Header: 'Nom',
    accessor: 'displayName',
    width: '40%',
    Cell: ({ item }) => <Text variant="small">{item.displayName}</Text>
  },
  {
    Header: 'Type de projet',
    accessor: 'projectKind.displayName',
    width: '20%',
    Cell: ({ item }) => {
      const color = item.projectKind?.color;
      const name = item.projectKind?.displayName;
      if (color && name) {
        return <Tag displayName={name} color={color} />;
      } else {
        return <Text variant="small">{name}</Text>;
      }
    }
  },
  {
    Header: 'Référence',
    accessor: 'reference',
    width: '17.5%',
    Cell: ({ item }) => (
      <Text variant="caption" color="theme">
        {item.reference}
      </Text>
    )
  },
  {
    Header: 'Unité',
    accessor: 'unit',
    width: '10%',
    Cell: ({ item }) => <Text variant="small">{item.unit?.displayName}</Text>
  },
  {
    Header: 'P.U',
    accessor: 'price',
    width: '12.5%',
    textAlign: 'end',
    Cell: ({ item }) => (
      <Text currency variant="small">
        {item.price}
      </Text>
    )
  }
];

const ServiceList = ({
  selector,
  action,
  options = {
    fetchOnMount: false,
    useLocalState: true
  },
  params = {
    sort: 'display_name'
  },
  perPage = 25,
  filters,
  onCountChanged,
  detailUrlPrefix = '/services',
  ...props
}) => {
  const { path, url } = useRouteMatch();
  const history = useHistory();
  const location = useLocation();

  const [services, paginationHelper, state] = useListFetcher({
    selector,
    action,
    options,
    params,
    perPage,
    filters
  });

  const detailLinkFormatter = useCallback(
    item => `${path}${detailUrlPrefix}/${item.id}`,
    [path, detailUrlPrefix]
  );

  useEffect(() => {
    if (paginationHelper.total != null && onCountChanged) {
      onCountChanged(paginationHelper.total);
    }
  }, [paginationHelper.total, onCountChanged]);

  return (
    <Fragment>
      <Table
        columns={columns}
        items={services}
        isLoading={state.isLoading}
        linkFormatter={detailLinkFormatter}
        centerMessage={state.errorMessage}
        {...paginationHelper}
        {...props}
      />
      <Route
        path={[
          `${path}${detailUrlPrefix}/nouveau-service`,
          `${path}${detailUrlPrefix}/:serviceId`
        ]}
      >
        <ServiceModal
          onRequestClose={() => {
            if (location.key) {
              history.goBack();
            } else {
              history.replace(url);
            }
          }}
        />
      </Route>
    </Fragment>
  );
};

export default ServiceList;

import { createAction } from 'redux-actions';
import * as Api from './api';

export const logout = createAction('LOGOUT');

const receiveLogin = createAction('LOGIN_SUCCESS');

export const login = payload => async dispatch => {
  const response = await Api.login(payload);
  const { accessToken, refreshToken } = response;
  localStorage.setItem('accessToken', accessToken);
  localStorage.setItem('refreshToken', refreshToken);
  dispatch(receiveLogin());
  return response;
};

export const sendForgottenPasswordEmail = payload => async dispatch => {
  return await Api.sendForgottenPasswordEmail(payload);
};

export const acceptInvite = payload => async dispatch => {
  const { email, password } = payload;
  await Api.acceptInvite(payload);
  if (!email) {
    return 'accepted';
  }
  await login({ email, password })(dispatch);
  return 'logged';
};

export const resetPassword = payload => async dispatch => {
  const { email, password } = payload;
  await Api.resetPassword(payload);
  if (!email) {
    return 'reset';
  }
  await login({ email, password })(dispatch);
  return 'logged';
};

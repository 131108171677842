import React, { Fragment, useEffect, useMemo, useCallback } from 'react';
import {
  useRouteMatch,
  Route,
  useHistory,
  useLocation
} from 'react-router-dom';
import { Text } from 'components/v3/ui';
import Table from 'components/v3/List/Table';
import { useListFetcher } from 'components/v3/utils';
import ContactModal from './Modal';

const pageVariant = {
  organization: {
    firstName: '20%',
    lastName: '20%',
    position: '30%',
    email: '30%'
  },
  default: {
    firstName: '15%',
    lastName: '15%',
    position: '20%',
    email: '25%',
    organization: '25%'
  }
};

const COLUMNS = [
  {
    Header: 'Prénom',
    accessor: 'firstName',
    width: pageVariant.default.firstName,
    Cell: ({ item }) => <Text variant="small">{item.firstName}</Text>
  },
  {
    Header: 'Nom',
    accessor: 'lastName',
    width: pageVariant.default.lastName,
    Cell: ({ item }) => <Text variant="small">{item.lastName}</Text>
  },
  {
    Header: 'Poste',
    accessor: 'position',
    width: pageVariant.default.position,
    Cell: ({ item }) => (
      <Text variant="caption" color="theme">
        {item.position}
      </Text>
    )
  },
  {
    Header: 'Email',
    accessor: 'email',
    width: pageVariant.default.email,
    Cell: ({ item }) => <Text variant="small">{item.email}</Text>
  },
  {
    Header: 'Organisation',
    accessor: 'organization',
    width: pageVariant.default.organization,
    Cell: ({ item }) => (
      <Text variant="small">{item.organization?.displayName}</Text>
    )
  }
];

const ContactList = ({
  columns = COLUMNS,
  selector,
  action,
  options = {
    fetchOnMount: false,
    useLocalState: true
  },
  perPage = 25,
  params = {
    sort: '-created_at'
  },
  filters,
  page = 'default',
  organization,
  onCountChanged,
  detailUrlPrefix = '/contacts',
  linkFormatter,
  ...props
}) => {
  const { path, url } = useRouteMatch();
  const history = useHistory();
  const location = useLocation();

  const [contacts, paginationHelper, state] = useListFetcher({
    selector,
    action,
    options,
    params,
    perPage,
    filters
  });

  const detailLinkFormatter = useCallback(
    item => `${url}${detailUrlPrefix}/${item.id}`,
    [url, detailUrlPrefix]
  );

  const memoColumns = useMemo(() => {
    const variant = pageVariant[page] || pageVariant.default;
    return columns
      .filter(column => Boolean(variant[column.accessor]))
      .map(column => ({
        ...column,
        width: variant[column.accessor]
      }));
  }, [columns, page]);

  useEffect(() => {
    if (paginationHelper.total != null && onCountChanged) {
      onCountChanged(paginationHelper.total);
    }
  }, [paginationHelper.total, onCountChanged]);

  return (
    <Fragment>
      <Table
        columns={memoColumns}
        items={contacts}
        isLoading={state.isLoading}
        linkFormatter={detailLinkFormatter}
        centerMessage={state.errorMessage}
        {...paginationHelper}
        {...props}
      />
      <Route
        path={[
          `${path}${detailUrlPrefix}/nouveau-contact`,
          `${path}${detailUrlPrefix}/:contactId`
        ]}
      >
        <ContactModal
          onRequestClose={() => {
            if (location.key) {
              history.goBack();
            } else {
              history.replace(url);
            }
          }}
          organization={organization}
        />
      </Route>
    </Fragment>
  );
};

export default ContactList;

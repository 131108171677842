import { mergeJsonApi } from 'common/reducers';

const initialState = {
  localizationById: {},
  localizationsIds: []
};

const localizations = (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case 'LOGOUT':
    case 'SELECT_ACCOUNT':
      return initialState;
    default:
      return mergeJsonApi('localization', state, action);
  }
};

export default localizations;

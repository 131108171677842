import { v4 } from 'uuid';

export const createSeparator = (position: number) => ({
  id: v4(),
  isNew: true,
  kind: 'divider',
  description: '',
  position
});

export const createLine = (position: number, group: any, initialData = {}) => ({
  serviceId: null,
  displayName: '',
  description: '',
  quantity: 1,
  unit: null,
  unitPrice: 0,
  total: 0,
  taxRate: group?.displayName === 'Bâtiment' ? 0.1 : 0.2,
  kind: 'financial',
  ...initialData,
  id: v4(),
  isNew: true,
  position
});

import React, {
  useCallback,
  useMemo,
  Fragment,
  useState,
  useEffect
} from 'react';
import { SelectField } from 'components/v3/Form';
import { SelectInputLayout } from 'components/v3/InputLayout';
import { useRedux } from 'components/v3/utils';
import { jsonApiToObject } from 'common/api';
import ContactModal from './Modal';
import {
  ContactSelector,
  ContactAction,
  contactAsOption
} from 'features/Contacts';
import { permissions, useAccountConfig } from 'config';

const formatCreateLabel = input => `Créer un contact: ${input}`;

const contactOptionsFormatter = response => {
  return (jsonApiToObject(response) || []).map(contactAsOption);
};

const ContactSelect = ({ value, onChange, organizationId, ...props }) => {
  const { accountId, hasPermission } = useAccountConfig();
  const [contactModal, setContactModal] = useState();
  const selectSearchContacts = useMemo(
    () => state =>
      ContactSelector.selectSearchContacts(state, { organizationId }),
    [organizationId]
  );

  const [contacts, fetch] = useRedux(
    selectSearchContacts,
    ContactAction.getContacts,
    {
      fetchOnMount: false,
      useLocalState: false,
      initialParams: {
        page: 1,
        perPage: 50,
        sort: 'first_name,last_name,position',
        useCache: true,
        isSearchRequest: true
      }
    }
  );

  const handleCreateOption = useCallback(input => {
    setContactModal({
      firstName: input
    });
  }, []);

  const handleEditOption = useCallback(value => {
    setContactModal(value);
  }, []);

  const handleSearch = useCallback(
    ({ query }) =>
      new Promise((resolve, reject) => {
        fetch(params => ({
          ...params,
          organizationIdEq: organizationId,
          organizationId,
          firstNameOrLastNameOrEmailContAny: query,
          page: 1,
          useCache: false
        }))
          .onError(reject)
          .onSuccess(res => resolve(contactOptionsFormatter(res)));
      }),
    [fetch, organizationId]
  );

  const options = useMemo(() => contactAsOption(contacts), [contacts]);

  const currentValue = useMemo(() => contactAsOption(value), [value]);

  useEffect(() => {
    fetch(params => ({
      ...params,
      firstNameOrLastNameOrEmailContAny: undefined,
      organizationIdEq: organizationId,
      organizationId,
      page: 1,
      useCache: true
    }));
  }, [fetch, organizationId, accountId]);

  return (
    <Fragment>
      <SelectInputLayout
        label="Contact"
        value={currentValue}
        options={options}
        isSearchable
        loadAsyncOptions={handleSearch}
        filterOptions={false}
        onChange={onChange}
        inputCreatable={hasPermission([permissions.CRM, 'contact.create'])}
        createLabel="Créer un contact"
        formatCreateLabel={formatCreateLabel}
        onCreateOption={handleCreateOption}
        optionEditable={hasPermission([permissions.CRM, 'contact.update'])}
        onEditOptionClick={handleEditOption}
        {...props}
      />
      {contactModal && (
        <ContactModal
          organizationId={organizationId}
          contact={contactModal}
          onRequestClose={() => setContactModal(null)}
          onSubmitSuccess={response => {
            const contact = jsonApiToObject(response);
            if (props.multi) {
              onChange([...currentValue, contact]);
            } else {
              onChange(contact);
            }
          }}
        />
      )}
    </Fragment>
  );
};

export const ContactField = props => {
  return <SelectField component={ContactSelect} {...props} />;
};

export default ContactSelect;

import React from 'react';
import styled from 'styled-components';
import { fontSize } from 'ui';
import {
  MdCheckBox,
  MdIndeterminateCheckBox,
  MdCheckBoxOutlineBlank
} from 'react-icons/md';
import Tooltip from '../Tooltip';

const Size = {
  giant: 40,
  large: 32,
  medium: 24,
  small: 20,
  tiny: 16
};

const CheckboxWrapper = styled.div`
  display: flex;
  pointer-events: none;
`;

const Wrapper = styled.div`
  display: inline-flex;
  cursor: pointer;
  padding: 8px 16px 8px 12px;
  align-items: center;
  cursor: pointer;

  > svg {
    transition: transform 0.2s ease;
    min-width: 18px;
    min-height: 18px;
  }
  &:active > svg {
    transform: scale3d(0.9, 0.9, 1);
  }

  > input:checked + div {
    .checked {
      display: initial;
    }
    .unchecked {
      display: none;
    }
    .indeterminate {
      display: none;
    }
    path {
      fill: ${({ color, theme }) => color || theme.primaryLight};
    }
  }

  > input:not(:checked) + div {
    .indeterminate {
      display: none;
    }
    .checked {
      display: none;
    }
    svg {
      fill: ${({ color, theme }) => color || theme.primaryLight};
    }
  }

  > input[value='indeterminate'] + div,
  > input:indeterminate + div,
  > input:checked:indeterminate + div {
    .unchecked {
      display: none;
    }
    .checked {
      display: none;
    }
    .indeterminate {
      display: initial;
    }
    path {
      fill: ${({ color, theme }) => color || theme.primaryLight};
    }
  }

  &[readOnly] {
    cursor: default;
  }
  > input:read-only + div {
    cursor: default;
  }
  &[disabled] {
    cursor: not-allowed;
  }
  > input:disabled + div {
    cursor: not-allowed;
    &:active {
      transform: none;
    }
    path {
      fill: ${({ theme }) => theme.disabled};
    }
  }
  > label {
    font-size: 0.875rem;
  }
`;

const Input = styled.input`
  width: 0px;
  height: 0px;
  display: none;
`;

const Label = styled.label`
  color: ${({ theme }) => theme.textSecondary};
  font-weight: 400;
  font-size: ${fontSize(16)};
  margin-left: 12px;
  cursor: pointer;
`;

export default React.memo(
  ({
    size,
    color,
    indeterminate,
    label,
    disabled,
    readOnly,
    onChange,
    value,
    tooltip,
    ...props
  }) => {
    const handleClick = e => {
      if (readOnly || disabled) {
        return;
      }
      e.preventDefault();
      e.stopPropagation();
      if (indeterminate) {
        switch (value) {
          case 'true':
            return onChange('indeterminate');
          case 'indeterminate':
            return onChange('false');
          default:
            return onChange('true');
        }
      } else {
        onChange(!value);
      }
    };
    return (
      <Tooltip content={tooltip}>
        <Wrapper
          {...props}
          disabled={disabled}
          onClick={disabled ? undefined : handleClick}
          color={color}
        >
          <Input
            type="checkbox"
            value={value || ''}
            disabled={disabled}
            checked={value === 'true' || value === true}
            readOnly
            indeterminate={value === 'indeterminate'}
          />
          <CheckboxWrapper>
            <MdCheckBox className="checked" size={size || Size.medium} />
            <MdIndeterminateCheckBox
              className="indeterminate"
              size={size || Size.medium}
            />
            <MdCheckBoxOutlineBlank
              className="unchecked"
              size={size || Size.medium}
            />
          </CheckboxWrapper>
          {label && (
            <Label className="input-checkbox" htmlFor={props.name}>
              {label}
            </Label>
          )}
        </Wrapper>
      </Tooltip>
    );
  }
);

import React, {
  useCallback,
  useMemo,
  Fragment,
  useState,
  useEffect
} from 'react';
import { MdArrowDropDown } from 'react-icons/md';
import { SelectField } from 'components/v3/Form';
import {
  SelectInputLayout,
  InputLayoutVariant
} from 'components/v3/InputLayout';
import Tag from 'components/v3/Tag';
import { useRedux } from 'components/v3/utils';
import GroupModal from './Modal';
import { jsonApiToObject } from 'common/api';
import { GroupSelector, GroupAction, groupAsOption } from 'features/Groups';
import { permissions, useAccountConfig } from 'config';

const formatCreateLabel = input => `Créer le groupe ${input}`;

const GroupSelect = ({
  value,
  onChange,
  size,
  asTag = false,
  readOnly,
  ...props
}) => {
  const { accountId, hasPermission } = useAccountConfig();
  const [groupModal, setGroupModal] = useState();
  const [groups, fetch] = useRedux(
    GroupSelector.selectGroups,
    GroupAction.getGroups,
    {
      fetchOnMount: false,
      useLocalState: true,
      initialParams: {
        page: 1,
        perPage: 500,
        useCache: true
      }
    }
  );

  const handleCreateOption = useCallback(input => {
    setGroupModal({
      displayName: input
    });
  }, []);

  const handleEditOption = useCallback(value => {
    setGroupModal(value);
  }, []);

  const options = useMemo(() => groupAsOption(groups), [groups]);

  const safeValue = useMemo(
    () =>
      value ||
      (asTag
        ? {
            id: -1,
            displayName: 'Groupe',
            color: '#8A8A8D'
          }
        : value),
    [value, asTag]
  );

  const currentValue = useMemo(() => groupAsOption(safeValue), [safeValue]);

  const currentOptionRenderer = useCallback(
    ({ option }) => (
      <Tag
        displayName={option.label}
        color={option.color}
        trailingIcon={readOnly ? null : MdArrowDropDown}
        clickable={!readOnly}
        size={size}
      />
    ),
    [size, readOnly]
  );

  useEffect(() => {
    fetch(params => params);
  }, [fetch, accountId]);

  return (
    <Fragment>
      <SelectInputLayout
        label={asTag ? null : 'Groupe'}
        isClearable={!asTag}
        showIndicator={!asTag}
        value={currentValue}
        options={options}
        isSearchable={asTag ? false : undefined}
        onChange={onChange}
        inputCreatable={hasPermission([
          permissions.RESOURCES_CONFIG,
          'group.create'
        ])}
        createLabel="Créer un groupe"
        formatCreateLabel={formatCreateLabel}
        onCreateOption={handleCreateOption}
        optionEditable={
          !asTag &&
          hasPermission([permissions.RESOURCES_CONFIG, 'group.update'])
        }
        onEditOptionClick={handleEditOption}
        currentOptionRenderer={asTag ? currentOptionRenderer : undefined}
        variant={asTag ? InputLayoutVariant.flat.medium : undefined}
        readOnly={readOnly}
        {...props}
      />
      {groupModal && (
        <GroupModal
          group={groupModal}
          onRequestClose={() => setGroupModal(null)}
          onSubmitSuccess={response => {
            const group = jsonApiToObject(response);
            if (props.multi) {
              onChange([...currentValue, group]);
            } else {
              onChange(group);
            }
          }}
        />
      )}
    </Fragment>
  );
};

export const GroupField = props => {
  return <SelectField component={GroupSelect} {...props} />;
};

export default GroupSelect;

import React from 'react';
import styled, { css } from 'styled-components';
import { SectionTitle, Text } from '../ui';
import { spacing } from 'ui';

export const Wrapper = styled.div`
  display: inline-flex;
  align-items: center;
  width: 100%;
  padding: ${spacing(1)};
  // background-color: ${({ theme }) => theme.backgroundSection};
  justify-content: space-between;

  > *:not(:first-child) {
    margin: 0px 0px 0px 24px;
  }

  ${({ bigPadded }) =>
    bigPadded &&
    css`
      padding: ${spacing(1.5)};
      padding-bottom: ${spacing()};
    `}
`;

const BoxTitle = styled(SectionTitle)``;

const BoxHeader = ({ title, subtitle, children, ...props }) => {
  return (
    <Wrapper {...props}>
      <div>
        <BoxTitle>{title}</BoxTitle>
        {subtitle && (
          <Text variant="hint" color="secondary">
            {subtitle}
          </Text>
        )}
      </div>
      {children}
    </Wrapper>
  );
};

export default BoxHeader;

import React, { useState, useCallback } from 'react';
import { useDelay, useDeepEffect } from 'components/v3/utils';
import Portal from 'components/v3/Portal';

const SHOW_DELAY = 650;
const HIDE_DELAY = 350;

const Style = { maxHeight: '90vh', overflow: 'auto', padding: 10 };

const EventTooltip = ({
  event: propsEvent,
  bounds: propsBounds,
  tooltipContent
}) => {
  const [state, setState] = useState({});
  const showDelay = useDelay();
  const hideDelay = useDelay();
  const { event, bounds } = state;
  const isVisible = Boolean(event?.id) && Boolean(bounds);

  useDeepEffect(() => {
    if (!propsEvent || !propsBounds) {
      showDelay.cancel();
      hideDelay(
        () =>
          setState(prev => {
            if (prev.isInTooltip) {
              return prev;
            } else {
              return {};
            }
          }),
        HIDE_DELAY
      );
    } else {
      hideDelay.cancel();
      showDelay(
        () =>
          setState({
            event: propsEvent,
            bounds: propsBounds
          }),
        isVisible ? 0 : SHOW_DELAY
      );
    }
  }, [isVisible, showDelay, hideDelay, propsEvent, propsBounds]);

  const handleMouseEnter = useCallback(() => {
    setState(prev => ({ ...prev, isInTooltip: true }));
  }, []);

  const handleMouseLeave = useCallback(() => {
    setState(prev => ({ ...prev, isInTooltip: false }));
    hideDelay(() => setState({}), HIDE_DELAY);
  }, [hideDelay]);

  if (!isVisible) {
    return null;
  }
  return (
    <Portal
      rootElement={
        document.fullscreenElement
          ? document.querySelector('.fc-media-screen')
          : null
      }
      bounds={bounds}
      top={5}
      alignTop
      maxWidth={350}
      zIndex={999}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={Style}
    >
      {tooltipContent({ event })}
    </Portal>
  );
};

export default EventTooltip;

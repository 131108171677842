import React, { useCallback, useEffect, Fragment, useRef } from 'react';
import styled from 'styled-components';
import {
  Formik,
  Form as FormikForm,
  FormikConfig,
  FormikValues,
  FormikProps,
  FormikHelpers
} from 'formik';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import { useAction, usePrevious, useDeepEffect } from '../utils';
import pick from 'lodash/pick';
import useNamingRules from './useNamingRules';

// https://github.com/kimamula/ts-transformer-keys

export const FormWrapper = styled(FormikForm)`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
`;

export declare type FormikBag = FormikHelpers<FormikValues> &
  FormikProps<FormikValues>;

interface Dependency {
  modifier: (
    values: FormikValues,
    form: FormikProps<FormikValues>
  ) => FormikValues;
  triggers: Array<string>;
}

interface Dependencies {
  [field: string]: Dependency;
}

interface Props {
  onValuesChanged?: (
    values: FormikValues,
    form: FormikProps<FormikValues>
  ) => void;
  valuesDependencies?: Dependencies;
  autoSubmit?: boolean;
  autoSubmitDelay?: number;
  readOnly?: boolean;
}

export interface FormChildrenProps {
  children: (props: FormikProps<FormikValues>) => React.ReactNode;
}

export interface FormProps extends FormikConfig<FormikValues>, Props {
  resetDependencies?: Array<any>;
  formikBagRef?: React.Ref<FormikBag | undefined>;
  wrapInForm?: boolean;
  model?: string;
  id?: any;
  canTriggerNamingRules?: boolean | ((values: FormikValues) => boolean);
}

interface FormContentProps {
  model?: string;
  id?: any;
  canTriggerNamingRules?: boolean | ((props: FormikValues) => boolean);
  form: FormikProps<FormikValues>;
  autoSubmit?: boolean;
  render: (values: FormikProps<FormikValues>) => React.ReactNode;
}

interface ValuesWatcherProps extends Props {
  form: FormikProps<FormikValues>;
}

interface ValueModifierProps {
  form: FormikProps<FormikValues>;
  valueKey: string;
  dependency: Dependency;
  readOnly?: boolean;
}

const ValueModifier: React.FunctionComponent<ValueModifierProps> = ({
  form,
  valueKey,
  dependency,
  readOnly
}) => {
  const formRef = useRef(form);
  formRef.current = form;

  const triggers = pick(form.values, dependency.triggers);

  useDeepEffect(() => {
    if (readOnly || !formRef.current.dirty) {
      return;
    }
    // let handler: number;
    // handler = setTimeout(() => {
    const value = form.values[valueKey];
    const newValue = dependency.modifier(form.values, form);
    if (!isEqual(value, newValue)) {
      form.setFieldValue(valueKey, newValue);
    }
    // }, 0);
    // return () => {
    // if (handler !== undefined) {
    //   console.log('cancel value watcher');
    //   clearTimeout(handler);
    // }
    // };
  }, [readOnly, dependency, triggers]);

  return null;
};

const ValuesWatcher: React.FunctionComponent<ValuesWatcherProps> = ({
  form,
  onValuesChanged,
  autoSubmitDelay,
  readOnly
}) => {
  const { values, dirty, submitForm, validateForm } = form;
  const action = useAction();
  const formRef = useRef(form);
  const prevValues = usePrevious(values);
  const valuesChanged = !isEqual(prevValues, values);
  formRef.current = form;

  useEffect(() => {
    let handler: number;
    if (!readOnly && dirty && valuesChanged) {
      handler = window.setTimeout(() => {
        if (onValuesChanged) {
          onValuesChanged(values, formRef.current);
        }
      }, 0);
    }
    return () => {
      if (handler !== undefined) {
        window.clearTimeout(handler);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dirty, values, onValuesChanged]);

  useEffect(() => {
    let handler: number;
    let canceled = false;
    if (!readOnly && dirty && valuesChanged) {
      action(() => validateForm(), 'submit', true).onSuccess(result => {
        if (!canceled && isEmpty(result)) {
          handler = window.setTimeout(submitForm, autoSubmitDelay);
        }
      });
    }
    return () => {
      canceled = true;
      if (handler !== undefined) {
        window.clearTimeout(handler);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dirty, readOnly, autoSubmitDelay, values, submitForm, validateForm]);
  return null;
};

const FormContent: React.FunctionComponent<FormContentProps> = ({
  id,
  form,
  render,
  model,
  canTriggerNamingRules,
  autoSubmit
}) => {
  const { dirty, values, resetForm, setFieldValue, setSubmitting } = form;
  const valuesRef = useRef(values);
  valuesRef.current = values;

  const handleNamingRuleValueChanged = useCallback(
    (name: string, value: any | undefined | null) => {
      setFieldValue(name, value, false);
      if (!autoSubmit) {
        resetForm({
          values: {
            ...valuesRef.current,
            name: value
          }
        });
      }
    },
    [setFieldValue, autoSubmit, resetForm]
  );

  useNamingRules({
    id,
    model,
    values,
    modified: dirty,
    canTriggerNamingRules,
    onFetchingValue: setSubmitting,
    onValueChanged: handleNamingRuleValueChanged
  });

  return <Fragment>{render(form)}</Fragment>;
};

const Form: React.FunctionComponent<FormProps & FormChildrenProps> = ({
  id,
  resetDependencies,
  formikBagRef,
  wrapInForm,
  autoSubmit,
  autoSubmitDelay,
  onSubmit,
  onValuesChanged,
  valuesDependencies,
  children,
  model,
  canTriggerNamingRules,
  readOnly,
  ...formikProps
}) => {
  const bag = useRef<FormikBag>();
  const initialized = useRef<boolean>(false);

  const formikBagSetter = useCallback(
    (ref: FormikBag) => {
      if (formikBagRef) {
        if (formikBagRef instanceof Function) {
          formikBagRef(ref as any);
        } else {
          (formikBagRef as React.MutableRefObject<FormikBag>).current = ref;
        }
      }
      bag.current = ref;
    },
    [formikBagRef]
  );

  useEffect(() => {
    if (initialized.current && bag.current) {
      bag.current.resetForm();
    } else {
      initialized.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...(resetDependencies || [])]);

  const handleSubmit = useCallback(
    (values, bag) => {
      // c'est juste pour montrer tout ce qu'il y a dans le bag
      /* eslint-disable @typescript-eslint/no-unused-vars */
      const {
        resetForm,
        setError,
        setErrors,
        setFieldError,
        setFieldTouched,
        setFieldValue,
        setFormikState,
        setStatus,
        setSubmitting,
        setTouched,
        setValues,
        submitForm,
        validateField,
        validateForm
      } = bag;
      /* eslint-enable @typescript-eslint/no-unused-vars */
      return onSubmit(values, bag);
    },
    [onSubmit]
  );

  return (
    <Formik {...formikProps} innerRef={formikBagSetter} onSubmit={handleSubmit}>
      {form => {
        return (
          <Fragment>
            {wrapInForm ? (
              <FormWrapper>
                <FormContent
                  id={id}
                  model={model}
                  canTriggerNamingRules={canTriggerNamingRules}
                  form={form}
                  render={children}
                  autoSubmit={autoSubmit}
                />
              </FormWrapper>
            ) : (
              <FormContent
                id={id}
                model={model}
                canTriggerNamingRules={canTriggerNamingRules}
                form={form}
                render={children}
                autoSubmit={autoSubmit}
              />
            )}
            {(autoSubmit || onValuesChanged) && (
              <ValuesWatcher
                form={form}
                autoSubmitDelay={autoSubmitDelay}
                onValuesChanged={onValuesChanged}
                readOnly={readOnly}
              />
            )}
            {valuesDependencies &&
              Object.keys(valuesDependencies).map(key => {
                return (
                  <ValueModifier
                    key={key}
                    valueKey={key}
                    dependency={valuesDependencies[key]}
                    form={form}
                    readOnly={readOnly}
                  />
                );
              })}
          </Fragment>
        );
      }}
    </Formik>
  );
};

Form.defaultProps = {
  wrapInForm: true,
  autoSubmit: false,
  autoSubmitDelay: 750
};

export default Form;

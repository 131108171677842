import { MeApi, MeAction, MeSelector } from './data';

export { MeApi, MeAction, MeSelector };

export const meAsValue = user => ({
  avatarUrl: user?.avatarUrl,
  firstName: user?.firstName || '',
  lastName: user?.lastName || '',
  currentPassword: '',
  password: '',
  passwordConfirmation: ''
});

import React, { useEffect, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import Uppy from '@uppy/core';
import { DragDrop } from '@uppy/react';
import '@uppy/core/dist/style.css';
import '@uppy/drag-drop/dist/style.css';
import { EntityForm, TextInputField, PasswordField } from 'components/v3/Form';
import { Grid, GridItem, Text, SectionTitle } from 'components/v3/ui';
import { MeSelector, MeAction, meAsValue } from 'features/Me';
import PasswordStrength from 'components/v3/PasswordStrength';
import { CardButtons as RawCardButtons } from 'components/v3/ui/card';
import { useHistory } from 'react-router';

const CardButtons = styled.div`
  margin-top: 16px;
`.withComponent(RawCardButtons);

const AvatarWrapper = styled.div`
  position: relative;
  display: flex;
  max-width: 282px;
  min-width: 232px;
  height: 182px;

  > div {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  * > .uppy-DragDrop-container {
    background: transparent;
    border-radius: ${({ theme }) => theme.borderInputRadius};
    border-color: ${({ theme }) => theme.borderInputColor};

    > .uppy-DragDrop-inner {
      padding: 0px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: none;
      align-items: center;
      justify-content: center;
      svg {
        fill: ${({ theme }) => theme.primaryLight};
      }
      > .uppy-DragDrop-label {
        display: none;
      }
    }
  }

  * > .uppy-DragDrop--isDraggingOver {
    border-color: ${({ theme }) => theme.primaryLight};
    > .uppy-DragDrop-inner {
      display: flex;
    }
  }

  * > .uppy-DragDrop-container {
    background: transparent;
  }

  * > .uppy-DragDrop--isDraggingOver {
    > .uppy-DragDrop-inner {
      background-color: rgba(255, 255, 255, 0.56);
    }
  }
`;

const Logo = styled.img`
  height: 150px;
  width: 100%;
  padding: 8px;
  object-fit: contain;
  margin: auto;
  display: none;
  &[src] {
    display: block;
  }
`;

const EditableLogoWrapper = styled.div`
  max-width: 250px;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  > span {
    padding-top: 4px;
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const AvatarPicker = ({ url, onChange }) => {
  const uppyRef = useRef();
  const onChangeRef = useRef();
  onChangeRef.current = onChange;

  useEffect(() => {
    uppyRef.current = Uppy({
      debug: true,
      meta: { type: 'avatar' },
      allowMultipleUploads: true,
      restrictions: {
        allowedFileTypes: ['image/*']
      },
      replaceTargetContent: true,
      autoProceed: false
    })
      .on('file-added', file => {
        if (onChangeRef.current) {
          onChangeRef.current(file.data);
        }
      })
      .on('upload-error', (file, error) => {})
      .on('complete', result => {});

    return () => {
      uppyRef.current.close();
    };
  }, []);

  return (
    <EditableLogoWrapper>
      <AvatarWrapper>
        <Logo src={url} alt="" />
        {uppyRef.current && (
          <DragDrop uppy={uppyRef.current} target="body" locale={null} />
        )}
      </AvatarWrapper>
      <Text variant="hint" color="hint">
        Cliquez ou glissez une image pour modifier le logo du compte
      </Text>
    </EditableLogoWrapper>
  );
};

const Profile = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const user = useSelector(MeSelector.selectMe);

  const fetchRequest = useCallback(
    () => dispatch(MeAction.getMe()),
    [dispatch]
  );

  const updateRequest = useCallback(
    (_, values) => dispatch(MeAction.updateMe(values)),
    [dispatch]
  );

  const handleButtonClick = ({ name }) => {
    if (name === 'negative') {
      history.goBack();
    }
  };

  return (
    <EntityForm
      id={user?.id}
      entity={user}
      fetchRequest={fetchRequest}
      updateRequest={updateRequest}
      entityAsValue={meAsValue}
      autoSubmit={false}
    >
      {({ setFieldValue, values, isSubmitting }) => {
        return (
          <Grid spacing={16}>
            <GridItem alignCenter width="100%">
              <AvatarPicker
                url={
                  (values.avatar && URL.createObjectURL(values.avatar)) ||
                  values.avatarUrl
                }
                onChange={file => setFieldValue('avatar', file)}
              />
            </GridItem>
            <GridItem width="100%">
              <SectionTitle>Informations générales</SectionTitle>
            </GridItem>
            <GridItem width="100%">
              <TextInputField name="firstName" label="Votre prénom" />
            </GridItem>
            <GridItem width="100%">
              <TextInputField name="lastName" label="Votre nom" />
            </GridItem>
            <GridItem width="100%">
              <SectionTitle>Modifier mon mot de passe</SectionTitle>
            </GridItem>
            <GridItem width="100%">
              <PasswordField
                hint="Indiquez votre mot de passe actuel afin de pouvoir le modifier."
                label="Mot de passe actuel"
                name="currentPassword"
              />
            </GridItem>
            <GridItem width="100%" style={{ marginTop: 16 }}>
              <PasswordField
                hint="Pour un maximum de sécurité, choisissez un mot de passe fort, en suivant notre indicateur."
                label="Nouveau mot de passe"
                name="password"
              />
              <PasswordStrength password={values.password} />
            </GridItem>
            <GridItem width="100%">
              <PasswordField
                hint="Répétez votre nouveau mot de passe pour confirmer le changement."
                label="Confirmer le mot de passe"
                name="passwordConfirmation"
              />
            </GridItem>
            <GridItem width="100%">
              <CardButtons
                center
                onButtonClick={handleButtonClick}
                buttonNameLoading={isSubmitting ? 'positive' : null}
                positiveText="Enregistrer"
                negativeText="Annuler"
              />
            </GridItem>
          </Grid>
        );
      }}
    </EntityForm>
  );
};

export default Profile;

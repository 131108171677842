import { get, put, post, destroy } from 'common/api';

export const getExtractionDestinations = params =>
  get('/v5/extraction_destinations', {
    ...(params || {}),
    sort: (params || {}).sort ? params.sort : 'name'
  });

export const createExtractionDestination = (extractionDestination, config) =>
  post('/v5/extraction_destinations', { extractionDestination }, config);

export const getExtractionDestination = (id, config) =>
  get(`/v5/extraction_destinations/${id}`, config);

export const putExtractionDestination = (id, extractionDestination, config) =>
  put(`/v5/extraction_destinations/${id}`, { extractionDestination }, config);

export const deleteExtractionDestination = (id, config) =>
  destroy(`/v5/extraction_destinations/${id}`, config);

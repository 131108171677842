import React from 'react';
import PropTypes from 'prop-types';
import throttle from 'lodash/throttle';
import { canUseDOM } from 'exenv';
import PortalWrapper from './PortalWrapper';

const listeners = {};

function fireListeners() {
  Object.keys(listeners).forEach(key => listeners[key]());
}

function getPageOffset() {
  return {
    x:
      window.pageXOffset !== undefined
        ? window.pageXOffset
        : (
            document.documentElement ||
            document.body.parentNode ||
            document.body
          ).scrollLeft,
    y:
      window.pageYOffset !== undefined
        ? window.pageYOffset
        : (
            document.documentElement ||
            document.body.parentNode ||
            document.body
          ).scrollTop
  };
}

function initDOMListener() {
  document.body.addEventListener(
    'wheel',
    throttle(fireListeners, 10, {
      leading: true,
      trailing: true
    })
  );
  window.addEventListener(
    'resize',
    throttle(fireListeners, 100, {
      leading: true,
      trailing: true
    })
  );
}

if (canUseDOM) {
  if (document.body) {
    initDOMListener();
  } else {
    document.addEventListener('DOMContentLoaded', initDOMListener);
  }
}

let listenerIdCounter = 0;
function subscribe(fn) {
  listenerIdCounter += 1;
  const id = listenerIdCounter;
  listeners[id] = fn;
  return () => delete listeners[id];
}

export default class Portal extends React.Component {
  static propTypes = {
    rootElement: PropTypes.any,
    elementRef: PropTypes.any,
    bounds: PropTypes.any,
    top: PropTypes.number,
    left: PropTypes.number,
    right: PropTypes.number,
    fitElementWidth: PropTypes.bool,
    toRightOfElement: PropTypes.bool,
    alignTop: PropTypes.bool,
    children: PropTypes.any,
    onOutClick: PropTypes.func,
    zIndex: PropTypes.number,
    useModalOutClick: PropTypes.bool
  };

  static defaultProps = {
    left: 0,
    top: 0
  };

  state = {
    right: 0,
    left: 0,
    top: 0
  };

  constructor(props) {
    super(props);
    this.handleOutClick = this.handleOutClick.bind(this);
  }

  componentDidMount() {
    this.handleScroll = () => {
      let element;
      let rect;
      if (this.props.elementRef) {
        element =
          typeof this.props.elementRef === 'function'
            ? this.props.elementRef()
            : this.props.elementRef.current;
      } else {
        rect = this.props.bounds;
      }
      if ((element || rect) && this.props.children) {
        if (element && element.addEventListener) {
          element.addEventListener(
            'resize',
            throttle(fireListeners, 16, {
              leading: true,
              trailing: true
            })
          );
          rect = element.getBoundingClientRect();
        }
        const pageOffset = getPageOffset();
        let top;
        if (this.props.alignTop) {
          top = pageOffset.y + rect.top;
        } else {
          top = pageOffset.y + rect.bottom;
        }
        let right;
        let left;
        right =
          document.documentElement.clientWidth - rect.right - pageOffset.x;

        if (this.props.toRightOfElement) {
          left = pageOffset.x + rect.right;
        } else {
          left = pageOffset.x + rect.left;
        }

        let childRect = {
          top: 0,
          bottom: 0,
          right: 0,
          left: 0
        };
        if (this.childElement) {
          childRect = this.childElement.getBoundingClientRect();
        }
        if (
          document.documentElement.clientHeight -
            (this.props.alignTop ? rect.top : rect.bottom) -
            (this.props.top || 0) <
          childRect.height
        ) {
          top =
            pageOffset.y +
            rect.bottom -
            (rect.height || 0) -
            (childRect.height || 0) -
            (this.props.top || 0) * 2;

          if (top < 0) {
            top =
              document.documentElement.clientHeight -
              childRect.height -
              (this.props.top || 0) * 2;
          }
        }

        const width = childRect.width;

        if (left + width > document.documentElement.clientWidth) {
          left = document.documentElement.clientWidth - width - 16;
        }

        if (top < 16) {
          top = 16;
        }
        if (left < 16) {
          left = 16;
        }

        if (
          top !== this.state.top ||
          left !== this.state.left ||
          right !== this.state.right
        ) {
          this.setState({ left, top, right, minWidth: rect.width });
        }
      }
    };
    this.unsubscribe = subscribe(this.handleScroll);
    this.handleScroll();
  }

  componentDidUpdate() {
    this.handleScroll();
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  findParentByClassName = (el, className) => {
    if (el) {
      if (el.className?.includes && el.className?.includes(className)) {
        return true;
      } else {
        return this.findParentByClassName(el.parentNode, className);
      }
    }
    return false;
  };

  handleOutClick = e => {
    // if (this.props.onOutClick) {
    //   this.props.onOutClick(e);
    // }
    if (this.findParentByClassName(e.target, 'flatpickr')) {
      return;
    }
    if (
      this.props.useModalOutClick &&
      this.findParentByClassName(e.target, 'ReactModalPortal')
    ) {
      return;
    }
    if (e.defaultPrevented) {
      return;
    }

    const elementRef = this.props.elementRef;
    if (!elementRef) {
      if (this.props.onOutClick) {
        this.props.onOutClick(e);
      }
      return;
    }
    const element =
      typeof elementRef === 'function' ? elementRef() : elementRef.current;
    if (
      this.props.onOutClick &&
      (!element || (e.target !== element && !element.contains(e.target)))
    ) {
      this.props.onOutClick(e);
    }
  };

  render() {
    const {
      top,
      left,
      right,
      maxWidth = 350,
      fitElementWidth,
      minWidth: propsMinWidth,
      onOutClick,
      useModalOutClick,
      elementRef,
      alignTop,
      toRightOfElement,
      bounds,
      children,
      rootElement,
      zIndex,
      style,
      ...rest
    } = this.props;
    const elementPosition = {
      left: this.state.left + (left || 0),
      right: this.state.right + (right || 0)
    };

    let fromLeftOrRight;
    if (right !== undefined) {
      fromLeftOrRight = { right: this.state.right + right };
    } else {
      fromLeftOrRight = { left: this.state.left + left };
    }
    const horizontalPosition = fitElementWidth
      ? elementPosition
      : fromLeftOrRight;

    let minWidth = this.state.minWidth;
    if (maxWidth !== 'triggerWidth' && minWidth > maxWidth) {
      minWidth = maxWidth;
    } else if (propsMinWidth && minWidth < propsMinWidth) {
      minWidth = propsMinWidth;
    }

    return (
      <PortalWrapper rootElement={rootElement} onOutClick={this.handleOutClick}>
        <div
          style={{
            position: 'absolute',
            zIndex: zIndex || 1002,
            top: this.state.top + top,
            minWidth: minWidth || propsMinWidth || 0,
            // minWidth: this.props.minWidth || minWidth || 0,
            maxWidth:
              maxWidth === 'triggerWidth'
                ? minWidth
                : maxWidth < minWidth
                ? minWidth
                : maxWidth,
            ...horizontalPosition,
            ...(style || {})
          }}
          ref={node => (this.childElement = node)}
          {...rest}
        >
          {children}
        </div>
      </PortalWrapper>
    );
  }
}

import { useCallback, useEffect, useReducer, useRef } from 'react';
import axios from 'axios';

const initialState = {
  action: null,
  isLoading: true,
  error: null,
  isFirstLoading: true,
  payload: '',
  result: null
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'updateParams': {
      return {
        ...state,
        isLoading: true,
        error: null,
        payload: action.payload,
        action: action.type
      };
    }
    case 'updateParamsWithFunction': {
      return {
        ...state,
        isLoading: true,
        error: null,
        payload: action.payload(state.payload),
        action: action.type
      };
    }
    case 'refresh': {
      return {
        ...state,
        isLoading: true,
        error: null,
        action: action.type
      };
    }
    case 'success': {
      return {
        ...state,
        isLoading: false,
        isFirstLoading: false,
        action: null,
        result: action.result
      };
    }
    case 'error': {
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        isFirstLoading: false,
        action: null
      };
    }
    case 'reset': {
      return { ...initialState, action: action.type };
    }
    default: {
      return state;
    }
  }
};

const CancelToken = axios.CancelToken;

// to use with redux
const useFetch = (
  promise,
  deps = [],
  hasParams = false,
  options = {
    autoFetch: true
  }
) => {
  const _deps = JSON.stringify(deps);
  const source = useRef(CancelToken.source());
  const promiseRef = useRef();
  promiseRef.current = promise;

  const { autoFetch } = options;

  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    isLoading: !hasParams && autoFetch,
    action: hasParams || !autoFetch ? '' : 'refresh'
  });
  const { action, payload } = state;

  useEffect(() => {
    if (!action || action.length === 0) {
      return;
    }
    let canceled = false;
    const fetchData = async () => {
      if (hasParams && (payload === '' || !payload)) {
        return;
      }
      if (source.current) {
        source.current.cancel('new request appeared');
      }
      source.current = CancelToken.source();
      try {
        const result = await promiseRef.current({
          ...payload,
          cancelToken: source.current.token
        });
        if (!canceled) {
          dispatch({ type: 'success', result });
        }
      } catch (error) {
        console.error(error);
        if (!canceled && !axios.isCancel(error)) {
          dispatch({ type: 'error', payload: error });
        }
      }
    };
    fetchData();
    return () => {
      canceled = true;
      source.current.cancel('Cancel request due to unmount');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_deps, payload, action, hasParams]);

  const refresh = useCallback(() => {
    dispatch({ type: 'refresh' });
  }, [dispatch]);

  useEffect(() => {
    if (!hasParams && autoFetch && (!action || action.length === 0)) {
      refresh();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_deps, hasParams, refresh, autoFetch]);

  const reset = () => {
    dispatch({ type: 'reset' });
  };

  const updateParams = useCallback(payload => {
    if (typeof payload === 'function') {
      dispatch({
        type: 'updateParamsWithFunction',
        payload
      });
    } else {
      dispatch({ type: 'updateParams', payload });
    }
  }, []);

  return {
    ...state,
    fetchError: state.error,
    updateParams,
    refresh,
    reset,
    isEmpty: !state.isLoading && !state.data
  };
};

export default useFetch;

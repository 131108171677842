import React, { useRef, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import Uppy from '@uppy/core';
import { DragDrop } from '@uppy/react';
import '@uppy/core/dist/style.css';
import '@uppy/drag-drop/dist/style.css';
import { useFormik } from 'formik';
import Modal, { ModalFooter, ModalHeader } from 'components/v3/Modal';
import { Grid, GridItem, Text } from 'components/v3/ui';
import VoucherKindSelect from './VoucherKindSelect';
import AttachmentIcon from 'features/Attachments/components/v3/Icon';
import { CardButtons } from 'components/v3/ui/card';
import { VoucherAction } from '../data';
import { toaster } from 'components/v3/Toast';

const AttachmentWrapper = styled.div`
  position: relative;
  display: flex;
  height: 50px;
  align-items: center;
  padding: 8px;
  > * {
    flex-shrink: 0;
  }

  > div {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  * > .uppy-DragDrop-container {
    background: transparent;
    border-radius: ${({ theme }) => theme.borderInputRadius};
    border-color: ${({ theme }) => theme.borderInputColor};

    > .uppy-DragDrop-inner {
      padding: 0px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: none;
      align-items: center;
      justify-content: center;
      svg {
        fill: ${({ theme }) => theme.primaryLight};
      }
      > .uppy-DragDrop-label {
        display: none;
      }
    }
  }

  * > .uppy-DragDrop--isDraggingOver {
    border-color: ${({ theme }) => theme.primaryLight};
    > .uppy-DragDrop-inner {
      display: flex;
    }
  }

  * > .uppy-DragDrop-container {
    background: transparent;
  }

  * > .uppy-DragDrop--isDraggingOver {
    > .uppy-DragDrop-inner {
      background-color: rgba(255, 255, 255, 0.56);
    }
  }
`;

const VoucherVersionModal = ({
  voucher,
  availableVoucherKinds,
  voucherableId,
  voucherableType,
  onRequestClose,
  onSubmitSuccess,
  ...props
}) => {
  const [uppy, setUppy] = useState();
  const uppyRef = useRef();
  uppyRef.current = uppy;
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      voucherId: voucher?.id,
      attachmentAttributes: {
        source: ''
      },
      voucherAttributes: voucher
        ? undefined
        : {
            voucherableId,
            voucherableType,
            voucherKind: null
          }
    },
    onSubmit: values => {
      const formData = new FormData();
      formData.append(
        'voucher_version[attachment_attributes][source]',
        values.attachmentAttributes.source
      );
      if (values.voucherAttributes) {
        formData.append(
          'voucher_version[voucher_attributes][voucherable_id]',
          values.voucherAttributes.voucherableId
        );
        formData.append(
          'voucher_version[voucher_attributes][voucherable_type]',
          values.voucherAttributes.voucherableType
        );
        formData.append(
          'voucher_version[voucher_attributes][voucher_kind_id]',
          values.voucherAttributes.voucherKind?.id
        );
      } else {
        formData.append('voucher_version[voucher_id]', values.voucherId);
      }
      return dispatch(VoucherAction.createVoucherVersion({ payload: formData }))
        .then(() => onRequestClose())
        .catch(err => toaster.error(err));
    }
  });

  const { setFieldValue } = formik;

  useEffect(() => {
    setUppy(
      u =>
        u ||
        Uppy({
          debug: true,
          meta: { type: 'avatar' },
          allowMultipleUploads: true,
          restrictions: {
            allowedFileTypes: ['image/*', 'application/*']
          },
          replaceTargetContent: true,
          autoProceed: false
        })
          .on('file-added', file => {
            setFieldValue('attachmentAttributes.source', file.data);
          })
          .on('upload-error', (file, error) => {})
          .on('complete', result => {})
    );

    return () => {
      uppyRef.current.close();
    };
  }, [setFieldValue]);

  const file = formik.values.attachmentAttributes?.source;
  const hasKind =
    voucher || Boolean(formik.values.voucherAttributes.voucherKind?.id);

  return (
    <Modal
      isOpen
      size="small"
      onRequestClose={onRequestClose}
      title="Nouvelle version"
      createRequest={() => {}}
      autoSubmit={false}
      {...props}
    >
      <form onSubmit={formik.handleSubmit}>
        <ModalHeader
          title={
            (voucher && voucher.voucherKind?.name) ||
            'Nouveau document officiel'
          }
        />
        <Grid spacing={16} padded>
          {!voucher && (
            <GridItem width="100%">
              <VoucherKindSelect
                voucherableType={voucherableType}
                name="voucherAttributes.voucherKind"
                availableVoucherKinds={availableVoucherKinds}
                required
                value={formik.values.voucherAttributes?.voucherKind}
                onChange={value => {
                  setFieldValue('voucherAttributes.voucherKind', value);
                }}
              />
            </GridItem>
          )}
          <GridItem width="100%">
            <AttachmentWrapper>
              <AttachmentIcon attachment={file} size={24} />
              <Text style={{ marginLeft: 12 }} color="secondary" small>
                {file?.name}
              </Text>
              {uppy && <DragDrop uppy={uppy} target="body" locale={null} />}
            </AttachmentWrapper>
            <Text variant="hint" color="hint">
              Cliquez ou glissez un fichier
            </Text>
          </GridItem>
        </Grid>
        <ModalFooter>
          <CardButtons
            positiveDisabled={!file || !hasKind}
            buttonNameLoading={formik.isSubmitting ? 'positive' : null}
            positiveText="Enregistrer"
            negativeText="Annuler"
            onButtonClick={({ name }) => {
              if (name === 'negative') {
                onRequestClose();
              }
            }}
          />
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default VoucherVersionModal;

import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-grow: 1;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  flex-direction: ${({ vertical }) => (vertical ? 'column' : 'row')};

  ${({ padded }) =>
    padded &&
    css`
      padding: 16px;
    `}

  ${({ huge }) =>
    huge &&
    css`
      max-width: 1680px;
    `};

  ${({ big }) =>
    big &&
    css`
      max-width: 1280px;
    `};

  ${({ narrow }) =>
    narrow &&
    css`
      max-width: 832px;
    `};

  ${({ tight }) =>
    tight &&
    css`
      max-width: 540px;
    `};
`;

export const GridItem = styled.div.attrs(
  ({ width, minWidth, maxWidth, style }) => ({
    style: {
      width,
      minWidth,
      maxWidth,
      ...(style || {})
    }
  })
)`
  ${({ alignEnd }) =>
    alignEnd &&
    css`
      align-items: end;
      text-align: end;
    `}

  ${({ alignCenter, justifyCenter }) =>
    (alignCenter || justifyCenter) &&
    css`
      display: flex;
      flex-direction: column;
    `}

  ${({ alignCenter }) =>
    alignCenter &&
    css`
      align-items: center;
    `}
           
  ${({ justifyCenter }) =>
    justifyCenter &&
    css`
      justify-content: center;
    `}

  ${({ wrap }) =>
    wrap &&
    css`
      flex: unset !important;
    `}
`;

export const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: ${({ padded = true, spacing = 16, vertical = false }) =>
    padded ? (vertical ? `${spacing / 2}px 0px` : `${spacing / 2}px`) : null};
  
  ${({ justifyCenter }) =>
    justifyCenter
      ? css`
          justify-content: center;
        `
      : css`
          > ${({ allItems }) => (allItems ? '*' : GridItem)} {
            flex: auto;
          }
        `}
        
  ${({ alignCenter }) =>
    alignCenter &&
    css`
      align-items: center;
    `} 

   > ${({ allItems }) => (allItems ? '*' : GridItem)} {
    padding: ${({ spacing = 16, vertical = false }) =>
      vertical ? `${spacing / 2}px 0px` : `${spacing / 2}px`};
  }
`;

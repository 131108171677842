import { get, put, post, destroy } from 'common/api';

export const getTeams = params =>
  get('/v5/teams', {
    ...(params || {}),
    sort: (params || {}).sort ? params.sort : 'position,first_name,display_name'
  });

export const createTeam = (team, config) => post('/v5/teams', { team }, config);

export const getTeam = (id, config) => get(`/v5/teams/${id}`, config);

export const putTeam = (id, team, config) =>
  put(`/v5/teams/${id}`, { team }, config);

export const deleteTeam = (id, config) => destroy(`/v5/teams/${id}`, config);

export const reorderTeam = (id, team, config) =>
  put(`/v5/teams/${id}/reorder`, { team }, config);

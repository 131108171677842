import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';
import { spacing, theme } from 'ui';
import { AvatarWrapper } from 'components/v3/Avatar';
import { MdMoreVert, MdSearch } from 'react-icons/md';
import EmptyState from 'components/EmptyState';
import { useAccountConfig } from 'config';
import Checkbox from 'components/v3/Checkbox';
import Menu, { MenuItem } from 'components/v3/Menu';
import { Text, ButtonIcon, Container } from 'components/v3/ui';
import List from 'components/v3/List/List';
import isSameIds from 'utils/idUtils';
import { InputLayoutVariant, TextInputLayout } from 'components/v3/InputLayout';
import ActionGroup, { Action } from 'components/v3/ActionGroup';
import { sortBy } from 'lodash';
import UserSelect from 'features/Users/components/v3/UserSelect';

const CheckboxWrapper = styled.span`
  display: flex;
  width: 32px;
  height: 32px;
  align-items: center;
  justify-content: center;
`;

const RowWrapper = styled.div`
  position: relative;
  min-height: 40px;
  display: flex;
  padding-right: 8px;
  align-items: center;
  cursor: pointer;

  &:before {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    content: '';
    display: block;
    border-bottom: 1px solid ${({ theme }) => theme.separator};
  }

  ${({ active }) =>
    !active &&
    css`
      background: rgba(0, 0, 0, 0.08);
      ${AvatarWrapper} {
        opacity: 0.3;
      }
    `}

  &:hover {
    background: ${({ theme, active }) =>
      active ? theme.hoverColor : theme.hoverColorDark};
  }
`;

const UserRowContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: ${spacing()};
`;

const Color = styled.div`
  margin-right: 8px;
  width: 12px;
  height: 100%;
`;

const UserRow = React.memo(
  ({ user, subscribed, checked, onChange, onRemove, onEdit }) => {
    const { translate } = useAccountConfig();
    const {
      id,
      displayName,
      displayRole,
      topRole,
      displayTopRole,
      settings
    } = user;
    const jobName = settings?.jobDisplayName;
    return (
      <RowWrapper
        key={id}
        active={checked}
        onClick={() => onChange({ id, checked: !checked })}
      >
        <Color style={{ backgroundColor: settings?.color || 'transparent' }} />
        <CheckboxWrapper>
          <Checkbox
            color={user?.settings?.color || theme.primaryLight}
            value={checked}
            onChange={checked => onChange({ id, checked })}
          />
        </CheckboxWrapper>
        <UserRowContent>
          <Text variant="small">{displayName}</Text>
          <Text variant="caption" color="theme">
            {[
              topRole !== 'technician' ? displayRole || displayTopRole : null,
              jobName
            ]
              .filter(Boolean)
              .join(' - ')}
          </Text>
        </UserRowContent>
        <Menu maxWidth={300}>
          <ButtonIcon>
            <MdMoreVert />
          </ButtonIcon>
          {subscribed && (
            <MenuItem onClick={() => onRemove(id)}>
              {`Se désabonner de ${displayName}`}
            </MenuItem>
          )}
          {!subscribed && (
            <MenuItem onClick={() => onChange({ id, checked: true })}>
              {`S'abonner à ${displayName}`}
            </MenuItem>
          )}
          <MenuItem onClick={() => onEdit(id)}>
            {translate({
              value: 'phase.user',
              fallback: 'technicien',
              prefix: 'Editer le '
            })}
          </MenuItem>
        </Menu>
      </RowWrapper>
    );
  }
);

const ActionContainer = styled.div`
  margin: 4px;
  > * {
    flex: 1;
    padding: 4px 12px;
    border-radius: 0px !important;
    font-size: 0.875rem;
    justify-content: center;
  }
`.withComponent(ActionGroup);

const SearchContainer = styled(Container)`
  margin: 0px 4px;
  width: calc(100% - 8px);
  border: 1px solid ${({ theme }) => theme.separator};
  > *:not(first-child) {
    border-left: 1px solid ${({ theme }) => theme.separator};
  }
`;

const Users = ({
  users: propsUsers,
  checkedIds,
  subscribedIds,
  onAdd,
  onChange,
  onRemove,
  allSelected,
  onAllSelected,
  onSearch,
  onEditUser
}) => {
  const users = useMemo(
    () =>
      sortBy(propsUsers, u => {
        if (u.topRole === 'technician') {
          return `z${u.settings?.job}${u.displayName}`;
        } else {
          return `a${u.topRole}${u.displayName}`;
        }
      }),
    [propsUsers]
  );

  return (
    <Container grow vertical style={{ height: '100%' }}>
      <SearchContainer>
        {!allSelected && (
          <UserSelect
            inputCreatable={false}
            optionEditable={false}
            variant={InputLayoutVariant.flat.medium}
            iconLeading={MdSearch}
            fillWidth
            filter={option => !users.find(u => isSameIds(u.id, option.value))}
            filterOptions
            label="Ajouter un agenda"
            onChange={user => user && onAdd(user.id)}
            style={{ width: 'unset', minWidth: '50%' }}
          />
        )}
        {allSelected && (
          <TextInputLayout
            iconLeading={MdSearch}
            variant={InputLayoutVariant.flat.medium}
            debounced
            fillWidth
            label="Rechercher"
            style={{ width: 'unset', minWidth: '50%' }}
            onChangeDebounce={onSearch}
          />
        )}
      </SearchContainer>
      <ActionContainer style={{ height: 'unset' }}>
        <Action checked={!allSelected} onClick={() => onAllSelected(false)}>
          Abonnements
        </Action>
        <Action checked={allSelected} onClick={() => onAllSelected(true)}>
          Tous
        </Action>
      </ActionContainer>
      {users.length === 0 && (
        <EmptyState message="Ajoutez un membre pour visualiser son agenda" />
      )}
      {users.length > 0 && (
        <List
          items={users}
          estimatedItemSize={40}
          rowRenderer={({ item }) => {
            const checked = Boolean(
              checkedIds?.find(id => isSameIds(item.id, id))
            );
            let subscribed = false;
            if (checked) {
              subscribed = true;
            } else if (
              !allSelected &&
              subscribedIds?.find(id => isSameIds(item.id, id))
            ) {
              subscribed = true;
            }
            return (
              <UserRow
                key={item.id}
                checked={checked}
                subscribed={subscribed}
                user={item}
                onChange={onChange}
                onRemove={onRemove}
                onEdit={onEditUser}
              />
            );
          }}
        />
      )}
    </Container>
  );
};

export default Users;

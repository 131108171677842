import { omit } from 'lodash';
import { mergeJsonApi } from 'common/reducers';
import isSameIds from 'utils/idUtils';

const initialVoucherKindState = {
  voucherKindById: {}
};

export const voucherKinds = (state = initialVoucherKindState, action) => {
  const { payload, type } = action;

  switch (type) {
    case 'NEW_VOUCHER_KIND_SUCCESS':
      return {
        ...mergeJsonApi('voucherKind', state, action)
      };
    case 'DELETE_VOUCHER_KIND_SUCCESS':
      return {
        ...state,
        voucherKindById: omit(state.voucherKindById, payload)
      };
    case 'LOGOUT':
    case 'SELECT_ACCOUNT':
      return initialVoucherKindState;
    default:
      return mergeJsonApi('voucherKind', state, action);
  }
};

const initialVoucherState = {
  voucherById: {}
};

export const vouchers = (state = initialVoucherState, action) => {
  const { payload, type } = action;
  let voucher;
  let voucherVersions;
  switch (type) {
    case 'NEW_VOUCHER_SUCCESS':
      return {
        ...mergeJsonApi('voucher', state, action)
      };
    case 'DELETE_VOUCHER_SUCCESS':
      return {
        ...state,
        voucherById: omit(state.voucherById, payload)
      };
    case 'DELETE_VOUCHER_VERSION_SUCCESS':
      voucher = state.voucherById[payload.voucherId];
      voucherVersions = voucher?.relationships?.voucherVersions;
      if (!voucherVersions?.data) return state;
      voucherVersions = {
        data: voucherVersions.data?.filter(
          ({ id }) => !isSameIds(id, payload.voucherVersionId)
        )
      };
      if (voucherVersions.data.length) {
        return {
          ...state,
          voucherById: {
            ...state.voucherById,
            [voucher.id]: {
              ...voucher,
              relationships: {
                ...voucher.relationships,
                voucherVersions
              }
            }
          }
        };
      } else {
        return {
          ...state,
          voucherById: omit(state.voucherById, payload.voucherId)
        };
      }

    case 'LOGOUT':
    case 'SELECT_ACCOUNT':
      return initialVoucherState;
    default:
      return mergeJsonApi('voucher', state, action);
  }
};

const initialVoucherVersionState = {
  voucherVersionById: {}
};

export const voucherVersions = (state = initialVoucherVersionState, action) => {
  const { payload, type } = action;

  switch (type) {
    case 'NEW_VOUCHER_VERSION_SUCCESS':
      return {
        ...mergeJsonApi('voucherVersion', state, action)
      };
    case 'DELETE_VOUCHER_VERSION_SUCCESS':
      return {
        ...state,
        voucherVersionById: omit(state.voucherVersionById, payload)
      };
    case 'LOGOUT':
    case 'SELECT_ACCOUNT':
      return initialVoucherVersionState;
    default:
      return mergeJsonApi('voucherVersion', state, action);
  }
};

import { get, put, post, destroy } from 'common/api';

export const getProjectKinds = params => get('/v5/project_kinds', params);

export const createProjectKind = (projectKind, config) =>
  post('/v5/project_kinds', { projectKind }, config);

export const getProjectKind = (id, config) =>
  get(`/v5/project_kinds/${id}`, config);

export const putProjectKind = (id, projectKind, config) =>
  put(
    `/v5/project_kinds/${id}`,
    {
      projectKind
    },
    config
  );

export const deleteProjectKind = (id, config) =>
  destroy(`/v5/project_kinds/${id}`, config);

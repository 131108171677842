import { useEffect } from 'react';
import { connect } from 'react-redux';
import { AuthAction } from 'features/Auth';

const Logout = ({ logout }) => {
  useEffect(() => {
    logout();
  }, [logout]);
  return null;
};

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(AuthAction.logout())
});

export default connect(
  null,
  mapDispatchToProps
)(Logout);

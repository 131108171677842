import { IssueApi, IssueAction, IssueReducer, IssueSelector } from './data';

export { IssueApi, IssueAction, IssueReducer, IssueSelector };

export const issueStateAsOption = state =>
  !!state
    ? {
        value: state.value,
        color: state.color,
        label: state.displayName || state.label,
        original: state
      }
    : '';

export const issueAsValues = issue => ({
  id: issue?.id,
  description: issue?.description || '',
  state: issue?.state || null,
  group: issue?.group || null,
  address: issue?.address || null,
  attachments: issue?.attachments || [],
  emergency: issue?.emergency || false,
  customData: Array.isArray(issue?.customData) ? issue.customData : []
});

export const issueAsMarker = issue => {
  if (!issue || !issue.address || !issue.address.lat || !issue.address.lng) {
    return null;
  }
  return { ...issue.address, id: issue.id, kind: 'issue', item: issue };
};

import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { AccountSelector } from 'features/Accounts';
import { MeSelector } from 'features/Me';
import { Provider } from './Context';
import isEqual from 'lodash/isEqual';
import isSameIds from 'utils/idUtils';
import { permissions as PERMISSIONS } from '.';

const selector = state => {
  const user = MeSelector.selectMe(state);
  const account = AccountSelector.selectCurrentAccount(state);
  const accountId = account ? account.id : null;
  const userRole = (user?.roles || []).find(role =>
    isSameIds(role.resourceId, accountId)
  )?.name;

  const permissions =
    account?.rolePermissions?.find(r => r.name === userRole)?.permissions || [];
  if (user?.superAdmin) {
    permissions.push(PERMISSIONS.SUPERADMIN);
  }
  if (userRole === 'admin') {
    permissions.push(PERMISSIONS.ADMIN);
  }

  return {
    accountId,
    features: account?.features || [],
    permissions,
    admin: userRole === 'admin' || user?.superAdmin,
    userRole,
    localizations: account?.localizations || [],
    user,
    superAdmin: user?.superAdmin
  };
};

const isOwner = (project, user) => {
  if (!user) {
    return false;
  }
  if (
    isSameIds(project.user?.id, user.id) ||
    isSameIds(project.userId, user.id)
  ) {
    return true;
  }
  return false;
};
const isInGroup = (project, user) => {
  if (!user) {
    return false;
  }
  if (
    !project.group ||
    Boolean(
      user.groups?.find(
        ({ id }) =>
          isSameIds(project.group?.id, id) || isSameIds(project.groupId, id)
      )
    )
  ) {
    return true;
  }
  return false;
};

const withAttribute = (permission, attribute) => {
  if (
    !attribute ||
    !permission.except ||
    !permission.except.includes ||
    !permission.except.includes(attribute)
  ) {
    return permission;
  } else {
    return null;
  }
};

export default ({ children }) => {
  const {
    permissions,
    admin,
    features,
    localizations,
    accountId,
    user,
    superAdmin,
    userRole
  } = useSelector(selector, isEqual);

  const hasFeature = useCallback(
    name => {
      if (Array.isArray(name)) {
        return name.some(val => features.indexOf(val) !== -1);
      } else if (name) {
        return features.includes(name);
      } else {
        return true;
      }
    },
    [features]
  );

  const hasPermission = useCallback(
    (perm, { target, attribute } = {}) => {
      if (perm === PERMISSIONS.SUPERADMIN) {
        return superAdmin;
      }
      if (perm === PERMISSIONS.CHECK_DOCUMENTS) {
        return userRole === 'user';
      }
      if (superAdmin || admin || !perm || !Array.isArray(permissions)) {
        return true;
      }
      const permission = permissions
        .filter(Boolean)
        .find(({ name: permissionName }) => {
          const name = permissionName.replace('_owned', '');
          if (Array.isArray(perm)) {
            return perm.find(p => name.includes(p));
          } else {
            return name.includes(perm);
          }
        });
      if (!target) {
        return withAttribute(permission, attribute);
      }
      if (permission?.name.includes('_owned')) {
        if (
          permission?.name?.includes('project.create') ||
          isOwner(target.project || target, user)
        ) {
          return withAttribute(permission, attribute);
        }
        return false;
      } else if (
        permission?.name.includes('project') ||
        permission?.name.includes('phase') ||
        permission?.name.includes('issue')
      ) {
        if (isInGroup(target.project || target, user)) {
          return withAttribute(permission, attribute);
        }
        return false;
      }
      return permission;
    },
    [permissions, user, admin, superAdmin, userRole]
  );

  const translate = useCallback(
    ({ value, plural = false, prefix = null, fallback }) => {
      var array = value.split('.');
      const feature = array.shift();
      const labelName = array.join('.');
      let customLabel = localizations.find(
        l => l.feature === feature && l.labelName === labelName
      );
      let result = fallback || null;
      if (customLabel && customLabel.displayName) {
        result = customLabel.displayName[plural ? 'plural' : 'singular'];
        if (!result) {
          result = customLabel.displayName;
        }
      }
      return prefix ? `${prefix}${result}` : result;
    },
    [localizations]
  );

  const memoValue = useMemo(
    () => ({
      accountId,
      features,
      hasFeature,
      permissions,
      hasPermission,
      translate,
      userRole
    }),
    [
      accountId,
      features,
      hasFeature,
      permissions,
      hasPermission,
      translate,
      userRole
    ]
  );
  return <Provider value={memoValue}>{children}</Provider>;
};

import {
  createDeepEqualSelector,
  selectRelations,
  getState
} from 'common/selector';
import { get } from 'lodash';
import { UserSelector } from 'features/Users';
import omit from 'lodash/omit';

export const getAuthState = state => {
  return omit(state.ui, 'ui');
};

export const selectAuthState = createDeepEqualSelector(
  [getAuthState],
  authState => authState
);

export const selectCurrentAccountId = createDeepEqualSelector(
  [getAuthState],
  ({ currentAccountId }) => currentAccountId
);

export const selectMe = createDeepEqualSelector(
  [getAuthState, UserSelector.getUsers, getState],
  ({ userId }, { userById }, state) =>
    selectRelations('user', userById[userId], state)
);

export const selectCurrentRole = createDeepEqualSelector([selectMe], user =>
  get(user, 'topRole')
);

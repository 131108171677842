import React, { useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { MdAdd } from 'react-icons/md';
import Box, { BoxHeader, BoxFilter } from 'components/v3/Box';
import SubcontractingList from './SubcontractingList';
import { Button } from 'components/v3/ui';

const SubcontractingBox = ({
  fetchAction,
  selector,
  params,
  creatable,
  marged,
  padded,
  ...props
}) => {
  const { url } = useRouteMatch();
  const [count, setCount] = useState(null);
  const [filters, setFilters] = useState([]);

  return (
    <Box marged={marged} padded={padded}>
      <BoxHeader
        bigPadded
        title={`Sous-traitances${count != null ? ` (${count})` : ''}`}
      >
        {fetchAction && (
          <BoxFilter
            searchQuery="byQuery"
            filters={filters}
            onChanges={setFilters}
          />
        )}
        {creatable && (
          <Button small to={`${url}/sous-traitances/nouveau`} icon={MdAdd}>
            Ajouter
          </Button>
        )}
      </BoxHeader>
      <SubcontractingList
        rowColor="light"
        selector={selector}
        action={fetchAction}
        params={params}
        filters={filters}
        onCountChanged={setCount}
        headerVariant="transparent"
        minRows={5}
        maxHeight={500}
        {...props}
      />
    </Box>
  );
};

export default SubcontractingBox;

import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SubHeader from 'components/v3/SubHeader';
import { FilterBar } from 'components/v3/Filters';
import { Container, Title } from 'components/v3/ui';
import {
  AccountingSelector,
  AccountingAction,
  INVOICE_KINDS,
  InvoiceState,
  InvoiceList
} from 'features/Accounting';
import OrganizationSelect from 'features/Organizations/components/v3/Select';
import GroupSelect from 'features/Groups/components/v3/Select';
import { features, useAccountConfig } from 'config';

const defaultParams = { sort: '-updated_at' };

const SIGN_FILTER = {
  kind: 'checkbox',
  key: 'sign_invoice',
  value: null,
  asQueryParam: value => {
    let signedEq = null;
    if (value === 'true') {
      signedEq = true;
    } else if (value === 'indeterminate') {
      signedEq = false;
    }
    return {
      signedEq
    };
  },
  props: {
    label: 'Signé',
    style: { minWidth: 100, maxWidth: 200 },
    indeterminate: true
  }
};

const FILTERS = [
  {
    kind: 'date',
    key: 'dates',
    value: null,
    asQueryParam: value => ({
      'between[start_at]': value?.startAt,
      'between[end_at]': value?.endAt
    }),
    props: {
      mode: 'range',
      collapsedIfBlank: true
    }
  },
  {
    kind: 'select',
    key: 'invoiceKind',
    value: '',
    asQueryParam: value => ({
      kindEq: value
    }),
    props: {
      label: 'Type',
      options: INVOICE_KINDS,
      mapOptionValue: true,
      isSearchable: false,
      style: { minWidth: 100, maxWidth: 200 }
    }
  },
  {
    kind: 'select',
    key: 'group',
    component: GroupSelect,
    value: null,
    asQueryParam: value => ({
      projectGroupIdEq: value ? value.id : null
    }),
    props: {
      inputCreatable: false,
      style: {
        minWidth: 120,
        maxWidth: 200
      }
    }
  },
  {
    kind: 'select',
    key: 'organization',
    component: OrganizationSelect,
    value: null,
    asQueryParam: value => ({
      projectOrganizationIdEq: value?.id
    }),
    props: {
      fitCurrentOptionWidth: true,
      style: { minWidth: 150, maxWidth: 300 }
    }
  },
  {
    kind: 'select',
    key: 'select',
    component: InvoiceState,
    value: null,
    asQueryParam: value => ({
      stateEq: value?.value
    }),
    props: {
      inputCreatable: false,
      asTag: false,
      isClearable: true,
      style: { minWidth: 100, maxWidth: 220 }
    }
  }
];

const Invoices = () => {
  const dispatch = useDispatch();
  const [count, setCount] = useState();
  const [filters, setFilters] = useState(FILTERS);
  const { hasFeature } = useAccountConfig();
  const hasSignFeature = hasFeature(features.SIGN_INVOICE);

  const amountSelector = useMemo(() => state => state.invoices.totalAmount, []);
  const amount = useSelector(amountSelector);
  const handleDelete = useCallback(
    item => dispatch(AccountingAction.deleteInvoice({ id: item.id })),
    [dispatch]
  );

  useEffect(() => {
    setFilters(filters => {
      if (hasSignFeature) {
        if (filters.find(f => f.key === SIGN_FILTER.key)) {
          return filters;
        } else {
          return [...filters, SIGN_FILTER];
        }
      } else {
        return FILTERS;
      }
    });
  }, [hasSignFeature]);

  return (
    <Container vertical>
      <SubHeader>
        <Title>{`Comptabilité${count != null ? ` (${count})` : ''}`}</Title>
        <Title>{amount}</Title>
      </SubHeader>
      <Container vertical huge padded>
        <FilterBar
          big
          filters={filters}
          searchQuery="byQuery"
          onChanges={setFilters}
        />
        <InvoiceList
          spacing={8}
          headerVariant="transparent"
          selector={AccountingSelector.selectInvoices}
          action={AccountingAction.getInvoices}
          onCountChanged={setCount}
          params={defaultParams}
          filters={filters}
          perPage={50}
          detailUrlPrefix=""
          isDeletable
          deleteRequest={handleDelete}
        />
      </Container>
    </Container>
  );
};

export default Invoices;

import React from 'react';
import styled from 'styled-components';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button } from 'components/v3/ui';
import Popover from 'components/v3/Popover';
import RawCard, {
  CardButtons,
  CardContent,
  CardFooter,
  CardHeader
} from 'components/v3/ui/card';
import { TextInputLayout } from 'components/v3/InputLayout';

const validationSchema = Yup.object().shape({
  comment: Yup.string()
    .trim()
    .min(1)
    .required('Vous devez indiquer une raison.')
});

const Card = styled(RawCard)`
  textarea {
    min-height: 140px;
  }
`;
const NegativeButton = styled(Button)`
  background-color: ${({ theme }) => theme.warning};
  color: ${({ theme }) => theme.textLight};
`;

const PopoverContent = ({ voucherVersion, updateVoucherVersion }) => {
  const formik = useFormik({
    initialValues: { comment: '' },
    validationSchema,
    onSubmit: values =>
      updateVoucherVersion({
        id: voucherVersion.id,
        payload: {
          ...values,
          checkResult: 'refused'
        }
      })
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Card
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <CardHeader subtitle="Commentaire" />
        <CardContent>
          <TextInputLayout
            textArea
            error={formik.errors.comment}
            name="commentaire"
            label="Indiquer la raison du refus"
            minRows={6}
            value={formik.values.comment}
            onChange={value => {
              formik.setFieldValue('comment', value);
            }}
          />
        </CardContent>
        <CardFooter>
          <CardButtons
            positiveDisabled={!formik.isValid}
            buttonNameLoading={formik.isSubmitting ? 'positive' : null}
            positiveText="Enregistrer"
            negativeText=""
          />
        </CardFooter>
      </Card>
    </form>
  );
};

const NegativeCheckButton = ({
  isLoading,
  voucherVersion,
  updateVoucherVersion
}) => {
  return (
    <Popover closeOnSelect={false} autoHeightMax={400} minWidth={300}>
      <NegativeButton
        variant="accent"
        small
        isLoading={isLoading}
        disabled={isLoading}
      >
        Refuser
      </NegativeButton>
      <PopoverContent
        voucherVersion={voucherVersion}
        updateVoucherVersion={updateVoucherVersion}
      />
    </Popover>
  );
};

export default NegativeCheckButton;

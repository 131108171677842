import {
  ProjectKindApi,
  ProjectKindAction,
  ProjectKindReducer,
  ProjectKindSelector
} from './data';
import { activityAsValues } from 'features/ProjectKindsActivities';

export {
  ProjectKindApi,
  ProjectKindAction,
  ProjectKindReducer,
  ProjectKindSelector
};

export const projectKindAsOption = projectKind => {
  if (Array.isArray(projectKind)) {
    return projectKind.map(projectKindAsOption);
  } else if (projectKind) {
    return {
      value: projectKind.id,
      label: projectKind.displayName,
      color: projectKind.color,
      original: projectKind
    };
  } else {
    return null;
  }
};

export const projectKindAsValues = projectKind => {
  return {
    id: projectKind?.id || null,
    displayName: projectKind?.displayName || '',
    abbr: projectKind?.abbr || '',
    activities: projectKind?.activities?.map(activityAsValues) || [],
    color: projectKind?.color || ''
  };
};

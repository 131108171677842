import { createDeepEqualSelector, getProps } from 'common/selector';
import { getState, selectRelations } from 'common/selector';

export const getExtractionMaterials = state => state.extractionMaterials;

export const selectExtractionMaterials = createDeepEqualSelector(
  [getExtractionMaterials, getState],
  ({ extractionMaterialsIds, extractionMaterialById }, state) =>
    extractionMaterialsIds
      ?.map(id =>
        selectRelations('extractionMaterial', extractionMaterialById[id], state)
      )
      .filter(Boolean) ?? []
);

export const selectExtractionMaterial = createDeepEqualSelector(
  [getExtractionMaterials, getProps, getState],
  ({ extractionMaterialById }, { id }, state) =>
    selectRelations('extractionMaterial', extractionMaterialById[id], state)
);

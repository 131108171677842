import { get, put, post } from 'common/api';

export const getActivities = () => get('/v5/activities?sort=display_name');

export const createActivity = (activity, config) =>
  post('/v5/activities', { activity }, config);

export const putActivity = (id, activity, config) =>
  put(
    `/v5/activities/${id}`,
    {
      activity
    },
    config
  );

import React, { useMemo, useCallback, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { v4 } from 'uuid';
import isEqual from 'lodash/isEqual';
import findIndex from 'lodash/findIndex';
import flatten from 'lodash/flatten';
import { getRouteFromWaypoints } from 'api/mapbox';
import { projectAsMarker } from 'features/Projects';
import { UiSelector, UiAction } from 'features/UiPreferences';
import {
  deviationAsRoute,
  projectAsRoute,
  signAsMarker,
  DEVIATIONS_COLORS
} from './utils';
import { Button } from 'components/v3/ui';
import Map, { Polylines } from 'components/v3/Map';
import { getCursor } from 'components/v3/Map/Map';

const DeviationMap = ({
  mapRef,
  width,
  height,

  project,

  onProjectPathChanged, // "directions"
  onDeviationsChanged, // "deviations"
  onSignsChanged, // "signs"

  activeRouteId,
  onRouteIdSelected,

  hoverRouteId,
  onHoverRouteId,

  readOnly,
  isEditing = true
}) => {
  const dispatch = useDispatch();
  const selectUi = useMemo(
    () => UiSelector.selectUiKeys('useRouteWaypointsApi'),
    []
  );
  const { useRouteWaypointsApi } = useSelector(selectUi, isEqual);

  const projectRoute = useMemo(() => projectAsRoute(project), [project]);

  const deviations = project?.deviations || [];
  const deviationRoutes = useMemo(() => {
    return deviations
      .filter(d => !d._destroy)
      .map((deviation, index) =>
        deviationAsRoute({
          ...deviation,
          color: deviation.color || DEVIATIONS_COLORS[index] || '#ccc'
        })
      );
  }, [deviations]);

  const signs = (project ? project.signs : []) || [];
  const signsMarkers = React.useMemo(() => {
    return signs.map(signAsMarker).filter(Boolean);
  }, [signs]);

  const routes = useMemo(() => {
    const routes = [];
    if (projectRoute) {
      projectRoute.markers.forEach(marker => (marker.hidden = !isEditing));
      routes.push(projectRoute);
    }
    deviationRoutes.forEach(route => {
      route.markers.forEach(marker => (marker.hidden = !isEditing));
      routes.push(route);
    });
    return routes;
  }, [isEditing, projectRoute, deviationRoutes]);

  const selectedRoute = useMemo(() => {
    return routes.find(r => String(r.id) === String(activeRouteId));
  }, [activeRouteId, routes]);

  const activeRouteMarkers = useMemo(() => {
    return !readOnly && selectedRoute?.markers ? selectedRoute.markers : [];
  }, [readOnly, selectedRoute]);

  const markers = React.useMemo(() => {
    if (isEditing) {
      signsMarkers.forEach(marker => (marker.draggable = !readOnly));
    }
    const projectMarker = projectAsMarker(project);
    if (projectMarker) {
      return [projectMarker, ...activeRouteMarkers, ...signsMarkers];
    } else {
      return [...activeRouteMarkers, ...signsMarkers];
    }
  }, [isEditing, project, signsMarkers, activeRouteMarkers, readOnly]);

  const coordinates = React.useMemo(() => {
    return [
      ...markers.filter(m => !m.item._destroy),
      ...flatten(routes.map(r => r.directionsLatLng))
    ];
  }, [markers, routes]);

  const handleMarkerChange = useCallback(
    async (action, targetMarker, index) => {
      if (readOnly) {
        return;
      }
      if (targetMarker.kind === 'point') {
        if (!selectedRoute || selectedRoute.id !== targetMarker.routeId) {
          return;
        }
        let routeMarkers;
        let directions;
        const route = selectedRoute;
        switch (action) {
          case 'creation': {
            routeMarkers = [...route.markers, targetMarker];
            break;
          }
          case 'insertion': {
            if (index >= 0) {
              routeMarkers = [
                ...route.markers.slice(0, index),
                targetMarker,
                ...route.markers.slice(index)
              ];
            } else {
              routeMarkers = [...route.markers, targetMarker];
            }
            break;
          }
          case 'delete': {
            routeMarkers = route.markers.filter(
              marker => marker.id !== targetMarker.id
            );
            break;
          }
          case 'move': {
            routeMarkers = route.markers.map(marker => {
              if (marker.id === targetMarker.id) {
                return targetMarker;
              } else {
                return marker;
              }
            });
            break;
          }
          default: {
            break;
          }
        }

        if (routeMarkers.length > 1) {
          if (useRouteWaypointsApi) {
            const fromWaypoints = routeMarkers.map(m => [m.lat, m.lng]);
            try {
              const result = await getRouteFromWaypoints(fromWaypoints);
              directions = flatten(result);
            } catch (e) {
              console.warn(e);
              directions = selectedRoute.directions;
            }
          } else {
            directions = routeMarkers.map(m => [m.lat, m.lng]);
          }
        } else {
          directions = [];
        }
        if (route.kind === 'deviation') {
          onDeviationsChanged(
            deviations.map(deviation =>
              deviation.id === route.id
                ? {
                    ...deviation,
                    directions,
                    points: flatten(routeMarkers.map(m => [m.lat, m.lng]))
                  }
                : deviation
            )
          );
        } else {
          onProjectPathChanged({
            directions,
            points: flatten(routeMarkers.map(m => [m.lat, m.lng]))
          });
        }
      } else {
        let updatedMarkers;
        if (action === 'delete') {
          if (targetMarker.item.isNew) {
            updatedMarkers = markers.filter(
              marker => marker.id !== targetMarker.id
            );
          } else {
            updatedMarkers = markers.map(marker => {
              if (marker.id === targetMarker.id) {
                return {
                  ...marker,
                  _destroy: true,
                  item: {
                    ...marker.item,
                    _destroy: true
                  }
                };
              } else {
                return marker;
              }
            });
          }
        } else {
          updatedMarkers = markers.map(marker =>
            marker.id === targetMarker.id
              ? {
                  ...targetMarker,
                  item: {
                    ...targetMarker.item,
                    lat: targetMarker.lat,
                    lng: targetMarker.lng
                  }
                }
              : marker
          );
        }
        onSignsChanged(
          updatedMarkers
            .filter(
              marker =>
                marker.kind === 'deviation_sign' ||
                marker.kind === 'custom' ||
                marker.kind === 'sign_road'
            )
            .map(marker => {
              const { item } = marker;
              const { src, ...sign } = item;
              return sign;
            })
        );
      }
    },
    [
      markers,
      selectedRoute,
      onProjectPathChanged,
      onDeviationsChanged,
      onSignsChanged,
      deviations,
      readOnly,
      useRouteWaypointsApi
    ]
  );

  const handleMarkerCreation = useCallback(
    (marker, index) => handleMarkerChange('creation', marker, index),
    [handleMarkerChange]
  );

  const handleMarkerInsertion = useCallback(
    (marker, index) => handleMarkerChange('insertion', marker, index),
    [handleMarkerChange]
  );

  const handleMarkerMove = useCallback(
    marker => handleMarkerChange('move', marker),
    [handleMarkerChange]
  );

  const handleMarkerDelete = useCallback(
    marker => handleMarkerChange('delete', marker),
    [handleMarkerChange]
  );

  const cursor = ({ isDragging, isHovering }) => {
    const cursor = getCursor({ isDragging, isHovering });
    return cursor === 'grab' && !readOnly && Boolean(activeRouteId)
      ? 'copy'
      : cursor;
  };

  return (
    <Fragment>
      <Map
        ref={mapRef}
        width={width}
        height={height}
        autoAdjustBounds={true}
        getCursor={cursor}
        markers={markers.filter(m => !m.item._destroy)}
        coordinates={coordinates}
        onMarkerDelete={handleMarkerDelete}
        onMarkerMove={handleMarkerMove}
        onMapClick={e => {
          const handled = e.srcEvent?.path?.find(p =>
            p.id?.includes('handled')
          );
          if (handled) {
            if (handled.id.includes('route')) {
              const split = handled.id.replace('handled-route-', '').split('#');
              const routeId = split[0];
              if (selectedRoute && split.length > 1) {
                let nearestPoint = split[1].split(':');
                if (Array.isArray(nearestPoint) && nearestPoint.length > 0) {
                  nearestPoint = nearestPoint[0].split(',');
                }
                if (isEditing && String(selectedRoute.id) === String(routeId)) {
                  const lngLat = e.lngLat;
                  const lat = lngLat[1];
                  const lng = lngLat[0];
                  const index = findIndex(
                    selectedRoute.points,
                    p =>
                      String(p[0]) === String(nearestPoint[0]) &&
                      String(p[1]) === String(nearestPoint[1])
                  );
                  handleMarkerInsertion(
                    {
                      id: v4(),
                      lat: lat,
                      lng: lng,
                      point: [lat, lng],
                      color: selectedRoute.color,
                      kind: 'point',
                      draggable: true,
                      routeId: selectedRoute.id,
                      item: `${selectedRoute.kind}Point`,
                      hidden: false
                    },
                    index >= 0 ? index + 1 : undefined
                  );
                  return;
                }
              }
              onRouteIdSelected(routeId);
            }
            return;
          }
          if (selectedRoute) {
            const lngLat = e.lngLat;
            const lat = lngLat[1];
            const lng = lngLat[0];
            handleMarkerCreation({
              id: v4(),
              lat: lat,
              lng: lng,
              point: [lat, lng],
              color: selectedRoute.color,
              kind: 'point',
              draggable: true,
              routeId: selectedRoute.id,
              item: `${selectedRoute.kind}Point`,
              hidden: false
            });
          }
        }}
      >
        {routes && (
          <Polylines
            editable={isEditing}
            activeRouteId={activeRouteId}
            routes={routes}
            hoverRouteId={hoverRouteId}
            onHoverRouteId={onHoverRouteId}
            useRouteWaypointsApi={useRouteWaypointsApi}
          />
        )}
      </Map>
      {!readOnly && (
        <Button
          style={{
            position: 'absolute',
            top: 16,
            left: 16,
            padding: '8px 16px',
            background: useRouteWaypointsApi ? undefined : '#aaa'
          }}
          type="button"
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            dispatch(UiAction.toggleUseWaypoints());
          }}
        >
          {useRouteWaypointsApi
            ? 'Suivi de route activé'
            : 'Suivi de route désactivé'}
        </Button>
      )}
    </Fragment>
  );
};

export default DeviationMap;

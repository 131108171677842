import { useEffect, useRef, MutableRefObject } from 'react';

const useMountedRef: () => MutableRefObject<boolean> = () => {
  const isMountedRef = useRef<boolean>(false);
  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);
  return isMountedRef;
};

export default useMountedRef;

import {
  DeviationApi,
  DeviationAction,
  DeviationReducer,
  DeviationSelector
} from './data';
import roadworks from 'common/images/roadworks.png';
import closed_road from 'common/images/closed_road.png';
import deviation_left from 'common/images/deviation_left.png';
import deviation_right from 'common/images/deviation_right.png';
import prohibited from 'common/images/prohibited.png';
import prohibited_left_turn from 'common/images/prohibited_left_turn.png';
import prohibited_right_turn from 'common/images/prohibited_right_turn.png';

export { DeviationApi, DeviationAction, DeviationReducer, DeviationSelector };

export const otherSigns = [
  { kind: 'deviation_sign', src: roadworks, name: 'roadworks' },
  { kind: 'deviation_sign', src: closed_road, name: 'closed_road' },
  { kind: 'deviation_sign', src: deviation_left, name: 'deviation_left' },
  { kind: 'deviation_sign', src: deviation_right, name: 'deviation_right' },
  { kind: 'deviation_sign', src: prohibited, name: 'prohibited' },
  {
    kind: 'deviation_sign',
    src: prohibited_left_turn,
    name: 'prohibited_left_turn'
  },
  {
    kind: 'deviation_sign',
    src: prohibited_right_turn,
    name: 'prohibited_right_turn'
  }
];

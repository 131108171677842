import { omit } from 'lodash';
import { mergeJsonApi } from 'common/reducers';

const initialCarrierState = {
  carrierById: {},
  carriersIds: []
};

const carriers = (state = initialCarrierState, action) => {
  const { payload, type } = action;

  switch (type) {
    case 'NEW_CARRIER_SUCCESS':
      return {
        ...mergeJsonApi('carrier', state, action),
        carriersIds: [payload.data.id, ...state.carriersIds]
      };
    case 'DELETE_CARRIER_SUCCESS':
      return {
        ...state,
        carrierById: omit(state.carrierById, payload),
        carriersIds: state.carriersIds.filter(id => parseInt(id) !== payload)
      };
    case 'LOGOUT':
    case 'SELECT_ACCOUNT':
      return initialCarrierState;
    default:
      return mergeJsonApi('carrier', state, action);
  }
};

const initialCarriageTypeState = {
  carriageTypeById: {},
  carriageTypesIds: []
};

const carriageTypes = (state = initialCarriageTypeState, action) => {
  const { payload, type } = action;

  switch (type) {
    case 'NEW_CARRIAGE_TYPE_SUCCESS':
      return {
        ...mergeJsonApi('carriageType', state, action),
        carriageTypesIds: [payload.data.id, ...state.carriageTypesIds]
      };
    case 'DELETE_CARRIAGE_TYPE_SUCCESS':
      return {
        ...state,
        carriageTypeById: omit(state.carriageTypeById, payload),
        carriageTypesIds: state.carriageTypesIds.filter(
          id => parseInt(id) !== payload
        )
      };
    case 'LOGOUT':
    case 'SELECT_ACCOUNT':
      return initialCarriageTypeState;
    default:
      return mergeJsonApi('carriageType', state, action);
  }
};

const initialCarrierOrdersState = {
  carrierOrdersIds: [],
  carrierOrderById: {}
};

const carrierOrders = (state = initialCarrierOrdersState, action) => {
  const { type } = action;
  switch (type) {
    case 'LOGOUT':
    case 'SELECT_ACCOUNT':
      return initialCarrierOrdersState;
    default:
      return mergeJsonApi('carrierOrder', state, action);
  }
};

const initialCarrierOrderDaysState = {
  carrierOrderDaysIds: [],
  carrierOrderDayById: {}
};

const carrierOrderDays = (state = initialCarrierOrderDaysState, action) => {
  const { type } = action;
  switch (type) {
    case 'LOGOUT':
    case 'SELECT_ACCOUNT':
      return initialCarrierOrderDaysState;
    default:
      return mergeJsonApi('carrierOrderDay', state, action);
  }
};

export { carriers, carrierOrders, carriageTypes, carrierOrderDays };

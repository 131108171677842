import { omit } from 'lodash';
import { mergeJsonApi } from 'common/reducers';

const initialState = {
  inputMaterialById: {},
  inputMaterialsIds: []
};

export const inputMaterials = (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case 'INPUT_MATERIALS_LOADING':
      return {
        ...state,
        isLoading: true
      };
    case 'INPUT_MATERIALS_ERROR':
      return {
        ...state,
        isLoading: false
      };
    case 'NEW_INPUT_MATERIAL_SUCCESS':
      return {
        ...mergeJsonApi('inputMaterial', state, action),
        inputMaterialsIds: [...state.inputMaterialsIds, payload.data.id]
      };
    case 'DELETE_INPUT_MATERIAL_SUCCESS':
      return {
        ...state,
        inputMaterialById: omit(state.inputMaterialById, payload),
        inputMaterialsIds: state.inputMaterialsIds.filter(
          id => parseInt(id) !== payload
        )
      };
    case 'LOGOUT':
    case 'SELECT_ACCOUNT':
      return initialState;
    default:
      return mergeJsonApi('inputMaterial', state, action);
  }
};

const inputItemInitialState = {
  inputItemById: {}
};

export const inputItems = (state = inputItemInitialState, action) => {
  const { type } = action;

  switch (type) {
    case 'LOGOUT':
    case 'SELECT_ACCOUNT':
      return initialState;
    default:
      return mergeJsonApi('inputItem', state, action);
  }
};
